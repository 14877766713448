import React from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { useSubscribe, PubSub } from "use-pubsub-js";

export const useNotif = () => {
  const notify = (notification) => {
    PubSub.publish("new-notification", notification);
  };

  return { notify };
};

const NotifZone = ({ eventName = "new-notification" }) => {
  const [notifications, setNotifications] = React.useState([]);
  const { unsubscribe, resubscribe } = useSubscribe({
    token: "new-notification",
    handler: (t, notification) => {
      setNotifications((notifications) => [...notifications, notification]);
    },
  });

  const closeNotification = (index) => {
    const newNotifications = [...notifications];
    newNotifications.splice(index, 1);
    setNotifications(newNotifications);
  };

  // const position = notifications.position || "top-end";

  return (
    <ToastContainer position={"top-end"} containerPosition="fixed">
      {notifications.map((notification, index) => (
        <Toast
          autohide={true}
          variant={notification.variant || "primary"}
          bg={notification.variant || "primary"}
          delay={notification.delay || 10000}
          key={index}
          onClose={() => closeNotification(index)}
        >
          <Toast.Header>
            <strong className="mr-auto">{notification.title}</strong>
          </Toast.Header>
          <Toast.Body className="">{notification.message}</Toast.Body>
        </Toast>
      ))}
    </ToastContainer>
  );
};

export default NotifZone;
