import React from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./BlockType.css";

const getDataFetcherTypeDetails = (block) => {
  const { method } = block;
  const { results } = method;

  if (!results || results.length === 0) {
    return null;
  }

  let type = "{\n";

  results.forEach((result, index) => {
    type += `${result.key}: ${result.resultType}`;
    if (index < results.length - 1) {
      type += ",\n";
    }
  });

  type += "\n}";

  return type;
};

const getActionFetcherTypeDetails = (block) => {
  const { method } = block;
  const { results } = method;

  if (!results || results.length === 0) {
    return null;
  }

  let type = "{\n";

  results.forEach((result, index) => {
    type += `${result.key}: ${result.resultType}`;
    if (index < results.length - 1) {
      type += ",\n";
    }
  });

  type += "\n}";

  return type;
};

const getAiProcessingTypeDetails = (block) => {
  const { builtPrompt } = block;
  const { typing } = builtPrompt;

  if (!typing || !typing.globalType) {
    return { type: "string" };
  }

  if (
    typing.globalType === "JSON_OBJECT" ||
    typing.globalType === "JSON_ARRAY"
  ) {
    return { details: typing.result, type: "object" };
  } else if (typing.globalType === "STRING_ENUM") {
    return {
      details: `Enum: ${typing.enums.join(", ")}`,
      type: "string"
    };
  } else {
    return {
      type: typing.globalType
    };
  }
};

const getMainTypeByBlock = (blockType) => {
  switch (blockType) {
    case "logicListener":
      return "boolean";
    case "actionFetcher":
      return "string";
    case "dataFetcher":
      return "string";
    case "input":
      return "string";
    case "aiProcessing":
      return "string";
    default:
      return "string";
  }
};

// A badge showing the type of block
// When hovered, it shows details on the type(objects, etc...)
const BlockType = ({ blockType, block }) => {
  let mainType = getMainTypeByBlock(blockType);
  let typeDetails = "";

  switch (blockType) {
    case "dataFetcher":
      typeDetails = getDataFetcherTypeDetails(block);
      if (!!typeDetails) {
        mainType = "object";
      }
      break;
    case "actionFetcher":
      typeDetails = getActionFetcherTypeDetails(block);
      if (!!typeDetails) {
        mainType = "object";
      }
      break;
    case "aiProcessing":
      const { type, details } = getAiProcessingTypeDetails(block);
      mainType = type;
      typeDetails = details;
      break;
    default:
      break;
  }

  if (!typeDetails) {
    return (
      <div className="block-type">
        <Badge className="badge-type">{mainType}</Badge>
      </div>
    );
  }

  return (
    <div className="block-type with-details">
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="block-type-tooltip">{typeDetails}</Tooltip>}
      >
        <Badge className="badge-type">{mainType}</Badge>
      </OverlayTrigger>
    </div>
  );
};

export default BlockType;
