import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Button,
  Col,
  Row,
  Card,
  Table,
  Dropdown,
  Placeholder,
  FormControl,
  OverlayTrigger,
  Popover,
  Modal
} from "react-bootstrap";

import DB from "../../../../database/DB";
import SystemBuilderUtils from "../utils";
import { BuiltPromptModal } from "../../LiteTraining";
import DropdownGroupedByAndAutoComplete from "../../../UI/DropdownGroupedByAndAutoComplete";
import ConfirmPopover from "../../../UI/ConfirmPopover";
import { ApiBuilderContext } from "../../../../contexts/ApiBuilderContext";
import { ChainContext } from "../../../../contexts/ChainContext";
import BlockType from "../utils/BlockType";

const getPromptBlocksKeys = (promptBlocks) => {
  return promptBlocks.map((pb) => ({
    chainKey: pb.chainKey,
    promptExportVariable: pb.valuesLinkedExport
      ? pb.valuesLinkedExport.result
      : null
  }));
};

const PromptBlockValueInput = ({ value, type, promptBlocksKeys, onChange }) => {
  const matchedBlockKeys = promptBlocksKeys.find(
    (blockKeys) => blockKeys.promptExportVariable === value
  );
  let textMatchedKey = "";
  if (matchedBlockKeys) {
    const { chainKey, promptExportVariable } = matchedBlockKeys;
    textMatchedKey = `${promptExportVariable}`;
  } else {
    textMatchedKey = value;
  }

  return (
    <div>
      {type === "input" && (
        <Form.Control
          type="text"
          value={value}
          placeholder="Leave empty to use the default property variable."
          onChange={(e) => onChange(e.target.value)}
        />
      )}
      {type === "computed" && (
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {textMatchedKey}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {promptBlocksKeys.map((blockKeys) => {
              const { chainKey, promptExportVariable } = blockKeys;
              const text = `${promptExportVariable}`;
              return (
                <Dropdown.Item
                  key={blockKeys.chainKey}
                  onClick={() => onChange(promptExportVariable)}
                >
                  {text}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
};

const extractComputedValue = (
  inputs,
  outputs,
  inputsToExtract,
  outputsToExtract
) => {
  console.log("inputs", inputs);
  const systemValues = SystemBuilderUtils.getIOitems(inputs, outputs, {
    inputs: inputsToExtract,
    outputs: outputsToExtract
    // inputs: ["transform.promptBlocks", "global.parameters"],
    // outputs: [
    //   "transform.promptBlocks",
    //   "inputConnections.fetcher",
    //   // "emitters.logic",
    //   "emitters.fetch",
    // ],
  });
  const { inputsList, outputsList } = systemValues;

  const systemValuesItems = SystemBuilderUtils.IOitemsToDropdownItems([
    ...inputsList,
    ...outputsList
  ]);

  return systemValuesItems;
};

const getComputedValueItems = (type, inputs, outputs) => {
  switch (type) {
    case "computed-receiver-fetch":
      return extractComputedValue(
        inputs,
        outputs,
        [],
        ["inputConnections.fetcher"]
      );
    case "computed-emitter-fetch":
      return extractComputedValue(inputs, outputs, [], ["emitters.fetch"]);
    case "computed-logic":
      return extractComputedValue(inputs, outputs, [], ["emitters.logic"]);
    case "global-parameter":
      return extractComputedValue(inputs, outputs, ["global.parameters"], []);
    case "input-block":
      return extractComputedValue(
        inputs,
        outputs,
        ["inputBlocks.userInput"],
        []
      );
    default:
      return [];
  }
};

const ComputedValueInput = ({ inputs, outputs, value, onChange, type }) => {
  const computedValueItems = getComputedValueItems(type, inputs, outputs);

  const matchedComputedValueItem = computedValueItems.find(
    (item) => item.value === value
  );

  const textMatchedComputedValueItem = matchedComputedValueItem
    ? matchedComputedValueItem.value
    : value;

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          {textMatchedComputedValueItem}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {computedValueItems.map((item) => (
            <Dropdown.Item
              key={item.value}
              onClick={() => onChange(item.value)}
            >
              {item.value}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

const LogicBlock = ({ promptBlock, onChange }) => {
  const { promptBlocks } = useContext(ChainContext);
  const { logicType, valuesLogicLinkedImport = {} } = promptBlock;
  const { source = "" } = valuesLogicLinkedImport;
  const promptBlocksKeys = getPromptBlocksKeys(promptBlocks);

  const sourceBlock = promptBlocksKeys.find(
    (key) => key.promptExportVariable === source
  );

  let text = "";
  if (sourceBlock) {
    const { chainKey, promptExportVariable } = sourceBlock;
    text = `${promptExportVariable} (${chainKey})`;
  }

  const handleValuesLinkedExport = (key, value) => {
    onChange({
      valuesLinkedExport: {
        ...promptBlock.valuesLinkedExport,
        [key]: value
      }
    });
  };

  return (
    <div>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2">
          Logic type
        </Form.Label>
        <Col sm="10">
          <Form.Select
            aria-label="Default select example"
            value={logicType || ""}
            onChange={(e) => onChange({ logicType: e.target.value })}
          >
            <option value="loopResult">Loop result</option>
            <option value="concatResult">concatResult result</option>
          </Form.Select>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2">
          Source
        </Form.Label>
        <Col sm="10">
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {text}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {promptBlocksKeys.map((blockKeys) => {
                const { chainKey, promptExportVariable } = blockKeys;
                return (
                  <Dropdown.Item
                    key={blockKeys.chainKey}
                    onClick={() =>
                      onChange({
                        valuesLogicLinkedImport: {
                          ...valuesLogicLinkedImport,
                          source: promptExportVariable
                        }
                      })
                    }
                  >
                    {`${promptExportVariable} (${chainKey})`}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Form.Group>
      {promptBlock.valuesLinkedExport && (
        <div className="mt-3">
          <Form.Label>Key/prop in api result</Form.Label>
          <FormControl
            type="text"
            placeholder="Leave blank to not export"
            value={promptBlock.valuesLinkedExport.result || ""}
            onChange={(e) => {
              handleValuesLinkedExport("result", e.target.value);
            }}
          />
        </div>
      )}
    </div>
  );
};

const CompletionBlock = ({ onChange, promptBlock }) => {
  const { builtPrompts = [], id: apiProjectId } = useContext(ApiBuilderContext);
  const { promptBlocks, inputs, outputs } = useContext(ChainContext);
  const [editingDefaultResultKey, setEditingDefaultResultKey] = useState(null);
  const [editingDefaultResultValue, setEditingDefaultResultValue] =
    useState("");
  const [showBuiltPromptModal, setShowBuiltPromptModal] = useState(false);
  const [builtPrompt, setBuiltPrompt] = useState(null);
  const isIaGenerated = !!promptBlock.aiGeneratedBuiltPrompt;
  const promptBlocksKeys = getPromptBlocksKeys(promptBlocks);

  // Hydrate copyOfPromptBlock.valuesLinkedImport with the builtPrompt valueKeys
  useEffect(() => {
    if (isIaGenerated) {
      return;
    }
    if (!builtPrompt) {
      onChange({
        ...promptBlock,
        valuesLinkedImport: {}
      });
      return;
    }
    const valuesLinkedImport = {};
    builtPrompt.valueKeys.forEach((valueKey) => {
      if (
        !promptBlock.valuesLinkedImport ||
        !promptBlock.valuesLinkedImport[valueKey]
      ) {
        valuesLinkedImport[valueKey] = {
          type: "input",
          value: "",
          defaultResult: null,
          dataType: "string"
        };
      } else {
        valuesLinkedImport[valueKey] = {
          ...promptBlock.valuesLinkedImport[valueKey]
        };
      }
    });
    onChange({
      ...promptBlock,
      valuesLinkedImport
    });
  }, [builtPrompt]);

  useEffect(() => {
    if (isIaGenerated) {
      return;
    }
    if (!promptBlock.builtPromptId) {
      setBuiltPrompt(null);
      return;
    }
    // Fetch builtPrompt document from Firestore
    const unsubscribe = DB.apiProjects.sub.builtPrompts.get(
      apiProjectId,
      promptBlock.builtPromptId,
      (builtPrompt) => {
        setBuiltPrompt(builtPrompt);
      }
    );
    return unsubscribe;
  }, [promptBlock.builtPromptId]);

  useEffect(() => {
    if (isIaGenerated) {
      const { promptName, model, valueKeys, prompt } =
        promptBlock.aiGeneratedBuiltPrompt;
      setBuiltPrompt({
        isAutoGenerated: true,
        prompt,
        promptName,
        model,
        valueKeys
      });
    }
  }, [isIaGenerated]);

  const handleBuiltPromptSelect = (value) => {
    onChange({
      ...promptBlock,
      builtPromptId: value
    });
  };

  const handleValuesLinkedImport = (key, keyValueProp, value) => {
    const newCopyOfPromptBlocks = {
      ...promptBlock,
      valuesLinkedImport: {
        ...promptBlock.valuesLinkedImport,
        [key]: {
          ...promptBlock.valuesLinkedImport[key],
          [keyValueProp]: value
        }
      }
    };
    onChange(newCopyOfPromptBlocks);
  };

  const handleValuesLinkedExport = (...args) => {
    // args are pairs of key, value
    if (args.length % 2 !== 0) {
      throw new Error("handleValuesLinkedExport: args must be pairs");
    }

    const finalResults = args.reduce((acc, arg, i) => {
      if (i % 2 === 0) {
        if (typeof arg !== "string") {
          throw new Error(
            "handleValuesLinkedExport: key must be a string, got " + typeof arg
          );
        }
        acc[arg] = args[i + 1];
      }
      return acc;
    }, {});

    const newCopyOfPromptBlocks = {
      ...promptBlock,
      valuesLinkedExport: {
        ...promptBlock.valuesLinkedExport,
        ...finalResults
        // [key]: value
      }
    };
    onChange(newCopyOfPromptBlocks);
  };

  let builtPromptUniqueValueKeys = [];

  if (builtPrompt && builtPrompt.valueKeys) {
    builtPromptUniqueValueKeys = builtPrompt.valueKeys.reduce(
      (acc, valueKey) => {
        if (!acc.includes(valueKey)) {
          acc.push(valueKey);
        }
        return acc;
      },
      []
    );
  }

  const builtPromptsForDropdown = builtPrompts.map((builtPrompt) => {
    return {
      key: builtPrompt.id,
      value: builtPrompt.id,
      label: builtPrompt.promptName || builtPrompt.id,
      tags: builtPrompt.tags || [],
      group: builtPrompt.groupId || "Other"
    };
  });

  const editDefaultResultTooltip = (valueKey) => {
    return (
      <Popover id="popover-basic">
        <Popover.Header as="h3">
          Edit default result to use
          <br />
          when this key is missing
        </Popover.Header>
        <Popover.Body>
          <Form.Control
            as="textarea"
            rows={3}
            value={editingDefaultResultValue}
            onChange={(e) => {
              setEditingDefaultResultValue(e.target.value);
            }}
          />
          <hr />
          <div className="d-flex align-items-end justify-content-end">
            <Button
              variant="secondary"
              onClick={() => setEditingDefaultResultKey(null)}
            >
              Cancel
            </Button>
            <Button
              className="ms-2"
              variant="primary"
              onClick={() => {
                handleValuesLinkedImport(
                  valueKey,
                  "defaultResult",
                  editingDefaultResultValue
                );
                setEditingDefaultResultValue("");
                setEditingDefaultResultKey(null);
              }}
            >
              Save
            </Button>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  return (
    <Card>
      <Card.Header>
        <Row>
          <Col className="d-flex align-items-center justify-content-between">
            <h6>Mode: Completion Block</h6>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        {showBuiltPromptModal && (
          <BuiltPromptModal
            apiProjectId={apiProjectId}
            builtPromptId={promptBlock.builtPromptId}
            defaultBuiltPrompt={
              isIaGenerated && builtPrompt ? builtPrompt : null
            }
            onHide={() => setShowBuiltPromptModal(false)}
            onSelectAndClose={(builtPromptId) => {
              handleBuiltPromptSelect(builtPromptId);
              setShowBuiltPromptModal(false);
            }}
          />
        )}

        <Form.Group>
          <Form.Label>Built Prompt {builtPrompt?.promptName}</Form.Label>
          <DropdownGroupedByAndAutoComplete
            valueLabel={builtPrompt?.promptName || "Select a built prompt"}
            items={builtPromptsForDropdown}
            onChange={handleBuiltPromptSelect}
            value={promptBlock.builtPromptId || ""}
            placeholder="Select a built prompt"
          />
          <Form.Text className="text-muted">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setShowBuiltPromptModal(true);
              }}
            >
              {promptBlock.builtPromptId
                ? "View Built Prompt"
                : "Create Built Prompt"}
            </a>
          </Form.Text>
        </Form.Group>
        <Form.Group>
          {promptBlock.valuesLinkedImport && builtPrompt && (
            <div className="mt-3">
              <Form.Label>Values Linked Import</Form.Label>
              <div className="table-parent">
                <Table bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>Default Property Key</th>
                      <th>Type</th>
                      <th>Custom Property Key</th>
                    </tr>
                  </thead>
                  <tbody>
                    {builtPromptUniqueValueKeys.map((valueKey, i) => {
                      if (!promptBlock.valuesLinkedImport[valueKey])
                        return (
                          <tr key={valueKey}>
                            <td>
                              <Placeholder xs={6} />
                            </td>
                          </tr>
                        );
                      const { defaultResult } =
                        promptBlock.valuesLinkedImport[valueKey];
                      const overlayElement = editDefaultResultTooltip(valueKey);
                      const isInputTypeExternal = [
                        "input",
                        "computed"
                      ].includes(promptBlock.valuesLinkedImport[valueKey].type);

                      return (
                        <tr key={valueKey}>
                          <td>
                            {valueKey}
                            <br />
                            {/* Set default result value */}
                            <OverlayTrigger
                              placement="top-start"
                              show={editingDefaultResultKey === valueKey}
                              onHide={() => setEditingDefaultResultKey(null)}
                              onToggle={(show) => {
                                if (show) {
                                  setEditingDefaultResultValue(defaultResult);
                                  setEditingDefaultResultKey(valueKey);
                                } else {
                                  setEditingDefaultResultKey(null);
                                  setEditingDefaultResultValue("");
                                }
                              }}
                              trigger="click"
                              overlay={overlayElement}
                            >
                              <Button
                                variant="link"
                                size="sm"
                                style={{ fontSize: "11px" }}
                              >
                                {!!defaultResult && (
                                  <>
                                    <i className="fas fa-edit"></i> Edit Default
                                    Result
                                  </>
                                )}
                                {!defaultResult && (
                                  <>
                                    <i className="fas fa-plus"></i> Add Default
                                    Result
                                  </>
                                )}
                              </Button>
                            </OverlayTrigger>
                          </td>
                          <td>
                            <Form.Control
                              as="select"
                              value={
                                promptBlock.valuesLinkedImport[valueKey].type ||
                                ""
                              }
                              onChange={(e) =>
                                handleValuesLinkedImport(
                                  valueKey,
                                  "type",
                                  e.target.value
                                )
                              }
                            >
                              {/* <option value="input">User Input</option> */}
                              <option value="input-block">Input</option>
                              <option value="computed">
                                Computed From Model
                              </option>
                              <option value="computed-receiver-fetch">
                                Computed From Receiver
                              </option>
                              <option value="computed-emitter-fetch">
                                Computed From Fetched Emitters
                              </option>
                              <option value="computed-logic">
                                Computed From Logic
                              </option>
                              <option value="global-parameter">
                                Global Parameter
                              </option>
                            </Form.Control>
                            {/* dataType */}
                            {promptBlock.valuesLinkedImport[valueKey].type ===
                              "input" && (
                              <Form.Control
                                style={{
                                  fontSize: "11px",
                                  height: "25px",
                                  padding: "0px 5px"
                                }}
                                className="mt-1"
                                as="select"
                                value={
                                  promptBlock.valuesLinkedImport[valueKey]
                                    .dataType || "string"
                                }
                                onChange={(e) =>
                                  handleValuesLinkedImport(
                                    valueKey,
                                    "dataType",
                                    e.target.value
                                  )
                                }
                              >
                                <option value="string">Text</option>
                                {/* <option value="number">Number</option> */}
                                {/* <option value="boolean">Boolean</option> */}
                                <option value="array">List</option>
                                {/* <option value="object">Object</option> */}
                              </Form.Control>
                            )}
                          </td>
                          <td>
                            {isInputTypeExternal && (
                              <PromptBlockValueInput
                                promptBlocksKeys={promptBlocksKeys}
                                onChange={(value) =>
                                  handleValuesLinkedImport(
                                    valueKey,
                                    "value",
                                    value
                                  )
                                }
                                value={
                                  promptBlock.valuesLinkedImport[valueKey]
                                    .value || ""
                                }
                                type={
                                  promptBlock.valuesLinkedImport[valueKey]
                                    .type || ""
                                }
                              />
                            )}
                            {!isInputTypeExternal && (
                              <ComputedValueInput
                                inputs={inputs}
                                outputs={outputs}
                                value={
                                  promptBlock.valuesLinkedImport[valueKey]
                                    .value || ""
                                }
                                type={
                                  promptBlock.valuesLinkedImport[valueKey]
                                    .type || ""
                                }
                                onChange={(value) => {
                                  handleValuesLinkedImport(
                                    valueKey,
                                    "value",
                                    value
                                  );
                                }}
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          )}
          {promptBlock.valuesLinkedExport && (
            <div className="mt-3">
              <Form.Label>Result Key</Form.Label>
              <FormControl
                type="text"
                placeholder="Leave blank to not export"
                value={promptBlock.valuesLinkedExport.resultName || ""}
                onChange={(e) => {
                  // resultKey is the value with spaces replaced by underscores
                  const resultKey = e.target.value
                    .toLowerCase()
                    .replace(/\s/g, "_");
                  handleValuesLinkedExport(
                    "result",
                    resultKey,
                    "resultName",
                    e.target.value
                  );
                }}
              />
            </div>
          )}
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

const PromptBlock = ({ promptBlock, onCloseSaved = () => {} }) => {
  const { promptBlocks, chainActions } = useContext(ChainContext);
  const [confirmRemoving, setConfirmRemoving] = useState(false);
  const parentBlock = React.useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [copyOfPromptBlock, setCopyOfPromptBlock] = useState(promptBlock);
  const { valuesLinkedExport = {}, chainKey } = copyOfPromptBlock;
  const { result: resultKey } = valuesLinkedExport;

  useEffect(() => {
    setCopyOfPromptBlock(promptBlock);
  }, [promptBlock]);

  const handleBlockChange = (e) => {
    const { name, value } = e.target;
    setCopyOfPromptBlock({ ...copyOfPromptBlock, [name]: value });
  };

  const handleBlockTypeChange = (e) => {
    const { name, value } = e.target;
    setCopyOfPromptBlock({
      ...copyOfPromptBlock,
      [name]: value,
      valuesLinkedImport: {}
    });
  };

  const onBlockChange = (newPromptBlock) => {
    setCopyOfPromptBlock({
      ...copyOfPromptBlock,
      ...newPromptBlock
    });
  };

  const onSave = async () => {
    await chainActions.updatePromptBlock(copyOfPromptBlock);
    onCloseSaved();
  };

  // const titleName = `${chainKey} ${!!resultKey ? ` - ${resultKey}` : ""}`;
  const titleName = resultKey || "Add a result key/name to this block below";

  const removeBlock = async () => {
    const { chainKey } = copyOfPromptBlock;
    const newBlocks = promptBlocks.filter((pb) => pb.chainKey !== chainKey);
    await chainActions.updatePromptBlocks(newBlocks);
    onCloseSaved();
  };

  return (
    <>
      <Card
        className={`PromptBlock mb-0 mt-card`}
        ref={parentBlock}
        style={{ position: "relative" }}
      >
        <div
          className="p-2"
          style={{ position: "absolute", top: "0", right: "0" }}
        >
          <ConfirmPopover
            onConfirm={removeBlock}
            title="Are you sure?"
            placement="bottom"
            message="This will remove the block and all its children. This action cannot be undone once it's saved."
          >
            <Button variant="link-secondary">
              <i className="fas fa-trash-alt text-danger"></i>
            </Button>
          </ConfirmPopover>
        </div>
        <Card.Header>
          <div>
            <h5 style={!resultKey ? { color: "#aaa" } : {}}>{titleName}</h5>
            <Form.Group as={Row} controlId={`blockType-${titleName}`}>
              <Form.Label>Block Type</Form.Label>
              <Form.Control
                as="select"
                name="blockType"
                value={copyOfPromptBlock.blockType}
                onChange={handleBlockTypeChange}
              >
                <option value="completion">Completion</option>
                <option value="logic">Logic</option>
              </Form.Control>
            </Form.Group>
          </div>
        </Card.Header>
        {
          <Card.Body>
            {copyOfPromptBlock.blockType === "completion" && (
              <CompletionBlock
                promptBlock={copyOfPromptBlock}
                onChange={onBlockChange}
              />
            )}
            {copyOfPromptBlock.blockType === "logic" && (
              <LogicBlock
                promptBlock={copyOfPromptBlock}
                onChange={onBlockChange}
              />
            )}

            {/* set builtPrompt writingMode : "overwrite", // can be "overwrite" or "append" */}
            <div className="mt-3">
              <Form.Group as={Row} controlId="formPlaintextEmail">
                <Form.Label column sm="2">
                  Writing Mode
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    as="select"
                    name="writingMode"
                    value={copyOfPromptBlock.writingMode || "overwrite"}
                    onChange={handleBlockChange}
                  >
                    <option value="overwrite">Overwrite</option>
                    <option value="append">Append</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </div>
          </Card.Body>
        }
        <Card.Footer>
          <Button variant="primary" onClick={onSave}>
            Save
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
};

export const PromptBlockNode = ({ data }) => {
  const [currentBuiltPrompt, setCurrentBuiltPrompt] = useState("");
  const [open, setOpen] = useState(false);

  const { promptBlock, childrenProps } = data;

  const { promptBlocks, inputs, outputs } = useContext(ChainContext);
  const { id: apiProjectId, apiProject } = useContext(ApiBuilderContext);

  const { chainKey, blockType, valuesLinkedExport } = promptBlock;
  const { result } = valuesLinkedExport;
  const builtPromptId = promptBlock.builtPromptId;

  useEffect(() => {
    if (promptBlock.blockType === "completion" && builtPromptId) {
      const unsubscribe = DB.apiProjects.sub.builtPrompts.get(
        apiProjectId,
        builtPromptId,
        (builtPrompt) => {
          setCurrentBuiltPrompt(builtPrompt);
        }
      );
      return unsubscribe;
    }
  }, [builtPromptId]);

  const toggleBlockConfigurationModal = () => {
    setOpen(!open);
  };

  const onScrollStopPropagation = (e) => {
    e.stopPropagation();
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  // const onPromptChange = (newPrompt) => {
  //   const newPromptBlock = { ...promptBlock };
  //   newPromptBlock.builtPromptId = newPrompt.id;
  //   DB.promptBlocks.update(newPromptBlock);
  // };

  const contentElementCompletion = () => {
    if (!currentBuiltPrompt) {
      return (
        <div className="p-3">
          <div className="text-left">
            <h5 className="text-gray-node fs-6">Completion Block</h5>
            <Placeholder />
            <Button
              variant="link"
              size="sm"
              className=""
              onClick={() => {
                toggleBlockConfigurationModal();
              }}
            >
              Create Completion Block
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="p-3">
          <div className="text-left">
            <h5 className="text-gray-node mb-3 fs-6">Completion Block</h5>
            <div
              className="text-gray-node mb-2 text-completion-node"
              style={{ height: "100px" }}
              // onScrollStopPropagation
              onScroll={onScrollStopPropagation}
            >
              {truncateText(currentBuiltPrompt.prompt, 130)}
            </div>
            <Button
              variant="link"
              size="sm"
              className="ps-0"
              onClick={() => {
                toggleBlockConfigurationModal();
              }}
            >
              Edit Completion Block
            </Button>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="p-1">
      {/* <Handle type="target" position={Position.Top} id="e" />
        <Handle type="target" position={Position.Right} id="f" /> */}

      <div className={`reactflow-node pt-3 type-${blockType}`}>
        <div className="reactflow-node-header">
          <div className="reactflow-node-header-title">
            <div className="reactflow-node-header-title-text">
              <div className="reactflow-node-header-title-text-name text-truncate text-gray-node ps-3 fs-5">
                {result || "Completion Block" + " - " + chainKey}
              </div>
            </div>
          </div>
          <div className="reactflow-node-header-actions">
            {blockType === "completion" && contentElementCompletion()}
          </div>

          {currentBuiltPrompt && (
            <BlockType
              blockType={"aiProcessing"}
              block={{
                ...promptBlock,
                builtPrompt: currentBuiltPrompt
              }}
            />
          )}
        </div>
        {open && (
          <Modal show={open} onHide={toggleBlockConfigurationModal} size="lg">
            <Modal.Body className="p-0">
              <PromptBlock
                promptBlock={promptBlock}
                onCloseSaved={() => {
                  toggleBlockConfigurationModal();
                }}
              />
            </Modal.Body>
          </Modal>
        )}
      </div>
      {/* <Handle type="source" position={Position.Bottom} id="a" />
        <Handle type="source" position={Position.Left} id="b" /> */}
    </div>
  );
};

export default PromptBlock;
