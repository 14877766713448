import React, { useState } from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";

const ConfirmPopover = ({
  title,
  message,
  onConfirm,
  onCancel,
  confirmText = "Confirm",
  cancelText = "Cancel",
  placement = "top",
  stopPropagation,
  children,
  ...props
}) => {
  const [show, setShow] = useState(false);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">{title}</Popover.Header>
      <Popover.Body>
        <p>{message}</p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button
            variant="outline-danger"
            size="sm"
            className="me-2"
            onClick={() => {
              setShow(false);
              onConfirm();
            }}
          >
            {confirmText}
          </Button>
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={() => {
              setShow(false);
              onCancel && onCancel();
            }}
          >
            {cancelText}
          </Button>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      show={show}
      placement={placement}
      onToggle={(show) => {
        setShow(show);
      }}
      overlay={popover}
      trigger="click"
      rootClose
    >
      {stopPropagation ? (
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          {children}
        </div>
      ) : (
        children
      )}
    </OverlayTrigger>
  );
};

export default ConfirmPopover;
