/**
 * @fileoverview DropdownGroupedSelect component
 */

import React, { useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";

/**
 * @typedef {Object} Item
 * @property {string} value
 * @property {string} group
 * @property {string} label
 * @property {string} key
 * @property {any} data
 */

/**
 * @typedef {Object} Props
 * @property {Item[]} items
 * @property {(item: Item) => boolean} isSelected
 * @property {(item: Item) => void} onAdd
 * @property {(item: Item) => void} onRemove
 * @property {string} placeholder
 * @property {string} className
 * @property {string} classNameMenu
 * @property {Object} tr
 * @property {(group: string) => string} tr.title
 */

const DropdownGroupedSelect = ({
  items,
  isSelected,
  onAdd,
  onRemove,
  placeholder,
  className,
  classNameMenu,
  tr = {
    title: (group) => group,
  },
}) => {
  // const [selectedItems, setSelectedItems] = useState([]);

  const getSelectedItems = () => {
    return items.filter((item) => isSelected(item));
  };

  const getRestItems = () => {
    return items.filter((item) => !isSelected(item));
  };

  const selectedItems = getSelectedItems();
  const restItems = getRestItems();

  const selectedItemsGrouped = selectedItems.reduce((acc, item) => {
    const key = item.group;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});

  const restItemsGrouped = restItems.reduce((acc, item) => {
    const key = item.group;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});

  return (
    <Dropdown className={className}>
      <Dropdown.Toggle variant="primary" id="dropdown-basic">
        {selectedItems.length
          ? selectedItems.map((item) => item.value).join(", ")
          : placeholder}
      </Dropdown.Toggle>
      <Dropdown.Menu className={classNameMenu}>
        {Object.keys(selectedItemsGrouped).map((key) => (
          <React.Fragment key={key}>
            {selectedItemsGrouped[key].map((item) => (
              <Dropdown.Item
                key={item.value}
                onClick={() => onRemove(item)}
                eventKey={item.value}
                className="d-flex justify-content-between align-items-center text-white-4"
              >
                <span>{item.value}</span>
                <Button
                  variant="link"
                  size="sm"
                  className="p-0 text-white-4"
                  onClick={(e) => {
                    e.stopPropagation();
                    onRemove(item);
                  }}
                >
                  <span className="text-danger">
                    <i className="fas fa-times"></i> Remove
                  </span>
                </Button>
              </Dropdown.Item>
            ))}
          </React.Fragment>
        ))}
        {Object.keys(selectedItemsGrouped).length ? (
          <div className="horizontal-separator"></div>
        ) : null}
        {Object.keys(restItemsGrouped).map((key, groupI) => (
          <React.Fragment key={key}>
            <Dropdown.Header className="text-white-7 fs-6">
              {tr.title(key)}
            </Dropdown.Header>
            {restItemsGrouped[key].map((item) => (
              <Dropdown.Item
                key={item.value}
                onClick={() => onAdd(item)}
                className="d-flex justify-content-between align-items-center text-white-4"
                eventKey={item.value}
              >
                <span>{item.value}</span>
                <Button
                  variant="link"
                  size="sm"
                  className="p-0 text-white-4"
                  onClick={(e) => {
                    e.stopPropagation();
                    onAdd(item);
                  }}
                >
                  <i className="fas fa-plus"></i> Add
                </Button>
              </Dropdown.Item>
            ))}
            {groupI < Object.keys(restItemsGrouped).length - 1 ? (
              <div className="px-3 my-1">
                <div className="horizontal-separator"></div>
              </div>
            ) : null}
          </React.Fragment>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownGroupedSelect;
