import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import DeploymentApi from "../../../../apis/deployment";
import { FaCheckCircle, FaTimesCircle, FaSpinner } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoCopyOutline } from "react-icons/io5";
import "./ApiKeysModal.css";

const ApiKeysModal = ({ show, onClose, deployment }) => {
  const [isCreatingApiKey, setIsCreatingApiKey] = useState(false);
  const [showApiKeyFor, setShowApiKeyFor] = useState(null);
  const { id: deploymentId, deploymentModel, deployedTokenGroups } = deployment;
  const { tokenGroups: tokenGroupsModel = [] } = deploymentModel;
  const [activeTab, setActiveTab] = useState("creaxys");
  const [activeTokenGroupModel, setActiveTokenGroupModel] = useState(null); // { id: "tokenGroupId", name: "tokenGroupName" }
  const [creaxysKeys, setCreaxysKeys] = useState([]);
  const [tokenGroupApiKeys, setTokenGroupApiKeys] = useState({});

  useEffect(() => {
    if (show) {
      loadKeys();
    }
  }, [show, deploymentId]);

  const loadKeys = async () => {
    await loadCreaxysApiKeys();
    await loadTokenGroupApiKeys();

    setActiveTokenGroupModel(tokenGroupsModel[0]);
  };

  const loadCreaxysApiKeys = async () => {
    const creaxysApiKeys = await DeploymentApi.getCreaxysApiKeys(deploymentId);
    setCreaxysKeys(creaxysApiKeys);
  };

  const loadTokenGroupApiKeys = async () => {
    const tokenGroupApiKeys = await DeploymentApi.getTokenGroupApiKeys(
      deploymentId
    );
    const { groups } = tokenGroupApiKeys;
    let newTokenGroupApiKeys = {};

    Object.keys(deployedTokenGroups).forEach((tokenGroupModelId) => {
      const deployedTokenGroupId = deployedTokenGroups[tokenGroupModelId];
      newTokenGroupApiKeys[tokenGroupModelId] = groups[deployedTokenGroupId];
    });

    setTokenGroupApiKeys(newTokenGroupApiKeys);
  };

  const createCreaxysApiKey = async (deploymentId) => {
    setIsCreatingApiKey(true);
    await DeploymentApi.createCreaxysApiKey(deploymentId);
    await loadCreaxysApiKeys();
    setIsCreatingApiKey(false);
  };

  const createTokenGroupApiKey = async (deploymentId, tokenGroupId) => {
    setIsCreatingApiKey(true);
    await DeploymentApi.createTokenGroupApiKey(deploymentId, tokenGroupId);
    await loadTokenGroupApiKeys();
    setIsCreatingApiKey(false);
  };

  const handleChangeTab = (tab) => {
    if (tab === "tokenGroup") {
      setActiveTokenGroupModel(tokenGroupsModel[0]);
    }
    setActiveTab(tab);
  };

  const getStatusIcon = (isActive) => {
    if (isActive) {
      return <FaCheckCircle className="active-icon" />;
    } else {
      return <FaTimesCircle className="unactive-icon" />;
    }
  };

  const selectedTokenGroupApiKeys =
    (activeTokenGroupModel && tokenGroupApiKeys[activeTokenGroupModel.id]) ||
    [];

  const hideKey = (key, hiddenState = true) => {
    if (!hiddenState) return key.keyId;

    return key.keyId.replace(/.(?=.{4})/g, "*").slice(-20);
  };

  const formatDateTimeToReadable = (dateTime) => {
    const date = new Date(dateTime);
    return date.toLocaleString();
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error("Erreur lors de la copie du texte :", err);
    }
  };

  return (
    <Modal
      className="api-keys-modal"
      show={show}
      onHide={onClose}
      centered
      size="lg"
    >
      <div className="modal-header">
        <h2>API Keys</h2>
        <button onClick={onClose}>Close</button>
      </div>
      <div className="modal-tabs">
        <button
          onClick={() => handleChangeTab("creaxys")}
          className={activeTab === "creaxys" ? "active" : ""}
        >
          System Keys
        </button>
        <button
          onClick={() => handleChangeTab("tokenGroup")}
          className={activeTab === "tokenGroup" ? "active" : ""}
        >
          User Keys
        </button>
      </div>
      <div className="modal-body">
        {activeTab === "creaxys" && (
          <div className="modal-tab-content">
            <div className="api-keys-actions">
              <button
                className={`link-button ${isCreatingApiKey ? "loading" : ""}`}
                disabled={isCreatingApiKey}
                onClick={() => createCreaxysApiKey(deploymentId)}
              >
                {isCreatingApiKey && <FaSpinner />} Create New System Key
              </button>
            </div>
            <div className="api-keys-list">
              <table>
                <tr>
                  <th>Key ID</th>
                  <th className="field-status">Status</th>
                  <th>Created At</th>
                  <th className="field-actions"></th>
                </tr>
                {creaxysKeys.map((key) => (
                  <tr>
                    <td>
                      <span>
                        {hideKey(key, showApiKeyFor !== key.id)}
                        <IoCopyOutline
                          className="copy-icon"
                          onClick={() => {
                            copyToClipboard(key.keyId);
                          }}
                        />
                      </span>
                    </td>
                    <td className="text-center field-status">
                      {getStatusIcon(key.isActive)}
                    </td>
                    <td>{formatDateTimeToReadable(key.createdAt)}</td>
                    <td className="field-actions">
                      <BsThreeDotsVertical />
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        )}
        {activeTab === "tokenGroup" && activeTokenGroupModel && (
          <div className="modal-tab-content">
            <div className="api-keys-actions d-flex justify-content-between">
              <select
                className="select-token-group"
                value={activeTokenGroupModel.id}
                onChange={(e) =>
                  setActiveTokenGroupModel(
                    tokenGroupsModel.find(
                      (group) => group.id === e.target.value
                    )
                  )
                }
              >
                {tokenGroupsModel.map((group) => (
                  <option value={group.id}>{group.name}</option>
                ))}
              </select>

              <button
                className={`link-button ${isCreatingApiKey ? "loading" : ""}`}
                disabled={isCreatingApiKey}
                onClick={() =>
                  createTokenGroupApiKey(deploymentId, activeTokenGroupModel.id)
                }
              >
                {isCreatingApiKey && <FaSpinner />} Create New User Key
              </button>
            </div>
            <div className="api-keys-list">
              <table>
                <tr>
                  <th>Key ID</th>
                  <th className="field-status">Status</th>
                  <th>Custom Metadata</th>
                  <th>Created At</th>
                  <th className="field-actions"></th>
                </tr>

                {selectedTokenGroupApiKeys.map((key) => (
                  <tr>
                    <td>
                      {hideKey(key)}
                      <IoCopyOutline
                        className="copy-icon"
                        onClick={() => {
                          copyToClipboard(key.keyId);
                        }}
                      />
                    </td>
                    <td className="text-align field-status">
                      {getStatusIcon(key.isActive)}
                    </td>
                    <td>{key.custom_metadata || "-"}</td>
                    <td>{formatDateTimeToReadable(key.createdAt)}</td>
                    <td className="field-actions">
                      <BsThreeDotsVertical />
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ApiKeysModal;
