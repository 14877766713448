// This file contains AiTextGenerated reactjs component. This component is used to display the generated text from the GPT-3 model.
// It takes the following props: learningRecommendation, onlearningRecommendationDelete, onlearningRecommendationEdit, onlearningRecommendationSelect, onlearningRecommendationUnselect, isSelected.
// depending on the value value learningRecommendation.status it will render different steps. status are: "NOT_STARTED", "IN_PROGRESS", "DONE".
// depending on the value value learningRecommendation.learningName it will render a different question.

// Path: code-ai-tool/src/components/widgets/Recommendations/ProjectLearning.js

import React, { useState, useEffect } from "react";
import { getOpenAICompletion } from "../../../apis/openAiAPI";
import { Button, Card, Spinner } from "react-bootstrap";
import AiTextGenerated from "../AiTextGenerated";
// DB
import DB from "../../../database/DB";
// import { from "react-bootstrap";

// JS Doc for LearningRecommendation object type
/**
 *
 * @typedef {Object} LearningRecommendation
 * @property {string} id - the id of the learningRecommendation
 * @property {string} learningName - the name of the learningRecommendation
 *
 */

const ProjectLearning = ({ learningRecommendationId, projectId }) => {
  const [learningRecommendation, setLearningRecommendation] = useState({});
  const textPrompt = learningRecommendation.prompt;
  const [textToValidate, setTextToValidate] = useState("");

  // get learningRecommendation from DB
  useEffect(async () => {
    const unsubscribe = DB.project.getLearningRecommendationById(
      projectId,
      learningRecommendationId,
      (learningRecommendation) => {
        setLearningRecommendation(learningRecommendation);
      }
    );
    return unsubscribe;
  }, []);

  //get the text from openAiAPI
  const getText = async () => {
    try {
      const response = await getOpenAICompletion({
        prompt: textPrompt,
        model: "text-davinci-003",
        temperature: 0.7,
        max_tokens: 2400,
      });
      if (response.data.choices[0].text) {
        setTextToValidate(response.data.choices[0].text);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Card>
      <Card.Header as="h5">Improvement recommendation</Card.Header>
      {learningRecommendation.status === "NOT_STARTED" && (
        <>
          <Card.Body>
            <Card.Title>{learningRecommendation.title}</Card.Title>
            <Card.Text>{learningRecommendation.description}</Card.Text>
            {/* Actions */}
          </Card.Body>
          <Card.Footer>
            <Button variant="primary" onClick={getText}>
              Run
            </Button>
          </Card.Footer>
        </>
      )}
      {learningRecommendation.status === "IN_PROGRESS" ||
        (!learningRecommendation.status && (
          <Card.Body>
            <Card.Title>{learningRecommendation.title}</Card.Title>
            <Card.Text>{learningRecommendation.description}</Card.Text>
            <Spinner animation="grow" variant="warning" />
          </Card.Body>
        ))}
      {learningRecommendation.status === "DONE" && (
        <>
          <Card.Body>
            <Card.Title>{learningRecommendation.title}</Card.Title>
            <Card.Text>{learningRecommendation.description}</Card.Text>
          </Card.Body>
          <Card.Footer>
            <Button variant="primary" onClick={getText}>
              Accept
            </Button>
            <Button variant="primary" onClick={getText}>
              Relaunch
            </Button>
          </Card.Footer>
        </>
      )}

      {/* <Card.Body>
        <Card.Title>Special title treatment</Card.Title>
        <Card.Text>
          With supporting text below as a natural lead-in to additional content.
        </Card.Text>
        <Button variant="primary">Go somewhere</Button>
      </Card.Body> */}
    </Card>
  );
};

export default ProjectLearning;
