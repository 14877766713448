import React from "react";
import "./CreaxysConfigurationDetails.css"; // Assurez-vous d'inclure le chemin correct vers votre fichier CSS

const CreaxysConfigurationDetails = ({
  baseApiUrl = "{deploymentApiHost}",
  lite = false,
  ref = null
}) => {
  return (
    <div
      className={`creaxys-api-details-container ${lite ? "lite" : ""}`}
      ref={ref}
    >
      <p>
        Utilize the internal Creaxys API with your Creaxys account token. This
        is separate from the tokens generated for your system in the Token
        Groups.
      </p>
      <p>
        Your Creaxys API key allows for token creation and more. Access the
        Creaxys resource deployment token from your Creaxys account settings.
      </p>
      <div className="api-endpoint">
        <span className="fw-700">Base Path for Creaxys API:</span>
        <code>
          {`${baseApiUrl}/`}
          <strong>dep</strong>
        </code>
      </div>
      <p>
        This API is used for managing your system's access tokens and other
        configurations. It is a critical component for setting up and
        maintaining your Creaxys-based system.
      </p>
      <div className="api-headers">
        <span className="fw-700">Required Headers for requests:</span>
        <code>{`Authorization: Bearer {Your Creaxys API Key}`}</code>
      </div>
      <p>
        The header must contain a Creaxys API key that has access to the
        resource in question. Ensure your API key is included in the header in
        bearer token format.
      </p>
    </div>
  );
};

export default CreaxysConfigurationDetails;
