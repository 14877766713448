import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  query,
  orderBy,
  limit,
  where,
  onSnapshot,
  setDoc,
  updateDoc,
  getDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { firebaseDB, firebaseAuth } from "../api-connector/firebase";

/**
 * This files contains all the functions that interact with the firebase database using firebaseDB(getFirestore(firebaseApp)).
 * The functions are used to create, read, update and delete fineTune collections and documents.
 */

/**
 * @typedef {Object} TrainingData
 * @property {string} prompt - The prompt of the trainingData.
 * @property {string} completion - The completion of the trainingData.
 */

/**
 * @typedef {Object} TrainingFile
 * @property {string} name - The name of the trainingFile.
 * @property {string} path - The path of the trainingFile.
 * @property {string} type - The type of the trainingFile, must be "JSONL".
 * @property {string} date - The date of the trainingFile.
 * @property {[TrainingData]} trainingData - The list of training data.
 * @property {string} status - The status of the trainingFile. Can be "created", "uploadedStorage", "uploadedOpenAI", "training", "trained", "error", "
 */

/**
 * @typedef {Object} fineTune
 * @property {string} id - The id of the fineTune.
 * @property {string} name - The name of the fineTune.
 * @property {string} description - The description of the fineTune.
 * @property {string} createdAt - The date the fineTune was created.
 * @property {string} updatedAt - The date the fineTune was last updated.
 * @property {string} uid - The id of the user who created the fineTune.
 * @property {string} v - The version of the fineTune.
 * @property {string} modelId - The id of the model that the fineTune is based on.
 * @property {strinh} status - The status of the fineTune. Can be "created", "training", "trained", "finetuning", "finetuned", "error", ""
 * @property {[TrainingFile]} trainingFiles - The list of training files.
 */

const fineTuneCollection = collection(firebaseDB, "fineTunes");

const version = "1.0.0";

/**
 * Creates a fineTune in the database.
 * @param {fineTune} fineTune
 * @returns {string} The id of the fineTune.
 * @throws {Error} If the fineTune could not be created.
 */
export const createFineTune = async (fineTune) => {
  console.log("createFineTune", fineTune);
  try {
    const user = firebaseAuth.currentUser;
    const fineTuneDoc = await addDoc(fineTuneCollection, {
      ...fineTune,
      createdAt: serverTimestamp(),
      uid: user.uid,
      v: version,
    });
    console.log("Document written with ID: ", fineTuneDoc.id);
    return fineTuneDoc.id;
  } catch (e) {
    console.log(e);
  }
};

/**
 * Gets a fineTune from the database.
 * @param {string} fineTuneId - The id of the fineTune.
 * @param {function} setFineTune - The function to set the fineTune.
 * @returns {function} The unsubscribe function.
 * @throws {Error} If the fineTune could not be retrieved.
 * @throws {Error} If the fineTuneId is not provided.
 * @throws {Error} If the setFineTune function is not provided.
 *  */
export const getFineTune = (fineTuneId, setFineTune) => {
  if (!fineTuneId) {
    throw new Error("fineTuneId is required");
  }
  if (!setFineTune) {
    throw new Error("setFineTune is required");
  }
  const docRef = doc(firebaseDB, "fineTunes", fineTuneId);

  const unsubscribe = onSnapshot(docRef, (doc) => {
    if (doc.exists()) {
      setFineTune({ ...doc.data(), id: doc.id });
    } else {
      console.log("No such document!");
    }
  });

  return unsubscribe;
};

/**
 * getFineTunes
 * @param {function} setFineTunes
 * @returns {function} The unsubscribe function.
 */
export const getFineTunes = (setFineTunes) => {
  const user = firebaseAuth.currentUser;
  const q = query(
    fineTuneCollection,
    where("uid", "==", user.uid)
    // orderBy("createdAt", "desc"),
    // limit(10)
  );
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const fineTunes = [];
    querySnapshot.forEach((doc) => {
      fineTunes.push({ ...doc.data(), id: doc.id });
    });
    setFineTunes(fineTunes);
  });

  return unsubscribe;
};

/**
 * Update a fineTune in the database.
 * @param {string} fineTuneId - The id of the fineTune.
 * @param {fineTune} fineTune - The fineTune to update.
 * @returns {string} The id of the fineTune.
 * @throws {Error} If the fineTune could not be updated.
 * @throws {Error} If the fineTune is not provided.
 * @throws {Error} If the fineTune does not have an id.
 * @throws {Error} If the fineTune does not have a builtPromptId.
 **/
export const updateFineTune = async (fineTuneId, fineTune) => {
  if (!fineTuneId) {
    throw new Error("fineTuneId is required");
  }
  if (!fineTune) {
    throw new Error("fineTune is required");
  }
  if (!fineTune.id) {
    throw new Error("fineTune must have an id");
  }
  try {
    const docRef = doc(firebaseDB, "fineTunes", fineTuneId);
    await updateDoc(docRef, {
      ...fineTune,
      updatedAt: serverTimestamp(),
    });
    console.log("Document successfully updated!");
    return docRef.id;
  } catch (e) {
    console.log(e);
  }
};

/**
 * Deletes a fineTune from the database.
 * @param {string} fineTuneId - The id of the fineTune.
 * @returns {string} The id of the fineTune.
 * @throws {Error} If the fineTune could not be deleted.
 * @throws {Error} If the fineTuneId is not provided.
 * @throws {Error} If the fineTune does not have an id.
 * @throws {Error} If the fineTune does not have a builtPromptId.
 * */
export const deleteFineTune = async (fineTuneId) => {
  if (!fineTuneId) {
    throw new Error("fineTuneId is required");
  }
  try {
    const docRef = doc(firebaseDB, "fineTunes", fineTuneId);
    await deleteDoc(docRef);
    console.log("Document successfully deleted!");
    return docRef.id;
  } catch (e) {
    console.log(e);
  }
};

export default {
  get: getFineTune,
  list: getFineTunes,
  create: createFineTune,
  update: updateFineTune,
  delete: deleteFineTune,
};
