import React, { useEffect, useState } from "react";
import "./ApiEndpointDetails.css";
import { add } from "lodash";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { python } from "@codemirror/lang-python";
import { php } from "@codemirror/lang-php";
import { rust } from "@codemirror/lang-rust";
import { java } from "@codemirror/lang-java";
import { cpp } from "@codemirror/lang-cpp";
// import { ruby } from "@codemirror/lang-ruby";
import { csharp } from "@replit/codemirror-lang-csharp";
import GenerateCodeExample from "./GenerateCodeExample";

const addQueryParamsToExampleUrl = (url, queryParamsKeys) => {
  if (queryParamsKeys.length === 0) return url;

  let urlWithQueryParams = url + "?";

  queryParamsKeys.forEach((key, index) => {
    // variable name is value${keyWithFirstLetterUpperCase}
    const variableName = `value${key.charAt(0).toUpperCase() + key.slice(1)}`;
    if (index !== 0) urlWithQueryParams += "&";
    urlWithQueryParams += `${key}={${variableName}}`;
  });

  return urlWithQueryParams;
};

const languageMap = {
  javascript: javascript,
  python: python,
  csharp: csharp,
  arduino: cpp,
  php: php,
  ruby: javascript,
  go: javascript,
  swift: python,
  kotlin: java,
  rust: rust,
  typescript: javascript,
  dart: javascript,
  lua: javascript,
  elixir: javascript,
  scala: javascript,
  clojure: javascript,
  fsharp: javascript,
  r: javascript,
  perl: javascript,
  objectivec: javascript,
  julia: javascript,
  haskell: javascript,
  elm: javascript,
  erlang: javascript,
  ocaml: javascript,
  smalltalk: javascript,
  groovy: javascript
};

export const ApiEndpointDetails = ({
  endpointUrl,
  lite = false,
  title,
  method,
  headers,
  body,
  query,
  response,
  showQueryParams = false,
  exampleLanguage,
  languageLib
}) => {
  const [loadingNewLanguage, setLoadingNewLanguage] = useState(false);
  const [codeExample, setCodeExample] = useState("");

  useEffect(() => {
    if (!languageLib || !exampleLanguage) return;
    generateCodeExample();
  }, [exampleLanguage, languageLib]);

  const renderBodyWithTypesJSON = (body) => {
    let bodyString = "{\n";
    Object.keys(body).forEach((key, index) => {
      if (index > 0) {
        bodyString += ",\n";
      }
      bodyString += `  ${key}: ${body[key].type}`;
    });
    bodyString += "\n}";
    return bodyString;
  };

  const renderBodyDescriptions = (body) => {
    return Object.keys(body).map((key) => {
      return (
        <div key={key}>
          <span className="io-description-field">{key}: </span>
          <span className="io-description">{body[key].description}</span> <br />
        </div>
      );
    });
  };

  const renderCleanRequestHeaders = (headers) => {
    return Object.keys(headers).map((key) => {
      return (
        <div key={key}>
          <span className="io-description-field">{key}: </span>
          <span className="io-description">{headers[key]}</span> <br />
        </div>
      );
    });
  };

  const renderResponseWithTypesJSON = (response) => {
    let responseString = "{\n";
    Object.keys(response).forEach((key, index) => {
      if (index > 0) {
        responseString += ",\n";
      }
      responseString += `  ${key}: ${response[key].type}`;
    });
    responseString += "\n}";
    return responseString;
  };

  const renderResponseDescriptions = (response) => {
    return Object.keys(response).map((key) => {
      return (
        <div key={key}>
          <span className="io-description-field">{key}: </span>
          <span className="io-description">
            {response[key].description}
          </span>{" "}
          <br />
        </div>
      );
    });
  };

  const hasBodyDescriptions = Object.keys(body || {}).some(
    (key) => body[key].description
  );

  const hasQueryDescriptions = Object.keys(query || {}).some(
    (key) => query[key].description
  );

  const hasResponseDescriptions = Object.keys(response || {}).some(
    (key) => response[key].description
  );

  const finalUrl =
    query && showQueryParams
      ? addQueryParamsToExampleUrl(endpointUrl, Object.keys(query))
      : endpointUrl;

  const generateCodeExample = async () => {
    setLoadingNewLanguage(true);
    if (!languageLib || !exampleLanguage) return;
    const code = GenerateCodeExample(
      exampleLanguage,
      languageLib,
      endpointUrl,
      method,
      headers,
      body,
      query
    );
    // const code = languageLib[exampleLanguage];
    // const codeExample = code(endpointUrl, method, headers, body, query);
    await (() => new Promise((resolve) => setTimeout(resolve, 200)))();
    setCodeExample(code);
    setLoadingNewLanguage(false);
  };

  return (
    <div className={`api-details-container ${lite ? "lite" : ""}`}>
      <div className="api-detail-header">
        <div className="request-title-part fs-5 mb-2">
          <span className={`api-endpoint-method ${method.toLowerCase()}`}>
            {method}
          </span>
          {title}
        </div>
        <code className="api-endpoint-url">{finalUrl}</code>
      </div>

      {exampleLanguage && (
        <div className="code-example-container">
          <div className="code-example-header">
            <span className="request-title-part">
              Code Example in{" "}
              {exampleLanguage[0].toUpperCase() + exampleLanguage.slice(1)}{" "}
              using {languageLib}:
            </span>
          </div>
          <div
            className={`code-mirror-cutter ${
              loadingNewLanguage ? "loading" : ""
            }`}
          >
            {!loadingNewLanguage && (
              <CodeMirror
                extensions={[languageMap[exampleLanguage]()]}
                value={codeExample}
                theme={"dark"}
                basicSetup={{
                  lineNumbers: false
                }}
                editable={false}
              />
            )}
          </div>
        </div>
      )}

      <div className="endpoint-details api-detail-body">
        <div className="api-instructions">
          <span className="request-title-part">Request Headers:</span>
          <pre>{renderCleanRequestHeaders(headers)}</pre>

          {body && Object.keys(body).length > 0 && (
            <>
              <span className="request-title-part">Request Body:</span>
              <pre>{renderBodyWithTypesJSON(body)}</pre>
              {hasBodyDescriptions && (
                <div className="io-description-container">
                  {renderBodyDescriptions(body)}
                </div>
              )}
            </>
          )}

          {query && Object.keys(query).length > 0 && (
            <>
              <span className="request-title-part">Query Params:</span>
              <pre>{renderBodyWithTypesJSON(query)}</pre>
              {hasQueryDescriptions && (
                <div className="io-description-container">
                  {renderBodyDescriptions(query)}
                </div>
              )}
            </>
          )}
        </div>
        <div className="api-response">
          <span className="request-title-part">Expected response:</span>
          <pre>{renderResponseWithTypesJSON(response)}</pre>
          {hasResponseDescriptions && (
            <div className="io-description-container">
              {renderResponseDescriptions(response)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
