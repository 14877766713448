// MethodRulesScriptEditor.js
import React, { useEffect, useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { FiInfo } from "react-icons/fi";
import Tooltip from "../../../../helpers/Tooltip";
import { javascript } from "@codemirror/lang-javascript";
import { sublime } from "@uiw/codemirror-theme-sublime";
import "./MethodRulesScriptEditor.css";
// import loader icon from react-icons
import { FaSpinner } from "react-icons/fa";

const MethodRulesScriptEditor = ({
  script,
  onChange,
  selectedScriptId,
  scriptsList = [],
  onScriptSelected
}) => {
  const [scriptText, setScriptText] = useState("");
  const [changingScript, setChangingScript] = useState(false);
  const selectedScript = scriptsList.find((s) => s.id === selectedScriptId);

  useEffect(() => {
    if (selectedScript) {
      setScriptText(selectedScript.script);
    } else {
      setScriptText("");
    }
  }, [selectedScript]);

  const messageTooltip = selectedScript
    ? selectedScript.description
    : "Select a script to see its description";

  const scriptName = selectedScript ? selectedScript.name : "";

  return (
    <div
      className="method-rules-script-editor_container"
      style={{
        border: "1px solid var(--gray-color)",
        borderRadius: "4px",
        marginBottom: "20px"
      }}
    >
      <div className="method-rules-script-editor_header d-flex align-items-center">
        <Tooltip
          message={
            messageTooltip
              ? messageTooltip
              : "Select a script to see its description"
          }
        >
          <FiInfo size={24} style={{ marginLeft: "10px" }} />
        </Tooltip>
        {scriptName && scriptsList.length <= 1 && (
          <h4
            style={{ margin: "0", padding: "10px" }}
            className="fs-4 text-white fw-600"
          >
            {scriptName}
          </h4>
        )}
        {scriptsList.length > 1 && (
          <select
            className="script-select-api-transformer fs-4 text-white fw-600 m-2"
            value={selectedScriptId}
            onChange={(e) => {
              onScriptSelected(e.target.value);
            }}
          >
            {/* <option value="">Select a script</option> */}
            {scriptsList.map((s, i) => (
              <option key={i} value={s.id}>
                {s.name}
              </option>
            ))}
          </select>
        )}
      </div>
      {!changingScript && (
        <CodeMirror
          value={scriptText}
          height="100%"
          extensions={[javascript()]}
          theme={sublime}
          onChange={(value) => {
            setScriptText(value);
          }}
          onBlur={() => {
            onChange(scriptText);
          }}
        />
      )}
      {changingScript && (
        <div className="loader-container">
          <FaSpinner size={40} />
        </div>
      )}
    </div>
  );
};

export default MethodRulesScriptEditor;
