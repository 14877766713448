import React, { useContext, useEffect, useState } from "react";
// import { getNewProject, GlobalContext } from "../../contexts/GlobalContext";
import { GlobalContext } from "../../contexts/GlobalContext";
import { useNotif } from "../zones/NotifZone";
import DB from "../../database/DB";
import {
  Form,
  Button,
  Badge,
  Container,
  Modal,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// Modals that show json-project.json file as follow:
// {
//    "user" : {
//       uid: project.uid,
//    },
//    "project" : {
//       id: project.id,
//    },
// }
const ModalProjectConfigJSON = ({ project }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const jsonObj = {
    user: {
      uid: project.uid,
    },
    project: {
      id: project.id,
    },
    ignore: ["node_modules"],
  };

  return (
    <>
      <Button variant="link" onClick={handleShow}>
        Get Config
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Project config JSON</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="bg-light p-3 rounded">
            <pre>{JSON.stringify(jsonObj, null, 2)}</pre>
          </div>
          <p>
            Or with command line:
            <div class="p-1 bg-light rounded">
              <code>
                echo '{JSON.stringify(jsonObj, null, 2)}' > project-config.json
              </code>
            </div>
          </p>
          <hr />
          {/* Documentation on CLI called bb-cli-code, contains link to download download-install-global.sh which will download bb-cli-code by himself */}
          <div>
            <h5 className="mb-3">Connect your folder to this project</h5>
            <div>
              <h6>Install the CLI</h6>
              <p className="mt-3">
                Download the CLI by clicking{" "}
                <a
                  className="text-secondary"
                  role="button"
                  href="https://firebasestorage.googleapis.com/v0/b/ai-engine-dashboard.appspot.com/o/dw%2Fcli%2Fbb-cli-code?alt=media&token=7244271b-1f2b-4a23-bc3a-7fef18b2a53b"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
              </p>
              <p className="mt-3">
                Make it accessible globally by running the following command:
              </p>
              <div className="bg-light p-3 rounded">
                <code>
                  cp ./bb-cli-code /usr/local/bin/bb-cli-code <br />
                  chmod +x /usr/local/bin/bb-cli-code <br /> <br />
                  # Uninstall <br />
                  rm /usr/local/bin/bb-cli-code
                </code>
              </div>
            </div>
            <hr />
            <div>
              <h6>Use bb-cli-code CLI</h6>
              <code class="p-1 bg-light">bb-cli-code [command]</code>
              <p className="mt-2">
                Commands:
                <ul>
                  <li>
                    <code class="p-1 bg-light">export|e</code> - Export files
                    and folders to Firestore and update them remotely
                  </li>
                  <li>
                    <code class="p-1 bg-light">import|i</code> - Import files
                    and folders from Firestore and update them locally
                  </li>
                  <li>
                    <code class="p-1 bg-light">listen|l</code> - Listen files
                    and folders locally and update them remotely
                  </li>
                  <li>
                    <code class="p-1 bg-light">help|h</code> - Display help
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const ProjectSelectorConfigForm = ({ projects, currentProject }) => {
  const { notify } = useNotif();
  const navigate = useNavigate();
  // const [projects, setProjects] = React.useState([]);
  // const [projectFormValues, setProjectFormValues] = useState(getNewProject());
  const [projectFormValues, setProjectFormValues] = useState({});
  const {
    name,
    descriptionApp,
    descriptionCompany,
    copywritingTags = [],
    programmingLanguages = [],
    libraries = [],
    audiences = [],
    features = [],
  } = projectFormValues;
  const isExistingProject = currentProject ? true : false;

  useEffect(() => {
    if (currentProject) {
      setProjectFormValues(currentProject);
    } else {
      // setProjectFormValues(getNewProject());
      setProjectFormValues({});
    }
  }, [currentProject]);

  // get projects from DB
  // useEffect(() => {
  //   try {
  //     const getProjectsUnsubscribe = DB.project.getProjects(setProjects);
  //     return getProjectsUnsubscribe;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }, []);

  // handle changes of inputs
  const handleProjectFormValues = (newProjectFormValues) => {
    setProjectFormValues(newProjectFormValues);
  };

  const handleChange = (e) => {
    handleProjectFormValues({
      ...projectFormValues,
      [e.target.name]: e.target.value,
    });
  };

  // handle new copywritingTags
  const handleCopywritingTag = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      handleProjectFormValues({
        ...projectFormValues,
        copywritingTags: [...projectFormValues.copywritingTags, e.target.value],
      });
      // clean input
      e.target.value = "";
    }
  };

  const handleAudiences = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleProjectFormValues({
        ...projectFormValues,
        audiences: [...projectFormValues.audiences, e.target.value],
      });
      // clean input
      e.target.value = "";
    }
  };

  const handleFeatures = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      handleProjectFormValues({
        ...projectFormValues,
        features: [...projectFormValues.features, e.target.value],
      });
      // clean input
      e.target.value = "";
    }
  };

  // handle new programmingLanguages
  const handleProgrammingLanguage = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      handleProjectFormValues({
        ...projectFormValues,
        programmingLanguages: [
          ...projectFormValues.programmingLanguages,
          e.target.value,
        ],
      });
      // clean input
      e.target.value = "";
    }
  };

  // handle new libraries
  const handleLibrary = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      handleProjectFormValues({
        ...projectFormValues,
        libraries: [...projectFormValues.libraries, e.target.value],
      });
      // clean input
      e.target.value = "";
    }
  };

  // handle restart project
  const handleRestartProject = () => {
    // set project to initial state
  };

  const changeProject = (e) => {
    if (e.target.value === "new") {
      navigate(`/project-configuration`);
    } else {
      navigate(`/project-configuration/${e.target.value}`);
    }
  };

  const handleDeleteProject = async () => {
    if (currentProject) {
      try {
        await DB.project.deleteProject(currentProject.id);
        notify({
          title: "Project deleted",
          message: "Project deleted at  " + new Date().toLocaleString(),
        });
        navigate(`/project-configuration`);
      } catch (error) {
        console.error(error);
      }
    }
  };

  // handle save project
  const handleSaveProject = async () => {
    // save project to DB
    if (isExistingProject && currentProject) {
      try {
        await DB.project.updateProject({
          ...currentProject,
          ...projectFormValues,
        });
        notify({
          title: "Project updated",
          message: "Project at  " + new Date().toLocaleString(),
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        await DB.project.createProject(projectFormValues);
        notify({
          title: "Project created",
          message: "Project created at  " + new Date().toLocaleString(),
        });
        // setProjectFormValues(getNewProject());
        setProjectFormValues({});
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Card>
      <Card.Header>
        {/* React-bootstrap select allows to chose between creating new project or chosing exisgin ones */}
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Control
            onChange={changeProject}
            as="select"
            value={currentProject ? currentProject.id : "new"}
          >
            <option value={"new"}>Create new project</option>
            {projects.map((project) => (
              <option value={project.id} key={project.id}>
                {project.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Card.Header>
      <Card.Body>
        <Form>
          <Container>
            <Row>
              <Col>
                <Form.Group controlId="formBasicName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Project name"
                    name="name"
                    value={name}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formBasicDescriptionApp">
                  <Form.Label>Description App</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="App description"
                    name="descriptionApp"
                    value={descriptionApp}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formBasicDescriptionCompany">
                  <Form.Label>Description Company</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Company description"
                    name="descriptionCompany"
                    value={descriptionCompany}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Group controlId="formBasicAudiences">
                  <Form.Label>Audiences</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter an audience"
                    name="audiences"
                    onKeyPress={handleAudiences}
                  />
                  {audiences &&
                    audiences.map((audience, index) => (
                      <Badge key={index} className="ml-2" variant="secondary">
                        {audience}
                      </Badge>
                    ))}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formBasicCopywritingTags">
                  <Form.Label>Copywriting Tags</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter a copywriting tag"
                    name="copywritingTags"
                    onKeyPress={handleCopywritingTag}
                  />
                  {copywritingTags &&
                    copywritingTags.map((tag, index) => (
                      <Badge key={index} className="ml-2" variant="secondary">
                        {tag}
                      </Badge>
                    ))}
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId="formBasicFeatures">
                  <Form.Label>Features</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter a feature"
                    name="features"
                    onKeyPress={handleFeatures}
                  />
                  {features &&
                    features.map((feature, index) => (
                      <Badge key={index} className="ml-2" variant="secondary">
                        {feature}
                      </Badge>
                    ))}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Group controlId="formBasicProgrammingLanguages">
                  <Form.Label>Programming Languages</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter a programming language"
                    name="programmingLanguages"
                    onKeyPress={handleProgrammingLanguage}
                  />
                  {programmingLanguages &&
                    programmingLanguages.map((language, index) => (
                      <Badge key={index} className="ml-2" variant="secondary">
                        {language}
                      </Badge>
                    ))}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formBasicLibraries">
                  <Form.Label>Libraries</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter a library"
                    name="libraries"
                    onKeyPress={handleLibrary}
                  />
                  {libraries &&
                    libraries.map((library, index) => (
                      <Badge key={index} className="ml-2" variant="secondary">
                        {library}
                      </Badge>
                    ))}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col></Col>
              <Col></Col>
              <Col className="d-flex justify-content-end">
                {/* <Button
                  className="mx-2"
                  variant="link"
                  type="button"
                  onClick={handleRestartProject}
                >
                  Restart Project
                </Button> */}
                {isExistingProject && currentProject && (
                  <ModalProjectConfigJSON project={currentProject} />
                )}
                {isExistingProject && (
                  <Button
                    className="mx-2"
                    variant="danger"
                    type="button"
                    onClick={handleDeleteProject}
                  >
                    Delete Project
                  </Button>
                )}
                {isExistingProject && (
                  <Button
                    variant="info"
                    type="button"
                    onClick={handleSaveProject}
                  >
                    Save Project
                  </Button>
                )}
                {!isExistingProject && (
                  <Button
                    variant="info"
                    type="button"
                    onClick={handleSaveProject}
                  >
                    Create Project
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ProjectSelectorConfigForm;
