import React, { useState, useEffect, useContext } from "react";
import AuthChecker from "./middleware-components/AuthChecker";
import "./MiddlewareItem.css";

// import react icons for : clean middleware type like a left arrow meaning going back to middleware initial config but also clean
import { FaArrowLeft } from "react-icons/fa";

import { DeploymentModelContext } from "../../../../../contexts/DeploymentModelContext";
import RateLimiter from "./middleware-components/RateLimiter";
import CorsHandler from "./middleware-components/CorsHandler";

const MiddlewareItem = ({
  component,
  onComponentChange,
  onRemoveComponent
}) => {
  const { deploymentModel } = useContext(DeploymentModelContext);
  const [copyComponent, setCopyComponent] = useState(component);

  const tokenGroups = deploymentModel?.tokenGroups || [];

  const middlewareComponentTypes = [
    {
      name: "Auth Checker",
      value: "authChecker"
    },
    {
      name: "Rate Limiter",
      value: "rateLimiter"
    },
    {
      name: "CORS Handler",
      value: "corsHandler"
    }
    // {
    //   name: "Redirector",
    //   value: "redirector"
    // },
    // {
    //   name: "Data Validator",
    //   value: "dataValidator"
    // },
    // {
    //   name: "Logger",
    //   value: "logger"
    // },
    // {
    //   name: "JSON Result Handler",
    //   value: "jsonResultHandler"
    // },
    // {
    //   name: "Response Handler",
    //   value: "responseHandler"
    // },
    // {
    //   name: "Error Handler",
    //   value: "errorHandler"
    // },
    // {
    //   name: "Response Compressor",
    //   value: "responseCompressor"
    // },
    // {
    //   name: "Response Cacher",
    //   value: "responseCacher"
    // },
    // {
    //   name: "Session Manager",
    //   value: "sessionManager"
    // }
    // ... autres types
  ];

  const onMiddlewareChange = (newMiddleware) => {
    setCopyComponent({ ...component, ...newMiddleware });
    onComponentChange({ ...component, ...newMiddleware });
  };

  const returnSelectedMiddlewareComponent = () => {
    const middlewareType = copyComponent.middlewareType;

    switch (middlewareType) {
      case "authChecker":
        return (
          <AuthChecker
            middleware={copyComponent}
            onMiddlewareChange={onMiddlewareChange}
            tokenGroups={tokenGroups}
          />
        );
      case "rateLimiter":
        return (
          <RateLimiter
            middleware={copyComponent}
            onMiddlewareChange={onMiddlewareChange}
            tokenGroups={tokenGroups}
          />
        );
      case "corsHandler":
        return (
          <CorsHandler
            middleware={copyComponent}
            onMiddlewareChange={onMiddlewareChange}
          />
        );
      case "redirector":
        return <div>Redirection</div>;
      case "dataValidator":
        return <div>Data Validation</div>;
      case "logger":
        return <div>Logging</div>;
      case "errorHandler":
        return <div>Error Handling</div>;
      case "responseCompressor":
        return <div>Compression</div>;
      case "responseCacher":
        return <div>CACHE</div>;
      case "sessionManager":
        return <div>Session Management</div>;
      default:
        return null;
    }
  };

  // Gestion de la modification du nom
  const handleNameChange = (e) => {
    setCopyComponent({ ...component, name: e.target.value });
  };

  const onBlur = () => {
    onComponentChange({ ...component, name: copyComponent.name });
  };

  // Gestion de la sélection d'un middleware
  const handleMiddlewareTypeSelection = (e) => {
    const newComponent = {
      ...component,
      middlewareType: e.target.value
    };
    setCopyComponent(newComponent);
    onComponentChange({ ...component, middlewareType: e.target.value });
  };

  const clearMiddlewareType = () => {
    setCopyComponent({ ...component, middlewareType: "" });
    onComponentChange({ ...component, middlewareType: "" });
  };

  // Effet pour mettre à jour l'état local si le prop `component` change
  useEffect(() => {
    setCopyComponent(component);
  }, [component]);

  const isMiddlewareDefined = !!copyComponent.middlewareType;

  return (
    <div className="middleware-item">
      {!isMiddlewareDefined && (
        <>
          <input
            type="text"
            value={copyComponent.name || ""}
            onBlur={onBlur}
            onChange={handleNameChange}
            placeholder="Middleware Name"
          />
          <select
            value={copyComponent.middlewareType}
            onChange={handleMiddlewareTypeSelection}
          >
            <option value="">Select Middleware Type</option>
            {middlewareComponentTypes.map((mddlCompType) => (
              <option key={mddlCompType.value} value={mddlCompType.value}>
                {mddlCompType.name}
              </option>
            ))}
          </select>
          <div className="delete-button-container">
            <span
              className="delete-button"
              role="button"
              onClick={() => onRemoveComponent(component.id)}
            >
              Delete
            </span>
          </div>
        </>
      )}
      {isMiddlewareDefined && (
        <>
          <div className="middleware-header">
            <div
              className="middleware-back-button"
              onClick={clearMiddlewareType}
            >
              <FaArrowLeft />
            </div>
            <div className="middleware-name">{copyComponent.name}</div>
            <div className="middleware-type">
              {copyComponent.middlewareType}
            </div>
          </div>
        </>
      )}

      {/* Ajouter des sous-composants pour les options de middleware si nécessaire */}
      {returnSelectedMiddlewareComponent()}
    </div>
  );
};

export default MiddlewareItem;
