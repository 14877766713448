import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

const ClickableTextInput = ({ value, onChange, ...props }) => {
  const inputRef = React.useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const { textclassname = "", iconEdit } = props;

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleOnBlur = () => {
    setIsEditing(false);
    onChange(inputValue);
  };

  const onEnter = (e) => {
    if (e.key === "Enter") {
      handleOnBlur();
    }
  };

  return (
    <div
      className={`clickable-text-input d-flex align-items-center ${props.textclassname}`}
    >
      <Form.Control
        {...props}
        ref={inputRef}
        autoFocus
        onKeyDown={onEnter}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onBlur={handleOnBlur}
        className={`${!isEditing && "d-none"} ${
          props.className
        } ${textclassname}`}
      />
      <div
        className={`${isEditing && "d-none"} ${textclassname}`}
        onClick={() => {
          setIsEditing(true);
        }}
      >
        {/* {(<span className="text-gray-500">{value}</span>) || (
              <span className="placeholder-span">{props.placeholder}</span>
            ) ||
            "Edit"} */}
        {(value && (
          <span className="clickable-text-input-value fw-bold text-truncate">
            {value}
          </span>
        )) || (
          <span className="placeholder-span">
            {props.placeholder || "Edit"}
          </span>
        )}
      </div>
      {iconEdit && iconEdit}
    </div>
  );
};

export default ClickableTextInput;
