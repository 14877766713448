//openAiAPI.js
import axios from "axios";
import { firebaseAuth } from "../api-connector/firebase";

const p = {
  complete: `${process.env.REACT_APP_API_URL}/openai/m/complete`,
  chainedBuilt: {
    execute: (id) =>
      `${process.env.REACT_APP_API_URL}/openai/chainedBuilt/${id}/execute`
  },
  models: `${process.env.REACT_APP_API_URL}/openai/m/models`,
  transcript: `${process.env.REACT_APP_API_URL}/openai/m/transcript`,
  translateToEn: `${process.env.REACT_APP_API_URL}/openai/m/translateToEnglish`,
  finetuning: {
    models: `${process.env.REACT_APP_API_URL}/openai/finetuning/models`,
    list: `${process.env.REACT_APP_API_URL}/openai/finetuning/list`,
    upload: `${process.env.REACT_APP_API_URL}/openai/finetuning/upload`,
    tune: `${process.env.REACT_APP_API_URL}/openai/finetuning/tune`,
    retrieve: (fineTuneId, fileTrainingId) =>
      `${process.env.REACT_APP_API_URL}/openai/finetuning/retrieve/${fineTuneId}/${fileTrainingId}`
  }
};

export const getModels = async () => {
  try {
    const headers = await getHeaders();
    const result = await axios.get(p.models, headers);
    return result.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

async function retry_deprecated(fn, maxRetries = 3) {
  let attempts = 0;

  while (attempts < maxRetries) {
    try {
      return await fn();
    } catch (error) {
      if (error.status === 429) {
        const waitTime = Math.pow(2, attempts) * 1000; // Exponential backoff
        attempts++;
        await new Promise((resolve) => setTimeout(resolve, waitTime));
      } else {
        throw error;
      }
    }
  }

  throw new Error("Max retries exceeded");
}

const getFirebaseToken = async () => {
  const token = await firebaseAuth.currentUser.getIdToken();
  return token;
};

const getHeaders = async () => {
  const token = await getFirebaseToken();
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
};

export const transcript = async (formData) => {
  try {
    const headers = await getHeaders();
    const result = await axios.post(p.transcript, formData, headers);
    console.log("result transcript", result);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const translateToEn = async (text) => {
  try {
    const headers = await getHeaders();
    const result = await axios.post(
      p.translateToEn,
      {
        text
      },
      headers
    );
    console.log("result translateToEn", result);
    return result;
  } catch (error) {
    console.log(error);
  }
};

const CHAT_MODELS = [
  "gpt-4",
  "gpt-3.5-turbo",
  "gpt-3.5-turbo-16k",
  "gpt-4-32k",
  "gpt-3.5-turbo-0613",
  "gpt-4-0613",
  "gpt-3.5-turbo-16k-0613",
  "gpt-4-1106-preview",
  "gpt-4-0125-preview",
  "gpt-4-turbo-preview"
];
const ONLY_COMPLETION_MODELS = ["text-davinci-003", "code-davinci-002"];

async function retry(fn, errorHandling = () => {}, maxRetries = 3) {
  let attempts = 0;

  while (attempts <= maxRetries) {
    try {
      return await fn();
    } catch (errorResponse) {
      let errorHandlingObject = {
        errorResponse,
        type: "retry",
        attempts: attempts + 1
      };
      console.log("error in retry", errorResponse);

      errorHandlingObject.errorBody = await errorResponse.json();

      console.log("errorResponse in retry", errorResponse);

      if (errorResponse.status === 429) {
        errorHandlingObject.type = "tooManyRequests";
        const waitTime = Math.pow(4, attempts + 1) * 1000; // Exponential backoff
        attempts++;
        if (attempts <= maxRetries) {
          errorHandlingObject.isRetrying = true;
          errorHandling(errorHandlingObject);
          await new Promise((resolve) => setTimeout(resolve, waitTime));
        }
      } else if (errorResponse.status >= 400) {
        errorHandlingObject.type = "internalServerError";
        const waitTime = Math.pow(4, attempts + 1) * 1000; // Exponential backoff

        attempts++;
        if (attempts <= maxRetries) {
          errorHandlingObject.isRetrying = true;
          errorHandling(errorHandlingObject);
          await new Promise((resolve) => setTimeout(resolve, waitTime));
        }
      } else {
        throw errorResponse;
      }
    }
  }

  errorHandling({
    type: "maxRetriesExceeded",
    attempts
  });
  throw new Error("Max retries exceeded");
}

export const getOpenAICompletion = async (params, options = {}) => {
  const fn = async () => {
    const { headers: authHeaders } = await getHeaders();

    const { messages, prompt, model = "gpt-4", functions } = params;
    const { stream = false } = options;

    let bodyExtention = {};

    if (CHAT_MODELS.includes(model)) {
      bodyExtention = {
        messages
      };
      if (functions && functions.length > 0) {
        bodyExtention.functions = functions;
      }
    } else if (ONLY_COMPLETION_MODELS.includes(model)) {
      bodyExtention = {
        prompt
      };
    }

    const response = await fetch(p.complete, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...authHeaders
      },
      body: JSON.stringify({
        model,
        stream: options.stream,
        ...bodyExtention
      }),
      credentials: "omit"
    });

    if (response.status >= 400) {
      throw response;
    }

    if (!options.stream) {
      const data = await response.json();
      const { text } = data.choices[0];
      // const { text } = response.data.choices[0];
      return text ? text.trim() : "";
    } else {
      const reader = response.body.getReader();
      // const stream = response.data;
      const { onDataStream, onErrorStream, onDataStreamEnd } = options;

      const finalData = await (async () => {
        const dataChunksStr = [];

        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            if (onDataStreamEnd) onDataStreamEnd(dataChunksStr.join("").trim());
            return dataChunksStr;
          }
          const text = new TextDecoder().decode(value);
          dataChunksStr.push(text);
          if (onDataStream) onDataStream(text, dataChunksStr.join(""));
        }
      })();
      return finalData.join("").trim();
    }
  };

  return await retry(fn, options.errorHandling);
};

export const getOpenAICompletion_deprecated = async (params, options = {}) => {
  // const { stream = false } = options;

  let fn = async () => {
    try {
      const headers = await getHeaders();
      const result = await axios.post(
        p.complete,
        {
          model: params.model,
          temperature: 0.7,
          ...params
        },
        headers
      );
      console.log("result openai", result);
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  return await retry(fn);
};

export const executeChainedBuilt = async (id, values) => {
  try {
    const headers = await getHeaders();
    console.log("values executeChainedBuilt", values);
    const result = await axios.post(
      p.chainedBuilt.execute(id),
      {
        values
      },
      headers
    );
    console.log("result executeChainedBuilt", result);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getOpenAIModelsFinetuning = async () => {
  try {
    const headers = await getHeaders();
    const result = await axios.get(p.finetuning.models, headers);
    // console.log("result getOpenAIModelsFinetuning", result);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const getOpenAIFinetuningList = async () => {
  try {
    const headers = await getHeaders();
    const result = await axios.get(p.finetuning.list, headers);
    // console.log("result getOpenAIFinetuningList", result);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const uploadJSONLtoOpenAI = async (params) => {
  try {
    const headers = await getHeaders();
    const result = await axios.post(p.finetuning.upload, params, headers);
    // console.log("result uploadJSONLtoOpenAI", result);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const tuneModel = async (params) => {
  try {
    const headers = await getHeaders();
    const result = await axios.post(p.finetuning.tune, params, headers);
    // console.log("result trainOpenAIModelsFinetuning", result);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getOpenAIFinetuning = async (fineTuneId, fileTrainingId) => {
  try {
    const headers = await getHeaders();
    const result = await axios.get(
      p.finetuning.retrieve(fineTuneId, fileTrainingId),
      headers
    );
    // console.log("result getOpenAIFinetuning", result);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export default {
  complete: getOpenAICompletion,
  transcript,
  translateToEn,
  models: getModels,
  chainedBuilt: {
    execute: executeChainedBuilt
  },
  finetuning: {
    models: getOpenAIModelsFinetuning,
    list: getOpenAIFinetuningList,
    tune: tuneModel,
    upload: uploadJSONLtoOpenAI,
    retrieve: getOpenAIFinetuning
  }
};
