import React from "react";
import { FaAws, FaGoogle } from "react-icons/fa";
// Find a convenient icon for "Ressources Gérées par Creaxys"
import { RiCloudFill } from "react-icons/ri";

const DeploymentSidebar = ({ onSelectResourceType }) => {
  return (
    <div className="deployment-sidebar">
      <button onClick={() => onSelectResourceType("managed")}>
        <RiCloudFill /> Ressources Gérées par Creaxys
      </button>
      <button onClick={() => onSelectResourceType("googlecloud")}>
        <FaGoogle /> Ressources Google Cloud
      </button>
      <button onClick={() => onSelectResourceType("aws")}>
        <FaAws /> Ressources AWS
      </button>
    </div>
  );
};

export default DeploymentSidebar;
