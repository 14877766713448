// src/components/HeaderComponent.js
import React from "react";
import { Navbar, Nav, Button } from "react-bootstrap";

const HeaderComponent = ({ editorMode, setEditorMode }) => {
  const toggleEditorMode = () => {
    setEditorMode(!editorMode);
  };

  return (
    <Navbar bg="primary" variant="dark" expand="lg" className="">
      <Navbar.Brand href="#">WebApp Builder</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Button
            className="ml-3"
            variant={editorMode ? "success" : "info"}
            onClick={toggleEditorMode}
          >
            {editorMode ? "Editor Mode" : "Test Mode"}
          </Button>
        </Nav>
        <Nav>
          <Nav.Link href="#">Account</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default HeaderComponent;
