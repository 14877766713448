import React, { useState, useEffect, useRef } from "react";
import { Button, Popover, OverlayTrigger, Overlay } from "react-bootstrap";
// import react-icons for runTransformScript checkbox
import { FiSquare, FiCheckSquare } from "react-icons/fi";
import "./ResponseTransform.css";
import MethodRulesScriptEditor from "./ResponseTransform/MethodRulesScriptEditor";

// Typing reminder
// export type ResultReturnRule = {
//   parentType?: "object" | "array";
//   type: "string" | "number" | "boolean" | "object" | "array";
//   key?: string; // quand resultType est "object"
//   index?: number; // quand resultType est "array"
//   sub?: ResultReturnRule[];
//   keyRenamed?: string;
//   convertTo?: "string" | "number" | "boolean" | "object" | "array";
// };

// export type ApiConnectorMethod = {
//   id: string;
//   name: string;
//   description: string;
//   group?: string;
//   path: string;
//   httpMethod: "GET" | "POST" | "PUT" | "DELETE";
//   queries: Query[];
//   parameters: Parameter[];
//   headers: Header[];
//   results: Result[];
//   importedQueriesVariables?: Variable[];
//   importedParametersVariables?: Variable[];
//   importedPathVariables?: Variable[];
//   importedHeadersVariables?: Variable[];
//   resultReturnRules: ResultReturnRule[];
//   resultType: "object" | "array" | "string" | "number" | "boolean";
// };

const ElementInfo = ({
  type,
  data,
  path,
  parentType,
  parentWrapperType,
  parentPath,
  rules,
  arrayIndex,
  objectKey,
  responseType,
  onApplyRule
}) => {
  // Logique pour gérer les actions du popover
  return (
    <Popover className="element-options-popover">
      <Popover.Body>
        <p>Type: {type}</p>
        <p>Path: result{path}</p>
      </Popover.Body>
    </Popover>
  );
};

const SimpleOverlay = ({ placement, children, onClickedOutside }) => {
  const overlayRef = useRef(null);

  // manage click outside

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        onClickedOutside();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClickedOutside]);

  // Style de base pour le placement de l'overlay
  const placementStyles = {
    top: { top: "20px", left: "50%", transform: "translateX(-50%)" },
    bottom: { bottom: 0, left: "50%", transform: "translateX(-50%)" },
    left: { left: 0, top: "50%", transform: "translateY(-50%)" },
    right: { right: 0, top: "50%", transform: "translateY(-50%)" },
    topRight: { top: "20px", left: 0 }
  };

  const overlayStyles = {
    position: "absolute",
    zIndex: 1050, // Assurez-vous que cela est au-dessus de tout autre contenu
    ...placementStyles[placement] // Applique le style de placement
    // Ajoutez d'autres styles pour l'overlay ici si nécessaire
  };

  return (
    <div style={overlayStyles} ref={overlayRef}>
      {children}
    </div>
  );
};

const ElementOverlayTrigger = ({
  children,
  type,
  data,
  path,
  responseType,
  onApplyRule,
  parentPath,
  rules,
  childrenClassName = "",
  parentType,
  parentWrapperType,
  arrayIndex,
  objectKey
}) => {
  const [show, setShow] = useState(false);
  const targetRef = useRef(null);

  return (
    <span style={{ position: "relative" }}>
      <span
        onClick={() => setShow(!show)}
        ref={targetRef}
        className={childrenClassName}
      >
        {children}
      </span>
      {show && (
        <SimpleOverlay
          placement="topRight"
          target={targetRef.current}
          onClickedOutside={() => setShow(false)}
        >
          <ElementInfo
            type={type}
            data={data}
            path={path}
            responseType={responseType}
            onApplyRule={onApplyRule}
            parentPath={parentPath}
            rules={rules}
            parentType={parentType}
            parentWrapperType={parentWrapperType}
            arrayIndex={arrayIndex}
            objectKey={objectKey}
          />
        </SimpleOverlay>
      )}
    </span>
  );
};

const getType = (value) => {
  if (Array.isArray(value)) {
    return "array";
  } else if (value !== null && typeof value === "object") {
    return "object";
  } else if (typeof value === "string") {
    return "string";
  } else if (typeof value === "number") {
    return "number";
  } else if (typeof value === "boolean") {
    return "boolean";
  } else {
    return "unknown";
  }
};

const ApiResponseVisualizerAndSelector = ({
  apiResponse,
  onAddRule,
  responseType,
  rules = {}
}) => {
  const [selectedElement, setSelectedElement] = useState(null);

  const handleElementClick = (e, element, path) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedElement({ element, path });
  };

  const isElementSelected = (element, path) => {
    return (
      !!selectedElement &&
      selectedElement.element === element &&
      selectedElement.path === path
    );
  };

  const renderApiResponse = (
    data,
    path = "",
    parentType = null,
    parentWrapperType = null,
    parentPath = "",
    arrayIndex = null,
    objectKey = null
  ) => {
    if (Array.isArray(data)) {
      const itemsToShow = data;
      // const itemsToShow = data.slice(0, 3);
      // const moreCount = data.length > 3 ? `${data.length - 3} more...` : null;
      return (
        <>
          <span>
            <ElementOverlayTrigger
              type="array"
              data={data}
              path={path}
              responseType={responseType}
              onApplyRule={onAddRule}
              parentType={parentType}
              parentPath={parentPath}
              parentWrapperType={parentWrapperType}
              rules={rules}
              arrayIndex={arrayIndex}
              objectKey={objectKey}
            >
              <span className="api-response-brackets">{"["}</span>
            </ElementOverlayTrigger>
          </span>
          <br />
          {itemsToShow.map((item, index) => (
            <div key={index} className="api-response-element">
              {renderApiResponse(
                item,
                `${path}[${index}]`,
                "array",
                parentType,
                path,
                index
              )}
              {index < itemsToShow.length - 1 ? "," : ""}
              <br />
            </div>
          ))}
          {/* {moreCount && (
            <div className="api-response-element">... {moreCount}</div>
          )} */}
          <span>
            <ElementOverlayTrigger
              type="array"
              data={data}
              path={path}
              responseType={responseType}
              onApplyRule={onAddRule}
              parentType={parentType}
              parentWrapperType={parentWrapperType}
              parentPath={parentPath}
              rules={rules}
              arrayIndex={arrayIndex}
              objectKey={objectKey}
            >
              <span className="api-response-brackets">{"]"}</span>
            </ElementOverlayTrigger>
          </span>
        </>
      );
    } else if (typeof data === "object" && data !== null) {
      return (
        <>
          <span>
            <ElementOverlayTrigger
              type="object"
              data={data}
              path={path}
              responseType={responseType}
              onApplyRule={onAddRule}
              parentType={parentType}
              parentWrapperType={parentWrapperType}
              parentPath={parentPath}
              rules={rules}
              arrayIndex={arrayIndex}
              objectKey={objectKey}
            >
              <span className="api-response-brackets">{"{"}</span>
            </ElementOverlayTrigger>
          </span>
          <br />
          {Object.keys(data).map((key, index, array) => (
            <div key={key} className="api-response-element">
              <span>
                <ElementOverlayTrigger
                  type={getType(data[key])}
                  data={data[key]}
                  path={`${path}.${key}`}
                  responseType={responseType}
                  onApplyRule={onAddRule}
                  parentType={"object"}
                  parentWrapperType={parentWrapperType}
                  parentPath={path}
                  rules={rules}
                  arrayIndex={arrayIndex}
                  objectKey={key}
                >
                  <span className="api-response-key">{key}</span>
                </ElementOverlayTrigger>
              </span>
              :{" "}
              {renderApiResponse(
                data[key],
                `${path}.${key}`,
                "object",
                parentWrapperType,
                path,
                null,
                key
              )}
              {index < array.length - 1 ? "," : ""}
              <br />
            </div>
          ))}

          <span>
            <ElementOverlayTrigger
              type="object"
              data={data}
              path={path}
              responseType={responseType}
              onApplyRule={onAddRule}
              parentType={parentType}
              parentWrapperType={parentWrapperType}
              parentPath={parentPath}
              rules={rules}
              arrayIndex={arrayIndex}
              objectKey={objectKey}
            >
              <span className="api-response-brackets">{"}"}</span>
            </ElementOverlayTrigger>
          </span>
        </>
      );
    } else {
      return (
        <span>
          <ElementOverlayTrigger
            type={typeof data}
            data={data}
            path={path}
            responseType={responseType}
            onApplyRule={onAddRule}
            parentType={parentType}
            parentWrapperType={parentWrapperType}
            parentPath={parentPath}
            rules={rules}
            arrayIndex={arrayIndex}
            objectKey={objectKey}
          >
            <span className="api-response-value">{JSON.stringify(data)}</span>
          </ElementOverlayTrigger>
        </span>
      );
    }
  };

  return (
    <div className="api-response-visualizer">
      <div>Response Type: {responseType}</div>
      <div className="api-response-content">
        {renderApiResponse(apiResponse, "", null, null, "")}
      </div>
    </div>
  );
};

const defaultTransformScript = `function transform(result) {
  return result;
}`;

const defaultTransformScript_params = `function transform(request) {
  const { queries, headers, parameters } = request;

  // New queries, headers and parameters
  return {
    queries,
    headers,
    parameters
  };
}`;

const getScriptsList = (
  transformScript,
  transformScript_params,
  runTransformScript,
  runTransformScript_params
) => {
  let scriptsList = [];

  if (runTransformScript) {
    scriptsList = [
      {
        name: "Transform Response",
        id: "transformScript_result",
        script: transformScript,
        description: (
          <span>
            This script will be executed on the response data to transform it
            into the desired format. (
            <a href="/docs/api-response-transform" target="_blank">
              Docs
            </a>
            )
          </span>
        )
      }
    ];
  }
  if (runTransformScript_params) {
    scriptsList = [
      ...scriptsList,
      {
        name: "Transform Request",
        id: "transformScript_params",
        script: transformScript_params,
        description: (
          <span>
            This script will be executed on the request data to transform it
            into the desired format. (
            <a href="/docs/api-response-transform" target="_blank">
              Docs
            </a>
            )
          </span>
        )
      }
    ];
  }
  return scriptsList;
};

const ResponseTransform = ({
  apiResponse,
  resultType,
  method,
  onMethodChange
}) => {
  const [resultTypeState, setResultTypeState] = useState(resultType);
  const [transformScript, setTransformScript] = useState(
    method.transformScript || defaultTransformScript
  );
  const [transformScript_params, setTransformScript_params] = useState(
    method.transformScript_params || defaultTransformScript_params
  );
  const [runTransformScript, setRunTransformScript] = useState(
    method.runTransformScript || false
  );
  const [runTransformScript_params, setRunTransformScript_params] = useState(
    method.runTransformScript_params || false
  );
  const [transformScriptLanguage, setTransformScriptLanguage] =
    useState("javascript");
  const [transformScriptLanguage_params, setTransformScriptLanguage_params] =
    useState("javascript");
  const [selectedScript, setSelectedScript] = useState(null);
  const shouldUpdated = useRef(false);
  let scriptsList = getScriptsList(
    transformScript,
    transformScript_params,
    runTransformScript,
    runTransformScript_params
  );

  const unselectScript = (scriptId) => {
    if (selectedScript === scriptId && scriptsList.length > 0) {
      setSelectedScript(scriptsList[0].id);
    } else {
      setSelectedScript(null);
    }
  };

  useEffect(() => {
    if (shouldUpdated.current) {
      shouldUpdated.current = false;
      onMethodChange({
        target: { name: "runTransformScript", value: runTransformScript }
      });
    }

    if (runTransformScript && !selectedScript) {
      setSelectedScript("transformScript_result");
    } else if (
      !runTransformScript &&
      selectedScript === "transformScript_result"
    ) {
      unselectScript("transformScript_result");
    }
  }, [runTransformScript]);

  useEffect(() => {
    if (shouldUpdated.current) {
      shouldUpdated.current = false;
      onMethodChange({
        target: {
          name: "runTransformScript_params",
          value: runTransformScript_params
        }
      });
    }

    if (!!runTransformScript_params && !selectedScript) {
      setSelectedScript("transformScript_params");
    } else if (
      !runTransformScript_params &&
      selectedScript === "transformScript_params"
    ) {
      unselectScript("transformScript_params");
    }
  }, [runTransformScript_params]);

  useEffect(() => {
    if (shouldUpdated.current) {
      shouldUpdated.current = false;
      onMethodChange(
        {
          target: { name: "transformScript", value: transformScript }
        },
        {
          target: {
            name: "transformScriptLanguage",
            value: transformScriptLanguage
          }
        }
      );
    }
  }, [transformScript]);

  useEffect(() => {
    if (shouldUpdated.current) {
      shouldUpdated.current = false;
      let newTransformScript_params = transformScript_params;

      // if is not a string, convert to string then it's default script
      if (typeof newTransformScript_params !== "string") {
        newTransformScript_params = defaultTransformScript_params;
      }

      onMethodChange(
        {
          target: {
            name: "transformScript_params",
            value: newTransformScript_params
          }
        },
        {
          target: {
            name: "transformScriptLanguage_params",
            value: transformScriptLanguage_params
          }
        }
      );
    }
  }, [transformScript_params]);

  useEffect(() => {
    if (!!apiResponse) {
      const detectedType = detectResponseType(apiResponse);
      setResultTypeState(detectedType);
    }
  }, [apiResponse]);

  const detectResponseType = (response) => {
    if (Array.isArray(response)) {
      return "array";
    } else if (response !== null && typeof response === "object") {
      return "object";
    } else if (typeof response === "string") {
      return "string";
    } else if (typeof response === "number") {
      return "number";
    } else if (typeof response === "boolean") {
      return "boolean";
    } else {
      return "unknown";
    }
  };

  const showApiResponse = !!apiResponse && !!resultTypeState;

  const handleScriptChange = (value) => {
    shouldUpdated.current = true;
    if (selectedScript === "transformScript_params") {
      setTransformScript_params(value);
    } else if (selectedScript === "transformScript_result") {
      setTransformScript(value);
    }
  };

  return (
    <div>
      <p className="fs-5 fw-bold">Visualize and Transform the API Response</p>

      <p>
        The response from the API is visualized below. You can use the
        visualizer to select elements and apply a function to transform the data
        (
        <a href="/docs/api-response-transform" target="_blank">
          Docs
        </a>
        )
      </p>

      {showApiResponse && (
        <div className="api-response-visualizer-container">
          <ApiResponseVisualizerAndSelector
            apiResponse={apiResponse}
            onAddRule={() => {}}
            responseType={resultTypeState}
          />
        </div>
      )}
      {!showApiResponse && (
        <div>
          <p>No response data available to visualize</p>
        </div>
      )}

      {/* using nice react icon for runTransformScript checkbox */}
      <div className="d-flex align-items-center mb-3 mt-3 gap-3 fs-6 fw-bold">
        <div className="d-flex align-items-center">
          {runTransformScript_params ? (
            <FiCheckSquare
              size={24}
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => {
                shouldUpdated.current = true;
                setRunTransformScript_params(false);
              }}
            />
          ) : (
            <FiSquare
              size={24}
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => {
                shouldUpdated.current = true;
                setRunTransformScript_params(true);
              }}
            />
          )}
          <p className="m-0">Run Transform Script Request</p>
        </div>

        <div className="d-flex align-items-center">
          {runTransformScript ? (
            <FiCheckSquare
              size={24}
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => {
                shouldUpdated.current = true;
                setRunTransformScript(false);
              }}
            />
          ) : (
            <FiSquare
              size={24}
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => {
                shouldUpdated.current = true;
                setRunTransformScript(true);
              }}
            />
          )}
          <p className="m-0">Run Transform Script</p>
        </div>
      </div>

      {(runTransformScript || runTransformScript_params) && (
        <>
          <p>
            The transform script below will be executed on the response data to
            transform it into the desired format. (
            <a href="/docs/api-response-transform" target="_blank">
              Docs
            </a>
            )
          </p>
          <MethodRulesScriptEditor
            onChange={handleScriptChange}
            selectedScriptId={selectedScript}
            scriptsList={scriptsList}
            onScriptSelected={setSelectedScript}
          />
        </>
      )}
    </div>
  );
};

export default ResponseTransform;
