import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./ApiModelDocumentation.css";
import RestApiDocumentation from "./ApiDocumentation/RestApiDocumentation";

const ApiModelDocumentation = ({
  deploymentModel,
  baseApiUrl,
  systemOverview,
  readableApiMap,
  deployment
}) => {
  const [activeTab, setActiveTab] = useState("restApi"); // 'restApi' ou 'sdk'

  return (
    <div className="api-model-documentation">
      <div className="doc-header">
        <button
          onClick={() => setActiveTab("restApi")}
          className={activeTab === "restApi" ? "active" : ""}
        >
          Rest API
        </button>
        <button
          onClick={() => setActiveTab("sdk")}
          className={activeTab === "sdk" ? "active" : ""}
        >
          SDK
        </button>
      </div>
      <div className="doc-content">
        {activeTab === "restApi" && (
          <RestApiDocumentation
            deployment={deployment}
            readableApiMap={readableApiMap}
            systemOverview={systemOverview}
            deploymentModel={deploymentModel}
            baseApiUrl={baseApiUrl}
          />
        )}
        {activeTab === "sdk" && <SDKDocumentation />}{" "}
        {/* Placeholder pour le contenu SDK */}
      </div>
    </div>
  );
};

const SDKDocumentation = () => {
  // Logique pour afficher la documentation du SDK
  // ...
  return <div>SDK Content</div>;
};

export const ApiModelDocumentationModal = ({
  systemOverview,
  readableApiMap,
  deploymentModel,
  baseApiUrl,
  show,
  handleClose,
  deployment
}) => {
  return (
    <>
      <Modal
        size="xl"
        // fullscreen
        show={show}
        onHide={handleClose}
        dialogClassName="api-model-documentation-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>API Documentation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ApiModelDocumentation
            deployment={deployment}
            readableApiMap={readableApiMap}
            systemOverview={systemOverview}
            deploymentModel={deploymentModel}
            baseApiUrl={baseApiUrl}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ApiModelDocumentation;
