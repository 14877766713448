import React, { useState, useContext } from "react";
import { DeploymentModelContext } from "../../../../../contexts/DeploymentModelContext";
import "./TokenGroupManager.css";
import { ApiEndpointDetails } from "./ApiDocumentation/ApiEndpointDetails";

/*
export type TokenGroup = {
  id: String; // Un identifiant unique pour le groupe
  name: String; // Nom du groupe
  description: String; // Description du groupe
  permissions: [String]; // Liste des permissions accordées à ce groupe
  apiKeys: [String]; // Liste des clés API associées à ce groupe
  rateLimit: {
    requests: Number; // Nombre de requêtes autorisées par unité de temps
    timeUnit: "second" | "minute" | "hour" | "day"; // Unité de temps pour le taux limite
  };
  // ... autres configurations spécifiques
};
*/

const TokenGroupManager = () => {
  const { deploymentModel, actions } = useContext(DeploymentModelContext);
  const [tokenGroups, setTokenGroups] = useState(
    deploymentModel.tokenGroups || []
  );

  const handleAddTokenGroup = () => {
    const newTokenGroup = {
      id: "token-group-" + Math.random().toString(36).substr(2, 9),
      name: "New Token Group",
      description: "",
      permissions: [],
      apiKeys: [],
      rateLimit: {
        requests: 100,
        timeUnit: "second"
      }
    };

    setTokenGroups([...tokenGroups, newTokenGroup]);
  };

  const handleRateLimitChange = (groupId, newRateLimit) => {
    const newTokenGroups = tokenGroups.map((group) => {
      if (group.id === groupId) {
        return {
          ...group,
          rateLimit: newRateLimit
        };
      } else {
        return group;
      }
    });

    setTokenGroups(newTokenGroups);
  };

  const handleName = (groupId, newName) => {
    const newTokenGroups = tokenGroups.map((group) => {
      if (group.id === groupId) {
        return {
          ...group,
          name: newName
        };
      } else {
        return group;
      }
    });

    setTokenGroups(newTokenGroups);
  };

  const onSave = () => {
    actions.update({
      ...deploymentModel,
      tokenGroups
    });
  };

  const onBlur = () => {
    onSave();
  };

  return (
    <div className="token-group-manager">
      <h4>Token Groups</h4>
      {tokenGroups.map((group) => (
        <div key={group.id} className="token-group">
          <input
            type="text"
            onBlur={onBlur}
            value={group.name}
            onChange={(e) => handleName(group.id, e.target.value)}
          />
          <input
            type="number"
            value={group.rateLimit.requests}
            onChange={(e) =>
              handleRateLimitChange(group.id, {
                ...group.rateLimit,
                requests: e.target.value
              })
            }
          />
          <select
            value={group.rateLimit.timeUnit}
            onChange={(e) =>
              handleRateLimitChange(group.id, {
                ...group.rateLimit,
                timeUnit: e.target.value
              })
            }
          >
            <option value="second">Second</option>
            <option value="minute">Minute</option>
            <option value="hour">Hour</option>
            <option value="day">Day</option>
          </select>
        </div>
      ))}
      <button onClick={handleAddTokenGroup}>Add Token Group</button>
    </div>
  );
};

export const TokenGroupsApiDetails = ({
  tokenGroups = [],
  baseApiUrl = "{baseApiUrl}",
  lite = false,
  exampleLanguage,
  languageLib
}) => {
  const [activeGroup, setActiveGroup] = useState(null);

  const toggleGroup = (groupId) => {
    setActiveGroup(activeGroup === groupId ? null : groupId);
  };

  return (
    <div className={`token-groups-api-details ${lite ? "lite" : ""}`}>
      {tokenGroups.map((group) => (
        <div
          key={group.id}
          className={`token-group-api-detail ${
            activeGroup === group.id ? "active" : ""
          }`}
        >
          <div
            className="token-group-toggle-button-header"
            onClick={() => toggleGroup(group.id)}
          >
            <span>{group.name} Group</span>
          </div>
          {activeGroup === group.id && (
            <div className="">
              {/* Generate Token Endpoint */}
              <ApiEndpointDetails
                exampleLanguage={exampleLanguage}
                languageLib={languageLib}
                lite={lite}
                title="- Generate Token Endpoint:"
                endpointUrl={`${baseApiUrl}/dep/generateTokenGroupApiKey`}
                method="POST"
                headers={{
                  Authorization: "Bearer {Your Creaxys API Key}"
                }}
                body={{
                  tokenGroupId: {
                    type: "String",
                    description:
                      "The token group id to generate the api key and secure token for",
                    required: true,
                    example: "30qweiofwe"
                  },
                  custom_metadata: {
                    type: "String",
                    description:
                      "Custom metadata to be associated with the token"
                  },
                  unique_id_for_rollkey: {
                    type: "String",
                    description:
                      "Unique id for the rollkey, helpful to rollkey based on a unique id(See for more info: https://docs.creaxys.com/docs/security#rollkey)"
                  }
                }}
                response={{
                  keyId: {
                    type: "string",
                    description:
                      "Unique secure api key with permissions based on the specified token group"
                  },
                  secure_token: {
                    type: "string",
                    description:
                      "The secure token to securely request an api key having permissions of the specified token group(See for more info: https://docs.creaxys.com/docs/security#secure-token)"
                  },
                  tokenGroupId: {
                    type: "string",
                    description: "The token group id"
                  },
                  isActive: {
                    type: "boolean",
                    description: "Is the token active"
                  },
                  createdAt: {
                    type: "date",
                    description: "The date the token was created"
                  },
                  unique_id_for_rollkey: {
                    type: "string",
                    description:
                      "Unique id for the rollkey, helpful to rollkey based on a unique id"
                  },
                  custom_metadata: {
                    type: "string",
                    description: "Custom metadata associated with the token"
                  }
                }}
              />

              <div
                style={{
                  height: "2px",
                  background: "#ccc",
                  width: "100%",
                  margin: "10px 0"
                }}
              ></div>

              {/* Verify Token Endpoint */}
              <ApiEndpointDetails
                exampleLanguage={exampleLanguage}
                languageLib={languageLib}
                lite={lite}
                title="- Verify Token Endpoint:"
                endpointUrl={`${baseApiUrl}/dep/verifyTokenGroupApiKey`}
                method="POST"
                headers={{
                  Authorization: "Bearer {Your Creaxys API Key}"
                }}
                body={{
                  keyId: {
                    type: "string",
                    description: "The key id to verify",
                    required: true
                  }
                }}
                response={{
                  groupId: {
                    type: "string",
                    description: "The token group id"
                  },
                  custom_metadata: {
                    type: "string",
                    description:
                      "Custom metadata to be associated with the token"
                  }
                }}
              />

              <div
                style={{
                  height: "2px",
                  background: "#ccc",
                  width: "100%",
                  margin: "10px 0"
                }}
              ></div>

              <ApiEndpointDetails
                exampleLanguage={exampleLanguage}
                languageLib={languageLib}
                lite={lite}
                title={`- Get User Api Key Using A Secure Token Endpoint:`}
                endpointUrl={`${baseApiUrl}/dep/getSecureToken`}
                method="POST"
                headers={{
                  Authorization: "Bearer {Your Creaxys API Key}"
                }}
                body={{
                  secure_token: {
                    type: "string",
                    description:
                      "The token to use to securely request an api key",
                    required: true
                  }
                }}
                response={{
                  keyId: {
                    type: "string",
                    description: "The generated token id"
                  }
                }}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default TokenGroupManager;
