/**
 * @fileoverview ChainedBuilt
 * @description Now this page will allow to create chained built prompts and then execute them
 * it will first get all the chained built prompts by getting list of chainedBuilt in the database
 * each chainedBuilt has properties defined below.
 * The page will allow to create new chainedBuilt, it will allow to add as many builtPrompts as needed to the chainedBuilt and change the order of the builtPrompts.
 * The page will allow to edit the chainedBuilt, it will allow to add as many builtPrompts as needed to the chainedBuilt and change the order of the builtPrompts.
 * The page will allow to delete the chainedBuilt.
 * The page will render a main form for following properties: name.
 * Then it will render the Json object the user has to pass to the API when using the chainedBuilt. This object will render from valuesKeysToMap of each builtPromptListItem.
 * Then it will render a list of builtPromptListItems.
 * For each builtPromptListItem it will render the name of the builtPrompt, and a list of valueKeysToMap, for each valueKeyToMap it will render the valueKey and a value selector(options are: $current, $previousPromptResult, Map custom key)
 * When clicking on "Map custom key" it will render an input under the valueKeyToMap, value of this input is saved at valueKeyToMap[valueKey] and the input is removed.
 *  */

/**
 * @typedef {Object} BuiltPromptListItem
 * @property {string} id - The id of the builtPrompt.
 * @property {Object} valueKeysToMap - The initial keys of this are the valueKeys of the builtPrompt.
 */

/*
 * @typedef {Object} chainedBuilt
 * @property {string} id - The id of the chainedBuilt.
 * @property {string} name - The name of the chainedBuilt.
 * @property {[BuiltPromptListItem]} builtPromptList - The list of builtPromptListItems.
 * @property {string} createdAt - The date the chainedBuilt was created.
 * @property {string} updatedAt - The date the chainedBuilt was last updated.
 */

/*
 * @typedef {Object} BuiltPrompt
 * @property {string} id - The id of the builtPrompt.
 * @property {string} uid - The user id of the user who created the builtPrompt.
 * @property {string} promptName - The name of the builtPrompt.
 * @property {string} prompt - The prompt of the builtPrompt.
 * @property {string} example - The example of the builtPrompt.
 * @property {[string]} valueKeys - The value keys of the builtPrompt.
 * @property {string} createdAt - The date the builtPrompt was created.
 * @property {string} updatedAt - The date the builtPrompt was last updated.
 * @property {GptConfig} gptConfig - The gptConfig of the builtPrompt.
 */
import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Spinner,
  Form,
  Modal,
  Container,
  Row,
  Alert,
  Col,
} from "react-bootstrap";
import CrudPage from "../../layouts/CrudPage";
import { getOpenAICompletion } from "../../../apis/openAiAPI";
import DB from "../../../database/DB";
import API from "../../../apis/API";
import { useParams, useNavigate } from "react-router-dom";

const VALUE_KEY_TO_MAP_VALUES_OPTIONS = [
  { value: "$current", label: "$current" },
  { value: "$previousPromptResult", label: "$previousPromptResult" },
];

const isValueKeyOption = (value) => {
  return VALUE_KEY_TO_MAP_VALUES_OPTIONS.find((v) => v.value === value);
};

const getDefaultChainedBuilt = () => {
  return {
    name: "",
    builtPromptList: [
      {
        id: "",
        valueKeysToMap: {},
      },
    ],
  };
};

const BuiltPromptValueKeysToMapSelect = ({
  previousBuiltPromptTextsList,
  builtPromptItem,
  onChange,
  builtPrompt,
}) => {
  const [customKeyActiveIndex, setCustomKeyActiveIndex] = useState(null);
  const [customKey, setCustomKey] = useState("");

  if (!builtPromptItem.id || !builtPrompt)
    return (
      <p
        className="text-gray-500"
        style={{
          position: "relative",
          top: "11px",
        }}
      >
        No keys to map
      </p>
    );

  const builtPromptKeys = Object.keys(builtPromptItem.valueKeysToMap) || [];

  const handleSelectChange = (e, index) => {
    if (e.target.value === "customKey") {
      const currentValueKey =
        builtPromptItem.valueKeysToMap[builtPromptKeys[index]] || "";
      if (
        VALUE_KEY_TO_MAP_VALUES_OPTIONS.find((v) => v.value === currentValueKey)
      ) {
        setCustomKey("");
      } else {
        setCustomKey(currentValueKey);
      }
      setCustomKeyActiveIndex(index);
      return;
    } else if (customKeyActiveIndex > -1) {
      closeCustomKeyInput();
    }
    const newValueKeysToMap = { ...builtPromptItem.valueKeysToMap };
    newValueKeysToMap[builtPromptKeys[index]] = e.target.value;
    const event = {
      target: {
        name: "valueKeysToMap",
        value: newValueKeysToMap,
      },
    };
    onChange(event);
  };

  const onSaveCustomKey = (index) => {
    const newValueKeysToMap = { ...builtPromptItem.valueKeysToMap };
    newValueKeysToMap[builtPromptKeys[index]] = customKey;
    const event = {
      target: {
        name: "valueKeysToMap",
        value: newValueKeysToMap,
      },
    };
    onChange(event);
    closeCustomKeyInput();
  };

  const closeCustomKeyInput = () => {
    setCustomKeyActiveIndex(-1);
    setCustomKey("");
  };

  // save custom key on enter
  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {
      onSaveCustomKey(index);
    }
  };

  return builtPromptKeys.map((key, index) => {
    const valueKeyValue = builtPromptItem.valueKeysToMap[key];
    const isValueOfKeyCustom = !isValueKeyOption(valueKeyValue);
    let customKeyValue = !isValueOfKeyCustom ? valueKeyValue : "customKey";

    return (
      <Row key={key} className="mb-1 mt-1">
        <Col>
          {/* On disabled input */}
          <Form.Control type="text" name="valueKey" value={key} disabled />
        </Col>
        <Col>
          {/* One select with options: $current, $previousPromptResult, Map custom key */}
          <Form.Control
            as="select"
            name="valueKeyToMapValue"
            value={customKeyValue}
            onChange={(e) => handleSelectChange(e, index)}
          >
            {VALUE_KEY_TO_MAP_VALUES_OPTIONS.map(({ value, label }, i) => (
              <option key={i} value={value}>
                {label}
              </option>
            ))}
            {previousBuiltPromptTextsList.map((builtPromptText, i) => {
              return (
                <option key={i} value={builtPromptText}>
                  {builtPromptText}
                </option>
              );
            })}
            <option value="customKey">
              {isValueOfKeyCustom && valueKeyValue.length > 0
                ? valueKeyValue
                : `Map custom key`}
            </option>
          </Form.Control>
          {/* If select value is Map custom key, render an input under the select */}
          {customKeyActiveIndex === index && (
            <Col sm={12}>
              <Form.Control
                placeholder="Enter custom key (e.g. myServerResponseKey, etc.)"
                type="text"
                name="customKey"
                value={customKey}
                onChange={(e) => setCustomKey(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e, index)}
              />
              <div className="d-flex justify-content-end mt-1">
                <Button variant="secondary" onClick={closeCustomKeyInput}>
                  Close
                </Button>
                <Button
                  variant="primary"
                  onClick={() => onSaveCustomKey(index)}
                >
                  Save
                </Button>
              </div>
            </Col>
          )}
        </Col>
      </Row>
    );
  });
};

const BuiltPromptListItemElement = ({
  builtPromptListItem,
  index,
  builtPromptOptions,
  handleBuiltPromptListChange,
  handleRemoveBuiltPromptListItem,
  builtPromptListItems,
  handleAddBuiltPromptListItem,
}) => {
  const [selectedBuiltPrompt, setSelectedBuiltPrompt] = useState(null);

  // Function to set new builtPromptListItem.valueKeysToMap from selectedBuiltPrompt.valueKeys
  const setNewValueKeysToMap = (builtPrompt) => {
    const newValueKeysToMap = {};
    builtPrompt.valueKeys.forEach((key, i) => {
      if (!builtPromptListItem.valueKeysToMap[key]) {
        if (index !== 0 && i === 0) {
          newValueKeysToMap[key] = "$previousPromptResult";
          return;
        }
        newValueKeysToMap[key] = "$current";
      } else {
        newValueKeysToMap[key] = builtPromptListItem.valueKeysToMap[key] || "";
      }
    });
    const event = {
      target: {
        name: "valueKeysToMap",
        value: newValueKeysToMap,
      },
    };
    handleBuiltPromptListChange(event, index);
  };

  useEffect(() => {
    if (!builtPromptListItem.id) return;
    const unsubscribe = DB.builtPrompt.get(
      builtPromptListItem.id,
      (builtPrompt) => {
        setSelectedBuiltPrompt(builtPrompt);
        setNewValueKeysToMap(builtPrompt);
      }
    );
    return unsubscribe;
  }, [builtPromptListItem.id]);

  // Return array of strings, like ["$promptResult0", "$promptResult1", ...]
  // where each value is "$promptResult" + index of the prompt in the list
  // It does not includes the current prompt and the next ones
  const previousBuiltPromptTextsList = (() => {
    const texts = [];
    for (let i = 0; i < index - 1; i++) {
      texts.push(`$promptResult${i + 1}`);
    }
    return texts;
  })();

  return (
    <div className="mb-3">
      <Row className="">
        <Col sm={5} className="d-flex">
          <span
            className="me-2 fw-bold"
            style={{
              position: "relative",
              top: "36px",
            }}
          >
            {index + 1}
          </span>
          <Form.Group>
            <Form.Label>Built Prompt</Form.Label>
            <Form.Control
              as="select"
              name="id"
              value={builtPromptListItem.id}
              onChange={(event) => handleBuiltPromptListChange(event, index)}
            >
              <option value="">Select Built Prompt</option>
              {builtPromptOptions.map((builtPromptOption) => {
                return (
                  <option
                    key={builtPromptOption.value}
                    value={builtPromptOption.value}
                  >
                    {builtPromptOption.label}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group>
            <Form.Label>Value Keys To Map</Form.Label>
            <BuiltPromptValueKeysToMapSelect
              previousBuiltPromptTextsList={previousBuiltPromptTextsList}
              builtPrompt={selectedBuiltPrompt}
              builtPromptItem={builtPromptListItem}
              onChange={(event) => handleBuiltPromptListChange(event, index)}
            />
          </Form.Group>
        </Col>
        <Col sm={1} className="d-flex align-items-center fs-4">
          <p></p>
          {index !== 0 && (
            <i
              className="fa-solid fa-xmark text-danger"
              role="button"
              onClick={() => handleRemoveBuiltPromptListItem(index)}
            ></i>
          )}
        </Col>
        {/* Render a add built prompt icon if it's last builtPrompt or a down arrow if not*/}
      </Row>
      <Col sm={12} className="fs-4 mt-3 cursor-pointer">
        {index !== builtPromptListItems.length - 1 && (
          <div className="d-flex justify-content-center">
            <i className="fa fa-arrow-down text-black-50"></i>
          </div>
        )}
        {index === builtPromptListItems.length - 1 && (
          <div
            className="d-flex justify-content-center"
            onClick={() => handleAddBuiltPromptListItem()}
          >
            <i className="fa fa-plus text-primary" role="button"></i>
          </div>
        )}
      </Col>
    </div>
  );
};

// export type ChainedBuiltResult = {
//   promptResult?: string;
//   prompt?: string;
//   valuesObject?: { [key: string]: string };
// };

// export type ChainedBuiltResults = {
//   [key: string]: ChainedBuiltResult;
// };

// results prop's type is ChainedBuiltResults
// Render a list of results and its prompt
const ChainedBuiltTestResult = ({ results }) => {
  return (
    <Container fluid>
      {Object.keys(results).map((key) => {
        const result = results[key];
        return (
          <Row key={key}>
            <Col sm={6} className="p-3">
              <div className="d-flex p-3 bg-white border rounded">
                <div className="flex-shrink-0 me-3">
                  <div className="avatar-xs">
                    <div className="">
                      <i className="fa-solid fa-terminal"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1">
                  <h5 className="font-size-14 mb-1">
                    {key}{" "}
                    {/* <small className="text-muted float-end">1 hr Ago</small> */}
                  </h5>
                  <p className="text-muted text-formated">{result.prompt}</p>
                  {/* <div>
                    <a href="javascript: void(0);" className="text-success">
                      <i className="mdi mdi-reply"></i> Reply
                    </a>
                  </div> */}
                </div>
              </div>
            </Col>
            <Col sm={6} className="p-3">
              <div className="d-flex p-3 bg-white border rounded">
                <div className="flex-shrink-0 me-3">
                  <div className="avatar-xs">
                    <div className="">
                      <i className="fa-sharp fa-solid fa-arrow-right"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1">
                  <h5 className="font-size-14 mb-1">
                    Result{" "}
                    {/* <small className="text-muted float-end">1 hr Ago</small> */}
                  </h5>
                  <p className="text-muted text-formated">
                    {result.promptResult}
                  </p>
                  {/* <div>
                    <a href="javascript: void(0);" className="text-success">
                      <i className="mdi mdi-reply"></i> Reply
                    </a>
                  </div> */}
                </div>
              </div>
            </Col>
          </Row>
        );
      })}
    </Container>
  );
};

const ChainedBuiltApiRequestInfo = ({ chainedBuilt }) => {
  const [testValues, setTestValues] = useState({});
  const [testResults, setTestResults] = useState({});

  const JSONKeysToSend = () =>
    chainedBuilt.builtPromptList.reduce((parentAcc, builtPromptListItem) => {
      const customAndCurrentKeys = Object.keys(
        builtPromptListItem.valueKeysToMap
      ).reduce((acc, key) => {
        const value = builtPromptListItem.valueKeysToMap[key];
        if (value === "$previousPromptResult") {
          return acc;
        } else if (value === "$current" && !parentAcc.includes(key)) {
          return [...acc, key];
        } else if (
          value !== "$current" &&
          !parentAcc.includes(value) &&
          !acc.includes(value)
        ) {
          // check if value contains $promptResult[0-9]+
          if (!value.match(/\$promptResult[0-9]+/g)) {
            return [...acc, value];
          } else {
            return acc;
          }
        }
        return acc;
      }, []);
      return [...parentAcc, ...customAndCurrentKeys];
    }, []);

  const setJsonToSendToTestValues = () => {
    const newTestValues = {};
    JSONKeysToSend().forEach((key) => {
      if (testValues[key]) {
        newTestValues[key] = testValues[key];
        return;
      }
      newTestValues[key] = "";
    });
    setTestValues(newTestValues);
  };

  useEffect(() => {
    setJsonToSendToTestValues();
  }, []);

  useEffect(() => {
    setJsonToSendToTestValues();
  }, [chainedBuilt.builtPromptList]);

  const testChainedBuilt = async () => {
    const response = await API.openAi.chainedBuilt.execute(
      chainedBuilt.id,
      testValues
    );
    const results = response.data;
    setTestResults(results);
  };

  return (
    <Alert variant="secondary">
      <p className="fs-4 fw-bold text-center">Api access</p>
      <p className="fs-6 text-center">
        {chainedBuilt.id && (
          <>
            To access the built prompt list, use the following api call:
            <br />
            <code>POST</code>{" "}
            <a href="/api//chained-built/{chainedBuilt.id}" target="_blank">
              /api/openai/chainedBuilt/{chainedBuilt.id}/execute
            </a>
            <br />
            <br />
          </>
        )}
        The request body should be a json object with the following structure:
        <br />
        {/* json code style */}
      </p>
      <div className="d-flex justify-content-center">
        <code
          className="bg-light"
          style={{
            borderRadius: "5px",
            padding: "10px",
            width: "300px",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            wordWrap: "break-word",
            overflowWrap: "break-word",
            margin: "10px 0",
          }}
        >
          {JSONKeysToSend().map((key, index, arr) => {
            return (
              <span key={key}>
                {index === 0 && (
                  <>
                    {`{`}
                    <br />
                  </>
                )}
                <span className="ps-2">
                  {key}:{" "}
                  <span className="test-value-input-built-chain">
                    {/* Input using react-bootstrap */}
                    <input
                      id={key}
                      type="text"
                      value={testValues[key] || ""}
                      placeholder="your value"
                      onChange={(event) => {
                        const newTestValues = { ...testValues };
                        newTestValues[key] = event.target.value;
                        setTestValues(newTestValues);
                      }}
                    />
                  </span>
                </span>
                <br />
                {index === arr.length - 1 && "}"}
              </span>
            );
          })}
          {/* button on right to test it */}
          <div className="d-flex justify-content-end">
            <Button variant="primary" onClick={testChainedBuilt}>
              Test
            </Button>
          </div>
        </code>
      </div>
      {testResults && <ChainedBuiltTestResult results={testResults} />}
    </Alert>
  );
};

const ChainedBuiltEditor = ({ chainedBuiltId }) => {
  const [chainedBuilt, setChainedBuilt] = useState(getDefaultChainedBuilt());
  const [builtPrompts, setBuiltPrompts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const isNewChainedBuilt = !chainedBuiltId;

  useEffect(() => {
    if (!chainedBuiltId) {
      setChainedBuilt(getDefaultChainedBuilt());
      return;
    }
    const unsubscribe = DB.chainedBuilt.get(
      chainedBuiltId,
      (chainedBuiltData) => {
        setChainedBuilt(chainedBuiltData);
      },
      setError
    );
    return unsubscribe;
  }, [chainedBuiltId]);

  useEffect(() => {
    const unsubscribe = DB.builtPrompt.list(setBuiltPrompts, setError);
    return unsubscribe;
  }, []);

  const handleChainedBuiltChange = (event) => {
    const { name, value } = event.target;
    setChainedBuilt((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBuiltPromptListChange = (event, index) => {
    const { name, value } = event.target;
    const list = [...chainedBuilt.builtPromptList];
    list[index][name] = value;
    setChainedBuilt((prevState) => ({
      ...prevState,
      builtPromptList: list,
    }));
  };

  const handleAddBuiltPromptListItem = () => {
    setChainedBuilt((prevState) => ({
      ...prevState,
      builtPromptList: [
        ...prevState.builtPromptList,
        { id: "", valueKeysToMap: {} },
      ],
    }));
  };

  const handleRemoveBuiltPromptListItem = (index) => {
    const list = [...chainedBuilt.builtPromptList];
    list.splice(index, 1);
    setChainedBuilt((prevState) => ({
      ...prevState,
      builtPromptList: list,
    }));
  };

  const handleSave = async () => {
    setLoading(true);
    setError(null);
    const chainedBuiltToSave = {
      ...chainedBuilt,
    };
    if (chainedBuilt.id) {
      DB.chainedBuilt.update(
        chainedBuiltToSave.id,
        chainedBuiltToSave,
        setLoading,
        setError
      );
    } else {
      const chainedBuiltId = await DB.chainedBuilt.create(
        chainedBuiltToSave,
        setLoading,
        setError
      );
      navigate(`/chained-built/${chainedBuiltId}`);
    }
  };

  const handleDelete = () => {
    setLoading(true);
    setError(null);
    DB.chainedBuilt.delete(chainedBuilt.id, setLoading, setError);
    navigate("/chained-built");
  };

  const cloneChainedBuilt = async () => {
    setLoading(true);
    setError(null);
    let clonedChainedBuilt = {
      ...chainedBuilt,
      name: `${chainedBuilt.name} (copy)`,
    };
    // clear id so it will be created as a new chainedBuilt
    clonedChainedBuilt.id = null;
    const clonedChainedBuiltId = await DB.chainedBuilt.create(
      clonedChainedBuilt,
      setLoading,
      setError
    );
    navigate(`/chained-built/${clonedChainedBuiltId}`);
  };

  const builtPromptOptions = builtPrompts.map((builtPrompt) => {
    return {
      label: builtPrompt.promptName,
      value: builtPrompt.id,
    };
  });

  const builtPromptListItemsElements = chainedBuilt.builtPromptList.map(
    (builtPromptListItem, index) => {
      return (
        <BuiltPromptListItemElement
          key={index}
          index={index}
          builtPromptListItem={builtPromptListItem}
          builtPromptOptions={builtPromptOptions}
          handleBuiltPromptListChange={handleBuiltPromptListChange}
          handleRemoveBuiltPromptListItem={handleRemoveBuiltPromptListItem}
          handleAddBuiltPromptListItem={handleAddBuiltPromptListItem}
          builtPromptListItems={chainedBuilt.builtPromptList}
        />
      );
    }
  );

  return (
    <Row className="ChainedBuiltEditor">
      <Col sm={12} className="bg-white p-4 mb-4">
        <div className="d-flex justify-content-end">
          {!isNewChainedBuilt && (
            <Button className="ms-2" variant="link" onClick={cloneChainedBuilt}>
              Clone
            </Button>
          )}
          {!isNewChainedBuilt && (
            <Button className="ms-2" variant="danger" onClick={handleDelete}>
              Delete
            </Button>
          )}
          <Button className="ms-2" variant="primary" onClick={handleSave}>
            {isNewChainedBuilt ? "Create" : "Save"}
          </Button>
        </div>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              name="name"
              value={chainedBuilt.name}
              onChange={handleChainedBuiltChange}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-5">
            {/* Add a description text */}
            <p className="fs-4 fw-bold text-center">Built Prompt List</p>

            <Container fluid="sm">{builtPromptListItemsElements}</Container>
          </Form.Group>
        </Form>
      </Col>

      <Col sm={12} className="p-0">
        {/* Description on how to use the current chained built */}
        {chainedBuilt && chainedBuilt.builtPromptList && (
          <ChainedBuiltApiRequestInfo chainedBuilt={chainedBuilt} />
        )}
      </Col>
    </Row>
  );
};

// Component for the list of built prompts
// and show a json object with built prompts name as keys and id as values
const ButtonForChainedBuiltNameIdMapModal = ({ chainedBuilts }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if (!chainedBuilts) {
    return null;
  }

  const chainedBuiltNameIdMap = chainedBuilts.reduce((acc, chainedBuilt) => {
    acc[chainedBuilt.name] = chainedBuilt.id;
    return acc;
  }, {});

  return (
    <>
      <Button variant="link" onClick={handleShow}>
        Chained Built Name-Id Map
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Chained Built Name-Id Map</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <pre>{JSON.stringify(chainedBuiltNameIdMap, null, 2)}</pre>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const ChainedBuiltPage = () => {
  const { chainedBuiltId } = useParams(null);
  const [chainedBuilts, setChainedBuilts] = useState([]);

  useEffect(() => {
    const unsubscribe = DB.chainedBuilt.list(setChainedBuilts);
    return unsubscribe;
  }, []);

  const newLink = {
    label: "Create new Chained Built",
    value: "",
  };
  let pageLinks = chainedBuilts.map((chainedBuilt) => {
    return {
      label: chainedBuilt.name,
      value: chainedBuilt.id,
    };
  });
  pageLinks = [newLink, ...pageLinks];

  return (
    <>
      <CrudPage
        title="Chained Built"
        links={pageLinks}
        value={chainedBuiltId}
        path="/chained-built"
        elements={[
          <ButtonForChainedBuiltNameIdMapModal chainedBuilts={chainedBuilts} />,
        ]}
      >
        <ChainedBuiltEditor chainedBuiltId={chainedBuiltId} />
      </CrudPage>
    </>
  );
};

export default ChainedBuiltPage;
