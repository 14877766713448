/**
 * @file PageConfiguration.js
 * @description PageConfiguration Component
 *   This component accepts following parameters: webApp, page, actions, dataModels
 *   If page.id is null, it means that the page is new and it will be created
 *   If page.id is not null, it means that the page is existing and it will be updated
 *   This component is used to configure the page.
 *    - conditional part, suggested pages (only if page.id is null)
 *    - first part, basic information
 *    - Page Name
 *    - Page Description
 *    - Page URL
 *    - Page Type (static, dynamic)
 *    - second part, Data Linked to Page, it allows to link specific fields of specific data models to the page
 *    - third part, modules, it allows to add modules to the page (TODO LATER)
 *
 *   Another Modal Wrapper component will be create for case we want to use the PageConfiguration component as a modal
 */

import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Col,
  Row,
  Modal,
  Container,
  Accordion,
  Spinner,
  Dropdown,
  Table,
  Tabs,
  Tab,
  Nav,
} from "react-bootstrap";
import AudioInput from "../../../components/UI/AudioInput";

const getDefaultPage = (props) => {
  return {
    name: "New Page",
    type: "static", // static, dynamic
    content: "<html><head></head><body>New Page</body></html>",
    modules: [],
    components: [],
    dataModels: [],
    ...props,
  };
};

const SuggestedPages = ({ suggestedPages = [], onSelect }) => {
  return (
    <Accordion className="mb-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Click to see suggested pages</Accordion.Header>
        {/* <h5>Suggested Models</h5> */}
        <Accordion.Body className="p-0">
          <Table striped bordered className="border-0 mb-0">
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>URL</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {suggestedPages.map((page, index) => {
                return (
                  <tr key={index}>
                    <td>{page.name}</td>
                    <td>{page.description}</td>
                    <td>{page.url}</td>
                    <td>
                      <Button variant="link" onClick={() => onSelect(page)}>
                        Select
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

const pageIconLibs = [
  {
    name: "fontawesome",
    label: "Font Awesome",
    description: "Font Awesome icons",
  },
  {
    name: "bootstrap",
    label: "Bootstrap",
    description: "Bootstrap icons",
  },
  {
    name: "ionicons",
    label: "Ionicons",
    description: "Ionicons icons",
  },
  {
    name: "material",
    label: "Material",
    description: "Material icons",
  },
  {
    name: "themify",
    label: "Themify",
    description: "Themify icons",
  },
  {
    name: "typicons",
    label: "Typicons",
    description: "Typicons icons",
  },
  {
    name: "foundation",
    label: "Foundation",
    description: "Foundation icons",
  },
];

const pageStyles = [
  {
    name: "vanilla",
    label: "Free",
    description: "Free style",
    framework: "vanilla",
  },
  {
    name: "bootstrap",
    label: "Bootstrap",
    description: "Bootstrap style",
    framework: "bootstrap",
  },
  {
    name: "material",
    label: "Material",
    description: "Material style",
    framework: "material",
  },
  {
    name: "bulma",
    label: "Bulma",
    description: "Bulma style",
    framework: "bulma",
  },
  {
    name: "tailwind",
    label: "Tailwind",
    description: "Tailwind style",
    framework: "tailwind",
  },
  {
    name: "semantic",
    label: "Semantic",
    description: "Semantic style",
    framework: "semantic",
  },
  {
    name: "foundation",
    label: "Foundation",
    description: "Foundation style",
    framework: "foundation",
  },
  {
    name: "uikit",
    label: "UIKit",
    description: "UIKit style",
    framework: "uikit",
  },
  {
    name: "spectre",
    label: "Spectre",
    description: "Spectre style",
    framework: "spectre",
  },
  {
    name: "pure",
    label: "Pure",
    description: "Pure style",
    framework: "pure",
  },
  {
    name: "skeleton",
    label: "Skeleton",
    description: "Skeleton style",
    framework: "skeleton",
  },
  {
    name: "milligram",
    label: "Milligram",
    description: "Milligram style",
    framework: "milligram",
  },
];

const pageGoals = [
  "landing",
  "home",
  "information",
  "settings",
  "dashboard",
  "login",
  "register",
  "login-register",
  "single-app-page",
];

const PageConfiguration = ({
  webApp,
  defaultValue = {},
  actions,
  dataModels,
  onHide,
  pagesNames = [],
  pages = [],
}) => {
  const [generatingContentObject, setGeneratingContentObject] = useState(false);
  const [generatingPageLinkedValues, setGeneratingPageLinkedValues] =
    useState(false);
  const [pageCopy, setPageCopy] = useState(getDefaultPage(defaultValue));
  const [loadingResults, setLoadingResults] = useState({});
  const { aiRenderedData = {} } = webApp;
  const isNew = !defaultValue.id;
  const suggestedPages = isNew
    ? JSON.parse(aiRenderedData.recommendedPages) || []
    : [];

  const linkedValues = pageCopy.linkedValues || {}; // { "dataModelId.fieldId": "value" }
  const contentObject = pageCopy.contentObject || {};

  useEffect(() => {
    const newPageCopy = { ...defaultValue };
    newPageCopy.aiRenderedData = { ...aiRenderedData };
    setPageCopy(newPageCopy);
  }, [aiRenderedData]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setPageCopy({
      ...pageCopy,
      [name]: value,
    });
  };

  const handleAiRenderedDataOnChange = (e) => {
    const { name, value } = e.target;
    setPageCopy({
      ...pageCopy,
      aiRenderedData: {
        ...pageCopy.aiRenderedData,
        [name]: value,
      },
    });
  };

  const onRunAiResult = async (resultKey) => {
    setLoadingResults({
      ...loadingResults,
      [resultKey]: true,
    });
    await actions.runAiResult(resultKey);
    setLoadingResults({
      ...loadingResults,
      [resultKey]: false,
    });
    actions.notify({
      type: "success",
      message: "AI Result Generated",
    });
  };

  const onSavePage = async () => {
    if (pageCopy.id) {
      await actions.updatePage(pageCopy);
      actions.notify({
        type: "success",
        message: "Page Updated",
      });
      onHide && onHide();
      return;
    }
    await actions.addNewPage(pageCopy);
    actions.notify({
      type: "success",
      message: "Page Saved",
    });
    onHide && onHide();
  };

  const setLinkedValues = (linkedValues) => {
    setPageCopy({
      ...pageCopy,
      linkedValues,
    });
  };

  const linkValuesWithAI = async () => {
    setGeneratingPageLinkedValues(true);
    const key = "linkedValuesToPage";
    const resultKey = null;
    let finalData = "[]";

    const setCurrentStreamedArray = (data) => {
      let jsonData = [];
      try {
        let newData = finalData + "]";
        jsonData = JSON.parse(newData);
      } catch (e) {
        jsonData = JSON.parse(finalData);
      }
      const newLinkedValues = jsonData.reduce(
        (acc, item) => ({
          ...acc,
          [item]: "value",
        }),
        {}
      );
      setLinkedValues(newLinkedValues);
    };

    const options = {
      onDataStream: (data) => {
        console.log("streaming", data);
        setCurrentStreamedArray(data);
        // setLinkedValues(linkedValues);
      },
      onDataStreamEnd: (data) => {
        console.log("streaming end", data);
      },
      forcedValues: {
        parameters: {
          pageName: pageCopy.name,
          dataModels: JSON.stringify(dataModels),
          currentPages: JSON.stringify(pages),
        },
        results: {},
      },
    };
    const result = await actions.runAiAndGet(key, resultKey, options);
    console.log("result", result);
    const newLinkedValues = JSON.parse(result).reduce((acc, item) => ({
      ...acc,
      [item]: "value",
    }));
    setLinkedValues(newLinkedValues);
    setGeneratingPageLinkedValues(false);
    console.log("linkedValues", linkedValues);
  };

  const onGenerateContentObject = async () => {
    const key = "pageContent";
    setGeneratingContentObject(true);

    const mappedPages = pages.map((page) => {
      return {
        name: page.name,
        description: page.description,
        url: page.url,
        accessTypeOfThePage: page.access || "public",
        frameworkToUse: page.style || "vanilla",
        goalOfThePage: page.goal || "landing",
        iconLibToUse: page.iconLib || "fontawesome",
      };
    });

    const options = {
      onDataStream: (data) => {
        // console.log("streaming", data);
      },
      onDataStreamEnd: (data) => {
        console.log("streaming end", data);
      },
      forcedValues: {
        parameters: {
          pageName: pageCopy.name,
          dataModels: JSON.stringify(dataModels),
          currentPages: JSON.stringify(mappedPages),
        },
      },
    };
    const result = await actions.runAiAndGet(key, null, options);
    const newContentObject = JSON.parse(result);
    setPageCopy({
      ...pageCopy,
      contentObject: newContentObject,
    });
    setGeneratingContentObject(false);
  };

  const suggestedPagesFiltered = suggestedPages.filter(
    (page) => !pagesNames.includes(page.name)
  );

  console.log("pageCopy", pageCopy);

  // sort string
  const contentObjectSortedList = Object.keys(contentObject).sort((a, b) =>
    a.localeCompare(b)
  );

  return (
    <Container>
      {isNew && suggestedPages.length > 0 && (
        <SuggestedPages
          suggestedPages={suggestedPagesFiltered}
          onSelect={(page) => {
            setPageCopy({
              ...pageCopy,
              ...page,
            });
          }}
        />
      )}
      <Form>
        <Form.Group as={Row} controlId="formPlaintextPageName" className="mb-2">
          <Form.Label column sm="2">
            Page Name
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              name="name"
              value={pageCopy.name || ""}
              onChange={handleOnChange}
            />
          </Col>
        </Form.Group>
        <Form.Group
          as={Row}
          controlId="formPlaintextPageDescription"
          className="mb-2"
        >
          <Form.Label column sm="2">
            Page Description
          </Form.Label>
          <Col sm="10">
            <AudioInput
              as="textarea"
              className="ta-sm"
              name="description"
              value={pageCopy.description || ""}
              onChange={handleOnChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formPlaintextPageUrl" className="mb-2">
          <Form.Label column sm="2">
            Page URL
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              name="url"
              value={pageCopy.url || ""}
              onChange={handleOnChange}
            />
          </Col>
        </Form.Group>

        {/* Define access for the page */}
        <Form.Group
          as={Row}
          controlId="formPlaintextPageAccess"
          className="mb-2"
        >
          <Form.Label column sm="2">
            Page Access
          </Form.Label>
          <Col sm="10">
            <Form.Control
              as="select"
              name="access"
              value={pageCopy.access || ""}
              onChange={handleOnChange}
            >
              <option value="public">Public</option>
              <option value="private-admin">Private (Admin)</option>
              <option value="private-user">Private (User)</option>
              <option value="private-user-group">Private (User/Groups)</option>
            </Form.Control>
          </Col>
        </Form.Group>

        {/* Define page style */}
        <Form.Group
          as={Row}
          controlId="formPlaintextPageStyle"
          className="mb-2"
        >
          <Form.Label column sm="2">
            Page Style
          </Form.Label>
          <Col sm="10">
            <Form.Control
              as="select"
              name="style"
              value={pageCopy.style || ""}
              onChange={handleOnChange}
            >
              {pageStyles.map((style) => (
                <option key={style.name} value={style.name}>
                  {style.label}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        {/* Allow to define icon pack lib */}
        <Form.Group
          as={Row}
          controlId="formPlaintextPageIconPack"
          className="mb-2"
        >
          <Form.Label column sm="2">
            Page Icon Pack
          </Form.Label>
          <Col sm="10">
            <Form.Control
              as="select"
              name="iconLib"
              value={pageCopy.iconLib || ""}
              onChange={handleOnChange}
            >
              {pageIconLibs.map((iconPack) => (
                <option key={iconPack.name} value={iconPack.name}>
                  {iconPack.label}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        {/* define page goals */}
        <Form.Group
          as={Row}
          controlId="formPlaintextPageGoals"
          className="mb-2"
        >
          <Form.Label column sm="2">
            Page Goals
          </Form.Label>
          <Col sm="10">
            <Form.Control
              as="select"
              name="goal"
              value={pageCopy.goal || ""}
              onChange={handleOnChange}
            >
              {pageGoals.map((goal) => (
                <option key={goal} value={goal}>
                  {goal}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formPlaintextPageType" className="mb-2">
          <Form.Label column sm="2">
            Page Type
          </Form.Label>
          <Col sm="10">
            <Form.Control
              as="select"
              name="type"
              value={pageCopy.type || ""}
              onChange={handleOnChange}
            >
              <option value="static">Static</option>
              <option value="dynamic">Dynamic</option>
            </Form.Control>
          </Col>
        </Form.Group>
      </Form>

      <hr />

      {/* Contents */}
      {/* Allow to add lines of content(each line is a key of an object, pageCopy.contentObject), each line has following property */}
      {/* text, condition, isList, dataModelsValues(multi selector for data models "modelaName.fieldName"). */}
      <h5>
        Page Contents
        {generatingContentObject && (
          <>
            <Spinner className="ms-1" animation="border" size="sm" />{" "}
            <span className="ms-1 fs-6">Generating Contents...</span>
          </>
        )}
        {!generatingContentObject && (
          <Button variant="link" size="sm" onClick={onGenerateContentObject}>
            <i className="fas fa-sync"></i> Generate Contents
          </Button>
        )}
      </h5>
      <p>Define the page contents.</p>
      <div className="mb-2">
        <Button
          variant="outline-primary"
          onClick={() => {
            setPageCopy({
              ...pageCopy,
              contentObject: {
                ...contentObject,
                [`content_${Object.keys(contentObject).length}`]: {
                  text: "",
                  condition: "",
                  isList: false,
                  dataModelsValues: [],
                },
              },
            });
          }}
        >
          <i className="fas fa-plus"></i> Add Content
        </Button>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Key</th>
            <th>Text</th>
            <th>Condition</th>
            <th>Is List</th>
            <th>Data Models Values</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {contentObjectSortedList.map((key, contentKeyIndex) => (
            <tr key={key}>
              <td>
                <Form.Control
                  type="text"
                  name="key"
                  value={key}
                  onChange={(e) => {
                    const value = e.target.value;
                    setPageCopy({
                      ...pageCopy,
                      contentObject: {
                        ...contentObject,
                        [value]: contentObject[key],
                      },
                    });
                  }}
                />
              </td>
              <td>
                <Form.Control
                  as="textarea"
                  name="text"
                  value={contentObject[key].text || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setPageCopy({
                      ...pageCopy,
                      contentObject: {
                        ...contentObject,
                        [key]: {
                          ...contentObject[key],
                          text: value,
                        },
                      },
                    });
                  }}
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  name="condition"
                  value={contentObject[key].condition || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setPageCopy({
                      ...pageCopy,
                      contentObject: {
                        ...contentObject,
                        [key]: {
                          ...contentObject[key],
                          condition: value,
                        },
                      },
                    });
                  }}
                />
              </td>
              <td>
                <Form.Check
                  type="checkbox"
                  name="isList"
                  checked={contentObject[key].isList || false}
                  onChange={(e) => {
                    const value = e.target.checked;
                    setPageCopy({
                      ...pageCopy,
                      contentObject: {
                        ...contentObject,
                        [key]: {
                          ...contentObject[key],
                          isList: value,
                        },
                      },
                    });
                  }}
                />
              </td>
              <td>
                {/* Replacing with Dropdown and checkbox */}
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-primary"
                    id="dropdown-basic"
                    size="sm"
                  >
                    <i className="fas fa-plus"></i> Link Data
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    style={{ maxHeight: "200px", overflowY: "auto" }}
                  >
                    {dataModels.map((dataModel, i) => (
                      <>
                        {dataModel.fields.map((field, indexField) => {
                          const currentDataModelValues =
                            contentObject[key].dataModelsValues || [];

                          return (
                            <Dropdown.Item
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                              key={`${dataModel.name}.${field.name}`}
                            >
                              {/* Checkbox */}
                              <Form.Check
                                inline
                                type="checkbox"
                                name="dataModelsValues"
                                value={`${dataModel.name}.${field.name}`}
                                checked={
                                  currentDataModelValues.includes(
                                    `${dataModel.name}.${field.name}`
                                  ) || false
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const checked = e.target.checked;
                                  const dataModelsValues = contentObject[key]
                                    .dataModelsValues
                                    ? contentObject[key].dataModelsValues
                                    : [];
                                  if (checked) {
                                    setPageCopy({
                                      ...pageCopy,
                                      contentObject: {
                                        ...contentObject,
                                        [key]: {
                                          ...contentObject[key],
                                          dataModelsValues: [
                                            ...dataModelsValues,
                                            value,
                                          ],
                                        },
                                      },
                                    });
                                  } else {
                                    setPageCopy({
                                      ...pageCopy,
                                      contentObject: {
                                        ...contentObject,
                                        [key]: {
                                          ...contentObject[key],
                                          dataModelsValues:
                                            dataModelsValues.filter(
                                              (item) => item !== value
                                            ),
                                        },
                                      },
                                    });
                                  }
                                }}
                              />
                              {dataModel.name}.{field.name}
                            </Dropdown.Item>
                          );
                        })}
                        {i !== dataModels.length - 1 && <Dropdown.Divider />}
                      </>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </td>
              <td>
                <Button
                  variant="outline-danger"
                  onClick={() => {
                    const newContentObject = { ...contentObject };
                    delete newContentObject[key];
                    setPageCopy({
                      ...pageCopy,
                      contentObject: newContentObject,
                    });
                  }}
                >
                  <i className="fas fa-trash"></i>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Part to link values of data models to the page */}

      {false && (
        <>
          <h5>
            Page Data{" "}
            <Button
              variant="link"
              className="ms-1"
              onClick={() => linkValuesWithAI()}
            >
              {generatingPageLinkedValues && (
                <>
                  <Spinner animation="border" size="sm" /> Linking...
                </>
              )}
              {!generatingPageLinkedValues && (
                <>
                  <i className="fas fa-sync-alt"></i> Link with AI
                </>
              )}
            </Button>
          </h5>
          <p>Link the data models to the page.</p>

          <Tab.Container id="left-tabs-example">
            <Row>
              <Col sm={12}>
                <Nav
                  className="d-flex flex-nowrap"
                  style={{ overflowX: "auto" }}
                  variant="tabs"
                >
                  {dataModels.map((dataModel) => {
                    return (
                      <Nav.Item key={dataModel.id}>
                        <Nav.Link eventKey={dataModel.name}>
                          {dataModel.name}
                        </Nav.Link>
                      </Nav.Item>
                    );
                  })}
                </Nav>
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <Tab.Content>
                  {dataModels &&
                    dataModels.map((dataModel) => (
                      <Tab.Pane eventKey={dataModel.name} key={dataModel.id}>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Field</th>
                              <th>Linked</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dataModel.fields.map((field) => {
                              return (
                                <tr key={field.name}>
                                  <td>{field.name}</td>
                                  <td>
                                    {/* Check wether it should include the field or not the page. */}
                                    <Form.Check
                                      type="checkbox"
                                      checked={
                                        linkedValues[
                                          `${dataModel.name}.${field.name}`
                                        ] !== undefined
                                      }
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setLinkedValues({
                                            ...linkedValues,
                                            [`${dataModel.name}.${field.name}`]:
                                              "",
                                          });
                                        } else {
                                          const newLinkedValues = {
                                            ...linkedValues,
                                          };
                                          delete newLinkedValues[
                                            `${dataModel.name}.${field.name}`
                                          ];
                                          setLinkedValues(newLinkedValues);
                                        }
                                      }}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Tab.Pane>
                    ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </>
      )}

      <Row className="mb-2">
        <Col className="d-flex justify-content-end">
          {!isNew && (
            <Button
              variant="link text-danger"
              onClick={() => {
                actions.selectPage(null);
                actions.deletePage(pageCopy);
                onHide && onHide();
              }}
            >
              Delete
            </Button>
          )}
          <Button variant="secondary" onClick={actions.onHide}>
            Close
          </Button>
          <Button className="ms-1" variant="primary" onClick={onSavePage}>
            Save
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export const PageConfigurationModal = ({
  webApp,
  defaultValue,
  actions,
  dataModels,
  onHide,
  pagesNames,
}) => {
  return (
    <Modal
      show
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
      fullscreen
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {defaultValue.id ? "Edit Page" : "Add New Page"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PageConfiguration
          pagesNames={pagesNames}
          onHide={onHide}
          webApp={webApp}
          defaultValue={defaultValue}
          actions={actions}
          dataModels={dataModels}
          isModal={true}
        />
      </Modal.Body>
    </Modal>
  );
};
