import React, { useState, useEffect, useRef } from "react";
import openAi from "../../apis/openAiAPI";
import { useNotif } from "../zones/NotifZone";
import GPT3Tokenizer from "gpt3-tokenizer";

import {
  Button,
  Card,
  Spinner,
  Form,
  Row,
  Col,
  Badge,
  Modal,
  Alert,
  Container,
  Dropdown,
  Popover,
  OverlayTrigger,
  ListGroup
} from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import DB from "../../database/DB";
import { ImportKitHoc } from "./ApiBuilder/DataSetsDatabase";
import DropdownGroupedByAndAutoComplete from "../UI/DropdownGroupedByAndAutoComplete";
import "./LiteTraining.css";
import TypingForm from "./LiteTraining/TypingForm";
import getSystemPromptWithType from "../../utils/getSystemPromptWithType";

const gpt3Tokenizer = new GPT3Tokenizer({ type: "gpt3" });

const fields = [
  { name: "name", type: "input", label: "Name" },
  { name: "description", type: "text", label: "Description" },
  { name: "codeImport", type: "text", label: "Code Import" },
  { name: "codeCss", type: "text", label: "Code Css" },
  { name: "codeToExecute", type: "text", label: "Code To Execute" },
  {
    name: "visibility",
    type: "select",
    values: ["private", "public"],
    label: "Visibility"
  },
  { name: "stringifiedExamples", type: "text", label: "Stringified Examples" }
];

const DataIframeIntegratorEditor = () => {
  const iframeRef = useRef(null);
  const [dataIframeIntegrators, setDataIframeIntegrators] = useState([]);
  const [dataIframeIntegrator, setDataIframeIntegrator] = useState({
    name: "",
    description: "",
    links: [],
    tags: [],
    groups: [],
    imageUrl: "",
    cssCode: "",
    jsLinks: [],
    cssLinks: [],
    jsCode: "",
    visibility: "private",
    stringifiedExamples: []
  });

  useEffect(() => {
    const iframe = iframeRef.current;
    const iframeDocument = iframe.contentDocument;

    let data = {};

    try {
      data = JSON.parse(dataIframeIntegrator.stringifiedExamples);
    } catch (error) {
      data = {};
    }

    let html = getIframeHtml({
      data
    });

    iframeDocument.open();
    iframeDocument.write(html);
    iframeDocument.close();
  }, [
    dataIframeIntegrator.codeImport,
    dataIframeIntegrator.codeCss,
    dataIframeIntegrator.codeToExecute,
    dataIframeIntegrator.stringifiedExamples
  ]);

  useEffect(() => {
    const unsubscribe = DB.dataIframeIntegrators.list(
      (newDataIframeIntegrators) => {
        setDataIframeIntegrators(newDataIframeIntegrators);
        if (newDataIframeIntegrators.length > 0) {
          setDataIframeIntegrator(newDataIframeIntegrators[0]);
        }
      }
    );

    return unsubscribe;
  }, []);

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setDataIframeIntegrator({ ...dataIframeIntegrator, [name]: value });
  };

  const handleOnSubmit = (event) => {
    const isNew = !dataIframeIntegrator.id;

    if (isNew) {
      DB.dataIframeIntegrators.create(dataIframeIntegrator);
    } else {
      DB.dataIframeIntegrators.update(dataIframeIntegrator);
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <h1>Data Iframe Integrator Editor</h1>
        <Button onClick={handleOnSubmit}>Save</Button>
      </div>
      <div className="d-flex">
        <div className="w-50">
          <Form>
            {fields.map((field) => {
              let props = {};

              if (field === "textarea") {
                props.as = "textarea";
                props.rows = 3;
              } else {
                props.type = field.type;
              }

              return (
                <Form.Group controlId={`form${field.name}`}>
                  <Form.Label>{field.label}</Form.Label>
                  <Form.Control
                    type="text"
                    name={field.name}
                    value={dataIframeIntegrator[field.name]}
                    onChange={handleOnChange}
                    {...props}
                  />
                  {}
                </Form.Group>
              );
            })}
          </Form>
        </div>
        <div className="w-50">
          <iframe
            ref={iframeRef}
            style={{ width: "100%", height: "100%" }}
          ></iframe>
        </div>
      </div>
    </div>
  );
};

const cssLinks = (links) => {
  return links.map((link) => `<link rel="stylesheet" href="${link}">`).join("");
};

const jsLinks = (links) => {
  return links.map((link) => `<script src="${link}"></script>`).join("");
};

// const isCodeValid = (code) => {
//   try {
//     eval(code);
//   } catch (error) {
//     return false;
//   }
//   return true;
// };

const getCodeToRun = (data, codeToExecute) => {
  return `
  // <script src="https://www.gstatic.com/firebasejs/8.10.1/firebase-app.js"></script>
  // <script src="https://www.gstatic.com/firebasejs/8.10.1/firebase-firestore.js"></script>
  // <script src="https://cdn.creaxys.com/code-ai-tool/ai-chain-sdk.js"></script>

  <script>
  (async () => {
    const DATA = ${JSON.stringify(data)};
    // DATA => DATA.result, DATA.params
    const DOM = document.getElementById("root");
    try {
      ${codeToExecute}
    }
    catch (error) {
      // Clean root element and print message
      const dom = document.getElementById("root");
      dom.innerHTML = "";
      dom.innerHTML = "Error Running Code: " + error;
      console.error("Error Running Code", error);
    }
  })();
  </script>
  `;
};

const verifyIframeHtmlParams = (...params) => {
  params.forEach((param) => {
    if (!param) {
      throw new Error("Missing iframe html param");
    }
  });
};

const getIframeHtml = ({ data, jsCode, cssCode, jsLinks, cssLinks }) => {
  try {
    verifyIframeHtmlParams(data, jsCode, cssCode, jsLinks, cssLinks);
  } catch (error) {
    return "";
  }

  const codeToRun = getCodeToRun(data, jsCode);
  const linksElement = jsLinks + cssLinks;

  const html = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8">
    ${linksElement}
    <style>
      ${cssCode}
    </style>
  </head>
  <body>
    <div id="root"></div>
    ${codeToRun}
  </body>
  </html>
  `;
  return html;
};

const DataIframeIntegrator = ({ data, style = {}, className = "" }) => {
  const iframeRef = useRef(null);
  const [dataIframeIntegrator, setDataIframeIntegrator] = useState(null);
  const [dataIframeIntegrators, setDataIframeIntegrators] = useState(null);
  const [html, setHtml] = useState("");

  useEffect(() => {
    const iframe = iframeRef.current;
    const iframeDocument = iframe.contentDocument;
    iframeDocument.open();
    iframeDocument.write(html);
    iframeDocument.close();
  }, [html]);

  useEffect(() => {
    if (data && dataIframeIntegrator) {
      const html = getIframeHtml({
        data,
        jsCode: dataIframeIntegrator.jsCode,
        cssCode: dataIframeIntegrator.cssCode,
        jsLinks: dataIframeIntegrator.jsLinks,
        cssLinks: dataIframeIntegrator.cssLinks
      });
      setHtml(html);
    }
  }, [data, dataIframeIntegrator]);

  useEffect(() => {
    const unsubscribe = DB.dataIframeIntegrators.list(
      (newDataIframeIntegrators) => {
        setDataIframeIntegrators(newDataIframeIntegrators);
        if (newDataIframeIntegrators.length > 0) {
          setDataIframeIntegrator(newDataIframeIntegrators[0]);
        }
      }
    );

    const onMessage = (event) => {
      const { type, dataIframeIntegrator } = event.data;
    };

    window.addEventListener("message", onMessage);

    return () => {
      window.removeEventListener("message", onMessage);
      unsubscribe();
    };
  }, []);

  return (
    <div style={style} className={className}>
      <iframe
        ref={iframeRef}
        style={{ width: "100%", height: "100%" }}
        srcDoc={html}
      ></iframe>
    </div>
  );
};

export const ReactAutocomplete = ({
  items,
  shouldItemRender,
  getItemValue,
  renderItem,
  value,
  onChange,
  onSelect,
  onKeyDownInputSelect = () => {},
  placeholder = "",
  className = "",
  classNameInput = "",
  onBlur = () => {}
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(null);
  const ref = useRef(null);

  const handleKeyDown = (event) => {
    switch (event.key) {
      case "ArrowDown":
        event.preventDefault();
        const nextIndex = highlightedIndex === null ? 0 : highlightedIndex + 1;
        setHighlightedIndex(
          nextIndex >= items.length ? items.length - 1 : nextIndex
        );
        break;
      case "ArrowUp": {
        event.preventDefault();
        const nextIndex =
          highlightedIndex === null ? items.length - 1 : highlightedIndex - 1;
        setHighlightedIndex(nextIndex < 0 ? null : nextIndex);
        break;
      }
      case "Enter": {
        event.preventDefault();
        const matchingItems = items.filter((item) =>
          shouldItemRender(item, value)
        );

        if (highlightedIndex !== null && matchingItems.length > 0) {
          onSelect(items[highlightedIndex]);
        } else {
          onKeyDownInputSelect(value);
        }
        break;
      }
      case "Escape": {
        event.preventDefault();
        setIsOpen(false);
        break;
      }
      default:
        break;
    }
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 100);
    onBlur();
  };

  const handleFocus = () => {
    setIsOpen(true);
  };

  const handleClick = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const matchingItems = items.filter((item) => shouldItemRender(item, value));

  return (
    <div ref={ref} className={className}>
      <Form.Control
        type="text"
        placeholder={placeholder}
        value={value || ""}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onFocus={handleFocus}
        className={classNameInput}
      />
      {isOpen && (
        <div
          style={{ border: "1px solid #ccc" }}
          className="react-autocomplete-dropdown"
        >
          {matchingItems.map((item, index) => (
            <div
              key={item.id || item}
              style={{
                padding: "5px 10px",
                backgroundColor:
                  highlightedIndex === index ? "#eee" : "transparent"
              }}
              onMouseDown={() => onSelect(item)}
              onMouseEnter={() => setHighlightedIndex(index)}
            >
              {renderItem(item, highlightedIndex === index)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

/**
 * @typedef {Component} BuiltPromptAdditionalForm
 * @description This component is used to display the additional form for the builtPrompt.
 * It shows fields for groupId(select), tags(input text).
 */
const BuiltPromptAdditionalForm = ({ builtPrompt, setBuiltPrompt }) => {
  const additionalContainerRef = React.useRef(null);
  const [creatingGroup, setCreatingGroup] = useState(false);
  const [creatingGroupValue, setCreatingGroupValue] = useState("");
  const [tags, setTags] = useState([]);
  const [groups, setGroups] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    const unsubscribe = DB.builtPromptTags.list((newTags) => {
      setTags(newTags);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    const unsubscribe = DB.builtPromptGroups.list((newGroups) => {
      setGroups(newGroups);
    });

    return unsubscribe;
  }, []);

  const addTagToDbIfNotExists = async (tag) => {
    const tagExists = tags.find((t) => t.name === tag);
    if (!tagExists) {
      await DB.builtPromptTags.create({ name: tag });
    }
  };

  const addTag = (tag) => {
    const newTags = [...builtPrompt.tags, tag];
    setBuiltPrompt({ ...builtPrompt, tags: newTags });
    addTagToDbIfNotExists(tag);
    setTagInput("");
  };

  const onRemoveTag = (tag) => {
    const newTags = builtPrompt.tags.filter((t) => t !== tag);
    setBuiltPrompt({ ...builtPrompt, tags: newTags });
  };

  const handleSelectGroup = (group) => {
    setBuiltPrompt({ ...builtPrompt, groupId: group.name });
  };

  const createGroup = () => {
    const newGroup = {
      name: creatingGroupValue
    };

    // create group if doesn't exist
    (async () => {
      const groupExists = groups.find((g) => g.name === newGroup.name);
      if (!groupExists) {
        await DB.builtPromptGroups.create(newGroup);
      }
    })();
    setCreatingGroup(false);
    setCreatingGroupValue("");
    setBuiltPrompt({ ...builtPrompt, groupId: newGroup.name });
  };

  const popoverGroupCreation = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Create Group</Popover.Header>
      <Popover.Body>
        <Form.Group controlId="formGroupName">
          <Form.Label>Group Name</Form.Label>
          <Form.Control
            style={{ zIndex: 1000 }}
            type="text"
            value={creatingGroupValue}
            onChange={(event) => setCreatingGroupValue(event.target.value)}
          />
        </Form.Group>
        <div className="d-flex justify-content-end mt-2">
          <Button
            variant="link"
            className="text-danger"
            onClick={() => {
              setCreatingGroupValue("");
              setCreatingGroup(false);
            }}
          >
            Cancel
          </Button>
          <Button variant="link" onClick={createGroup}>
            Create
          </Button>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <Card ref={additionalContainerRef}>
      <Card.Header>
        <Card.Title>Additional</Card.Title>
      </Card.Header>
      <Card.Body>
        <Form.Group>
          <Form.Label>
            Group
            <OverlayTrigger
              container={additionalContainerRef.current}
              onHide={() => {
                setCreatingGroupValue("");
                setCreatingGroup(false);
              }}
              onToggle={(isOpen) => {
                setCreatingGroup(isOpen);
              }}
              show={creatingGroup}
              trigger={["click"]}
              placement={"right"}
              overlay={popoverGroupCreation}
            >
              <Button variant="link">+ Create group</Button>
            </OverlayTrigger>
          </Form.Label>

          <ReactAutocomplete
            items={groups}
            shouldItemRender={(group, value) => {
              return group.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
            }}
            getItemValue={(group) => group.name}
            renderItem={(group, highlighted) => (
              <div
                key={group.id}
                style={{
                  backgroundColor: highlighted ? "#eee" : "transparent"
                }}
              >
                {group.name}
              </div>
            )}
            value={builtPrompt.groupId || ""}
            onChange={(event) => {
              setBuiltPrompt({ ...builtPrompt, groupId: event.target.value });
            }}
            onSelect={(group) => handleSelectGroup(group)}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Tags</Form.Label>

          <ReactAutocomplete
            items={tags}
            shouldItemRender={(tag, value) =>
              tag.name.toLowerCase().indexOf(value.toLowerCase()) > -1
            }
            getItemValue={(tag) => tag.name}
            renderItem={(tag, highlighted) => (
              <div
                key={tag.id}
                style={{
                  backgroundColor: highlighted ? "#eee" : "transparent"
                }}
              >
                {tag.name}
              </div>
            )}
            value={tagInput}
            placeholder="Enter tags"
            onChange={(event) => setTagInput(event.target.value)}
            onSelect={(tag) => {
              addTag(tag.name);
            }}
            onKeyDownInputSelect={addTag}
          />

          {/* <Form.Control
            type="text"
            placeholder="Enter tags"
            value={tagInput}
            onChange={(event) => setTagInput(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                onAddTags(event);
              }
            }}
          /> */}
        </Form.Group>
        <div>
          {builtPrompt.tags.map((tag) => (
            <Badge
              key={tag}
              variant="primary"
              style={{ margin: "2px", fontSize: "16px" }}
            >
              {tag}
              <i
                className="fas fa-times"
                style={{ marginLeft: "5px" }}
                role="button"
                onClick={() => onRemoveTag(tag)}
              ></i>
            </Badge>
          ))}
        </div>

        <hr />

        {/* Set visibility of the built prompt to public or private */}
        <Form.Group>
          <Form.Label>Visibility</Form.Label>
          <Form.Check
            type="switch"
            id="visibility-switch"
            label="Make this prompt public"
            checked={builtPrompt.visibility === "public"}
            onChange={(event) => {
              setBuiltPrompt({
                ...builtPrompt,
                visibility: event.target.checked ? "public" : "private"
              });
            }}
          />
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

// ClickableWord component
const ClickableWord = ({
  key,
  wordStr,
  index,
  contextMenuActions,
  isActive,
  controlContextMenuActive,
  onCloseContextMenu
}) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const contextMenuOpen =
    typeof controlContextMenuActive === "boolean"
      ? controlContextMenuActive
      : show;

  const handleClick = (event) => {
    event.preventDefault();
    setShow(!show);
    setTarget(event.target);
  };

  const additionalClassName = isActive ? "text-warning" : "";
  const text = isActive ? `${wordStr}` : wordStr;

  // disbale context menu on the word when clicked outside the word

  const onCloseContextMenuHandle =
    typeof controlContextMenuActive === "boolean"
      ? onCloseContextMenu
      : (show) => setShow(!show);

  return (
    <span
      className={`word ${isActive ? "active" : ""} ${additionalClassName}`}
      style={{ position: "relative" }}
    >
      <span style={{ cursor: "pointer" }} onContextMenu={handleClick}>
        {text}
      </span>
      {contextMenuOpen && (
        <Dropdown
          show={true}
          target={target}
          onToggle={onCloseContextMenuHandle}
          onBlur={onCloseContextMenuHandle}
          autoClose="outside"
          style={{ position: "absolute", top: 0, left: 0 }}
        >
          <Dropdown.Menu>
            {contextMenuActions.map((action) => (
              <Dropdown.Item
                key={action.label}
                onClick={() => {
                  action.onClick(wordStr, index);
                  onCloseContextMenuHandle(true);
                }}
              >
                {action.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </span>
  );
};

const isStrBreakLine = (str) => {
  return str === "\n" || str === "\r";
};

// Get the index from text area cursor position
const getWordInWordsListWithIndex = (wordsList, index) => {
  let wordIndex = 0;
  let word = "";
  let wordStartIndex = 0;
  let wordEndIndex = 0;
  for (let i = 0; i < wordsList.length; i++) {
    const word = wordsList[i].stringValue;
    wordEndIndex = wordStartIndex + word.length;
    if (index >= wordStartIndex && index <= wordEndIndex) {
      return { word, wordIndex };
    }
    wordStartIndex = wordEndIndex;
    wordIndex++;
  }
  return { word, wordIndex };
};

const splitByWordsAndSymbols = (value) => {
  const valueSplit = value
    .split(/( {1}|\{\{[A-z0-9]*\}\}|\n{1}|[^\d\s\w]+)/g)
    .filter((str) => str !== "");
  return valueSplit;
};

// function to render list of elements, it accept following parameters: text, selectedWords, openedContextMenuWord
// it returns a list of elements, 2 kind: the spans containing all the texts, the spans the selected words replaced by ma

// TextAreaWordsSelectable component renders a text area which can be clicked on words. It show a dropddown menu on the word clicked using onContextMenu event.
// It contains an hidden input which is get the focus when the user clicks on the fake text area.
// When the user is typing, a list is updated by splitting the text area value on spaces.
// This list is then used to render a list {id: index+stringValue, element, stringValue},
// the element depends on the value of the string value, if it's a word it renders a span with the drowpdown, if it's a space it renders a span with a space, if it's a new line it renders a br,
// It takes the following props: value, onChange.
// The fake text are should have a cursor: text style to be able to get the focus.
// Pass the following object to property onChange: {value, selectedWords}
//
const TextAreaWordsSelectable = ({
  value,
  selectedWords,
  onChange,
  onWordSelect
}) => {
  const hiddenInputRef = useRef(null);
  const [words, setWords] = useState([]);
  const [wordContextMenuOpened, setWordContextMenuOpened] = useState(null);

  useEffect(() => {
    // const valueSplit = getValueSplitForWordsList(value);
    const valueSplit = splitByWordsAndSymbols(value);
    const newWords = splitByWordsAndSymbols(value).map(getWord);
    setWords(newWords);
  }, [value, selectedWords, wordContextMenuOpened]);

  // set focus on text area when the user clicks on it
  const handleFakeTextAreaClick = (e) => {
    hiddenInputRef.current.focus();
  };

  // Hidden input Event Handlers
  // open context menu on right click by setting wordClicked to the word clicked with the cursor position
  const handleHiddenInputContextMenu = (e) => {
    e.preventDefault();
    const cursorPosition = e.target.selectionStart;
    const { word, wordIndex } = getWordInWordsListWithIndex(
      words,
      cursorPosition
    );
    setWordContextMenuOpened(wordIndex);
  };

  // set cursorPosition on change of the hidden input
  const handleHiddenInputChange = (e) => {
    onChange(e);
  };

  // Context Menu Event Handlers
  const contextMenuActions = [
    {
      label: "Add to prompt values",
      onClick: (wordStr, index) => {
        // in value replace the word clicked with {{wordStr}} at the cursor position
        const newValue = words.reduce((acc, curr, i) => {
          const isAdded = curr.stringValue === wordStr && i === index;
          if (isAdded) {
            return acc + `{{${wordStr}}}`;
          }
          return acc + curr.stringValue;
        }, "");
        onWordSelect(wordStr, newValue);
        setWordContextMenuOpened(null);
      }
    }
  ];

  // Other functions

  // return corresponding element depending on the string value
  const getWord = (stringValue, index) => {
    const id = index + stringValue;
    const selectedWordsWithBrackets = selectedWords.map(
      (word) => `{{${word}}}`
    );
    const isActive = selectedWordsWithBrackets.includes(stringValue);
    const isContextMenuOpened = wordContextMenuOpened === index;

    return {
      id,
      stringValue,
      contextMenuOpened: isContextMenuOpened,
      isActive
    };
  };

  return (
    <div
      style={{ position: "relative" }}
      className="mb-3 TextareaWordsSelectable"
    >
      <div className="height-referencer">
        <Form.Control
          className="hidden-input textarea-1"
          as="textarea"
          rows={3}
          value={value}
          onChange={handleHiddenInputChange}
          ref={hiddenInputRef}
          onContextMenu={handleHiddenInputContextMenu}
        />

        <div
          className="fake-textarea form-control"
          onClick={handleFakeTextAreaClick}
        >
          {words.map((word, index) => (
            <span key={word.id}>
              {word.stringValue === " " && <span key={index}>&nbsp;</span>}
              {isStrBreakLine(word.stringValue) && <br key={index} />}
              {word.stringValue !== " " &&
                !isStrBreakLine(word.stringValue) && (
                  <ClickableWord
                    id={word.id}
                    index={index}
                    isActive={word.isActive}
                    wordStr={word.stringValue}
                    contextMenuActions={contextMenuActions}
                    controlContextMenuActive={word.contextMenuOpened}
                    onCloseContextMenu={() => setWordContextMenuOpened(null)}
                  />
                )}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

// KeysListInput component renders an input which can add tags. Each tag is a key of the json object.
// It takes the following props: value, onChange.
// Tags can be removed by clicking on the cross button.
const KeysListInput = ({ keys = [], onChange }) => {
  const [keyToAdd, setKeyToAdd] = useState("");

  const addKey = () => {
    if (keyToAdd) {
      onChange([...keys, keyToAdd]);
      setKeyToAdd("");
    }
  };

  const removeKey = (key) => {
    onChange(keys.filter((k) => k !== key));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addKey();
    }
  };

  return (
    <div>
      <Form.Group as={Row}>
        <Col sm={10}>
          <Form.Control
            type="text"
            placeholder="Add a key"
            value={keyToAdd}
            onKeyDown={handleKeyDown}
            onChange={(e) => setKeyToAdd(e.target.value)}
          />
        </Col>
        <Col sm={2}>
          <Button style={{ width: "100%" }} onClick={addKey}>
            Add
          </Button>
        </Col>
      </Form.Group>
    </div>
  );
};

//  JsonInput component renders an input for each key of the json object(keys prop).
// And it asks the user to enter a value for each key.
// Then he will have a button to save the json object.
// It takes the following props: keys, jsonValue, onChange.
const JsonInput = ({ keys, jsonValue, onChange, containerRef }) => {
  // const [json, setJson] = useState(jsonValue);

  // useEffect(() => {
  //   if (Object.keys(jsonValue).length === 0) {
  //     setJson({});
  //   }
  // }, [jsonValue]);

  // useEffect(() => {
  //   onChange(json);
  // }, [json]);

  const handleKeyDown = (e) => {
    // if (e.key === "Enter") {
    //   e.preventDefault();
    //   onChange(json);
    // }
  };

  const handleInputChange = (e) => {
    // setJson({ ...json, [e.target.id]: e.target.value });
    onChange({ ...jsonValue, [e.target.id]: e.target.value });
    // onChange(json);
  };

  if (!keys || keys.length < 1) return null;

  return (
    <div className="mt-3 mb-3">
      {keys.map((key) => (
        <Form.Group as={Row} key={key} controlId={key} className="mb-3">
          <Col sm="2">
            <Form.Label>{key}</Form.Label>
            <ImportKitHoc
              variant="secondary"
              className=""
              parentRef={containerRef}
              onResult={(result) => {
                handleInputChange({
                  target: { id: key, value: result, name: key }
                });
              }}
            />
          </Col>
          <Col sm="10">
            <Form.Control
              as="textarea"
              rows={3}
              placeholder={key}
              // id={key}
              value={jsonValue[key] || ""}
              onKeyDown={handleKeyDown}
              onChange={handleInputChange}
            />
          </Col>
          <div></div>
        </Form.Group>
      ))}
    </div>
  );
};

const possibleRoles = ["user", "assistant"];

const ChatCompletionExamples = ({ examples, onExamplesChange }) => {
  const onExampleChange = (e, index, example) => {
    const newExamples = [...examples];
    const newExample = { ...example, [e.target.name]: e.target.value };
    newExamples[index] = newExample;
    onExamplesChange(newExamples);
  };

  const onExampleDelete = (index) => {
    const newExamples = [...examples];
    newExamples.splice(index, 1);
    onExamplesChange(newExamples);
  };

  const onExampleAdd = () => {
    const newExamples = [...examples];
    let newRole = "user";
    const lastExample = newExamples[newExamples.length - 1];
    const lastExampleRole = lastExample ? lastExample.role : null;

    if (lastExampleRole === "user") {
      newRole = "assistant";
    }

    newExamples.push({ role: newRole, content: "" });
    onExamplesChange(newExamples);
  };

  const onAddSystemInstruction = () => {
    let newExamples = [...examples];
    const systemInstruction = {
      role: "system",
      content: ""
    };
    newExamples = [systemInstruction, ...newExamples];
    onExamplesChange(newExamples);
  };

  const hasSystemInstruction = examples.some(
    (example) => example.role === "system"
  );

  return (
    <Row className="mt-3 mb-3">
      <Col sm={12} className="">
        <div
          className="border rounded p-3"
          style={{ maxHeight: "300px", overflowY: "auto" }}
        >
          {!hasSystemInstruction && (
            <Row>
              <Col sm={12} className="d-flex justify-content-center">
                <Button variant="link" onClick={onAddSystemInstruction}>
                  <i className="fas fa-plus"></i> Add system instructions
                </Button>
              </Col>
            </Row>
          )}
          {examples.length > 0 && (
            <Row>
              <Col sm={12}>
                {examples.map((example, index) => (
                  <Row key={index} className="mb-3">
                    <Col sm={2}>
                      <Form.Group controlId="role">
                        <Form.Label>Role</Form.Label>
                        <Form.Control
                          as="select"
                          name="role"
                          disabled={example.role === "system"}
                          value={example.role}
                          onChange={(e) => onExampleChange(e, index, example)}
                        >
                          {example.role === "system" && (
                            <option key="system" value="system">
                              system
                            </option>
                          )}
                          {example.role !== "system" &&
                            possibleRoles.map((role) => (
                              <option key={role} value={role}>
                                {role}
                              </option>
                            ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col sm={8}>
                      <Form.Group controlId="content">
                        <Form.Label>Content</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="content"
                          value={example.content}
                          onChange={(e) => onExampleChange(e, index, example)}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <Button
                        variant="danger"
                        className="mt-4"
                        onClick={() => onExampleDelete(index)}
                      >
                        Delete
                      </Button>
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          )}
          <Row>
            <Col sm={12} className="d-flex justify-content-center">
              <Button variant="link" onClick={onExampleAdd}>
                <i className="fas fa-plus"></i> Add example
              </Button>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

// property =>
// {
//   description: string,
//   enum?: string[],
// }

const InputTags = ({ value, onChange, label }) => {
  const [newTag, setNewTag] = useState("");
  const [tags, setTags] = useState(value || []);

  const handleDelete = (i) => {
    const newTags = [...tags];
    newTags.splice(i, 1);
    setTags(newTags);
    onChange(newTags);
  };

  const handleAddition = () => {
    if (!newTag || newTag.length === 0) {
      return;
    }
    const newTags = [...tags, newTag];
    setTags(newTags);
    setNewTag("");
    onChange(newTags);
  };

  const handleNewTagChange = (e) => {
    setNewTag(e.target.value);
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      handleAddition();
    }
  };

  return (
    <React.Fragment>
      <Form.Group controlId={`new-tag-form`} className="mb-2">
        <Form.Label>{label}</Form.Label>
        <Form.Control
          type="text"
          name="newTag"
          onKeyDown={onKeyDown}
          value={newTag}
          onChange={handleNewTagChange}
        />
      </Form.Group>
      <div>
        {tags.map((tag, index) => (
          <Badge key={index} className="fs-5 bg-light text-dark me-2">
            {tag}{" "}
            <span
              className="ml-1"
              onClick={() => handleDelete(index)}
              style={{ cursor: "pointer" }}
            >
              x
            </span>
          </Badge>
        ))}
      </div>
    </React.Fragment>
  );
};

const StringPropertyForm = ({ property, onChange, index }) => {
  const [propertyState, setPropertyState] = useState(property);
  const mustChange = React.useRef(false);

  useEffect(() => {
    if (mustChange.current) {
      mustChange.current = false;
      onChange(propertyState);
    }
  }, [propertyState, onChange]);

  const handlePropertyChange = (event) => {
    const { name, value } = event.target;
    const newProperty = { ...propertyState, [name]: value };

    if (["enum"].includes(name)) {
      mustChange.current = true;
    }

    setPropertyState(newProperty);
  };

  const onBlur = () => {
    onChange(propertyState);
  };

  return (
    <React.Fragment key={index}>
      <Form.Group controlId={`property-description-${index}`}>
        <Form.Label>Description:</Form.Label>
        <Form.Control
          type="text"
          name="description"
          onBlur={onBlur}
          value={propertyState.description || ""}
          onChange={handlePropertyChange}
        />
      </Form.Group>
      <Form.Group controlId={`property-enum-${index}`}>
        <InputTags
          value={propertyState.enum || []}
          onChange={(value) =>
            handlePropertyChange({ target: { name: "enum", value } })
          }
          label="Enum:"
        />
        {/* <Form.Label>Enum:</Form.Label>
      <Form.Control type="text" name="enum" onBlur={onBlur} value={property.enum ? property.enum.join(',') : ''} onChange={handlePropertyChange} /> */}
      </Form.Group>
    </React.Fragment>
  );
};

const numberPropertyToForm = (property) => {
  const form = {
    type: property.type,
    description: property.description,
    key: property.key,
    required: property.required
  };

  if (property.minimum !== undefined && property.minimum !== null) {
    form.minimum = property.minimum;
    form.hasMinimum = true;
    if (
      property.exclusiveMinimum !== undefined ||
      property.exclusiveMinimum !== null
    ) {
      form.exclusiveMinimum = property.exclusiveMinimum;
    }
  }
  if (property.maximum !== undefined && property.maximum !== null) {
    form.maximum = property.maximum;
    form.hasMaximum = true;
    if (
      property.exclusiveMaximum !== undefined ||
      property.exclusiveMaximum !== null
    ) {
      form.exclusiveMaximum = property.exclusiveMaximum;
    }
  }
  if (property.multipleOf !== undefined && property.multipleOf !== null) {
    form.multipleOf = property.multipleOf;
    form.hasMultipleOf = true;
  }

  return form;
};

const numberFormToProperty = (form) => {
  const property = {
    type: form.type,
    description: form.description,
    key: form.key,
    required: form.required
  };

  if (form.hasMinimum) {
    property.minimum = parseFloat(form.minimum || 0);
    if (form.exclusiveMinimum) {
      property.exclusiveMinimum = form.exclusiveMinimum;
    }
  }
  if (form.hasMaximum) {
    property.maximum = parseFloat(form.maximum || 0);
    if (form.exclusiveMaximum) {
      property.exclusiveMaximum = form.exclusiveMaximum;
    }
  }
  if (form.hasMultipleOf) {
    property.multipleOf = form.multipleOf;
  }

  return property;
};

const NumberPropertyForm = ({ property, onChange, index }) => {
  const [propertyState, setPropertyState] = useState(
    numberPropertyToForm(property)
  );
  const mustChange = React.useRef(false);

  useEffect(() => {
    if (mustChange.current) {
      mustChange.current = false;
      const formToSave = numberFormToProperty(propertyState);
      onChange(formToSave);
    }
  }, [propertyState, onChange]);

  const handlePropertyChange = (event) => {
    const { name, value } = event.target;
    const newProperty = { ...propertyState, [name]: value };

    setPropertyState(newProperty);
  };

  const onBlur = () => {
    const formToSave = numberFormToProperty(propertyState);
    onChange(formToSave);
  };

  const onCheck = (name, value) => {
    const newProperty = { ...propertyState, [name]: value };
    mustChange.current = true;
    setPropertyState(newProperty);
  };

  return (
    <React.Fragment key={index}>
      <Form.Group
        className="mb-3 form-group-container"
        controlId={`property-description-${index}`}
      >
        <Form.Label>Description:</Form.Label>
        <p className="text-white-5">
          <i className="fas fa-info-circle"></i> The description input is
          currently disabled because it is not supported by the API.
        </p>
        <Form.Control
          type="text"
          name="description"
          onBlur={onBlur}
          value={propertyState.description}
          onChange={handlePropertyChange}
          disabled={true}
        />
      </Form.Group>

      <div className="horizontal-separator my-3"></div>

      <Form.Group
        className="mb-3 form-group-container"
        controlId={`property-hasMinimum-${index}`}
      >
        <Form.Label>
          Has minimum:{" "}
          <i
            role="button"
            onClick={() => onCheck("hasMinimum", !propertyState.hasMinimum)}
            className={`fas fa-${
              propertyState.hasMinimum ? "check-square" : "square"
            }`}
          ></i>
        </Form.Label>
      </Form.Group>
      {propertyState.hasMinimum && (
        <div className="">
          <Form.Group
            className="mb-3 form-group-container"
            controlId={`property-minimum-${index}`}
          >
            <Form.Label>Minimum:</Form.Label>
            <Form.Control
              type="number"
              name="minimum"
              onBlur={onBlur}
              value={propertyState.minimum || ""}
              onChange={handlePropertyChange}
            />
          </Form.Group>
          <Form.Group
            className="mb-3 form-group-container"
            controlId={`property-exclusiveMinimum-${index}`}
          >
            <Form.Label>
              Exclusive minimum:{" "}
              <i
                role="button"
                onClick={() =>
                  onCheck("exclusiveMinimum", !propertyState.exclusiveMinimum)
                }
                className={`fas fa-${
                  propertyState.exclusiveMinimum ? "check-square" : "square"
                }`}
              ></i>
            </Form.Label>
          </Form.Group>
        </div>
      )}
      <div className="horizontal-separator mb-3"></div>
      <Form.Group
        className="mb-3 form-group-container"
        controlId={`property-hasMaximum-${index}`}
      >
        <Form.Label>
          Has maximum:{" "}
          <i
            role="button"
            onClick={() => onCheck("hasMaximum", !propertyState.hasMaximum)}
            className={`fas fa-${
              propertyState.hasMaximum ? "check-square" : "square"
            }`}
          ></i>
        </Form.Label>
      </Form.Group>
      {propertyState.hasMaximum && (
        <div className="mb-3">
          <Form.Group
            className="mb-3 form-group-container"
            controlId={`property-maximum-${index}`}
          >
            <Form.Label>Maximum:</Form.Label>
            <Form.Control
              type="number"
              name="maximum"
              onBlur={onBlur}
              value={propertyState.maximum || ""}
              onChange={handlePropertyChange}
            />
          </Form.Group>
          <Form.Group
            className="mb-3 form-group-container"
            controlId={`property-exclusiveMaximum-${index}`}
          >
            <Form.Label>
              Exclusive maximum:{" "}
              <i
                role="button"
                onClick={() =>
                  onCheck("exclusiveMaximum", !propertyState.exclusiveMaximum)
                }
                className={`fas fa-${
                  propertyState.exclusiveMaximum ? "check-square" : "square"
                }`}
              ></i>
            </Form.Label>
          </Form.Group>
          <div className="horizontal-separator"></div>
        </div>
      )}
      <Form.Group
        className="mb-3 form-group-container"
        controlId={`property-hasMultipleOf-${index}`}
      >
        <Form.Label>
          Has multiple of:{" "}
          <i
            role="button"
            onClick={() =>
              onCheck("hasMultipleOf", !propertyState.hasMultipleOf)
            }
            className={`fas fa-${
              propertyState.hasMultipleOf ? "check-square" : "square"
            }`}
          ></i>
        </Form.Label>
      </Form.Group>
      {propertyState.hasMultipleOf && (
        <>
          <Form.Group
            className="mb-3 form-group-container"
            controlId={`property-multipleOf-${index}`}
          >
            <Form.Label>Multiple of:</Form.Label>
            <Form.Control
              type="number"
              name="multipleOf"
              onBlur={onBlur}
              value={propertyState.multipleOf || ""}
              onChange={handlePropertyChange}
            />
          </Form.Group>
        </>
      )}
    </React.Fragment>
  );
};

const BooleanPropertyForm = ({ property, onChange, index }) => {
  const [propertyState, setPropertyState] = useState(property);

  const handlePropertyChange = (event) => {
    const { name, value } = event.target;
    const newProperty = { ...propertyState, [name]: value };
    setPropertyState(newProperty);
  };

  const onBlur = () => {
    onChange(propertyState);
  };

  return (
    <React.Fragment key={index}>
      <Form.Group controlId={`property-description-${index}`}>
        <Form.Label>Description:</Form.Label>
        <Form.Control
          type="text"
          name="description"
          onBlur={onBlur}
          value={propertyState.description}
          onChange={handlePropertyChange}
        />
      </Form.Group>
    </React.Fragment>
  );
};

const ArrayPropertyForm = ({ property, onChange, index }) => {
  const [propertyState, setPropertyState] = useState(property);

  const handlePropertyChange = (event) => {
    const { name, value } = event.target;
    const newProperty = { ...propertyState, [name]: value };
    setPropertyState(newProperty);
  };

  const handleArrayItemsChange = (event) => {
    const { name, value } = event.target;

    const newProperty = { ...propertyState };
    if (name === "itemsType") {
      newProperty.items = { type: value };
    } else {
      newProperty.items[name] = value;
    }
    setPropertyState(newProperty);
  };

  const onBlur = () => {
    onChange(propertyState);
  };

  const items = propertyState.items || { type: "string" };

  return (
    <React.Fragment key={index}>
      <Form.Group controlId={`property-description-${index}`}>
        <Form.Label>Description:</Form.Label>
        <Form.Control
          type="text"
          name="description"
          onBlur={onBlur}
          value={propertyState.description}
          onChange={handlePropertyChange}
        />
        <Form.Text className="text-muted">Describe the property</Form.Text>
      </Form.Group>
      <Form.Group
        className="mb-3 form-group-container"
        controlId={`items-type-${index}`}
      >
        <Form.Label>Items type:</Form.Label>
        <Form.Control
          as="select"
          name="itemsType"
          onBlur={onBlur}
          value={items.type}
          onChange={handleArrayItemsChange}
        >
          <option value="string">String</option>
          <option value="number">Number</option>
          <option value="boolean">Boolean</option>
          <option value="array">Array</option>
          <option value="object">Object</option>
        </Form.Control>
      </Form.Group>
    </React.Fragment>
  );
};

const propertiesObjToArray = (properties, required) => {
  const propertiesArray = [];
  Object.keys(properties).forEach((key) => {
    const isRequired = required.includes(key);
    propertiesArray.push({ key, ...properties[key], required: isRequired });
  });
  return propertiesArray;
};

const securePropertySave = (property) => {
  const { type, description } = property;

  let baseProperties = {};

  if (description && description.length > 0) {
    baseProperties.description = description;
  }

  if (type === "string") {
    const enumValues = property.enum;
    console.log("enumValues", enumValues);
    if (enumValues && enumValues.length > 0) {
      baseProperties.enum = enumValues;
    }
    return { type, ...baseProperties };
  } else if (["integer", "float", "number"].includes(type)) {
    const authorizedKeys = [
      "minimum",
      "maximum",
      "exclusiveMinimum",
      "exclusiveMaximum",
      "multipleOf"
    ];
    const objToReturn = Object.keys(property).reduce((acc, key) => {
      if (
        authorizedKeys.includes(key) &&
        property[key] !== undefined &&
        property[key] !== null
      ) {
        acc[key] = property[key];
      }
      return acc;
    }, {});
    return { type: "number", ...objToReturn };
  } else if (type === "boolean") {
    return { type, ...baseProperties };
  } else if (type === "array") {
    return { type, ...baseProperties, items: property.items };
  }
};

function FunctionsParametersObject(props) {
  const [toggledProperties, setToggledProperties] = useState({});
  const { properties, onPropertiesChange, required = [] } = props;
  const [newProperty, setNewProperty] = useState({
    key: "",
    type: "string",
    description: "",
    enum: []
  });
  const [propertiesList, setPropertiesList] = useState(
    propertiesObjToArray(properties, required)
  );
  const [showAddProperty, setShowAddProperty] = useState(false);
  const shouldAddProperty = React.useRef(false);

  useEffect(() => {
    if (shouldAddProperty.current) {
      shouldAddProperty.current = false;
      onPropertiesChangeHandler();
    }
  }, [propertiesList]);

  const handlePropertyChange = (event, index) => {
    const { name, value } = event.target;
    const newPropertiesList = [...propertiesList];
    newPropertiesList[index][name] = value;
    if (["required"].includes(name)) {
      shouldAddProperty.current = true;
    }
    setPropertiesList(newPropertiesList);
  };

  const handlePropertyChangeObj = (property, index) => {
    const newPropertiesList = [...propertiesList];
    newPropertiesList[index] = property;
    shouldAddProperty.current = true;
    setPropertiesList(newPropertiesList);
  };

  function handleAddProperty() {
    const newKey = newProperty.key.trim();
    if (newKey === "") {
      return;
    }
    const newPropertiesList = [...propertiesList];
    newPropertiesList.push({ key: newKey, ...newProperty });
    shouldAddProperty.current = true;
    setPropertiesList(newPropertiesList);
    setNewProperty({ key: "", type: "string", description: "", enum: [] });
    setShowAddProperty(false);
  }

  function handleNewPropertyKeyChange(event) {
    const newKey = event.target.value;
    setNewProperty((prevProperty) => ({ ...prevProperty, key: newKey }));
  }

  function handleNewPropertyTypeChange(event) {
    const newType = event.target.value;
    setNewProperty((prevProperty) => ({ ...prevProperty, type: newType }));
  }

  function handleNewPropertyDescriptionChange(event) {
    const newDescription = event.target.value;
    setNewProperty((prevProperty) => ({
      ...prevProperty,
      description: newDescription
    }));
  }

  function handleNewPropertyEnumChange(event) {
    const newEnum = event.target.value.split(",");
    setNewProperty((prevProperty) => ({ ...prevProperty, enum: newEnum }));
  }

  const handleOnNewPropertyChange = (property) => {
    setNewProperty(property);
  };

  const onPropertiesChangeHandler = () => {
    let newProperties = {};
    let newRequired = [];

    propertiesList.forEach((property) => {
      const { key, type, required: isRequired } = property;
      const propertyToSave = securePropertySave(property);
      if (propertyToSave) {
        newProperties[key] = { ...propertyToSave };
        if (isRequired) {
          newRequired.push(key);
        }
      }
    });
    onPropertiesChange(newProperties, newRequired);
  };

  const onBlur = () => {
    onPropertiesChangeHandler();
  };

  return (
    <div>
      {propertiesList.map((property, index) => (
        <div
          key={`property-${index}`}
          className="function-property-container p-3 mb-3"
        >
          <p
            role="button"
            className="mb-0"
            onClick={() =>
              setToggledProperties({
                ...toggledProperties,
                [index]: !toggledProperties[index]
              })
            }
          >
            <strong>{property.key}</strong> ({property.type})
          </p>
          {toggledProperties[index] && (
            <>
              <div className="horizontal-separator mt-3 mb-3"></div>

              <Form.Group
                className="mb-3 form-group-container"
                controlId={`property-key-${index}`}
              >
                <Form.Label>Key:</Form.Label>
                <Form.Control
                  type="text"
                  name="key"
                  onBlur={onBlur}
                  value={property.key || ""}
                  onChange={(event) => handlePropertyChange(event, index)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3 form-group-container"
                controlId={`property-type-${index}`}
              >
                <Form.Label>Type:</Form.Label>
                <Form.Control
                  as="select"
                  name="type"
                  onBlur={onBlur}
                  value={property.type}
                  onChange={(event) => handlePropertyChange(event, index)}
                >
                  <option value="string">string</option>
                  <option value="number">number</option>
                  {/* <option value="integer">integer</option>
                <option value="float">float</option> */}
                  <option value="boolean">boolean</option>
                  <option value="array">array</option>
                  <option value="object">object</option>
                </Form.Control>
              </Form.Group>
              {property.type === "string" && (
                <StringPropertyForm
                  property={property}
                  index={index}
                  onChange={(newProperty) =>
                    handlePropertyChangeObj(newProperty, index)
                  }
                />
              )}
              {property.type === "number" && (
                <NumberPropertyForm
                  property={property}
                  index={index}
                  onChange={(newProperty) =>
                    handlePropertyChangeObj(newProperty, index)
                  }
                />
              )}
              {property.type === "integer" && (
                <NumberPropertyForm
                  property={property}
                  index={index}
                  onChange={(newProperty) =>
                    handlePropertyChangeObj(newProperty, index)
                  }
                />
              )}
              {property.type === "float" && (
                <NumberPropertyForm
                  property={property}
                  index={index}
                  onChange={(newProperty) =>
                    handlePropertyChangeObj(newProperty, index)
                  }
                />
              )}
              {property.type === "boolean" && (
                <BooleanPropertyForm
                  property={property}
                  index={index}
                  onChange={(newProperty) =>
                    handlePropertyChangeObj(newProperty, index)
                  }
                />
              )}
              {property.type === "array" && (
                <ArrayPropertyForm
                  property={property}
                  index={index}
                  onChange={(newProperty) =>
                    handlePropertyChangeObj(newProperty, index)
                  }
                />
              )}

              <Form.Group
                controlId={`property-required-${index}`}
                className="mt-3 form-group-container"
              >
                <Form.Check
                  type="checkbox"
                  name="required"
                  label="Required"
                  checked={property.required || false}
                  onChange={(event) => handlePropertyChange(event, index)}
                />
              </Form.Group>
            </>
          )}
        </div>
      ))}
      {showAddProperty ? (
        <div className="function-new-property-container p-3 mb-3">
          <p className="mb-3 fs-5">Create new property</p>
          <div className="horizontal-separator mb-3"></div>
          <Form.Group controlId="new-property-key">
            <Form.Label>Key:</Form.Label>
            <Form.Control
              type="text"
              value={newProperty.key}
              onChange={handleNewPropertyKeyChange}
            />
          </Form.Group>
          <Form.Group controlId="new-property-type">
            <Form.Label>Type:</Form.Label>
            <Form.Control
              as="select"
              value={newProperty.type}
              onChange={handleNewPropertyTypeChange}
            >
              <option value="string">string</option>
              <option value="integer">integer</option>
              <option value="float">float</option>
              <option value="boolean">boolean</option>
              <option value="array">array</option>
              <option value="object">object</option>
            </Form.Control>
          </Form.Group>
          {/* <Form.Group controlId="new-property-description">
            <Form.Label>Description:</Form.Label>
            <Form.Control type="text" value={newProperty.description} onChange={handleNewPropertyDescriptionChange} />
          </Form.Group>
          <Form.Group controlId="new-property-enum">
            <Form.Label>Enum:</Form.Label>
            <Form.Control type="text" value={newProperty.enum ? newProperty.enum.join(',') : ''} onChange={handleNewPropertyEnumChange} />
          </Form.Group> */}

          {newProperty.type === "string" && (
            <StringPropertyForm
              property={newProperty}
              index={1}
              onChange={(newProperty) => handleOnNewPropertyChange(newProperty)}
            />
          )}
          {newProperty.type === "number" && (
            <NumberPropertyForm
              property={newProperty}
              index={1}
              onChange={(newProperty) => handleOnNewPropertyChange(newProperty)}
            />
          )}
          {newProperty.type === "integer" && (
            <NumberPropertyForm
              property={newProperty}
              index={1}
              onChange={(newProperty) => handleOnNewPropertyChange(newProperty)}
            />
          )}
          {newProperty.type === "float" && (
            <NumberPropertyForm
              property={newProperty}
              index={1}
              onChange={(newProperty) => handleOnNewPropertyChange(newProperty)}
            />
          )}
          {newProperty.type === "boolean" && (
            <BooleanPropertyForm
              property={newProperty}
              index={1}
              onChange={(newProperty) => handleOnNewPropertyChange(newProperty)}
            />
          )}
          {newProperty.type === "array" && (
            <ArrayPropertyForm
              property={newProperty}
              index={1}
              onChange={(newProperty) => handleOnNewPropertyChange(newProperty)}
            />
          )}

          <Form.Group controlId="new-property-required" className="mt-2 mb-2">
            <Form.Check
              type="checkbox"
              label="Required"
              checked={newProperty.required || false}
              onChange={(event) =>
                setNewProperty((prevProperty) => ({
                  ...prevProperty,
                  required: event.target.checked
                }))
              }
            />
          </Form.Group>
          <Button variant="primary" onClick={handleAddProperty}>
            Save new property
          </Button>
        </div>
      ) : (
        <Button variant="link" onClick={() => setShowAddProperty(true)}>
          Add new property
        </Button>
      )}
    </div>
  );
}

const FunctionConfigModal = (props) => {
  const {
    show,
    onHide,
    function: func,
    onFunctionChange,
    onNewFunction
  } = props;
  const [functionState, setFunctionState] = useState(
    func || {
      name: "",
      description: "",
      parameters: { type: "object", properties: {} }
    }
  );
  const isNew = !func;

  function handlePropertiesChange(newProperties, newRequired) {
    setFunctionState((prevFunction) => ({
      ...prevFunction,
      parameters: {
        ...prevFunction.parameters,
        properties: newProperties,
        required: newRequired
      }
    }));
  }

  const handleOnChange = (event) => {
    setFunctionState((prevFunction) => ({
      ...prevFunction,
      [event.target.name]: event.target.value
    }));
  };

  const handleSave = () => {
    if (!isNew) {
      onFunctionChange(functionState);
    } else {
      onNewFunction(functionState);
    }
    onHide();
  };

  const handleFunctionTypeChange = (event) => {
    const newType = event.target.value;

    if (newType === "object") {
      setFunctionState((prevFunction) => ({
        ...prevFunction,
        parameters: { type: "object", properties: {} }
      }));
    } else if (newType === "array") {
      setFunctionState((prevFunction) => ({
        ...prevFunction,
        parameters: { type: "array", items: { type: "string" } }
      }));
    } else if (newType === "string") {
      setFunctionState((prevFunction) => ({
        ...prevFunction,
        parameters: { type: "string", description: "" }
      }));
    } else if (newType === "integer") {
      setFunctionState((prevFunction) => ({
        ...prevFunction,
        parameters: { type: "integer" }
      }));
    } else if (newType === "float") {
      setFunctionState((prevFunction) => ({
        ...prevFunction,
        parameters: { type: "float" }
      }));
    } else if (newType === "boolean") {
      setFunctionState((prevFunction) => ({
        ...prevFunction,
        parameters: { type: "boolean" }
      }));
    }
  };

  const functionReturnType = functionState.parameters.type;

  return (
    // Add background layer to modal
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      backdrop="static"
      className="function-config-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Function Configuration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Configure the function name, description and parameters.</p>
        <div className="horizontal-separator mb-3"></div>
        <p className="fs-5 fw-bold mb-3">Function</p>
        <Form.Group controlId="function-name" className="mb-3">
          <Form.Label>Name:</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={functionState.name}
            onChange={handleOnChange}
          />
        </Form.Group>
        <Form.Group controlId="function-description" className="mb-3">
          <Form.Label>Description:</Form.Label>
          <Form.Control
            type="text"
            name="description"
            value={functionState.description}
            onChange={handleOnChange}
          />
        </Form.Group>
        {/* Function return type */}
        <Form.Group controlId="function-return-type" className="mb-3">
          <Form.Label>Return type:</Form.Label>
          <Form.Control
            as="select"
            name="returnType"
            value={functionReturnType}
            onChange={handleFunctionTypeChange}
          >
            <option value="string">string</option>
            <option value="number">number</option>
            <option value="boolean">boolean</option>
            <option value="array">array</option>
            <option value="object">object</option>
          </Form.Control>
        </Form.Group>
        <div className="horizontal-separator mb-3"></div>
        <Form.Group controlId="function-parameters">
          <Form.Label className="fs-5 fw-bold mb-3">Parameters</Form.Label>

          {functionReturnType === "object" && (
            <FunctionsParametersObject
              properties={functionState.parameters.properties}
              required={functionState.parameters.required}
              onPropertiesChange={handlePropertiesChange}
            />
          )}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

function FunctionsConfig(props) {
  const { functions = [], onFunctionsChange } = props;
  const [showModal, setShowModal] = useState(false);
  const [selectedFunctionIndex, setSelectedFunctionIndex] = useState(null);

  function handleAddFunction(newFunction) {
    const updatedFunctions = [...functions, newFunction];
    onFunctionsChange(updatedFunctions);
  }

  function handleEditFunction(index) {
    setSelectedFunctionIndex(index);
    setShowModal(true);
  }

  function handleRemoveFunction(index) {
    const updatedFunctions = functions.filter((_, i) => i !== index);
    onFunctionsChange(updatedFunctions);
  }

  const handleFunctionOnChange = (newFunction) => {
    const updatedFunctions = [...functions];
    const i = selectedFunctionIndex;
    updatedFunctions[i] = newFunction;
    onFunctionsChange(updatedFunctions);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedFunctionIndex(null);
  };

  const onAddFunctionModal = () => {
    setSelectedFunctionIndex(null);
    setShowModal(true);
  };

  const currentFunction =
    selectedFunctionIndex !== null ? functions[selectedFunctionIndex] : null;

  return (
    <div className="border rounded p-3 mb-3 mt-3">
      <ListGroup>
        {functions.map((func, index) => (
          <ListGroup.Item key={index}>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <div>
                  <strong>{func.name}</strong>
                  <p>{func.description}</p>
                </div>
                <div>
                  <Button
                    variant="link"
                    onClick={() => handleEditFunction(index)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="link"
                    onClick={() => handleRemoveFunction(index)}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>

      <Row>
        <Col sm={12} className="d-flex justify-content-center">
          <Button variant="link" onClick={() => setShowModal(true)}>
            <i className="fas fa-plus"></i> Add function
          </Button>
        </Col>
      </Row>
      {showModal && (
        <FunctionConfigModal
          show={showModal}
          onHide={closeModal}
          function={currentFunction}
          onFunctionChange={handleFunctionOnChange}
          onNewFunction={handleAddFunction}
        />
      )}
    </div>
  );
}

const modelsList = [
  "gpt-4",
  "gpt-4-turbo-preview",
  "gpt-3.5-turbo",
  "gpt-3.5-turbo-16k"
  // "gpt-4-0125-preview",
  // "gpt-4-1106-preview",
  // "gpt-4-32k",
  // "text-davinci-003",
  // "code-davinci-002",
  // "gpt-3.5-turbo-0613",
  // "gpt-4-0613",
  // "gpt-3.5-turbo-16k-0613"
];

const chatModelsList = [
  "gpt-4",
  "gpt-4-turbo-preview",
  "gpt-4-0125-preview",
  "gpt-4-1106-preview",
  "gpt-3.5-turbo",
  "gpt-3.5-turbo-16k",
  "gpt-4-32k",
  "gpt-3.5-turbo-0613",
  "gpt-4-0613",
  "gpt-3.5-turbo-16k-0613"
];

const functionModels = [
  "gpt-3.5-turbo-0613",
  "gpt-4-0613",
  "gpt-3.5-turbo-16k-0613"
];

const LiteTrainingContent = ({
  apiProjectId,
  currentBuiltPrompt,
  builtPrompts,
  isFromModal,
  onSelectAndClose,
  onBuiltPromptIdChange,
  defaultBuiltPrompt = {}
}) => {
  const containerRef = React.useRef(null);
  const { notify } = useNotif();
  const [availableModels, setAvailableModels] = useState(null);
  // Test
  const [promptJsonValueTest, setPromptJsonValueTest] = useState({});
  const [textGeneratedTest, setTextGeneratedTest] = useState("");
  const [isTestLoading, setIsTestLoading] = useState(false);
  // Prompt
  const [isLoading, setIsLoading] = useState(false);
  const [showTyping, setShowTyping] = useState(false);
  // DB
  const [builtPromptFormValues, setBuiltPromptFormValues] = useState({
    promptName: "",
    prompt: "",
    valueKeys: [],
    example: "",
    examples: [],
    groupId: "",
    tags: [],
    model: "gpt-4",
    visibility: "private",
    typing: {
      result: "",
      globalType: "JSON", // JSON_OBJECT, JSON_ARRAY, STRING, NUMBER, BOOLEAN
      enums: []
    },
    functions: [],
    ...defaultBuiltPrompt
  });

  const isNewPrompt = !currentBuiltPrompt;
  const navigate = useNavigate();
  const isChatModel = chatModelsList.includes(builtPromptFormValues.model);
  const modelHasFunctions = functionModels.includes(
    builtPromptFormValues.model
  );

  const cleanFormsAndValues = () => {
    setBuiltPromptFormValues({
      promptName: "",
      prompt: "",
      valueKeys: [],
      example: "",
      examples: [],
      groupId: "",
      tags: [],
      model: getDefaultModel(),
      visibility: "private",
      functions: [],
      ...defaultBuiltPrompt,
      typing: {
        result: "",
        globalType: "JSON"
      }
    });
    setTextGeneratedTest("");
  };

  useEffect(() => {
    const f = async () => {
      const models = await openAi.models();

      let availableModelsAllowed = models.filter((model) => {
        return modelsList.includes(model);
      });

      setAvailableModels(availableModelsAllowed);
    };
    f();
  }, []);

  useEffect(() => {
    if (currentBuiltPrompt && availableModels) {
      setBuiltPromptFormValues({
        promptName: currentBuiltPrompt.promptName || "",
        prompt: currentBuiltPrompt.prompt || "",
        valueKeys: currentBuiltPrompt.valueKeys || "",
        example: currentBuiltPrompt.example || "",
        examples: currentBuiltPrompt.examples || [],
        groupId: currentBuiltPrompt.groupId || "",
        tags: currentBuiltPrompt.tags || [],
        model: currentBuiltPrompt.model || getDefaultModel(),
        visibility: currentBuiltPrompt.visibility || "private",
        functions: currentBuiltPrompt.functions || [],
        typing: currentBuiltPrompt.typing || {
          result: "",
          globalType: "JSON"
        }
      });
    } else {
      cleanFormsAndValues();
    }
  }, [currentBuiltPrompt, availableModels]);

  const getDefaultModel = () => {
    // default priority: gpt-4, gpt-3.5-turbo.

    if (availableModels) {
      if (availableModels.includes("gpt-4")) {
        return "gpt-4";
      } else if (availableModels.includes("gpt-3.5-turbo")) {
        return "gpt-3.5-turbo";
      } else {
        return availableModels[0];
      }
    } else {
      return "";
    }
  };

  const savePrompt = async () => {
    setIsLoading(true);
    try {
      if (isNewPrompt) {
        let newId = null;
        if (apiProjectId) {
          newId = await DB.apiProjects.sub.builtPrompts.create(apiProjectId, {
            ...builtPromptFormValues
          });
        } else {
          newId = await DB.builtPrompt.create({
            ...builtPromptFormValues
          });
        }
        notify({
          title: "Prompt created",
          message: "Your prompt has been created with id " + newId,
          variant: "success"
        });
        cleanFormsAndValues();
        if (!isFromModal) {
          navigate(`/lite-training/${newId}`);
        } else if (isFromModal) {
          onBuiltPromptIdChange(newId);
        }
        setIsLoading(false);
        return newId;
      } else {
        if (apiProjectId) {
          await DB.apiProjects.sub.builtPrompts.update(
            apiProjectId,
            currentBuiltPrompt.id,
            {
              ...builtPromptFormValues
            }
          );
        } else {
          await DB.builtPrompt.update(currentBuiltPrompt.id, {
            ...builtPromptFormValues
          });
        }
        notify({
          title: "Prompt updated",
          message: "Your prompt has been updated",
          variant: "success"
        });
        setIsLoading(false);
        return currentBuiltPrompt.id;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteBuiltPrompt = async () => {
    setIsLoading(true);
    try {
      if (apiProjectId) {
        await DB.apiProjects.sub.builtPrompts.delete(
          apiProjectId,
          currentBuiltPrompt.id
        );
      } else {
        await DB.builtPrompt.delete(currentBuiltPrompt.id);
      }
      notify({
        title: "Prompt deleted",
        message: "Your prompt has been deleted",
        variant: "danger"
      });
      cleanFormsAndValues();
      navigate(`/lite-training`);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const cloneBuiltPromptToNew = async () => {
    setIsLoading(true);
    try {
      let newId = null;
      if (apiProjectId) {
        newId = await DB.apiProjects.sub.builtPrompts.create(apiProjectId, {
          ...builtPromptFormValues,
          promptName: builtPromptFormValues.promptName + " (clone)"
        });
      } else {
        newId = await DB.builtPrompt.create({
          ...builtPromptFormValues,
          promptName: builtPromptFormValues.promptName + " (clone)"
        });
      }
      notify({
        title: "Prompt cloned",
        message: "Your prompt has been cloned with id " + newId,
        variant: "success"
      });
      cleanFormsAndValues();
      setIsLoading(false);
      if (!isFromModal) {
        navigate(`/lite-training/${newId}`);
      } else if (isFromModal) {
        onBuiltPromptIdChange(newId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const savePromptAndSelectFromModal = async () => {
    try {
      setIsLoading(true);
      const newId = await savePrompt();
      onSelectAndClose(newId);
    } catch (e) {
      console.error(e);
    }
  };

  const onAddStream = (data, finalData) => {
    setTextGeneratedTest(finalData);
  };

  //get the text from openAiAPI
  const getText = async () => {
    const isChatModel = chatModelsList.includes(builtPromptFormValues.model);

    try {
      const model = builtPromptFormValues.model || modelsList[0];
      setIsTestLoading(true);
      let mainPrompt = builtPromptFormValues.prompt;
      if (builtPromptFormValues.valueKeys.length > 0) {
        mainPrompt = builtPromptFormValues.valueKeys.reduce((acc, key) => {
          const regex = new RegExp(`{{${key}}}`, "g");
          return acc.replace(regex, promptJsonValueTest[key]);
        }, mainPrompt);
      }
      const promptRequest = builtPromptFormValues.example + "\n" + mainPrompt;

      let finalParams = {
        model
      };

      const functions = builtPromptFormValues.functions;

      if (functions.length > 0) {
        finalParams = {
          ...finalParams,
          functions
        };
      }

      if (isChatModel) {
        let systemExample = builtPromptFormValues.examples.find((example) => {
          return example.role === "system";
        });

        let examples = [...builtPromptFormValues.examples];
        let textToAddToSystem = "";

        if (builtPromptFormValues.typing) {
          textToAddToSystem = getSystemPromptWithType(
            builtPromptFormValues.typing
          );
        }

        if (systemExample) {
          examples = examples.map((example) => {
            if (example.role === "system") {
              return {
                ...example,
                content:
                  textToAddToSystem + "\n\nInstruction Clès:" + example.content
              };
            }
            return example;
          });
        } else {
          const newSystemExample = {
            role: "system",
            content: textToAddToSystem
          };
          examples = [newSystemExample, ...examples];
        }

        console.log("examples", examples);

        finalParams = {
          ...finalParams,
          messages: [
            ...examples,
            {
              role: "user",
              content: promptRequest
            }
          ]
        };
      } else {
        finalParams = {
          ...finalParams,
          prompt: promptRequest
        };
      }

      openAi.complete(finalParams, {
        stream: true,
        onDataStream: onAddStream,
        onDataStreamEnd: (finalData) => {
          setTextGeneratedTest((finalData || "").trim());
          setIsTestLoading(false);
        }
      });
    } catch (error) {
      setIsTestLoading(false);
      console.error(error);
    }
  };

  const addToExample = () => {
    let mainPrompt = builtPromptFormValues.prompt;
    if (builtPromptFormValues.valueKeys.length > 0) {
      mainPrompt = builtPromptFormValues.valueKeys.reduce((acc, key) => {
        const regex = new RegExp(`{{${key}}}`, "g");
        return acc.replace(regex, promptJsonValueTest[key]);
      }, mainPrompt);
    }

    if (isChatModel) {
      setBuiltPromptFormValues({
        ...builtPromptFormValues,
        examples: [
          ...builtPromptFormValues.examples,
          {
            role: "user",
            content: mainPrompt
          },
          {
            role: "assistant",
            content: textGeneratedTest
          }
        ]
      });
    } else {
      setBuiltPromptFormValues({
        ...builtPromptFormValues,
        example:
          builtPromptFormValues.example +
          "\n\n" +
          mainPrompt +
          "\n" +
          textGeneratedTest
      });
    }

    setTextGeneratedTest("");
    setPromptJsonValueTest({});
  };

  const removeKey = (key) => {
    setBuiltPromptFormValues({
      ...builtPromptFormValues,
      valueKeys: builtPromptFormValues.valueKeys.filter((k) => k !== key)
    });
  };

  const handleInputChanges = (e) => {
    setBuiltPromptFormValues({
      ...builtPromptFormValues,
      [e.target.id]: e.target.value
    });
  };

  const promptSelectValue = currentBuiltPrompt?.id || "new";

  const checkboxValues = [
    { key: "model", label: "GPT-4", value: "gpt-4" },
    { key: "model", label: "GPT-3.5 Turbo", value: "gpt-3.5-turbo" },
    { key: "model", label: "GPT-3", value: "text-davinci-003" },
    { key: "model", label: "Codex", value: "code-davinci-002" }
  ];

  const promptValue =
    builtPromptFormValues.example + builtPromptFormValues.prompt;
  const encodedPromptValue =
    promptValue && promptValue.length > 0
      ? gpt3Tokenizer.encode(promptValue)
      : { bpe: [] };

  const builtPromptsForDropdown = builtPrompts.map((builtPrompt) => {
    return {
      key: builtPrompt.id,
      value: builtPrompt.id,
      label: builtPrompt.promptName || builtPrompt.id,
      tags: builtPrompt.tags || [],
      group: builtPrompt.groupId || "Other",
      filterValues: {
        model: builtPrompt.model
      }
    };
  });

  // It should be in alphabetical order, and Other should be last
  const dropDownSortGroup = (a, b) => {
    if (a.group === "Other") {
      return 1;
    }
    if (b.group === "Other") {
      return -1;
    }
    return a.group.localeCompare(b.group);
  };

  const defaultModelText = (() => {
    if (availableModels) {
      if (availableModels.includes("gpt-4")) {
        return "gpt-4 (default)";
      } else if (availableModels.includes("gpt-3.5-turbo")) {
        return "gpt-3.5-turbo (default)";
      } else {
        return "";
      }
    } else {
      return "";
    }
  })();

  return (
    <Card className="liteTraining">
      {/* Title and prompt selector */}
      {!isFromModal && (
        <Card.Header as={"div"} className="d-flex align-items-center">
          <DropdownGroupedByAndAutoComplete
            groupSortFunction={dropDownSortGroup}
            checkboxValues={checkboxValues}
            valueLabel={
              currentBuiltPrompt?.promptName || "Select a pre-built prompt"
            }
            items={builtPromptsForDropdown}
            onChange={(id) => {
              navigate(`/prompt-builder/${id}`);
            }}
            value={currentBuiltPrompt?.builtPromptId || ""}
            placeholder="Select a pre-built prompt"
          />
          <div className="menu-horizontal-separator ms-4" />
          <Button
            variant="link"
            onClick={() => {
              navigate(`/prompt-builder`);
            }}
          >
            <i className="bi bi-plus-circle me-2"></i>
            Create new prompt
          </Button>
        </Card.Header>
      )}
      <Container fluid>
        <Row>
          <Col sm="6">
            <Card.Body>
              {/* Main Actions */}
              <Row className="d-flex justify-content-end p-2 mb-2">
                {isNewPrompt ? (
                  <Button
                    variant="primary"
                    onClick={savePrompt}
                    className="btn-auto"
                  >
                    Generate Prompt
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="link"
                      onClick={cloneBuiltPromptToNew}
                      className="btn-auto me-3"
                    >
                      <i className="fas fa-clone me-2"></i>
                      Duplicate prompt
                    </Button>
                    {!isFromModal && (
                      <Button
                        variant="link"
                        onClick={deleteBuiltPrompt}
                        className="btn-auto me-3 text-danger"
                      >
                        <i className="fas fa-trash-alt me-2"></i>
                        Remove prompt
                      </Button>
                    )}
                    <Button
                      variant="link"
                      onClick={savePrompt}
                      className="btn-auto"
                    >
                      <i className="fas fa-save me-2"></i>
                      Update Prompt
                    </Button>
                    {isFromModal && (
                      <Button
                        variant="link"
                        onClick={savePromptAndSelectFromModal}
                        className="btn-auto"
                      >
                        <i className="fas fa-check me-2"></i>
                        Save and choose
                      </Button>
                    )}
                  </>
                )}
              </Row>
              <Card.Title className="fs-4">Craft your custom prompt</Card.Title>
              <Card.Text className="fs-5 mb-4">
                Design and test your personalized prompt using the selected AI
                model.
              </Card.Text>
              {/* Form for builtPrompt */}
              <Form>
                <Row>
                  <Col sm="6">
                    <Form.Group as={Col} sm="12" controlId="promptName">
                      <Form.Label>Prompt Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter prompt name"
                        value={builtPromptFormValues.promptName}
                        onChange={handleInputChanges}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="6">
                    {/* Select model */}

                    <Form.Group controlId="model">
                      <Form.Label>Select model</Form.Label>
                      {/* Small spinner while availableModels loading */}
                      <div className="position-relative">
                        {!availableModels && (
                          // <div className="d-flex justify-content-center">
                          <Spinner
                            className="position-absolute top-50 translate-middle"
                            animation="border"
                            variant="primary"
                            size="sm"
                            security="true"
                          />
                          // </div>
                        )}
                        <Form.Control
                          as="select"
                          disabled={!availableModels}
                          value={builtPromptFormValues.model || ""}
                          onChange={handleInputChanges}
                        >
                          <option value="">Select a model</option>
                          {availableModels &&
                            availableModels.map((model) => (
                              <option key={model} value={model}>
                                {model}
                              </option>
                            ))}
                        </Form.Control>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <TypingForm
                  typing={builtPromptFormValues.typing}
                  onTypingChange={(typing) => {
                    setBuiltPromptFormValues({
                      ...builtPromptFormValues,
                      typing: typing
                    });
                  }}
                />

                {isChatModel && (
                  <ChatCompletionExamples
                    examples={builtPromptFormValues.examples}
                    onExamplesChange={(examples) => {
                      setBuiltPromptFormValues({
                        ...builtPromptFormValues,
                        examples: examples
                      });
                    }}
                  />
                )}

                {modelHasFunctions && (
                  <FunctionsConfig
                    functions={builtPromptFormValues.functions}
                    onFunctionsChange={(functions) => {
                      setBuiltPromptFormValues({
                        ...builtPromptFormValues,
                        functions: functions
                      });
                    }}
                  />
                )}

                <Row>
                  {!isChatModel && (
                    <Form.Group as={Col} sm="12" controlId="example">
                      <Form.Label>Example</Form.Label>
                      <Form.Control
                        as="textarea"
                        className="textarea-1"
                        style={{ resize: "none", height: "200px" }}
                        value={builtPromptFormValues.example}
                        onChange={handleInputChanges}
                      />
                    </Form.Group>
                  )}
                  <Form.Group as={Col} sm="12" controlId="prompt">
                    <Form.Label>Prompt</Form.Label>
                    <TextAreaWordsSelectable
                      value={builtPromptFormValues.prompt}
                      selectedWords={builtPromptFormValues.valueKeys}
                      onChange={handleInputChanges}
                      onWordSelect={(word, newValue) => {
                        setBuiltPromptFormValues({
                          ...builtPromptFormValues,
                          prompt: newValue,
                          valueKeys: [...builtPromptFormValues.valueKeys, word]
                        });
                      }}
                    />
                    <Badge className="badge-1 bg-secondary">
                      Tokens: {encodedPromptValue.bpe.length}
                    </Badge>
                  </Form.Group>
                </Row>
                <Row className="mt-3 mb-3">
                  {/* <Col sm="12">
                    <KeysListInput
                      keys={builtPromptFormValues.valueKeys}
                      onChange={(keys) => {
                        setBuiltPromptFormValues({
                          ...builtPromptFormValues,
                          valueKeys: keys,
                        });
                      }}
                    />
                  </Col> */}
                  {/* List of keys shown in a code style if list is not empty */}
                  {builtPromptFormValues.valueKeys.length > 0 && (
                    <div className="p-2 mb-2">
                      <div className="bg-white p-3 rounded text-dark fs-5">
                        Following field keys are required in the prompt:
                        <div className="d-flex flex-wrap">
                          {builtPromptFormValues.valueKeys.map((key) => (
                            <Badge
                              className="badge-1 bg-light"
                              key={key}
                              variant="light"
                              style={{ margin: "5px" }}
                            >
                              {key}
                              <i
                                className="fa fa-times action"
                                onClick={() => removeKey(key)}
                              />
                            </Badge>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* {builtPromptFormValues && (
                    <Col sm="12">
                      <BuiltPromptAdditionalForm
                        builtPrompt={builtPromptFormValues}
                        setBuiltPrompt={setBuiltPromptFormValues}
                      />
                    </Col>
                  )} */}
                </Row>
                {/* <Row className="d-flex justify-content-end">
                  <div>
                    <Badge variant="secondary" className="badge-1">
                      Tokens: {encodedPromptValue.bpe.length}
                    </Badge>
                  </div>
                </Row> */}
              </Form>
            </Card.Body>
          </Col>
          {/* Test part */}
          <Col sm="6">
            <Card.Body>
              <Card.Title className="fs-4">Test your prompt</Card.Title>
              <Card.Text className="fs-5 mb-4">
                You can test your prompt here, and see the generated text.
              </Card.Text>
              <JsonInput
                containerRef={containerRef}
                jsonValue={promptJsonValueTest}
                keys={builtPromptFormValues.valueKeys}
                onChange={(jsonValue) => {
                  setPromptJsonValueTest(jsonValue);
                }}
              />
              <div className="d-flex justify-content-between">
                <Button variant="primary" onClick={getText}>
                  Test Prompt{" "}
                  {isTestLoading && (
                    <Spinner size="sm" animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  )}
                </Button>
              </div>

              {textGeneratedTest && textGeneratedTest.length > 0 && (
                <Row className="mt-3">
                  <Col sm="12">
                    <Form.Group as={Col} sm="12" controlId="example">
                      <Form.Label>Result:</Form.Label>
                      {/* Inactive form */}
                      <Form.Control
                        as="textarea"
                        disabled={true}
                        className="textarea-1"
                        style={{ resize: "none", height: "200px" }}
                        value={textGeneratedTest}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                    <Button variant="link" onClick={addToExample}>
                      Add to example
                    </Button>
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Col>
        </Row>
      </Container>
    </Card>
  );
};

const LiteTrainingPageWithBuiltPromptID = ({
  apiProjectId,
  builtPromptId,
  builtPrompts,
  isFromModal,
  onSelectAndClose,
  onBuiltPromptIdChange,
  defaultBuiltPrompt
}) => {
  const [currentBuiltPrompt, setCurrentBuiltPrompt] = useState(null);

  useEffect(() => {
    if (!builtPromptId) {
      setCurrentBuiltPrompt(null);
      return;
    }
    if (apiProjectId) {
      const unsubscribe = DB.apiProjects.sub.builtPrompts.get(
        apiProjectId,
        builtPromptId,
        (newBuiltPrompt) => {
          setCurrentBuiltPrompt(newBuiltPrompt);
        }
      );
      return unsubscribe;
    } else {
      const unsubscribe = DB.builtPrompt.get(
        builtPromptId,
        (newBuiltPrompt) => {
          setCurrentBuiltPrompt(newBuiltPrompt);
        }
      );
      return unsubscribe;
    }
  }, [builtPromptId]);

  return (
    <LiteTrainingContent
      apiProjectId={apiProjectId}
      defaultBuiltPrompt={defaultBuiltPrompt}
      isFromModal={isFromModal}
      currentBuiltPrompt={currentBuiltPrompt}
      builtPrompts={builtPrompts}
      onSelectAndClose={onSelectAndClose}
      onBuiltPromptIdChange={onBuiltPromptIdChange}
    />
  );
};

const LiteTrainingPage = () => {
  const { builtPromptId } = useParams();
  const [builtPrompts, setBuiltPrompts] = useState([]);

  // get all the builtPrompts
  useEffect(() => {
    const unsubscribe = DB.builtPrompt.list((newBuiltPrompts) => {
      setBuiltPrompts(newBuiltPrompts);
    });
    return unsubscribe;
  }, [builtPromptId]);

  return (
    <div className="padding-page">
      <LiteTrainingPageWithBuiltPromptID
        builtPrompts={builtPrompts}
        builtPromptId={builtPromptId}
      />
    </div>
  );
};

export const BuiltPromptModal = ({
  apiProjectId,
  builtPromptId,
  onHide,
  onSelectAndClose,
  defaultBuiltPrompt
}) => {
  const [currentBuiltPromptId, setCurrentBuiltPromptId] =
    useState(builtPromptId);

  return (
    <Modal
      // enforceFocus={false}
      show={true}
      onHide={onHide}
      // size="xl"
      fullscreen={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {currentBuiltPromptId && `Built Prompt - ${currentBuiltPromptId}}`}
          {!currentBuiltPromptId && "Create a new built prompt"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LiteTrainingPageWithBuiltPromptID
          apiProjectId={apiProjectId}
          onSelectAndClose={onSelectAndClose}
          builtPromptId={currentBuiltPromptId}
          defaultBuiltPrompt={defaultBuiltPrompt}
          builtPrompts={[]}
          isFromModal={true}
          onBuiltPromptIdChange={setCurrentBuiltPromptId}
        />
      </Modal.Body>
    </Modal>
  );
};

export default LiteTrainingPage;
