import React, { useContext, useEffect } from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import { Spinner, Container, Row, Col } from "react-bootstrap";
import ProjectConfiguration from "./components/pages/ProjectConfiguration";
import SmartCodeEditor from "./components/pages/SmartCodeEditor";
import NavigationBar from "./components/zones/NavigationBar";
import useAuth from "./hooks/useAuth";
import Authentification from "./components/pages/Authentification";
import { GlobalContext } from "./contexts/GlobalContext";
import NotifZone from "./components/zones/NotifZone";

const importCss = async () => {
  await import("bootstrap/dist/css/bootstrap.min.css");
  import("./css/Admin/dist-1/css/preloader.css");
  import("./css/Admin/dist-1/css/bootstrap.css");
  import("./css/Admin/dist-1/css/app.css");
  import("./css/Admin/dist-1/css/icons.css");
  import("reactflow/dist/style.css");

  // import("./css/themeApp/assets/css/style.css");
  // import("./css/themeApp/assets/css/color.css");

  import("./index.css");
  // import("./apps/AppBuilder/styles/bootstrap-custom.css");
  import("./lib.css");
  import("@fortawesome/fontawesome-free/css/all.min.css");
  import("bootstrap-icons/font/bootstrap-icons.css");
};

// import "bootstrap-icons/font/bootstrap-icons.css";
// import "bootstrap/dist/css/bootstrap.min.css";

// // Admin style 1
// import "./css/Admin/dist-1/css/preloader.css";
// import "./css/Admin/dist-1/css/bootstrap.css";
// import "./css/Admin/dist-1/css/app.css";
// import "./css/Admin/dist-1/css/icons.css";

// import "./lib.css";

const App = () => {
  useAuth();
  const { firebaseUser, firebaseUserLoading } = useContext(GlobalContext);

  useEffect(() => {
    importCss();
  }, []);

  return (
    <div className="App-Container bg-light">
      {!firebaseUserLoading && firebaseUser && (
        <>
          <NavigationBar />
          <div className="app-main-content">
            <NotifZone />
            <Outlet />
          </div>
        </>
      )}
      {!firebaseUserLoading && !firebaseUser && (
        <>
          <Authentification />
        </>
      )}
      {firebaseUserLoading && (
        <>
          <Container style={{ height: "100vh", width: "100vw" }}>
            <Row className="h-100 w-100">
              <Col className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
              </Col>
            </Row>
          </Container>
        </>
      )}
    </div>
  );
};

export default App;
