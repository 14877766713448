import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ApiBuilderContext } from "../../../../contexts/ApiBuilderContext";
import { FaBolt } from "react-icons/fa";
import { DeploymentModelContext } from "../../../../contexts/DeploymentModelContext";
import { ListGroup, Button, Badge } from "react-bootstrap";
import { NewDeploymentModelFormModal } from "./NewDeploymentModelFormModal";
import { DeploymentModelBuilderLeftBar } from "./DeploymentModelBuilderLeftBar";
import { ApiModelLeftBar } from "./ApiModel/ApiModelLeftBar";

export const LeftBar = () => {
  const { deploymentModels } = useContext(ApiBuilderContext);
  const { deploymentModel } = useContext(DeploymentModelContext);
  const [showDeploymentModelCreation, setShowDeploymentModelCreation] =
    useState(false);
  const [modelName, setModelName] = useState("");
  const navigate = useNavigate();

  const createModel = () => {
    // Ajouter la logique pour créer un nouveau modèle de déploiement
    // et mettre à jour le state/contexte en conséquence
    // handleClose();
    // navigate(`/system-designer/${apiProjectId}/launch-lite/new`);
  };

  console.log("deploymentModels", deploymentModels);

  const goToModel = (model) => {
    navigate(`/system-designer/${model.apiProjectId}/launch-lite/${model.id}`);
  };

  if (deploymentModel) {
    switch (deploymentModel.type) {
      case "api":
        return <ApiModelLeftBar />;
      case "interface":
        return <DeploymentModelBuilderLeftBar />;
      default:
        return <DeploymentModelBuilderLeftBar />;
    }
  }

  return (
    <div className="left-bar">
      <h5>Deployment Models</h5>
      <Button
        variant="primary"
        onClick={() => setShowDeploymentModelCreation(true)}
        className="mb-3"
      >
        Create New Model
      </Button>
      <ListGroup className="deployment-models-list">
        {deploymentModels &&
          deploymentModels.map((model) => (
            <ListGroup.Item
              key={model.id}
              onClick={() => goToModel(model)}
              role="button"
            >
              {model.name}{" "}
              {model.type && (
                <Badge className={`deployment-model-type ${model.type}`}>
                  {model.type}{" "}
                  {model.type === "api" && !model.isPersistent && <FaBolt />}
                </Badge>
              )}
            </ListGroup.Item>
          ))}
      </ListGroup>
      {showDeploymentModelCreation && (
        <NewDeploymentModelFormModal
          onHide={() => setShowDeploymentModelCreation(false)}
        />
      )}
    </div>
  );
};
