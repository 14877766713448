/**
 * Home page
 * @description This is the home page
 *   it will describe the features of the website and the purpose of the website
 *   and giving links to the other page and where to start
 *
 *   Service description:
 *   The webapp allow users to create chains of prompts(for ML models such as GPT-4, and even custom models)
 *   and then it allows to test the chains of prompts with saved data sets. It can built any system the user wants just by speaking.
 *   The chains of prompts are built in the "API Builder" page. This system remove the limit of the number of prompts that can be used.
 *   The chains of prompts then can be used as SDK for developers to use in their projects, or it can be integrated into the "WebApp Builder" page.
 *
 *   The service are still in development and bugs are expected.
 *
 *   The vision of the service is to allow users to create any system they want just by speaking, no matter if the user is a developer or not.
 *   The vision is also to allow people with no budget or low budget to create their own systems.
 *   The vision is also to show that AI can bring a lot of benefits to the world.
 *
 *    Based on above information, generate right copywriting for the home page.
 */

import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Carousel, Nav } from "react-bootstrap";

// The image should scrool slower than the children content
const Parallax = ({ imgSrc, children, parentRef }) => {
  const containerRef = React.useRef(null);
  const imgRef = React.useRef(null);

  const [offset, setOffset] = React.useState(0);
  const [height, setHeight] = React.useState(0);
  const [width, setWidth] = React.useState(0);

  React.useEffect(() => {
    const container = containerRef.current;
    const img = imgRef.current;

    if (!container || !img) {
      return;
    }

    const onScroll = () => {
      const { top } = container.getBoundingClientRect();
      const { height: imgHeight, width: imgWidth } =
        img.getBoundingClientRect();

      console.log("top", top);

      setOffset(top);
      setHeight(imgHeight);
      setWidth(imgWidth);
    };

    // window.addEventListener("scroll", onScroll); does not work

    // try another way
    const parent = parentRef.current;
    if (!parent) {
      return;
    }

    // parent.addEventListener("scroll", onScroll);

    // window body
    const body = document.body;
    body.addEventListener("scroll", onScroll);

    return () => {
      // window.removeEventListener("scroll", onScroll);
      // parent.removeEventListener("scroll", onScroll);
      body.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className=""
      // style={{ height: "100vh" }}
    >
      <div
        className="position-absolute"
        style={{
          top: offset * 2,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${imgSrc})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <div
        // className="position-absolute"
        style={{
          // top: 0,
          // left: 0,
          width: "100%",
          height: "400px",
          overflow: "hidden",
        }}
      >
        {children}
      </div>
    </div>
  );
};

const Home = () => {
  const parentRef = React.useRef(null);

  return (
    <div className="HomePage" ref={parentRef}>
      <Row
        className="justify-content-md-center mb-5"
        style={{ height: "50vh" }}
      >
        {/* Show desktop */}
        <Col xs="12" className="d-none d-md-block">
          <Parallax
            className="hero-image"
            imgSrc="/illustration/pic_1.jpg"
            parentRef={parentRef}
          >
            <div className="hero-text">
              <h1>
                Transform Your Ideas into Intelligent Systems with the Power of
                AI
              </h1>
            </div>
          </Parallax>
        </Col>

        {/* Show mobile */}
        <Col xs="12" className="d-md-none">
          <Parallax
            className="hero-image"
            imgSrc="/illustration/pic_1_xs.jpg"
            parentRef={parentRef}
          >
            <div className="hero-text">
              <h1>
                Transform Your Ideas into Intelligent Systems with the Power of
                AI
              </h1>
            </div>
          </Parallax>
        </Col>
      </Row>

      <Row className="justify-content-md-center my-5 text-center">
        <Col md="auto">
          <h2 className="mb-5">Unleash Unlimited Possibilities</h2>
          <ul>
            <li>
              Effortlessly create chains of prompts for ML models, including
              custom ones
            </li>
            <li>
              Connect to databases and third-party sources for seamless
              integration
            </li>
            <li>Automate tasks and actions with the I/O Connector</li>
            <li>Explore, buy, and sell creations in our marketplace</li>
          </ul>
        </Col>
      </Row>

      <Row className="justify-content-md-center my-5">
        <Col md="auto">
          <Button variant="primary">Get Started Now</Button>
        </Col>
      </Row>

      <Row className="justify-content-md-center my-5">
        <Col xs="12">
          <Parallax
            className="hero-image"
            imgSrc={"/illustration/pic_2.jpg"}
            parentRef={parentRef}
          >
            <div className="hero-text">
              <h2>Empowering Your Vision with Cutting-Edge Technology</h2>
            </div>
          </Parallax>
        </Col>
      </Row>

      <Row className="justify-content-md-center my-5 text-center">
        <Col md="auto">
          <h2 className="mb-5">
            Accessible AI for All: Affordable, Powerful, and User-Friendly
          </h2>
          <p>
            We're committed to making AI technology accessible and affordable
            for everyone. <br /> <br />
            With our user-friendly platform, you can build intelligent systems
            without breaking the bank. <br /> <br />
            Experience the power of AI and create your dream system today!
          </p>
        </Col>
      </Row>

      <Row className="justify-content-md-center my-5">
        <Col md="auto">
          <Button variant="primary">Explore Use Cases</Button>
        </Col>
      </Row>

      <Row className="justify-content-md-center my-5">
        <Col xs="12">
          <div
            className="hero-image"
            style={{ backgroundImage: "url(/illustration/pic_3.jpg)" }}
          >
            <div className="hero-text">
              <h2>Collaborate and Innovate Together</h2>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="justify-content-md-center my-5 text-center">
        <Col md="auto">
          <h2 className="mb-5">Simplify Teamwork and Maximize Creativity</h2>
          <p>
            Our platform enables seamless collaboration, allowing you and your
            team to work together on creating the perfect AI-driven system.{" "}
            <br /> Share your prompt chains and harness the collective power of
            your team's creativity.
          </p>
        </Col>
      </Row>

      <Row className="justify-content-md-center my-5">
        <Col md="auto">
          <Button variant="primary">Join the Waitlist</Button>
        </Col>
      </Row>

      <Row className="justify-content-md-center mt-5">
        <Col xs="12">
          <div
            className="hero-image"
            style={{ backgroundImage: "url(/illustration/pic_4.jpg)" }}
          >
            <div className="hero-text">
              <h2>Experience the Power of AI</h2>
            </div>
          </div>
        </Col>
      </Row>

      <div
        style={{ width: "100%" }}
        className="d-flex justify-content-center bg-dark p-4 align-items-center"
      >
        <Nav>
          <Nav.Item>
            <Nav.Link className="text-light" href="/about">
              About Us
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className="text-light" href="/contact">
              Contact Us
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className="text-light" href="/privacy">
              Privacy Policy
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className="text-light" href="/terms">
              Terms of Service
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <div className="text-light mx-3">|</div>

        <div className="text-light mx-3">Creaxys 2023 ©</div>
      </div>
    </div>
  );
};

export default Home;
