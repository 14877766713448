import React, { useContext, useState, useRef, useEffect } from "react";
import { useDrag, useDrop, useDragLayer } from "react-dnd";
import { DeploymentModelContext } from "../../../../../contexts/DeploymentModelContext";
import { getEmptyImage } from "react-dnd-html5-backend";
import "./ApiComponentsList.css";
import {
  canDropOver,
  isInSameComponentOf,
  componentHasParent
} from "./ApiModelConstructionZone";

const DraggableComponent = ({
  id,
  components,
  type,
  children,
  moveItem,
  addEndpointToRoute,
  addMiddlewareToRoute,
  addMiddlewareToEndpoint,
  addRouteToRoute
}) => {
  const [hoverPosition, setHoverPosition] = useState(null);
  const ref = useRef(null);
  const componentsRef = useRef(components);

  useEffect(() => {
    componentsRef.current = components;
  }, [components]);

  const [, drop] = useDrop({
    accept: "component",
    hover(item, monitor) {
      if (!ref.current) return;

      if (item.id === id) return;

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      const isInSameComponent = isInSameComponentOf(item.id, id, components);
      let canMove = true;

      if (isInSameComponent && item.type !== type) {
        const hoveredHasParent = componentHasParent(item.id, components);
        const draggedHasParent = componentHasParent(id, components);
        if (hoveredHasParent && draggedHasParent) {
          canMove = false;
        }
      } else if (!isInSameComponent && item.type !== type) {
        canMove = false;
      }

      if (hoverClientY < hoverMiddleY / 10 && canMove) {
        setHoverPosition("top");
      } else if (hoverClientY > (hoverMiddleY * 9) / 10 && canMove) {
        setHoverPosition("bottom");
      } else if (canDropOver(item.type, type)) {
        setHoverPosition("middle");
      }
    },
    drop(item, monitor) {
      if (hoverPosition === "top") {
        moveItem(item.id, id, "before");
      } else if (hoverPosition === "bottom") {
        moveItem(item.id, id, "after");
      } else if (hoverPosition === "middle") {
        if (type === "route" && item.type === "endpoint") {
          addEndpointToRoute(item.id, id);
        } else if (type === "route" && item.type === "middleware") {
          addMiddlewareToRoute(item.id, id);
        } else if (type === "endpoint" && item.type === "middleware") {
          addMiddlewareToEndpoint(item.id, id);
        } else if (type === "route" && item.type === "route") {
          addRouteToRoute(item.id, id);
        }
      }
    },
    leave() {
      console.log("leave");
      // Réinitialiser hoverPosition lorsque l'élément quitte la zone de survol
      setHoverPosition(null);
    },
    // Leave is not effective to reset hoverPosition when the item is dropped
    // So we reset it manually
    collect: (monitor) => {
      if (!monitor.isOver()) {
        setHoverPosition(null);
      }
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: "component",
    item: () => ({ id, type }),
    end: () => {
      setHoverPosition(null);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  // useEffect(() => {
  //   if (preview) {
  //     preview(getEmptyImage(), { captureDraggingState: true });
  //   }
  // }, [preview]);

  // const { isDragging: isLayerDragging } = useDragLayer((monitor) => ({
  //   isDragging: monitor.isDragging()
  // }));

  // useEffect(() => {
  // if (isLayerDragging) {
  // Créez un élément de prévisualisation
  // const dragPreview = document.createElement("div");
  // // Appliquez des styles à l'élément de prévisualisation
  // dragPreview.style.backgroundColor = "rgba(255, 255, 255, 0.8)";
  // dragPreview.style.border = "1px dashed #666";
  // dragPreview.style.borderRadius = "4px";
  // dragPreview.style.padding = "10px";
  // dragPreview.innerText = "Custom Drag Preview";
  // Utilisez la méthode preview pour configurer l'élément de prévisualisation
  // preview(dragPreview);
  // }
  // }, [isLayerDragging, preview]);

  drag(drop(ref));

  let style = {}; // Définir les styles en fonction de hoverPosition

  let className = "list-item";
  if (hoverPosition === "top") {
    className += " hover-top";
  } else if (hoverPosition === "bottom") {
    className += " hover-bottom";
  } else if (hoverPosition === "middle") {
    className += " hover-middle";
  }

  if (isDragging) {
    className += " is-dragging";
  }

  return (
    <div ref={ref} style={style} className={className}>
      {children(isDragging)}
    </div>
  );
};

const ApiComponentsList = ({ draggableActions, components }) => {
  // Fonction pour générer récursivement les éléments de la liste
  const renderListItems = (items) => {
    return items.map((item, index) => (
      <React.Fragment key={item.id}>
        <DraggableComponent
          components={components}
          id={item.id}
          type={item.type}
          {...draggableActions}
        >
          {(isDragging) => (
            <div className={`${isDragging ? "is-dragging" : ""}`}>
              {item.url || item.name || item.type}
              {/* Rendre les enfants si c'est une route ou un endpoint avec des middlewares */}
            </div>
          )}
        </DraggableComponent>
        {(item.type === "route" || item.type === "endpoint") && (
          <div className="children-list">
            {renderListItems([
              ...(item.middlewares || []),
              ...(item.routes || []),
              ...(item.endpoints || [])
            ])}
          </div>
        )}
      </React.Fragment>
    ));
  };

  return <div className="components-list">{renderListItems(components)}</div>;
};

export default ApiComponentsList;
