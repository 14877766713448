/**
 * @fileoverview AppBuilder App
 * @name App.js
 * @description AppBuilder App
 */

import React, { useState, useEffect } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import HeaderComponent from "./components/HeaderComponent";
import LeftSidebarComponent from "./components/LeftSidebarComponent";
import CenterAreaComponent from "./components/CenterAreaComponent";
import FooterComponent from "./components/FooterComponent";
import NewWebAppForm from "./components/WebAppForm";
import DB from "./db/DB";
import { useNotif } from "../../components/zones/NotifZone";
import { useAiChain } from "../../libraries/ApiProjectSdk";
import NotifZone from "../../components/zones/NotifZone";
import AiRenderedDataWidget from "./components/AiRenderedDataWidget";

const importCss = async () => {
  import("bootstrap-icons/font/bootstrap-icons.css");
  await import("bootstrap/dist/css/bootstrap.min.css");
  import("./styles/bootstrap-custom.css");
  import("./styles/index.css");
  import("./styles/App.css");
  import("@fortawesome/fontawesome-free/css/all.min.css");
  import("bootstrap-icons/font/bootstrap-icons.css");
};

// const getDefaultAiRenderedData = () => {
//   return {
//     webAppDescription: "",
//     dataModels: "",
//     recommendedPages: "",
//     linkedValuesToPage: "",
//     themeGuidelines: "",
//     filesToGenerate: "",
//     generatedCodes: [],
//     codeForFile: [],
//     finalWebApp: [],
//   };
// };

const getWebAppDefault = (props = {}) => {
  return {
    name: "",
    description: "",
    modules: [],
    aiChains: [],
    ...props,
  };
};

const getDefaultPage = (props) => {
  return {
    name: "New Page",
    type: "static", // static, dynamic
    content: "<html><head></head><body>New Page</body></html>",
    modules: [],
    components: [],
    ...props,
  };
};

const getSortedPagesByCreatedAtInDesc = (pages) => {
  const newPages = [...pages];
  return newPages.sort((a, b) => {
    if (a.createdAt < b.createdAt) {
      return -1;
    } else if (a.createdAt > b.createdAt) {
      return 1;
    } else {
      return 0;
    }
  });
};

const useWebApp = (webapp) => {
  const aiWebappBuilderParameters = {
    description: webapp?.description || "",
  };
  const key = webapp ? webapp.id : null;
  const defaultResults = webapp ? webapp.aiRenderedData : {};

  const [results = {}, runAiResults, runResultsAsync, resultsRunningStatus] =
    useAiChain("webappBuilder", aiWebappBuilderParameters, defaultResults, key);

  return [results, runAiResults, runResultsAsync, resultsRunningStatus];
};

const App = () => {
  const { notify } = useNotif();
  const [appLoaded, setAppLoaded] = useState(false);
  const navigate = useNavigate();
  const { webappProjectId } = useParams();
  const [webApp, setWebApp] = useState(null);
  const [pages, setPages] = useState([]);
  const [dataModels, setDataModels] = useState([]);
  const [editorMode, setEditorMode] = useState(true);
  const [selectedPage, setSelectedPage] = useState(null);
  const [
    aiWebappResults,
    runAiResults,
    runAndGetResults,
    resultsRunningStatus,
  ] = useWebApp(webApp);
  const webAppObjectId = webApp ? webApp.id : null;

  console.log("webApp", webApp);

  useEffect(() => {
    if (webApp) {
      const newWebApp = { ...webApp };
      newWebApp.aiRenderedData = aiWebappResults;
      setWebApp(newWebApp);
    }
  }, [aiWebappResults]);

  useEffect(() => {
    if (!webappProjectId) {
      setWebApp(null);
      setPages([]);
      setDataModels([]);
      setSelectedPage(null);
    } else {
      const unsubscribe = DB.webappProjects.get(
        webappProjectId,
        (newWebApp) => {
          setWebApp(newWebApp);
          setAppLoaded(true);
        }
      );
      return unsubscribe;
    }
  }, [webappProjectId]);

  useEffect(() => {
    if (webApp && webApp.id) {
      const unsubscribe_1 = DB.webappProjects.sub.pages.list(
        webApp.id,
        (newPages) => {
          setPages(newPages);
          if (newPages.length > 0 && !selectedPage) {
            // setSelectedPage(newPages[0]);
          }
        }
      );
      const unsubscribe_2 = DB.webappProjects.sub.dataModels.list(
        webApp.id,
        (newDataModels) => {
          setDataModels(newDataModels);
        }
      );
      return () => {
        unsubscribe_1();
        unsubscribe_2();
      };
    }
  }, [webAppObjectId]);

  useEffect(() => {
    if (!webappProjectId) {
      setAppLoaded(true);
    }
    importCss();
  }, []);

  useEffect(() => {
    if (pages.length > 0) {
      if (!selectedPage) {
        setSelectedPage(pages[0]);
      }
    }
  }, [pages]);

  const commonActions = {
    notify: ({ message, variant = "info" }) => {
      notify({ message, variant });
    },
    saveWebApp: async (updates) => {
      const newWebApp = { ...webApp, ...updates };
      await DB.webappProjects.update(webApp.id, newWebApp);
      setWebApp(newWebApp);
    },
    updatePage: async (page) => {
      await DB.webappProjects.sub.pages.update(webApp.id, page.id, page);
    },
    deletePage: async (page) => {
      await DB.webappProjects.sub.pages.delete(webApp.id, page.id);
    },
    selectPage: (page) => {
      setSelectedPage(page);
    },
    runAiAndGet: async (key, resultIndex = null, options) => {
      const {
        onDataStream,
        onDataStreamEnd,
        forcedValues = {},
      } = options || {};
      const results = await runAndGetResults(key, resultIndex, {
        onDataStream,
        onDataStreamEnd,
        forcedValues,
      });
      return results;
    },
  };

  const leftSideBarActions = {
    ...commonActions,
    addNewPage: async (newPage) => {
      const newPageId = await DB.webappProjects.sub.pages.create(
        webApp.id,
        newPage
      );
      setSelectedPage({ ...newPage, id: newPageId });
    },
    addDataModel: async (dataModel) => {
      await DB.webappProjects.sub.dataModels.create(webApp.id, dataModel);
    },
    updateDataModel: async (dataModel) => {
      await DB.webappProjects.sub.dataModels.update(
        webApp.id,
        dataModel.id,
        dataModel
      );
    },
    runAiResult: async (resultKey, resultIndex) => {
      if (resultIndex) await runAiResults(resultKey, resultIndex);
      else await runAiResults(resultKey);
    },
  };

  const centerAreaActions = {
    ...commonActions,
    onPageChange: (page) => {
      const newPages = pages.map((p) => {
        if (p.id === page.id) {
          return page;
        }
        return p;
      });
      setPages(newPages);
    },
    savePage: async (page) => {
      await DB.webappProjects.sub.pages.update(webApp.id, page.id, page);
    },
  };

  const NewWebAppFormActions = {
    onWebAppChange: (webApp) => {
      setWebApp(webApp);
    },
    createWebApp: async (webApp) => {
      const newId = await DB.webappProjects.create(webApp);
      navigate(`/webapp-builder/${newId}`);
    },
  };

  const sortedPages = getSortedPagesByCreatedAtInDesc(pages);

  return (
    <div className="WebApp-Builder-App">
      <HeaderComponent
        editorMode={editorMode}
        setEditorMode={setEditorMode}
        webApp={webApp}
      />
      <div className="d-flex flex-row middle-zone">
        {appLoaded && webApp && (
          <>
            <div className="LeftSidebar-parent">
              <LeftSidebarComponent
                dataModels={dataModels}
                pages={sortedPages}
                actions={leftSideBarActions}
                selectedPage={selectedPage}
                webApp={webApp}
              />
            </div>
            <main className="CenterArea-parent">
              <CenterAreaComponent
                webApp={webApp}
                actions={centerAreaActions}
                pages={sortedPages}
                dataModels={dataModels}
                editorMode={editorMode}
                selectedPage={selectedPage}
              />
            </main>
            {webApp.aiRenderedData && (
              <div
                style={{ position: "absolute", bottom: "75px", right: "19px" }}
              >
                <AiRenderedDataWidget
                  aiRenderedData={webApp.aiRenderedData}
                  actions={leftSideBarActions}
                  resultsRunningStatus={resultsRunningStatus}
                />
              </div>
            )}
          </>
        )}
        {appLoaded && !webApp && (
          <NewWebAppForm
            actions={NewWebAppFormActions}
            defaultValue={getWebAppDefault()}
          />
        )}
        {/* add spinner */}
        {!appLoaded && (
          <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <Spinner
              as="span"
              animation="border"
              size="lg"
              role="status"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      <FooterComponent />
      <NotifZone />
    </div>
  );
};

export default App;
