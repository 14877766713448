import React, { useState } from "react";
import { Button, Col, ListGroup, Row } from "react-bootstrap";
import { DataModelModal } from "./DataModelCreation";
import { WebAppConfigurationModal } from "./WebAppConfiguration";
import { PageConfigurationModal } from "./PageConfiguration";

// actions contains: addNewPage
const LeftSidebarComponent = ({
  actions,
  pages = [],
  selectedPage,
  webApp,
  dataModels = [],
}) => {
  const [selectedDataModel, setSelectedDataModel] = useState(null);
  const [openedModals, setOpenedModals] = useState({
    createDataModel: false,
    webAppConfiguration: false,
    createPage: false,
  });
  const { aichains, modules } = webApp;

  const closeModalFor = (modalName) => {
    setOpenedModals({
      ...openedModals,
      [modalName]: false,
    });
  };

  const openModalFor = (modalName) => {
    setOpenedModals({
      ...openedModals,
      [modalName]: true,
    });
  };

  const webAppConfigurationActions = {
    runAiAndGet: actions.runAiAndGet,
    runAiResult: actions.runAiResult,
    saveWebApp: actions.saveWebApp,
    notify: actions.notify,
  };

  const dataModelsNames = dataModels.map((dataModel) => dataModel.name);
  const pagesNames = pages.map((page) => page.name);

  return (
    <>
      {openedModals.createPage && (
        <PageConfigurationModal
          pages={pages}
          show={openedModals.createPage}
          pagesNames={pagesNames}
          onHide={() => {
            closeModalFor("createPage");
          }}
          webApp={webApp}
          actions={actions}
          dataModels={dataModels}
          defaultValue={{
            name: "",
            description: "",
            webAppObjectId: webApp.id,
          }}
        />
      )}
      {(openedModals.createDataModel || !!selectedDataModel) && (
        <DataModelModal
          dataModelsNames={dataModelsNames}
          show={openedModals.createDataModel || !!selectedDataModel}
          onHide={() => {
            if (selectedDataModel) {
              setSelectedDataModel(null);
            } else {
              closeModalFor("createDataModel");
            }
          }}
          actions={actions}
          defaultValue={
            selectedDataModel || {
              name: "",
              description: "",
              webAppObjectId: webApp.id,
            }
          }
          webApp={webApp}
        />
      )}
      {openedModals.webAppConfiguration && (
        <WebAppConfigurationModal
          onSave={actions.saveWebApp}
          show={openedModals.webAppConfiguration}
          actions={webAppConfigurationActions}
          onHide={() => {
            closeModalFor("webAppConfiguration");
          }}
          webApp={webApp}
        />
      )}

      <aside className="bg-dark text-light p-3 left-sidebar">
        <div>
          <h4 className="mb-4">Elements</h4>
          <div className="mb-3">
            <Button
              variant="primary"
              className="w-100 mb-2"
              onClick={() => openModalFor("createPage")}
            >
              <i className={"fa fa-plus"} /> Add Page
            </Button>
            <Button variant="primary" className="w-100 mb-2">
              <i className={"fa fa-cube"} /> Add Module
            </Button>
          </div>
        </div>
        <hr />
        {/* WebApp configuration */}
        <div>
          <h5 className="mb-3">WebApp</h5>
          <div className="d-flex flex-wrap">
            <div className="p-2 bg-secondary text-light m-1">
              <i
                className={"fa fa-cog"}
                size="2x"
                role={"button"}
                onClick={() => openModalFor("webAppConfiguration")}
              />
            </div>
            <div className="p-2 bg-secondary text-light m-1">
              <i className={"fa fa-database"} size="2x" />
            </div>
            <div className="p-2 bg-secondary text-light m-1">
              <i className={"fa fa-cubes"} size="2x" />
            </div>
            <div className="p-2 bg-secondary text-light m-1">
              <i className={"fa fa-clone"} size="2x" />
            </div>
            <div className="p-2 bg-secondary text-light m-1">
              <i className={"fa fa-code"} size="2x" />
            </div>
          </div>
        </div>

        <hr />

        <div>
          <h5 className="mb-3">Components</h5>
          <div className="d-flex flex-wrap">
            <div className="p-2 bg-secondary text-light m-1">
              <i className={"fa fa-mouse-pointer"} size="2x" />
            </div>
            {/* Add more component icons here */}
          </div>
        </div>
        <hr />
        <div>
          <h5 className="mb-3 mt">Pages</h5>
          {pages.length === 0 && (
            <p className="text-center">
              No pages yet.{" "}
              <span
                className="text-primary"
                role={"button"}
                onClick={() => {
                  openModalFor("createPage");
                }}
              >
                Add one
              </span>
            </p>
          )}
          <ListGroup>
            {pages.map((page, index) => (
              <ListGroup.Item
                key={index}
                action
                variant="secondary"
                active={selectedPage && selectedPage.id === page.id}
                onClick={() => actions.selectPage(page)}
              >
                {page.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
        <hr />
        {/* Datasets, rows of 3 columns, each item is a big nice icon with the name just below */}
        <div>
          <h5 className="mb-3 mt">Data Models</h5>
          {dataModels.length === 0 && (
            <p className="text-center">
              No data models yet.{" "}
              <span
                className="text-primary"
                role={"button"}
                onClick={() => {
                  openModalFor("createDataModel");
                }}
              >
                Add one
              </span>
            </p>
          )}
          <Row>
            {dataModels.map((dataModel, index) => (
              <Col key={index} xs={4}>
                <div
                  className="p-2 bg-secondary text-center text-white m-1"
                  role={"button"}
                  onClick={() => {
                    setSelectedDataModel(dataModel);
                  }}
                >
                  <i className={"fa fa-database"} size="2x" />
                  <p className="text-center m-0 ellipsis">{dataModel.name}</p>
                </div>
              </Col>
            ))}
          </Row>
          {dataModels.length > 0 && (
            <div className="text-light m-1 text-center">
              <Button
                variant="link"
                className=""
                role={"button"}
                onClick={() => {
                  openModalFor("createDataModel");
                }}
              >
                <i className={"fa fa-plus"} size="2x"></i>Add Data Model
              </Button>
            </div>
          )}
        </div>
        <hr />
        {/* AI Chains, rows of 3 columns, each item is a big nice icon with the name just below */}
        <div>
          <h5 className="mb-3 mt">AI Chains</h5>
          {aichains && aichains.length === 0 && (
            <p className="text-center">
              No AI Chains yet.{" "}
              <span
                className="text-primary"
                role={"button"}
                onClick={actions.addNewAIChain}
              >
                Add one
              </span>
            </p>
          )}
          <div className="d-flex flex-wrap">
            {aichains &&
              aichains.map((aichain, index) => (
                <div
                  key={index}
                  className="p-2 bg-secondary text-light m-1"
                  style={{ width: "100px" }}
                >
                  <i className={"fa fa-cogs"} size="2x" />
                  <p className="text-center">{aichain.name}</p>
                </div>
              ))}
          </div>
        </div>
        <hr />
        {/* Modules, rows of 3 columns, each item is a big nice icon with the name just below */}
        <div>
          <h5 className="mb-3 mt">Modules</h5>
          {modules.length === 0 && (
            <p className="text-center">
              No Modules yet.{" "}
              <span
                className="text-primary"
                role={"button"}
                onClick={actions.addNewModule}
              >
                Add one
              </span>
            </p>
          )}
          <div className="d-flex flex-wrap">
            {modules.map((module, index) => (
              <div
                key={index}
                className="p-2 bg-secondary text-light m-1"
                style={{ width: "100px" }}
              >
                <i className={"fa fa-cube"} size="2x" />
                <p className="text-center">{module.name}</p>
              </div>
            ))}
          </div>
        </div>
      </aside>
    </>
  );
};

export default LeftSidebarComponent;
