import axios from "axios";
import { firebaseAuth } from "../api-connector/firebase";

const p = {
  testInputApiConnector: `${process.env.REACT_APP_API_URL}/system-creator/test-input-api-connector`,
  checkIfEmailExists: `${process.env.REACT_APP_API_URL}/system-creator/email-exists`,
  shareProjectWithUser: `${process.env.REACT_APP_API_URL}/system-creator/share-project-with-user`,
  getEmailsFromIds: `${process.env.REACT_APP_API_URL}/system-creator/get-emails-from-ids`,
};

const getFirebaseToken = async () => {
  const token = await firebaseAuth.currentUser.getIdToken();
  return token;
};

const getHeaders = async (headers = {}) => {
  const token = await getFirebaseToken();
  return {
    Authorization: `Bearer ${token}`,
    ...headers,
  };
};

const systemCreatorApi = {
  testInputApiConnector: async (requestData) => {
    try {
      const headers = await getHeaders();
      const response = await axios.post(
        `${p.testInputApiConnector}`,
        requestData,
        { headers }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  checkIfEmailExists: async (email) => {
    try {
      const headers = await getHeaders();
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/system-creator/email-exists`,
        {
          params: {
            email,
          },
          headers,
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  shareProjectWithUser: async (projectId, email) => {
    try {
      const headers = await getHeaders();
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/system-creator/share-project-with-user`,
        {
          projectId,
          email,
        },
        { headers }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  getEmailsFromIds: async (ids) => {
    try {
      const headers = await getHeaders();
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/system-creator/get-emails-from-ids`,
        {
          params: {
            ids,
          },
          headers,
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
};

export default systemCreatorApi;
