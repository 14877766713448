import React from "react";
import { useState, useEffect } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import systemCreatorApi from "../../../../apis/systemCreator";
import ResponseTransform from "./ResponseTransform";

const getDefaultValueByType = (type) => {
  switch (type) {
    case "string":
      return "";
    case "text":
      return "";
    case "number":
      return 0;
    case "boolean":
      return false;
    case "json":
      return {};
    case "json-array":
      return [];
    default:
      return "";
  }
};

export const updateInitialValues = (method) => {
  const { parameters = [], queries = [], headers = [] } = method;
  const {
    importedHeadersVariables,
    importedQueriesVariables,
    importedParametersVariables,
    importedPathVariables
  } = method;

  const updatedParameters = parameters.map((parameter) => {
    const { key, value, type, isRequired } = parameter;
    const importedParameter = importedParametersVariables.find(
      (importedParameter) => importedParameter.key === key
    );
    return {
      ...parameter,
      value: importedParameter ? getDefaultValueByType(type) : value,
      type: importedParameter ? importedParameter.type : type,
      isRequired: importedParameter ? importedParameter.isRequired : isRequired
    };
  });

  const updatedQueries = queries.map((query) => {
    const { key, value, isRequired } = query;
    const importedQuery = importedQueriesVariables.find(
      (importedQuery) => importedQuery.key === key
    );
    return {
      ...query,
      value: importedQuery ? "" : value,
      isRequired: importedQuery ? importedQuery.isRequired : isRequired,
      type: "string"
    };
  });

  const updatedHeaders = headers.map((header) => {
    const { key, value } = header;
    const importedHeader = importedHeadersVariables.find((importedHeader) => {
      if (importedHeader.key === key) return true;
      if (value === `{{${importedHeader.key}}}`) return true;
      return false;
    });
    return {
      ...header,
      value: importedHeader ? "" : value,
      type: "string"
    };
  });

  return {
    parameters: updatedParameters,
    queries: updatedQueries,
    headers: updatedHeaders
  };
};

export const buildRequest = (parameters, queries, headers, path, method) => {
  const builtParameters = parameters.reduce((acc, parameter) => {
    const { key, value } = parameter;
    if (value === "" || value === undefined) {
      return acc;
    }
    return {
      ...acc,
      [key]: value
    };
  }, {});

  const builtQueries = queries.reduce((acc, query) => {
    const { key, value } = query;
    if (value === "" || value === undefined) {
      return acc;
    }
    return {
      ...acc,
      [key]: value
    };
  }, {});

  const builtHeaders = headers.reduce((acc, header) => {
    const { key, value } = header;
    if (value === "" || value === undefined) {
      return acc;
    }
    return {
      ...acc,
      [key]: value
    };
  }, {});

  return {
    parameters: builtParameters,
    queries: builtQueries,
    headers: builtHeaders
  };
};

export const ModalTestApi = ({
  method,
  onClose,
  inputApiConnector,
  onMethodChange,
  onNewResultsReturnsConfig
}) => {
  const [resultsReturnsConfig, setResultsReturnsConfig] = useState(
    method.resultsReturnsConfig || []
  );
  const [loading, setLoading] = useState(false);
  const [parameters, setParameters] = useState([]);
  const [queries, setQueries] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const { baseUrl } = inputApiConnector;

  useEffect(() => {
    const { parameters, queries, path, headers } = updateInitialValues(method);
    setParameters(parameters);
    setQueries(queries);
    setHeaders(headers);
  }, [method]);

  const handleTestApi = async () => {
    setLoading(true);
    setError(null);
    setResponse(null);
    try {
      const builtRequest = buildRequest(parameters, queries, headers);
      const response = await systemCreatorApi.testInputApiConnector({
        parameters: builtRequest.parameters,
        queries: builtRequest.queries,
        headers: builtRequest.headers,
        url: baseUrl + method.path,
        method: method.httpMethod
      });

      setResponse(response);
      setError(null);
      setLoading(false);
    } catch (error) {
      setResponse(null);
      setError(error.message);
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    onClose();
  };

  const handleObjectChange = (event, index, objectType) => {
    const { value } = event.target;

    switch (objectType) {
      case "parameters":
        setParameters((prevParameters) => {
          const updatedParameters = [...prevParameters];
          updatedParameters[index].value = value;
          return updatedParameters;
        });
        break;
      case "queries":
        setQueries((prevQueries) => {
          const updatedQueries = [...prevQueries];
          updatedQueries[index].value = value;
          return updatedQueries;
        });
        break;
      case "headers":
        setHeaders((prevHeaders) => {
          const updatedHeaders = [...prevHeaders];
          updatedHeaders[index].value = value;
          return updatedHeaders;
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Modal show={true} onHide={onClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Test API</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Method: <span className="fw-bold">{method.httpMethod}</span>
          </p>
          <p>
            URL:{" "}
            <span className="fw-bold">
              {baseUrl}
              {method.path}
            </span>
          </p>

          <div className="horizontal-separator-2-dark mb-3"></div>

          <Form>
            {parameters && parameters.length > 0 && (
              <>
                <Form.Group>
                  <p className="fs-5 fw-bold">Parameters</p>
                  {parameters.length === 0 && (
                    <p className="text-black-5">No parameters to fill</p>
                  )}

                  {parameters.map(({ key, type, isRequired, value }, index) => (
                    <React.Fragment key={key}>
                      <Form.Label>{key}</Form.Label>
                      <Form.Control
                        key={key}
                        type={type}
                        name={key}
                        placeholder={`Enter ${type} value for ${key}`}
                        value={value}
                        onChange={(event) =>
                          handleObjectChange(event, index, "parameters")
                        }
                        required={isRequired}
                        className="mb-2"
                      />
                    </React.Fragment>
                  ))}
                </Form.Group>
                <div className="horizontal-separator-2-dark my-3"></div>
              </>
            )}

            {queries && queries.length > 0 && (
              <>
                <Form.Group>
                  <p className="fs-5 fw-bold">Queries</p>
                  {queries.length === 0 && (
                    <p className="text-black-5">No queries to fill</p>
                  )}

                  {queries.map(({ key, type, isRequired, value }, index) => (
                    <React.Fragment key={key}>
                      <Form.Label>{key}</Form.Label>
                      <Form.Control
                        key={key}
                        type={type}
                        name={key}
                        placeholder={`Enter ${type} value for ${key}`}
                        value={value}
                        onChange={(event) =>
                          handleObjectChange(event, index, "queries")
                        }
                        required={isRequired}
                        className="mb-2"
                      />
                    </React.Fragment>
                  ))}
                </Form.Group>
                <div className="horizontal-separator-2-dark my-3"></div>
              </>
            )}

            {headers && headers.length > 0 && (
              <>
                <Form.Group>
                  <p className="fs-5 fw-bold">Headers</p>
                  {headers.length === 0 && (
                    <p className="text-black-5">No headers to fill</p>
                  )}
                  {headers.map(({ key, type, isRequired, value }, index) => (
                    <React.Fragment key={key}>
                      <Form.Label>{key}</Form.Label>
                      <Form.Control
                        key={key}
                        type={type}
                        name={key}
                        placeholder={`Enter ${type} value for ${key}`}
                        value={value}
                        onChange={(event) =>
                          handleObjectChange(event, index, "headers")
                        }
                        required={isRequired}
                        className="mb-2"
                      />
                    </React.Fragment>
                  ))}
                </Form.Group>
                <div className="horizontal-separator-2-dark my-3"></div>
              </>
            )}
          </Form>

          <div className="d-flex justify-content-end">
            <Button variant="primary" onClick={handleTestApi}>
              Send Request
            </Button>
          </div>

          <div className="horizontal-separator-2-dark my-3"></div>

          {loading && (
            <div className="text-center">
              <Spinner animation="border" variant="primary" />
              <p className="text-black-5">Sending request...</p>
            </div>
          )}
          <ResponseTransform
            method={method}
            apiResponse={response}
            resultType={method.resultType}
            onMethodChange={onMethodChange}
          />
          {/* {response && (
            <div>
              <div className="horizontal-separator-2-dark my-3"></div>
              <p className="fs-5 fw-bold">Response</p>
              <pre className="bg-light p-3 input-api-connector__response">
                {JSON.stringify(response, null, 2)}
              </pre>
            </div>
          )} */}
          {error && <p>{error}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
