import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { Modal, Button, Placeholder } from "react-bootstrap";
import DB from "../../../database/DB";
// import { PromptBlock } from "../../pages/ApiBuilder";
import PromptBlock from "./Transform/PromptBlock";
import {
  PanelTopFlowSelector,
  ContextMenuPane,
  GlobalChainParameters,
  PanelLeft
} from "./SystemFlowCreator";
import ReactFlow, {
  Controls,
  Background,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  MiniMap,
  useViewport,
  Panel
} from "reactflow";
import { ApiBuilderContext } from "../../../contexts/ApiBuilderContext";
import { ChainContext } from "../../../contexts/ChainContext";
import flowDbUtils from "./flowDbUtils";
import builtPrompt from "../../../database/builtPrompt";
import BlockType from "./utils/BlockType";

const BlockNode = ({ data }) => {
  const [currentBuiltPrompt, setCurrentBuiltPrompt] = useState("");
  const [open, setOpen] = useState(false);

  const { promptBlock, childrenProps } = data;

  const { promptBlocks, inputs, outputs } = useContext(ChainContext);
  const { id: apiProjectId, apiProject } = useContext(ApiBuilderContext);

  const { chainKey, blockType, valuesLinkedExport } = promptBlock;
  const { result } = valuesLinkedExport;
  const builtPromptId = promptBlock.builtPromptId;

  useEffect(() => {
    if (promptBlock.blockType === "completion" && builtPromptId) {
      const unsubscribe = DB.apiProjects.sub.builtPrompts.get(
        apiProjectId,
        builtPromptId,
        (builtPrompt) => {
          setCurrentBuiltPrompt(builtPrompt);
        }
      );
      return unsubscribe;
    }
  }, [builtPromptId]);

  const toggleBlockConfigurationModal = () => {
    setOpen(!open);
  };

  const onScrollStopPropagation = (e) => {
    e.stopPropagation();
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  // const onPromptChange = (newPrompt) => {
  //   const newPromptBlock = { ...promptBlock };
  //   newPromptBlock.builtPromptId = newPrompt.id;
  //   DB.promptBlocks.update(newPromptBlock);
  // };

  const contentElementCompletion = () => {
    if (!currentBuiltPrompt) {
      return (
        <div className="p-3">
          <div className="text-left">
            <h5 className="text-gray-node fs-6">Completion Block</h5>
            <Placeholder />
            <Button
              variant="link"
              size="sm"
              className=""
              onClick={() => {
                toggleBlockConfigurationModal();
              }}
            >
              Create Completion Block
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="p-3">
          <div className="text-left">
            <h5 className="text-gray-node mb-3 fs-6">Completion Block</h5>
            <div
              className="text-gray-node mb-2 text-completion-node"
              style={{ height: "100px" }}
              // onScrollStopPropagation
              onScroll={onScrollStopPropagation}
            >
              {truncateText(currentBuiltPrompt.prompt, 130)}
            </div>
            <Button
              variant="link"
              size="sm"
              className="ps-0"
              onClick={() => {
                toggleBlockConfigurationModal();
              }}
            >
              Edit Completion Block
            </Button>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="p-1">
      {/* <Handle type="target" position={Position.Top} id="e" />
        <Handle type="target" position={Position.Right} id="f" /> */}

      <div className={`reactflow-node pt-3 type-${blockType}`}>
        <div className="reactflow-node-header">
          <div className="reactflow-node-header-title">
            <div className="reactflow-node-header-title-text">
              <div className="reactflow-node-header-title-text-name text-truncate text-gray-node ps-3 fs-5">
                {result || "Completion Block" + " - " + chainKey}
              </div>
            </div>
          </div>
          <div className="reactflow-node-header-actions">
            {blockType === "completion" && contentElementCompletion()}
          </div>

          <h1>TEST</h1>

          {currentBuiltPrompt && (
            <BlockType
              blockType={"aiProcessing"}
              block={{
                ...promptBlock,
                builtPrompt: currentBuiltPrompt
              }}
            />
          )}
        </div>

        {open && (
          <Modal show={open} onHide={toggleBlockConfigurationModal} size="lg">
            <Modal.Body className="p-0">
              <PromptBlock
                promptBlock={promptBlock}
                onCloseSaved={() => {
                  toggleBlockConfigurationModal();
                }}
              />
            </Modal.Body>
          </Modal>
        )}
      </div>
      {/* <Handle type="source" position={Position.Bottom} id="a" />
        <Handle type="source" position={Position.Left} id="b" /> */}
    </div>
  );
};

const initialEdges = (nodes) => {
  return nodes.reduce((acc, node) => {
    if (node.edge) {
      acc.push(node.edge);
    }
    return acc;
  }, []);
};

const blockNodesTypes = {
  block: BlockNode
};

const getDataFromPromptBlocks = (promptBlocks) => {
  const nodes = promptBlocks.map((pb, i) => {
    // Will render by columns of 300px with 3 columns

    const {
      chainKey,
      valuesLinkedExport,
      valuesLogicLinkedImport,
      positionFlow = {}
    } = pb;
    const { result } = valuesLinkedExport || {};
    const { source } = valuesLogicLinkedImport || {};
    let additionalNodeProps = {};

    let x = positionFlow.x;
    let y = positionFlow.y;

    if (!x || !y) {
      const col = i % 3;
      const row = Math.floor(i / 3);

      x = col * 400;
      y = row * 400;
    }

    const node = {
      id: `transform-${chainKey}`,
      data: {
        // label: result || chainKey,
        label: chainKey,
        promptExportVariable: result,
        logicExportVariable: source,
        key: chainKey,
        promptBlock: pb
      },
      position: { x, y },
      type: "block"
      // ...additionalNodeProps,
    };

    return node;
  });

  return nodes;
};

const TransformFlow = ({ reactFlowInstance, onSelectFlow }) => {
  const { id: apiProjectId } = useContext(ApiBuilderContext);
  const {
    promptBlocks = [],
    id: chainId,
    chainActions
  } = useContext(ChainContext);
  const { addNodes, setNodes: setNodesFlow } = reactFlowInstance;
  const { x: viewportX, y: viewportY, zoom } = useViewport();
  const [showGlobalParameters, setShowGlobalParameters] = useState(false);
  const [nodes, setNodes] = useState(getDataFromPromptBlocks(promptBlocks));
  const [contextMenu, setContextMenu] = useState({
    show: false,
    position: { x: 0, y: 0 }
  });
  const [edges, setEdges] = useState([]);
  const isMovingNode = React.useRef(false);
  const lastNodesPositions = React.useRef({});

  useEffect(() => {
    setNodesFlow(getDataFromPromptBlocks(promptBlocks));
    setNodes(getDataFromPromptBlocks(promptBlocks));
  }, [promptBlocks]);

  const updatePromptBlocksPositions = (nodes) => {
    const newPromptBlocks = [...promptBlocks];

    nodes.forEach((node) => {
      const { id, positionFlow } = node;
      if (!positionFlow) {
        return;
      }
      const index = newPromptBlocks.findIndex((pb) => pb.chainKey === id);
      if (index !== -1) {
        newPromptBlocks[index] = {
          ...newPromptBlocks[index],
          positionFlow: positionFlow
        };
      }
    });

    chainActions.updatePromptBlocks(newPromptBlocks);
  };

  const onNewPositionsCallback = (changePayload) => {
    console.log("onNewPositionsCallback", changePayload);
    // flowDbUtils.updateNodesPositions(changePayload, isMovingNode, lastNodesPositions, (newNodes) => {
    //   updatePromptBlocksPositions(newNodes);
    // });
  };

  const onNodesChange = (changes) =>
    setNodes((nds) => {
      // reset, position, add, select, dimensions --- remove, hover, connect, drag, selectconnection, source, target
      const positions = changes.map((change) => {
        const { type: changeType } = change;

        console.log("change", change);

        if (changeType === "position") {
          const { position = null, id = null } = change;
          return { position, id, changeType };
        } else if (changeType === "add") {
          const { item } = change;
          const { data, id, position, type } = item;
          return { data, id, position, type, changeType };
        }
        return { ...change, changeType };
      });

      const positionsToChange = positions.filter(
        (position) => position.changeType === "position"
      );
      if (positionsToChange.length > 0) {
        onNewPositionsCallback(positionsToChange);
      }

      const filteredChanges = changes.filter((change) => {
        const { type } = change;
        return !["remove", "select"].includes(type);
      });

      return applyNodeChanges(filteredChanges, nds);
    });

  const onEdgesChange = React.useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onConnect = React.useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  const onBackgroundContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const x = e.clientX;
    const y = e.clientY;
    const height = e.target.clientHeight;

    setContextMenu({ show: true, position: { x, y }, menuHeight: height });
  };

  const onCloseContextMenu = () => {
    setContextMenu({ show: false, position: { x: 0, y: 0 } });
  };

  const createNewNode = (promptBlock) => {
    const { positionFlow = { x: 0, y: 0 } } = promptBlock;
    const newNode = {
      id: promptBlock.chainKey,
      data: {
        label: promptBlock.chainKey,
        promptBlock: promptBlock,
        promptExportVariable: "",
        logicExportVariable: "",
        key: promptBlock.chainKey
      },
      position: positionFlow,
      type: "block"
    };

    return newNode;
  };

  const actions = [
    {
      name: "Add Completion Block",
      onClick: async (e) => {
        const { clientX, clientY } = e;

        const positionFlow = {
          x: (clientX - viewportX) * zoom,
          y: (clientY - viewportY) * zoom
        };

        const newPromptBlock = await chainActions.createPromptBlock({
          positionFlow
        });
        const newNode = createNewNode(newPromptBlock);

        addNodes(newNode);
      },
      icon: "fas fa-plus-circle"
    },
    {
      name: "Add Logic Block",
      onClick: (e) => {
        console.log("Add Logic Block");
      },
      icon: "fas fa-plus-circle"
    }
  ];

  const actionsLeftMenu = [
    {
      icon: "bi bi-receipt",
      label: "Global Inputs/Parameters",
      description: "See and set the inputs/parameters your system will use",
      onClick: () => {
        setShowGlobalParameters(!showGlobalParameters);
      }
    }
  ];

  return (
    <>
      <ReactFlow
        style={{ height: "100%" }}
        nodes={nodes}
        edges={edges}
        nodeTypes={blockNodesTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        panOnScroll
        snapToGrid={true}
        snapGrid={[17, 20]}
        // set origin to -100, -100
        defaultViewport={{
          x: 100,
          y: 100,
          zoom: 1
        }}
        // className="nodes-background"
        // onContextMenu={onBackgroundContextMenu}
        onPaneContextMenu={onBackgroundContextMenu}
      >
        <Panel position="top-center" className="px-5 w-100">
          <PanelTopFlowSelector
            apiProjectId={apiProjectId}
            chainId={chainId}
            onSelectFlow={onSelectFlow}
            selectedFlow={"transform"}
          />
        </Panel>
        <Panel
          position="left"
          className="px-3 h-100 d-flex flex-column align-items-center justify-content-center"
        >
          <PanelLeft actions={actionsLeftMenu} />
        </Panel>
        <ContextMenuPane
          {...contextMenu}
          onClose={onCloseContextMenu}
          actions={actions}
        />
        {/* <Background className="nodes-background" gap={75} id="background" /> */}
        <Background className="nodes-background" gap={77} id="background" />
        <Controls />
        <MiniMap />
      </ReactFlow>
    </>
  );
};

export default TransformFlow;
