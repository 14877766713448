// This file contains the parent component of the recommendation module. It takes the following props: projectId: the id of the project.
// For now it only contains:
// - a list of learning recommendations(ProjectLearning.js)
// - a list of tasks(Task.js)
// Initialisation of the component:
// - set loadedProject to value of project from DB
// - get the list of learning recommendations from the DB
// - get the list of tasks from the DB

import React, { useState, useEffect } from "react";
import { Container, Row, Spinner } from "react-bootstrap";
import ProjectLearning from "../../widgets/Recommendations/ProjectLearning";
import Task from "../../widgets/Recommendations/Task";
import DB from "../../../database/DB";

const ProjectLearningsList = ({ projectId }) => {
  const [learningRecommendations, setLearningRecommendations] = useState([]);

  // get learningProcesses from DB
  useEffect(() => {
    const unsubscribe = DB.project.getLearningRecommendations(
      projectId,
      (learningProcesses) => {
        setLearningRecommendations(learningProcesses);
      }
    );
    return unsubscribe;
  }, []);

  return (
    <>
      {learningRecommendations.map((learningRecommendation) => (
        <ProjectLearning
          key={learningRecommendations.id}
          learningRecommendationId={learningRecommendations.id}
        />
      ))}
    </>
  );
};

const ProjectTasks = ({ projectId }) => {
  const [tasks, setTasks] = useState([]);
  // get tasks from DB
  useEffect(async () => {
    const unsubscribe = DB.project.getTasks(projectId, (tasks) => {
      setTasks(tasks);
    });
    return unsubscribe;
  }, []);

  return (
    <>
      {tasks.map((task) => (
        <Task key={task.id} taskId={task.id} />
      ))}
    </>
  );
};

const ProjectConfigBuilder = ({ projectId }) => {
  const [loadedProject, setLoadedProject] = useState({});
  const [learningProcesses, setLearningProcesses] = useState([]);
  const [tasks, setTasks] = useState([]);

  // get project from DB
  useEffect(() => {
    const unsubscribe = DB.project.getProjectById(projectId, (project) => {
      setLoadedProject(project);
    });
    return unsubscribe;
  }, []);

  return (
    <>
      {loadedProject && (
        <Container>
          {/* <Row>
            <h1>{loadedProject.name}</h1>
          </Row> */}
          <Row>
            <ProjectLearningsList projectId={projectId} />
          </Row>
          {/* <Row>
            <ProjectTasks projectId={projectId} />
          </Row> */}
          <Row />
        </Container>
      )}
      {!loadedProject && (
        // Centered spinner
        <Container>
          <Row className="justify-content-md-center">
            <Spinner animation="grow" variant="warning" />
          </Row>
        </Container>
      )}
    </>
  );
};

export default ProjectConfigBuilder;
