import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import {
  FaEllipsisV,
  FaCloud,
  FaAws,
  FaGoogle,
  FaBook,
  FaCog,
  FaChartLine,
  FaKey,
  FaMoon,
  FaCloudUploadAlt
} from "react-icons/fa";
import DeploymentApi from "../../../../apis/deployment";
import { useDeployments } from "../../../../contexts/DeploymentContext";
import { ApiModelDocumentationModal } from "../LaunchLite/ApiModel/ApiModelDocumentation";
import ApiKeysModal from "./ApiKeysModal";
import "./DeploymentList.css";

// light loader modal taking the whole screen with a spinner
const LoaderModal = ({ text }) => {
  return (
    <Modal show={true} centered>
      <Modal.Body>
        <div className="loader-modal text-center">
          <Spinner animation="border" variant="primary" />
          <p>{text}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const DeploymentList = () => {
  const { deployments } = useDeployments();
  const [isUpdatingDeployment, setIsUpdatingDeployment] = useState(false);
  const [docSelectedDeployment, setDocSelectedDeployment] = useState(null);
  const [apiKeysModalSelectedDeployment, setApiKeysModalSelectedDeployment] =
    useState(null);

  const getResourceIcon = (type) => {
    switch (type) {
      case "managed":
        return <FaCloud />;
      case "aws":
        return <FaAws />;
      case "googleCloud":
        return <FaGoogle />;
      default:
        return null;
    }
  };

  const getDeploymentUrl = (deployment) => {
    return `https://cx0.io/${deployment.id}`;
  };

  const getStatusColor = (status) => {
    const statusColors = {
      active: "green",
      pending: "orange",
      error: "red",
      inactive: "grey"
    };
    return statusColors[status] || "grey";
  };

  const getStatusBadge = (status) => {
    const statusClasses = {
      pending: "status-pending",
      active: "status-active",
      inactive: "status-inactive",
      error: "status-error"
    };
    return (
      <span className={`deployment-status ${statusClasses[status]}`}>
        {status}
      </span>
    );
  };

  console.log("readableApiMap", docSelectedDeployment?.readableApiMap);

  const updateDeployment = async (deployment) => {
    setIsUpdatingDeployment(true);
    const response = await DeploymentApi.updateManaged(deployment.id);
    setIsUpdatingDeployment(false);
  };

  return (
    <>
      {isUpdatingDeployment && <LoaderModal text="Updating deployment..." />}
      <div className="deployment-list">
        {deployments.map((deployment) => (
          <div key={deployment.id} className="deployment-item">
            <span className="deployment-name">{deployment.name}</span>
            <span
              className="deployment-type"
              style={{ color: getStatusColor(deployment.type) }}
            >
              {deployment.type.toUpperCase()}
            </span>
            {getStatusBadge(deployment.status)}
            <span className="deployment-resource">
              {getResourceIcon(deployment.resourceType)}
            </span>
            <span className="deployment-url">
              {getDeploymentUrl(deployment)}
            </span>
            <div className="deployment-action-dropdown">
              <FaEllipsisV className="deployment-action-icon" />
              <div className="deployment-dropdown-content">
                <a
                  href="#"
                  onClick={() => setDocSelectedDeployment(deployment)}
                >
                  <FaBook /> API Documentation
                </a>
                <a onClick={() => updateDeployment(deployment)}>
                  <FaCloudUploadAlt /> Deploy Latest Version
                </a>
                <a
                  href="#"
                  onClick={() => {
                    setApiKeysModalSelectedDeployment(deployment);
                  }}
                >
                  <FaKey /> API Keys
                </a>
                <a href="#">
                  <FaCog /> Configuration
                </a>
                <a href="#">
                  <FaChartLine /> Analytics
                </a>
                <a href="#">
                  <FaMoon /> Sleep Mode
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
      {docSelectedDeployment && (
        <ApiModelDocumentationModal
          deployment={docSelectedDeployment}
          readableApiMap={docSelectedDeployment.readableApiMap}
          systemOverview={docSelectedDeployment.systemOverview}
          deploymentModel={docSelectedDeployment.deploymentModel}
          baseApiUrl={getDeploymentUrl(docSelectedDeployment)}
          show={true}
          handleClose={() => setDocSelectedDeployment(null)}
        />
      )}
      {apiKeysModalSelectedDeployment && (
        <ApiKeysModal
          deployment={apiKeysModalSelectedDeployment}
          show={true}
          onClose={() => setApiKeysModalSelectedDeployment(null)}
        />
      )}
    </>
  );
};

export default DeploymentList;
