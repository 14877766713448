import React, { useState } from "react";
import EndpointItem from "./EndpointItem";
import MiddlewareItem from "./MiddlewareItem";
import { DraggableAPIComponent } from "./ApiModelConstructionZone";
import "./RouteItem.css";

const RouteItem = ({
  component,
  draggbaleActions,
  onComponentChange,
  onRemoveComponent
}) => {
  const [url, setUrl] = useState(component.url || "");
  const { name, middlewares = [], routes = [], endpoints = [] } = component;

  // Handle change event of url input
  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };

  // Handle blur event of url input
  const handleUrlBlur = () => {
    onComponentChange({ ...component, url });
  };

  const renderMiddlewares = () => {
    return middlewares?.map((middleware, index) => {
      // Trouver le middleware dans la liste des components
      return (
        <DraggableAPIComponent
          type={middleware.type}
          key={index}
          id={middleware.id}
          index={index}
          className="middleware-item-parent-draggable"
          {...draggbaleActions}
        >
          {(isDragging) => {
            return (
              <MiddlewareItem
                onRemoveComponent={onRemoveComponent}
                component={middleware}
                isDragging={isDragging}
                key={middleware.id}
                onComponentChange={onComponentChange}
              />
            );
          }}
        </DraggableAPIComponent>
      );
    });
  };

  const renderEndpoints = () => {
    return endpoints?.map((endpoint, index) => {
      // Trouver le middleware dans la liste des components
      return (
        <DraggableAPIComponent
          type={endpoint.type}
          key={index}
          id={endpoint.id}
          index={index}
          className="endpoint-item-parent-draggable"
          {...draggbaleActions}
        >
          {(isDragging) => {
            return (
              <EndpointItem
                onRemoveComponent={onRemoveComponent}
                draggbaleActions={draggbaleActions}
                isDragging={isDragging}
                key={endpoint.id}
                component={endpoint}
                onComponentChange={onComponentChange}
              />
            );
          }}
        </DraggableAPIComponent>
      );
    });
  };

  const renderRoutes = () => {
    return routes?.map((route, index) => {
      // Trouver le middleware dans la liste des components
      return (
        <DraggableAPIComponent
          type={route.type}
          key={index}
          id={route.id}
          index={index}
          className="route-item-parent-draggable"
          {...draggbaleActions}
        >
          {(isDragging) => {
            return (
              <RouteItem
                onRemoveComponent={onRemoveComponent}
                draggbaleActions={draggbaleActions}
                component={route}
                isDragging={isDragging}
                key={route.id}
                onComponentChange={onComponentChange}
              />
            );
          }}
        </DraggableAPIComponent>
      );
    });
  };

  const totalComponentLength =
    middlewares.length + endpoints.length + routes.length;

  return (
    <div
      className={`route-parent-item ${
        middlewares.length || endpoints.length || routes.length
          ? "has-components"
          : ""
      }`}
    >
      <div className="route-item draggable-component">
        <input
          type="text"
          value={url}
          onChange={handleUrlChange}
          onBlur={handleUrlBlur}
          placeholder="URL de la route"
          className="route-url-input"
        />
      </div>
      {renderMiddlewares()}
      {renderEndpoints()}
      {renderRoutes()}
    </div>
  );
};

export default RouteItem;
