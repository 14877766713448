// Utils
const createSpace = (count) => {
  let space = "";
  for (let i = 0; i < count; i++) {
    space += " ";
  }
  return space;
};

const getBodyJsonText = (body, spaceCount = 2) => {
  let bodyJson = "{\n";
  let spaceData = createSpace(spaceCount);
  let spaceClose = createSpace(spaceCount - 2);

  if (!body || Object.keys(body).length === 0) {
    return "{}";
  }

  const keys = Object.keys(body);
  keys.forEach((key, index) => {
    bodyJson += `${spaceData}"${key}": "Value ${index + 1}"`;
    if (index < keys.length - 1) {
      bodyJson += ",";
    }
    bodyJson += "\n";
  });

  bodyJson += `${spaceClose}}`;

  return bodyJson;
};

const getQueryJsonText = (query, spaceCount = 2) => {
  let queryJson = "{\n";
  let spaceData = createSpace(spaceCount);
  let spaceClose = createSpace(spaceCount - 2);

  if (!query || Object.keys(query).length === 0) {
    return "{}";
  }

  const keys = Object.keys(query);
  keys.forEach((key, index) => {
    queryJson += `${spaceData}"${key}": "Value ${index + 1}"`;
    if (index < keys.length - 1) {
      queryJson += ",";
    }
    queryJson += "\n";
  });

  queryJson += `${spaceClose}}`;

  return queryJson;
};

const getHeadersJsonText = (headers, spaceCount = 2) => {
  let headersJson = "{\n";
  let spaceData = createSpace(spaceCount);
  let spaceClose = createSpace(spaceCount - 2);

  if (!headers || Object.keys(headers).length === 0) {
    return "{}";
  }

  const keys = Object.keys(headers);
  keys.forEach((key, index) => {
    headersJson += `${spaceData}"${key}": "${headers[key]}"`;
    if (index < keys.length - 1) {
      headersJson += ",";
    }
    headersJson += "\n";
  });

  headersJson += `${spaceClose}}`;

  return headersJson;
};

// Functions to generate code examples
const getQueryString = (query = {}) => {
  let queryString = "";

  Object.keys(query).forEach((key, index) => {
    if (index > 0) {
      queryString += "&";
    }

    queryString += `${key}={value${index}}`;
  });

  return queryString;
};

/* JAVASCRIPT */

const generateVanillaJSCode = (endpointUrl, method, headers, body, query) => {
  // Génération de la chaîne de requête à partir de l'objet 'query'
  const queryString = getQueryString(query);
  const url = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;

  const headersString = getHeadersJsonText(headers, 4);

  const bodyString = getBodyJsonText(body, 4);

  return `fetch("${url}", {
  method: "${method.toUpperCase()}",
  headers: ${headersString},
  ${body ? `body: ${bodyString}` : ""}
})
.then(response => response.json())
.then(data => console.log(data))
.catch(error => console.error('Error:', error));`;
};

const generateAxiosCode = (endpointUrl, method, headers, body, query) => {
  const queryString = getQueryString(query);
  const url = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;

  const headersString = getHeadersJsonText(headers, 4);
  const bodyString = getBodyJsonText(body, 4);

  return `const axios = require('axios');

axios.${method.toLowerCase()}("${url}", ${body ? bodyString : "{}"}, {
  headers: ${headersString}
})
.then((response) => {
  console.log(response.data);
})
.catch((error) => {
  console.error('Error:', error);
});`;
};

/* PYTHON */

const generatePythonRequestsCode = (
  endpointUrl,
  method,
  headers,
  body,
  query
) => {
  const queryString = getQueryString(query);
  const url = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersString = getHeadersJsonText(headers, 4);
  const bodyString = getBodyJsonText(body, 4);

  return `
import requests
import json

url = "${url}"
headers = ${headersString}
${body ? `data = json.loads('${bodyString}')` : ""}

response = requests.${method.toLowerCase()}(url, headers=headers${
    body ? ", data=data" : ""
  })
print(response.json())
`.trim();
};

const generatePythonHTTPClientCode = (
  endpointUrl,
  method,
  headers,
  body,
  query
) => {
  const queryString = getQueryString(query);
  const url = new URL(endpointUrl);
  const headersString = getHeadersJsonText(headers, 4);
  const bodyString = getBodyJsonText(body, 4);

  return `
import http.client
import json

conn = http.client.HTTPSConnection("${url.host}")
headers = ${headersString}
${body ? `payload = json.loads('${bodyString}')` : ""}

conn.request("${method.toUpperCase()}", "${url.pathname}${
    queryString ? "?" + queryString : ""
  }"${body ? ", payload" : ""}, headers)
res = conn.getresponse()
data = res.read()

print(data.decode("utf-8"))
`.trim();
};

/* C# */

const generateCSharpHttpClientCode = (
  endpointUrl,
  method,
  headers,
  body,
  query
) => {
  const queryString = getQueryString(query);
  const url = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersString = getHeadersJsonText(headers, 20);
  const bodyString = getBodyJsonText(body, 20);

  return `
using System;
using System.Net.Http;
using System.Threading.Tasks;

class Program
{
    static async Task Main()
    {
        using (var client = new HttpClient())
        {
            var request = new HttpRequestMessage
            {
                Method = HttpMethod.${
                  method.charAt(0).toUpperCase() + method.slice(1).toLowerCase()
                },
                RequestUri = new Uri("${url}"),
                Headers = {
                    ${headersString.split("\n").join("\n    ").trim()}
                },
                ${
                  body
                    ? `Content = new StringContent(${bodyString}, Encoding.UTF8, "application/json")`
                    : ""
                }
            };

            using (var response = await client.SendAsync(request))
            {
                response.EnsureSuccessStatusCode();
                var body = await response.Content.ReadAsStringAsync();
                Console.WriteLine(body);
            }
        }
    }
}
`.trim();
};

const generateCSharpRestSharpCode = (
  endpointUrl,
  method,
  headers,
  body,
  query
) => {
  const queryString = getQueryString(query);
  const url = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersString = getHeadersJsonText(headers, 4);
  const bodyString = getBodyJsonText(body, 4);

  return `
using RestSharp;
using System;

class Program
{
    static void Main(string[] args)
    {
        var client = new RestClient("${url}");
        var request = new RestRequest(Method.${method.toUpperCase()});
        ${headersString
          .split("\n")
          .map((line) => `request.AddHeader(${line});`)
          .join("\n    ")}
        ${
          body
            ? `request.AddParameter("application/json", ${bodyString}, ParameterType.RequestBody);`
            : ""
        }

        IRestResponse response = client.Execute(request);
        Console.WriteLine(response.Content);
    }
}
`.trim();
};

/* ARDUINO */

const generateArduinoWiFiCode = (endpointUrl, method, headers, body, query) => {
  // Simplification pour l'exemple : Nous ne gérons pas les requêtes POST avec body ou les headers ici
  const queryString = getQueryString(query);
  const url = new URL(endpointUrl);
  const pathWithQuery = `${url.pathname}${
    queryString ? "?" + queryString : ""
  }`;

  return `
#include <ESP8266WiFi.h>
#include <ESP8266HTTPClient.h>

const char* ssid = "yourSSID";
const char* password = "yourPASSWORD";

void setup() {
  Serial.begin(115200);
  WiFi.begin(ssid, password);

  while (WiFi.status() != WL_CONNECTED) {
    delay(1000);
    Serial.println("Connecting to WiFi...");
  }

  HTTPClient http;
  http.begin("${url.protocol}//${url.host}${pathWithQuery}");
  int httpCode = http.${method.toLowerCase()}();

  if (httpCode > 0) {
    String payload = http.getString();
    Serial.println(payload);
  } else {
    Serial.println("Error on HTTP request");
  }

  http.end();
}

void loop() {
  // Mettez votre code de boucle ici
}
`.trim();
};

const generateArduinoEthernetCode = (
  endpointUrl,
  method,
  headers,
  body,
  query
) => {
  // Simplification pour l'exemple : Nous ne gérons pas les requêtes POST avec body ou les headers ici
  const queryString = getQueryString(query);
  const url = new URL(endpointUrl);
  const pathWithQuery = `${url.pathname}${
    queryString ? "?" + queryString : ""
  }`;

  return `
#include <Ethernet.h>
#include <SPI.h>

byte mac[] = { 0xDE, 0xAD, 0xBE, 0xEF, 0xFE, 0xED };

void setup() {
  Serial.begin(9600);
  if (Ethernet.begin(mac) == 0) {
    Serial.println("Failed to configure Ethernet using DHCP");
    while(true);
  }

  EthernetClient client;
  if (client.connect("${url.host}", 80)) {
    client.println("${method.toUpperCase()} ${pathWithQuery} HTTP/1.1");
    client.println("Host: ${url.host}");
    client.println("Connection: close");
    client.println();

    while (client.connected()) {
      if (client.available()) {
        char c = client.read();
        Serial.write(c);
      }
    }

    client.stop();
  }
}

void loop() {
  // Mettez votre code de boucle ici
}
`.trim();
};

/* RUBY */

const generateRubyNetHttpCode = (endpointUrl, method, headers, body, query) => {
  const queryString = getQueryString(query);
  const url = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersString = getHeadersJsonText(headers, 2);
  const bodyString = getBodyJsonText(body, 2);
  const headersLines = Object.entries(headers).map(
    ([key, value]) => `${key}: ${value}`
  );

  return `
require 'net/http'
require 'uri'
require 'json'

uri = URI.parse("${url}")
http = Net::HTTP.new(uri.host, uri.port)
request = Net::HTTP::${
    method.charAt(0) + method.slice(1).toLowerCase()
  }.new(uri.request_uri)

${headersLines
  .map(
    (line) =>
      `request["${line.trim().split(":")[0]}"] = "${line
        .trim()
        .split(":")[1]
        .trim()}"`
  )
  .join("\n")}

${body ? `request.body = ${bodyString}` : ""}

response = http.request(request)
puts response.body
`.trim();
};

const generateRubyFaradayCode = (endpointUrl, method, headers, body, query) => {
  const queryString = getQueryString(query);
  const url = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersString = getHeadersJsonText(headers, 2);
  const bodyString = getBodyJsonText(body, 2);
  const headersLines = Object.entries(headers).map(
    ([key, value]) => `${key}: ${value}`
  );

  return `
require 'faraday'
require 'json'

conn = Faraday.new(url: "${url}") do |faraday|
  faraday.adapter Faraday.default_adapter
end

response = conn.${method.toLowerCase()} do |req|
  ${headersLines
    .map(
      (line) =>
        `req.headers['${line.trim().split(":")[0]}'] = '${line
          .trim()
          .split(":")[1]
          .trim()}'`
    )
    .join("\n  ")}
  ${body ? `req.body = ${bodyString}` : ""}
end

puts response.body
`.trim();
};

/* PHP */

const generatePHPCurlCode = (endpointUrl, method, headers, body, query) => {
  const queryString = getQueryString(query);
  const url = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersString = Object.entries(headers)
    .map(([key, value]) => `"${key}: ${value}"`)
    .join(", ");
  const bodyString = JSON.stringify(body);

  return `
<?php
$curl = curl_init();

curl_setopt_array($curl, [
    CURLOPT_URL => "${url}",
    CURLOPT_CUSTOMREQUEST => "${method.toUpperCase()}",
    CURLOPT_HTTPHEADER => [${headersString}],
    ${body ? `CURLOPT_POSTFIELDS => '${bodyString}',` : ""}
    CURLOPT_RETURNTRANSFER => true,
]);

$response = curl_exec($curl);
curl_close($curl);

echo $response;
?>
`.trim();
};

const generatePHPGuzzleCode = (endpointUrl, method, headers, body, query) => {
  const queryString = getQueryString(query);
  const url = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersString = getHeadersJsonText(headers, 4).replace(/"/g, "'");
  const bodyString = getBodyJsonText(body, 4).replace(/"/g, "'");

  return `
<?php
require 'vendor/autoload.php';

use GuzzleHttp\\Client;

$client = new Client();
$response = $client->request('${method.toUpperCase()}', '${url}', [
    'headers' => ${headersString},
    ${body ? `'json' => ${bodyString}` : ""}
]);

echo $response->getBody();
?>
`.trim();
};

/* GO */

const generateGoNetHttpCode = (endpointUrl, method, headers, body, query) => {
  const queryString = getQueryString(query);
  const url = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersString = getHeadersJsonText(headers, 2).replace(/"/g, "`");
  const bodyString = getBodyJsonText(body, 2).replace(/"/g, "`");
  const headersLines = Object.entries(headers).map(
    ([key, value]) => `${key}: ${value}`
  );

  return `
package main

import (
    "net/http"
    "fmt"
    "io/ioutil"
    "strings"
)

func main() {
    client := &http.Client{}
    req, err := http.NewRequest("${method.toUpperCase()}", "${url}", strings.NewReader(${bodyString}))
    if err != nil {
        // handle error
    }

    ${headersLines
      .map(
        (line) =>
          `req.Header.Set(${line.trim().split(":")[0]}, ${line
            .trim()
            .split(":")[1]
            .trim()})`
      )
      .join("\n    ")}

    resp, err := client.Do(req)
    if err != nil {
        // handle error
    }
    defer resp.Body.Close()
    body, err := ioutil.ReadAll(resp.Body)
    fmt.Println(string(body))
}
`.trim();
};

/* SWIFT */

const generateSwiftURLSessionCode = (
  endpointUrl,
  method,
  headers,
  body,
  query
) => {
  const queryString = getQueryString(query);
  const url = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const bodyString = getBodyJsonText(body, 2).replace(/"/g, "`");
  const headersLines = Object.entries(headers).map(
    ([key, value]) => `${key}: ${value}`
  );

  return `
import Foundation

let url = URL(string: "${url}")!
var request = URLRequest(url: url)
request.httpMethod = "${method.toUpperCase()}"

${headersLines
  .map(
    (line) =>
      `request.addValue(${line
        .trim()
        .split(":")[1]
        .trim()}, forHTTPHeaderField: ${line.trim().split(":")[0]})`
  )
  .join("\n")}

${
  body
    ? `let json: [String: Any] = ${bodyString}
let jsonData = try? JSONSerialization.data(withJSONObject: json)`
    : ""
}

let task = URLSession.shared.dataTask(with: request) { data, response, error in
    guard let data = data, error == nil else {
        print(error?.localizedDescription ?? "No data")
        return
    }
    let responseJSON = try? JSONSerialization.jsonObject(with: data, options: [])
    if let responseJSON = responseJSON as? [String: Any] {
        print(responseJSON)
    }
}

task.resume()
`.trim();
};

const generateSwiftAlamofireCode = (
  endpointUrl,
  method,
  headers,
  body,
  query
) => {
  const queryString = getQueryString(query);
  const url = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const bodyString = getBodyJsonText(body, 2).replace(/"/g, "`");
  const headersLines = Object.entries(headers).map(
    ([key, value]) => `${key}: ${value}`
  );

  return `
import Alamofire

let headers: HTTPHeaders = [
  ${headersLines
    .map(
      (line) =>
        `${line.trim().split(":")[0].trim()}: ${line
          .trim()
          .split(":")[1]
          .trim()}`
    )
    .join(",\n  ")}
]

${
  body
    ? `let parameters: Parameters = ${bodyString}`
    : "let parameters: Parameters = [:]"
}

Alamofire.request("${url}", method: .${method.toLowerCase()}, parameters: parameters, encoding: JSONEncoding.default, headers: headers)
  .responseJSON { response in
      switch response.result {
      case .success(let value):
          print("Response JSON: \\(value)")
      case .failure(let error):
          print(error)
      }
}
`.trim();
};

/* KOTLIN */

const generateKotlinOkHttpCode = (
  endpointUrl,
  method,
  headers,
  body,
  query
) => {
  const queryString = getQueryString(query);
  const url = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersString = getHeadersJsonText(headers, 2).replace(/"/g, '"');
  const bodyString = getBodyJsonText(body, 2).replace(/"/g, '"');
  const headersLines = Object.entries(headers).map(
    ([key, value]) => `${key}: ${value}`
  );

  return `
import okhttp3.OkHttpClient
import okhttp3.Request
import okhttp3.RequestBody
import okhttp3.MediaType

fun main() {
    val client = OkHttpClient()

    val mediaType = MediaType.parse("application/json")
    val body = RequestBody.create(mediaType, ${bodyString})
    val request = Request.Builder()
        .url("${url}")
        .method("${method.toUpperCase()}", ${
    method.toLowerCase() === "get" ? "null" : "body"
  })
        ${headersLines
          .map(
            (line) =>
              `.addHeader(${line.trim().split(":")[0]}, ${line
                .trim()
                .split(":")[1]
                .trim()})`
          )
          .join("\n        ")}

    val response = client.newCall(request.build()).execute()
    println(response.body()?.string())
}
`.trim();
};

const generateKotlinKtorCode = (endpointUrl, method, headers, body, query) => {
  const queryString = getQueryString(query);
  const url = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersString = getHeadersJsonText(headers, 2).replace(/"/g, '"');
  const bodyString = getBodyJsonText(body, 2).replace(/"/g, '"');
  const headersLines = Object.entries(headers).map(
    ([key, value]) => `${key}: ${value}`
  );

  return `
import io.ktor.client.*
import io.ktor.client.engine.cio.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.coroutines.*

fun main() = runBlocking {
    val client = HttpClient(CIO)
    try {
        val response: HttpResponse = client.request("${url}") {
            method = HttpMethod.${method.toUpperCase()}
            ${headersLines
              .map(
                (line) =>
                  `header(${line.trim().split(":")[0]}, ${line
                    .trim()
                    .split(":")[1]
                    .trim()})`
              )
              .join("\n            ")}
            ${body ? `body = ${bodyString}` : ""}
        }
        println(response.readText())
    } catch (e: Exception) {
        e.printStackTrace()
    } finally {
        client.close()
    }
}
`.trim();
};

/* RUST */

const formatRustHeaders = (format, key, value) => {
  return format.replace("${key}", key).replace("${value}", value);
};

const generateRustReqwestCode = (endpointUrl, method, headers, body, query) => {
  const queryString = getQueryString(query);
  const url = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersString = Object.entries(headers)
    .map(([key, value]) => formatRustHeaders("{}: {}", `${key}`, `${value}`))
    .join(",\n        ");
  const bodyString = JSON.stringify(body);

  return `
extern crate reqwest;

#[tokio::main]
async fn main() -> Result<(), reqwest::Error> {
    let client = reqwest::Client::new();
    let res = client.${method.toLowerCase()}("${url}")
        .headers(reqwest::header::HeaderMap::from_headers(vec![
            ${headersString}
        ]))
        ${body ? `.body(String::from('${bodyString}'))` : ""}
        .send()
        .await?;

    println!("Status: {}", res.status());
    println!("Headers: {:#?}", res.headers());
    let body = res.text().await?;
    println!("Body: {}", body);

    Ok(())
}
`.trim();
};

/* TYPESCRIPT */
const getTypeScriptType = (type) => {
  return type && type.toLowerCase() !== "unknown" ? type : "any";
};

const generateTypeScriptAxiosCode = (
  endpointUrl,
  method,
  headers,
  body,
  query
) => {
  const queryString = getQueryString(query);
  const url = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersString = getHeadersJsonText(headers, 4);
  const bodyString = getBodyJsonText(body, 4);

  // Génération des types pour TypeScript
  const queryTypes = query
    ? Object.entries(query)
        .map(([key, { type }]) => `${key}: ${getTypeScriptType(type)}`)
        .join("; ")
    : "";
  const bodyTypes = body
    ? Object.entries(body)
        .map(([key, { type }]) => `${key}: ${getTypeScriptType(type)}`)
        .join("; ")
    : "";

  return `
import axios from 'axios';

interface QueryParams {
  ${queryTypes}
}

interface BodyParams {
  ${bodyTypes}
}

const queryParams: QueryParams = ${query ? `{ ${queryString} }` : "{}"};
const bodyParams: BodyParams = ${body ? bodyString : "{}"};

axios.${method.toLowerCase()}<ResponseType>(${JSON.stringify(url)}, {
  params: queryParams,
  data: bodyParams,
  headers: ${headersString}
})
.then((response) => {
  console.log(response.data);
})
.catch((error) => {
  console.error('Error:', error);
});
`.trim();
};

const generateTypeScriptAngularHttpCode = (
  endpointUrl,
  method,
  headers,
  body,
  query
) => {
  const queryString = getQueryString(query);
  const url = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersString = getHeadersJsonText(headers, 4);
  const bodyString = getBodyJsonText(body, 4);

  // Génération des types pour TypeScript
  const queryTypes = query
    ? Object.entries(query)
        .map(([key, { type }]) => `${key}: ${getTypeScriptType(type)}`)
        .join("; ")
    : "";
  const bodyTypes = body
    ? Object.entries(body)
        .map(([key, { type }]) => `${key}: ${getTypeScriptType(type)}`)
        .join("; ")
    : "";

  return `
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient) {}

  public ${method.toLowerCase()}Data() {
    const headers = ${headersString};
    const queryParams: QueryParams = ${query ? `{ ${queryString} }` : "{}"};
    const bodyParams: BodyParams = ${body ? bodyString : "{}"};

    return this.http.${method.toLowerCase()}<ResponseType>('${url}', ${
    method.toLowerCase() === "get" ? "queryParams" : "bodyParams"
  }, { headers })
      .toPromise()
      .then(response => console.log(response))
      .catch(error => console.error('Error:', error));
  }
}

interface QueryParams {
  ${queryTypes}
}

interface BodyParams {
  ${bodyTypes}
}
`.trim();
};

/* DART */
const generateDartHttpCode = (endpointUrl, method, headers, body, query) => {
  const queryString = getQueryString(query);
  const url = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersString = getHeadersJsonText(headers, 2).replaceAll('"', "");
  const bodyString = getBodyJsonText(body, 2);

  return `
import 'package:http/http.dart' as http;
import 'dart:convert';

void main() async {
  var uri = Uri.parse("${url}");
  var response = await http.${method.toLowerCase()}(
    uri,
    headers: <String, String>{${headersString}},
    ${body ? `body: jsonEncode(${bodyString})` : ""}
  );
  print('Response status: \${response.statusCode}');
  print('Response body: \${response.body}');
}
`.trim();
};

const generateDartDioCode = (endpointUrl, method, headers, body, query) => {
  const queryString = getQueryString(query);
  const fullUrl = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersString = getHeadersJsonText(headers, 2).replaceAll('"', "'");
  const bodyString = getBodyJsonText(body, 2);

  return `
import 'package:dio/dio.dart';

void main() async {
  var dio = Dio();
  var response = await dio.request(
    "${fullUrl}",
    data: ${body ? `jsonDecode('${bodyString}')` : "null"},
    options: Options(
      method: "${method.toUpperCase()}",
      headers: ${headersString},
    ),
  );
  print(response.data);
}
`.trim();
};

/* LUA */
const generateLuaLuaSocketCode = (
  endpointUrl,
  method,
  headers,
  body,
  query
) => {
  const queryString = getQueryString(query);
  const fullUrl = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersString = Object.entries(headers)
    .map(([key, value]) => `["${key}"] = "${value}"`)
    .join(", ");
  const bodyString = JSON.stringify(body);

  return `
local http = require("socket.http")
local ltn12 = require("ltn12")
local json = require("json")

local response_body = {}
local request_body = ${body ? bodyString : "nil"}

local res, code, response_headers, status = http.request{
    url = "${fullUrl}",
    method = "${method.toUpperCase()}",
    headers = {
        ${headersString}
    },
    source = ltn12.source.string(request_body),
    sink = ltn12.sink.table(response_body)
}

if code == 200 then
    print(table.concat(response_body))
else
    print(status)
end
`.trim();
};

/* ELIXIR */
const generateElixirHTTPoisonCode = (
  endpointUrl,
  method,
  headers,
  body,
  query
) => {
  const queryString = getQueryString(query);
  const fullUrl = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersList = Object.entries(headers)
    .map(([key, value]) => `{:"${key}", "${value}"}`)
    .join(", ");
  const bodyString = JSON.stringify(body);

  return `
defmodule MyHTTPClient do
  @moduledoc """
  Simple HTTP client using HTTPoison.
  """

  def fetch_data do
    headers = [${headersList}]
    body = ${body ? `{:json, ${bodyString}}` : `""`}

    case HTTPoison.request(:${method.toLowerCase()}, "${fullUrl}", body, headers) do
      {:ok, %HTTPoison.Response{status_code: 200, body: response_body}} ->
        IO.puts("Response: #{response_body}")
      {:error, %HTTPoison.Error{reason: reason}} ->
        IO.inspect(reason, label: "Error")
    end
  end
end
`.trim();
};

/* SCALA */
const generateScalaAkkaHttpCode = (
  endpointUrl,
  method,
  headers,
  body,
  query
) => {
  const queryString = getQueryString(query);
  const fullUrl = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersList = Object.entries(headers)
    .map(([key, value]) => `RawHeader("${key}", "${value}")`)
    .join(",\n    ");
  const bodyString = JSON.stringify(body);

  return `
import akka.actor.ActorSystem
import akka.http.scaladsl.Http
import akka.http.scaladsl.model._
import akka.stream.ActorMaterializer
import scala.concurrent.Future
import scala.util.{Failure, Success}

object HttpClient extends App {
  implicit val system = ActorSystem()
  implicit val materializer = ActorMaterializer()
  import system.dispatcher

  val requestBody = HttpEntity(ContentTypes.\`application/json\`, ${
    body ? `"${bodyString}"` : `""`
  })
  val request = HttpRequest(
    method = HttpMethods.${method.toUpperCase()},
    uri = "${fullUrl}",
    headers = List(${headersList}),
    entity = requestBody
  )

  val responseFuture: Future[HttpResponse] = Http().singleRequest(request)

  responseFuture.onComplete {
    case Success(res) => println(s"Response: \${res.entity}")
    case Failure(_)   => sys.error("something wrong")
  }
}
`.trim();
};

const generateScalaPlayWsCode = (endpointUrl, method, headers, body, query) => {
  const queryString = getQueryString(query);
  const fullUrl = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersList = Object.entries(headers)
    .map(([key, value]) => `s""""${key}" -> "${value}"""")`)
    // .map(([key, value]) => s""""${key}" -> "${value}"""")
    .join(",\n    ");
  const bodyString = JSON.stringify(body);

  return `
import play.api.libs.ws._
import play.api.libs.ws.ahc.AhcWSClient
import scala.concurrent.ExecutionContext.Implicits.global

object HttpClient extends App {
  val wsClient = AhcWSClient()

  wsClient.url("${fullUrl}")
    .withMethod("${method.toUpperCase()}")
    .withHttpHeaders(${headersList})
    .withBody(${body ? `"${bodyString}"` : `""`})
    .execute()
    .map { response =>
      println(s"Status: \${response.status}")
      println(s"Body: \${response.body}")
    }
    .recover {
      case e: Exception => e.printStackTrace()
    }

  wsClient.close()
}
`.trim();
};

/* CLOJURE */

const generateClojureCljHttpCode = (
  endpointUrl,
  method,
  headers,
  body,
  query
) => {
  const queryString = getQueryString(query);
  const fullUrl = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersMap = Object.entries(headers)
    .map(([key, value]) => `:${key} "${value}"`)
    .join(" ");
  const bodyString = body ? JSON.stringify(body) : "";

  return `
(ns http-client.core
  (:require [clj-http.client :as client]))

(defn fetch-data []
  (let [response (client/request {:url "${fullUrl}"
                                  :method :${method.toLowerCase()}
                                  :headers {${headersMap}}
                                  ${
                                    body
                                      ? `:body (json/write-str ${bodyString})`
                                      : ""
                                  }})
  ]
    (println (:status response))
    (println (:body response))))
`.trim();
};

/* F# */

const generateFSharpDataCode = (endpointUrl, method, headers, body, query) => {
  const queryString = getQueryString(query);
  const fullUrl = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersList = Object.entries(headers)
    .map(([key, value]) => `("${key}", "${value}")`)
    .join("; ");
  const bodyString = JSON.stringify(body);

  return `
open FSharp.Data
open System.Net

let sendRequest () =
    let url = "${fullUrl}"
    let request = Http.RequestString(
        url,
        httpMethod = "${method.toUpperCase()}",
        headers = [${headersList}],
        ${body ? `body = TextRequest ${bodyString}` : ""}
    )
    printfn "%s" request
`.trim();
};

/* R */

const generateRHttrCode = (endpointUrl, method, headers, body, query) => {
  const queryString = getQueryString(query);
  const fullUrl = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersList = Object.entries(headers)
    .map(([key, value]) => `add_headers(${key} = "${value}")`)
    .join(" %>% ");
  const bodyString = JSON.stringify(body);

  return `
library(httr)

response <- VERB("${method.toUpperCase()}", "${fullUrl}"${
    headersList.length > 0 ? ` %>% ${headersList}` : ""
  }${body ? `, body = ${bodyString}` : ""})

content(response, "text")
`.trim();
};

/* PERL */
const generatePerlLwpCode = (endpointUrl, method, headers, body, query) => {
  const queryString = getQueryString(query);
  const fullUrl = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersList = Object.entries(headers)
    .map(([key, value]) => `'$key' => '$value'`)
    .join(", ");
  const bodyString = JSON.stringify(body);

  return `
use LWP::UserAgent;
use HTTP::Request;

my $ua = LWP::UserAgent->new;
my $req = HTTP::Request->new('${method.toUpperCase()}' => '${fullUrl}');

$req->header(${headersList});
${body ? `$req->content('${bodyString}');` : ""}

my $res = $ua->request($req);

if ($res->is_success) {
    print $res->decoded_content;
} else {
    print "HTTP Error: ", $res->status_line, "\\n";
}
`.trim();
};

/* OBJECTIVE-C */

const generateObjectiveCNSURLSessionCode = (
  endpointUrl,
  method,
  headers,
  body,
  query
) => {
  const queryString = getQueryString(query);
  const fullUrl = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersList = Object.entries(headers)
    .map(
      ([key, value]) =>
        `[request setValue:@"${value}" forHTTPHeaderField:@"${key}"];`
    )
    .join("\n    ");
  const bodyString = body ? JSON.stringify(body) : "{}";

  return `
#import <Foundation/Foundation.h>

@interface HTTPClient : NSObject
- (void)sendRequest;
@end

@implementation HTTPClient

- (void)sendRequest {
    NSURL *url = [NSURL URLWithString:@"${fullUrl}"];
    NSMutableURLRequest *request = [NSMutableURLRequest requestWithURL:url];
    [request setHTTPMethod:@"${method.toUpperCase()}"];
    ${headersList}
    [request setHTTPBody:[@"${bodyString}" dataUsingEncoding:NSUTF8StringEncoding]];

    NSURLSession *session = [NSURLSession sharedSession];
    NSURLSessionDataTask *dataTask = [session dataTaskWithRequest:request
        completionHandler:^(NSData *data, NSURLResponse *response, NSError *error) {
            if (error) {
                NSLog(@"Error: %@", error);
            } else {
                NSString *result = [[NSString alloc] initWithData:data encoding:NSUTF8StringEncoding];
                NSLog(@"%@", result);
            }
        }];
    [dataTask resume];
}

@end

int main(int argc, const char * argv[]) {
    @autoreleasepool {
        HTTPClient *client = [[HTTPClient alloc] init];
        [client sendRequest];
    }
    return 0;
}
`.trim();
};

/* JULIA */

const generateJuliaHttpJuliaCode = (
  endpointUrl,
  method,
  headers,
  body,
  query
) => {
  const queryString = getQueryString(query);
  const fullUrl = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersList = Object.entries(headers)
    .map(([key, value]) => `("${key}" => "${value}")`)
    .join(", ");
  const bodyString = body ? JSON.stringify(body) : "{}";

  return `
using HTTP

function send_request()
    url = "${fullUrl}"
    headers = Dict(${headersList})
    body = ${body ? `"${bodyString}"` : "nothing"}

    response = HTTP.request("${method.toUpperCase()}", url, headers, body)
    println(String(response.body))
end

send_request()
`.trim();
};

/* HASKELL */

const generateHaskellWreqCode = (endpointUrl, method, headers, body, query) => {
  const queryString = getQueryString(query);
  const fullUrl = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersList = Object.entries(headers)
    .map(
      ([key, value]) => `(hFromByteString "${key}", hFromByteString "${value}")`
    )
    .join(" : ");
  const bodyString = JSON.stringify(body);

  return `
{-# LANGUAGE OverloadedStrings #-}

import Network.Wreq
import Control.Lens
import Data.Aeson (object, (.=))
import qualified Data.ByteString.Char8 as BC
import qualified Data.ByteString.Lazy as BL

hFromByteString :: BC.ByteString -> BC.ByteString -> Header
hFromByteString k v = (BC.pack k, BC.pack v)

main = do
  let opts = defaults & headers .~ [${headersList}]
  r <- ${method.toLowerCase()}With opts "${fullUrl}" ${
    body ? `(object ["body" .= ${bodyString}])` : "empty"
  }
  putStrLn $ BL.unpack $ r ^. responseBody
`.trim();
};

const generateHaskellServantCode = (
  endpointUrl,
  method,
  headers,
  body,
  query
) => {
  // Remarque : Servant est normalement utilisé pour la création d'API, pas pour les requêtes clients.
  // Cette fonction suppose que vous avez déjà défini des types d'API avec Servant et que vous souhaitez faire une requête vers cette API.
  const apiType = "MyApi"; // Remplacez par le type d'API correspondant
  const functionName = "apiFunction"; // Remplacez par le nom de la fonction API

  const methodSignature = (method, body) => {
    // Génère la signature de la méthode en fonction de la méthode HTTP et du corps de la requête
    switch (method.toUpperCase()) {
      case "GET":
        return "ClientM Response";
      case "POST":
      case "PUT":
        // Génère une signature avec un corps de requête pour les méthodes POST et PUT
        return `ReqBody '[JSON] ${
          body ? "YourRequestBodyType" : "NoContent"
        } -> ClientM Response`;
      // Ajouter d'autres méthodes au besoin
      default:
        return "ClientM Response";
    }
  };

  const apiParameters = (method, body, query) => {
    // Génère les paramètres de la fonction API en fonction de la méthode, du corps et de la requête
    let params = "";
    if (
      body &&
      (method.toUpperCase() === "POST" || method.toUpperCase() === "PUT")
    ) {
      params += " yourRequestBody"; // Remplacer par la variable du corps de la requête réelle
    }
    if (query) {
      params += " queryParams"; // Remplacer par la variable des paramètres de requête réelle
    }
    return params;
  };

  const parseUrl = (endpointUrl) => {
    // Cette fonction doit extraire le chemin de base de l'URL pour l'utiliser avec Servant
    // Implémenter une logique pour analyser l'URL
    // Exemple simple (à améliorer pour une utilisation réelle) :
    const urlObj = new URL(endpointUrl);
    return urlObj.pathname;
  };

  return `
{-# LANGUAGE DataKinds #-}
{-# LANGUAGE TypeOperators #-}
{-# LANGUAGE OverloadedStrings #-}

import Servant.API
import Servant.Client
import Network.HTTP.Client (newManager, defaultManagerSettings)
import Data.Proxy

type MyApi = ${apiType}  -- Définir le type d'API ici

myApi :: Proxy MyApi
myApi = Proxy

${functionName} :: ${methodSignature(
    method,
    body
  )}  -- Signature de la fonction API
${functionName} = client myApi

run :: IO ()
run = do
  manager <- newManager defaultManagerSettings
  res <- runClientM (${functionName}${apiParameters(
    method,
    body,
    query
  )}) (mkClientEnv manager (BaseUrl Http "${parseUrl(endpointUrl)}" 80 ""))
  case res of
    Left err -> putStrLn $ "Error: " ++ show err
    Right response -> print response
`.trim();
};

/* ERLANG */

const generateErlangInetsCode = (endpointUrl, method, headers, body, query) => {
  const queryString = getQueryString(query);
  const fullUrl = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersList = Object.entries(headers)
    .map(([key, value]) => `{<<"${key}">>, <<"${value}">>}`)
    .join(", ");
  const bodyString = body ? JSON.stringify(body) : "{}";

  return `
-module(http_client).
-export([send_request/0]).

send_request() ->
    inets:start(),
    httpc:request('${method.toUpperCase()}', {"${fullUrl}", [${headersList}]}, [], [{body, "${bodyString}"}]).
`.trim();
};

/* SMALLTALK */

const generateSmalltalkZincCode = (
  endpointUrl,
  method,
  headers,
  body,
  query
) => {
  const fullUrl = `${endpointUrl}${query ? "?" + getQueryString(query) : ""}`;
  const headersList = Object.entries(headers)
    .map(([key, value]) => `'${key}: ${value}'`)
    .join(" with: ");

  return `
| client response |
client := ZnClient new.
client url: '${fullUrl}';
    method: '${method.toUpperCase()}'.
${headersList ? `client headers ${headersList};` : ""}
${
  body
    ? `client entity: (ZnStringEntity contents: '${JSON.stringify(body)}');`
    : ""
}
response := client execute.
^response contents
`.trim();
};

/* GROOVY */

const generateGroovyHttpBuilderCode = (
  endpointUrl,
  method,
  headers,
  body,
  query
) => {
  const queryString = getQueryString(query);
  const fullUrl = queryString ? `${endpointUrl}?${queryString}` : endpointUrl;
  const headersMap = Object.entries(headers)
    .map(([key, value]) => `"${key}": "${value}"`)
    .join(", ");
  const bodyString = body ? JSON.stringify(body) : "{}";

  return `
import groovyx.net.http.RESTClient
import groovyx.net.http.ContentType
import groovyx.net.http.Method

def client = new RESTClient("${fullUrl}")

def response = client.request(Method.${method.toUpperCase()}, ContentType.JSON) {
  headers {
    ${headersMap
      .split(", ")
      .map((h) => `set("${h.split(": ")[0]}", "${h.split(": ")[1]}")`)
      .join("\n    ")}
  }
  ${
    method.toUpperCase() === "POST" || method.toUpperCase() === "PUT"
      ? `body ${bodyString}`
      : ""
  }
}

println "Response: \${response.data}"
`.trim();
};

const CodeExampleMap = {
  javascript: {
    vanilla: generateVanillaJSCode,
    axios: generateAxiosCode
  },
  python: {
    requests: generatePythonRequestsCode,
    http: generatePythonHTTPClientCode
  },
  csharp: {
    httpclient: generateCSharpHttpClientCode,
    restsharp: generateCSharpRestSharpCode
  },
  arduino: {
    wifi: generateArduinoWiFiCode,
    ethernet: generateArduinoEthernetCode
  },
  ruby: {
    nethttp: generateRubyNetHttpCode,
    faraday: generateRubyFaradayCode
  },
  php: {
    curl: generatePHPCurlCode,
    guzzle: generatePHPGuzzleCode
  },
  go: {
    nethttp: generateGoNetHttpCode
  },
  swift: {
    urlsession: generateSwiftURLSessionCode,
    alamofire: generateSwiftAlamofireCode
  },
  kotlin: {
    okhttp: generateKotlinOkHttpCode,
    ktor: generateKotlinKtorCode
  },
  rust: {
    reqwest: generateRustReqwestCode
  },
  typescript: {
    axios: generateTypeScriptAxiosCode,
    angularhttp: generateTypeScriptAngularHttpCode
  },
  dart: {
    http: generateDartHttpCode,
    dio: generateDartDioCode
  },
  lua: {
    luasocket: generateLuaLuaSocketCode
  },
  elixir: {
    httpoison: generateElixirHTTPoisonCode
  },
  scala: {
    akkahttp: generateScalaAkkaHttpCode,
    playws: generateScalaPlayWsCode
  },
  clojure: {
    cljhttp: generateClojureCljHttpCode
  },
  fsharp: {
    fsharpdata: generateFSharpDataCode
  },
  r: {
    httr: generateRHttrCode
  },
  perl: {
    lwp: generatePerlLwpCode
  },
  objectivec: {
    nsurlsession: generateObjectiveCNSURLSessionCode
  },
  julia: {
    httpjulia: generateJuliaHttpJuliaCode
  },
  haskell: {
    wreq: generateHaskellWreqCode,
    servant: generateHaskellServantCode
  },
  erlang: {
    inets: generateErlangInetsCode
  },
  smalltalk: {
    zinc: generateSmalltalkZincCode
  },
  groovy: {
    httpbuilder: generateGroovyHttpBuilderCode
  }
};

const GenerateCodeExample = (
  language,
  languageLib,
  endpointUrl,
  method,
  headers,
  body,
  query
) => {
  if (!CodeExampleMap[language] || !CodeExampleMap[language][languageLib]) {
    return "";
  }

  const code = CodeExampleMap[language][languageLib];
  return code(endpointUrl, method, headers, body, query);
};

export default GenerateCodeExample;
