import project from "./project";
import builtPrompt from "./builtPrompt";
import chainedBuilt from "./chainedBuilt";
import fineTune from "./fineTune";
import { FirestoreCrud } from "./FirestoreCrud";

const fineTuneDb = new FirestoreCrud("fineTunes").setSubCollection(
  "trainingFiles"
);

const chainsDB_apiProjects = new FirestoreCrud("chains");
chainsDB_apiProjects.setSubCollection("dataSets");
chainsDB_apiProjects.setSubCollection("inputConnections");
chainsDB_apiProjects.setSubCollection("emitters");

const inputApiConnectors_project = new FirestoreCrud("inputApiConnectors");
inputApiConnectors_project.setSubCollection("methods");

const dslScripts_project = new FirestoreCrud("dslScripts");

const deploymentModels = new FirestoreCrud("deploymentModels");
deploymentModels.setSubCollection("dataTest");

const apiProjectDB = new FirestoreCrud("apiProjects");
apiProjectDB.setSubCollection(chainsDB_apiProjects);
apiProjectDB.setSubCollection(inputApiConnectors_project);
apiProjectDB.setSubCollection(dslScripts_project);
apiProjectDB.setSubCollection(deploymentModels);
apiProjectDB.setSubCollection("builtPrompts");

const paidEntities = new FirestoreCrud("paidEntities");

const userAccountsDB = new FirestoreCrud("userAccounts");
userAccountsDB.setSubCollection("paymentLines");
userAccountsDB.setSubCollection("apiKeys");

const inputApiConnector_methods = new FirestoreCrud("methods");
const inputApiConnectors = new FirestoreCrud("inputApiConnectors");
inputApiConnectors.setSubCollection(inputApiConnector_methods);

const dslScripts = new FirestoreCrud("dslScripts");

const deployments = new FirestoreCrud("deployments");
const tokenGroups = new FirestoreCrud("tokenGroups");
const creaxysApiKeys = new FirestoreCrud("creaxysApiKeys");

export default {
  project,
  builtPrompt,
  chainedBuilt,
  fineTunes: fineTuneDb,
  projects: new FirestoreCrud("projects").setSubCollection("files"),
  apiProjects: apiProjectDB,
  builtPrompts: new FirestoreCrud("builtPrompts"),
  builtPromptTags: new FirestoreCrud("builtPromptTags"),
  builtPromptGroups: new FirestoreCrud("builtPromptGroups"),
  userAccounts: userAccountsDB,
  paidEntities,
  inputApiConnectors,
  dslScripts,
  deployments,
  tokenGroups,
  creaxysApiKeys
};

// quick doc
// available documents:
// apiProjects
// apiProjects.sub.chains
// apiProjects.sub.chains.sub.dataSets
// apiProjects.sub.chains.sub.inputConnections
// apiProjects.sub.chains.sub.emitters
// apiProjects.sub.inputApiConnectors
// apiProjects.sub.inputApiConnectors.sub.methods
// apiProjects.sub.dslScripts
// apiProjects.sub.builtPrompts
// userAccounts
