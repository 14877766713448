import React, { useContext, useState } from "react";
import { Button, ListGroup, Form } from "react-bootstrap";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ApiBuilderContext } from "../../../contexts/ApiBuilderContext";
import { SystemBuilderInterfaceContext } from "../../../contexts/SystemBuilderInterfaceContext";
import { ChainContext } from "../../../contexts/ChainContext";
import { useNavigate, useParams } from "react-router-dom";
import createRandomUniqueId from "../../../utils/createRandomUniqueId";

const GlobalParameters = () => {
  const { chainActions, chain } = useContext(ChainContext);
  const { globalParameters = [] } = chain;

  const addGlobalParameter = async (param) => {
    await chainActions.createGlobalParameter(param);
  };

  return (
    <div className="global-parameters-section">
      <h5 className="mb-3">Global Parameters</h5>
      <ListGroup>
        {globalParameters.map((param) => (
          <ParameterItem key={param.id} param={param} />
        ))}
      </ListGroup>
      <AddParameter onAdd={addGlobalParameter} />
    </div>
  );
};

const ParameterItem = ({ param }) => {
  return (
    <ListGroup.Item>
      {param.name} - {param.valueType}
    </ListGroup.Item>
  );
};

const AddParameter = ({ onAdd }) => {
  const [name, setName] = useState("");
  const [valueType, setValueType] = useState("rawValue"); // or 'input'
  const [value, setValue] = useState("");

  const handleSubmit = () => {
    const randomUniqueId = createRandomUniqueId();
    onAdd({ name, valueType, value, id: randomUniqueId });
    setName("");
    setValueType("rawValue");
    setValue("");
  };

  return (
    <div className="add-parameter-section">
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Parameter Name</Form.Label>
          <Form.Control
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Value Type</Form.Label>
          <Form.Control
            as="select"
            value={valueType}
            onChange={(e) => setValueType(e.target.value)}
          >
            <option value="rawValue">Direct</option>
            <option value="input">Input</option>
          </Form.Control>
        </Form.Group>
        {valueType === "rawValue" && (
          <Form.Group className="mb-3">
            <Form.Label>Value</Form.Label>
            <Form.Control
              type="text"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </Form.Group>
        )}
        <Button variant="primary" onClick={handleSubmit}>
          Add Parameter
        </Button>
      </Form>
    </div>
  );
};

const ItemType = {
  BLOCK: "block",
  CHAIN: "chain"
};

const BlockItem = ({ type, name }) => {
  const [{ isDragging }, ref] = useDrag({
    type: ItemType.BLOCK,
    item: { type },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  });

  const iconMapping = {
    "data-fetcher": "fas fa-database",
    "ai-processing-block": "fas fa-brain",
    logic: "fas fa-code",
    "action-fetcher": "fas fa-robot",
    input: "fas fa-keyboard",
    "data-object": "fas fa-cube",
    "cron-emitter": "fas fa-clock",
    function: "fas fa-cogs"
  };

  return (
    <div
      ref={ref}
      className="block-item"
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <i className={`${iconMapping[type]} me-2`}></i>
      {name}
    </div>
  );
};

const getPositionFlow = (clientX, clientY, zoom, viewportX, viewportY) => {
  const xFromViewport = -(viewportX / zoom) + clientX / zoom;
  const yFromViewport = -(viewportY / zoom) + clientY / zoom;

  const positionFlow = {
    x: xFromViewport,
    y: yFromViewport
  };

  return positionFlow;
};

export const BlockLeftBarReceiver = ({ children }) => {
  const { chainActions } = useContext(ChainContext);
  const { builderViewPort } = useContext(SystemBuilderInterfaceContext);

  const [, ref] = useDrop({
    accept: ItemType.BLOCK,
    drop: async (item, monitor) => {
      const { type } = item;
      const clientOffset = monitor.getClientOffset();
      const { x, y } = clientOffset;
      const { zoom, x: viewportX, y: viewportY } = builderViewPort;

      console.log("dropData", {
        x,
        y,
        zoom,
        viewportX,
        viewportY
      });

      const positionFlow = getPositionFlow(
        x - 217,
        y - 53.5 - 60,
        // 0,
        // 0,
        zoom,
        viewportX,
        viewportY
      );

      console.log("positionFlow", positionFlow);

      if (type === "data-fetcher") {
        await chainActions.createInputConnection({
          positionFlow
        });
      }
      if (type === "ai-processing-block") {
        await chainActions.createPromptBlock({
          positionFlow
        });
      }
      if (type === "logic") {
        await chainActions.createEmitter({
          name: "New Logic Listener/Emitter",
          positionFlow,
          action: "logic"
        });
      }
      if (type === "action-fetcher") {
        await chainActions.createEmitter({
          name: "New Action Fetcher",
          positionFlow,
          action: "fetch"
        });
      }
      if (type === "input") {
        await chainActions.createInputBlock({
          positionFlow
        });
      }
      if (type === "data-object") {
        await chainActions.createDataObject({
          positionFlow
        });
      }

      console.log("item", item, monitor);
      // Handle the dropped block
      // For example, you could call an `update` function here

      console.log("clientOffset", clientOffset);
    }
  });

  return children(ref);
};

const BlockList = () => {
  return (
    <div className="block-list">
      <h5 className="mb-3">Blocks</h5>
      <ListGroup>
        <ListGroup.Item>
          <BlockItem type="input" name="Input" />
        </ListGroup.Item>
        <ListGroup.Item>
          <BlockItem type="data-fetcher" name="Data Fetcher" />
        </ListGroup.Item>
        <ListGroup.Item>
          <BlockItem type="ai-processing-block" name="AI Processing" />
        </ListGroup.Item>
        <ListGroup.Item>
          <BlockItem type="logic" name="Logic Listener/Emitter" />
        </ListGroup.Item>
        <ListGroup.Item>
          <BlockItem type="action-fetcher" name="Action Fetcher" />
        </ListGroup.Item>
        <ListGroup.Item>
          <BlockItem type="function" name="Function" />
        </ListGroup.Item>
        {/* <ListGroup.Item>
          <BlockItem type="data-object" name="Data Object" />
        </ListGroup.Item>
        <ListGroup.Item>
          <BlockItem type="cron-emitter" name="Cron Emitter" />
        </ListGroup.Item> */}
      </ListGroup>
    </div>
  );
};

const ChainItem = ({ chain, index, moveChain }) => {
  const [, ref] = useDrag({
    type: ItemType.CHAIN,
    item: { id: chain.id, index }
  });

  const [, drop] = useDrop({
    accept: ItemType.CHAIN,
    hover(item, monitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      moveChain(dragIndex, hoverIndex);

      item.index = hoverIndex;
    }
  });

  return <div ref={(node) => ref(drop(node))}>{chain.name}</div>;
};

const ChainsList = () => {
  const {
    chains,
    systemActions,
    id: apiProjectId
  } = useContext(ApiBuilderContext);
  const { pageId: chainId } = useParams();

  const moveChain = (fromIndex, toIndex) => {
    // TODO: Update function to handle the reordering
  };

  const createChain = async () => {
    // TODO: Update function to create a new chain
    const newChain = await systemActions.createChain({
      positionOrder: chains.length + 1
    });

    navigate(`/system-designer/${apiProjectId}/${newChain.id}`);
  };

  const orderedChains = [...chains].sort(
    (a, b) => a.positionOrder || 0 - b.positionOrder || 0
  );

  const navigate = useNavigate();

  const navigateTo = (chainId) => {
    navigate(`/system-designer/${apiProjectId}/${chainId}`);
  };

  return (
    <>
      <h5 className="mb-3 title-5">Chains</h5>
      <Button className="mb-3" onClick={createChain}>
        +
      </Button>
      <ListGroup>
        {orderedChains.map((chain, index) => (
          <ListGroup.Item
            key={chain.id}
            active={chain.id === chainId}
            onClick={() => navigateTo(chain.id)}
            role="button"
          >
            <ChainItem chain={chain} index={index} moveChain={moveChain} />
          </ListGroup.Item>
        ))}
      </ListGroup>
    </>
  );
};

const LeftBarDesigner = () => {
  return (
    <div className="LeftBarDesigner">
      <ChainsList />
      <div className="divider"></div>
      <BlockList />
      <div className="divider"></div>

      <GlobalParameters />
    </div>
  );
};

// const LeftBarDesigner = () => {
//   return (
//     <DndProvider backend={HTML5Backend}>
//       <Sidebar />
//       {/* Other components */}
//     </DndProvider>
//   );
// };

export default LeftBarDesigner;
