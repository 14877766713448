import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { GlobalContext } from "../../contexts/GlobalContext";
import ProjectSelectorConfigForm from "../forms/ProjectSelectorConfigForm";
import { useParams } from "react-router-dom";
import DB from "../../database/DB";
import ProjectConfigBuilder from "./ProjectConfiguration/ProjectConfigBuilder";

const ProjectManager = () => {
  const { projectId } = useParams();
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const { globalState, setGlobalState } = useContext(GlobalContext);

  useEffect(() => {
    try {
      const getProjectsUnsubscribe = DB.project.getProjects(setProjects);
      return getProjectsUnsubscribe;
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (projectId && projects.length > 0) {
      const project = projects.find((project) => project.id === projectId);
      if (project) {
        setSelectedProject(project);
      }
    } else {
      setSelectedProject(null);
    }
  }, [projectId, projects]);

  return (
    <Container fluid="sm pt-3">
      <ProjectSelectorConfigForm
        currentProject={selectedProject}
        projects={projects}
      />
      {selectedProject && (
        <ProjectConfigBuilder projectId={selectedProject.id} />
      )}
    </Container>
  );
};

export default ProjectManager;
