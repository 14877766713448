/**
 * @fileoverview
 * @name CircularProgress.js
 * @description This file is used to render the circular progress bar component
 *   It accepts following parameters:
 *   - value: the value of the progress bar, default is 0
 *   - size: the size of the progress bar, default is 100px
 *   - color: the color of the progress bar, default is #000
 *   - thickness: the thickness of the progress bar, default is 10px
 *   - variant: the variant of the progress bar, default is round
 *   - text: the text to be displayed in the progress bar, default is ""
 *   - textVariant: the variant of the text, default is body1
 *   - textWeight: the weight of the text, default is normal
 *   - textAlign: the alignment of the text, default is center
 *   - textFontSize: the font size of the text, default is 1rem
 *   - textFontColor: the font color of the text, default is #000
 *   - textFontFamily: the font family of the text, default is inherit
 *   - textFontWeight: the font weight of the text, default is inherit
 *   - textFontStyle: the font style of the text, default is inherit
 *   - textFontDecoration: the font decoration of the text, default is inherit
 *   - textFontTransform: the font transform of the text, default is inherit
 *
 *   It will create a native circular progress bar component by using svg and rect
 */

import React from "react";
import PropTypes from "prop-types";

const defaultProgressColor = {
  0: "#66FF73",
  25: "#19FC7F",
  50: "#09D64D",
  75: "#23D404",
  100: "#05AB05",
};

const getColor = (value) => {
  if (value <= 25) {
    return defaultProgressColor["0"];
  } else if (value <= 50) {
    return defaultProgressColor["25"];
  } else if (value <= 75) {
    return defaultProgressColor["50"];
  } else if (value <= 100) {
    return defaultProgressColor["75"];
  } else {
    return defaultProgressColor["100"];
  }
};

const CircularProgress = (props) => {
  const {
    value = 0,
    size = "100px",
    color = getColor(value),
    thickness = 10,
    variant = "round",
    animatedBackground = false,
  } = props;

  const radius = 50 - thickness / 2;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (value / 100) * circumference;

  return (
    <div
      className="circular-progress d-flex justify-content-center align-items-center flex-column"
      style={{
        width: size,
        height: size,
      }}
    >
      <svg
        className="circular-progress__svg"
        viewBox="0 0 100 100"
        style={{
          width: size,
          height: size,
        }}
      >
        {/* Add a background light gray circle, which will be a full circle */}
        <circle
          className="circular-progress__circle"
          cx="50"
          cy="50"
          r={radius}
          fill="none"
          stroke="#e0e0e0"
          strokeWidth={thickness}
          strokeDasharray={circumference}
          strokeDashoffset={0}
          strokeLinecap={variant}
        />

        <circle
          className="circular-progress__circle"
          cx="50"
          cy="50"
          r={radius}
          fill="none"
          stroke={color}
          strokeWidth={thickness}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap={variant}
        />
      </svg>
    </div>
  );
};

export default CircularProgress;
