// src/components/PageComponent.js
import React, { useEffect } from "react";
import { Button, Spinner, Tab, Tabs, Row, Col, Nav } from "react-bootstrap";
import CustomComponent from "./CustomComponent";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { PageConfigurationModal } from "./PageConfiguration";
import CodeMirror from "@uiw/react-codemirror";
import { html } from "@codemirror/lang-html";
import { css } from "@codemirror/lang-css";
import { sublime } from "@uiw/codemirror-theme-sublime";
import {
  initElements,
  importStyle,
  importIconsFont,
} from "../importedScripts/pageInteraction";

const jsInjectedScript = `
  ${initElements}
`;

const getFullHTML = (
  html = "",
  meta = "",
  css = "",
  style = "vanilla",
  fontLib = "fontawesome"
) => {
  return `<!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    ${meta}
    <title>${meta.title}</title>
    ${style === "vanilla" ? "" : importStyle(style)}
    ${importIconsFont(fontLib)}
    <style>
    ${css}
    </style>
    <style>
    .iframe-selected {
      border: 1px dashed red;
    }
    </style>
  </head>
  <body>
    ${html}/
    <script>
      ${jsInjectedScript}
    </script>
  </body>
  </html>
  `;
};

const IframeRenderPreview = ({ content }) => {
  const ifFrameRef = React.useRef(null);

  useEffect(() => {
    const iframe = ifFrameRef.current;
    const iframeDocument = iframe.contentDocument;
    iframeDocument.open();
    iframeDocument.write(content);
    iframeDocument.close();
  }, [content]);

  return (
    <iframe
      ref={ifFrameRef}
      title="iframe"
      style={{ width: "100%", height: "100%" }}
    />
  );
};

// Will receive message from iframe
const IframeRender = ({ content }) => {
  const ifFrameRef = React.useRef(null);

  useEffect(() => {
    window.addEventListener("message", receiveMessage, false);
    return () => {
      window.removeEventListener("message", receiveMessage, false);
    };
  }, []);

  useEffect(() => {
    const iframe = ifFrameRef.current;
    const iframeDocument = iframe.contentDocument;
    iframeDocument.open();
    iframeDocument.write(content);
    iframeDocument.close();
  }, [content]);

  const receiveMessage = (event) => {
    console.log("event.data", event.data);
    // if (event.data.type === "link") {
    //   console.log("link", event.data.id);
    // }
    // if (event.data.type === "button") {
    //   console.log("button", event.data.id);
    // }
  };

  return (
    <iframe
      ref={ifFrameRef}
      title="iframe"
      style={{ width: "100%", height: "100%" }}
    />
  );
};

const files = [
  {
    name: "HTML",
    key: "content",
    codeEditorExtension: html,
  },
  {
    name: "CSS",
    key: "css",
    codeEditorExtension: css,
  },
  {
    name: "Meta",
    key: "meta",
    codeEditorExtension: html,
  },
];

const HtmlCodeEditor = ({ page, onSave }) => {
  const [contentCopy, setContentCopy] = React.useState(page.content);
  const [cssCopy, setCssCopy] = React.useState(page.css);
  const [metaCopy, setMetaCopy] = React.useState(page.meta);
  const [seePreview, setSeePreview] = React.useState(false);
  const { content, meta, css } = page;

  const iframeContent = getFullHTML(content, meta, css, page.style);

  const handleCodeChange = (key, value) => {
    if (key === "content") {
      setContentCopy(value);
    } else if (key === "css") {
      setCssCopy(value);
    } else if (key === "meta") {
      setMetaCopy(value);
    }
  };

  const handleOnSave = () => {
    onSave({
      content: contentCopy,
      css: cssCopy,
      meta: metaCopy,
    });
  };

  const rightFloatActions = () => {
    return (
      <div
        className="d-flex flex-row justify-content-end align-items-center p-3"
        style={{ position: "absolute", right: "0px", top: "0px", zIndex: 1 }}
      >
        <Button
          variant="primary"
          className="me-2"
          onClick={() => setSeePreview(!seePreview)}
        >
          <i className="bi bi-code-slash"></i>{" "}
          {!seePreview ? "Preview" : "Code"}
        </Button>
        <Button variant="primary" onClick={() => handleOnSave(contentCopy)}>
          <i className="bi bi-code-square"></i> Save
        </Button>
      </div>
    );
  };

  return (
    <div style={{ height: "100%", width: "100%", position: "relative" }}>
      {rightFloatActions()}
      {!seePreview && (
        <Tab.Container id="left-tabs-example" defaultActiveKey="pageHTML">
          <Row>
            <Col sm={12}>
              <Nav className="flex-row">
                {files.map((file, index) => {
                  return (
                    <Nav.Item key={index}>
                      <Nav.Link eventKey={file.key}>{file.name}</Nav.Link>
                    </Nav.Item>
                  );
                })}
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Tab.Content>
                {files.map((file, index) => {
                  return (
                    <Tab.Pane eventKey={file.key} key={index}>
                      <CodeMirror
                        value={page[file.key]}
                        style={{ height: "100%", width: "100%" }}
                        height="100%"
                        extensions={[file.codeEditorExtension()]}
                        theme={sublime}
                        onChange={(value) => handleCodeChange(file.key, value)}
                      />
                    </Tab.Pane>
                  );
                })}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      )}
      {seePreview && <IframeRenderPreview content={contentCopy} />}
    </div>
  );
};

const PageHeader = ({ page, actions }) => {
  const { id, name, isStatic, components, content } = page;

  return (
    <div className="page-header d-flex flex-row justify-content-start align-items-center">
      <p className="fw-bold">{name}</p>
      <div className="vertical-divider bg-white"></div>
      <div className="d-flex flex-row justify-content-start align-items-center">
        {actions.map((action, index) => {
          if (action.isDivider) {
            return (
              <div key={index} className="vertical-divider bg-white"></div>
            );
          }

          return (
            <Button
              key={index}
              variant="link"
              style={{ textDecoration: "none" }}
              size="sm"
              onClick={() => action.onClick(page)}
              disabled={action.isLoading}
            >
              <i className={action.icon}></i> {action.name}{" "}
              {action.isLoading && <Spinner animation="border" size="sm" />}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

const keysMapToPage = {
  pageHTML: "content",
  pageCSS: "css",
  pageMeta: "meta",
};

const PageComponent = ({ page, webApp, dataModels, actions, pages }) => {
  const [generatingCodeFor, setGeneratingCodeFor] = React.useState({});
  const [pageCopy, setPageCopy] = React.useState(page);
  const [pageConfigModalShow, setPageConfigModalShow] = React.useState(false);
  const [pageViewMode, setPageViewMode] = React.useState("result");
  const { id, name, isStatic, components, content, css, meta } = pageCopy;

  useEffect(() => {
    setPageCopy(page);
  }, [page]);

  const generateCode = async (key) => {
    setGeneratingCodeFor({ ...generatingCodeFor, [key]: true });
    const pageKey = keysMapToPage[key];
    const mappedPages = pages.map((page) => {
      return {
        name: page.name,
        description: page.description,
        url: page.url,
        accessTypeOfThePage: page.access || "public",
        frameworkToUse: page.style || "vanilla",
        goalOfThePage: page.goal || "landing",
        iconLibToUse: page.iconLib || "fontawesome",
      };
    });
    let currentStream = "";

    let forcedParameters = {
      pageName: pageCopy.name,
      currentPages: JSON.stringify(mappedPages),
      contentObject: JSON.stringify(page.contentObject),
    };

    console.log("forced parameters", forcedParameters);

    if (key === "pageCSS") {
      forcedParameters = {
        ...forcedParameters,
        pageHTML: pageCopy.content,
      };
    }

    const keyToStream = ["pageHTML", "pageCSS"];

    const options = {
      onDataStream: (data) => {
        currentStream += data;
        if (keyToStream.includes(key)) {
          setPageCopy({
            ...pageCopy,
            [pageKey]: currentStream,
          });
        }
        // console.log("data stream", data);
      },
      onDataStreamEnd: (data) => {},
      forcedValues: {
        parameters: forcedParameters,
      },
    };
    const result = await actions.runAiAndGet(key, null, options);

    setPageCopy({
      ...pageCopy,
      [pageKey]: result,
    });
    setGeneratingCodeFor({ ...generatingCodeFor, [key]: false });
  };

  const actionsHeader = [
    {
      name: "Save",
      icon: "bi bi-save",
      onClick: () => {
        actions.savePage(pageCopy);
      },
    },
    {
      name: pageViewMode === "code" ? "Cancel Code Edit" : "Edit Code",
      icon: "bi bi-code-square",
      onClick: () =>
        setPageViewMode(pageViewMode === "code" ? "result" : "code"),
    },
    {
      name: "Configure",
      icon: "bi bi-gear",
      onClick: () => setPageConfigModalShow(true),
    },
    { isDivider: true },
    {
      name: "Generate HTML",
      icon: "bi bi-filetype-html",
      isLoading: generatingCodeFor.pageHTML,
      onClick: () => {
        generateCode("pageHTML");
      },
    },
    {
      name: "Generate Meta",
      icon: "bi bi-file-earmark-code",
      isLoading: generatingCodeFor.pageMeta,
      onClick: () => {
        generateCode("pageMeta");
      },
    },
    {
      name: "Generate CSS",
      icon: "bi bi-filetype-css",
      isLoading: generatingCodeFor.pageCSS,
      onClick: () => {
        generateCode("pageCSS");
      },
    },
  ];

  const handlePageCopyChange = (page) => {
    setPageCopy({ ...pageCopy, ...page });
  };

  const iFrameContent = getFullHTML(content, meta, css, page.style);

  return (
    <div className="PageComponent">
      {/* Page configuration modal */}
      {pageConfigModalShow && (
        <PageConfigurationModal
          webApp={webApp}
          dataModels={dataModels}
          actions={actions}
          defaultValue={page}
          onHide={() => setPageConfigModalShow(false)}
        />
      )}
      {/* Very thin header which will contains page name and some actions */}
      <PageHeader actions={actionsHeader} page={page} />
      <div className="page-content">
        {iFrameContent && pageViewMode === "result" && (
          <IframeRender content={iFrameContent} />
        )}
        {content && pageViewMode === "code" && (
          <HtmlCodeEditor
            page={pageCopy}
            onSave={(pageContents) => {
              handlePageCopyChange({
                content: pageContents.content,
                css: pageContents.css,
                meta: pageContents.meta,
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default PageComponent;

// In the code snippet above, we have the `PageComponent` that receives its props for `id`, `name`, `isStatic`, and `components`. It renders the page name as a heading (which can be styled according to the theme guidelines) and iterates over the `components` array, rendering a `CustomComponent` for each item in the array.

// This file should be saved as `src/components/PageComponent.js` in the project directory, and the `CustomComponent` import should be adjusted if the file name or location is changed.
