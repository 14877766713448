import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ApiBuilderContext } from "../../../../contexts/ApiBuilderContext";
import { Button, Modal, Form } from "react-bootstrap";

export const NewDeploymentModelFormModal = ({ onHide }) => {
  const { id: apiProjectId, systemActions } = useContext(ApiBuilderContext);
  const [newDeploymentModel, setNewDeploymentModel] = useState({
    name: "",
    type: "api", // can be "api", "interface"
    isPersistent: false
  });
  const navigate = useNavigate();
  const { createDeploymentModel } = systemActions;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewDeploymentModel({ ...newDeploymentModel, [name]: value });
  };

  const createModel = async () => {
    console.log("newDeploymentModel", newDeploymentModel);
    const newModel = await createDeploymentModel(newDeploymentModel);
    onHide();
    navigate(`/system-designer/${apiProjectId}/launch-lite/${newModel.id}`);
  };

  return (
    <Modal show={true} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create New Deployment Model</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="name">
            <Form.Label>Model Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder="Enter model name"
              value={newDeploymentModel?.name}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="type">
            <Form.Label>Model Type</Form.Label>
            <Form.Control
              as="select"
              name="type"
              value={newDeploymentModel?.type}
              onChange={handleChange}
            >
              <option value="api">API</option>
              <option value="interface">Interface</option>
            </Form.Control>
            {newDeploymentModel?.type === "api" && (
              // if type is "api" show check box for isPersistent
              <Form.Check
                type="checkbox"
                label="Persistent"
                name="isPersistent"
                checked={newDeploymentModel?.isPersistent}
                onChange={(e) =>
                  setNewDeploymentModel({
                    ...newDeploymentModel,
                    isPersistent: e.target.checked
                  })
                }
              />
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={createModel}>
          Create Model
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
