import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { GlobalContext } from "./GlobalContext";
import DB from "../database/DB";
import { SystemBuilderCrud } from "./SystemBuilderCrud";
import { CreaxysSystemHelper } from "../libraries/CreaxysSdk/creaxys";

/**
 * ApiBuilderContext
 * @description
 * This context is used to provide the apiProject data to the ApiBuilder component.
 **/

export const ApiBuilderContext = createContext({});

// Get random id containing [a-z0-9] with length 20
const getId10 = () => {
  return Math.random().toString(36).substr(2, 20);
};

export const getRandomId = () => {
  return getId10() + getId10();
};

// PromptBlock
export const getDefaultNewPromptBlock = (props = {}) => {
  return {
    chainKey: null,
    aliasName: null,
    builtPromptId: null,
    blockType: "completion", // can be "completion", "logic"
    logicType: null, // can be "loopResult", "concatResult"
    valuesLogicLinkedImport: {},
    valuesLinkedImport: {},
    valuesLinkedExport: {},
    ...props
  };
};

export const getDefaultInputBlock = (props = {}) => {
  return {
    name: "New Input Block",
    type: "text", // text, number, date, time, datetime, select, multi-select, checkbox, radio, file, image, video, audio, location, email, phone, url, password, hidden, html, markdown, code, json, yaml, xml, csv, excel, pdf, word, powerpoint, archive, other
    required: false,
    defaultValue: "",
    enum: [],
    ...props
  };
};

export const getDefaultDataObject = (props = {}) => {
  return {
    name: "New Data Object",
    properties: [],
    ...props
  };
};

export const getDefaultDeploymentModel = (props = {}) => {
  return {
    name: "New Deployment Model",
    type: "api", // api, interface
    isPersistent: false,
    ...props
  };
};

export const getDefaultEmitter = (props = {}) => {
  return {
    action: "fetch", // fetch, chainAction, systemAction, systemsAction, logic
    name: "New Emitter",
    webhookConfig: {},
    fetcherConfig: {},
    chainActionConfig: {},
    systemActionConfig: {},
    systemsActionConfig: {},
    logicConfig: {},
    listeners: [],
    ...props
  };
};

export const getDefaultInputConnection = (props = {}) => {
  return {
    mode: "fetcher",
    apiConnectorId: "",
    name: "New Input Connection",
    webhookConfig: {},
    fetcherConfig: {},
    ...props
  };
};

const returnAccess = (apiProject, uid) => {
  if (apiProject.uid === uid) {
    return {
      read: true,
      write: true,
      useOnly: false
    };
  }

  const {
    sharedUidsRead = [],
    sharedUidsWrite = [],
    sharedUidsUseOnly = []
  } = apiProject;

  const read = sharedUidsRead.includes(uid);
  const write = sharedUidsWrite.includes(uid);
  const useOnly = sharedUidsUseOnly.includes(uid);

  return {
    read,
    write,
    useOnly
  };
};

export const ApiBuilderProvider = ({ apiProjectId, children }) => {
  const [apiProject, setApiProject] = useState(null);
  const { uid } = useContext(GlobalContext);
  const [chains, setChains] = useState([]);
  const [inputApiConnectors, setInputApiConnectors] = useState([]);
  const [deploymentModels, setDeploymentModels] = useState([]);
  const [dslScripts, setDslScripts] = useState([]);
  const [builtPrompts, setBuiltPrompts] = useState([]);
  const [systemOverview, setSystemOverview] = useState({});
  // const [id, setId] = useState("");
  // const [creatorId, setCreatorId] = useState("");
  // const [isCreator, setIsCreator] = useState(null);
  const [access, setAccess] = useState({
    read: false,
    write: false,
    useOnly: false
  });

  useEffect(() => {
    if (!apiProject) {
      setChains([]);
      setInputApiConnectors([]);
      setDslScripts([]);
      return;
    }
  }, [apiProject]);

  useEffect(() => {
    if (apiProject) {
      const isCreator = apiProject.uid === uid;
      const unsubscribe = DB.apiProjects.sub.builtPrompts.list(
        apiProject.id,
        (builtPrompts) => {
          setBuiltPrompts(builtPrompts);
        },
        null,
        null,
        null,
        {
          isFromShared: isCreator ? false : true
        }
      );
      return unsubscribe;
    }
  }, [apiProject]);

  useEffect(() => {
    if (apiProject) {
      const isCreator = apiProject.uid === uid;
      const unsubscribe = DB.apiProjects.sub.deploymentModels.list(
        apiProject.id,
        (deploymentModels) => {
          setDeploymentModels(deploymentModels);
        },
        null,
        null,
        null,
        {
          isFromShared: isCreator ? false : true
        }
      );
      return unsubscribe;
    }
  }, [apiProject]);

  useEffect(() => {
    if (apiProject) {
      const isCreator = apiProject.uid === uid;
      const unsubscribe = DB.apiProjects.sub.chains.list(
        apiProject.id,
        (chains) => {
          setChains(chains);
        },
        null,
        null,
        null,
        {
          isFromShared: isCreator ? false : true
        }
      );
      return unsubscribe;
    }
  }, [apiProject]);

  useEffect(() => {
    if (apiProject) {
      const isCreator = apiProject.uid === uid;
      const unsubscribe = DB.apiProjects.sub.inputApiConnectors.list(
        apiProject.id,
        (inputApiConnectors) => {
          setInputApiConnectors(inputApiConnectors);
        },
        null,
        null,
        null,
        {
          isFromShared: isCreator ? false : true
        }
      );
      return unsubscribe;
    }
  }, [apiProject]);

  useEffect(() => {
    if (apiProject) {
      const isCreator = apiProject.uid === uid;
      const unsubscribe = DB.apiProjects.sub.dslScripts.list(
        apiProject.id,
        (dslScripts) => {
          setDslScripts(dslScripts);
        },
        null,
        null,
        null,
        {
          isFromShared: isCreator ? false : true
        }
      );
      return unsubscribe;
    }
  }, [apiProject]);

  useEffect(() => {
    if (!apiProjectId || !uid) {
      setApiProject(null);
      return;
    }
    // Fetch apiProjects document from Firestore
    const unsubscribe = DB.apiProjects.get(apiProjectId, (apiProject) => {
      setApiProject(apiProject);
      console.log("setApiProject", apiProject);
      const access = returnAccess(apiProject, uid);
      setAccess(access);
    });
    loadSystemOverview();
    return unsubscribe;
  }, [apiProjectId, uid]);

  const isCreator = apiProject && uid ? apiProject.uid === uid : null;

  const systemActions = {
    createChain: async (chain) => {
      return await SystemBuilderCrud.chain.create(apiProject, uid, {
        name: `Chain ${chains.length + 1}`,
        ...chain
      });
    },
    createDeploymentModel: async (deploymentModel) => {
      return await SystemBuilderCrud.deploymentModels.create(apiProject, uid, {
        name: `Deployment Model ${deploymentModels.length + 1}`,
        ...deploymentModel
      });
    }
  };

  const loadSystemOverview = async () => {
    const systemOverview = await CreaxysSystemHelper.loadSystemOverview(
      apiProjectId
    );
    setSystemOverview(systemOverview);
  };

  if (!apiProject) {
    return null;
  }

  const value = {
    // setChains,
    dslScripts,
    inputApiConnectors,
    deploymentModels,
    access,
    chains: chains || [],
    apiProject,
    id: apiProject ? apiProject.id : null,
    creatorUid: apiProject ? apiProject.uid : null,
    isCreator,
    systemActions,
    loadSystemOverview,
    systemOverview
  };

  // console.log("apiProject", apiProject, apiProjectId);

  return (
    <ApiBuilderContext.Provider value={value}>
      {children}
    </ApiBuilderContext.Provider>
  );
};

export const useApiBuilder = () => useContext(ApiBuilderContext);
