/**
 * @fileoverview DataModelCreation.js
 * @name DataModelCreation.js
 * @description DataModelCreation.js
 *  Data will allow the user to create database of documents for his webapp project, data can be of type unique/multi and have field acccess which can be sessionBased/public.
 */

import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Modal,
  Form,
  Table,
  Button,
  Accordion,
  Spinner,
} from "react-bootstrap";
import DB from "../db/DB";

const typeConverter = (type) => {
  const STRING_TYPES = ["String", "Text"];
  const NUMBER_TYPES = ["Number", "Integer", "Float"];
  const BOOLEAN_TYPES = ["Boolean"];
  const DATE_TYPES = ["DateTime"];
  const OBJECT_TYPES = ["Object", "ObjectID"];
  const ARRAY_TYPES = ["Array", "List"];

  if (STRING_TYPES.includes(type)) {
    return "String";
  } else if (NUMBER_TYPES.includes(type)) {
    return "Number";
  } else if (BOOLEAN_TYPES.includes(type)) {
    return "Boolean";
  } else if (DATE_TYPES.includes(type)) {
    return "DateTime";
  } else if (OBJECT_TYPES.includes(type)) {
    return "Object";
  } else if (ARRAY_TYPES.includes(type)) {
    return "Array";
  } else {
    return null;
  }
};

// will update dataModel.fields by giving a list of {name, type, access}
const DataModelFields = ({ fields: fieldsValue = [], onChange }) => {
  const [fields, setFields] = useState(fieldsValue);

  useEffect(() => {
    setFields(fieldsValue);
  }, [fieldsValue]);

  const handleOnChange = (event) => {
    const newFields = [...fields];
    newFields[event.target.dataset.index][event.target.name] =
      event.target.value;
    setFields(newFields);
    onChange({ target: { name: "fields", value: newFields } });
  };

  const handleAddProperty = () => {
    setFields([...fields, { name: "", type: "", access: "" }]);
  };

  const handleRemoveProperty = (index) => {
    const newFields = [...fields];
    newFields.splice(index, 1);
    setFields(newFields);
    onChange({ target: { name: "fields", value: newFields } });
  };

  return (
    <>
      <Row>
        <Col sm={12}>
          <h5>Fields</h5>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                {/* <th>Access</th> */}
                <th>Unique</th>
                <th>Primary Key</th>
                <th>Foreign Key</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {fields.map((field, index) => (
                <tr key={index}>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="Enter field name"
                      name="name"
                      data-index={index}
                      value={field.name || ""}
                      onChange={handleOnChange}
                    />
                  </td>
                  {/* Type of the field */}
                  <td>
                    <Form.Control
                      as="select"
                      name="type"
                      data-index={index}
                      value={typeConverter(field.type) || ""}
                      onChange={handleOnChange}
                    >
                      <option value="">Select Type</option>
                      <option value="String">String (Text)</option>
                      <option value="Number">Number</option>
                      <option value="Boolean">Boolean(True/False)</option>
                      <option value="DateTime">Date</option>
                      <option value="Object">Object(With sub fields)</option>
                      <option value="Array">Array(List of values)</option>
                    </Form.Control>
                  </td>
                  {/* Wether the field is unique or not */}
                  <td>
                    <Form.Control
                      as="select"
                      name="type"
                      data-index={index}
                      value={field.unique || ""}
                      onChange={handleOnChange}
                    >
                      <option value="unique">Unique</option>
                      <option value="">No</option>
                    </Form.Control>
                  </td>
                  {/* <td>
                    <Form.Control
                      type="text"
                      placeholder="Enter field access"
                      name="access"
                      data-index={index}
                      value={field.access}
                      onChange={handleOnChange}
                    />
                  </td> */}
                  {/* is primary key */}
                  <td>
                    <Form.Check
                      type="checkbox"
                      name="isPrimaryKey"
                      data-index={index}
                      checked={field.primary_key || false}
                      onChange={handleOnChange}
                    />
                  </td>
                  {/* is foreign key */}
                  <td>
                    <Form.Check
                      type="checkbox"
                      name="isForeignKey"
                      data-index={index}
                      checked={field.foreign_key || false}
                      onChange={handleOnChange}
                    />
                  </td>
                  <td>
                    <a
                      href="#"
                      onClick={() => handleRemoveProperty(index)}
                      className="text-danger"
                    >
                      Remove
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Button variant="link" onClick={handleAddProperty}>
            Add Property
          </Button>
        </Col>
      </Row>
    </>
  );
};

const SuggestedModels = ({ suggestedModels = [], onSelect }) => {
  return (
    <Accordion className="mb-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Click to see suggested models</Accordion.Header>
        {/* <h5>Suggested Models</h5> */}
        <Accordion.Body className="p-0">
          <Table striped bordered className="border-0 mb-0">
            <thead>
              <tr>
                <th>Name</th>
                <th>Fields</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {suggestedModels.map((model, index) => {
                const fieldsNames = model.fields.map((field) => field.name);
                return (
                  <tr key={index}>
                    <td>{model.name}</td>
                    <td>{fieldsNames.join(", ")}</td>
                    <td>
                      <Button variant="link" onClick={() => onSelect(model)}>
                        Select
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export const DataModelModal = ({
  show,
  onHide,
  actions,
  defaultValue,
  webApp,
  dataModelsNames,
}) => {
  const [dataModel, setDataModel] = useState(defaultValue);
  const [isSaving, setIsSaving] = useState(false);
  const { aiRenderedData = {} } = webApp;
  const isNew = !dataModel.id;

  const { dataModels } = aiRenderedData;

  const suggestedModels = dataModels ? JSON.parse(dataModels) : [];

  console.log("suggestedModels", suggestedModels);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSaving(true);

    if (isNew) {
      await actions.addDataModel(dataModel);
    } else {
      await actions.updateDataModel(dataModel);
    }

    setIsSaving(false);
    onHide();
  };

  const handleOnChange = (event) => {
    setDataModel({
      ...dataModel,
      [event.target.name]: event.target.value,
    });
  };

  const suggestedModelsFiltered = suggestedModels.filter(
    (model) => !dataModelsNames.includes(model.name)
  );

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {isNew ? "Add" : "Edit"} DataModel
          {isSaving && (
            <Spinner
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="ml-2"
            />
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* If there are suggested models, then show the suggested models and allow to select one, which will set the dataModel with the selected sugested data model */}
        {isNew && suggestedModelsFiltered.length > 0 && (
          <SuggestedModels
            suggestedModels={suggestedModelsFiltered}
            onSelect={(model) => setDataModel(model)}
          />
        )}
        <form onSubmit={handleSubmit}>
          <Row>
            <Form.Group as={Col} controlId="name" sm={12}>
              <Form.Label>DataModel Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter DataModel name"
                name="name"
                value={dataModel.name || ""}
                onChange={handleOnChange}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="description" sm={12}>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                className="ta-sm"
                placeholder="Enter DataModel description"
                name="description"
                value={dataModel.description || ""}
                onChange={handleOnChange}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="type" sm={12}>
              <Form.Label>DataModel Type</Form.Label>
              <Form.Control
                as="select"
                name="type"
                value={dataModel.type || ""}
                onChange={handleOnChange}
              >
                <option value="">Select DataModel Type</option>
                <option value="single">Single Record</option>
                <option value="multi">Multiple Records</option>
              </Form.Control>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="access" sm={12}>
              <Form.Label>DataModel Access</Form.Label>
              <Form.Control
                as="select"
                name="access"
                value={dataModel.access || ""}
                onChange={handleOnChange}
              >
                <option value="">Select DataModel Access</option>
                <option value="sessionBased">Session Based</option>
                <option value="public">Public</option>
              </Form.Control>
            </Form.Group>
          </Row>
          <DataModelFields
            fields={dataModel.fields}
            onChange={handleOnChange}
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleSubmit}
          disabled={isSaving}
        >
          {isNew && (isSaving ? "Creating..." : "Create DataModel")}
          {!isNew && (isSaving ? "Updating..." : "Update DataModel")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
