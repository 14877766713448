//2. Create the GlobalContext in a new file called GlobalContext.js
import React, { useEffect, useState } from "react";
// import { useLocalStorage } from "../hooks/useLocalStorage";
import DB from "../database/DB";

export const GlobalContext = React.createContext();

export const GlobalProvider = ({ children }) => {
  // const [globalState, setGlobalState] = useState({
  //   uid: null,
  //   userAccount: null,
  // });
  const [uid, setUid] = useState(null);
  const [userAccount, setUserAccount] = useState(null);
  const [firebaseUser, setFirebaseUser] = useState(null);
  const [firebaseUserLoading, setFirebaseUserLoading] = useState(true);

  useEffect(() => {
    if (!uid) {
      setUserAccount(null);
      return;
    }
    const unsubscribe = DB.userAccounts.getByUid((userAccount) => {
      setUserAccount(userAccount);
    });
    return unsubscribe;
  }, [uid]);

  const update = (field, value) => {
    if (field === "uid") {
      setUid(value);
    }
    if (field === "userAccount") {
      setUserAccount(value);
    }
  };

  const value = {
    // globalState,
    // setGlobalState,
    uid,
    userAccount,
    update,
    firebaseUser,
    setFirebaseUser: (user) => {
      setFirebaseUser(user);
      setFirebaseUserLoading(false);
      setUid(user.uid);
    },
    firebaseUserLoading,
    setFirebaseUserLoading
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

// quick doc

/**
 * const { uid, userAccount, update } = useContext(GlobalContext);
 */
