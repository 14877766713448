import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { Form, Button, Card, Overlay, Row, Col } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CodeEditor from "./CodeEditor";
import GptConfigForm from "../forms/GptConfig";
import { GlobalContext, getNewModelPrompt } from "../../contexts/GlobalContext";
import PromptsSelectorDropdown from "./GptCodeEditor/PromptsSelectorDropdown";
import { getOpenAICompletion } from "../../apis/openAiAPI";
import DB from "../../database/DB";

const WritingParameters = ({ values, handlers, files }) => {
  const { writingLocation, writingMode, instructionsMode } = values;
  const { setWritingLocation, setWritingMode, setInstructionsMode } = handlers;

  return (
    <Card className="writing-parameters">
      <Card.Body>
        <Row className="code-writing-mode">
          <Form.Group as={Col}>
            <Form.Label>Instructions mode</Form.Label>
            <Form.Control
              as="select"
              value={instructionsMode}
              onChange={(e) => setInstructionsMode(e.target.value)}
            >
              <option value="complete">Complete code</option>
              <option value="insert">Insert code</option>
              <option value="edit">Edit code</option>
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Writing mode</Form.Label>
            <Form.Control
              as="select"
              value={writingMode}
              onChange={(e) => setWritingMode(e.target.value)}
            >
              <option value="append">By Appending Code To</option>
              <option value="replace">By Replacing Code Of</option>
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Writing location</Form.Label>
            <Form.Control
              value={writingLocation}
              as="select"
              onChange={(e) => setWritingLocation(e.target.value)}
            >
              <option value="currentFile">Current file</option>
              <option value="newFiles">New File</option>
              {files.map((file) => (
                <option key={file.id} value={file.id}>
                  {file.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Row>
      </Card.Body>
    </Card>
  );
};

const GptCodeEditor = ({ file: fileData, handleFileChange, project }) => {
  const [file, setFile] = useState({});
  const [params, setParams] = useState({});
  const [showCopied, setShowCopied] = React.useState(false);
  const buttonCopy = useRef(null);
  const textArea = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [instructionsMode, setInstructionsMode] = useState("complete");
  const [writingLocation, setWritingLocation] = useState("currentFile");
  const [writingMode, setWritingMode] = useState("append");
  const { globalState, getNewFile } = useContext(GlobalContext);
  // const [generatingStatus, setGeneratingStatus] = useState("new");
  // const [generatingText, setGeneratingText] = useState("");
  // const { project, modelPrompts } = globalState;
  // const { files } = project;

  useState(() => {
    const unsub = DB.projects.sub.files.get(project.id, fileData.id, (file) => {
      setFile(file);
    });
    return unsub;
  }, []);

  const handleInstructionsChange = (e) => {
    file.gptInstructions = e.target.value;
    handleFileChange(file);
  };

  const handleCodeTextChange = (value) => {
    file.generatedCode = value;
    handleFileChange(file);
  };

  const handleSubmit = async (e) => {
    try {
      if (e) e.preventDefault();
      let fileToModify = file;
      if (writingLocation === "newFile") {
        // fileToModify = getNewFile(`File ${files.length + 2}`);
      } else if (writingLocation !== "currentFile") {
        // fileToModify = files.find((f) => f.id === writingLocation);
      }

      let prompt = "";
      if (writingMode === "append") {
        if (writingLocation === "currentFile") {
          prompt += `/* ${file.gptInstructions} */ \n` + file.generatedCode;
        } else {
          prompt +=
            file.generatedCode +
            `\n/* ${file.gptInstructions} */ \n` +
            fileToModify.generatedCode;
        }
      } else {
        prompt += file.generatedCode;
        prompt += `/* ${file.gptInstructions} */`;
      }

      setIsLoading(true);
      const apiResult = await getOpenAICompletion({ prompt, ...params });
      const generatedText = apiResult.data.choices[0].text;

      if (writingMode === "replace") {
        fileToModify.generatedCode = generatedText.trim();
      } else if (writingMode === "append") {
        fileToModify.generatedCode = (
          fileToModify.generatedCode + generatedText
        ).trim();
      }

      handleFileChange(fileToModify, "isFromCreating");
      setIsLoading(false);
      textArea.current.scrollTop = textArea.current.scrollHeight;
    } catch (err) {
      setIsLoading(false);
      textArea.current.scrollTop = textArea.current.scrollHeight;
      console.log(err);
    }
  };

  const onPromptChange = (prompt) => {
    file.gptInstructions = prompt.prompt;
    handleFileChange(file);
  };

  const cleanCodeText = (e) => {
    if (e) e.preventDefault();
    file.generatedCode = "";
    handleFileChange(file);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.metaKey) {
      handleSubmit();
    }
  };

  const toggleCopied = () => {
    setShowCopied(true);
    setTimeout(() => {
      setShowCopied(false);
    }, 2000);
  };

  const onOpenAIParamsChange = (params) => {
    setParams(params);
  };

  /**
   * Add setWritingLocation, setWritingMode and setInstructionsMode
   */

  return (
    <div className="GptCodeEditor">
      <Form onSubmit={handleSubmit}>
        <div className="code-editor-zone">
          <div className="actions-code container-left">
            {/* <Button variant="link" onClick={pushCodeToInstructions}>
              Push code to instruction
            </Button> */}

            <CopyToClipboard text={file.generatedCode} onCopy={toggleCopied}>
              <Button variant="link" ref={buttonCopy}>
                Copy code
              </Button>
            </CopyToClipboard>
            <Overlay
              id="copied-popover"
              placement="bottom"
              show={showCopied}
              body={true}
              target={buttonCopy.current}
              onHide={() => setShowCopied(false)}
            >
              <div className="overlay-copied">Copied to clipboard!</div>
            </Overlay>
            <Button variant="link" onClick={cleanCodeText} type="submit">
              Clean code
            </Button>
          </div>
          <div className="syntax-highlighter-block">
            <CodeEditor
              onTextChange={handleCodeTextChange}
              value={file.generatedCode}
              language="javascript"
            />
          </div>
          <WritingParameters
            files={[]}
            values={{ writingLocation, writingMode, instructionsMode }}
            handlers={{
              setWritingLocation,
              setWritingMode,
              setInstructionsMode,
            }}
          />
        </div>

        <div className=" form-text-instruction">
          <div className="container-right-column form-actions">
            <PromptsSelectorDropdown
              file={file}
              instructionsMode={instructionsMode}
              onPromptChange={onPromptChange}
            />
            <Button onClick={handleSubmit} disabled={isLoading} type="submit">
              {!isLoading ? "Send to OpenAI" : "Loading..."}
            </Button>
            {/* <Badge bg="secondary">
              Text size: {file.gptInstructions.length}
            </Badge> */}
          </div>
          <Form.Group>
            {/* <Form.Label></Form.Label> */}
            <Form.Control
              onKeyDown={handleKeyDown}
              ref={textArea}
              as="textarea"
              rows="3"
              className="form-text-instruction-ta"
              value={file.gptInstructions}
              onChange={handleInstructionsChange}
            />
          </Form.Group>
        </div>

        {/* Small accordion with a class opened, which set height */}
        {/* <div className={`openai-params-parent ${isOpen ? 'opened' : ''}`}>
          <GptConfigForm onParamsChange={onOpenAIParamsChange} />
        </div> */}
      </Form>
    </div>
  );
};

export default GptCodeEditor;
