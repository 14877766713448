import React from "react";
import { Dropdown } from "react-bootstrap";

/**
 * @typedef {Object} Item
 * @property {string} name
 * @property {string} imageUrl
 * @property {string} title
 * @property {string} description
 */

/**
 * @typedef {Object} Props
 * @property {Item[]} items
 * @property {(item: Item) => boolean} isSelected
 * @property {(item: Item) => void} onSelect
 * @property {Object[]} actions
 * @property {string} actions[].icon
 * @property {string} actions[].label
 * @property {() => void} actions[].onClick
 * @property {string} className
 * @property {string} selectorPlaceholder
 */

const SimpleSelector = ({
  items,
  onSelect,
  actions = [],
  isSelected,
  className,
  selectorPlaceholder,
}) => {
  const activeItem = items.find((item) => isSelected(item));

  return (
    <Dropdown
      className={`${className}`}
      onSelect={(key) => {
        if (key.includes("action")) {
          const index = parseInt(key.split("-")[1]);
          actions[index].onClick();
        } else {
          const index = parseInt(key);
          onSelect(items[index]);
        }
      }}
    >
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        {activeItem && activeItem.name}
        {!activeItem && selectorPlaceholder}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {actions.map((action, i) => {
          const { icon, onClick, label } = action;
          return (
            <Dropdown.Item key={i} onClick={onClick} eventKey={`action-${i}`}>
              <i className={`fas fa-${icon}`} /> <span>{label}</span>
            </Dropdown.Item>
          );
        })}
        {items.map((item, index) => (
          <Dropdown.Item key={index} eventKey={index} active={isSelected(item)}>
            {item.imageUrl && <img src={item.imageUrl} alt={item.name} />}
            <strong>{item.title || item.name}</strong>
            {item.description && (
              <div className="text-truncate text-gray-600 fs-6">
                {item.description}
              </div>
            )}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SimpleSelector;
