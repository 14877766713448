/**
 * @fileoverview This file defines the AiEngineErrors React component.
 * This component is used to display the errors that can occur when using our internal AI engine.
 *
 * userEngineFetchError(): [fetchLastError: FetchError, cleanError]
 *
 * FetchError: {attempts: number, isRetrying, boolean, type : tooManyRequests | internalServerError | maxRetriesExceeded}
 *
 */

import React, { useEffect } from "react";
import { useEngineFetchError } from "../../libraries/ApiProjectSdk";
// Link
import { Link } from "react-router-dom";
import {
  Modal,
  Button,
  Alert,
  Container,
  Row,
  Col,
  Spinner,
  Card,
  Accordion,
  ListGroup,
} from "react-bootstrap";

const CustomButton = ({ children, eventKey, ...props }) => {
  return <Button {...props}>{children}</Button>;
};

// 2. Extension Wordings:

//    a. Alert Heading: "Attention: Request Limit Reached"
//    b. Alert Message: "To ensure smooth performance, consider adding your personal API key to your account settings."
//    c. Alert Heading: "Server Error: Temporary Downtime"
//    d. Alert Message: "We apologize for the inconvenience. Our team is working to resolve the issue. In the meantime, you can view the error details below."
//    e. Alert Heading: "Hold On: Maximum Retries Exceeded"
//    f. Alert Message: "To prevent disruption, please add your own API key in your account settings and try again later."

// 3. Additional Suggestions:

//    a. Add a "Learn More" link to guide users on how to add their own API key.
//    b. Offer a "Contact Support" button for users experiencing issues that require assistance.
//    c. Use friendly and informative language to keep users engaged and informed during unexpected errors or downtimes.
//    d. Consider providing tooltips or help icons to explain specific terms or error messages for user clarity.

const AiEngineErrors = ({ containerRef = null }) => {
  const [fetchLastError, cleanError] = useEngineFetchError();

  if (fetchLastError === null) {
    return null;
  }

  const shouldHide = (() => {
    if (fetchLastError.type === "tooManyRequests") {
      return fetchLastError.attempts > 1;
    } else {
      return false;
    }
  })();

  if (shouldHide) {
    return null;
  }

  const errorBody = fetchLastError.errorBody || null;

  console.log("errorBody", errorBody);

  const errorBodyText = (() => {
    if (errorBody === null) {
      return "";
    }

    if (typeof errorBody === "string") {
      return <p className="text-danger">errorBody</p>;
    }

    console.log("errorBody in AiEngineErrors", errorBody);

    if (typeof errorBody === "object") {
      const { error: errorText, message } = errorBody;

      if (errorText && message) {
        return (
          <ListGroup>
            <ListGroup.Item>{errorText}</ListGroup.Item>
            <ListGroup.Item>{message}</ListGroup.Item>
          </ListGroup>
        );
      } else if (errorText) {
        return errorText;
      } else if (message) {
        return message;
      } else {
        return <pre>{JSON.stringify(errorBody, null, 2)}</pre>;
      }
    }

    return "";
  })();

  console.log("errorBodyText", errorBodyText);

  const alertVariant = (() => {
    if (fetchLastError.type === "tooManyRequests") {
      return "warning";
    } else if (fetchLastError.type === "internalServerError") {
      return "danger";
    } else if (fetchLastError.type === "maxRetriesExceeded") {
      return "warning";
    } else {
      return "danger";
    }
  })();

  const getTexts = () => {
    if (fetchLastError.type === "tooManyRequests") {
      return {
        alertHeading: "Attention: Request Limit Reached",
        alertMessage:
          "To ensure smooth performance, consider adding your personal API key to your account settings.",
      };
    } else if (fetchLastError.type === "internalServerError") {
      return {
        alertHeading: "Server Error: Temporary Downtime",
        alertMessage:
          "We apologize for the inconvenience. Our team is working to resolve the issue. In the meantime, you can view the error details below.",
      };
    } else if (fetchLastError.type === "maxRetriesExceeded") {
      return {
        alertHeading: "Hold On: Maximum Retries Exceeded",
        alertMessage:
          "To prevent disruption, please add your own API key in your account settings and try again later.",
      };
    } else {
      return {
        alertHeading: "Hold On: Maximum Retries Exceeded",
        alertMessage:
          "To prevent disruption, please add your own API key in your account settings and try again later.",
      };
    }
  };

  const { alertHeading, alertMessage } = getTexts();

  return (
    <Modal
      container={containerRef?.current}
      show={fetchLastError !== null}
      onHide={cleanError}
      centered
      size="lg"
      backdrop="static"
      keyboard={false}
      dialogAs={Alert}
      variant={alertVariant}
      className="d-flex align-items-center justify-content-center"
    >
      {/* <Alert variant={alertVariant}> */}
      <Modal.Header closeButton>
        <Modal.Title>
          {alertHeading}
          {/* <Alert.Heading>{alertHeading}</Alert.Heading> */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="fs-5">{alertMessage}</p>
        {fetchLastError.type === "internalServerError" && (
          <Accordion defaultActiveKey="0">
            <Card>
              <Card.Header>
                <CustomButton variant="link" eventKey="0">
                  Details &rarr;
                </CustomButton>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <pre>{errorBodyText}</pre>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={cleanError}>
          Close
        </Button>
      </Modal.Footer>
      {/* </Alert> */}

      {/* <Modal.Body variant={alertVariant}>
        {fetchLastError.type === "tooManyRequests" && (
          <>
            <p>
              To ensure smooth performance, consider adding your personal API
              key to your account settings.
            </p>
          </>
        )}
        {fetchLastError.type === "internalServerError" && (
          <>
            <Alert.Heading>Server Error: Temporary Downtime</Alert.Heading>
            <p>
              We apologize for the inconvenience. Our team is working to resolve
              the issue. In the meantime, you can view the error details below.
            </p>
            errorBodyText

            <Accordion defaultActiveKey="0">
              <Card>
                <Card.Header>
                  <CustomButton variant="link" eventKey="0">
                    View Error Details
                  </CustomButton>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <pre>{errorBodyText}</pre>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </>
        )}
        {fetchLastError.type === "maxRetriesExceeded" && (
          <>
            <Alert.Heading>Hold On: Maximum Retries Exceeded</Alert.Heading>
            <p>
              To prevent disruption, please add your own API key in your account
              settings and try again later.
            </p>
          </>
        )}
      </Modal.Body> */}
    </Modal>
  );
};

export default AiEngineErrors;
