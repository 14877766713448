import axios from "axios";
import { firebaseAuth } from "../../api-connector/firebase";

const r = {
  systemDataOverview: `${process.env.REACT_APP_API_URL}/system-overview`
};

const getFirebaseToken = async () => {
  const token = await firebaseAuth.currentUser.getIdToken();
  return token;
};

const getHeaders = async () => {
  const token = await getFirebaseToken();
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
};

export const getOverview = async (systemId) => {
  try {
    const headers = await getHeaders();
    const result = await axios.get(
      r.systemDataOverview + "/" + systemId,
      headers
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const RequestCreaxysSDK = {
  getOverview
};

export default RequestCreaxysSDK;
