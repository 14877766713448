import React, { createContext, useContext, useEffect, useState } from "react";
import { GlobalContext } from "./GlobalContext";
import DB from "../database/DB";
import { ApiBuilderContext } from "./ApiBuilderContext";
import { SystemBuilderCrud } from "./SystemBuilderCrud";

// quick doc
/**
 * const { apiProject, chains, inputApiConnectors, dslScripts, builtPrompts, id, creatorUid, isCreator } = useContext(ApiBuilderContext);
 */
/**
 * const { chain, emitters, inputConnections, promptBlocks, inputBlocks, dataObjects, inputs, outputs, chainActions } = useContext(ChainContext);
 */

export const API_COMPONENT_DEFAULTS = {
  endpoint: {
    name: "New API endpoint",
    method: "GET",
    url: "",
    headers: [],
    body: "",
    query: "",
    response: "",
    responseHeaders: [],
    responseType: "json",
    responseHeadersType: "json"
  },
  route: {
    name: "New API route",
    url: ""
  },
  middleware: {
    name: "New API middleware",
    type: "middleware",
    middlewareType: "",
    middleware: "",
    middlewareOptions: {},
    middlewareOptionsType: "json"
  }
};

export const DeploymentModelContext = createContext();

export const DeploymentModelProvider = ({ deployId, children }) => {
  const { uid } = useContext(GlobalContext);
  const { id: apiProjectId, apiProject } = useContext(ApiBuilderContext);
  const [deploymentModel, setDeploymentModel] = useState(null);

  console.log("apiProjectId", apiProjectId, deployId);

  useEffect(() => {
    if (deployId && apiProjectId) {
      const unsubscribe = DB.apiProjects.sub.deploymentModels.get(
        apiProjectId,
        deployId,
        (deploymentModel) => {
          setDeploymentModel(deploymentModel);
        }
      );
      return unsubscribe;
    } else {
      setDeploymentModel(null);
    }
  }, [apiProjectId, deployId]);

  const value = {
    deploymentModel,
    actions: {
      update: async (newDeployment) => {
        return await SystemBuilderCrud.deploymentModels.update(
          apiProject,
          deploymentModel,
          uid,
          newDeployment
        );
      }
    }
  };

  return (
    <DeploymentModelContext.Provider value={value}>
      {children}
    </DeploymentModelContext.Provider>
  );
};
