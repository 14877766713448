import React, { useContext } from "react";
import { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Dropdown,
  Form,
  Row,
  Col,
  Card,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import DB from "../../../database/DB";
import ReactFlow, {
  Controls,
  Background,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  Handle,
  Position,
  MiniMap,
  ReactFlowProvider,
  useOnViewportChange,
  useViewport,
  useReactFlow,
  Panel
} from "reactflow";
import {
  PanelTopFlowSelector,
  ContextMenuPane,
  GlobalChainParameters,
  PanelLeft
} from "./SystemFlowCreator";
import deepEqual from "deep-equal";
// import jsonpath
import * as jsonpath from "jsonpath";
import { InputApiConnectorModal } from "./InputApiConnectors";
import InputTypeForm from "../../UI/InputForms";
import SystemBuilderUtils from "./utils";
import { ApiBuilderContext } from "../../../contexts/ApiBuilderContext";
import { ChainContext } from "../../../contexts/ChainContext";
import { GlobalContext } from "../../../contexts/GlobalContext";
import flowDbUtils from "./flowDbUtils";
import DropdownGroupedSelect from "../../UI/DropdownGroupedSelect";
import BlockType from "./utils/BlockType";

/**
 * @object WebhookConfig
 * @description
 * @property {string} url // set by system
 * @property {string} method // set by user (GET, POST, PUT, DELETE, etc.)
 * @property {object} headers // set by user
 * @property {object} body // set by user
 * @property {object} queryParams // set by user
 * @property {object} responseParser // set by user
 */

/**
 * @object FetcherConfig
 * @description
 * @property {object} mappedParams // set by user
 * @property {object} mappedResponse // set by user
 * @property {object} mappedHeaders // set by user
 * @property {object} mappedBody // set by user
 * @property {object} mappedQueryParams // set by user
 */

/**
 * 

/**
 * @object InputConnection
 * @description
 * @property {string} id // set by system
 * @property {string} apiProjectId // set by system
 * @property {string} chainId // set by system
 * @property {string} uid // set by system
 * @property {string} flowKey // set by system
 * @property {string} name // set by user
 * @property {string} mode // set by user - fetcher, webhook
 * @property {string} apiConnectorId // set by user
 * @property {object} positionFlow // set by user
 * @property {WebhookConfig} webhookConfig // set by user
 * @property {FetcherConfig} fetcherConfig // set by user
 * @property {string} parentId // set by system
 */

// const [] = [
//   {
//     id: "1",
//     flowKey: "input-connection-1",
//     name: "Input Connection 1",
//     connectorId: "1",
//     mode: "fetcher",
//     emittedProperties: {},
//     positionFlow: { x: 300, y: 200 },
//   },
//   {
//     id: "2",
//     flowKey: "input-connection-2",
//     name: "Input Connection 2",
//     connectorId: "2",
//     mode: "fetcher",
//     emittedProperties: {},
//     positionFlow: { x: 300, y: 500 },
//   },
//   {
//     id: "3",
//     flowKey: "input-connection-3",
//     name: "Input Connection 3",
//     connectorId: "3",
//     mode: "fetcher",
//     emittedProperties: {},
//     positionFlow: { x: 300, y: 800 },
//   },
// ];

const ItemWithPicSelector = ({ items, onSelect, actions, isSelected }) => {
  const activeItem = items.find((item) => isSelected(item));

  return (
    <Dropdown
      onSelect={(key) => {
        if (key.includes("action")) {
          const index = parseInt(key.split("-")[1]);
          actions[index].onClick();
        } else {
          const index = parseInt(key);
          onSelect(items[index]);
        }
      }}
    >
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        {activeItem && activeItem.name}
        {!activeItem && "Select Input API Connector"}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {actions.map((action, i) => {
          const { icon, onClick, label } = action;
          return (
            <Dropdown.Item key={i} onClick={onClick} eventKey={`action-${i}`}>
              <i className={`fas fa-${icon}`} /> <span>{label}</span>
            </Dropdown.Item>
          );
        })}
        {items.map((item, index) => (
          <Dropdown.Item key={index} eventKey={index} active={isSelected(item)}>
            {item.imageUrl && <img src={item.imageUrl} alt={item.name} />}
            <strong>{item.name}</strong>
            <div className="text-truncate text-gray-600 fs-6">
              {item.description}
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const getDataFromConnections = (connections) => {
  const nodes = connections.map((connection, i) => {
    // Will render by columns of 300px with 3 columns
    // const {apiProject, userUid} = dataNodes;

    const { name, positionFlow = {}, id } = connection;
    let additionalNodeProps = {};

    let x = positionFlow.x;
    let y = positionFlow.y;

    if ((!x && x !== 0) || (!y && y !== 0)) {
      const col = i % 3;
      const row = Math.floor(i / 3);

      x = col * 400;
      y = row * 400;
    }

    const node = {
      id: id,
      data: {
        label: name,
        key: id,
        connection
      },
      position: { x, y },
      type: "input-connection"
      // ...additionalNodeProps,
    };

    return node;
  });

  return nodes;
};

const getSystemValues = (inputs, outputs) => {
  // System values inputs/outputs
  const systemValuesForParameters = SystemBuilderUtils.getIOitems(
    inputs,
    outputs,
    {
      inputs: ["global.parameters", "inputBlocks.userInput"],
      outputs: [
        "transform.promptBlocks",
        "inputConnections.fetcher",
        "emitters.logic",
        "emitters.fetch"
      ]
    }
  );

  const systemValuesItems = SystemBuilderUtils.IOitemsToDropdownItems([
    ...systemValuesForParameters.inputsList,
    ...systemValuesForParameters.outputsList
  ]);

  const systemValuesForTriggering = SystemBuilderUtils.getIOitems(
    inputs,
    outputs,
    {
      inputs: ["global.parameters", "inputBlocks.userInput"],
      outputs: [
        "transform.promptBlocks",
        "inputConnections.fetcher",
        "emitters.logic",
        "emitters.fetch"
      ]
    }
  );

  const systemValuesItemsForTriggering =
    SystemBuilderUtils.IOitemsToDropdownItems([
      ...systemValuesForTriggering.inputsList,
      ...systemValuesForTriggering.outputsList
    ]);

  return {
    trigger: systemValuesItemsForTriggering,
    parameters: systemValuesItems
  };
};

export const InputConnectionNode = ({ data }) => {
  const { label, connection, key } = data;
  const { inputs, outputs, chainActions } = useContext(ChainContext);
  const { uid: userUid } = useContext(GlobalContext);
  const {
    apiProject,
    id: apiProjectId,
    inputApiConnectors
  } = useContext(ApiBuilderContext);
  const [currentBuiltPrompt, setCurrentBuiltPrompt] = useState("");
  const [modifyingInputApiConnector, setModifyingInputApiConnector] =
    useState(null);
  const [showInputApiConnectorModal, setShowInputApiConnectorModal] =
    useState(false);
  const [connectionState, setConnectionState] = useState(connection);
  const [inputApiConnector, setInputApiConnector] = useState(null);
  const [seeMethodOptions, setSeeMethodOptions] = useState(false);
  const [methods, setMethods] = useState([]);
  const fetcherConfig = connectionState.fetcherConfig || {};
  const shouldSave = React.useRef(false);
  const isUserCreator = apiProject.uid === userUid;

  useEffect(() => {
    if (!connectionState.apiConnectorId) {
      setInputApiConnector(null);
      return;
    }
    const unsubscribe = DB.apiProjects.sub.inputApiConnectors.get(
      apiProjectId,
      connectionState.apiConnectorId,
      (inputApiConnector) => {
        setInputApiConnector(inputApiConnector);
      }
    );
    return unsubscribe;
  }, [connectionState.apiConnectorId]);

  useEffect(() => {
    if (!connectionState && connectionState.fetcherConfig) {
      return;
    }
    if (shouldSave.current) {
      shouldSave.current = false;
      save();
    }
  }, [connectionState.fetcherConfig]);

  useEffect(() => {
    if (!connectionState) {
      return;
    }
    if (shouldSave.current) {
      shouldSave.current = false;
      save();
    }
  }, [connectionState]);

  useEffect(() => {
    if (connection && connection.id) {
      setConnectionState(connection);
    }
  }, [connection]);

  useEffect(() => {
    if (!inputApiConnector) {
      return;
    }
    const isFromShared = apiProject.uid !== userUid;
    const unsubscribe = DB.apiProjects.sub.inputApiConnectors.sub.methods.list(
      apiProjectId,
      inputApiConnector.id,
      (methods) => {
        setMethods(methods);
      },
      null,
      null,
      null,
      {
        isFromShared
      }
    );
    return unsubscribe;
  }, [inputApiConnector]);

  const handleModeChange = (e) => {
    const updatedInputConnection = { ...connectionState, mode: e.target.value };
    setConnectionState(updatedInputConnection);
  };

  const handleOnChangeFetcherConfig = (value, key) => {
    const updatedFetcherConfig = {
      ...fetcherConfig,
      [key]: value
    };
    const updatedConnection = {
      ...connectionState,
      fetcherConfig: updatedFetcherConfig
    };
    shouldSave.current = true;
    setConnectionState(updatedConnection);
  };

  const handleApiConnectorSelect = (selectedApiConnector) => {
    const updatedInputConnection = {
      ...connectionState,
      apiConnectorId: selectedApiConnector.id
    };

    chainActions.updateInputConnection(updatedInputConnection);
    setConnectionState(updatedInputConnection);
  };

  const save = async () => {
    try {
      await chainActions.updateInputConnection(connectionState);
    } catch (error) {
      console.error("Error saving connection:", error);
    }
  };

  const onBlur = () => {
    save();
  };

  const inputApiConnectorSelectorActions = [
    {
      label: "Create New Input API Connector",
      icon: "plus-circle",
      onClick: () => {
        setShowInputApiConnectorModal(true);
      }
    }
  ];

  const systemValues = getSystemValues(inputs, outputs);

  const selectedMethod = methods.find(
    (method) => method.id === fetcherConfig.selectedMethod
  );

  console.log("selectedMethod", selectedMethod);

  const importedQueriesVariables = selectedMethod
    ? selectedMethod.importedQueriesVariables
    : [];
  const importedParametersVariables = selectedMethod
    ? selectedMethod.importedParametersVariables
    : [];
  const importedPathVariables = selectedMethod
    ? selectedMethod.importedPathVariables
    : [];
  const importedHeadersVariables = selectedMethod
    ? selectedMethod.importedHeadersVariables
    : [];

  const importedVariables = [
    ...importedQueriesVariables,
    ...importedParametersVariables,
    ...importedPathVariables,
    ...importedHeadersVariables
  ];

  const importedVariablesSaved = fetcherConfig.options || [];
  const newImportedVariables = importedVariables.map((importedVariable) => {
    const { key } = importedVariable;
    const currentVariableSaved = importedVariablesSaved.find(
      (importedVariableSaved) => importedVariableSaved.key === key
    );
    const currentVariableSavedValue = currentVariableSaved
      ? currentVariableSaved.value
      : null;
    const variableMode = currentVariableSaved
      ? currentVariableSaved.variableMode
      : "rawValue";

    return {
      key,
      value: currentVariableSavedValue,
      type: importedVariable.type,
      variableMode
    };
  });

  const handleOnImportedVariableChange = (value, keyValue, keyVariable) => {
    const newOptions = [...(fetcherConfig.options || [])];

    const keyFoundIndex = newOptions.findIndex(
      (option) => option.key === keyVariable
    );

    if (keyFoundIndex === -1) {
      newOptions.push({
        key: keyVariable,
        [keyValue]: value
      });
    } else {
      newOptions[keyFoundIndex] = {
        ...newOptions[keyFoundIndex],
        [keyValue]: value
      };
    }

    handleOnImportedVariableChangeAllSave(newOptions);
  };

  const handleOnImportedVariableChangeAllSave = (newOptions) => {
    const newFetcherConfig = {
      ...fetcherConfig,
      options: newOptions
    };
    const updatedConnection = {
      ...connectionState,
      fetcherConfig: newFetcherConfig
    };
    shouldSave.current = true;
    setConnectionState(updatedConnection);
  };

  const options = fetcherConfig.options || [];

  const listeners = connectionState.listeners || [];

  return (
    <Card className="p-3 shadow-sm InputConnectionNode">
      <Card.Title className="text-white">{label}</Card.Title>
      <Card.Body>
        <Form.Group className="mb-3">
          <Form.Label className="text-white">Input Connection Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Name"
            value={connectionState.name}
            onBlur={onBlur}
            onChange={(e) => {
              const { value } = e.target;
              const newConnection = { ...connectionState, name: value };
              setConnectionState(newConnection);
            }}
          />
        </Form.Group>
        {/* dropdown selector for systemValues.trigger items list */}
        {/* <Form.Group className={"mb-3"}>
          <Form.Label>Triggered by</Form.Label>
          <DropdownGroupedSelect
            className="node-dropdown nodrag dpd-1 zIndex-9999"
            classNameMenu="nowheel"
            items={systemValues.trigger}
            onAdd={(item) => {
              const newListeners = [...listeners, item.value];
              shouldSave.current = true;
              setConnectionState({
                ...connectionState,
                listeners: newListeners
              });
            }}
            onRemove={(item) => {
              const newListeners = listeners.filter(
                (listener) => listener !== item.value
              );
              shouldSave.current = true;
              setConnectionState({
                ...connectionState,
                listeners: newListeners
              });
            }}
            isSelected={(item) => {
              return listeners.includes(item.value);
            }}
            tr={{
              title: (group) => SystemBuilderUtils.tr(group)
            }}
            placeholder={"Select Listener"}
          />
        </Form.Group> */}

        {/* <Form.Group className="mb-4 mt-3">
          <Form.Label className="text-white">Input Connection Mode</Form.Label>
          <Form.Control
            as="select"
            value={connectionState.mode}
            onChange={(e) => {
              const { value } = e.target;
              const newConnection = { ...connectionState, mode: value };
              setConnectionState(newConnection);
            }}
          >
            <option value="fetcher">Fetcher</option>
            <option value="webhook">Webhook</option>
          </Form.Control>
        </Form.Group> */}
        {connectionState.mode === "fetcher" ? (
          <>
            <div className="mt-3">
              <ItemWithPicSelector
                isSelected={(item) =>
                  item.id === connectionState.apiConnectorId
                }
                items={inputApiConnectors}
                onSelect={handleApiConnectorSelect}
                actions={inputApiConnectorSelectorActions}
              />
              {inputApiConnector && (
                <Button
                  className="pb-0"
                  variant="link"
                  onClick={() =>
                    setModifyingInputApiConnector(inputApiConnector)
                  }
                >
                  Edit Input API Connector
                </Button>
              )}
              {modifyingInputApiConnector && (
                <InputApiConnectorModal
                  isFromShared={!isUserCreator}
                  apiProjectId={apiProjectId}
                  show={true}
                  onHide={() => setModifyingInputApiConnector(null)}
                  onSave={(newApiConnector) => {
                    //
                  }}
                  inputApiConnector={modifyingInputApiConnector}
                />
              )}
            </div>
            {inputApiConnector && (
              <Form.Group className="mb-3">
                <Form.Label>Method</Form.Label>
                <Form.Control
                  as="select"
                  value={fetcherConfig.selectedMethod || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    handleOnChangeFetcherConfig(value, "selectedMethod");
                  }}
                >
                  <option value="">None</option>
                  {methods.map((method) => (
                    <option key={method.id} value={method.id}>
                      {method.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}
            {fetcherConfig.selectedMethod && (
              <Form.Group className="mb-3">
                <Button
                  variant="link"
                  className="p-0 fw-bold text-white-9"
                  onClick={() => setSeeMethodOptions(true)}
                >
                  Variables ({importedVariables.length})
                </Button>
                <Modal
                  className="flow-parameters-modal"
                  show={seeMethodOptions}
                  onHide={() => setSeeMethodOptions(false)}
                  size="lg"
                >
                  <Modal.Header closeButton className="p-4 mb-3">
                    <Modal.Title className="text-white">Variables</Modal.Title>
                  </Modal.Header>
                  <div className="modal-form-container">
                    {newImportedVariables.map((importedVariable, index) => {
                      const { key, type } = importedVariable;
                      const savedImportedVariable = options.find(
                        (importedVariable) => importedVariable.key === key
                      );
                      const value = savedImportedVariable
                        ? savedImportedVariable.value
                        : null;
                      const variableMode = savedImportedVariable
                        ? savedImportedVariable.variableMode
                        : "rawValue";

                      let selectProps = {};

                      const inputMode =
                        variableMode === "variableRef" ? "select" : "default";

                      const placeholder = `Enter value for ${key}`;

                      if (inputMode === "select") {
                        selectProps = {
                          items: systemValues.parameters,
                          isSelected: (item) => item.value === value,
                          onSelect: (item) => {
                            handleOnImportedVariableChange(
                              item.value,
                              "value",
                              key
                            );
                          },
                          className: "nodrag nowheel",
                          selectorPlaceholder: `Select ${key}`
                        };
                      }

                      return (
                        <Row key={index} className="mb-3">
                          <Col xs={12} className="mb-3">
                            <div className="d-flex align-items-center">
                              <div className="me-3">
                                <h5 className="title-label-parameter">{key}</h5>
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <InputTypeForm
                              resultclassname="nodrag text-white w-80 text-center d-flex align-items-center justify-content-center"
                              editbuttonclassname="btn-side-1"
                              placeholder={placeholder}
                              type={type}
                              inputMode={inputMode}
                              value={value}
                              notresultlabel={
                                <span className="text-white-5">
                                  List of {key} is empty
                                </span>
                              }
                              editbuttonlabel={`Add ${key} item`}
                              onChange={(value) => {
                                handleOnImportedVariableChange(
                                  value,
                                  "value",
                                  key
                                );
                              }}
                              {...selectProps}
                            />
                          </Col>
                          <Col>
                            <Form.Control
                              as="select"
                              value={variableMode}
                              onChange={(e) => {
                                const value = e.target.value;
                                handleOnImportedVariableChange(
                                  value,
                                  "variableMode",
                                  key
                                );
                              }}
                            >
                              <option value="rawValue">Raw Value</option>
                              <option value="variableRef">Variable Ref</option>
                            </Form.Control>
                          </Col>
                          <div className="horizontal-separator mt-3" />
                        </Row>
                      );
                    })}
                  </div>
                </Modal>
              </Form.Group>
            )}
            {selectedMethod && (
              <BlockType
                block={{
                  ...connectionState,
                  method: selectedMethod
                }}
                blockType="dataFetcher"
              />
            )}
            <InputApiConnectorModal
              apiProjectId={apiProjectId}
              show={showInputApiConnectorModal}
              onHide={() => setShowInputApiConnectorModal(false)}
              isFromShared={!isUserCreator}
            />
          </>
        ) : (
          // webhook mode form
          <></>
        )}
      </Card.Body>
    </Card>
  );
};

const nodesTypes = {
  "input-connection": InputConnectionNode
  // "global-chain-parameters": GlobalChainParameters,
};

const getDataNodes = (connections) => {
  const nodes = [...getDataFromConnections(connections)];
  return nodes;
};

const InputConnectorsFlow = ({ reactFlowInstance, onSelectFlow }) => {
  const { id: chainId, chainActions } = useContext(ChainContext);
  const { apiProject } = useContext(ApiBuilderContext);
  const { inputConnections = [] } = useContext(ChainContext);

  const [showGlobalParameters, setShowGlobalParameters] = useState(false);
  const { addNodes, setNodes: setNodesFlow } = reactFlowInstance;
  const { x: viewportX, y: viewportY, zoom } = useViewport();
  const [nodes, setNodes] = useState(getDataNodes([]));
  const [contextMenu, setContextMenu] = useState({
    show: false,
    position: { x: 0, y: 0 }
  });
  const [edges, setEdges] = useState([]);
  const isMovingNode = React.useRef(false);
  const lastNodesPositions = React.useRef({});
  const apiProjectId = apiProject.id;

  useEffect(() => {
    if (inputConnections) {
      setNodesFlow(getDataNodes(inputConnections));
      setNodes(getDataNodes(inputConnections));
    } else {
      setNodesFlow(getDataNodes([]));
      setNodes(getDataNodes([]));
    }
  }, [inputConnections]);

  const updateInputConnections = async (connections) => {
    try {
      for (let i = 0; i < connections.length; i++) {
        const connection = connections[i];
        await chainActions.updateInputConnection(connection);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onNewPositionsCallback = (changePayload) => {
    flowDbUtils.updateNodesPositions(
      changePayload,
      isMovingNode,
      lastNodesPositions,
      (newNodes) => {
        updateInputConnections(newNodes);
      }
    );
  };

  const onNodesChange = (changes) =>
    setNodes((nds) => {
      const changesPayload = changes.map((change) => {
        const { type: changeType } = change;

        if (changeType === "position") {
          const { position = null, id = null } = change;
          return { position, id, changeType };
        } else if (changeType === "add") {
          const { item } = change;
          const { data, id, position, type } = item;
          return { data, id, position, type, changeType };
        }
        return { ...change, changeType };
      });

      const positionsToChange = changesPayload.filter(
        (position) => position.changeType === "position"
      );
      if (positionsToChange.length > 0) {
        onNewPositionsCallback(changesPayload);
      }

      const filteredChanges = changes.filter((change) => {
        const { type, id } = change;

        const isOperationAllowed = !["remove", "select"].includes(type);
        // const isIdAllowed = !["global-chain-parameters"].includes(id);

        return isOperationAllowed;
      });

      return applyNodeChanges(filteredChanges, nds);
    });

  const onEdgesChange = React.useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onConnect = React.useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  const onBackgroundContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const x = e.clientX;
    const y = e.clientY;
    const height = e.target.clientHeight;

    setContextMenu({ show: true, position: { x, y }, menuHeight: height });
  };

  const onCloseContextMenu = () => {
    setContextMenu({ show: false, position: { x: 0, y: 0 } });
  };

  const actions = [
    {
      name: "Add Input Connection",
      onClick: async (e) => {
        const { clientX, clientY } = e;

        const positionFlow = {
          x: (clientX - viewportX) * zoom,
          y: (clientY - viewportY) * zoom
        };

        const newConnection = await chainActions.createInputConnection({
          positionFlow
        });

        addNodes({
          id: newConnection.id,
          data: {
            label: newConnection.name,
            key: newConnection.id,
            connection: {
              ...newConnection,
              id: newConnection.id
            }
          },
          position: { x: positionFlow.x, y: positionFlow.y },
          type: "input-connection"
        });
      },
      icon: "fas fa-plus-circle"
    }
  ];

  const actionsLeftMenu = [
    {
      icon: "bi bi-receipt",
      label: "Global Inputs/Parameters",
      description: "See and set the inputs/parameters your system will use",
      onClick: () => {
        setShowGlobalParameters(!showGlobalParameters);
      }
    }
  ];

  return (
    <>
      {showGlobalParameters && <GlobalChainParameters />}
      <ReactFlow
        style={{ height: "100%" }}
        nodes={nodes}
        edges={edges}
        nodeTypes={nodesTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        panOnScroll
        snapToGrid={true}
        defaultViewport={{
          x: 100,
          y: 100,
          zoom: 1
        }}
        snapGrid={[17, 20]}
        // className="nodes-background"
        // onContextMenu={onBackgroundContextMenu}
        onPaneContextMenu={onBackgroundContextMenu}
      >
        <Panel position="top-center" className="px-5 w-100">
          <PanelTopFlowSelector
            apiProjectId={apiProjectId}
            chainId={chainId}
            onSelectFlow={onSelectFlow}
            selectedFlow={"receive"}
          />
        </Panel>
        <Panel
          position="left"
          className="px-3 h-100 d-flex flex-column align-items-center justify-content-center"
        >
          <PanelLeft actions={actionsLeftMenu} />
        </Panel>
        <ContextMenuPane
          {...contextMenu}
          onClose={onCloseContextMenu}
          actions={actions}
        />
        <Background className="nodes-background" gap={75} id="background" />
        <Controls />
        <MiniMap />
      </ReactFlow>
    </>
  );
};

export default InputConnectorsFlow;
