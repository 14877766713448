// src/components/NewWebAppForm.js
import React, { useState } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import AudioInput from "../../../components/UI/AudioInput";

const NewWebAppForm = ({ actions, defaultValue }) => {
  const [webApp, setWebApp] = useState(defaultValue);

  const handleSubmit = async (event) => {
    event.preventDefault();
    await actions.createWebApp(webApp);
  };

  const handleOnChange = (event) => {
    setWebApp({
      ...webApp,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div className="container mt-5">
      <h1>Create a New WebApp</h1>
      <Form onSubmit={handleSubmit}>
        {/* Name */}
        <Form.Group as={Row} controlId="name">
          <Form.Label column sm={2}>
            WebApp Name
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="Enter WebApp name"
              name="name"
              value={webApp.name}
              onChange={handleOnChange}
            />
          </Col>
        </Form.Group>
        {/* Description */}
        <Form.Group as={Row} controlId="description" className="mt-3">
          <Form.Label column sm={2}>
            Description
          </Form.Label>
          <Col sm={10}>
            <AudioInput
              as="textarea"
              placeholder="Enter WebApp description"
              name="description"
              value={webApp.description}
              onChange={handleOnChange}
            />
          </Col>
        </Form.Group>
        {/* Submit */}
        <br />
        <div className="d-flex justify-content-end">
          <Button variant="primary" type="submit">
            Create WebApp
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default NewWebAppForm;
