import React from "react";
import {
  Navbar,
  Nav,
  Button,
  NavDropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
// import module to get path of current page

const T = ({ children, active }) => {
  if (!active) {
    return children;
  }

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="button-tooltip">In development</Tooltip>}
    >
      <span className="d-inline-block">{children}</span>
    </OverlayTrigger>
  );
};

const NavigationBar = () => {
  const navbarRef = React.useRef(null);
  const location = useLocation();
  const { logout, user } = useAuth();
  const isProduction = process.env.NODE_ENV === "production";
  const linkClass = !isProduction ? "text-primary" : "text-gray-500";

  const isHome = location.pathname === "/";

  // Add a listener to the scroll event and change the background color of the navbar
  // Only if the user is on the home page
  React.useEffect(() => {
    if (isHome) {
      const navbar = navbarRef.current;

      if (!navbar) {
        return;
      }

      if (window.scrollY > 100) {
        // navbar.classList.add("bg-dark");
        // navbar.classList.remove("py-5");
        setHomeHeaderBottomScroll();
      } else {
        setHomeHeaderTop();
        // navbar.classList.remove("bg-dark");
        // navbar.classList.add("py-5");
      }

      const onScroll = () => {
        if (window.scrollY > 100) {
          setHomeHeaderBottomScroll();
          // navbar.classList.add("bg-dark");
          // navbar.classList.remove("py-5");
        } else {
          setHomeHeaderTop();
          // navbar.classList.remove("bg-dark");
          // navbar.classList.add("py-5");
        }
      };

      window.addEventListener("scroll", onScroll);
      return () => {
        window.removeEventListener("scroll", onScroll);
      };
    }
  }, [isHome]);

  const setHomeHeaderTop = () => {
    const navbar = navbarRef.current;
    const links = navbar.querySelectorAll("a");

    navbar.classList.add("py-5");
    navbar.classList.add("at-top");
    navbar.classList.remove("bg-dark");
  };

  const setHomeHeaderBottomScroll = () => {
    const navbar = navbarRef.current;
    const links = navbar.querySelectorAll("a");

    navbar.classList.add("bg-dark");
    navbar.classList.remove("at-top");
    navbar.classList.remove("py-5");
  };

  const navbarClassName = (() => {
    if (isHome) {
      return "px-5 home-header";
    } else {
      return "px-5 bg-dark";
    }
  })();

  const navbarProps = (() => {
    if (isHome) {
      return {
        fixed: "top",
        expand: "lg",
      };
    } else {
      return {
        variant: "dark",
        expand: "lg",
        // style: { height: "40px" },
        bg: "dark",
      };
    }
  })();

  return (
    <Navbar
      {...navbarProps}
      className={"MainNavBar " + navbarClassName}
      ref={navbarRef}
    >
      <Navbar.Toggle aria-controls="basic-navbar-nav" />

      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto ps-1">
          {/* Brand image */}
          {/* align center classname flex */}
          <Navbar.Brand
            as={Link}
            to="/"
            className="d-flex align-items-center me-5"
          >
            <img
              src="/logos/Logo-Creaxys-1.png"
              height="25"
              className="d-inline-block align-top"
              alt="Logo Creaxys"
            />
          </Navbar.Brand>

          <Nav.Link as={Link} to="/system-designer" className="text-primary">
            System Designer
          </Nav.Link>

          <Nav.Link as={Link} to="/live-systems" className="text-primary">
            Live Systems
          </Nav.Link>

          {/* <NavDropdown
            renderMenuOnMount={true}
            menuVariant="dark"
            className="text-primary"
            title={<span className="text-primary">Create & Innovate</span>}
            id="innovate-nav-dropdown"
          >
            <NavDropdown.Item as={Link} to="/logic-system-creator">
              Design Your Logic System
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/automation-system-creator">
              Automate Your Tasks
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown
            renderMenuOnMount={true}
            menuVariant="dark"
            className="text-primary"
            title={<span className="text-primary">Market</span>}
            id="market-nav-dropdown"
          >
            <NavDropdown.Item as={Link} to="/market/categories">
              Browse Categories
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/market/top-rated">
              Top-Rated Systems
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown
            renderMenuOnMount={true}
            menuVariant="dark"
            className="text-primary"
            title={<span className="text-primary">Tools & Resources</span>}
            id="tools-nav-dropdown"
          >
            <NavDropdown.Item as={Link} to="/prompt-builder">
              AI-Powered Prompt Generator
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/integration-guides">
              Seamless Integration Guides
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/tutorials">
              Expert Tutorials & Tips
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/community-forum">
              Collaborative Community Forum
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/pricing">
              Affordable Pricing Plans
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown
            renderMenuOnMount={true}
            menuVariant="dark"
            className="text-primary"
            title={<span className="text-primary">Explore AI</span>}
            id="explore-ai-nav-dropdown"
          >
            <NavDropdown.Item as={Link} to="/ai-potential">
              Discover AI Potential
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/success-stories">
              AI Success Stories
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/industry-applications">
              Industry Applications
            </NavDropdown.Item>
          </NavDropdown>

          <Nav.Link as={Link} to="/webapp-builder" className="text-primary">
            Start Building Now
          </Nav.Link> */}
        </Nav>
      </Navbar.Collapse>

      <Navbar.Collapse className="justify-content-end">
        <Nav className="mr-auto">
          <NavDropdown
            renderMenuOnMount={true}
            menuVariant="dark"
            className="text-primary"
            title={
              <span className="text-primary">
                <i className="fas fs-5 fa-user-circle"></i>
              </span>
            }
            id="explore-ai-nav-dropdown"
          >
            <NavDropdown.Item as={Link} to="/account">
              Settings
            </NavDropdown.Item>
            <NavDropdown.Item variant="link" as={Button} onClick={logout}>
              Log out
            </NavDropdown.Item>
          </NavDropdown>

          {/* <Navbar.Text className="text-white">
            Signed in as:{" "}
            <Link to="/account" className="text-white fw-bold">
              {user && user.email}
            </Link>
          </Navbar.Text>
          <Nav.Link
            variant="link"
            as={Button}
            onClick={logout}
            className="text-gray-500"
          >
            Log out
          </Nav.Link> */}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;
