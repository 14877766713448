/**
 * @fileoverview TasksVision
 * @description TasksVision
 *   This component is used to execute AI powered vision tasks such as Image Segmentation.
 */

import React, { useState, useEffect } from "react";
import { Row, Col, Spinner, Modal } from "react-bootstrap";
import { FilesetResolver, ImageSegmenter } from "@mediapipe/tasks-vision";
import { groupSimilarColours, isPixelsSimilar } from "../../utils/colors";

const labels = [
  "background",
  "aeroplane",
  "bicycle",
  "bird",
  "boat",
  "bottle",
  "bus",
  "car",
  "cat",
  "chair",
  "cow",
  "dining table",
  "dog",
  "horse",
  "motorbike",
  "person",
  "potted plant",
  "sheep",
  "sofa",
  "train",
  "tv",
];

const legendColors = [
  [255, 197, 0, 255], // Vivid Yellow
  [128, 62, 117, 255], // Strong Purple
  [255, 104, 0, 255], // Vivid Orange
  [166, 189, 215, 255], // Very Light Blue
  [193, 0, 32, 255], // Vivid Red
  [206, 162, 98, 255], // Grayish Yellow
  [129, 112, 102, 255], // Medium Gray
  [0, 125, 52, 255], // Vivid Green
  [246, 118, 142, 255], // Strong Purplish Pink
  [0, 83, 138, 255], // Strong Blue
  [255, 112, 92, 255], // Strong Yellowish Pink
  [83, 55, 112, 255], // Strong Violet
  [255, 142, 0, 255], // Vivid Orange Yellow
  [179, 40, 81, 255], // Strong Purplish Red
  [244, 200, 0, 255], // Vivid Greenish Yellow
  [127, 24, 13, 255], // Strong Reddish Brown
  [147, 170, 0, 255], // Vivid Yellowish Green
  [89, 51, 21, 255], // Deep Yellowish Brown
  [241, 58, 19, 255], // Vivid Reddish Orange
  [35, 44, 22, 255], // Dark Olive Green
  [0, 161, 194, 255], // Vivid Blue
];

const rgbToHex = (r, g, b) => {
  if (r > 255 || g > 255 || b > 255) throw "Invalid color component";
  return ((r << 16) | (g << 8) | b).toString(16);
};

const getAllPixelsWithSamePixel = (imageData, pixel) => {
  let pixels = [];
  for (let i = 0; i < imageData.data.length; i += 4) {
    if (
      imageData.data[i] === pixel[0] &&
      imageData.data[i + 1] === pixel[1] &&
      imageData.data[i + 2] === pixel[2] &&
      imageData.data[i + 3] === pixel[3]
    ) {
      pixels.push(i);
    }
  }
  return pixels;
};

const isPixelSameColor = (pixel1, pixel2) => {
  return (
    pixel1[0] === pixel2[0] &&
    pixel1[1] === pixel2[1] &&
    pixel1[2] === pixel2[2] &&
    pixel1[3] === pixel2[3]
  );
};

// grouping in an object by key
const groupedPixelsByColor = (imageData) => {
  let groupedPixels = {};
  for (let i = 0; i < imageData.data.length; i += 4) {
    let p = [
      imageData.data[i],
      imageData.data[i + 1],
      imageData.data[i + 2],
      imageData.data[i + 3],
    ];
    // let hex = rgbToHex(pixel[0], pixel[1], pixel[2]);
    var hex = "#" + ("000000" + rgbToHex(p[0], p[1], p[2])).slice(-6);
    if (groupedPixels[hex]) {
      groupedPixels[hex].push(i);
    } else {
      groupedPixels[hex] = [i];
    }
  }
  return groupedPixels;
};

const TasksVisionSegmentation = ({ imgUrl }) => {
  const [removingBackground, setRemovingBackground] = useState(false);
  const [threshold, setThreshold] = useState(0.15);
  const canvasCopy = React.useRef(null);
  const canvas = React.useRef(null);
  const img = React.useRef(null);
  const vision = React.useRef(null);
  const imageSegmenter = React.useRef(null);

  useState(() => {
    // (async () => {
    //   let newVision = await FilesetResolver.forVisionTasks(
    //     "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.1.0-alpha-11/wasm"
    //   );
    //   vision.current = newVision;
    //   let newImageSegmenter = await ImageSegmenter.createFromOptions(
    //     newVision,
    //     {
    //       baseOptions: {
    //         modelAssetPath:
    //           // "https://storage.googleapis.com/mediapipe-assets/deeplabv3.tflite?generation=1661875711618421", // human/animal/vehicle
    //           "https://storage.googleapis.com/mediapipe-tasks/image_segmenter/selfie_segm_128_128_3.tflite", // more general
    //       },
    //       runningMode: "IMAGE",
    //     }
    //   );
    //   imageSegmenter.current = newImageSegmenter;
    // })();
  }, []);

  const segmenterCallback = (masks, width, height) => {
    const canvasElement = canvas.current;
    const cxt = canvasElement.getContext("2d");
    let datas = cxt.getImageData(0, 0, width, height).data;
    canvasElement.width = width;
    canvasElement.height = height;
    let category = "";
    const mask = masks[0];
    for (let i in mask) {
      if (mask[i] > 0) {
        category = labels[mask[i]];
      }
      const dataR = legendColors[mask[i]];
      //
      if (mask[i] > 0) {
        // datas[i * 4] = (dataR[0] + datas[i * 4]) / 2;
        // datas[i * 4 + 1] = (dataR[1] + datas[i * 4 + 1]) / 2;
        // datas[i * 4 + 2] = (dataR[2] + datas[i * 4 + 2]) / 2;
        // datas[i * 4 + 3] = (dataR[3] + datas[i * 4 + 3]) / 2;
      } else {
        // remove background
        datas[i * 4 + 3] = 0;
      }
    }
    const uint8Array = new Uint8ClampedArray(datas);
    const dataNew = new ImageData(uint8Array, width, height);
    cxt.putImageData(dataNew, 0, 0);
    console.log("category", category);
  };

  const onLoad = async (event) => {
    const canvasElement = canvas.current;
    canvasElement.classList.remove("removed");
    canvasElement.width = event.target.naturalWidth;
    canvasElement.height = event.target.naturalHeight;
    const cxt = canvasElement.getContext("2d", { willReadFrequently: true });
    cxt.save();
    // cxt.clearRect(0, 0, canvasElement.width, canvasElement.height);
    cxt.drawImage(
      event.target,
      0,
      0,
      canvasElement.width,
      canvasElement.height
    );
    // await imageSegmenter.current.setOptions({ runningMode: "IMAGE" });
    // imageSegmenter.current.segment(event.target, segmenterCallback);
  };

  // Get the color of the pixel at the given coordinates.
  const onCanvasClick = (event) => {
    setRemovingBackground(true);
    const canvasElement = canvas.current;
    const cxt = canvasElement.getContext("2d", { willReadFrequently: true });

    const ratio = canvasElement.width / img.current.width;

    const pixel = cxt.getImageData(
      event.nativeEvent.offsetX * ratio,
      event.nativeEvent.offsetY * ratio,
      img.current.width,
      img.current.height
    ).data;
    copyCanvasExceptPixelsWithSameColor(pixel);
    setRemovingBackground(false);
  };

  const onCanvasCopyClick = (event) => {
    setRemovingBackground(true);
    const canvasCopyElement = canvasCopy.current;
    const cxt = canvasCopyElement.getContext("2d", {
      willReadFrequently: true,
    });

    const ratio = canvasCopyElement.width / img.current.width;

    const pixel = cxt.getImageData(
      event.nativeEvent.offsetX * ratio,
      event.nativeEvent.offsetY * ratio,
      img.current.width,
      img.current.height
    ).data;
    copyCanvasExceptPixelsWithSameColor(pixel, canvasCopyElement);
    setRemovingBackground(false);
  };

  const copyCanvasExceptPixelsWithSameColor = (pixel, sourceCanvas) => {
    const canvasSource = canvas.current;
    const canvasElement = sourceCanvas || canvas.current;
    const cxt = canvasElement.getContext("2d", { willReadFrequently: true });
    const canvasCopyElement = canvasCopy.current;
    canvasCopyElement.classList.remove("removed");
    canvasCopyElement.width = canvasSource.width;
    canvasCopyElement.height = canvasSource.height;
    const cxtCopy = canvasCopyElement.getContext("2d", {
      willReadFrequently: true,
    });
    const imageData = cxt.getImageData(
      0,
      0,
      canvasCopyElement.width,
      canvasCopyElement.height
    );
    let data = imageData.data;
    var hexColorPixel =
      "#" + ("000000" + rgbToHex(pixel[0], pixel[1], pixel[2])).slice(-6);
    for (let i = 0; i < data.length; i += 4) {
      let hexColor =
        "#" +
        ("000000" + rgbToHex(data[i], data[i + 1], data[i + 2])).slice(-6);
      if (isPixelsSimilar(hexColorPixel, hexColor, "lab", threshold)) {
        data[i + 3] = 0;
      } else {
      }
    }
    cxtCopy.putImageData(imageData, 0, 0);
    cxtCopy.save();
  };

  const copyPixelsToCanvasCopy = (pixels_with_same_color) => {
    const canvasElement = canvas.current;
    const cxt = canvasElement.getContext("2d", { willReadFrequently: true });
    const canvasCopyElement = canvasCopy.current;
    canvasCopyElement.classList.remove("removed");
    canvasCopyElement.width = canvasElement.width;
    canvasCopyElement.height = canvasElement.height;
    const cxtCopy = canvasCopyElement.getContext("2d", {
      willReadFrequently: true,
    });
    cxtCopy.save();
    cxtCopy.clearRect(0, 0, canvasCopyElement.width, canvasCopyElement.height);
    cxtCopy.drawImage(
      canvasElement,
      0,
      0,
      canvasCopyElement.width,
      canvasCopyElement.height
    );
    const imageData = cxtCopy.getImageData(
      0,
      0,
      canvasCopyElement.width,
      canvasCopyElement.height
    );
    const data = imageData.data;
    for (let i = 0; i < data.length; i += 4) {
      if (!pixels_with_same_color.includes(i)) {
        data[i + 3] = 0;
      }
    }
    cxtCopy.putImageData(imageData, 0, 0);
  };

  console.log("removingBackground", removingBackground);

  return (
    <div>
      {/* Description */}
      <Row>
        <Col>
          <h3>Image Segmentation</h3>
          <p>
            Image segmentation is the process of partitioning an image into
            multiple segments. The goal of segmentation is to simplify and/or
            change the representation of an image into something that is more
            meaningful and easier to analyze.
          </p>
        </Col>
      </Row>

      <Row className="bg-light">
        <Col className="p-5" style={{ position: "relative" }}>
          <div className="bg-light" style={{ opacity: 0 }}>
            <img
              crossOrigin="anonymous"
              ref={img}
              src={imgUrl}
              className="img-fluid"
              onLoad={onLoad}
            />
          </div>

          <div
            className="bg-light p-5"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
            }}
          >
            <canvas
              onClick={onCanvasClick}
              className="img-fluid removed"
              ref={canvas}
              width={img.current?.width}
              height={img.current?.height}
            />
          </div>
        </Col>
        <Col className="p-5" style={{ position: "relative" }}>
          <div className="bg-light">
            <canvas
              onClick={onCanvasCopyClick}
              className="img-fluid removed"
              ref={canvasCopy}
              width={img.current?.width}
              height={img.current?.height}
            />
          </div>
          {/* Loader layout position absolute, centered spinner */}
          {removingBackground && (
            <div
              className="bg-light d-flex justify-content-center align-items-center"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 1,
                backgroundColor: "rgba(255, 255, 255, 0.5)",
              }}
            >
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <button onClick={segmentImage}>Segment</button>
        </Col>
      </Row> */}
    </div>
  );
};

export const TasksVisionMode = ({ mode, ...props }) => {
  if (mode === "segmentation") {
    return <TasksVisionSegmentation {...props} />;
  }
  return null;
};

export const TasksVisionModal = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          TasksVision
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TasksVisionMode {...props} />
      </Modal.Body>
    </Modal>
  );
};
