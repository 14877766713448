import DB from "../database/DB";
import {
  getDefaultNewPromptBlock,
  getDefaultInputBlock,
  getRandomId,
  getDefaultDataObject,
  getDefaultEmitter,
  getDefaultInputConnection,
  getDefaultDeploymentModel
} from "./ApiBuilderContext";

const getNewKeyForPromptBlock = (promptBlocks = []) => {
  return promptBlocks.length + "";
};
const getDocWithoutUid = (doc) => {
  const { uid, ...docWithoutUid } = doc;
  return docWithoutUid;
};

export const SystemBuilderCrud = {
  /* Chain */
  deploymentModels: {
    create: async (apiProject, uid, deploymentModel) => {
      const isProjectCreator = apiProject.uid === uid;
      let additionalProps = {};

      if (isProjectCreator) {
        additionalProps.uid = uid;
      } else {
        additionalProps.uid = apiProject.uid;
      }

      const newDeploymentModel = getDefaultDeploymentModel({
        ...deploymentModel,
        ...additionalProps,
        apiProjectId: apiProject.id
      });
      const id = await DB.apiProjects.sub.deploymentModels.create(
        apiProject.id,
        newDeploymentModel
      );

      return { ...newDeploymentModel, id };
    },
    update: async (apiProject, deploymentModel, uid, newDeploymentModel) => {
      await DB.apiProjects.sub.deploymentModels.update(
        apiProject.id,
        deploymentModel.id,
        getDocWithoutUid(newDeploymentModel)
      );
      return newDeploymentModel;
    }
  },
  chain: {
    create: async (apiProject, uid, chain) => {
      const isProjectCreator = apiProject.uid === uid;
      let additionalProps = {};

      if (isProjectCreator) {
        additionalProps.uid = uid;
      } else {
        additionalProps.uid = apiProject.uid;
      }

      const newChain = {
        ...chain,
        ...additionalProps,
        apiProjectId: apiProject.id
      };
      const id = await DB.apiProjects.sub.chains.create(
        apiProject.id,
        newChain
      );
      return { ...newChain, id };
    },
    update: async (apiProject, chain, uid, newChain) => {
      await DB.apiProjects.sub.chains.update(
        apiProject.id,
        chain.id,
        getDocWithoutUid(newChain)
      );
      return newChain;
    },
    /* GlobalParameters */
    createGlobalParameter: async (apiProject, chain, uid, globalParameters) => {
      const newChain = {
        ...chain,
        globalParameters: [...(chain.globalParameters || []), globalParameters]
      };
      await DB.apiProjects.sub.chains.update(
        apiProject.id,
        chain.id,
        getDocWithoutUid(newChain)
      );
      return globalParameters;
    },
    updateGlobalParameter: async (apiProject, chain, uid, globalParameters) => {
      const newGlobalParameters = (chain.globalParameters || []).map((gp) => {
        if (gp.id === globalParameters.id) {
          return globalParameters;
        }
        return gp;
      });
      const newChain = {
        ...chain,
        globalParameters: newGlobalParameters
      };
      await DB.apiProjects.sub.chains.update(
        apiProject.id,
        chain.id,
        getDocWithoutUid(newChain)
      );
      return newChain;
    },
    /* Prompt Block */
    createPromptBlock: async (apiProject, chain, uid, promptBlock) => {
      const promptBlocks = chain.promptBlocks || [];
      const newPromptBlock = getDefaultNewPromptBlock({
        chainKey: getNewKeyForPromptBlock(promptBlocks),
        ...promptBlock
      });
      const newPromptBlocks = [...promptBlocks, newPromptBlock];
      const newChain = {
        ...chain,
        promptBlocks: newPromptBlocks
      };
      await DB.apiProjects.sub.chains.update(
        apiProject.id,
        chain.id,
        getDocWithoutUid(newChain)
      );
      return newPromptBlock;
    },
    updatePromptBlocks: async (apiProject, chain, uid, promptBlocks) => {
      const newChain = {
        ...chain,
        promptBlocks
      };
      await DB.apiProjects.sub.chains.update(
        apiProject.id,
        chain.id,
        getDocWithoutUid(newChain)
      );
      return newChain;
    },
    updatePromptBlock: async (apiProject, chain, uid, promptBlock) => {
      const newPromptBlocks = chain.promptBlocks.map((pb) => {
        if (pb.chainKey === promptBlock.chainKey) {
          return promptBlock;
        }
        return pb;
      });
      const newChain = {
        ...chain,
        promptBlocks: newPromptBlocks
      };
      await DB.apiProjects.sub.chains.update(
        apiProject.id,
        chain.id,
        getDocWithoutUid(newChain)
      );
      return promptBlock;
    },
    /* Input Block */
    createInputBlock: async (apiProject, chain, uid, inputBlock) => {
      const inputBlocks = chain.inputBlocks || [];
      const newInputBlock = getDefaultInputBlock({
        name: `Input ${inputBlocks.length + 1}`,
        ...inputBlock,
        id: getRandomId()
      });
      const newInputBlocks = [...inputBlocks, newInputBlock];
      const newChain = {
        ...chain,
        inputBlocks: newInputBlocks
      };
      await DB.apiProjects.sub.chains.update(
        apiProject.id,
        chain.id,
        getDocWithoutUid(newChain)
      );
      return newInputBlock;
    },
    updateInputBlocks: async (apiProject, chain, uid, inputBlocks) => {
      const newChain = {
        ...chain,
        inputBlocks
      };
      await DB.apiProjects.sub.chains.update(
        apiProject.id,
        chain.id,
        getDocWithoutUid(newChain)
      );
      return newChain;
    },
    updateInputBlock: async (apiProject, chain, uid, inputBlock) => {
      const newInputBlocks = chain.inputBlocks.map((ib) => {
        if (ib.id === inputBlock.id) {
          return inputBlock;
        }
        return ib;
      });
      const newChain = {
        ...chain,
        inputBlocks: newInputBlocks
      };
      await DB.apiProjects.sub.chains.update(
        apiProject.id,
        chain.id,
        getDocWithoutUid(newChain)
      );
      return inputBlock;
    },
    /* Data Objects */
    createDataObject: async (apiProject, chain, uid, dataObject) => {
      const dataObjects = chain.dataObjects || [];
      const newDataObject = getDefaultDataObject({
        name: `Data Object ${dataObjects.length + 1}`,
        ...dataObject,
        id: getRandomId()
      });
      const newDataObjects = [...dataObjects, newDataObject];
      const newChain = {
        ...chain,
        dataObjects: newDataObjects
      };
      await DB.apiProjects.sub.chains.update(
        apiProject.id,
        chain.id,
        getDocWithoutUid(newChain)
      );
      return newDataObject;
    },
    updateDataObjects: async (apiProject, chain, uid, dataObjects) => {
      const newChain = {
        ...chain,
        dataObjects
      };
      await DB.apiProjects.sub.chains.update(
        apiProject.id,
        chain.id,
        getDocWithoutUid(newChain)
      );
      return newChain;
    },
    updateDataObject: async (apiProject, chain, uid, dataObject) => {
      const newDataObjects = chain.dataObjects.map((doj) => {
        if (doj.id === dataObject.id) {
          return dataObject;
        }
        return doj;
      });
      const newChain = {
        ...chain,
        dataObjects: newDataObjects
      };
      await DB.apiProjects.sub.chains.update(
        apiProject.id,
        chain.id,
        getDocWithoutUid(newChain)
      );
      return dataObject;
    }
  },
  /* Emitter */
  emitter: {
    create: async (apiProject, chain, uid, emitter) => {
      const isProjectCreator = apiProject.uid === uid;
      const additionalProps = {};

      if (isProjectCreator) {
        additionalProps.uid = uid;
      } else {
        additionalProps.uid = apiProject.uid;
      }

      const newEmitter = {
        ...getDefaultEmitter({
          ...emitter,
          apiProjectId: apiProject.id,
          chainId: chain.id
        }),
        ...additionalProps
      };
      const id = await DB.apiProjects.sub.chains.sub.emitters.create(
        apiProject.id,
        chain.id,
        newEmitter
      );
      return { ...newEmitter, id };
    },
    update: async (apiProject, chain, uid, emitter) => {
      await DB.apiProjects.sub.chains.sub.emitters.update(
        apiProject.id,
        chain.id,
        emitter.id,
        getDocWithoutUid(emitter)
      );
      return emitter;
    }
  },
  /* Input Connection */
  inputConnection: {
    create: async (apiProject, chain, uid, inputConnection) => {
      const isProjectCreator = apiProject.uid === uid;
      const additionalProps = {};

      if (isProjectCreator) {
        additionalProps.uid = uid;
      } else {
        additionalProps.uid = apiProject.uid;
      }

      const newInputConnection = getDefaultInputConnection({
        ...inputConnection,
        apiProjectId: apiProject.id,
        chainId: chain.id,
        ...additionalProps
      });
      const id = await DB.apiProjects.sub.chains.sub.inputConnections.create(
        apiProject.id,
        chain.id,
        newInputConnection
      );
      return { ...newInputConnection, id };
    },
    update: async (apiProject, chain, uid, inputConnection) => {
      await DB.apiProjects.sub.chains.sub.inputConnections.update(
        apiProject.id,
        chain.id,
        inputConnection.id,
        getDocWithoutUid(inputConnection)
      );
      return inputConnection;
    }
  }
};
