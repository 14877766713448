//openAiAPI.js
import axios from "axios";
import { firebaseAuth } from "../api-connector/firebase";

const p = {
  transform: {
    jsonl: `${process.env.REACT_APP_API_URL}/storage-utils/transform/jsonl`,
    docxText: `${process.env.REACT_APP_API_URL}/storage-utils/transform/docx-text`,
  },
  upload: {
    webappPictures: `${process.env.REACT_APP_API_URL}/storage-utils/upload/webapp-pictures`,
  },
};

const getFirebaseToken = async () => {
  const token = await firebaseAuth.currentUser.getIdToken();
  return token;
};

const getHeaders = async () => {
  const token = await getFirebaseToken();
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const transformJsonl = async (params) => {
  try {
    const headers = await getHeaders();

    console.log("params transform", params);
    const result = await axios.post(
      p.transform.jsonl,
      {
        ...params,
      },
      headers
    );
    console.log("result transform", result);
    return result.data;
  } catch (error) {
    console.error(error);
  }
};

export const getDocxText = async (formData) => {
  try {
    const headers = await getHeaders();

    const result = await axios.post(p.transform.docxText, formData, headers);
    return result.data;
  } catch (error) {
    console.error(error);
  }
};

// Send using browser Stream API for sending stream and fetch API for receiving stream, and forget about axios
// create a ReadableStream from the data and send it to the server through body
// disable cors
export const uploadWebappPictures = async (formData, options = {}) => {
  const { headers } = await getHeaders();

  const response = await fetch(p.upload.webappPictures, {
    method: "POST",
    body: formData,
    headers,
  });

  const reader = response.body.getReader();
  const { onDataStream, onErrorStream, onDataStreamEnd } = options;

  let contentLength, contentEncoding;

  try {
    contentEncoding = response.headers.get("content-encoding");
    contentLength = response.headers.get(
      contentEncoding ? "x-file-size" : "content-length"
    );
  } catch (e) {
    console.error(e);
  }
  console.log("contentLength", contentLength);
  console.log("contentEncoding", contentEncoding);

  const finalData = await (async () => {
    const dataChunksStr = [];

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        if (onDataStreamEnd) onDataStreamEnd(dataChunksStr.join("").trim());
        return dataChunksStr;
      }
      const text = new TextDecoder().decode(value);
      dataChunksStr.push(text);
      if (onDataStream) onDataStream(text, dataChunksStr.join(""));
    }
  })();
  return JSON.parse(finalData.join("").trim());
};

export default {
  transform: {
    jsonl: transformJsonl,
    docxText: getDocxText,
  },
  upload: {
    webappPictures: uploadWebappPictures,
  },
};
