import App from "./App";
import HomePage from "./components/pages/Home";

const appChildren = (rootName) => [
  {
    path: `${rootName}/home`,
    element: <HomePage />,
  },
];

const routes = (rootName) => [
  {
    path: rootName || "/",
    element: <App />,
    children: appChildren(rootName),
  },
  {
    path: rootName + "/:webappProjectId",
    element: <App />,
  },
];

export default routes;
