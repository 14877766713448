import { FirestoreCrud } from "../../../database/FirestoreCrud";

const apiProjectDB = new FirestoreCrud("apiProjects").setSubCollection(
  "chains"
);

const webappProjectDB = new FirestoreCrud("webappProjects").setSubCollection(
  "pages"
);
webappProjectDB.setSubCollection("dataModels");
webappProjectDB.setSubCollection("icons");

export default {
  apiProjects: apiProjectDB,
  webappProjects: webappProjectDB,
  builtPrompts: new FirestoreCrud("builtPrompts"),
  builtPromptTags: new FirestoreCrud("builtPromptTags"),
  builtPromptGroups: new FirestoreCrud("builtPromptGroups"),
};
