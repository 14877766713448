export const initElements = `
// Allow to click on links and buttons
// And send to parent window
(() => {
  const removeIframeSelectedClass = () => {
    const selectedElements = document.querySelectorAll(".iframe-selected");
    selectedElements.forEach((element) => {
      element.classList.remove("iframe-selected");
    });
  };

  const addRandomIdToElementIfNotExists = (element, prefix) => {
    if (!element.id) {
      element.id = prefix + "-" + Math.random().toString(36).substr(2, 9);
    }
  };
  
  const linkElementsToParent = (tag) => {
    const elements = document.querySelectorAll(tag);
    elements.forEach((element) => {
      element.addEventListener("click", (e) => {
        e.preventDefault();
        e.stopPropagation();
        removeIframeSelectedClass();
        addRandomIdToElementIfNotExists(element, tag);
        element.classList.add("iframe-selected");
        let paramsToPost = {
          type: tag,
          id: element.id,
        };
        if (tag === "img") {
        }
        if (tag === "input") {
          paramsToPost.inputType = element.type;
        }
        window.parent.postMessage(paramsToPost, "*");
      });
    });
  };

  linkElementsToParent("button");
  linkElementsToParent("a");
  // linkElementsToParent("img");
  linkElementsToParent("input");
  linkElementsToParent("textarea");
  linkElementsToParent("select");
  linkElementsToParent("ul");
  linkElementsToParent("section");
  linkElementsToParent("header");
  linkElementsToParent("nav");
  linkElementsToParent("footer");

  // remove iframe-selected class from any elements who has it using body click
  document.body.addEventListener("click", (e) => {
    removeIframeSelectedClass();
  });
})();
`;

// import cdn
export const importStyle = (style) => {
  if (style === "vanilla") return "";
  if (style === "bootstrap")
    return `
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" integrity="sha384-JcKb8q3iqJ61gNV9KGb8thSsNjpSL0n8PARn9HuZOnIxN0hoP+VmmDGMN5t9UJ0Z" crossorigin="anonymous">
  `;
  if (style === "material")
    return `
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/css/materialize.min.css">
  `;
  if (style === "bulma")
    return `
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bulma/0.9.0/css/bulma.min.css">
  `;
  if (style === "tailwind")
    return `
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/1.9.6/tailwind.min.css">
  `;
  if (style === "semantic")
    return `
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css">
  `;
  if (style === "foundation")
    return `
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/foundation/6.6.3/css/foundation.min.css">
  `;
  if (style === "pure")
    return `
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/pure/2.0.3/pure-min.css">
  `;
  if (style === "spectre")
    return `
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/spectre.css/0.5.9/spectre.min.css">
  `;
  if (style === "uikit")
    return `
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/uikit/3.5.9/css/uikit.min.css">
  `;
  if (style === "milligram")
    return `
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/milligram/1.4.1/milligram.min.css">
  `;
  if (style === "skeleton")
    return `
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/skeleton/2.0.4/skeleton.min.css">
  `;
  if (style === "basscss")
    return `
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/basscss/8.0.1/basscss.min.css">
  `;
  if (style === "picnic")
    return `
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/picnic/8.0.1/picnic.min.css">
  `;
  if (style === "tachyons")
    return `
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tachyons/4.12.0/tachyons.min.css">
  `;
  if (style === "minireset")
    return `
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/minireset.css/0.0.6/minireset.min.css">
  `;
  if (style === "normalize")
    return `
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css">
  `;
  if (style === "sanitize")
    return `
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/sanitize.css/10.0.0/sanitize.min.css">
  `;
  if (style === "sakura")
    return `
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/sakura/1.3.0/sakura.min.css">
  `;
  return "";
};

// defaut is font awesome
export const importIconsFont = (iconFontModel) => {
  if (iconFontModel === "none") return "";
  if (iconFontModel === "fontawesome")
    return `
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css">
  `;
  if (iconFontModel === "material")
    return `
    <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons">
  `;
  if (iconFontModel === "ionicons")
    return `
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/ionicons/5.4.0/collection/icon/icon.css">
  `;
  if (iconFontModel === "themify")
    return `
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/themify-icons/0.1.2/css/themify-icons.min.css">
  `;
  if (iconFontModel === "typicons")
    return `
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/typicons/2.0.9/typicons.min.css">
  `;
  if (iconFontModel === "bootstrap")
    return `
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.1/font/bootstrap-icons.min.css">
  `;
  if (iconFontModel === "foundation")
    return `
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/foundation-icon-fonts/3.0.0/foundation-icons.min.css">
  `;
  if (iconFontModel === "evil")
    return `
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/evil-icons/1.10.1/evil-icons.min.css">
  `;
  return `
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css">
  `;
};
