/**
 * @file Account.js
 * @description Account page.
 */

// firestore documents
// userAccounts and userAccounts/{userId}/paymentLines - DB.userAccounts || DB.userAccounts.sub.paymentLines
// userAccounts contains following fields:
// * 1. id: string
// * 2. userId: string
// * 3. apiKeys: { openai: string }
// * 4. subscription: { plan: string, status: string, startDate: string, endDate: string }

// userAccounts/{userId}/paymentLines contains following fields:
// * id: string
// * userId: string
// * paymentDate: serverTimestamp
// * paymentAmount: number
// * paymentCurrency: string
// * lineDateInit: serverTimestamp
// * paymentFor: string ("request"/"subscription"/"feature"/"database"/"hosting")
// * status: string ("pending"/"paid"/"failed")
// -- Request data of paymentLines ---
// * requestConsumedPromptTokens: number
// * requestConsumedCompletionTokens: number
// * requestUsedModel: string
// * requestModelTokenPrice: { completion: number, prompt: number}
// * requestTotalCost: number
// * requestPromptCost: number
// * requestCompletionCost: number
// --- Subscription data of paymentLines ---
// * subscriptionPlan: string
// * subscriptionStartDate: string
// * subscriptionEndDate: string
// * subscriptionTotalPrice: number
// --- Feature data of paymentLines ---
// * featureName: string
// * featurePrice: number
// * featureTotalPrice: number
// --- Database data of paymentLines ---
// * databaseName: string
// * databasePrice: number
// * databaseTotalPrice: number
// --- Hosting data of paymentLines ---
// * hostingName: string
// * hostingPrice: number
// * hostingTotalPrice: number
//

import React, { useState, useEffect } from "react";
import DB from "../../database/DB";
import {
  Col,
  Row,
  Container,
  Button,
  Form,
  Card,
  Table,
  Spinner,
  Alert,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import { DateTime } from "luxon";
import { firebaseAuth } from "../../api-connector/firebase";
import payments from "../../apis/payments";

const planDetails = {
  free: {
    name: "Free",
    price: 0,
    features: [
      "API Builder - 1 projects",
      "API Builder Project - 8 maximum block per project",
      "Require you to set API key for model languages such as OpenAI",
    ],
  },
  basic: {
    name: "Basic",
    price: 29.99,
    features: [
      "API Builder - 3 projects",
      "API Builder Project - 15 maximum block per project",
      "Web App Builder - 1 projects",
      "5$ credit for third party ML model",
      "Access to a list of free ML models(level 1)",
    ],
  },
  pro: {
    name: "Pro",
    price: 49.99,
    features: [
      "API Builder - 8 projects",
      "API Builder Project - 25 maximum block per project",
      "Web App Builder - 2 projects",
      "10$ credit for third party ML model",
      "Access to a list of free ML models(level 2)",
    ],
  },
  enterprise: {
    name: "Enterprise",
    price: 99.99,
    features: [
      "API Builder - 20 projects",
      "API Builder Project - 50 maximum block per project",
      "Web App Builder - 5 projects",
      "20$ credit for third party ML model",
      "Access to a list of free ML models(level 2)",
      "Add your own ML model",
    ],
  },
  enterpriseUnlimited: {
    name: "Enterprise Unlimited",
    price: 199.99,
    features: [
      "API Builder - Unlimited projects",
      "API Builder Project - Unlimited maximum block per project",
      "Web App Builder - Unlimited projects",
      "50$ credit for third party ML model",
      "Access to a list of free ML models(level 2)",
      "Add your own ML model",
    ],
  },
};

const createWindowModal = (url, title) => {
  const width = 600;
  const height = 600;
  const left = window.screen.width / 2 - width / 2;
  const top = window.screen.height / 2 - height / 2;

  return window.open(
    url,
    title,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`
  );
};

const PlanDetails = ({ plan, show = false }) => {
  const [toggle, setToggle] = useState(show);

  return (
    <div>
      {!show && (
        <Button variant="link" onClick={() => setToggle(!toggle)}>
          View Current Plan Details
        </Button>
      )}
      {toggle && (
        <Card>
          <Card.Header>
            <h5>{planDetails[plan].name}</h5>
          </Card.Header>
          <Card.Body>
            <h6 className="text-center mb-3">
              <strong>${planDetails[plan].price}</strong> / month
            </h6>
            <ul>
              {planDetails[plan].features.map((feature, index) => (
                <li
                  key={index}
                  style={{ listStyleType: "none" }}
                  className="mb-3"
                >
                  {feature}
                </li>
              ))}
            </ul>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

const ModalPlansList = ({ onHide }) => {
  return (
    <Modal show={true} onHide={onHide} fullscreen>
      <Modal.Header closeButton>
        <Modal.Title>Plans</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          {Object.keys(planDetails).map((plan, index) => (
            <Col key={index} xs={12} md={6} lg={4}>
              <PlanDetails show={true} plan={plan} />
            </Col>
          ))}
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const CreditsPacks = ({ onBuyCredits }) => {
  const [creditsPacks, setCreditsPacks] = useState(null);

  useEffect(() => {
    DB.paidEntities
      .getList({
        field: "type",
        operator: "==",
        value: "modelsCredits",
      })
      .then((list) => {
        console.log("list", list);
        setCreditsPacks(list);
      });
  }, []);

  const getListSortedByPrice = () => {
    const list = [...creditsPacks];
    list.sort((a, b) => a.value - b.value);
    return list;
  };

  return (
    <div>
      <h5 className="mb-3">Credits Packs</h5>
      <Row>
        {creditsPacks &&
          getListSortedByPrice().map((creditsPack, index) => {
            // creditsPack => { id, value }
            const { value: price } = creditsPack;

            return (
              <Col key={index} xs={12} md={6} lg={4}>
                <Card>
                  <Card.Header>
                    <h5>${price} Credits</h5>
                  </Card.Header>
                  <Card.Footer className="text-center">
                    <Button
                      variant="primary"
                      onClick={() => onBuyCredits(creditsPack)}
                    >
                      Buy
                    </Button>
                  </Card.Footer>
                </Card>
              </Col>
            );
          })}
      </Row>
    </div>
  );
};

const apiKeyDataInfo = {
  openai: {
    name: "OpenAI",
    description: "API key for OpenAI",
    link: "https://platform.openai.com/account/api-keys",
    help: "Get your API key from OpenAI",
    metaFields: {
      organizationId: {
        name: "Organization ID",
        description: "Organization ID",
        type: "text",
        required: true,
        link: "https://platform.openai.com/organizations",
        help: "Get your Organization ID from OpenAI",
      },
    },
  },
};

const Account = () => {
  const [paymentWindowModalOpen, setPaymentWindowModalOpen] = useState(false);
  const [apiKeyModifying, setApiKeyModifying] = useState(false);
  const [plansListModalShow, setPlansListModalShow] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userFirebaseAccount, setUserFirebaseAccount] = useState(null);
  const [userAccount, setUserAccount] = useState(null);
  const [userAccountLastSaved, setUserAccountLastSaved] = useState(null);
  const [userPaymentLines, setUserPaymentLines] = useState(null);
  const [isUpdatingAccoun, setIsUpdatingAccount] = useState(false);
  const [isSendingToApi, setIsSendingToApi] = useState(false);
  const [isSendingToApiError, setIsSendingToApiError] = useState(false);
  const [isSendingToApiErrorMessage, setIsSendingToApiErrorMessage] =
    useState("");

  const userSubscription = userAccount?.subscription || {
    plan: "free",
    status: "active",
  };

  useEffect(() => {
    const firebaseUser = firebaseAuth.currentUser;
    const userId = firebaseUser.uid;
    setUserFirebaseAccount(firebaseUser);
    setUserId(userId);
  }, []);

  useEffect(() => {
    if (userId) {
      setIsSendingToApi(true);
      setIsSendingToApiError(false);
      setIsSendingToApiErrorMessage("");
      const subscriber = DB.userAccounts.get(userId, (userAccount) => {
        if (!userAccount) {
          createUserAccount();
        } else {
          setUserAccount(userAccount);
          setUserAccountLastSaved(userAccount);
          setIsSendingToApi(false);
        }
      });
      return subscriber;
    }
  }, [userId]);

  useEffect(() => {
    if (userAccount && userId) {
      console.log("userId", userId);
      setIsSendingToApi(true);
      setIsSendingToApiError(false);
      setIsSendingToApiErrorMessage("");
      const subscriber = DB.userAccounts.sub.paymentLines.list(
        userId,
        (userPaymentLines) => {
          setUserPaymentLines(userPaymentLines);
          setIsSendingToApi(false);
        },
        null,
        { field: "lineDateInit", direction: "desc" }
      );
      return subscriber;
    }
  }, [!!userAccount]);

  const createUserAccount = async () => {
    setIsSendingToApi(true);
    setIsSendingToApiError(false);
    setIsSendingToApiErrorMessage("");

    const apiKeys = Object.keys(apiKeyDataInfo).reduce((acc, apiKeyInfo) => {
      let meta = Object.keys(apiKeyDataInfo[apiKeyInfo].metaFields).reduce(
        (acc, metaField) => {
          acc[metaField] = "";
          return acc;
        },
        {}
      );
      acc[apiKeyInfo] = {
        key: "",
        meta: meta,
        disabled: false,
      };
      return acc;
    }, {});

    const docId = await DB.userAccounts.createWithId(userId, {
      uid: userId,
      id: userId,
      apiKeys: apiKeys,
    });
    console.log("docId", docId);
  };

  const updateUserAccount = async () => {
    setIsUpdatingAccount(true);
    let newUserAccount = { ...userAccount };
    delete newUserAccount.subscription;
    const currentApiKey = newUserAccount.apiKeys[apiKeyModifying];

    if (!!apiKeyModifying) {
      await saveApiKey({ ...currentApiKey });
    }
    let apiKeyHidden = "";
    if (currentApiKey) {
      const { key = "", meta = {} } = currentApiKey || {};
      apiKeyHidden = key.slice(0, 3) + "..." + key.slice(-3);
      newUserAccount.apiKeys = {
        ...newUserAccount.apiKeys,
        [apiKeyModifying]: {
          key: apiKeyHidden,
          meta: {
            ...meta,
          },
          disabled: currentApiKey.disabled || false,
        },
      };
    }

    await DB.userAccounts.update(userId, {
      ...newUserAccount,
    });
    setIsUpdatingAccount(false);
  };

  const submitAccountSave = async (e) => {
    e.preventDefault();
    updateUserAccount();
  };

  const saveApiKey = async (newApiKey) => {
    if (!apiKeyModifying) return;
    const userAccountSaved = { ...userAccountLastSaved };
    const lastApiKey = userAccountSaved.apiKeys[apiKeyModifying] || {};

    const { key = "", meta = {} } = newApiKey || {};
    const { key: lastKey = "" } = lastApiKey || {};

    // const isApiKeyChanged = lastSavedApiKey !== currentApiKey;
    const isMetaEqual = (() => {
      const currentMeta = newApiKey.meta || {};
      const lastMeta = lastApiKey.meta || {};
      return Object.keys(currentMeta).every(
        (metaKey) => currentMeta[metaKey] === lastMeta[metaKey]
      );
    })();

    const isApiKeyChanged = lastKey !== key || !isMetaEqual;

    const canChange = (() => {
      if (apiKeyModifying === "openai") {
        const { organizationId } = newApiKey.meta || {};
        return !!organizationId;
      } else if (!apiKeyModifying || key.length <= 10) {
        return false;
      }
      return true;
    })();

    if (isApiKeyChanged && canChange) {
      await DB.userAccounts.sub.apiKeys.createWithId(userId, apiKeyModifying, {
        id: apiKeyModifying,
        key: key,
        meta: meta,
      });
    }

    const lastDisable = lastApiKey.disabled || false;
    const currentDisable = newApiKey.disabled || false;

    if (lastDisable !== currentDisable) {
      await DB.userAccounts.sub.apiKeys.update(userId, apiKeyModifying, {
        disabled: currentDisable,
      });
    }
  };

  const buyCredits = async (creditPack) => {
    const { id } = creditPack;
    setPaymentWindowModalOpen(true);
    const stripeData = await payments.stripe.createCheckoutSession(id);
    const { url } = stripeData;
    // Redirect to Stripe Checkout url
    const windowModal = createWindowModal(url, "stripe-checkout");
    const timer = setInterval(() => {
      if (windowModal.closed) {
        clearInterval(timer);
        setPaymentWindowModalOpen(false);
      }
    }, 1000);
  };

  // const updateUserFirebaseAccount = async () => {
  //   setIsUpdatingAccount(true);

  const roundNumber = (number, decimals = 2) => {
    let savedDecimal = decimals;
    if (number < 0.01) {
      savedDecimal = 4;
    }
    return (
      Math.round((number + Number.EPSILON) * Math.pow(10, savedDecimal)) /
      Math.pow(10, savedDecimal)
    );
  };

  const totalUnpdaidCost = (paymentLines) => {
    const totalUnpaid = paymentLines.reduce((acc, paymentLine) => {
      return acc + paymentLine.requestTotalCost;
    }, 0);
    return roundNumber(totalUnpaid);
  };

  const subscriptionFormat = (subscription) => {
    if (!subscription) return "Free";
    if (subscription.plan === "free") {
      return "Free";
    } else if (subscription.plan === "basic") {
      return "Basic";
    } else if (subscription.plan === "pro") {
      return "Pro";
    } else if (subscription.plan === "enterprise") {
      return "Enterprise";
    } else {
      return "Free";
    }
  };

  const handleApiKeysChange_key = (e, apiKeyName) => {
    const key = e.target.value;
    const apiKey = userAccount.apiKeys[apiKeyName] || {};
    const { meta = {} } = apiKey;
    setUserAccount({
      ...userAccount,
      apiKeys: {
        ...userAccount.apiKeys,
        [apiKeyName]: {
          ...apiKey,
          key: key,
          meta: {
            ...meta,
          },
        },
      },
    });
  };

  const handleApiKeysChange_meta = (e, apiKeyName, metaFieldName) => {
    const metaFieldValue = e.target.value;
    const apiKey = userAccount.apiKeys[apiKeyName] || {};
    const { meta = {} } = apiKey;
    setUserAccount({
      ...userAccount,
      apiKeys: {
        ...userAccount.apiKeys,
        [apiKeyName]: {
          ...apiKey,
          meta: {
            ...meta,
            [metaFieldName]: metaFieldValue,
          },
        },
      },
    });
  };

  const handleApiKeyDisable = (apiKeyName) => {
    const apiKey = userAccount.apiKeys[apiKeyName] || {};
    const { disabled = false } = apiKey;
    setUserAccount({
      ...userAccount,
      apiKeys: {
        ...userAccount.apiKeys,
        [apiKeyName]: {
          ...apiKey,
          disabled: !disabled,
        },
      },
    });
  };

  const unpaidForModelRequestTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Unpaid for model requests are requests that have been made using our own
      third part services(OpenAI) but have not been paid for yet. They are not
      included in your monthly subscription. To avoid this, you can use your own
      API keys for the services you want to use.
    </Tooltip>
  );

  const modelsCreditsUsageTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Model credits are used for requests made using our own third part
      services(OpenAI). They are not included in your monthly
      subscription(except for the Basic higher tier). To use your own API keys
      for the services you want to use, you can use your own API keys for the
      services you want to use.
    </Tooltip>
  );

  const formatCredits = (credit) => {
    if (!credit) return "$0";
    if (credit < 0) {
      return `-$${Math.abs(roundNumber(credit, 2))}`;
    }
    return `$${roundNumber(credit)}`;
  };

  return (
    <Container>
      {paymentWindowModalOpen && (
        <div className="payment-window-modal d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status" />
        </div>
      )}
      <Row>
        <Col>
          <h1>Account</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Account</Card.Title>
              <Row>
                {isSendingToApi && (
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                )}
                {isSendingToApiError && (
                  <Alert variant="danger">{isSendingToApiErrorMessage}</Alert>
                )}
                {userAccount && userFirebaseAccount && (
                  <Form onSubmit={submitAccountSave}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={userFirebaseAccount.email}
                        onChange={(e) => {
                          setUserFirebaseAccount({
                            ...userFirebaseAccount,
                            email: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        value="********"
                        disabled
                      />
                    </Form.Group>
                    <hr />
                    {/* Api key configuration */}
                    <h6>API Keys</h6>
                    {Object.keys(userAccount.apiKeys).map((apiKeyName) => {
                      const isModifying = apiKeyModifying === apiKeyName;
                      const apiKey = userAccount.apiKeys[apiKeyName];
                      const apiKeyInfo = apiKeyDataInfo[apiKeyName];
                      console.log("apiKeyInfo", apiKeyInfo);
                      const { metaFields } = apiKeyInfo;
                      console.log("metaFields", metaFields);

                      let formProps = {};
                      if (!isModifying) {
                        formProps = {
                          disabled: true,
                          value: "********",
                        };
                      } else {
                        formProps = {
                          value: apiKey.key || "",
                        };
                      }

                      return (
                        <div key={apiKeyName}>
                          <Form.Group controlId={`apiKey${apiKeyName}`}>
                            <Form.Label>
                              {apiKeyInfo.name}

                              {/* Disabled checkbox */}
                              <Form.Check
                                className="ms-3"
                                inline
                                disabled={!isModifying}
                                type="checkbox"
                                label="Disabled"
                                checked={apiKey.disabled || false}
                                onChange={() => {
                                  handleApiKeyDisable(apiKeyName);
                                }}
                              />
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="API Key"
                              {...formProps}
                              onChange={(e) => {
                                handleApiKeysChange_key(e, apiKeyName);
                              }}
                            />
                          </Form.Group>
                          {Object.keys(metaFields).map((metaFieldKeyName) => {
                            const metaField =
                              metaFields[metaFieldKeyName] || {};
                            const meta =
                              userAccount.apiKeys[apiKeyName].meta || {};
                            const metaFieldValue = meta[metaFieldKeyName] || "";

                            return (
                              <Form.Group
                                controlId={`apiKey${apiKeyName}${metaFieldKeyName}`}
                                key={metaFieldKeyName}
                              >
                                <Form.Label>{metaField.name}</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder={metaField.placeholder}
                                  value={
                                    isModifying ? metaFieldValue : "********"
                                  }
                                  disabled={!isModifying}
                                  onChange={(e) => {
                                    handleApiKeysChange_meta(
                                      e,
                                      apiKeyName,
                                      metaFieldKeyName
                                    );
                                  }}
                                />
                              </Form.Group>
                            );
                          })}

                          <div>
                            <Button
                              className="ps-0"
                              variant="link"
                              onClick={() => {
                                setApiKeyModifying(
                                  !isModifying ? apiKeyName : ""
                                );
                              }}
                            >
                              {!isModifying &&
                                (apiKey.length > 0 ? "Change" : "Add")}
                              {isModifying && "Hide"} {apiKeyInfo.name} API Key
                            </Button>
                          </div>
                          <Form.Text className="text-muted">
                            {apiKeyInfo.description} <br />
                            {apiKeyInfo.help}{" "}
                            <a href={apiKeyInfo.link} target="_blank">
                              here
                            </a>
                            .
                            <br />
                          </Form.Text>
                        </div>
                      );
                    })}
                    <div className="d-flex justify-content-end mt-3">
                      <Button variant="primary" type="submit">
                        Save
                      </Button>
                    </div>
                  </Form>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        {/* Subscription */}
        <Col xs={6}>
          <Card>
            <Card.Body>
              <Card.Title className="fs-1">Subscription</Card.Title>
              <Row>
                {isSendingToApi && (
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                )}
                {isSendingToApiError && (
                  <Alert variant="danger">{isSendingToApiErrorMessage}</Alert>
                )}
                {userAccount && (
                  <div>
                    <h1 className="fs-2 text-gray-600">
                      {subscriptionFormat(userSubscription)}{" "}
                      {/* <Button variant="link">Change Plan</Button> */}
                    </h1>
                    {/* Information on current plan, features list etc... toggle link */}
                    <div>
                      <PlanDetails show={true} plan={userSubscription.plan} />
                    </div>
                    <div>
                      {/* Button to see all plan modal */}
                      <Button
                        variant="link"
                        onClick={() => setPlansListModalShow(true)}
                      >
                        View All Plans
                      </Button>
                      {plansListModalShow && (
                        <ModalPlansList
                          onHide={() => setPlansListModalShow(false)}
                        />
                      )}
                    </div>
                  </div>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>

        {/* User Credits */}
        <Col xs={6}>
          <Card>
            <Card.Body>
              <Card.Title className="fs-1">
                Credits
                <OverlayTrigger
                  placement="top"
                  overlay={modelsCreditsUsageTooltip}
                >
                  <span className="ms-2">
                    <i
                      className="bi bi-question-circle fs-6"
                      style={{ position: "relative", top: "-3px" }}
                    ></i>
                  </span>
                </OverlayTrigger>
              </Card.Title>
              <Row>
                {isSendingToApi && (
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                )}
                {isSendingToApiError && (
                  <Alert variant="danger">{isSendingToApiErrorMessage}</Alert>
                )}
                {userAccount && (
                  <div>
                    <h1 className="fs-2 text-gray-600">
                      {formatCredits(userAccount.credits)}{" "}
                    </h1>
                    <CreditsPacks onBuyCredits={buyCredits} />
                  </div>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>

        {/* Usage */}
        {/* <Col xs={6}>
          <Card>
            <Card.Body>
              <Card.Title className="fs-1">
                Unpaid For Model Requests{" "}
                <OverlayTrigger
                  placement="top"
                  overlay={unpaidForModelRequestTooltip}
                >
                  <i className="fas fs-5 fa-question-circle"></i>
                </OverlayTrigger>
              </Card.Title>
              <Row>
                {isSendingToApi && (
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                )}
                {isSendingToApiError && (
                  <Alert variant="danger">{isSendingToApiErrorMessage}</Alert>
                )}
                {userPaymentLines && (
                  <h1 className="fs-2 text-gray-600">
                    {totalUnpdaidCost(userPaymentLines)}$
                  </h1>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col> */}
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Request history</Card.Title>
              <Row>
                {isSendingToApi && (
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                )}
                {isSendingToApiError && (
                  <Alert variant="danger">{isSendingToApiErrorMessage}</Alert>
                )}
                {userPaymentLines && (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Model</th>
                        <th>Price</th>
                        <th>Cost</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userPaymentLines.map((userPaymentLine) => {
                        const formatedDateInit = DateTime.fromMillis(
                          userPaymentLine.lineDateInit
                        ).toFormat("dd/MM/yyyy HH:mm:ss");

                        return (
                          <tr key={userPaymentLine.id}>
                            <td>{formatedDateInit}</td>
                            <td>{userPaymentLine.requestUsedModel}</td>
                            <td>
                              Prompt:{" "}
                              {userPaymentLine.requestModelTokenPrice.prompt}${" "}
                              <br />
                              Completion:{" "}
                              {
                                userPaymentLine.requestModelTokenPrice
                                  .completion
                              }
                              $
                            </td>
                            <td>
                              Total:{" "}
                              {roundNumber(userPaymentLine.requestTotalCost)}$
                              <br />
                              Prompt:{" "}
                              {roundNumber(userPaymentLine.requestPromptCost)}$
                              ({userPaymentLine.requestConsumedPromptTokens}{" "}
                              Tokens)
                              <br />
                              Completion:{" "}
                              {roundNumber(
                                userPaymentLine.requestCompletionCost
                              )}
                              $ (
                              {userPaymentLine.requestConsumedCompletionTokens}{" "}
                              Tokens)
                            </td>
                            <td>{userPaymentLine.status || "Pending"}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Account;
