import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { Form, Button, Dropdown, Modal } from "react-bootstrap";
import {
  GlobalContext,
  // getNewModelPrompt,
} from "../../../contexts/GlobalContext";

const PromptsSelectorDropdown = ({
  instructionsMode,
  onPromptChange,
  file,
}) => {
  const { globalState, setGlobalState } = useContext(GlobalContext);
  const { modelPrompts } = globalState;
  const { completePrompts, editPrompts, insertPrompts } = modelPrompts;
  const [modelPromptsName, setModelPromptsName] = useState("");
  const [showModalName, setShowModalName] = useState(false);
  const [currentList, setCurrentList] = useState([]);

  useEffect(() => {
    setCurrentList(
      instructionsMode === "complete"
        ? completePrompts
        : instructionsMode === "edit"
        ? editPrompts
        : insertPrompts
    );
  }, [instructionsMode, modelPrompts]);

  const handlePromptChange = (prompt) => {
    onPromptChange(prompt);
  };

  const saveModelPrompt = () => {
    const instructionToSave = file.gptInstructions;
    // const promptToSave = getNewModelPrompt({
    //   prompt: instructionToSave,
    //   name: modelPromptsName,
    // });
    const promptToSave = {};
    setGlobalState({
      ...globalState,
      modelPrompts: {
        ...modelPrompts,
        [instructionsMode + "Prompts"]: [...currentList, promptToSave],
      },
    });
    setShowModalName(false);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle variant="link" id="dropdown-basic">
        Models
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => setShowModalName(true)}>
          Save model
        </Dropdown.Item>
        <Dropdown.Divider />
        {currentList.map((prompt, index) => (
          <Dropdown.Item
            key={prompt.id}
            value={prompt}
            onClick={() => handlePromptChange(prompt)}
          >
            {prompt.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>

      <Modal show={showModalName} onHide={() => setShowModalName(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Save model</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Model name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter model name"
                value={modelPromptsName}
                onChange={(e) => setModelPromptsName(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModalName(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={saveModelPrompt}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Dropdown>
  );
};

export default PromptsSelectorDropdown;
