import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Spinner,
  Form,
  ListGroup,
  Badge,
  FormControl,
} from "react-bootstrap";

/**
 * @typedef DropdownGroupedByAndAutoComplete - The DropdownGroupedByAndAutoComplete object.
 * @description The DropdownGroupedByAndAutoComplete component.
 *  It allows to select an item and search an item by name, group and tags.
 *  It will show the items grouped by group. And a default group "Others" for the items without group.
 *  It will show the items with the same group in the same order as the items array.
 *  Tags will be shown in the dropdown in small badge, right after the label.
 *  It's not able to select multiple items or entering a custom value.
 *  When press enter on the search input, it will select the first item of the list.
 *
 * @property {[object]} items - The items of the DropdownGroupedByAndAutoComplete.
 * @property {string} items[].label - The label of the DropdownGroupedByAndAutoComplete.
 * @property {string} items[].value - The value of the DropdownGroupedByAndAutoComplete.
 * @property {string} items[].group - The group of the DropdownGroupedByAndAutoComplete.
 * @property {string} items[].tags - The group of the DropdownGroupedByAndAutoComplete.
 * @property {string} items[].key - The key of the DropdownGroupedByAndAutoComplete.
 * @property {string} value - The value of the DropdownGroupedByAndAutoComplete.
 * @property {function} onChange - The onChange of the DropdownGroupedByAndAutoComplete.
 * @property {string} placeholder - The placeholder of the DropdownGroupedByAndAutoComplete.
 *
 * @example
 * const items = [
 * {
 *  label: "Item 1",
 * value: "item1",
 * group: "Group 1",
 * tags: ["tag1", "tag2"],
 * key: "item1",
 * },
 * {
 * label: "Item 2",
 * value: "item2",
 * group: "Group 1",
 * tags: ["tag1", "tag2"],
 *
 * key: "item2",
 * },
 * ]
 *
 * const value = "item1"
 *
 * const onChange = (value) => {
 * console.log(value)
 * }
 *
 * const placeholder = "Select an item"
 *
 * const checkboxValues = [
 *   { key: "model", label: "GPT-4", value: "gpt-4" },
 *   { key: "model", label: "GPT-3.5 Turbo", value: "gpt-3.5-turbo" },
 *   { key: "model", label: "GPT-3", value: "text-davinci-003" },
 *   { key: "model", label: "Codex", value: "code-davinci-002" },
 * ];
 *
 * <DropdownGroupedByAndAutoComplete
 * checkboxValues={checkboxValues}
 * items={items}
 * value={value}
 * onChange={onChange}
 * placeholder={placeholder}
 * />
 */

/**
 * @typedef Item
 * @property {string} label - The label of the DropdownGroupedByAndAutoComplete.
 * @property {string} value - The value of the DropdownGroupedByAndAutoComplete.
 * @property {string} group - The group of the DropdownGroupedByAndAutoComplete.
 * @property {string} tags - The group of the DropdownGroupedByAndAutoComplete.
 * @property {string} key - The key of the DropdownGroupedByAndAutoComplete.
 * @property {object} filterValues - The filterValues of the DropdownGroupedByAndAutoComplete.
 */

/**
 * @typedef CheckboxValue
 * @property {string} key - The key of the CheckboxValue.
 * @property {string} label - The label of the CheckboxValue.
 * @property {any} value - The value of the CheckboxValue.
 * @property {string} key - The key of the CheckboxValue.
 */

/**
 * @typedef FilterValues
 * @property {{[key: string]: any}} filterValues - The filterValues of the DropdownGroupedByAndAutoComplete.
 */

/**
 * @typedef {Object} DropdownGroupedByAndAutoCompleteProps
 * @property {[Item]} items - The items of the DropdownGroupedByAndAutoComplete.
 * @property {string} value - The value of the DropdownGroupedByAndAutoComplete.
 * @property {function} onChange - The onChange of the DropdownGroupedByAndAutoComplete.
 * @property {string} valueLabel - The valueLabel of the DropdownGroupedByAndAutoComplete.
 * @property {string} placeholder - The placeholder of the DropdownGroupedByAndAutoComplete.
 * @property {[CheckboxValue]} checkboxValues - The checkboxValues of the DropdownGroupedByAndAutoComplete.
 * @property {function} groupSortFunction - The groupSortFunction of the DropdownGroupedByAndAutoComplete.
 */

const DropdownGroupedByAndAutoComplete = ({
  items,
  value,
  onChange,
  valueLabel,
  placeholder,
  checkboxValues = [],
  groupSortFunction,
}) => {
  const searchInputRef = React.useRef(null);
  const parentRef = React.useRef(null);
  const [highlightedIndex, setHighlightedIndex] = useState(null);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [boxsChecked, setBoxsChecked] = useState({});

  const [itemsFiltered, setItemsFiltered] = useState(items);
  const [itemsGrouped, setItemsGrouped] = useState([]);

  useEffect(() => {
    const newItems = [...items];
    newItems.sort(groupSortFunction);
    setItemsFiltered(newItems);
  }, [items]);

  useEffect(() => {
    const boxsCheckedKeys = Object.keys(boxsChecked).filter(
      (key) => boxsChecked[key]
    );

    const itemsGrouped = itemsFiltered.reduce((acc, item) => {
      const { group, filterValues } = item;

      if (boxsCheckedKeys.length > 0) {
        const valueKeys = Object.keys(filterValues).map(
          (key) => `${key}:${filterValues[key]}`
        );
        const isItemChecked = valueKeys.some((valueKey) =>
          boxsCheckedKeys.includes(valueKey)
        );
        if (!isItemChecked) {
          return acc;
        }
      }

      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(item);
      return acc;
    }, {});

    if (Object.keys(itemsGrouped).length > 0) {
      setHighlightedIndex(0);
    } else {
      setHighlightedIndex(null);
    }

    setItemsGrouped(itemsGrouped);
  }, [itemsFiltered, boxsChecked]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (parentRef.current && !parentRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [show]);

  const getGroupedItemsToList = () => {
    const itemsGroupedToList = Object.keys(itemsGrouped).reduce(
      (acc, group) => {
        const items = itemsGrouped[group];
        acc = [...acc, ...items];
        return acc;
      },
      []
    );

    return itemsGroupedToList;
  };

  const handleOnKeyDown = (e) => {
    const currentHighlightedIndex = highlightedIndex || 0;
    if (e.key === "Enter") {
      const item = itemsFiltered[highlightedIndex];
      if (item) {
        handleSelect(item);
      }
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      if (!highlightedIndex && highlightedIndex !== 0) {
        setHighlightedIndex(0);
        return;
      }

      const nextHighlightedIndex =
        currentHighlightedIndex + 1 >= itemsFiltered.length
          ? currentHighlightedIndex
          : currentHighlightedIndex + 1;
      setHighlightedIndex(nextHighlightedIndex);
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      if (!highlightedIndex && highlightedIndex !== 0) {
        setHighlightedIndex(0);
        return;
      }

      const nextHighlightedIndex =
        currentHighlightedIndex - 1 < 0 ? 0 : currentHighlightedIndex - 1;
      setHighlightedIndex(nextHighlightedIndex);
    }
  };

  const handleSearch = (e) => {
    const search = e.target.value;
    setSearch(search);

    const itemsFiltered = items.filter((item) => {
      const { label, tags = [], group } = item;
      const searchLowerCase = search.toLowerCase();
      const labelLowerCase = label.toLowerCase();

      const isLabelMatching = labelLowerCase.includes(searchLowerCase);

      const isTagMatching = tags.some((tag) => {
        const tagLowerCase = tag.toLowerCase();
        return tagLowerCase.includes(searchLowerCase);
      });

      // search for group
      const isGroupMatching = group.toLowerCase().includes(searchLowerCase);
      // return isLabelMatching || isTagMatching;

      return isLabelMatching || isTagMatching || isGroupMatching;
    });

    setItemsFiltered(itemsFiltered);
  };

  const handleSelect = (item) => {
    const { value, label } = item;
    onChange(value);
    setSearch("");
    setShow(false);
  };

  const handleToggle = () => {
    setShow(!show);
    if (!show) {
      setTimeout(() => {
        searchInputRef.current.focus();
      }, 0);
    }
  };

  const itemsGroupedKeys = Object.keys(itemsGrouped);
  const itemsGroupedToList = getGroupedItemsToList();

  // Reverse itemsGroupedKeys to show the last group first
  // itemsGroupedKeys.reverse();

  const handleClear = () => {
    onChange(null);
    setSearch("");
  };

  const drowpDownProps = {};

  if (!show) {
    drowpDownProps.style = {
      display: "none",
    };
  }

  return (
    <div ref={parentRef}>
      <FormControl
        placeholder={placeholder}
        value={valueLabel}
        onChange={() => {}}
        onClick={handleToggle}
      />

      <ListGroup
        className="dropdown-grouped-by-and-auto-complete"
        {...drowpDownProps}
      >
        <ListGroup.Item>
          <FormControl
            ref={searchInputRef}
            placeholder={placeholder}
            value={search}
            onChange={handleSearch}
            onClick={handleToggle}
            onKeyDown={handleOnKeyDown}
          />
        </ListGroup.Item>

        {checkboxValues && checkboxValues.length > 0 && (
          <ListGroup.Item>
            <div className="d-flex flex-wrap">
              {checkboxValues.map((checkboxValue) => {
                const { key, label, value } = checkboxValue;
                const keyValue = `${key}:${value}`;
                const isChecked = boxsChecked[keyValue];
                return (
                  <Form.Check
                    inline
                    key={keyValue}
                    type="checkbox"
                    label={label}
                    checked={isChecked}
                    onChange={() => {
                      setBoxsChecked({
                        ...boxsChecked,
                        [keyValue]: !isChecked,
                      });
                    }}
                  />
                );
              })}
            </div>
          </ListGroup.Item>
        )}

        {show &&
          itemsGroupedKeys.map((groupKey) => {
            const itemsGroup = itemsGrouped[groupKey];
            return (
              <React.Fragment key={groupKey}>
                <ListGroup.Item className="built-prompt-group-name">
                  {groupKey}
                </ListGroup.Item>
                {itemsGroup.map((item, index) => {
                  const { key, label, tags = [] } = item;
                  const isItemSelected =
                    highlightedIndex >= 0 &&
                    itemsGroupedToList[highlightedIndex]
                      ? itemsGroupedToList[highlightedIndex].value ===
                        item.value
                      : false;
                  return (
                    <ListGroup.Item
                      action
                      key={key}
                      active={isItemSelected}
                      onClick={() => handleSelect(item)}
                    >
                      {label}
                      {tags.map((tag, i) => (
                        <Badge bg="secondary" className="ms-1" key={tag}>
                          {tag}
                        </Badge>
                      ))}
                    </ListGroup.Item>
                  );
                })}
              </React.Fragment>
            );
          })}
      </ListGroup>
    </div>
  );
};

export default DropdownGroupedByAndAutoComplete;
