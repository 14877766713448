import React, { createContext, useContext, useEffect, useState } from "react";
import { GlobalContext } from "./GlobalContext";
import DB from "../database/DB";
import SystemBuilderUtils from "../components/pages/ApiBuilder/utils";
import { ApiBuilderContext } from "./ApiBuilderContext";
import { SystemBuilderCrud } from "./SystemBuilderCrud";

/**
 * ChainContext
 * @description
 * This context is used to provide the chain data to the ChainBuilder component.
 */

export const ChainContext = createContext();

export const ChainProvider = ({ chainId, children }) => {
  const { uid } = useContext(GlobalContext);
  const { id: apiProjectId, apiProject } = useContext(ApiBuilderContext);
  const [chain, setChain] = useState(null);
  const [emitters, setEmitters] = useState([]);
  const [inputConnections, setConnections] = useState([]);
  const [dataSets, setDataSets] = useState([]);

  useEffect(() => {
    if (!chainId || !apiProject) {
      setChain(null);
      return;
    }
    // Fetch apiProjects document from Firestore
    const unsubscribe = DB.apiProjects.sub.chains.get(
      apiProjectId,
      chainId,
      (chain) => {
        setChain(chain);
      }
    );
    return unsubscribe;
  }, [chainId, apiProject]);

  useEffect(() => {
    if (chain && apiProject) {
      const unsubscribe = DB.apiProjects.sub.chains.sub.emitters.list(
        apiProject.id,
        chain.id,
        (emitters) => {
          setEmitters(emitters);
        },
        null,
        null,
        null,
        {
          isFromShared: apiProject.uid === uid ? false : true
        }
      );
      return unsubscribe;
    }
  }, [chain, apiProject]);

  useEffect(() => {
    if (chain && apiProject) {
      const unsubscribe = DB.apiProjects.sub.chains.sub.inputConnections.list(
        apiProject.id,
        chain.id,
        (inputConnections) => {
          setConnections(inputConnections);
        },
        null,
        null,
        null,
        {
          isFromShared: apiProject.uid === uid ? false : true
        }
      );
      return unsubscribe;
    }
  }, [chain, apiProject]);

  if (!chain || !apiProject) {
    return null;
  }

  const chainActions = {
    createGlobalParameter: async (globalParameter) => {
      return await SystemBuilderCrud.chain.createGlobalParameter(
        apiProject,
        chain,
        uid,
        globalParameter
      );
    },
    updateGlobalParameters: async (globalParameters) => {
      return await SystemBuilderCrud.chain.updateGlobalParameters(
        apiProject,
        chain,
        uid,
        globalParameters
      );
    },
    createPromptBlock: async (promptBlock) => {
      return await SystemBuilderCrud.chain.createPromptBlock(
        apiProject,
        chain,
        uid,
        promptBlock
      );
    },
    updatePromptBlocks: async (promptBlocks) => {
      return await SystemBuilderCrud.chain.updatePromptBlocks(
        apiProject,
        chain,
        uid,
        promptBlocks
      );
    },
    updatePromptBlock: async (promptBlock) => {
      return await SystemBuilderCrud.chain.updatePromptBlock(
        apiProject,
        chain,
        uid,
        promptBlock
      );
    },
    createInputBlock: async (inputBlock) => {
      return await SystemBuilderCrud.chain.createInputBlock(
        apiProject,
        chain,
        uid,
        inputBlock
      );
    },
    updateInputBlocks: async (inputBlocks) => {
      return await SystemBuilderCrud.chain.updateInputBlocks(
        apiProject,
        chain,
        uid,
        inputBlocks
      );
    },
    updateInputBlock: async (inputBlock) => {
      return await SystemBuilderCrud.chain.updateInputBlock(
        apiProject,
        chain,
        uid,
        inputBlock
      );
    },
    update: async (newChain) => {
      return await SystemBuilderCrud.chain.update(
        apiProject,
        chain,
        uid,
        newChain
      );
    },
    createEmitter: async (emitter) => {
      return await SystemBuilderCrud.emitter.create(
        apiProject,
        chain,
        uid,
        emitter
      );
    },
    updateEmitter: async (emitter) => {
      return await SystemBuilderCrud.emitter.update(
        apiProject,
        chain,
        uid,
        emitter
      );
    },
    createInputConnection: async (inputConnection) => {
      return await SystemBuilderCrud.inputConnection.create(
        apiProject,
        chain,
        uid,
        inputConnection
      );
    },
    updateInputConnection: async (inputConnection) => {
      return await SystemBuilderCrud.inputConnection.update(
        apiProject,
        chain,
        uid,
        inputConnection
      );
    },
    createDataObject: async (dataObject) => {
      return await SystemBuilderCrud.chain.createDataObject(
        apiProject,
        chain,
        uid,
        dataObject
      );
    },
    updateDataObjects: async (dataObjects) => {
      return await SystemBuilderCrud.chain.updateDataObjects(
        apiProject,
        chain,
        uid,
        dataObjects
      );
    },
    updateDataObject: async (dataObject) => {
      return await SystemBuilderCrud.chain.updateDataObject(
        apiProject,
        chain,
        uid,
        dataObject
      );
    }
  };

  const inputs = SystemBuilderUtils.getInputs(
    chain.promptBlocks || [],
    inputConnections,
    emitters,
    chain.globalParameters || [],
    chain.inputBlocks || []
  );
  const outputs = SystemBuilderUtils.getOutputs(
    chain.promptBlocks || [],
    inputConnections,
    emitters
  );

  const value = {
    id: chainId,
    chain,
    emitters,
    inputConnections,
    // dataSets,
    promptBlocks: chain ? chain.promptBlocks || [] : [],
    inputBlocks: chain ? chain.inputBlocks || [] : [],
    dataObjects: chain ? chain.dataObjects || [] : [],
    inputs,
    outputs,
    chainActions
  };

  return (
    <ChainContext.Provider value={value}>{children}</ChainContext.Provider>
  );
};
