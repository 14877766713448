// const _colours = document.querySelectorAll('.colours > *')
// const colours = []
const colourSpaces = ["lab", "hsl", "rgb"];
const colourDiffs = {
  lab: colourDiffLab,
  hsl: colourDiffHsl,
  rgb: colourDiffRgb,
};

// Array.from(_colours).forEach(_colour => {
//   _colour.style.color = _colour.dataset.colour
//   colours.push(_colour.dataset.colour)
// })

// colourSpaces.forEach(colourSpace => {
//   observeThreshold(colourSpace)
//   groupSimilarColours(colourSpace)
// })

// function observeThreshold(colourSpace) {
//   const _el = document.querySelector(`.results.${colourSpace}`)
//   const _input = _el.querySelector('input')

//   _input.addEventListener('change', e => {
//     const _colours = _el.querySelectorAll('.results-colours > .colour')

//     Array.from(_colours).forEach(_colour => _colour.remove())

//     groupSimilarColours(colourSpace)
//   })
// }

export const isPixelsSimilar = (
  pixel1,
  pixel2,
  space = "lab",
  threshold = 0.1
) => {
  const diff = colourDiffs[space](pixel1, pixel2);

  return diff < threshold;
};

export function groupSimilarColours(colourSpace, colours, threshold = 0.1) {
  // const reducedColours = {};
  console.log("colours", colours);

  let availableColours = colours.slice(); // Duplicate the array so it can be modified
  // return {};

  // while (availableColours.length > 0) {
  //   const colour = availableColours[0];

  //   reducedColours[colour] = [colour];

  //   availableColours.forEach((otherColour) => {
  //     if (colour === otherColour) return;

  //     const diff = colourDiffs[colourSpace](colour, otherColour);

  //     if (diff < threshold) {
  //       reducedColours[colour].push(otherColour);
  //     }
  //   });

  //   // Remove the matched colours from the available colours so that
  //   // they aren't matched again
  //   availableColours = availableColours.filter(
  //     (x) => !reducedColours[colour].includes(x)
  //   );
  // }

  // replace the loop by a real reduce function
  const reducedColours = availableColours.reduce((acc, colour) => {
    if (!acc[colour]) {
      acc[colour] = [colour];
    }

    availableColours.forEach((otherColour) => {
      if (colour === otherColour) return;

      const diff = colourDiffs[colourSpace](colour, otherColour);

      if (diff < threshold) {
        acc[colour].push(otherColour);
      }
    });

    // Remove the matched colours from the available colours so that
    // they aren't matched again
    availableColours = availableColours.filter((x) => !acc[colour].includes(x));

    return acc;
  }, {});

  const sorted = Object.keys(reducedColours); // No sorting for now
  return {
    reduced: reducedColours,
    sorted: sorted,
  };
}

// HELPERS

function colourDiffLab(colour1, colour2) {
  var lab1 = parseColour(colour1).lab;
  var lab2 = parseColour(colour2).lab;

  return deltaE(lab1, lab2) / 100;
}

function colourDiffHsl(colour1, colour2) {
  var distance = 0;

  var hsl1 = parseColour(colour1).hsl;
  var hsl2 = parseColour(colour2).hsl;

  hsl1.forEach((_, i) => {
    distance += (hsl1[i] - hsl2[i]) * (hsl1[i] - hsl2[i]);
  });

  return Math.sqrt(distance);
}

function colourDiffRgb(colour1, colour2) {
  var rgb1 = parseColour(colour1).rgb;
  var rgb2 = parseColour(colour2).rgb;

  var r = 255 - Math.abs(rgb1[0] - rgb2[0]);
  var g = 255 - Math.abs(rgb1[1] - rgb2[1]);
  var b = 255 - Math.abs(rgb1[2] - rgb2[2]);

  // Scale diffs to between 0 and 1
  r /= 255;
  g /= 255;
  b /= 255;

  return 1 - (r + g + b) / 3;
}

function parseColour(colour) {
  var rgb, hsl, lab;

  colour = colour.toLowerCase();

  if (colour.indexOf("#") == 0) {
    rgb = parseHexColour(colour);
  } else {
    rgb = parseRgbColour(colour);
  }

  hsl = rgbToHsl.apply(this, rgb);
  lab = rgbToLab.apply(this, rgb);

  return {
    rgb: rgb,
    hsl: hsl,
    lab: lab,
  };
}

function parseHexColour(colour) {
  var hex = parseInt(colour.substring(1), 16);

  var red = (hex & 0xff0000) >> 16;
  var green = (hex & 0x00ff00) >> 8;
  var blue = hex & 0x0000ff;

  return [red, green, blue];
}

function parseRgbColour(colour) {
  var match = colour.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

  var red = parseInt(match[1]);
  var green = parseInt(match[2]);
  var blue = parseInt(match[3]);

  return [red, green, blue];
}

function rgbToHsl(r, g, b) {
  // (r /= 255), (g /= 255), (b /= 255);
  r /= 255;
  g /= 255;
  b /= 255;

  var max = Math.max(r, g, b);
  var min = Math.min(r, g, b);
  var h,
    s,
    l = (max + min) / 2;

  if (max == min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }

    h /= 6;
  }

  return [h, s, l];
}

function rgbToLab(r, g, b) {
  r /= 255;
  g /= 255;
  b /= 255;

  var x, y, z;

  r = r > 0.04045 ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
  g = g > 0.04045 ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
  b = b > 0.04045 ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;

  x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
  y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.0;
  z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;

  x = x > 0.008856 ? Math.pow(x, 1 / 3) : 7.787 * x + 16 / 116;
  y = y > 0.008856 ? Math.pow(y, 1 / 3) : 7.787 * y + 16 / 116;
  z = z > 0.008856 ? Math.pow(z, 1 / 3) : 7.787 * z + 16 / 116;

  return [116 * y - 16, 500 * (x - y), 200 * (y - z)];
}

// Perceptual distance between colors in CIELAB
function deltaE(labA, labB) {
  var deltaL = labA[0] - labB[0];
  var deltaA = labA[1] - labB[1];
  var deltaB = labA[2] - labB[2];
  var c1 = Math.sqrt(labA[1] * labA[1] + labA[2] * labA[2]);
  var c2 = Math.sqrt(labB[1] * labB[1] + labB[2] * labB[2]);
  var deltaC = c1 - c2;
  var deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC;
  deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH);
  var sc = 1.0 + 0.045 * c1;
  var sh = 1.0 + 0.015 * c1;
  var deltaLKlsl = deltaL / 1.0;
  var deltaCkcsc = deltaC / sc;
  var deltaHkhsh = deltaH / sh;
  var i =
    deltaLKlsl * deltaLKlsl + deltaCkcsc * deltaCkcsc + deltaHkhsh * deltaHkhsh;
  return i < 0 ? 0 : Math.sqrt(i);
}
