import React from "react";

const generatePostmanCollection = (deployment, creaxysEndpoints = []) => {
  const { readableApiMap } = deployment;

  const postmanCollection = {
    info: {
      name: deployment.name,
      schema:
        "https://schema.getpostman.com/json/collection/v2.1.0/collection.json"
    },
    item: []
  };

  // Ajouter un dossier pour creaxysEndpoints
  const creaxysFolder = {
    name: "Creaxys Endpoints",
    item: creaxysEndpoints.map((endpoint) => ({
      name: endpoint.name,
      request: {
        method: endpoint.method,
        url: endpoint.url, // Assurez-vous que l'URL est complète ou ajustez-la ici
        header: endpoint.headers,
        body: {
          mode: "raw",
          raw: JSON.stringify(endpoint.body || {}, null, 2),
          options: {
            raw: {
              language: "json"
            }
          }
        }
      }
    }))
  };

  postmanCollection.item.push(creaxysFolder);

  // if deployment.name include "API" then apiName = deployment.name else apiName = deployment.name + " API"
  const apiName = deployment.name.toUpperCase().includes("API")
    ? deployment.name
    : `${deployment.name} API`;

  // Ajouter un dossier pour readableApiMap
  const apiMapFolder = {
    name: `${apiName} Endpoints`,
    item: []
  };

  // if is in production then host = "https://cx0.io" else host = "http://localhost:8080"
  const host =
    process.env.NODE_ENV === "production"
      ? "https://cx0.io"
      : "http://localhost:8000";

  const baseUrl = `${host}/${deployment.id}/pb`;

  const transformBody = (body) => {
    if (body && typeof body === "object") {
      return Object.keys(body).reduce((acc, key) => {
        acc[key] = body[key].type || "unknown type";
        return acc;
      }, {});
    }
    return {};
  };

  Object.keys(readableApiMap).forEach((key) => {
    const endpoint = readableApiMap[key];
    if (endpoint.method || endpoint.type === "endpoint") {
      const body = transformBody(endpoint.body);

      const headers = Object.entries(endpoint.headers || {}).map(
        ([key, val]) => ({
          key,
          value: val
        })
      );

      const extendedHeaders = [
        ...headers,
        {
          key: "Content-Type",
          value: "application/json"
        },
        {
          key: "Authorization",
          value: "Bearer {{groupApiKey}}"
        }
      ];

      let finalUrl = `${baseUrl}${endpoint.path + endpoint.url}`;

      console.log("endpoint", endpoint.url, endpoint.queries);

      if (endpoint.queries && Object.keys(endpoint.queries).length > 0) {
        const queriesStr = Object.keys(endpoint.queries)
          .map((query) => `${query}={{${query}}}`)
          .join("&");
        finalUrl += `?${queriesStr}`;
      }

      apiMapFolder.item.push({
        name: endpoint.url,
        request: {
          method: endpoint.method,
          url: finalUrl,
          header: extendedHeaders,
          body: {
            mode: "raw",
            raw: JSON.stringify(body, null, 2),
            options: {
              raw: {
                language: "json"
              }
            }
          }
        }
      });
    }
  });

  postmanCollection.item.push(apiMapFolder);

  return JSON.stringify(postmanCollection, null, 2);
};

export default generatePostmanCollection;
