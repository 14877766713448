import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ApiBuilderContext } from "../../../../../contexts/ApiBuilderContext";
import { DeploymentModelContext } from "../../../../../contexts/DeploymentModelContext";
import { ToolElement } from "../LaunchLite";
import { ConfigurationModal } from "../ConfigurationModal";
import { Badge } from "react-bootstrap";
import "./ApiModelLeftBar.css";
import TokenGroupManager from "./TokenGroupManager";

export const ApiModelLeftBar = () => {
  const { deploymentModel } = useContext(DeploymentModelContext);
  const { id: apiProjectId } = useContext(ApiBuilderContext);
  const { name } = deploymentModel;

  return (
    <div className="api-modal-left-bar">
      <Link
        to={`/system-designer/${apiProjectId}/launch-lite`}
        className="back-to-models"
      >
        <i className="bi bi-arrow-left"></i> Back to Models
      </Link>

      <div className="model-header">
        <Badge bg="secondary">API</Badge>
        <span className="model-name">{name}</span>
      </div>

      {/* <ConfigurationModal buttonClassName="config-modal-btn" /> */}

      <div className="tool-category">
        <h4>Components</h4>
        <ToolElement
          type="endpoint"
          label="Endpoint"
          config={{ type: "endpoint" }}
        />
        <ToolElement type="route" label="Route" config={{ type: "route" }} />
        <ToolElement
          type="middleware"
          label="Middleware"
          config={{ type: "middleware" }}
        />
        {/* Add more UI components as needed */}
      </div>

      <TokenGroupManager />
    </div>
  );
};
