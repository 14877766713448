import React, { useContext } from "react";
import { useState, useEffect } from "react";
import InputConnectorsFlow from "./InputConnectorsFlow";
import EmittersFlow from "./EmittersFlow";
import TransformFlow from "./TransformFlow";
import FlowBuilder from "./FlowBuilder";
import { useParams, useNavigate } from "react-router-dom";
import { ReactFlowProvider, useReactFlow } from "reactflow";
import {
  Dropdown,
  Form,
  Col,
  Row,
  Card,
  InputGroup,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { ApiBuilderContext } from "../../../contexts/ApiBuilderContext";
import { ChainContext } from "../../../contexts/ChainContext";
import ClickableTextInput from "../../UI/ClickableTextInput";

const CompWithProvider = () => (Comp) => {
  const HOC = (props) => {
    const reactFlowInstance = useReactFlow();

    return <Comp {...props} reactFlowInstance={reactFlowInstance} />;
  };

  return (props) => (
    <ReactFlowProvider>
      <HOC {...props} />
    </ReactFlowProvider>
  );
};

const TransformFlowWithProvider = CompWithProvider()(TransformFlow);

const InputConnectorsFlowWithProvider = CompWithProvider()(InputConnectorsFlow);

const EmittersFlowWithProvider = CompWithProvider()(EmittersFlow);

const FlowBuilderWithProvider = CompWithProvider()(FlowBuilder);

const GLOBAL_PARAMETERS_TYPES = [
  {
    name: "String",
    label: "Text",
    value: "string"
  },
  {
    name: "String(Select)",
    label: "Select",
    value: "string-select"
  },
  {
    name: "Number",
    label: "Number",
    value: "number"
  },
  {
    name: "Boolean",
    label: "True/False",
    value: "boolean"
  },
  {
    name: "Array",
    label: "List",
    value: "array"
  },
  {
    name: "Object",
    label: "Object",
    value: "object"
  }
];

const getAllKeys = (object, path = "") => {
  const keys = Object.keys(object).reduce((keys, key) => {
    const value = object[key];
    const newPath = path ? `${path}.${key}` : key;
    if (typeof value === "object") {
      const subKeys = getAllKeys(value, newPath);
      return [...keys, ...subKeys];
    }
    return [...keys, newPath];
  }, []);
  return keys;
};

const getAllKeysOrdered = (object, path = "") => {
  const allKeys = getAllKeys(object, path);
  // asc string sort
  const orderedKeys = allKeys.sort((a, b) => {
    if (a < b) {
      return -1;
    }
    return 1;
  });
  return orderedKeys;
};

const getFieldType = (value) => {
  if (["string", "number", "boolean"].includes(value)) {
    return value;
  } else if (Array.isArray(value)) {
    return "array";
  } else if (typeof value === "object") {
    return "object";
  }
  return "string";
};

const getAutoInputs = (promptBlocks) => {
  let inputs = [];

  promptBlocks.forEach((promptBlock) => {
    const { valuesLogicLinkedImport, valuesLinkedImport, blockType } =
      promptBlock;

    if (blockType === "completion") {
      Object.keys(valuesLinkedImport).forEach((key) => {
        const { value, type } = valuesLinkedImport[key];

        if (type === "input") {
          if (!inputs.includes(value) && !!value) {
            inputs.push(value);
          } else if (!inputs.includes(key) && !!key) {
            inputs.push(key);
          }
        }
      });
    } else if (blockType === "logic") {
      const { source } = valuesLogicLinkedImport;

      if (!inputs.includes(source) && !!source) {
        inputs.push(source);
      }
    }
  });

  // order inputs alphabetically
  inputs = inputs.sort((a, b) => {
    if (a < b) {
      return -1;
    }
    return 1;
  });

  return inputs;
};

const ObjectEditor = ({ object, onChange }) => {
  const [objectState, setObjectState] = useState(object);

  const onAddProperty = (currentPath) => {
    const newObject = { ...objectState };
    const path = currentPath.split(".");
    const lastKey = path.pop();
    const parentObject = path.reduce((obj, key) => obj[key], newObject);
    parentObject[lastKey] = "String";
    setObjectState(newObject);
  };

  const onRemoveProperty = (currentPath) => {
    const newObject = { ...objectState };
    const path = currentPath.split(".");
    const lastKey = path.pop();
    const parentObject = path.reduce((obj, key) => obj[key], newObject);
    delete parentObject[lastKey];
    setObjectState(newObject);
  };

  const onRenameProperty = (currentPath, newName) => {
    const newObject = { ...objectState };
    const path = currentPath.split(".");
    const lastKey = path.pop();
    const parentObject = path.reduce((obj, key) => obj[key], newObject);
    parentObject[newName] = parentObject[lastKey];
    delete parentObject[lastKey];
    setObjectState(newObject);
  };

  const onPropertyValueChange = (currentPath, newValue) => {
    const newObject = { ...objectState };
    const path = currentPath.split(".");
    const lastKey = path.pop();
    const parentObject = path.reduce((obj, key) => obj[key], newObject);
    parentObject[lastKey] = newValue;
    setObjectState(newObject);
  };

  const orderedKeys = getAllKeysOrdered(objectState);

  const getField = (key) => {
    const keys = key.split(".");
    const lastKey = keys.pop();
    const currentPath = keys.join(".");
    const value = keys.reduce((obj, key) => obj[key], objectState);
    const type = getFieldType(value);
    const isObject = type === "object";

    return (
      <div>
        <Form.Group>
          <InputGroup>
            <InputGroup.Text>
              <ClickableTextInput
                placeholder="Property name"
                value={lastKey}
                onChange={(newName) => {
                  onRenameProperty(currentPath, newName);
                }}
              />
            </InputGroup.Text>
            <Form.Control
              type="select"
              value={type || "string"}
              onChange={(e) => {
                const { value } = e.target;
                onPropertyValueChange(currentPath, value);
              }}
            >
              {GLOBAL_PARAMETERS_TYPES.map((type) => (
                <option value={type.value}>{type.label}</option>
              ))}
            </Form.Control>
            <InputGroup.Text>
              <i
                className={`fas fa-plus-circle ${isObject ? "" : "text-muted"}`}
                onClick={() => {
                  if (isObject) {
                    onAddProperty(currentPath);
                  }
                }}
              />
            </InputGroup.Text>
            <InputGroup.Text>
              <i
                className={`fas fa-minus-circle ${
                  isObject ? "" : "text-muted"
                }`}
                onClick={() => {
                  if (isObject) {
                    onRemoveProperty(currentPath);
                  }
                }}
              />
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </div>
    );
  };

  const fields = orderedKeys.map((key) => {
    return getField(key);
  });

  return (
    <div className="ObjectEditor">
      {fields}
      <div>
        <i
          className="fas fa-plus-circle"
          onClick={() => {
            onAddProperty("");
          }}
        />
      </div>
    </div>
  );
};

export const GlobalChainParameters = () => {
  const { id: apiProjectId } = useContext(ApiBuilderContext);
  const {
    chain,
    id: chainId,
    promptBlocks,
    chainActions
  } = useContext(ChainContext);
  const { globalParameters = [] } = chain;
  const [globalParametersState, setGlobalChainParametersState] =
    useState(globalParameters);
  const [newParameter, setNewParameter] = useState({
    name: "",
    type: "string",
    object: {},
    selectValues: [],
    defaultValue: ""
  });

  const saveChain = async (update) => {
    await chainActions.update(update);
  };

  const onAddParameter = () => {
    const { name, type, defaultValue, object } = newParameter;

    if (!name) {
      return;
    }

    const newGlobalChainParameters = [
      ...globalParametersState,
      { name, type, defaultValue, object }
    ];

    setGlobalChainParametersState(newGlobalChainParameters);
    saveChain({ globalParameters: newGlobalChainParameters });
    setNewParameter({
      name: "",
      type: "string",
      object: {},
      selectValues: [],
      defaultValue: ""
    });
  };

  const onBlur = (i) => {
    const parameter = globalParametersState[i];
    const { name, type, defaultValue, object } = parameter;

    if (!name) {
      return;
    }

    const newGlobalChainParameters = [...globalParametersState];
    newGlobalChainParameters[i] = {
      name,
      type,
      defaultValue,
      object
    };

    // setGlobalChainParametersState(newGlobalChainParameters);
    saveChain({ globalParameters: newGlobalChainParameters });
  };

  const onRemoveParameter = (index) => {
    const newGlobalChainParameters = globalParametersState.filter(
      (parameter, i) => i !== index
    );
    saveChain({ globalParameters: newGlobalChainParameters });
    setGlobalChainParametersState(newGlobalChainParameters);
  };

  const onEditParameter = (index, parameterUpdated, options = {}) => {
    const newGlobalChainParameters = globalParametersState.map(
      (parameter, i) => {
        if (i === index) {
          return { ...parameter, ...parameterUpdated };
        }
        return parameter;
      }
    );
    setGlobalChainParametersState(newGlobalChainParameters);
    if (options.shouldSave) {
      saveChain({ globalParameters: newGlobalChainParameters });
    }
  };

  const autoInputs = getAutoInputs(promptBlocks);

  return (
    <Card className="p-3 shadow-sm GlobalChainParameters">
      <Card.Title className="ps-3 text-white fs-4 mb-0">
        Global Inputs/Parmeters
      </Card.Title>
      <Card.Body>
        <Row className="mb-3">
          <Col xs={12}>
            {/* Auto inputs are non editables */}
            <p className="text-white fs-5 mb-2">Auto Created Inputs</p>
            <div>
              {autoInputs.map((input, i) => (
                <div className="text-gray-600" key={i}>
                  {input} (Type: Text)
                </div>
              ))}
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12}>
            <p className="fs-5 text-white mb-2">Manually Created Inputs</p>
            <div>
              {!globalParametersState.length && (
                <div className="text-gray-600 mb-2">
                  No manually created inputs
                </div>
              )}
              {globalParametersState.map((parameter, i) => (
                <div key={i}>
                  <Row>
                    <Col xs={5}>
                      <Form.Control
                        placeholder="Name"
                        value={parameter.name}
                        onChange={(e) => {
                          onEditParameter(i, {
                            ...parameter,
                            name: e.target.value
                          });
                        }}
                        onBlur={() => {
                          onBlur(i);
                        }}
                      />
                    </Col>
                    <Col xs={5}>
                      <Form.Control
                        as="select"
                        value={parameter.type}
                        onChange={(e) => {
                          const { value } = e.target;
                          onEditParameter(
                            i,
                            { ...parameter, type: value },
                            { shouldSave: true }
                          );
                        }}
                      >
                        {GLOBAL_PARAMETERS_TYPES.map((type) => (
                          <option key={type.value} value={type.value}>
                            {type.label}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                    {/* <Col xs={4}>
                      <Form.Control
                        placeholder="Default Value"
                        value={parameter.defaultValue}
                        onChange={(defaultValue) => {
                          onEditParameter(i, {
                            ...parameter,
                            defaultValue,
                          });
                        }}
                      />
                    </Col> */}
                    <Col
                      xs={2}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <i
                        className="fas fs-4 fa-minus-circle"
                        role="button"
                        onClick={() => {
                          onRemoveParameter(i);
                        }}
                      />
                    </Col>
                  </Row>
                  {parameter.type === "object" && (
                    <ObjectEditor
                      object={parameter.object}
                      onChange={(object) => {
                        onEditParameter(i, { ...parameter, object });
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={12}>
            {/* <p className="text-white fs-5 mb-2">Add New Input</p> */}
            <Row>
              <Col xs={5}>
                <Form.Group>
                  <Form.Control
                    placeholder="Name"
                    value={newParameter.name}
                    onChange={(e) => {
                      setNewParameter({
                        ...newParameter,
                        name: e.target.value
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={5}>
                <Form.Group>
                  <Form.Control
                    as="select"
                    value={newParameter.type}
                    onChange={(e) => {
                      const { value } = e.target;
                      setNewParameter({ ...newParameter, type: value });
                    }}
                  >
                    {GLOBAL_PARAMETERS_TYPES.map((type) => (
                      <option key={type.value} value={type.value}>
                        {type.label}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              {/* Add button */}
              <Col
                xs={2}
                className="d-flex align-items-center justify-content-center"
              >
                <i
                  role="button"
                  className="fas fa-plus-circle fs-4"
                  onClick={() => {
                    onAddParameter();
                  }}
                />
              </Col>
              {/* <Col xs={4}>
                <Form.Group>
                  <Form.Control
                    placeholder="Default Value"
                    value={newParameter.defaultValue}
                    onChange={(defaultValue) => {
                      setNewParameter({ ...newParameter, defaultValue });
                    }}
                  />
                </Form.Group>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const Ttip = ({ children, text, placement = "right" }) => {
  if (!text) {
    return children;
  }
  return (
    <OverlayTrigger
      placement={placement}
      overlay={<Tooltip id="button-tooltip">{text}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  );
};

export const PanelLeft = ({ actions }) => {
  return (
    <div className="PanelLeft">
      {actions.map((action, i) => {
        const { icon, onClick, label } = action;
        return (
          <div className="PanelLeftItem" onClick={onClick} key={i}>
            <Ttip text={label}>
              <i role="button" className={`fas fs-1 fa-${icon}`} />
            </Ttip>
          </div>
        );
      })}
    </div>
  );
};

export const ContextMenuPane = ({
  actions,
  position,
  onClose,
  show,
  menuHeight
}) => {
  const dropwdownRef = React.useRef(null);
  const [dropdownHeight, setDropdownHeight] = useState(0);

  useEffect(() => {
    if (dropwdownRef.current) {
      setDropdownHeight(dropwdownRef.current.clientHeight);
    }
  }, [dropwdownRef, actions]);

  const eventToSend = {
    clientX: position.x - 217,
    clientY: position.y - 53.5 - 60
  };

  return (
    <Dropdown
      show={show}
      onToggle={onClose}
      ref={dropwdownRef}
      style={{
        position: "absolute",
        // top: position.y + menuHeight,
        top: eventToSend.clientY,
        left: eventToSend.clientX,
        zIndex: 1000
      }}
    >
      <Dropdown.Toggle className="d-none">
        <div className="d-flex justify-content-between">
          <div>Actions</div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {actions.map((action) => (
          <Dropdown.Item
            key={action.name}
            onClick={(e) => {
              action.onClick(eventToSend);
              onClose();
            }}
          >
            <i className={action.icon}></i> {action.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export const PanelTopFlowSelector = ({
  onSelectFlow,
  selectedFlow,
  apiProjectId,
  chainId
}) => {
  const navigate = useNavigate();

  const classNameButton = (flow) => {
    if (flow === selectedFlow) {
      return "text-gray-400 fw-bold fs-4 mx-5 mt-3";
    } else {
      return "text-gray-600 fs-4 mx-5 mt-3";
    }
  };

  const onNavigate = (flow) => {
    navigate(`/logic-system-creator/${apiProjectId}/${chainId}/${flow}`);
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      <p
        role="button"
        className={classNameButton("receive")}
        onClick={() => onNavigate("receive")}
      >
        Receive{" "}
        <i
          className="bi bi-input-cursor-text ms-2 position-relative"
          style={{ top: "1px" }}
        ></i>
      </p>
      <p
        role="button"
        className={classNameButton("transform")}
        onClick={() => onNavigate("transform")}
      >
        Transform{" "}
        <i
          className="bi bi-text-paragraph ms-2 position-relative"
          style={{ top: "1px" }}
        ></i>
      </p>
      <p
        role="button"
        className={classNameButton("emit")}
        onClick={() => onNavigate("emit")}
      >
        Emit{" "}
        <i
          className="fas fa-exchange-alt ms-2 position-relative"
          style={{ top: "1px" }}
        ></i>
      </p>
      <p
        role="button"
        className={classNameButton("automate")}
        onClick={() => onNavigate("automate")}
      >
        Automate{" "}
        <i
          className="bi bi-nut-fill ms-2 position-relative"
          style={{ top: "1px" }}
        ></i>
      </p>
      <p
        role="button"
        className={classNameButton("automate")}
        onClick={() => onNavigate("automate")}
      >
        Deploy
        <i
          className="fas fa-rocket ms-2 position-relative"
          style={{ top: "1px" }}
        ></i>
      </p>
    </div>
  );
};

const getMenu = (menu) => {
  if (allowedMenus.includes(menu)) {
    return menu;
  }
  return "transform";
};

const allowedMenus = ["transform", "receive", "emit", "automate", "deploy"];

const SystemFlowCreator = ({ ...props }) => {
  const { menu } = useParams();
  const [selectedFlow, setSelectedFlow] = useState(getMenu(menu));

  useEffect(() => {
    setSelectedFlow(getMenu(menu));
  }, [menu]);

  return (
    <>
      <FlowBuilderWithProvider
        {...props}
        onSelectFlow={setSelectedFlow}
        selectedFlow={selectedFlow}
      />
    </>
  );
};

export default SystemFlowCreator;
