const updateNodesPositions = (
  changePayload,
  isMovingNode,
  lastNodesPositions,
  updateCallback
) => {
  const payloadWithPositions = changePayload.filter(
    (change) => !!change.position
  );

  if (!isMovingNode.current && payloadWithPositions.length > 0) {
    isMovingNode.current = true;
    lastNodesPositions.current = payloadWithPositions.reduce(
      (acc, change) => {
        const { id, position } = change;
        acc[id] = position;
        return acc;
      },
      {}
    );
  } else if (isMovingNode.current && payloadWithPositions.length > 0) {
    lastNodesPositions.current = payloadWithPositions.reduce(
      (acc, change) => {
        const { id, position } = change;
        acc[id] = position;
        return acc;
      },
      {}
    );
  } else if (
    isMovingNode.current &&
    payloadWithPositions.length === 0 &&
    lastNodesPositions.current
  ) {
    isMovingNode.current = false;

    const newNode = Object.keys(lastNodesPositions.current).map(
      (id) => {
        const position = lastNodesPositions.current[id];
        return {
          id,
          positionFlow: position,
        };
      }
    );

    lastNodesPositions.current = {};
    updateCallback(newNode);
  }
};

const flowDbUtils = {
    updateNodesPositions,
};

export default flowDbUtils;