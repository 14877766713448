import React, { useState, useEffect } from "react";
import FilesManager from "../modules/FilesManager";
import DB from "../../database/DB";
// import modules from react-router-dom v6 to allow render different login depending on route, in case of / it renders project selector, in case of /project/:id it renders the files manager
import { useLoaderData, useNavigate } from "react-router-dom";

import { Form, Button, Container } from "react-bootstrap";

const SmartCodeEditor = () => {
  const navigate = useNavigate();
  const loaderData = useLoaderData() || {};
  const projectId = loaderData.projectId;
  const [currentProject, setCurrentProject] = useState(null);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const getProjectsUnsubscribe = DB.project.getProjects((projects) => {
      setProjects(projects);
    });
    return getProjectsUnsubscribe;
  }, []);

  useEffect(() => {
    if (projectId && projects.length > 0) {
      const project = projects.find((project) => project.id === projectId);
      if (project) {
        setCurrentProject(project);
      }
    } else {
      setCurrentProject(null);
    }
  }, [projectId, projects]);

  const selectProject = (e) => {
    const projectId = e.target.value;
    navigate(`/smart-code-editor/${projectId}`);
  };

  return (
    <div>
      {/* If there's no currentProject so ask to choose a project using a select from react-bootstrap */}
      {!currentProject && (
        <Container fluid="sm">
          <Form>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Edit code for your project</Form.Label>
              <Form.Control as="select" onChange={selectProject}>
                <option>Select a project</option>
                {projects.map((project) => (
                  <option value={project.id} key={project.id}>
                    {project.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Container>
      )}
      {/* If there's currentProject so we show the Files Maneger */}
      {currentProject && <FilesManager currentProject={currentProject} />}
    </div>
  );
};

export default SmartCodeEditor;
