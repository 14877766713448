// This is the page for fine tunning the model and allow to select fine-tunning model.

import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Spinner,
  Badge,
  Form,
  Modal,
  Container,
  ListGroup,
  Row,
  Alert,
  Col,
} from "react-bootstrap";
import { useNotif } from "../zones/NotifZone";
import { useNavigate } from "react-router-dom";
import CrudPage from "../layouts/CrudPage";
import { getOpenAICompletion } from "../../apis/openAiAPI";
import DB from "../../database/DB";
import API from "../../apis/API";
import { useParams } from "react-router-dom";

/**
 * @typedef {Object} TrainingData
 * @property {string} prompt - The prompt of the trainingData.
 * @property {string} completion - The completion of the trainingData.
 */

/**
 * @typedef {Object} TrainingFile
 * @property {string} id - The id of the trainingFile.
 * @property {string} fineTuneId - The id of the fineTune.
 * @property {string} openAiId - The name of the trainingFile.
 * @property {string} openAiIdFineTune - The name of the trainingFile.
 * @property {string} name - The name of the trainingFile.
 * @property {string} path - The path of the trainingFile.
 * @property {string} type - The type of the trainingFile, must be "JSONL".
 * @property {string} date - The date of the trainingFile.
 * @property {[TrainingData]} trainingData - The list of training data.
 * @property {string} status - The status of the trainingFile. Can be "created", "uploadingStorage", "uploadedStorage", "uploadingOpenAI", "uploadedOpenAI", "training", "trained", "finetuning", "finetuned", "error"
 */

/**
 * @typedef {Object} fineTune
 * @property {string} id - The id of the fineTune.
 * @property {string} name - The name of the fineTune.
 * @property {string} description - The description of the fineTune.
 * @property {string} createdAt - The date the fineTune was created.
 * @property {string} updatedAt - The date the fineTune was last updated.
 * @property {string} uid - The id of the user who created the fineTune.
 * @property {string} v - The version of the fineTune.
 * @property {string} modelId - The id of the model that the fineTune is based on.
 * @property {strinh} status - The status of the fineTune. Can be "created", "training", "trained", "finetuning", "finetuned", "error", ""
 * @property {[TrainingFile]} trainingFiles - The list of training files.
 */

const getDefaultTrainingFile = () => ({
  name: "",
  status: "",
  trainingData: [
    {
      prompt: "Prompt",
      completion: "Completion",
    },
  ],
});

const getDefaultFineTune = () => ({
  name: "",
  description: "",
  modelId: "davinci",
  status: "",
  trainingFiles: [getDefaultTrainingFile()],
});

// This component shows the Crud system for fineTune, receive following props: fineTuneId
// If no fineTuneId is provided, then it's a new fineTune, but keep using the same fineTune state, which is updated on every change of the form.
// Then it's sent to the database when the user click on the save button.
// fineTune is cleaned or set to the fineTune with the fineTuneId provided, and when fineTuneId is updated, the fineTune is updated too.
// contains following components/elements:
// - <section-header>
// - if fineTuneId is provided, then show the status of the fineTune
// - Save/Create button
// - Delete button
// - <section-content>
// - Input for the name of the fineTune
// - Input for the description of the fineTune
// - Select for the modelId of the fineTune (list of models)
// - A button to add a new training file
// - List of training files
// - For each training file:
//   - <list-item trainingFile>
//   - if it's the new added training file with no status/path/trainingData, then show the prompt and completion list which is a list of prompt and completion(training data), both are input fields.
//   - If saved the status is "created".
//   - inputs can be editable(toggle button, default true) when status is "created", "uploadedStorage" and "uploadedOpenAI" or uninitialized.
//   - if trainingData is provided, then show a button to upload to internal storage(post trainingData to api/storage-utils/transform/jsonl), which then set status to "uploadedStorage".
//   - if status is "uploadedStorage", then show a button to upload to OpenAI(post trainingData to api/openai/finetuning/upload), which then set status to "uploadedOpenAI".
//   - if status is "uploadedOpenAI", then show a button to train the model(post trainingData to api/openai/finetuning/train), which then set status to "trained".
//   - if status is "trained", then show a button to fine-tune the model(post trainingData to api/openai/finetuning/finetune), which then set status to "finetuned".
//   - Button to delete the training file with confirmation modal.
const TrainingFileListItem = ({
  toggleContent,
  showContent,
  trainingFile,
  onEdit,
  onDelete,
  onUploadStorage,
  onUploadOpenAI,
  onTrain,
  onFineTune,
}) => {
  const [fineTuneOpenAiObj, setFineTuneOpenAiObj] = useState(null);
  const [trainingFileCopy, setTrainingFileCopy] = useState(trainingFile);
  const isEditable = ["created", "uploadedStorage", "uploadedOpenAI"].includes(
    trainingFile.status
  );
  const { id, name, status, trainingData } = trainingFileCopy;
  // Contains bolean values to show/hide the training data based on index of trainingData
  const [trainingDatasShown, setTrainingDatasShown] = useState([]);
  const { status: openAiStatus } = fineTuneOpenAiObj || {};

  useEffect(() => {
    setTrainingFileCopy(trainingFile);
  }, [trainingFile]);

  useEffect(() => {
    (async () => {
      if (trainingFile.openAiIdFineTune) {
        const data = await API.openAi.finetuning.retrieve(
          trainingFile.fineTuneId,
          trainingFile.id
        );
        console.log("data", data);
        if (data) {
          setFineTuneOpenAiObj(data);
        }
      } else {
        setFineTuneOpenAiObj(null);
      }
    })();
  }, [trainingFile.openAiIdFineTune]);

  useEffect(() => {
    if (!trainingData || trainingData.length === 0) {
      setTrainingDatasShown([]);
    } else {
      const newTrainingDatasShown = trainingData.map((trainingData, index) => {
        const isShown = trainingDatasShown[index];
        return isShown === undefined ? true : isShown;
      });
      setTrainingDatasShown(newTrainingDatasShown);
    }
  }, [trainingData]);

  const statusColorClassNames = {
    created: "info",
    uploadingStorage: "info",
    uploadedStorage: "info",
    uploadingOpenAI: "info",
    uploadedOpenAI: "info",
    training: "info",
    trained: "success",
    fineTuning: "info",
    finetuned: "success",
    error: "danger",
  };

  const statusFaIconClassNames = {
    created: "fas fa-file",
    uploadingStorage: "fas fa-cloud-upload-alt",
    uploadedStorage: "fas fa-cloud-upload-alt",
    uploadingOpenAI: "fas fa-cloud-upload-alt",
    uploadedOpenAI: "fas fa-cloud-upload-alt",
    training: "fas fa-cog fa-spin",
    trained: "fas fa-check",
    fineTuning: "fas fa-cog fa-spin",
    fineTuned: "fas fa-check",
    error: "fas fa-exclamation-triangle",
  };

  const openAiStatusColorClassNames = {
    pending: "info",
    running: "info",
    succeeded: "success",
    cancelled: "grey",
    failed: "danger",
  };

  const openAiStatusFaIconClassNames = {
    pending: "fas fa-clock",
    running: "fas fa-cog fa-spin",
    succeeded: "fas fa-check",
    cancelled: "fas fa-times",
    failed: "fas fa-exclamation-triangle",
  };

  const formatNameTitle = (name) => {
    const newString = name.replace(/_/g, " ");
    return newString.charAt(0).toUpperCase() + newString.slice(1);
  };

  const onEditTrainingData = (e, index) => {
    const { name, value } = e.target;
    const newTrainingFile = { ...trainingFileCopy };
    newTrainingFile.trainingData[index][name] = value;
    setTrainingFileCopy(newTrainingFile);
  };

  const onDeleteTrainingData = (index) => {
    const newTrainingFile = { ...trainingFileCopy };
    newTrainingFile.trainingData.splice(index, 1);
    setTrainingFileCopy(newTrainingFile);
  };

  const toggleTrainingData = (index) => {
    const newTrainingDatasShown = [...trainingDatasShown];
    newTrainingDatasShown[index] = !newTrainingDatasShown[index];
    setTrainingDatasShown(newTrainingDatasShown);
  };

  const textAreaStyle = {
    minHeight: "100px !important",
    height: "150px",
    resize: "none",
  };

  const onSaveTrainingFile = () => {
    onEdit(trainingFileCopy);
  };

  return (
    <div className="training-file-list-item">
      <div className="training-file-list-item-header">
        <div className="training-file-list-item-header-title">
          <h4>
            {/* Toggle icon */}
            <i
              role="button"
              className={`fs-5 me-2 fas fa-chevron-${
                showContent ? "down" : "right"
              }`}
              onClick={toggleContent}
            ></i>

            {formatNameTitle(name)}
          </h4>
          <div>
            <h5>
              <Badge bg={statusColorClassNames[status]} size="lg">
                <i className={statusFaIconClassNames[status]}></i> {status}
              </Badge>
              {openAiStatus && (
                <Badge
                  bg={openAiStatusColorClassNames[openAiStatus]}
                  size="lg"
                  className="ms-2"
                >
                  <i className={openAiStatusFaIconClassNames[openAiStatus]}></i>{" "}
                  OpenAi Status : {openAiStatus}
                </Badge>
              )}
            </h5>
          </div>
        </div>
      </div>

      {showContent && (
        <div className="data-training-list">
          {trainingData.map((data, index) => {
            const { prompt, completion } = data;
            const isShown = trainingDatasShown[index];
            return (
              <Container className="data-training-item" key={index} fluid>
                {index === 0 && (
                  <div>
                    <hr />
                  </div>
                )}
                <Row>
                  <h5>
                    {/* Toggle icon */}
                    <i
                      role="button"
                      className={`fs-5 me-2 fas fa-chevron-${
                        isShown ? "down" : "right"
                      }`}
                      onClick={() => toggleTrainingData(index)}
                    ></i>
                    #{index + 1}
                    {/* Floating right delete icon */}
                    <i
                      role="button"
                      className="fs-5 ms-2 fas fa-trash-alt text-danger float-end"
                      onClick={() => onDeleteTrainingData(index)}
                    ></i>
                  </h5>
                  {trainingDatasShown[index] && (
                    <div>
                      <Form.Group
                        className="data-training-item-prompt"
                        controlId="prompt"
                      >
                        <Form.Label>Prompt</Form.Label>
                        <Form.Control
                          style={textAreaStyle}
                          as="textarea"
                          name="prompt"
                          value={prompt}
                          onChange={(e) => onEditTrainingData(e, index)}
                          disabled={!isEditable}
                        />
                      </Form.Group>
                      <Form.Group
                        className="data-training-item-completion"
                        controlId="completion"
                      >
                        <Form.Label>Completion</Form.Label>
                        <Form.Control
                          style={textAreaStyle}
                          as="textarea"
                          name="completion"
                          value={completion}
                          onChange={(e) => onEditTrainingData(e, index)}
                          disabled={!isEditable}
                        />
                      </Form.Group>
                    </div>
                  )}
                  {index !== trainingData.length - 1 && (
                    <div>
                      <hr />
                    </div>
                  )}
                </Row>
              </Container>
            );
          })}
          {/* Button to add prompt/completion item, d-flex align at the end */}
          <div className="data-training-item d-flex justify-content-end mt-3">
            {/* Download link */}
            {trainingFileCopy.path && (
              <a
                className="btn btn-sm btn-outline-secondary me-3"
                href={trainingFileCopy.path}
                download
                target="_blank"
                rel="noreferrer"
              >
                <i className="fas fa-download"></i> Download JSONL
              </a>
            )}
            {isEditable && (
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={() => {
                  const newTrainingFileCopy = { ...trainingFileCopy };
                  newTrainingFileCopy.trainingData.push({
                    prompt: "",
                    completion: "",
                  });
                  setTrainingFileCopy(newTrainingFileCopy);
                }}
              >
                <i className="fas fa-plus"></i> Add Prompt/completion
              </Button>
            )}
            {/* Save button */}
            {(!status ||
              status === "created" ||
              status === "uploadedStorage") && (
              <Button
                size="sm"
                variant="link"
                className="ms-2"
                onClick={onSaveTrainingFile}
              >
                <i className="fas fa-save"></i> Save
              </Button>
            )}
            {status === "created" ||
              status === "uploadedStorage" ||
              (status === "uploadedOpenAI" && (
                <Button
                  size="sm"
                  variant="link"
                  className="ms-2"
                  onClick={() => {
                    onUploadStorage(trainingFileCopy);
                  }}
                >
                  <i className="fas fa-upload"></i> Storage
                </Button>
              ))}
            {/* Button to upload to openAI  */}
            {status === "uploadedStorage" ||
              (status === "uploadedOpenAI" && (
                <Button
                  size="sm"
                  variant="link"
                  className="ms-2"
                  onClick={() => {
                    onUploadOpenAI(trainingFileCopy);
                  }}
                >
                  <i className="fas fa-upload"></i> OpenAI
                </Button>
              ))}
            {/* Button to train the model */}
            {status === "uploadedOpenAI" && (
              <Button
                size="sm"
                variant="link"
                className="ms-2"
                onClick={() => {
                  onFineTune(trainingFileCopy);
                }}
              >
                <i className="fas fa-play"></i> Fine tune
              </Button>
            )}
          </div>
        </div>
      )}
      <div>
        <hr />
      </div>
    </div>
  );
};

const NoListResult = () => {
  return (
    <div className="no-list-result">
      <p>
        <i className="fas fa-exclamation-triangle"></i> No training files found.
      </p>
    </div>
  );
};

const NoFineTuneResult = () => {
  return (
    <div className="no-list-result">
      <p>
        <i className="fas fa-exclamation-triangle"></i>Create a new fine-tune.
      </p>
    </div>
  );
};

const TrainingFileList = ({ fineTune }) => {
  const { notify } = useNotif();
  const [trainingFiles, setTrainingFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  // Contains bollean values to show/hide the training file based on index of trainingFiles
  const [trainingFilesShown, setTrainingFilesShown] = useState([]);

  useEffect(() => {
    if (!fineTune || !fineTune.id) {
      setTrainingFiles([]);
      return;
    }
    const unsubscribe = DB.fineTunes.sub.trainingFiles.list(
      fineTune.id,
      (trainingFiles) => {
        setTrainingFiles(trainingFiles);
      },
      null,
      {
        field: "createdAt",
        direction: "asc",
      }
    );
    return unsubscribe;
  }, [fineTune.id]);

  // update trainingFilesShown when trainingFiles changes
  useEffect(() => {
    if (!trainingFiles || !trainingFiles.length) {
      setTrainingFilesShown([]);
      return;
    }
    const newTrainingFilesShown = trainingFiles.map((trainingFile, index) => {
      let isShown = trainingFilesShown[index];
      if (isShown === undefined) {
        isShown = false;
      }
      return isShown;
    });
    setTrainingFilesShown(newTrainingFilesShown);
  }, [trainingFiles]);

  if (!fineTune || !fineTune.id) {
    return <NoFineTuneResult />;
  }

  const saveTrainingFile = async (trainingFile) => {
    setIsLoading(true);
    try {
      await DB.fineTunes.sub.trainingFiles.update(
        trainingFile.fineTuneId,
        trainingFile.id,
        trainingFile
      );
      notify({
        title: "Training file updated",
        message: "Training file updated successfully",
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      setError(error);
      notify({
        title: "Training file update failed",
        message: "Training file update failed",
        variant: "error",
      });
    }
    setIsLoading(false);
  };

  const onAddNewTrainingFile = async () => {
    const newTrainingFile = getDefaultTrainingFile();
    const idCreated = await DB.fineTunes.sub.trainingFiles.create(fineTune.id, {
      ...newTrainingFile,
      name: `${fineTune.name}_Training_File_${trainingFiles.length + 1}`,
      status: "created",
      fineTuneId: fineTune.id,
    });
    if (idCreated) {
      notify({
        title: "Fine-tune created",
        message: "Fine-tune created successfully with id " + idCreated,
        variant: "success",
      });
    }
  };

  const toggleShowTrainingFile = (index) => {
    const newTrainingFilesShown = [...trainingFilesShown];
    newTrainingFilesShown[index] = !newTrainingFilesShown[index];
    setTrainingFilesShown(newTrainingFilesShown);
  };

  const onUploadStorageForTrainingFile = async (trainingFile) => {
    setIsLoading(true);
    try {
      const response = await API.storageUtils.transform.jsonl({
        trainingFile,
      });
      console.log("response", response);
      notify({
        title: "Training file uploaded",
        message: "Training file uploaded successfully",
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      setError(error);
      notify({
        title: "Training file upload failed",
        message: "Training file upload failed",
        variant: "error",
      });
    }
    setIsLoading(false);
  };

  const onUploadOpenAIForTrainingFile = async (trainingFile) => {
    setIsLoading(true);
    try {
      const response = await API.openAi.finetuning.upload({
        trainingFile,
      });
      console.log("response", response);
      notify({
        title: "Training file uploaded on OpenAI",
        message: "Training file uploaded successfully on OpenAI",
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      setError(error);
      notify({
        title: "Training file upload failed on OpenAI",
        message: "Training file upload failed on OpenAI",
        variant: "error",
      });
    }
    setIsLoading(false);
  };

  const onFineTuneForTrainingFile = async (trainingFile) => {
    setIsLoading(true);
    try {
      const response = await API.openAi.finetuning.tune({
        trainingFile,
      });
      console.log("response", response);
      notify({
        title: "Fine-tuning started",
        message: "Fine-tuning started successfully",
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      setError(error);
      notify({
        title: "Fine-tuning failed",
        message: "Fine-tuning failed",
        variant: "error",
      });
    }
    setIsLoading(false);
  };

  return (
    <div className="training-file-list">
      {trainingFiles.map((trainingFile, index) => (
        <TrainingFileListItem
          showContent={trainingFilesShown[index]}
          toggleContent={() => toggleShowTrainingFile(index)}
          key={trainingFile.id}
          trainingFile={trainingFile}
          onEdit={saveTrainingFile}
          onUploadStorage={onUploadStorageForTrainingFile}
          onUploadOpenAI={onUploadOpenAIForTrainingFile}
          onFineTune={onFineTuneForTrainingFile}
        />
      ))}
      {trainingFiles.length === 0 && <NoListResult />}
      {/* Actions such as adding new training file */}
      <div className="training-file-list-item mt-3">
        <Button variant="primary" size="sm" onClick={onAddNewTrainingFile}>
          <i className="fas fa-plus"></i> Add new training file
        </Button>
      </div>
    </div>
  );
};

const FineTuningCrudSection = ({ fineTuneId }) => {
  const navigate = useNavigate();
  const { notify } = useNotif();
  const [fineTune, setFineTune] = useState(getDefaultFineTune());
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [error, setError] = useState(null);
  const [openAIModelList, setOpenAIModelList] = useState([]);

  useEffect(() => {
    const unsub = async () => {
      const modelsList = await API.openAi.finetuning.models();
      setOpenAIModelList(modelsList);
    };
    unsub();
  }, []);

  useEffect(() => {
    if (fineTuneId) {
      const unsubscribe = DB.fineTunes.get(fineTuneId, (fineTune) => {
        setFineTune(fineTune);
      });
      return unsubscribe;
    } else {
      setFineTune(getDefaultFineTune());
    }
  }, [fineTuneId]);

  const handleSave = async () => {
    setIsSaving(true);
    if (fineTuneId) {
      await DB.fineTunes.update(fineTuneId, fineTune);
      notify({
        title: "Fine-tune updated",
        message: "Fine-tune updated successfully with id " + fineTuneId,
        variant: "success",
      });
    } else {
      const idCreated = await DB.fineTunes.create(fineTune);
      notify({
        title: "Fine-tune created",
        message: "Fine-tune created successfully with id " + idCreated,
        variant: "success",
      });
      navigate("/fine-tuning/" + idCreated);
    }
    setIsSaving(false);
  };

  const handleDelete = async () => {
    // setIsDeleting(true);
    // await DB.fineTunes.delete(fineTuneId);
    // setIsDeleting(false);
  };

  const handleDeleteModalShow = () => {
    setShowDeleteModal(true);
  };

  return (
    <>
      <Row className="Row">
        <Col sm="6" title="Fine-tune model">
          <Button
            className="ml-2"
            variant="primary"
            onClick={handleSave}
            disabled={isSaving}
          >
            {fineTuneId ? "Save" : "Create"}
          </Button>
          {fineTuneId && (
            <Button
              className="ml-2 ms-3"
              variant="danger"
              onClick={handleDeleteModalShow}
              disabled={isDeleting}
            >
              Delete
            </Button>
          )}
          <div className="mt-3">
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={fineTune.name}
                onChange={(e) =>
                  setFineTune({ ...fineTune, name: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                className="ta-100"
                placeholder="Enter description"
                value={fineTune.description}
                onChange={(e) =>
                  setFineTune({ ...fineTune, description: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="modelId">
              <Form.Label>Model</Form.Label>
              <Form.Control
                as="select"
                value={fineTune.modelId}
                onChange={(e) =>
                  setFineTune({ ...fineTune, modelId: e.target.value })
                }
              >
                <option value="">Select model</option>
                {openAIModelList.map((model) => (
                  <option key={model.id} value={model.id}>
                    {model.id}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
        </Col>
        <Col sm="6">
          <Form.Group controlId="model">
            <Form.Label>Training files</Form.Label>
            <TrainingFileList fineTune={fineTune} />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

//

const FineTuning = () => {
  const { fineTuningId } = useParams();
  const [fineTunes, setFineTunes] = useState([]);

  useEffect(() => {
    const unsubscribe = DB.fineTunes.list((fineTunes) => {
      setFineTunes(fineTunes);
    });
    return unsubscribe;
  }, []);

  let pageLinks = fineTunes.map((model) => {
    return {
      label: model.name,
      value: model.id,
    };
  });

  pageLinks = [
    {
      value: "",
      label: "Create Fine-Tuning Model",
    },
    ...pageLinks,
  ];

  return (
    <>
      <CrudPage
        title="Fine-Tuning"
        links={pageLinks}
        value={fineTuningId || ""}
        path="/fine-tuning"
        elements={[]}
      >
        <FineTuningCrudSection fineTuneId={fineTuningId} />
      </CrudPage>
    </>
  );
};

export default FineTuning;
