import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { getNewFile, GlobalContext } from "../../contexts/GlobalContext";
import {
  Tabs,
  Tab,
  Button,
  Col,
  Row,
  Container,
  Breadcrumb,
  Badge,
  Nav,
} from "react-bootstrap";
import GptCodeEditor from "../widgets/GptCodeEditor";
import DB from "../../database/DB";
import FoldersAndFiles from "./FoldersAndFiles";
import { useLocalStorage } from "../../hooks/useLocalStorage";

const ScreenViewWithTabs = ({
  project,
  screen,
  onCloseTab,
  onSelectTab,
  uiTabSaved,
  indexTab,
}) => {
  const onCloseTabClick = (e, id) => {
    e.stopPropagation();
    onCloseTab(id, indexTab);
  };
  const activeKey = screen.activeKeyTab + "-" + indexTab;

  return (
    <Tab.Container
      id="left-tabs-example"
      activeKey={screen.activeKeyTab}
      onSelect={onSelectTab}
    >
      <Row>
        <Col sm={12}>
          <Nav
            variant="tabs"
            className="flex-nowrap files-tabs-header"
            activeKey={screen.activeKeyTab}
          >
            {screen.files.map((file) => (
              <Nav.Item key={file.id}>
                <Nav.Link eventKey={file.id}>
                  {file.name}{" "}
                  <i
                    onClick={(e) => onCloseTabClick(e, file.id)}
                    className="fa fa-times"
                  />
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
        <Tab.Content>
          {screen.files.map((file) => (
            <Tab.Pane eventKey={file.id} key={file.id}>
              <GptCodeEditor
                project={project}
                file={file}
                handleFileChange={(newFile, isFromCreating) => {}}
              />
            </Tab.Pane>
          ))}
        </Tab.Content>
      </Row>
    </Tab.Container>
  );
};

const FilesManager = ({ currentProject }) => {
  const [codeEditorUIStore, setCodeEditorUIStore] = useLocalStorage(
    `codeEditorUIStore-${currentProject.id}`,
    {
      screensList: [
        {
          files: [],
          activeKeyTab: null,
        },
      ],
      screenLastFocused: 0,
    }
  );
  const [screenLastFocused, setScreenLastFocused] = useState(0);
  const [projectFiles, setProjectFiles] = useState([]);
  const [screensList, setScreensList] = useState([
    // {
    //   files: [],
    //   activeKeyTab: "1190w-we9f9w-ihaggha0" // id of the file
    // },
    {
      files: [],
      activeKeyTab: null,
    },
  ]);

  const files = projectFiles;

  // Function to set initial screensList and screenLastFocused using codeEditorUIStore
  const setInitialSavedUI = () => {
    const { screensList } = codeEditorUIStore;
    const screenLastFocused = codeEditorUIStore.screenLastFocused;
    setScreensList(screensList);
    setScreenLastFocused(screenLastFocused);
  };

  /**
   * Opening/ Closing files in screens/ tabs
   */
  // get the active file of the last focused screen
  const getActiveFileOfScreenLastFocused = () => {
    const screen = screensList[screenLastFocused];
    if (screen) {
      const activeFile = screen.files.find(
        (file) => file.id === screen.activeKeyTab
      );
      return activeFile;
    }
  };

  // check if the screen exists or is valid
  const checkScreenIndex = (screenIndex, showError) => {
    // check if the screen exists
    if (screensList[screenIndex] === undefined) {
      if (showError) console.error("screenIndex not found");
      return false;
    }
    // check if screenIndex is valid
    if (screenIndex < 0 || screenIndex >= screensList.length) {
      if (showError) console.error("screenIndex not valid");
      return false;
    }
    return true;
  };

  // add a file in a new screen
  const addFileToNewScreen = (file) => {
    const newScreensList = [...screensList];
    const newScreen = {
      files: [file],
      activeKeyTab: file.id,
    };
    newScreensList.push(newScreen);
    setScreensList(newScreensList);
  };

  // open a file in a screen
  const openFile = (screenIndex, file) => {
    const newScreensList = [...screensList];

    // check if the screen exists
    if (!checkScreenIndex(screenIndex, true)) return;

    // check if the file is already opened in the current screen
    const indexFile = newScreensList[screenIndex].files.findIndex(
      (fileOpened) => fileOpened.id === file.id
    );
    if (indexFile < 0) {
      // add the file in the current screen
      // and set the file as active in his tabs
      newScreensList[screenIndex].files.push(file);
      newScreensList[screenIndex].activeKeyTab = file.id;
    }
    // set the screen as the last focused screen
    setScreenLastFocused(screenIndex);
    // update the screensList
    setScreensList(newScreensList);
  };

  // close a file in a screen
  const closeFile = (screenIndex, fileId) => {
    const newScreensList = [...screensList];

    // check if the screen exists
    if (!checkScreenIndex(screenIndex, true)) return;

    // check if the file is already opened in the current screen
    const indexFile = newScreensList[screenIndex].files.findIndex(
      (fileOpened) => fileOpened.id === fileId
    );
    if (indexFile >= 0) {
      // remove the file from the current screen
      newScreensList[screenIndex].files.splice(indexFile, 1);
      // set the file as active in his tabs
      if (newScreensList[screenIndex].files.length > 0) {
        newScreensList[screenIndex].activeKeyTab =
          newScreensList[screenIndex].files[0].id;
      } else {
        newScreensList[screenIndex].activeKeyTab = null;
      }
    }

    // set the screen activeKeyTab as the first file of the screen
    // if the screen is empty and is not the first one, remove the screen and set the first screen as active
    // else if the first screen is not empty set last focused screen as 0
    if (newScreensList[screenIndex].files.length > 0) {
      newScreensList[screenIndex].activeKeyTab =
        newScreensList[screenIndex].files[0].id;
    } else if (screenIndex > 0) {
      newScreensList.splice(screenIndex, 1);
      setScreenLastFocused(0);
    } else {
      setScreenLastFocused(0);
    }
    // update the screensList
    setScreensList(newScreensList);
  };

  const openFileLogic = (logicStr, file, screenIndex) => {
    if (logicStr === "newScreen") {
      addFileToNewScreen(file);
    } else if (logicStr === "currentScreen") {
      const currentScreenIndex = screenLastFocused;
      // check if the screen is already exixts, and to first scrren of screensList
      if (!checkScreenIndex(currentScreenIndex, true)) {
        // first screen of screensList exists it add the file to this screen
        // else it create a new screen
        if (screensList.length > 0) {
          openFile(screenLastFocused || 0, file);
        } else {
          addFileToNewScreen(file);
        }
      } else {
        openFile(currentScreenIndex, file);
      }
    } else if (logicStr === "screenIndex") {
      openFile(screenIndex, file);
    }
  };

  /**
   * Use Effects
   */

  // get files from DB for the current project
  useEffect(() => {
    try {
      const getFilesUnsubscribe = DB.project.getFilesForProject(
        currentProject.id,
        (files) => {
          setProjectFiles(files);
        }
      );
      setInitialSavedUI();

      return getFilesUnsubscribe;
    } catch (error) {
      console.error(error);
    }
  }, []);

  // useEffect to set last focused screen when screensList change
  useEffect(() => {
    setScreenLastFocused(screensList.length - 1);
    // save screensList in DB in local storage using setCodeEditorUIStore, it saves only the activeKeyTab, and the file ids of the tabs, and last focused screen index
    const screensListToSave = screensList.map((screen) => {
      return {
        activeKeyTab: screen.activeKeyTab,
        files: screen.files.map((file) => ({
          id: file.id,
          name: file.name,
        })),
      };
    });
    setCodeEditorUIStore({
      screensList: screensListToSave,
      screenLastFocused: screenLastFocused,
    });
  }, [screensList]);

  const addScreen = () => {
    const newScreensList = [...screensList];
    const lastFocusedFile = getActiveFileOfScreenLastFocused();
    if (lastFocusedFile) {
      newScreensList.push({
        files: [lastFocusedFile],
        activeKeyTab: lastFocusedFile.id,
      });
    } else {
      newScreensList.push({
        files: [],
        activeKeyTab: null,
      });
    }
    setScreensList(newScreensList);
  };

  /**
   *
   * Manipulate screens
   */

  const removeScreen = (index, file) => {
    const newScreensList = [...screensList];
    newScreensList.splice(index, 1);
    setScreensList(newScreensList);
  };

  const onOpenFile = (file) => {
    // change screenLastFocused to the screen where the file is opened
    openFileLogic("currentScreen", file);
  };

  /**
   *
   * Write files in DB
   */

  const handleNewFile = async (newFile) => {
    const indexFile = files.findIndex((file) => file.id === newFile.id);
    const newFileWithWithFinalValues = {
      ...newFile,
      projectId: currentProject.id,
    };
    let fileId = null;
    if (indexFile > -1) {
      fileId = await DB.project.updateFileForProject(
        currentProject.id,
        newFileWithWithFinalValues
      );
    } else {
      fileId = await DB.project.createFileForProject(
        currentProject.id,
        newFileWithWithFinalValues
      );
    }
    openFileLogic("currentScreen", {
      ...newFile,
      id: fileId,
    });
  };

  const handleNewFolder = (newFolder) => {
    const indexFolder = files.findIndex((file) => file.id === newFolder.id);
    const newFolderWithWithFinalValues = {
      ...newFolder,
      projectId: currentProject.id,
    };
    if (indexFolder > -1) {
      DB.project.updateFileForProject(
        currentProject.id,
        newFolderWithWithFinalValues
      );
    } else {
      DB.project.createFileForProject(
        currentProject.id,
        newFolderWithWithFinalValues
      );
    }
  };

  const handleDeleteFile = (file) => {
    const newFiles = [...files];
    const fileName = file.name;
    const deletedIds = [];

    if (file.isFolder && fileName == ".") {
      // delete all the files in inside the folder and start with the path
      const path = file.path;
      const filesToDelete = newFiles.filter(
        (file) => `${file.path}/`.startsWith(path) > -1
      );
      filesToDelete.forEach((file) => {
        DB.project.deleteFileForProject(currentProject.id, file.id);
        //
        deletedIds.push(file.id);
      });
    } else {
      deletedIds.push(file.id);
      DB.project.deleteFileForProject(currentProject.id, file.id);
    }
    // Close the file in all the screens if there's
    const newScreensList = screensList
      .map((screen) => {
        const newFiles = screen.files.filter(
          (fileScreen) => fileScreen.id !== file.id
        );
        const newActiveKeyTab = newFiles.length > 0 ? newFiles[0].id : null;
        return {
          ...screen,
          files: newFiles,
          activeKeyTab: newActiveKeyTab,
        };
      })
      .filter((screen) => screen.files.length > 0); // if one of the deleted screen is empty, remove it

    setScreensList(newScreensList);
  };

  const actionsFunctionsManager = {
    handleNewFile,
    handleNewFolder,
    handleDeleteFile,
  };

  const gridNumber = 12 / screensList.length;

  return (
    <Container className="FilesManager" fluid>
      {/* Group of Button with variant = link, buttons is on the left */}
      <Row className="files-manager-actions">
        <Col className="d-flex align-items-start editor-header p-1">
          {/* Using breadcrump with a link for select project a badge for the current project name, using react-bootstrap, add bootsrap class ro remove margin-bottom */}
          <div className="no-margin-ol project-nav-header">
            <Breadcrumb className="">
              <Breadcrumb.Item
                linkAs={Link}
                linkProps={{ to: "/smart-code-editor" }}
                className=""
              >
                <i className="fas fa-chevron-left"></i>
                Projects
              </Breadcrumb.Item>
              <Breadcrumb.Item className="" active>
                {currentProject.name}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          {/* <Button variant="link" onClick={addFile}>
            Add file
          </Button> */}
          <Row>
            <Col className="d-flex">
              <Button variant="link" onClick={addScreen}>
                Add screen
              </Button>
              {screensList.length > 1 && (
                <Button variant="link" onClick={removeScreen}>
                  Remove screen
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      {/* Folder/files explorer */}
      <Row className="files-manager-tabs">
        <Col sm={2}>
          <FoldersAndFiles
            project={currentProject}
            actionsManager={actionsFunctionsManager}
            onOpenFile={onOpenFile}
            files={files}
          />
        </Col>
        {/* Tabs with files */}
        <Col sm={10}>
          <Row>
            {screensList.map((screen, index) => (
              <Col
                onClick={() => setScreenLastFocused(index)}
                key={index}
                sm={gridNumber}
                className={`${index > 0 && `not-first-file`}`}
              >
                <ScreenViewWithTabs
                  project={currentProject}
                  screen={screen}
                  uiSaved={codeEditorUIStore.screensList[index]}
                  onCloseTab={(fileId) => {
                    closeFile(index, fileId);
                  }}
                  onSelectTab={(key) => {
                    const newScreensList = [...screensList];
                    newScreensList[index].activeKeyTab = key;
                    setScreensList(newScreensList);
                  }}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default FilesManager;
