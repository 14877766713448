/**
 * @fileoverview Home page
 * @name Home.js
 * @description Home page
 */

import React, { useState } from "react";
import LeftSidebarComponent from "../LeftSidebarComponent";
import CenterAreaComponent from "../CenterAreaComponent";

import { Container, Row, Col, Button } from "react-bootstrap";

const Home = () => {
  const [pages, setPages] = useState([]);
  const [modules, setModules] = useState([]);
  const [components, setComponents] = useState([]);
  const [editorMode, setEditorMode] = useState(true);

  return (
    <div className="WebApp-Builder-Home bg-light">
      <Container>
        <Row>
          <Col>
            <LeftSidebarComponent />
            <CenterAreaComponent
              pages={pages}
              modules={modules}
              components={components}
              editorMode={editorMode}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Home;
