import React from "react";
import { AuthCheckerDetails } from "./AuthChecker";
import "./MiddlewareDetailsComponent.css";
import { CorsHandlerDetails } from "./CorsHandler";

const MiddlewareDetailsComponent = ({ middleware, lite = false }) => {
  const { middlewareType } = middleware;

  switch (middlewareType) {
    case "authChecker":
      return <AuthCheckerDetails middleware={middleware} lite={lite} />;
    case "corsHandler":
      return <CorsHandlerDetails middleware={middleware} lite={lite} />;
    default:
      return null;
  }
};

export default MiddlewareDetailsComponent;
