/**
 * @file AudioInput.js
 * @description Audio input component.
 *   This component is used to translate audio to text. And to display the result.
 *   It will complete the current value of the input.
 *   It will use openAi.transcript function to translate audio to text.
 *   To get the recorded audio, we will use the most natives way to do it.
 *   We will use the navigator.mediaDevices.getUserMedia function.
 *   It will take a stream as parameter.
 *   We will use the MediaRecorder API to record the stream.
 *   We will use the Blob API to get the recorded audio.
 *   We will use the FileReader API to read the recorded audio.
 *   We will use the openAi.transcript function to translate the recorded audio to text.
 *   We will use the useRef hook to store the recorded audio.
 *   We will use the useState hook to store the text.
 *
 *   Form component from react-bootstrap. can be input or textarea.
 *   A microphone icon from bootstrap-icons and it will be gray when the audio is not recorded and red when the audio is recorded.
 *
 *   When clicking on the microphone icon, it will start recording the audio.
 *
 *   When clicking on the microphone icon again, it will stop recording the audio and translate the audio to text set the text in the input.
 */

import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert, Tab, Tabs, Spinner } from "react-bootstrap";
import openAi from "../../apis/openAiAPI";

const AudioInput = ({
  value,
  onChange,
  as,
  name = "audio-input-name",
  className = "",
  placeholder = "",
}) => {
  const audioRef = useRef();
  const currentMediaRecorder = useRef();
  const stream = useRef();
  const [isRecording, setIsRecording] = useState(false);
  const [isSendingToApi, setIsSendingToApi] = useState(false);

  // function to remove the echo and noise
  const configureAudio = (stream) => {
    const audioContext = new AudioContext();
    const audioInput = audioContext.createMediaStreamSource(stream);
    const audioRecorder = new MediaRecorder(stream);
    const gainNode = audioContext.createGain();
    const biquadFilter = audioContext.createBiquadFilter();

    audioInput.connect(gainNode);
    gainNode.connect(biquadFilter);
    biquadFilter.connect(audioContext.destination);

    biquadFilter.type = "lowpass";
    biquadFilter.frequency.value = 1000;
    gainNode.gain.value = 0;

    return audioRecorder;
  };

  // the function open the microphone
  // the function should first configure the audio
  // the function should then start recording the audio in webm format
  // the function should then translate the audio to text
  // the function should then set the text in the input
  const handleRecordTranscript = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const mediaRecorder = configureAudio(stream);
        // const mediaRecorder = new MediaRecorder(stream);
        currentMediaRecorder.current = mediaRecorder;
        mediaRecorder.start();
        const audioChunks = [];
        mediaRecorder.addEventListener("dataavailable", (event) => {
          audioChunks.push(event.data);
        });

        // save in webm format and give a random name
        mediaRecorder.addEventListener("stop", () => {
          const audioBlob = new Blob(audioChunks);
          const fileName = `audio-${Math.random() * 100000000000000000}.webm`;
          const formData = new FormData();
          formData.append("file", audioBlob, fileName);
          trancriptAudio(formData);

          stream.getTracks().forEach((track) => track.stop());
          stopRecording();

          // const audioUrl = URL.createObjectURL(audioBlob);
          // const audio = new Audio(audioUrl);
          // audio.play();
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const trancriptAudio = async (formData) => {
    try {
      setIsSendingToApi(true);
      const result = await openAi.transcript(formData);
      onChange({ target: { value: value + result.data, name } });
      setIsSendingToApi(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleTranscript = async () => {
    try {
      currentMediaRecorder.current.stop();
    } catch (err) {
      console.error(err);
    }
  };

  // stop listening to the microphone and remove the red circle
  const stopRecording = () => {
    setIsRecording(false);
    currentMediaRecorder.current = null;
  };

  const formProps = {};

  if (as) {
    formProps.as = as;
  }

  return (
    <div style={{ position: "relative" }}>
      <div
        role={"button"}
        className="bg-light d-flex justify-content-center align-items-center"
        style={{
          padding: "0px",
          width: "29px",
          height: "29px",
          position: "absolute",
          left: "2px",
          top: "2px",
        }}
        onClick={() => {
          if (isRecording) {
            handleTranscript();
          } else {
            handleRecordTranscript();
          }
          setIsRecording(!isRecording);
        }}
      >
        {!isSendingToApi && (
          <i
            className={`bi bi-mic ${
              isRecording ? "text-danger" : "text-secondary"
            }`}
          ></i>
        )}
        {isSendingToApi && <Spinner animation="border" size="sm" />}
      </div>
      <Form.Control
        // className="mt-textarea"
        placeholder={placeholder}
        className={className}
        name={name}
        style={{
          textIndent: "20px",
          minHeight: "250px",
        }}
        {...formProps}
        rows={3}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default AudioInput;
