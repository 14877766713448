// Tooltip.js
import React from "react";
import "./Tooltip.css"; // Assurez-vous de créer ce fichier CSS et d'ajuster le chemin si nécessaire

const Tooltip = ({ message, children }) => {
  return (
    <div className="tooltip-container-cx">
      {children}
      <div className="tooltip-message">{message}</div>
    </div>
  );
};

export default Tooltip;
