import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  query,
  orderBy,
  limit,
  where,
  onSnapshot,
  setDoc,
  updateDoc,
  getDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { firebaseDB, firebaseAuth } from "../api-connector/firebase";

const version = "1.0.0";

/**
 * This files contains all the functions that interact with the firebase database using firebaseDB(getFirestore(firebaseApp)).
 * The functions are used to create, read, update and delete projects and files.
 */

// Firebase rules
// rules_version = '2';

// service cloud.firestore {
//   match /databases/{database}/documents {
//     function resourceMatchesUID() {
//         return request.auth.uid == resource.data.uid;
//     }

//     function requestMatchesUID() {
//         return request.auth.uid == request.resource.data.uid;
//     }

//     match /projects/{projectId} {
//       allow read, update, delete: if resourceMatchesUID();
// 			allow create: if requestMatchesUID();

// 			match /files/{fileId} {
//         allow read, update, delete: if resourceMatchesUID();
//         allow create: if requestMatchesUID();
//       }
//       match /learningRecommendations/{recommendationId} {
//         allow read, update, delete: if resourceMatchesUID();
//         allow create: if requestMatchesUID();
//       }
//       match /tasks/{taskId} {
//           allow read: if resourceMatchesUID();
// 		      allow write: if requestMatchesUID();
//       }
//     }

//     match /builtPrompts/{builtPromptId} {
//         allow read, update, delete: if resourceMatchesUID();
//         allow create: if requestMatchesUID();
//     }
//   }
// }

export const getProjects = (setProjects) => {
  const user = firebaseAuth.currentUser;

  const q = query(
    collection(firebaseDB, "projects"),
    where("uid", "==", user.uid)
    // orderBy("createdAt", "desc"),
    // limit(10)
  );
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const projects = [];
    querySnapshot.forEach((doc) => {
      projects.push({ id: doc.id, ...doc.data() });
    });
    setProjects(projects);
  });
  return unsubscribe;
};

export const getProjectById = (projectId, setProject) => {
  const docRef = doc(firebaseDB, "projects", projectId);

  const unsubscribe = onSnapshot(docRef, (doc) => {
    setProject({ id: doc.id, ...doc.data() });
  });

  return unsubscribe;
};

export const createProject = async (project) => {
  console.log("id PROJECT", project.id);
  try {
    const user = firebaseAuth.currentUser;
    const docRef = await addDoc(collection(firebaseDB, "projects"), {
      ...project,
      uid: user.uid,
      createdAt: serverTimestamp(),
      v: version,
    });
    console.log("Document written with ID: ", docRef.id);
    return docRef.id;
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

export const updateProject = async (project) => {
  try {
    const user = firebaseAuth.currentUser;
    const docRef = doc(firebaseDB, "projects", project.id);
    await updateDoc(docRef, {
      ...project,
      uid: user.uid,
      updatedAt: serverTimestamp(),
    });
    console.log("Document updated with ID: ", docRef.id);
    return docRef.id;
  } catch (e) {
    console.error("Error updating document: ", e);
  }
};

export const deleteProject = async (projectId) => {
  try {
    const docRef = doc(firebaseDB, "projects", projectId);
    await deleteDoc(docRef);
    console.log("Document deleted with ID: ", docRef.id);
  } catch (e) {
    console.error("Error deleting document: ", e);
  }
};

export const getFilesForProject = (projectId, setFiles) => {
  const user = firebaseAuth.currentUser;
  const q = query(
    collection(firebaseDB, "projects", projectId, "files"),
    where("uid", "==", user.uid)
    // orderBy("createdAt", "desc"),
    // limit(10)
  );
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const files = [];
    querySnapshot.forEach((doc) => {
      files.push({ id: doc.id, ...doc.data() });
    });
    setFiles(files);
  });
  return unsubscribe;
};

export const createFileForProject = async (projectId, file) => {
  try {
    const user = firebaseAuth.currentUser;
    const docRef = await addDoc(
      collection(firebaseDB, "projects", projectId, "files"),
      {
        ...file,
        uid: user.uid,
        createdAt: serverTimestamp(),
        v: version,
      }
    );
    console.log("Document written with ID: ", docRef.id);

    return await docRef.id;

    // return docRef;
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

export const updateFileForProject = async (projectId, file) => {
  try {
    const docRef = doc(firebaseDB, "projects", projectId, "files", file.id);
    await updateDoc(docRef, {
      ...file,
      updatedAt: serverTimestamp(),
    });
    console.log("Document updated with ID: ", docRef.id);
    return docRef;
  } catch (e) {
    console.error("Error updating document: ", e);
  }
};

export const deleteFileForProject = async (projectId, fileId) => {
  try {
    const docRef = doc(firebaseDB, "projects", projectId, "files", fileId);
    await deleteDoc(docRef);
    console.log("Document deleted with ID: ", docRef.id);
  } catch (e) {
    console.error("Error deleting document: ", e);
  }
};

export const getLearningRecommendations = (
  projectId,
  setLearningRecommendations
) => {
  try {
    const user = firebaseAuth.currentUser;
    const q = query(
      collection(firebaseDB, "projects", projectId, "learningRecommendations"),
      where("uid", "==", user.uid)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const learningRecommendation = [];
      querySnapshot.forEach((doc) => {
        learningRecommendation.push({ id: doc.id, ...doc.data() });
      });
      setLearningRecommendations(learningRecommendation);
    });
    return unsubscribe;
  } catch (e) {
    console.error("Error getting documents: ", e);
  }
};

export const getLearningRecommendationById = (
  projectId,
  id,
  setLearningRecommendation
) => {
  const user = firebaseAuth.currentUser;
  const docRef = doc(
    firebaseDB,
    "projects",
    projectId,
    "learningRecommendations",
    id
  );

  const unsubscribe = onSnapshot(docRef, (doc) => {
    setLearningRecommendation({ id: doc.id, ...doc.data() });
  });

  return unsubscribe;
};

export const createLearningRecommendation = async (
  projectId,
  learningRecommendation
) => {
  try {
    const user = firebaseAuth.currentUser;
    const docRef = await addDoc(
      collection(firebaseDB, "projects", projectId, "learningRecommendations"),
      {
        ...learningRecommendation,
        uid: user.uid,
        createdAt: serverTimestamp(),
        v: version,
      }
    );
    console.log("Document written with ID: ", docRef.id);

    return await docRef.id;
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

export const updateLearningRecommendation = async (
  projectId,
  learningRecommendation
) => {
  try {
    const docRef = doc(
      firebaseDB,
      "projects",
      projectId,
      "learningRecommendations",
      learningRecommendation.id
    );
    await updateDoc(docRef, {
      ...learningRecommendation,
      updatedAt: serverTimestamp(),
    });
    console.log("Document updated with ID: ", docRef.id);
    return docRef;
  } catch (e) {
    console.error("Error updating document: ", e);
  }
};

export const deleteLearningRecommendation = async (
  projectId,
  learningRecommendationId
) => {
  try {
    const docRef = doc(
      firebaseDB,
      "projects",
      projectId,
      "learningRecommendations",
      learningRecommendationId
    );
    await deleteDoc(docRef);
    console.log("Document deleted with ID: ", docRef.id);
  } catch (e) {
    console.error("Error deleting document: ", e);
  }
};

const getTasks = (projectId, setTasks) => {
  const user = firebaseAuth.currentUser;
  const q = query(
    collection(firebaseDB, "projects", projectId, "tasks"),
    where("uid", "==", user.uid)
    // orderBy("createdAt", "desc"),
    // limit(10)
  );
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const tasks = [];
    querySnapshot.forEach((doc) => {
      tasks.push({ id: doc.id, ...doc.data() });
    });
    setTasks(tasks);
  });
  return unsubscribe;
};

const createTask = async (projectId, task) => {
  try {
    const user = firebaseAuth.currentUser;
    const docRef = await addDoc(
      collection(firebaseDB, "projects", projectId, "tasks"),
      {
        ...task,
        uid: user.uid,
        createdAt: serverTimestamp(),
        v: version,
      }
    );
    console.log("Document written with ID: ", docRef.id);

    return await docRef.id;
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

const updateTask = async (projectId, task) => {
  try {
    const docRef = doc(firebaseDB, "projects", projectId, "tasks", task.id);
    await updateDoc(docRef, {
      ...task,
      updatedAt: serverTimestamp(),
    });
    console.log("Document updated with ID: ", docRef.id);
    return docRef;
  } catch (e) {
    console.error("Error updating document: ", e);
  }
};

const deleteTaskFor = async (projectId, taskId) => {
  try {
    const docRef = doc(firebaseDB, "projects", projectId, "tasks", taskId);
    await deleteDoc(docRef);
    console.log("Document deleted with ID: ", docRef.id);
  } catch (e) {
    console.error("Error deleting document: ", e);
  }
};

const project = {
  getProjects,
  getProjectById,
  createProject,
  updateProject,
  deleteProject,
  getFilesForProject,
  createFileForProject,
  updateFileForProject,
  deleteFileForProject,
  getLearningRecommendations,
  getLearningRecommendationById,
  createLearningRecommendation,
  updateLearningRecommendation,
  deleteLearningRecommendation,
  getTasks,
  createTask,
  updateTask,
  deleteTaskFor,
};

export default project;
