//openAiAPI.js
import axios from "axios";
import { firebaseAuth } from "../api-connector/firebase";

const p = {
  payment: {
    stripe: `${process.env.REACT_APP_API_URL}/p/s/create-checkout-session`,
  },
};

const getFirebaseToken = async () => {
  const token = await firebaseAuth.currentUser.getIdToken();
  return token;
};

const getHeaders = async () => {
  const token = await getFirebaseToken();
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const stripeCreateCheckoutSession = async (packId) => {
  try {
    const headers = await getHeaders();
    const result = await axios.post(
      p.payment.stripe,
      {
        packId: packId,
      },
      headers
    );
    console.log("result transform", result);
    return result.data;
  } catch (error) {
    console.error(error);
  }
};

export default {
  stripe: {
    createCheckoutSession: stripeCreateCheckoutSession,
  },
};
