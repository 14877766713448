// This file contains the code for the form modal, it accept a title, a body and a footer. The body is a form with a text input and a textarea. The footer is a button that submit the form.
// The component accept prop initialValue which is an object, this object can be any object that contains the fields that the form needs to render.
// The fields the form needs is defined by the prop fields of the component, which is an array of objects, each object is a field of the form, the object can contain the follodoes wing properties:
// - name: the name of the field, this is the key of the object that will be returned by the onSubmit function
// - type: the type of the field, can be text, textarea, select, tags
// - value: the initial value of the field
// - when the form is submitted the onSubmit function is called, it will call onFormSubmit prop with replacing initialValue(with modified fields) with the new values of the form
import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

// Bug: react-dom.development.js:86 Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by the value changing from undefined to a defined value, which should not happen. Decide between using a controlled or uncontrolled input element for the lifetime of the component. More info:
// Fix above warning in following code:
const FormModal = ({
  title,
  body,
  footer,
  initialValue,
  fields,
  onFormSubmit,
  onClose,
}) => {
  const [formValue, setFormValue] = React.useState(initialValue);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onFormSubmit(formValue);
  };

  return (
    <Modal show={true} onHide={onClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {fields.map((field) => {
            if (field.type === "text") {
              return (
                <Form.Group controlId={field.name} key={field.name}>
                  <Form.Label>{field.label || field.name}</Form.Label>
                  <Form.Control
                    type="text"
                    name={field.name}
                    value={formValue[field.name]}
                    onChange={handleChange}
                  />
                </Form.Group>
              );
            } else if (field.type === "textarea") {
              return (
                <Form.Group controlId={field.name} key={field.name}>
                  <Form.Label>{field.label || field.name}</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name={field.name}
                    value={formValue[field.name]}
                    onChange={handleChange}
                  />
                </Form.Group>
              );
            } else if (field.type === "select") {
              return (
                <Form.Group controlId={field.name} key={field.name}>
                  <Form.Label>{field.label || field.name}</Form.Label>
                  <Form.Control
                    as="select"
                    name={field.name}
                    value={formValue[field.name]}
                    onChange={handleChange}
                  >
                    {field.options.map((option) => (
                      <option key={option}>{option}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              );
            } else if (field.type === "tags") {
              return (
                <Form.Group controlId={field.name} key={field.name}>
                  <Form.Label>{field.label || field.name}</Form.Label>
                  <Form.Control
                    type="text"
                    name={field.name}
                    value={formValue[field.name]}
                    onChange={handleChange}
                  />
                </Form.Group>
              );
            }
          })}
        </Modal.Body>
        {/* Actions buttons */}
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default (props) => {
  const { show } = props;
  return show ? <FormModal {...props} /> : null;
};
