/**
 * @fileoverview DataSetsDatabase
 *
 *
 * Componenent with:
 * - Left side: List of datasets
 * - Selected dataset content:
 *   - List of Object.keys(dataSet.parameters)
 *   - Tree of dataSet.results({string: string})
 *
 * - For each parameter:
 *   - A textarea field to set the value
 *   - A button to set the value
 *
 * - For each result:
 *   - A textarea field to set the value
 *   - A button to select the block
 *   - A button to run the block
 */
import React, { useState, useEffect } from "react";
import { useApiProject, useDataSet } from "../../../libraries/ApiProjectSdk";
import { firebaseDB, firebaseAuth } from "../../../api-connector/firebase";
import { read as readXlsx, utils as utilsXlsx, writeFileXLSX } from "xlsx";
import {
  Container,
  Form,
  Button,
  Col,
  Row,
  Card,
  Tabs,
  Tab,
  Table,
  Dropdown,
  Offcanvas,
  ButtonGroup,
  Tooltip,
  Modal,
  ModalDialog,
  OverlayTrigger,
  Placeholder,
  FormControl,
  ListGroup,
  Badge,
  Spinner,
  Nav,
  Alert,
  NavDropdown,
} from "react-bootstrap";
import storageUtils from "../../../apis/storageUtils";
import { useNotif } from "../../zones/NotifZone";
import AudioInput from "../../UI/AudioInput";
import DB from "../../../database/DB";
import AiEngineErrors from "../../modals/AiEngineErrors";
import ClickableTextInput from "../../UI/ClickableTextInput";
// import * as  docx2html from "docx2html";
// import * as mammoth from "mammoth";

const HighTopLoaderDialog = ({ show, text, parentRef }) => {
  return (
    <Offcanvas
      show={show}
      onHide={() => {}}
      placement="top"
      container={parentRef.current}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{text}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

const sortByChainKey = (a, b) => {
  const aChainKey = a.chainKey.split("-");
  const bChainKey = b.chainKey.split("-");
  for (let i = 0; i < aChainKey.length; i++) {
    if (parseInt(aChainKey[i]) > parseInt(bChainKey[i])) {
      return 1;
    } else if (parseInt(aChainKey[i]) < parseInt(bChainKey[i])) {
      return -1;
    }
  }
  return 0;
};

const setMultiDimensionElementArrayForPromptBlocks = (
  dimArray,
  component,
  ...arrayKeys
) => {
  for (let i = 0; i < arrayKeys.length; i++) {
    const key = parseInt(arrayKeys[i]);

    if (i === arrayKeys.length - 1) {
      if (!dimArray[key]) {
        dimArray[key] = [];
      }

      if (!dimArray[key][0]) {
        dimArray[key][0] = component;
      }

      if (!dimArray[key][1]) {
        dimArray[key][1] = [];
      }
    } else {
      if (!dimArray[key]) {
        dimArray[key] = [null, []];
      }
      dimArray = dimArray[key][1];
    }
  }
};

const TruncatedText = ({ text, maxLength, containerRef }) => {
  const tooltipOverlay = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <span>{text}</span>
    </Tooltip>
  );

  if (text.length > maxLength) {
    return (
      <OverlayTrigger
        container={containerRef}
        placement="bottom"
        overlay={tooltipOverlay}
      >
        <span>{text.substring(0, maxLength - 3)}...</span>
      </OverlayTrigger>
    );
  } else {
    return <span>{text}</span>;
  }
};

const renderTreeListElementsWithStyle = (rawElements) => {
  const elements = [];

  rawElements.forEach((element) => {
    if (element[0]) {
      elements.push(
        <div key={element[0].key} className="tree-list-element">
          <div className="parent-block">
            <div className="content">{element[0]}</div>
          </div>
          {element[1] && element[1].length > 0 && (
            <>
              <div className="link-line"></div>
              <div className="children-parent">
                {renderTreeListElementsWithStyle(element[1])}
              </div>
            </>
          )}
        </div>
      );
    }
  });

  return elements;
};

const renderTreeListElements = (promptBlocks, childrenProps, element) => {
  const copy = [...promptBlocks];
  copy.sort(sortByChainKey);
  let multiDimensionsArray = [];
  let elements = [];
  const Element = element;

  copy.forEach((promptBlock) => {
    const chainKeySplit = promptBlock.chainKey.split("-");
    setMultiDimensionElementArrayForPromptBlocks(
      multiDimensionsArray,
      <Element
        {...childrenProps}
        key={promptBlock.chainKey}
        promptBlock={promptBlock}
      />,
      ...chainKeySplit
    );
  });

  elements = renderTreeListElementsWithStyle(
    multiDimensionsArray,
    childrenProps
  );

  return elements;
};

const UploadDocxHOC = ({ children, onResult }) => {
  const docxUploadButtonRef = React.useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const uploadDocx = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async (e) => {
      const formData = new FormData();
      formData.append("file", file);
      const data = await storageUtils.transform.docxText(formData);
      const { text } = data;
      onResult(text);
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <>
      {children({
        openDocx: () => docxUploadButtonRef.current.click(),
        setIsLoading,
        setIsError,
      })}
      <input
        type="file"
        ref={docxUploadButtonRef}
        style={{ display: "none" }}
        onChange={uploadDocx}
      />
      <Modal
        show={isLoading}
        onHide={() => setIsLoading(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <Spinner animation="border" variant="primary" />
          </div>
          <div className="d-flex justify-content-center">
            <p className="text-center">Uploading...</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const UploadExcelHOC = ({ children, onResult }) => {
  const excelUploadButtonRef = React.useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const uploadExcel = async (e) => {
    setIsLoading(true);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = e.target.result;
      const workbook = readXlsx(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const result = utilsXlsx.sheet_to_json(worksheet);
      setIsLoading(false);
      onResult(result);
      // handleOnResultChange(result);
    };
    reader.readAsBinaryString(file);
  };

  // when user close files dialog without selecting file
  const handleOnClose = () => {
    setIsLoading(false);
  };

  return (
    <>
      {children({
        openExcel: () => {
          excelUploadButtonRef.current.click();
        },
        isLoading,
        setIsLoading,
        setIsError,
      })}
      <Form.Group
        className="mt-2"
        as={Row}
        controlId="formFile"
        style={{ display: "none" }}
      >
        <Form.Label column sm="2">
          Import Excel
        </Form.Label>
        <Col sm="10">
          <Form.Control
            ref={excelUploadButtonRef}
            type="file"
            name="file"
            onChange={uploadExcel}
            onClose={handleOnClose}
          />
        </Col>
      </Form.Group>
    </>
  );
};

// using writeFileXLSX
const DownloadExcelHOC = ({
  children,
  toParse,
  data,
  fileName = "sheetjs",
}) => {
  const downloadExcel = () => {
    const finalData = toParse ? JSON.parse(data) : data;

    // using writeFileXLSX
    const worksheet = utilsXlsx.json_to_sheet(finalData);
    const workbook = utilsXlsx.book_new();
    utilsXlsx.book_append_sheet(workbook, worksheet, "SheetJS");
    writeFileXLSX(workbook, `${fileName}.xlsx`);
  };

  return children({ downloadExcel });
};

const DataSetsList = ({ apiProjectId, chainId, onResult }) => {
  const containerRef = React.useRef(null);
  const [dataSets, setDataSets] = useState([]);
  const [dataOpenIndex, setDataOpenIndex] = useState(null);

  useEffect(() => {
    const unsubscribe = DB.apiProjects.sub.chains.sub.dataSets.list(
      apiProjectId,
      chainId,
      (data) => {
        setDataSets(data);
      }
    );
    return unsubscribe;
  }, [chainId]);

  const parametersListElement = (dataSet) => {
    const parameters = dataSet.parameters;

    if (!parameters) {
      return <div className="mt-3 text-black-6 fw-bold">No parameters for this data set</div>;
    }

    const keysWithValues = Object.keys(parameters).filter(
      (key) => parameters[key]
    );

    if (keysWithValues.length === 0) {
      return <div className="mt-3 text-black-6 fw-bold">No parameters for this data set</div>;
    }

    return (
      <div className="mt-3">
        <h5>Parameters</h5>
        <ListGroup>
          {keysWithValues.map((key) => (
            <ListGroup.Item key={key}>
              <div className="">
                <div className="fw-bold">{key}</div>
                <div>
                  <TruncatedText
                    containerRef={containerRef}
                    text={parameters[key]}
                    maxLength={30}
                  />
                </div>
                <div className="d-flex justify-content-end">
                  <Button
                    variant="link"
                    size="sm"
                    onClick={() => onResult(parameters[key])}
                  >
                    Import
                  </Button>
                </div>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>
    );
  };

  const resultsListElement = (dataSet) => {
    const results = dataSet.results;

    if (!results) {
      return <div className="mt-3 text-black-6 fw-bold">No results for this data set</div>;
    }

    const keysWithValues = Object.keys(results).filter((key) => results[key]);

    if (keysWithValues.length === 0) {
      return <div className="mt-3 text-black-6 fw-bold">No results for this data set</div>;
    }

    return (
      <div className="mt-3">
        <h5>Results</h5>
        <ListGroup>
          {keysWithValues.map((key) => (
            <ListGroup.Item key={key}>
              <div className="">
                <div className="fw-bold">{key}</div>
                <div>
                  <TruncatedText
                    containerRef={containerRef}
                    text={results[key]}
                    maxLength={30}
                  />
                </div>
                <div className="d-flex justify-content-end">
                  <Button
                    variant="link"
                    size="sm"
                    onClick={() => onResult(results[key])}
                  >
                    Import
                  </Button>
                </div>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>
    );
  };

  const toggleDataOpen = (index) => {
    if (dataOpenIndex === index) {
      setDataOpenIndex(null);
    } else {
      setDataOpenIndex(index);
    }
  };

  return (
    <div className="mt-3" ref={containerRef}>
      <h5>Select the data set to import</h5>
      <ListGroup>
        {dataSets.map((dataSet, index) => (
          <ListGroup.Item key={dataSet.id}>
            <div className="">
              <div>
                <div role="button" onClick={() => toggleDataOpen(index)} className="fw-bold">
                  {dataSet.name || dataSet.id}</div>

                {dataOpenIndex === index ? (
                  <>
                <Row>
                  <Col sm={6}>
                  {parametersListElement(dataSet)}
                  </Col>
                  <Col sm={6}>
                  {resultsListElement(dataSet)}
                  </Col>
                </Row>
                </>
                  ) : null}
              </div>
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

const ChainDataModal = ({ apiProjectId, onResult, parentRef, onHide }) => {
  const [selectedChainId, setSelectedChainId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [chains, setChains] = useState([]);

  useEffect(() => {
    const unsubscribe = DB.apiProjects.sub.chains.list(apiProjectId, (data) => {
      setChains(data);
    });
    return unsubscribe;
  }, []);

  return (
    <Modal show={true} onHide={onHide} container={parentRef.current} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Import Chain Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="chainSelector">
            <Form.Label><h5>Select Chain</h5></Form.Label>
            <Form.Control
              as="select"
              value={selectedChainId || ""}
              onChange={(e) => setSelectedChainId(e.target.value)}
            >
              <option value="">Select Chain</option>
              {chains.map((chain) => (
                <option key={chain.id} value={chain.id}>
                  {chain.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>

        <div className="horizontal-separator-2-dark my-3"></div>

        {selectedChainId && (
          <DataSetsList
            apiProjectId={apiProjectId}
            chainId={selectedChainId}
            onResult={onResult}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => {}}>
          Import
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ImportChainData = ({ apiProjectId, children, onResult, parentRef }) => {
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  return (
    <>
      {children({
        importChainData: () => setOpenModal(true),
        setIsLoading,
        setIsError,
      })}
      {openModal && (
        <ChainDataModal
          parentRef={parentRef}
          apiProjectId={apiProjectId}
          onResult={(result) => {
            onResult(result);
            setOpenModal(false);
          }}
          show={true}
          onHide={() => setOpenModal(false)}
        />
      )}
    </>
  );
};

const ImportApiProjectModal = ({ onProjectSelected, onHide, parentRef }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [apiProjects, setApiProjects] = useState([]);

  useEffect(() => {
    const unsubscribe = DB.apiProjects.list((data) => {
      setApiProjects(data);
    });
    return unsubscribe;
  }, []);

  return (
    <Modal show={true} onHide={onHide} container={parentRef.current} centered>
      <Modal.Header closeButton>
        <Modal.Title>Import API Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="apiProjectSelector">
            <Form.Label>API Project</Form.Label>
            <Form.Control
              as="select"
              value={""}
              onChange={(e) => {
                const { value } = e.target;
                onProjectSelected(value);
              }}
            >
              <option value="">Select API Project</option>
              {apiProjects.map((apiProject) => (
                <option key={apiProject.id} value={apiProject.id}>
                  {apiProject.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ImportApiProjectChainData = ({ children, onResult, parentRef }) => {
  const [apiProjectId, setApiProjectId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleOnResult = (...args) => {
    onResult(...args);
    setApiProjectId(null);
    setShowModal(false);
  };

  return (
    <>
      {children({
        importApiProjectChainData: () => setShowModal(true),
      })}
      {showModal && (
        <ImportApiProjectModal
          parentRef={parentRef}
          onProjectSelected={(apiProjectId) => {
            setApiProjectId(apiProjectId);
            setShowModal(false);
          }}
          onHide={() => setShowModal(false)}
        />
      )}
      {apiProjectId && (
        <ChainDataModal
          apiProjectId={apiProjectId}
          onResult={handleOnResult}
          parentRef={parentRef}
          onHide={() => setApiProjectId(null)}
        />
      )}
    </>
  );
};

// Return Dropdown Buttons with importExcel, importDocx and importApiProjectChainData
// onResult will check if the result is a string or an json array and return the result as a string
export const ImportKitHoc = ({
  onResult,
  parentRef,
  variant = "link",
  className = "",
}) => {
  const handleOnResult = (result) => {
    try {
      const stringified = JSON.stringify(result);
      onResult(stringified);
    } catch (e) {
      onResult(result);
    }
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        size="sm"
        variant={variant}
        id="dropdown-basic"
        className={className}
      >
        Import
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <UploadExcelHOC onResult={handleOnResult}>
          {({ openExcel, isLoading }) => (
            <>
              {isLoading && (
                <HighTopLoaderDialog
                  show={isLoading}
                  text="Uploading..."
                  parentRef={parentRef}
                />
              )}
              <Dropdown.Item>
                <Button variant="link" onClick={openExcel}>
                  <i className="fas fa-file-excel"></i> Excel
                </Button>
              </Dropdown.Item>
            </>
          )}
        </UploadExcelHOC>
        <UploadDocxHOC onResult={handleOnResult}>
          {({ openDocx }) => (
            <Dropdown.Item>
              <Button variant="link" onClick={openDocx}>
                <i className="fas fa-file-word"></i> Word
              </Button>
            </Dropdown.Item>
          )}
        </UploadDocxHOC>
        {/* Divider */}
        <Dropdown.Divider />
        <ImportApiProjectChainData parentRef={parentRef} onResult={onResult}>
          {({ importApiProjectChainData }) => (
            <Dropdown.Item>
              <Button variant="link" onClick={importApiProjectChainData}>
                <i className="fas fa-link"></i> API Project
              </Button>
            </Dropdown.Item>
          )}
        </ImportApiProjectChainData>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const ResultBlockText = ({ result, handleOnResultChange }) => {
  return (
    <Form.Group>
      <Form.Label>Result</Form.Label>
      <Form.Control
        as="textarea"
        rows={3}
        value={result}
        onChange={(e) => {
          const { value } = e.target;
          handleOnResultChange(value);
        }}
      />
    </Form.Group>
  );
};

const ResultBlockParentOfArray = ({ result }) => {
  return (
    <>
      {result.length === 0 && <Alert variant="warning">No result yet</Alert>}
      {result.length > 0 && (
        <div className="result-array-table">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Result</th>
              </tr>
            </thead>
            <tbody>
              {result.map((itemText, index) => (
                <tr key={index}>
                  {/* style show new line */}
                  <td style={{ whiteSpace: "pre-wrap" }}>{itemText}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
};

const ResultBlockChildrenOfArray = ({
  result,
  handleOnResultArrayChange,
  promptBlock,
  dataSet,
  resultKey,
  importedArrayValueKey,
  handleRunArrayBlocks,
  addExamplesToBuiltPrompt,
}) => {
  const { notify } = useNotif();
  const [isLoading, setIsLoading] = useState(false);
  const resultArray = typeof result === "string" || !result ? [] : result;
  const parentArrayResult = importedArrayValueKey
    ? dataSet.results[importedArrayValueKey]
    : [];

  useState(() => {
    if (resultArray.length !== parentArrayResult.length) {
      const timeout = setTimeout(() => {
        const newResultArray = [];
        for (let i = 0; i < parentArrayResult.length; i++) {
          if (resultArray[i]) {
            newResultArray.push(resultArray[i]);
          } else {
            newResultArray.push("");
          }
        }
        handleOnResultArrayChange(newResultArray);
      }, 0);

      return () => clearTimeout(timeout);
    }
  }, []);

  const runBlock = async (resultIndex) => {
    setIsLoading(true);
    await handleRunArrayBlocks(resultIndex);
    setIsLoading(false);
  };

  const addExample = async (result, resultIndex) => {
    await addExamplesToBuiltPrompt(resultKey, result, resultIndex);
    notify({
      variant: "success",
      message: "Added examples to prompt",
    });
  };

  return (
    <>
      {parentArrayResult.length === 0 && (
        <Alert variant="warning">No results yet</Alert>
      )}
      {parentArrayResult.length > 0 && (
        <Tab.Container defaultActiveKey="0">
          <Row>
            <Col sm={12}>
              {/* Nav contains aligned items with overflow-y */}
              <Nav
                variant="tabs"
                style={{ overflowX: "auto" }}
                className="flex-nowrap"
              >
                {parentArrayResult.map((item, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link
                      eventKey={index}
                    >{`${resultKey}[${index}]`}</Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Col>
            <Col sm={12}>
              <Tab.Content>
                {parentArrayResult.map((item, index) => (
                  <Tab.Pane eventKey={index} key={index}>
                    <div className="mt-3">
                      <p>{item}</p>
                    </div>
                    <Form.Group>
                      <Form.Label>Result</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={resultArray[index]}
                        onChange={(e) => {
                          const { value } = e.target;
                          const newResult = [...resultArray];
                          newResult[index] = value;
                          handleOnResultArrayChange(newResult);
                        }}
                      />
                    </Form.Group>
                    <Col className="mt-3" sm={12}>
                      <ButtonGroup className="mr-2">
                        <Button
                          variant="primary"
                          onClick={() => {
                            addExample(resultArray[index], index);
                          }}
                        >
                          Add to training
                        </Button>
                        <Button
                          disabled={isLoading}
                          variant="primary"
                          onClick={() => {
                            runBlock(index);
                          }}
                        >
                          Run
                          {isLoading && (
                            <Spinner animation="border" size="sm" />
                          )}
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Col>
          </Row>
          <hr />
        </Tab.Container>
      )}
    </>
  );
};

const ResultBlockConcat = ({
  result,
  // dataSet,
  // promptBlock,
  resultKey,
}) => {
  // const {valuesLogicLinkedImport} = promptBlock;
  // const {source: sourceResultKey} = valuesLogicLinkedImport;
  // const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <Form.Group>
        <Form.Label>Result</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={result || ""}
          disabled
          onChange={() => {}}
        />
      </Form.Group>
    </>
  );
};

const ResultBlock = ({
  promptBlock,
  dataSet,
  runBlock,
  saveResults,
  handleOnResultChange,
  selectBlock,
  isParentsArrayByResultKey,
  getBlockResultType,
  getParentArrayResultKey,
  getImportedArrayValueKey,
  addExamplesToBuiltPrompt,
}) => {
  const excelUploadButtonRef = React.useRef(null);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingRun, setLoadingRun] = useState(false);
  const { notify } = useNotif();

  const { valuesLinkedExport, blockType, logicType } = promptBlock;
  const { result: resultKey } = valuesLinkedExport;
  const isParentOfArray = getBlockResultType(resultKey) === "array";
  const isChildrenOfArray = isParentsArrayByResultKey(resultKey);
  const isArray = isParentOfArray || isChildrenOfArray;
  const isText = !isArray;
  const result = dataSet.results[resultKey] || (isArray ? [] : "");
  const importedArrayValueKey = getImportedArrayValueKey(resultKey);
  const isConcatBlock = blockType === "logic" && logicType === "concatResult";

  const handleSaveResults = async () => {
    setLoadingSave(true);
    await saveResults();
    setLoadingSave(false);
  };

  const handleRunBlocks = async () => {
    setLoadingRun(true);
    await runBlock(resultKey);
    setLoadingRun(false);
  };

  const handleRunArrayBlocks = async (resultIndex) => {
    await runBlock(resultKey, resultIndex);
  };

  const addToExample = async () => {
    await addExamplesToBuiltPrompt(resultKey, result);
    notify({
      variant: "success",
      message: "Prompt is trained",
    });
  };

  const onExcelResult = async (result) => {
    const stringified = JSON.stringify(result);
    handleOnResultChange({
      ...dataSet.results,
      [resultKey]: stringified,
    });
  };

  const onDocxResult = async (result) => {
    handleOnResultChange({
      ...dataSet.results,
      [resultKey]: result,
    });
  };

  return (
    <Card className="database-result-block">
      <Card.Header>
        <Card.Title>{resultKey}</Card.Title>
      </Card.Header>
      <Card.Body>
        {isText && !isConcatBlock && (
          <ResultBlockText
            addExamplesToBuiltPrompt={addExamplesToBuiltPrompt}
            result={result}
            handleOnResultChange={(value) => {
              handleOnResultChange({
                ...dataSet.results,
                [resultKey]: value,
              });
            }}
          />
        )}
        {isParentOfArray && <ResultBlockParentOfArray result={result} />}
        {importedArrayValueKey && (
          <ResultBlockChildrenOfArray
            addExamplesToBuiltPrompt={addExamplesToBuiltPrompt}
            resultKey={resultKey}
            promptBlock={promptBlock}
            dataSet={dataSet}
            result={result}
            handleRunArrayBlocks={handleRunArrayBlocks}
            importedArrayValueKey={importedArrayValueKey}
            handleOnResultArrayChange={(value) => {
              handleOnResultChange({
                ...dataSet.results,
                [resultKey]: value,
              });
            }}
          />
        )}
        {isConcatBlock && (
          <ResultBlockConcat resultKey={resultKey} result={result} />
        )}

        <div className="d-flex justify-content-end">
          <ButtonGroup className="mt-3">
            {!importedArrayValueKey && (
              <>
                <Dropdown>
                  <Dropdown.Toggle variant="link" id="dropdown-basic">
                    <i className="fas fa-file-import"></i> Import
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <UploadExcelHOC onResult={onExcelResult}>
                      {({ openExcel }) => (
                        <Dropdown.Item onClick={openExcel}>
                          <i className="fas fa-file-excel"></i> Excel
                        </Dropdown.Item>
                      )}
                    </UploadExcelHOC>
                    <UploadDocxHOC onResult={onDocxResult}>
                      {({ openDocx }) => (
                        <Dropdown.Item onClick={openDocx}>
                          <i className="fas fa-file-word"></i> Word
                        </Dropdown.Item>
                      )}
                    </UploadDocxHOC>
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown>
                  <Dropdown.Toggle variant="link" id="dropdown-basic">
                    <i className="fas fa-file-export"></i> Export
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <DownloadExcelHOC
                      data={result}
                      toParse={true}
                      fileName={`${resultKey}-${dataSet.id}`}
                    >
                      {({ downloadExcel }) => (
                        <Dropdown.Item onClick={downloadExcel}>
                          <i className="fas fa-file-excel"></i> Excel
                        </Dropdown.Item>
                      )}
                    </DownloadExcelHOC>
                  </Dropdown.Menu>
                </Dropdown>

                {/* <DownloadExcelHOC
                  data={result}
                  toParse={true}
                  fileName={`${resultKey}-${dataSet.id}`}
                >
                  {({ downloadExcel }) => (
                    <Button variant="link" onClick={downloadExcel}>
                      <i className="fas fa-file-excel"></i> Export Excel
                    </Button>
                  )}
                </DownloadExcelHOC> */}

                {/* Button to run the block */}
                <Button
                  variant="link"
                  onClick={handleRunBlocks}
                  disabled={loadingRun}
                >
                  <i className="fas fa-play"></i> Run{" "}
                  {loadingRun && <Spinner animation="border" size="sm" />}
                </Button>
                <Button
                  variant="link"
                  onClick={() => {
                    addToExample();
                  }}
                >
                  <i className="fas fa-plus"></i> Add to training
                </Button>
              </>
            )}
            <Button
              variant="link"
              onClick={handleSaveResults}
              disabled={loadingSave}
            >
              <i className="fas fa-save"></i> Save{" "}
              {loadingSave && <Spinner animation="border" size="sm" />}
            </Button>
          </ButtonGroup>
        </div>
      </Card.Body>
    </Card>
  );
};

const DataSetTree = ({ dataSet, sdkInstance, dataSetInstance, blockProps }) => {
  const { chain } = sdkInstance;
  const { promptBlocks } = chain;

  const treesElement = renderTreeListElements(
    promptBlocks,
    blockProps,
    ResultBlock
  );

  return (
    <div>
      <Card className="mb-0">
        <Card.Header>
          <Card.Title>Results</Card.Title>
        </Card.Header>
        <Card.Body
          style={{ padding: 0 }}
          className="chain-blocks-tree-list-card-body"
        >
          <div className="chain-blocks-tree-list-parent datasets-results">
            {treesElement}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

const DataSetTable = ({
  dataSet,
  sdkInstance,
  blockProps,
  projectId,
  chainId,
}) => {
  const { chain } = sdkInstance;

  return (
    <Card>
      <Card.Header>
        <Card.Title>{dataSet.name}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Parameter</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(dataSet.parameters).map((parameter) => (
              <tr key={parameter}>
                <td>{parameter}</td>
                <td>{dataSet.parameters[parameter]}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Result</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(dataSet.results).map((result) => (
              <tr key={result}>
                <td>{result}</td>
                <td>{dataSet.results[result]}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

const DataSetContentView = ({
  dataSet,
  projectId,
  chainId,
  sdkInstance,
  onShowCanvas,
  blockProps,
  cloneData,
  dataSetMethods,
}) => {
  const [dataForm, setDataForm] = useState({
    name: "",
    description: "",
  });
  const [viewMode, setViewMode] = useState("treeView");

  const dataSetId = dataSet && dataSet.id;

  const cloneOnlyParameters = async () => {
    const { parameters } = dataSet;

    await cloneData(parameters);
  };

  useEffect(() => {
    if (dataSet) {
      setDataForm({
        name: dataSet.name || "",
        description: dataSet.description || "",
      });
    }
  }, [dataSetId]);

  const cloneAllData = async () => {
    const { parameters, results } = dataSet;

    await cloneData(parameters, results);
  };

  const onDataSetDataChange = (e) => {
    const { name, value } = e.target;
    setDataForm({
      ...dataForm,
      [name]: value,
    });
  };

  const updateDataSetData = () => {
    console.log("updateDataSetData", dataForm);
    dataSetMethods.updateData(dataForm);
  };

  const setDataFormAndUpdate = (data) => {
    const newData = {
      ...dataForm,
      ...data,
    };
    setDataForm(newData);
    dataSetMethods.updateData(newData);
  };

  return (
    <div>
      <div className="d-flex">
        <Nav>
          <Nav.Item>
            <Nav.Link
              eventKey="showMenu"
              onClick={() => onShowCanvas("datasets")}
              className="text-secondary"
            >
              See all data
            </Nav.Link>
          </Nav.Item>

          {/* Disabled item */}
          <Nav.Item>
            <Nav.Link eventKey="disabled" disabled>
              |
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              eventKey="showParameters"
              onClick={() => onShowCanvas("parameters")}
            >
              Parameters
            </Nav.Link>
          </Nav.Item>

          {/* Editable name */}
          <Nav.Item>
            <Nav.Link eventKey="disabled" disabled>
              |
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="name">
              <ClickableTextInput
                placeholder="Edit name"
                value={dataForm.name}
                onChange={(value) => {
                  setDataFormAndUpdate({
                    ...dataForm,
                    name: value,
                  });
                }}
              />
            </Nav.Link>
          </Nav.Item>

          <NavDropdown title="Data" id="nav-dropdown">
            <NavDropdown.Item onClick={cloneOnlyParameters}>
              Clone data (only parameters)
            </NavDropdown.Item>
            <NavDropdown.Item onClick={cloneAllData}>
              Clone full data
            </NavDropdown.Item>
          </NavDropdown>

          {/* Disabled item */}
          <Nav.Item>
            <Nav.Link eventKey="disabled" disabled>
              |
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Nav
          className="mb-1"
          defaultActiveKey="simpleView"
          onSelect={(selectedKey) => setViewMode(selectedKey)}
        >
          <Nav.Item>
            <Nav.Link eventKey="simpleView">
              <i className="fas fa-list"></i>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="treeView">
              <i className="fa-solid fa-sitemap"></i>
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      <div>
        {viewMode === "simpleView" && (
          <DataSetTable
            blockProps={blockProps}
            dataSet={dataSet}
            projectId={projectId}
            chainId={chainId}
            sdkInstance={sdkInstance}
          />
        )}
        {viewMode === "treeView" && (
          <DataSetTree
            blockProps={blockProps}
            dataSet={dataSet}
            sdkInstance={sdkInstance}
          />
        )}
      </div>
    </div>
  );
};

const OffCanvasParametersFields = ({
  show,
  onHide,
  parameters,
  setParameters,
  onSave,
  parametersKeys,
  projectId,
}) => {
  const offcanvasRef = React.useRef(null);
  const onExcelResult = (result, parametersKey) => {
    const stringified = JSON.stringify(result);
    setParameters({
      ...parameters,
      [parametersKey]: stringified,
    });
  };

  const onDocxResult = (result, parametersKey) => {
    setParameters({
      ...parameters,
      [parametersKey]: result,
    });
  };

  const onChainDataResult = (result, parametersKey) => {
    setParameters({
      ...parameters,
      [parametersKey]: result,
    });
  };

  return (
    <Offcanvas show={show} placement="end" onHide={onHide}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Parameters</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body ref={offcanvasRef}>
        <Form>
          {parametersKeys.map((parameterKey, index) => (
            <div key={parameterKey}>
              <Form.Group className="mb-3" controlId={parameterKey}>
                <Form.Label>{parameterKey}</Form.Label>
                <AudioInput
                  as="textarea"
                  rows={3}
                  placeholder={parameterKey}
                  value={parameters[parameterKey] || ""}
                  onChange={(e) =>
                    setParameters({
                      ...parameters,
                      [parameterKey]: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <div>
                <Dropdown>
                  <Dropdown.Toggle variant="dark" id="dropdown-basic">
                    <i className="fas fa-file-upload"></i> Import
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <UploadExcelHOC
                      onResult={(result) => onExcelResult(result, parameterKey)}
                    >
                      {({ openExcel }) => (
                        <Dropdown.Item onClick={openExcel}>
                          <i className="fas fa-file-excel"></i> Excel
                        </Dropdown.Item>
                      )}
                    </UploadExcelHOC>
                    <UploadDocxHOC
                      onResult={(result) => onDocxResult(result, parameterKey)}
                    >
                      {({ openDocx }) => (
                        <Dropdown.Item onClick={openDocx}>
                          <i className="fas fa-file-word"></i> Docx
                        </Dropdown.Item>
                      )}
                    </UploadDocxHOC>
                    {/* Separator */}
                    <Dropdown.Divider />
                    <ImportChainData
                      onResult={(result) =>
                        onChainDataResult(result, parameterKey)
                      }
                      apiProjectId={projectId}
                      parentRef={offcanvasRef}
                    >
                      {({ importChainData }) => (
                        <Dropdown.Item onClick={importChainData}>
                          <i className="fas fa-link"></i> Chain Data
                        </Dropdown.Item>
                      )}
                    </ImportChainData>

                    <ImportApiProjectChainData
                      onResult={(result) => {
                        onChainDataResult(result, parameterKey);
                      }}
                      parentRef={offcanvasRef}
                    >
                      {({ importApiProjectChainData }) => (
                        <Dropdown.Item onClick={importApiProjectChainData}>
                          <i className="fas fa-link"></i> API Project Chain Data
                        </Dropdown.Item>
                      )}
                    </ImportApiProjectChainData>

                    {/* <Dropdown.Item onClick={() => {}}>
                      Data - Other Logic System
                    </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {index !== parametersKeys.length - 1 && <hr />}
            </div>
          ))}
          <hr />
          <div className="d-flex justify-content-end mt-3">
            <Button variant="primary" onClick={onSave}>
              Save
            </Button>
          </div>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

// asc, undefined name last, string number last
const orderByName = (a, b) => {
  if (!a.name && !b.name) {
    return 0;
  }
  if (!a.name) {
    return 1;
  }
  if (!b.name) {
    return -1;
  }

  const nameA = a.name.toLowerCase();
  const nameB = b.name.toLowerCase();

  if (nameA < nameB) {
    return -1;
  }

  if (nameA > nameB) {
    return 1;
  }

  return 0;
};

const getOrderedDataList = (dataSets) => {
  const newDataSets = [...dataSets];
  return newDataSets.sort(orderByName);
};

const OffCanvasDataSetList = ({
  show,
  dataSets,
  selectedDataSet,
  setSelectedDataSet,
  createDataSetHandler,
  onHide,
}) => {
  const orderedDataSets = getOrderedDataList(dataSets);

  return (
    <Offcanvas show={show} placement="start" onHide={onHide}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Data Sets</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div>
          <Button variant="link" onClick={createDataSetHandler}>
            Create A Data Line
          </Button>
        </div>
        <hr />
        <ListGroup>
          {orderedDataSets.map((dataSet) => (
            <ListGroup.Item
              key={dataSet.id}
              action
              onClick={() => setSelectedDataSet(dataSet)}
              active={selectedDataSet && selectedDataSet.id === dataSet.id}
            >
              {dataSet.name || dataSet.id}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

const DataSetsDatabase = ({ projectId, chainId }) => {
  const { notify } = useNotif();
  const [showCanvas, setShowCanvas] = useState(false);
  const [selectedDataSetId, setSelectedDataSetId] = useState(null);

  const [sdkInstance, loading, error, dataSets, createDataSet] = useApiProject(
    projectId,
    chainId,
    {
      db: firebaseDB,
    }
  );
  const [dataSet, dataSetMethods, dataSetLoading, dataSetError] = useDataSet(
    sdkInstance,
    selectedDataSetId
  );

  useEffect(() => {
    if (dataSets && dataSets.length > 0 && !selectedDataSetId) {
      setSelectedDataSetId(dataSets[0].id);
    } else if (
      dataSets &&
      !showCanvas &&
      sdkInstance &&
      dataSets.length === 0
    ) {
      setShowCanvas("datasets");
    }
  }, [dataSets]);

  if (!sdkInstance) {
    return null;
  }

  const { project: apiProject, chain } = sdkInstance;

  if (!apiProject) {
    return null;
  }

  const selectDataSet = (dataSet) => {
    const dataSetId = dataSet.id;
    setSelectedDataSetId(dataSetId);
  };

  const createDataSetHandler = async () => {
    try {
      const uid = firebaseAuth.currentUser.uid;
      const dataSetId = await createDataSet({}, {}, uid, {
        name: "Unnamed - " + new Date().toLocaleString().replace(/[/: ]/g, ""),
      });
      notify({
        variant: "success",
        message: `DataSet created.`,
      });
      setShowCanvas(null);
      console.log("dataSetId", dataSetId);
      setSelectedDataSetId(dataSetId);
    } catch (e) {
      console.error("error saving chain", e);
      notify({
        variant: "danger",
        message: `Error creating dataSet for chain ${chain.name}.`,
      });
    }
  };

  const cloneDataSetHandler = async (parameters = {}, results = {}) => {
    try {
      const uid = firebaseAuth.currentUser.uid;
      const dataSetId = await createDataSet(parameters, results, uid, {
        name: `${dataSet.name || "Unnamed"} - Copy - ${new Date()
          .toLocaleString()
          .replace(/[/: ]/g, "")}`,
      });
      notify({
        variant: "success",
        message: `DataSet cloned.`,
      });
      setShowCanvas(null);
      console.log("dataSetId", dataSetId);
      setSelectedDataSetId(dataSetId);
    } catch (e) {
      console.error("error saving chain", e);
      notify({
        variant: "danger",
        message: `Error cloning dataSet for chain ${chain.name}.`,
      });
    }
  };

  const onSaveDataSet = async () => {
    try {
      await dataSetMethods.saveData();
      notify({
        variant: "success",
        message: `DataSet saved.`,
      });
      setShowCanvas(null);
    } catch (e) {
      console.error("error saving chain", e);
      notify({
        variant: "danger",
        message: `Error saving DataSet.`,
      });
    }
  };

  let paramatersKeys = [];

  if (sdkInstance && chain) {
    paramatersKeys = sdkInstance.getChainParametersKeys().reduce((acc, key) => {
      if (acc.indexOf(key) === -1) {
        acc.push(key);
      }
      return acc;
    }, []);
    paramatersKeys = paramatersKeys.sort();
  }

  const blockProps = {
    dataSet: dataSet,
    runBlock: async (blockVariableName, resultIndex) => {
      const runnedBlocks = await dataSetMethods.runByExportedVariables(
        blockVariableName,
        resultIndex
      );
      notify({
        variant: "success",
        message: "Block runned",
      });
      // setLoading(false);
    },
    selectBlock: (block) => {},
    saveResults: async () => {
      await dataSetMethods.saveData();
    },
    handleOnResultChange: (results) => {
      dataSetMethods.setResults(results);
    },
    isParentsArrayByResultKey: (resultKey) => {
      return sdkInstance.isParentsArray(resultKey);
    },
    getBlockResultType: (resultKey) => {
      return sdkInstance.getResultType(resultKey);
    },
    getParentArrayResultKey: (resultKey) => {
      return sdkInstance.getParentArrayResultKey(resultKey);
    },
    getImportedArrayValueKey: (resultKey) => {
      return sdkInstance.getImportedArrayValueKey(resultKey);
    },
    addExamplesToBuiltPrompt: async (resultKey, example, resultIndex) => {
      return dataSetMethods.addExamplesToBuiltPrompt(
        resultKey,
        example,
        resultIndex
      );
    },
  };

  return (
    <Container fluid>
      <OffCanvasDataSetList
        onHide={() => setShowCanvas(null)}
        show={showCanvas === "datasets"}
        dataSets={dataSets}
        selectedDataSet={dataSet}
        setSelectedDataSet={
          (newSelectedDataSet) => setSelectedDataSetId(newSelectedDataSet.id)
          // setSelectedDataSet(newSelectedDataSet)
        }
        createDataSetHandler={createDataSetHandler}
      />
      {dataSet && (
        <OffCanvasParametersFields
          projectId={projectId}
          parametersKeys={paramatersKeys}
          onHide={() => setShowCanvas(null)}
          show={showCanvas === "parameters"}
          parameters={dataSet.parameters}
          setParameters={(newParameters) =>
            dataSetMethods.setParameters(newParameters)
          }
          onSave={onSaveDataSet}
        />
      )}
      <Row>
        <Col xs={12}>
          {dataSet && (
            <DataSetContentView
              dataSetMethods={dataSetMethods}
              blockProps={blockProps}
              cloneData={cloneDataSetHandler}
              onShowCanvas={(canvasName) => setShowCanvas(canvasName)}
              sdkInstance={sdkInstance}
              dataSet={dataSet}
              projectId={projectId}
              chainId={chainId}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export const DataSetsDatabaseModal = ({ projectId, chainId, show, onHide }) => {
  const containerRef = React.useRef(null);

  return (
    // make modal width 90vw and height 90vh
    <Modal
      show={show}
      onHide={onHide}
      fullscreen={true}
      className="data-sets-database-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Playground <br/>
          <span style={{ fontSize: "14px" }}>
            Project: {projectId} - Chain: {chainId}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body ref={containerRef}>
        <AiEngineErrors containerRef={containerRef} />
        <DataSetsDatabase projectId={projectId} chainId={chainId} />
      </Modal.Body>
    </Modal>
  );
};
