import React from "react";
import PageComponent from "./PageComponent";

const CenterAreaComponent = ({
  selectedPage,
  editorMode,
  actions,
  webApp,
  dataModels,
  pages,
}) => {
  return (
    <div className={"CenterAreaComponent"}>
      {selectedPage && (
        <PageComponent
          pages={pages}
          dataModels={dataModels}
          webApp={webApp}
          actions={actions}
          page={selectedPage}
          editorMode={editorMode}
        />
      )}
      {!selectedPage && (
        <div className="no-page-selected">
          <h2>No Page Selected</h2>
          <p>Please select a page from the left sidebar to view it here.</p>
        </div>
      )}
    </div>
  );
};

export default CenterAreaComponent;
