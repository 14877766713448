import React, { useState } from "react";
import DeploymentList from "./DeploymentList";
import CreateCreaxysDeploymentForm from "./CreateCreaxysDeploymentForm";
import "./CreaxysDeployments.css";
import {
  DeploymentProvider,
  useDeployments
} from "../../../../contexts/DeploymentContext";

const CreaxysDeployments = () => {
  const { deployments } = useDeployments();
  const [view, setView] = useState("list"); // 'list' ou 'create'

  const handleCreateNew = () => {
    setView("create");
  };

  const handleReturnToList = () => {
    setView("list");
  };

  return (
    <div className="creaxys-deployments">
      {view === "list" && (
        <div className="deployments-header">
          <button onClick={handleCreateNew}>
            Créer un Nouveau Déploiement
          </button>
          {/* Autres actions et options de filtrage */}
        </div>
      )}

      {view === "list" ? (
        <DeploymentList />
      ) : (
        <CreateCreaxysDeploymentForm onReturnToList={handleReturnToList} />
      )}
    </div>
  );
};

export default () => {
  return (
    <DeploymentProvider queryObject={{ resourceType: "managed" }}>
      <CreaxysDeployments />
    </DeploymentProvider>
  );
};
