import React, { useState } from "react";
import { FaTimes, FaPlus } from "react-icons/fa";
import "./AuthChecker.css";

/**
 * @typedef {Object} APIMiddleware
 * @property {string} name - The name of the middleware.
 * @property {string} middlewareType - The type of the middleware (e.g., "custom").
 * @property {string} middlewarePreset - The code or script of the middleware.
 * @property {Record<string, any>} middlewareOptions - The options for the middleware.
 * @property {string} middlewareOptionsType - The type of the middleware options (e.g., "json", "xml").
 * @property {AuthTokenGroup[]} authTokenGroups - The ID of the groups authorized to use this middleware.
 */

/**
 * @typedef {Object} AuthTokenGroup - authTokenGroups
 * @property {string} name - The name of the token group.
 * @property {string} id - The ID of the token group.
 * @property {string} permissionState - The permission state of the token group ("authorize" or "unauthorize").
 */

/**
 * @typedef {Object} TokenGroup - tokenGroups
 * @property {string} id - The unique identifier for the group.
 * @property {string} name - The name of the group.
 * @property {string} description - The description of the group.
 * @property {string[]} permissions - The list of permissions granted to this group.
 * @property {string[]} apiKeys - The list of API keys associated with this group.
 * @property {Object} rateLimit - The rate limit configuration.
 * @property {number} rateLimit.requests - The number of allowed requests per time unit.
 * @property {("second"|"minute"|"hour"|"day")} rateLimit.timeUnit - The time unit for the rate limit.
 */

/**
 * AuthChecker component.
 * @param {Object} props - The component props.
 * @param {AuthTokenGroup[]} props.tokenGroups - The token groups.
 * @param {Function} props.onMiddlewareChange - The callback function for middleware change.
 * @param {APIMiddleware} props.middleware - The middleware.
 * @returns {JSX.Element} The AuthChecker component.
 */
const AuthChecker = ({ tokenGroups, onMiddlewareChange, middleware }) => {
  const [selectedTokenGroupId, setSelectedTokenGroupId] = useState("");
  const middlewareTokenGroups = middleware.authTokenGroups || [];

  /**
   * Handles adding a token group.
   */
  const handleAddTokenGroup = () => {
    if (selectedTokenGroupId) {
      const tokenGroup = tokenGroups.find(
        (group) => group.id === selectedTokenGroupId
      );
      if (tokenGroup) {
        setSelectedTokenGroupId("");
        onMiddlewareChange({
          ...middleware,
          authTokenGroups: [
            ...middlewareTokenGroups,
            {
              id: tokenGroup.id,
              name: tokenGroup.name,
              permissionState: "authorize"
            }
          ]
        });
      }
    }
  };

  /**
   * Handles updating the access of a token group.
   */
  const onUpdateAccess = (groupId, permissionState) => {
    const newTokenGroups = middlewareTokenGroups.map((group) => {
      if (group.id === groupId) {
        return {
          ...group,
          permissionState
        };
      }
      return group;
    });
    onMiddlewareChange({
      ...middleware,
      authTokenGroups: newTokenGroups
    });
  };

  /**
   * Handles removing a token group.
   */
  const onRemoveTokenGroup = (groupId) => {
    const newTokenGroups = middlewareTokenGroups.filter(
      (group) => group.id !== groupId
    );
    onMiddlewareChange({
      ...middleware,
      authTokenGroups: newTokenGroups
    });
  };

  const isTokenGroupAdded = (groupId) => {
    return middlewareTokenGroups.some((group) => group.id === groupId);
  };

  return (
    <div className="auth-checker">
      <div className="add-token-group">
        <select
          value={selectedTokenGroupId}
          onChange={(e) => setSelectedTokenGroupId(e.target.value)}
        >
          <option value="">Select Token Group</option>
          {tokenGroups.map((group) => (
            <option
              key={group.id}
              value={group.id}
              disabled={isTokenGroupAdded(group.id)}
            >
              {group.name}
            </option>
          ))}
        </select>
        <button onClick={handleAddTokenGroup} disabled={!selectedTokenGroupId}>
          <FaPlus />
        </button>
      </div>

      <div className="token-groups-list">
        {middlewareTokenGroups.map((group) => (
          <div key={group.id} className="token-group-item">
            <span>{group.name}</span>
            <select onChange={(e) => onUpdateAccess(group.id, e.target.value)}>
              <option value="authorize">Authorize</option>
              <option value="unauthorize">Unauthorize</option>
            </select>
            <button onClick={() => onRemoveTokenGroup(group.id)}>
              <FaTimes />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

const PERMISSION_STATES = {
  authorize: "authorized",
  unauthorize: "unauthorized"
};

/**
 * AuthCheckerDetails component.
 * @param {Object} props - The component props.
 * @param {APIMiddleware} props.middleware - The middleware.
 * @returns {JSX.Element} The AuthCheckerDetails component.
 */
export const AuthCheckerDetails = ({ middleware, lite }) => {
  return (
    <div className={`middleware-details-container ${lite ? "lite" : ""}`}>
      <div className="detail-item">
        <span>Type:</span>
        <span>{middleware.middlewareType}</span>
      </div>
      {middleware.authTokenGroups.map((group) => (
        <div key={group.id} className="detail-item">
          <span>Group:</span>
          <span>
            {group.name} - {PERMISSION_STATES[group.permissionState]}
          </span>
        </div>
      ))}
    </div>
  );
};

export default AuthChecker;
