import React, { useState } from "react";
import { FaTimes, FaPlus, FaToggleOn, FaToggleOff } from "react-icons/fa";

// Try using the icons here and see which one is the best for the `allowedOrigins` identifier
import {
  FaGlobe,
  FaMapMarkerAlt,
  FaCompass,
  FaLocationArrow
} from "react-icons/fa";
// Try using the icons here and see which one is the best for the `allowedMethods` identifier

// Option 1: Using React Icons
import { FaLock, FaArrowRight, FaPencilAlt, FaTrashAlt } from "react-icons/fa";

// Option 2: Using React Icons
import {
  BsLockFill,
  BsArrowRight,
  BsPencilSquare,
  BsTrashFill
} from "react-icons/bs";

// Option 3: Using React Icons
import { FiShield, FiArrowUp, FiPlusCircle, FiTrash2 } from "react-icons/fi";

// Option 4: Using React Icons
import {
  AiOutlineLock,
  AiOutlineArrowDown,
  AiOutlinePlus,
  AiOutlineDelete
} from "react-icons/ai";

// Option 1: Using React Icons
import {
  RiLock2Line,
  RiUnlockLine,
  RiKeyLine,
  RiShieldStarLine
} from "react-icons/ri";

// Option 2: Using React Icons
import { FaUnlock, FaKey, FaShieldAlt } from "react-icons/fa";

// Option 3: Using Boxicons icons
import { BiLock, BiUnlock, BiKey, BiShieldAlt } from "react-icons/bi";

// Option 4: Using Feather icons
import { FiLock, FiUnlock, FiKey } from "react-icons/fi";

// Try using the icons here and see which one is the best for the `exposedHeaders` identifier
import {
  FaEye,
  FaEyeSlash,
  FaEyeDropper,
  FaEyeSlashSlash
} from "react-icons/fa";

// i want to try 4 different icons to use to identify allowdOrigins tags easily and want to the best suitable from react-icons and then chose

import "./CorsHandler.css";

/**
 * Represents the CorsConfig type.
 * @typedef {Object} CorsConfig
 * @property {string[]} allowedOrigins - The allowed origins.
 * @property {string[]} allowedMethods - The allowed methods.
 * @property {string[]} allowedHeaders - The allowed headers.
 * @property {string[]} exposedHeaders - The exposed headers.
 * @property {boolean} credentials - Indicates if credentials are allowed.
 * @property {number} maxAge - The maximum age.
 */

/**
 * Represents the APIMiddleware type.
 * @typedef {Object} APIMiddleware
 * @property {string} name - The name of the middleware.
 * @property {string} middlewareType - The type of the middleware.
 * @property {string} middlewarePreset - The code or script of the middleware.
 * @property {Record<string, any>} middlewareOptions - The options of the middleware.
 * @property {string} middlewareOptionsType - The type of the middleware options.
 * @property {AuthTokenGroup[]} authTokenGroups - The authorized token groups.
 * @property {RateLimiterConfig[]} rateLimiterConfigs - The rate limiter configurations.
 * @property {CorsConfig} corsConfig - The CORS configuration.
 */

const CONFIG_LISTS = [
  "allowedOrigins",
  "allowedMethods",
  "allowedHeaders",
  "exposedHeaders"
];
const getConfigLists = (config) => {
  return CONFIG_LISTS.reduce((acc, list) => {
    if (config[list]) {
      acc[list] = config[list] || [];
    }
    return acc;
  }, {});
};

/**
 * Represents the CorsHandler component.
 * @param {Object} props - The component props.
 * @param {Object} props.middleware - The middleware object.
 * @param {Function} props.onMiddlewareChange - The function to handle middleware changes.
 * @returns {JSX.Element} The CorsHandler component.
 */
const CorsHandler = ({ middleware, onMiddlewareChange }) => {
  const corsConfig = middleware?.corsConfig || {};
  const [newItem, setNewItem] = useState({
    allowedOrigins: "",
    allowedMethods: "",
    allowedHeaders: "",
    exposedHeaders: ""
  });
  const [credentials, setCredentials] = useState(
    corsConfig.credentials || false
  );
  const [maxAge, setMaxAge] = useState(corsConfig.maxAge || 0);
  const configLists = getConfigLists(corsConfig);

  const handleCorsChange = (updatedConfig) => {
    onMiddlewareChange({ ...middleware, corsConfig: updatedConfig });
  };

  const handleAddItem = (type) => {
    if (!newItem[type]) return;
    let updatedConfig = { ...corsConfig };
    if (!updatedConfig[type]) updatedConfig[type] = [];
    updatedConfig[type].push(newItem[type]);
    handleCorsChange(updatedConfig);
    setNewItem({ ...newItem, [type]: "" });
  };

  const onEnterPress = (e, type) => {
    if (e.keyCode === 13) {
      handleAddItem(type);
    }
  };

  const handleRemoveItem = (type, index) => {
    const updatedConfig = { ...corsConfig };
    updatedConfig[type].splice(index, 1);
    handleCorsChange(updatedConfig);
  };

  const handleCredentialsToggle = () => {
    setCredentials(!credentials);
    handleCorsChange({ ...corsConfig, credentials: !credentials });
  };

  const handleMaxAgeChange = (e) => {
    const newMaxAge = Number(e.target.value);
    setMaxAge(newMaxAge);
    handleCorsChange({ ...corsConfig, maxAge: newMaxAge });
  };

  const renderIcon = (type) => {
    switch (type) {
      case "allowedOrigins":
        return <FaShieldAlt />;
      case "allowedMethods":
        return <FaArrowRight />;
      case "allowedHeaders":
        return <RiKeyLine />;
      case "exposedHeaders":
        return <FaEye />;
      default:
        return null;
    }
  };

  const renderList = (type, items = []) => {
    return (
      <>
        {items.map((item, index) => (
          <div key={index} className={`cors-item ${type}`}>
            <div className="cors-item-icon">{renderIcon(type)}</div>
            <span>{item}</span>
            <button onClick={() => handleRemoveItem(type, index)}>
              <FaTimes />
            </button>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="cors-handler">
      <div className="cors-section-list">
        {Object.entries(newItem).map(([type, value]) => (
          <div key={type} className="cors-section">
            <input
              type={type === "maxAge" ? "number" : "text"}
              value={value}
              onChange={(e) =>
                setNewItem({ ...newItem, [type]: e.target.value })
              }
              placeholder={`Add ${
                type.charAt(0).toUpperCase() + type.slice(1)
              }`}
              onKeyDown={(e) => onEnterPress(e, type)}
            />
            <button onClick={() => handleAddItem(type)}>
              <FaPlus />
            </button>
          </div>
        ))}
      </div>

      <div className="cors-list">
        {Object.keys(configLists).map((type) =>
          renderList(type, configLists[type])
        )}
      </div>

      <div className="cors-toggle">
        <span>Credentials:</span>
        <button onClick={handleCredentialsToggle}>
          {credentials ? <FaToggleOn /> : <FaToggleOff />}
        </button>
      </div>

      <div className="cors-max-age">
        <span>Max Age:</span>
        <input
          type="number"
          value={maxAge}
          onChange={handleMaxAgeChange}
          placeholder="Max Age in Seconds"
        />
      </div>
    </div>
  );
};

export const CorsHandlerDetails = ({ middleware, lite }) => {
  const { corsConfig } = middleware;

  return (
    <div
      className={`middleware-details-container cors-handler-details ${
        lite ? "lite" : ""
      }`}
    >
      <div className="detail-item">
        <span>Allowed Origins:</span>
        <ul className="detail-list">
          {corsConfig.allowedOrigins.map((origin, index) => (
            <li key={index} className="detail-list-item">
              <span>{origin}</span>
            </li>
          ))}
        </ul>
      </div>

      {/* Répéter pour allowedMethods, allowedHeaders, exposedHeaders */}

      <div className="detail-item">
        <span>Allowed Methods:</span>
        <ul className="detail-list">
          {corsConfig.allowedMethods.map((method, index) => (
            <li key={index} className="detail-list-item">
              <span>{method}</span>
            </li>
          ))}
        </ul>
      </div>

      <div className="detail-item">
        <span>Allowed Headers:</span>
        <ul className="detail-list">
          {corsConfig.allowedHeaders.map((header, index) => (
            <li key={index} className="detail-list-item">
              <span>{header}</span>
            </li>
          ))}
        </ul>
      </div>

      <div className="detail-item">
        <span>Exposed Headers:</span>
        <ul className="detail-list">
          {corsConfig.exposedHeaders.map((header, index) => (
            <li key={index} className="detail-list-item">
              <span>{header}</span>
            </li>
          ))}
        </ul>
      </div>

      <div className="detail-item">
        <span>Credentials:</span>
        <span>{corsConfig.credentials ? "Enabled" : "Disabled"}</span>
      </div>

      <div className="detail-item">
        <span>Max Age:</span>
        <span>{corsConfig.maxAge} seconds</span>
      </div>
    </div>
  );
};

export default CorsHandler;
