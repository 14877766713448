import React, { useState, useRef, useContext, useEffect } from "react";
import { useDrop, useDrag } from "react-dnd";
import { DeploymentModelContext } from "../../../../../contexts/DeploymentModelContext";
import EndpointItem from "./EndpointItem";
import RouteItem from "./RouteItem";
import MiddlewareItem from "./MiddlewareItem";
import { API_COMPONENT_DEFAULTS } from "../../../../../contexts/DeploymentModelContext";
import "./ApiModelConstructionZone.css";
import { ApiBuilderContext } from "../../../../../contexts/ApiBuilderContext";
import _ from "lodash";
import ApiComponentsList from "./ApiComponentsList";
import ConstructionZoneNavbar from "./ConstructionZoneNavbar";

// export type APIEndpoint = {
//   name: string;
//   method: "GET" | "POST" | "PUT" | "DELETE"; // et autres méthodes HTTP si nécessaire
//   url: string;
//   headers: APIHeader[];
//   body: string;
//   query: string;
//   response: string;
//   responseHeaders: APIHeader[];
//   responseType: string; // "json", "xml", etc.
//   responseHeadersType: string;
//   chains: string[]; // ID des chaînes que cette API peut déclencher
//   blocks: string[]; // ref des blocs que cette API peut déclencher
//   selectedChain: string; // ID de la chaîne sélectionnée
//   selectedBlock: string; // ref du bloc sélectionné
//   middlewares: APIMiddleware[];
// };

// export type APIRoute = {
//   name: string;
//   url: string;
//   // Vous pouvez ajouter d'autres propriétés si nécessaire
//   routes: APIRoute[];
//   middlewares: APIMiddleware[];
//   endpoints: APIEndpoint[];
// };

// export type APIMiddleware = {
//   name: string;
//   type: "middleware";
//   middlewareType: string; // "custom" ou autres types
//   middleware: string; // code ou script du middleware
//   middlewareOptions: Record<string, any>;
//   middlewareOptionsType: string; // "json", "xml", etc.
// };

const removeComponentById = (id, componentsList) => {
  if (!componentsList) {
    return;
  }

  // Supprimer directement si trouvé
  _.remove(componentsList, (component) => component.id === id);

  // Sinon, chercher récursivement dans les sous-composants
  componentsList.forEach((component) => {
    if (component.type === "route") {
      removeComponentById(id, component.routes);
      removeComponentById(id, component.endpoints);
      removeComponentById(id, component.middlewares);
    } else if (component.type === "endpoint") {
      removeComponentById(id, component.middlewares);
    }
  });
};

const addComponentById = (id, componentsList, newComponent) => {
  if (!componentsList) {
    return false;
  }

  if (!id) {
    componentsList.push(newComponent);
    return true;
  }

  for (let i = 0; i < componentsList.length; i++) {
    const component = componentsList[i];
    if (component.id === id) {
      // Ajouter à la liste appropriée en fonction du type de component
      if (newComponent.type === "route") {
        if (!component.routes) {
          component.routes = [];
        }
        component.routes.push(newComponent);
      } else if (newComponent.type === "endpoint") {
        if (!component.endpoints) {
          component.endpoints = [];
        }
        component.endpoints.push(newComponent);
      } else if (newComponent.type === "middleware") {
        if (!component.middlewares) {
          component.middlewares = [];
        }
        component.middlewares.push(newComponent);
      }
      return true;
    }

    // Chercher récursivement
    if (component.type === "route") {
      const added =
        addComponentById(id, component.routes, newComponent) ||
        addComponentById(id, component.endpoints, newComponent) ||
        addComponentById(id, component.middlewares, newComponent);
      if (added) return true;
    } else if (component.type === "endpoint") {
      const added = addComponentById(
        id,
        component.middlewares || [],
        newComponent
      );
      if (added) return true;
    }
  }

  return false;
};

const findComponentInRoute = (id, route) => {
  let component =
    findComponent(id, route.endpoints || []) ||
    findComponent(id, route.routes || []) ||
    findComponent(id, route.middlewares || []);
  if (component) {
    return component;
  }
};

const findComponentInEndpoint = (id, endpoint) => {
  const component =
    endpoint.middlewares &&
    endpoint.middlewares.find((middleware) => middleware.id === id);
  if (component) {
    return component;
  }
  return null;
};

const findComponent = (id, componentsList) => {
  let component = componentsList.find((component) => component.id === id);
  let iComponent = null;
  if (component) {
    return component;
  }

  for (let i = 0; i < componentsList.length; i++) {
    iComponent = componentsList[i];

    if (iComponent.type === "route") {
      component = findComponentInRoute(id, iComponent);
      if (component) {
        return component;
      }
    } else if (iComponent.type === "endpoint") {
      component = findComponentInEndpoint(id, iComponent);
      if (component) {
        return component;
      }
    }
  }
  return null;
};

const findParentComponent = (id, componentsList, parent = null) => {
  if (!componentsList) {
    return null;
  }

  for (let i = 0; i < componentsList.length; i++) {
    const component = componentsList[i];

    if (component.id === id) {
      return parent;
    }

    // Chercher récursivement dans les sous-composants
    let foundParent = null;
    if (component.type === "route") {
      foundParent =
        findParentComponent(id, component.routes, component) ||
        findParentComponent(id, component.endpoints, component) ||
        findParentComponent(id, component.middlewares, component);
    } else if (component.type === "endpoint") {
      foundParent = findParentComponent(id, component.middlewares, component);
    }

    if (foundParent) {
      return foundParent;
    }
  }

  return null;
};

const isComponentChildOf = (childId, parentId, components) => {
  const parentComponent = findComponent(parentId, components);
  const childComponent = findComponent(childId, components);

  if (!parentComponent || !childComponent) {
    return false;
  }

  if (parentComponent.type === "route") {
    return (
      findComponent(childId, parentComponent.routes || []) ||
      findComponent(childId, parentComponent.endpoints || []) ||
      findComponent(childId, parentComponent.middlewares || [])
    );
  } else if (parentComponent.type === "endpoint") {
    return findComponent(childId, parentComponent.middlewares || []);
  }

  return false;
};

const moveComponent = (dragId, hoverId, componentsList, position) => {
  const dragComponent = _.cloneDeep(findComponent(dragId, componentsList));
  const hoverComponent = findComponent(hoverId, componentsList);
  const parentHoverComponent = findParentComponent(hoverId, componentsList);
  let spliceIndexOffset = 1;

  if (!dragComponent || !hoverComponent) {
    return;
  }

  if (position === "after") {
    spliceIndexOffset = 1;
  } else if (position === "before") {
    spliceIndexOffset = 0;
  }

  if (!parentHoverComponent) {
    removeComponentById(dragId, componentsList);

    let hoverIndex = componentsList.findIndex((c) => c.id === hoverId);

    if (position === "after") {
      componentsList.splice(hoverIndex + 1, 0, dragComponent);
    } else if (position === "before") {
      componentsList.splice(hoverIndex, 0, dragComponent);
    }
    return;
  }

  if (dragComponent.type === "route") {
    removeComponentById(dragId, componentsList);

    if (!parentHoverComponent.routes) {
      parentHoverComponent.routes = [];
    }

    let hoverIndex = parentHoverComponent.routes.findIndex(
      (c) => c.id === hoverId
    );

    parentHoverComponent.routes.splice(
      hoverIndex + spliceIndexOffset,
      0,
      dragComponent
    );

    updateComponentById(parentHoverComponent.id, componentsList, {
      routes: parentHoverComponent.routes
    });
  } else if (dragComponent.type === "endpoint") {
    removeComponentById(dragId, componentsList);
    if (!parentHoverComponent.endpoints) {
      parentHoverComponent.endpoints = [];
    }

    let hoverIndex = parentHoverComponent.endpoints.findIndex(
      (c) => c.id === hoverId
    );

    parentHoverComponent.endpoints.splice(
      hoverIndex + spliceIndexOffset,
      0,
      dragComponent
    );

    updateComponentById(parentHoverComponent.id, componentsList, {
      endpoints: parentHoverComponent.endpoints
    });
  } else if (dragComponent.type === "middleware") {
    removeComponentById(dragId, componentsList);

    if (!parentHoverComponent.middlewares) {
      parentHoverComponent.middlewares = [];
    }

    let hoverIndex = parentHoverComponent.middlewares.findIndex(
      (c) => c.id === hoverId
    );

    parentHoverComponent.middlewares.splice(
      hoverIndex + spliceIndexOffset,
      0,
      dragComponent
    );

    updateComponentById(parentHoverComponent.id, componentsList, {
      middlewares: parentHoverComponent.middlewares
    });
  }
};

const updateComponentById = (id, componentsList, updatedProperties) => {
  if (!componentsList) {
    return false;
  }

  let isUpdated = false;

  for (let i = 0; i < componentsList.length; i++) {
    let component = componentsList[i];

    // Mise à jour du composant si l'ID correspond
    if (component.id === id) {
      // Nettoyer les propriétés existantes, sauf 'id' et 'type'
      // Object.keys(component).forEach((key) => {
      //   if (key !== "id" && key !== "type") {
      //     delete component[key];
      //   }
      // });

      // Ajouter ou mettre à jour les propriétés
      Object.assign(component, updatedProperties);
      isUpdated = true;
      break; // On arrête la boucle car le composant a été trouvé et mis à jour
    }

    // Chercher récursivement dans les sous-composants
    if (!isUpdated && component.type === "route") {
      isUpdated =
        updateComponentById(id, component.routes, updatedProperties) ||
        updateComponentById(id, component.endpoints, updatedProperties) ||
        updateComponentById(id, component.middlewares, updatedProperties);
    } else if (!isUpdated && component.type === "endpoint") {
      isUpdated = updateComponentById(
        id,
        component.middlewares,
        updatedProperties
      );
    }

    if (isUpdated) {
      break; // On arrête la boucle car le composant a été trouvé et mis à jour dans une sous-liste
    }
  }

  return isUpdated;
};

export const componentHasParent = (id, componentsList) => {
  if (!componentsList) {
    return false;
  }

  const parentFound = findParentComponent(id, componentsList);
  return !!parentFound;
};

const getDropVariables = (ref, monitor) => {
  const hoverBoundingRect = ref.current?.getBoundingClientRect();
  const clientOffset = monitor.getClientOffset();
  const hoverClientY = clientOffset.y - hoverBoundingRect.top;

  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
  const hoverMiddleY_1_4 =
    (hoverBoundingRect.bottom - hoverBoundingRect.top) / 4;
  const hoverMiddleY_3_4 =
    ((hoverBoundingRect.bottom - hoverBoundingRect.top) * 3) / 4;

  const isHoveringOnTop = hoverClientY < hoverMiddleY_1_4;
  const isHoveringOnMiddle =
    hoverClientY >= hoverMiddleY_1_4 && hoverClientY <= hoverMiddleY_3_4;
  const isHoveringOnBottom = hoverClientY > hoverMiddleY_3_4;

  return {
    hoverBoundingRect,
    hoverMiddleY,
    clientOffset,
    hoverClientY,
    isHoveringOnTop,
    isHoveringOnMiddle,
    isHoveringOnBottom
  };
};

const getDropVariablesFromDrop = (ref, monitor) => {
  const dropBoundingRect = ref.current?.getBoundingClientRect();
  const clientOffset = monitor.getDropResult();
  const dropClientY = clientOffset.y - dropBoundingRect.top;

  const dropMiddleY = (dropBoundingRect.bottom - dropBoundingRect.top) / 2;
  const dropMiddleY_1_4 = (dropBoundingRect.bottom - dropBoundingRect.top) / 4;
  const dropMiddleY_3_4 =
    ((dropBoundingRect.bottom - dropBoundingRect.top) * 3) / 4;

  const isDroppingOnTop = dropClientY < dropMiddleY_1_4;
  const isDroppingOnMiddle =
    dropClientY >= dropMiddleY_1_4 && dropClientY <= dropMiddleY_3_4;
  const isDroppingOnBottom = dropClientY > dropMiddleY_3_4;

  return {
    dropBoundingRect,
    dropMiddleY,
    clientOffset,
    dropClientY,
    isDroppingOnTop,
    isDroppingOnMiddle,
    isDroppingOnBottom
  };
};

export const canDropOver = (typeDragged, typeHovered) => {
  if (typeDragged === "endpoint" && typeHovered === "route") {
    return true;
  } else if (typeDragged === "middleware" && typeHovered === "route") {
    return true;
  } else if (typeDragged === "middleware" && typeHovered === "endpoint") {
    return true;
  } else if (typeDragged === "route" && typeHovered === "route") {
    return true;
  }
  return false;
};

export const isInSameComponentOf = (dragId, hoverId, componentsList) => {
  const dragComponentParent = findParentComponent(dragId, componentsList);
  const hoverComponentParent = findParentComponent(hoverId, componentsList);

  if (!dragComponentParent && !hoverComponentParent) {
    return true;
  }

  if (!dragComponentParent || !hoverComponentParent) {
    return false;
  }

  return dragComponentParent.id === hoverComponentParent.id;
};

export const DraggableAPIComponent = ({
  id,
  index,
  moveItem,
  children,
  type,
  addMiddlewareToEndpoint,
  addEndpointToRoute,
  addRouteToRoute,
  addMiddlewareToRoute,
  onMovingItemEnd,
  className = ""
}) => {
  const ref = useRef(null);
  const { deploymentModel } = useContext(DeploymentModelContext);
  const { components } = deploymentModel;
  const isMovingItem = useRef(false);
  const componentsRef = useRef(components);

  useEffect(() => {
    componentsRef.current = components;
  }, [components]);

  const [, drop] = useDrop({
    accept: "component",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const { isHoveringOnTop, isHoveringOnBottom } = getDropVariables(
        ref,
        monitor
      );
      let shouldMove = true;
      let indexItem = item.index;

      // Ne déplace pas l'élément sur lui-même
      if (item.id === id) {
        return;
      }

      const isInSameComponent = isInSameComponentOf(item.id, id, components);

      if (isInSameComponent) {
        if (isHoveringOnTop && indexItem > index) {
          moveItem(item.id, id, "before");
          isMovingItem.current = true;
        } else if (isHoveringOnBottom && indexItem < index) {
          moveItem(item.id, id, "after");
          isMovingItem.current = true;
        }
      } else {
        const hoveredHasParent = findParentComponent(id, components);
        if (
          hoveredHasParent &&
          type === "middleware" &&
          item.type !== "middleware"
        ) {
          return;
        }

        if (isHoveringOnTop) {
          moveItem(item.id, id, "before");
          isMovingItem.current = true;
        } else if (isHoveringOnBottom) {
          moveItem(item.id, id, "after");
          isMovingItem.current = true;
        }
      }
    },
    drop(item, monitor) {
      if (!ref.current) {
        return;
      }

      if (item.id === id) {
        return;
      }

      const canDrop = canDropOver(item.type, type);

      if (!canDrop) {
        return;
      }

      const isChild = isComponentChildOf(item.id, id, components);

      if (isChild) {
        return;
      }

      const itemType = item.type;
      let isEventOnMiddle = null;

      try {
        const { isDroppingOnMiddle } = getDropVariablesFromDrop(ref, monitor);
        isEventOnMiddle = isDroppingOnMiddle;
      } catch (e) {}

      try {
        if (typeof isDroppingOnMiddle !== "boolean") {
          const { isHoveringOnMiddle } = getDropVariables(ref, monitor);
          isEventOnMiddle = isHoveringOnMiddle;
        }
      } catch (e) {
        isEventOnMiddle = true;
      }

      if (isMovingItem.current) {
        isMovingItem.current = false;
        onMovingItemEnd && onMovingItemEnd();
      }

      if (monitor.isOver({ shallow: true })) {
        if (type === "route" && itemType === "endpoint" && isEventOnMiddle) {
          addEndpointToRoute(item.id, id);
        } else if (
          (type === "route" || type === "endpoint") &&
          itemType === "middleware" &&
          isEventOnMiddle
        ) {
          type === "route"
            ? addMiddlewareToRoute(item.id, id)
            : addMiddlewareToEndpoint(item.id, id);
        } else if (
          type === "route" &&
          itemType === "route" &&
          isEventOnMiddle
        ) {
          addRouteToRoute(item.id, id);
        }
      }
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: "component",
    item: () => {
      return { id, index, type };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  drag(drop(ref));

  return (
    <div ref={ref} className={className}>
      {children(isDragging)}
    </div>
  );
};

const getRandomId = () => {
  return Math.random().toString(36).substring(7);
};

const getComponentId = () => {
  return getRandomId() + getRandomId();
};

export const ApiModelConstructionZone = () => {
  const { systemOverview } = useContext(ApiBuilderContext);
  const { deploymentModel, actions } = useContext(DeploymentModelContext);
  const [components, setComponents] = useState(
    deploymentModel.components || []
  );
  const componentsRef = useRef(components);

  useEffect(() => {
    componentsRef.current = components;
  }, [components]);

  const handleDrop = (item, components) => {
    const componentType = item.type;
    const componentDefaults = API_COMPONENT_DEFAULTS[componentType];
    const newComponent = {
      ...componentDefaults,
      type: componentType,
      id: getComponentId()
    };
    actions.update({
      ...deploymentModel,
      components: [...components, newComponent]
    });
    setComponents([...components, newComponent]);
    // Update the deployment model with the new components list
  };

  const [, drop] = useDrop(() => ({
    accept: ["endpoint", "route", "middleware"],
    drop: (item, monitor) => {
      if (monitor.canDrop()) {
        handleDrop(item, componentsRef.current);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }));

  const onComponentChange = (component, index) => {
    const newComponents = [...components];
    // newComponents[index] = component;
    updateComponentById(component.id, newComponents, component);
    actions.update({
      ...deploymentModel,
      components: newComponents
    });
    setComponents(newComponents);
  };

  const onRemoveComponent = (id) => {
    const newComponents = [...components];
    removeComponentById(id, newComponents);
    actions.update({
      ...deploymentModel,
      components: newComponents
    });
    setComponents(newComponents);
  };

  const moveItem = (dragId, hoverId, position) => {
    const newComponents = _.cloneDeep(components);

    if (!dragId || !hoverId) {
      return;
    }

    if (position === "after") {
      moveComponent(dragId, hoverId, newComponents, "after");
    } else if (position === "before") {
      moveComponent(dragId, hoverId, newComponents, "before");
    }

    setComponents(newComponents);
  };

  const addMiddlewareToEndpoint = (middlewareId, endpointId) => {
    const middlewareComponent = findComponent(middlewareId, components);
    const newMiddlewareComponent = {
      ...middlewareComponent
    };
    const newComponents = [...components];

    removeComponentById(middlewareId, newComponents);
    addComponentById(endpointId, newComponents, newMiddlewareComponent);

    setComponents(newComponents);
  };

  const addEndpointToRoute = (endpointId, routeId) => {
    const endpointComponent = findComponent(endpointId, components);
    const newEndpointComponent = {
      ...endpointComponent
    };
    const newComponents = [...components];

    removeComponentById(endpointId, newComponents);
    addComponentById(routeId, newComponents, newEndpointComponent);

    setComponents(newComponents);
  };

  const addRouteToRoute = (childRouteId, parentRouteId) => {
    const routeComponent = findComponent(childRouteId, components);
    const newRouteComponent = {
      ...routeComponent
    };
    const newComponents = [...components];

    removeComponentById(childRouteId, newComponents);
    addComponentById(parentRouteId, newComponents, newRouteComponent);

    setComponents(newComponents);
  };

  const addMiddlewareToRoute = (middlewareId, routeId) => {
    const middlewareComponent = findComponent(middlewareId, components);
    const newMiddlewareComponent = {
      ...middlewareComponent
    };
    const newComponents = [...components];

    removeComponentById(middlewareId, newComponents);
    addComponentById(routeId, newComponents, newMiddlewareComponent);

    setComponents(newComponents);
  };

  const draggbaleActions = {
    addMiddlewareToEndpoint,
    addEndpointToRoute,
    addRouteToRoute,
    addMiddlewareToRoute,
    moveItem
  };

  const onMovingItemEnd = () => {
    console.log("onMovingItemEnd");
  };

  return (
    <div className="api-model-construction-zone">
      <ApiComponentsList
        draggableActions={draggbaleActions}
        components={components}
      />
      <div ref={drop} className="construction-zone">
        <ConstructionZoneNavbar />
        {/* <div className="construction-zone-navbar">
          <a href="#documentation" className="navbar-link">
            API Documentation
          </a>
          <a href="#settings" className="navbar-link">
            Settings
          </a>
          <a href="#analytics" className="navbar-link">
            Analytics
          </a>
        </div> */}
        {components.map((component, index) => (
          <DraggableAPIComponent
            {...draggbaleActions}
            type={component.type}
            key={component.id}
            id={component.id}
            index={index}
          >
            {(isDragging) => {
              switch (component.type) {
                case "endpoint":
                  return (
                    <EndpointItem
                      draggbaleActions={draggbaleActions}
                      onMovingItemEnd={onMovingItemEnd}
                      isDragging={isDragging}
                      key={component.id}
                      component={component}
                      onRemoveComponent={onRemoveComponent}
                      onComponentChange={(component) =>
                        onComponentChange(component, index)
                      }
                    />
                  );
                case "route":
                  return (
                    <RouteItem
                      draggbaleActions={draggbaleActions}
                      isDragging={isDragging}
                      key={component.id}
                      onMovingItemEnd={onMovingItemEnd}
                      component={component}
                      onRemoveComponent={onRemoveComponent}
                      onComponentChange={(component) =>
                        onComponentChange(component, index)
                      }
                    />
                  );
                case "middleware":
                  return (
                    <MiddlewareItem
                      isDragging={isDragging}
                      key={component.id}
                      component={component}
                      onMovingItemEnd={onMovingItemEnd}
                      onRemoveComponent={onRemoveComponent}
                      onComponentChange={(component) =>
                        onComponentChange(component, index)
                      }
                    />
                  );
                default:
                  return null;
              }
            }}
          </DraggableAPIComponent>
        ))}
      </div>
    </div>
  );
};

export default ApiModelConstructionZone;
