import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import {
  DeploymentModelContext,
  DeploymentModelProvider
} from "../../../../contexts/DeploymentModelContext";
import { HTML5Backend } from "react-dnd-html5-backend";
import "./LaunchLite.css";
import { LeftBar } from "./LeftBar";
import ApiModelConstructionZone from "./ApiModel/ApiModelConstructionZone";
import { ApiBuilderContext } from "../../../../contexts/ApiBuilderContext";
import LaunchLiteOverview from "./LaunchLiteOverview";

const ConstructionZone = () => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "item",
    drop: () => ({ name: "ConstructionZone" }),
    collect: (monitor) => ({
      isOver: !!monitor.isOver()
    })
  }));

  return (
    <div ref={drop} className="construction-zone">
      {isOver ? "Release to drop" : "Drag a component here"}
    </div>
  );
};

export const ToolElement = ({ type, label, config }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type,
    item: { type, config },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  }));

  return (
    <div
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className="tool-element"
    >
      {label}
    </div>
  );
};

const LaunchLite = () => {
  const { loadSystemOverview } = useContext(ApiBuilderContext);
  const { deploymentModel } = useContext(DeploymentModelContext);

  const deploymentModelType = deploymentModel?.type;

  useEffect(() => {
    loadSystemOverview();
  }, []);

  return (
    <div className="LaunchLite container-fluid d-flex">
      <div className="">
        <LeftBar />
      </div>
      <div className="">
        {deploymentModelType === "api" && <ApiModelConstructionZone />}
        {deploymentModelType === "interface" && <ConstructionZone />}
        {!deploymentModelType && <LaunchLiteOverview />}
      </div>
    </div>
  );
};

export default () => {
  const { subId: deployId } = useParams();

  return (
    <DeploymentModelProvider deployId={deployId}>
      <DndProvider backend={HTML5Backend}>
        <LaunchLite />
      </DndProvider>
    </DeploymentModelProvider>
  );
};
