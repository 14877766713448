/**
 * @description: This file is used to render the AI rendered data widget
 *   This component accepts following parameters: actions, aiRenderedData, defaultRunningMode(onLoad, runningAll, runningEach), resultsRunningStatus
 *
 *   If runningMode is onLoad, it will run the mainRenderedData using actions.runAiResult function when the component is loaded
 *   If runningMode is runningAll, it will not run the mainRenderedData using actions.runAiResult function and show a button to run the mainRenderedData
 *   (TODO LATER)If runningMode is runningEach, it will not run the mainRenderedData using actions.runAiResult function and show a list of buttons to run the mainRenderedData
 *   - There will be a circular progress bar if the mainRenderedData is running
 */

import React, { useEffect, useState } from "react";
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  FormCheck,
  Spinner,
  Popover,
  OverlayTrigger,
  Form,
  Toast,
  Tooltip,
  Modal,
} from "react-bootstrap";
import CircularProgress from "./UI/CircularProgress";

const generatedAiKeys = {
  webAppDescription: {
    title: "Web App Description",
    visible: true,
    runningPosition: 1,
  },
  dataModels: {
    title: "Data Models",
    visible: true,
    runningPosition: 2,
  },
  recommendedPages: {
    title: "Recommended Pages",
    visible: true,
    runningPosition: 2,
  },
  themeGuideline: {
    title: "Theme Guideline",
    visible: true,
    runningPosition: 2,
  },
  filesToGenerate: {
    title: "Files To Generate",
    visible: true,
    runningPosition: 3,
  },
  generatedCodes: {
    title: "Generated Codes",
    visible: false,
    runningPosition: 4,
  },
  codeForFile: {
    title: "Code For File",
    visible: false,
    notRunnable: true,
    // runningPosition : 5
  },
  finalWebApp: {
    title: "Final Web App",
    visible: false,
    notRunnable: true,
  },
};

const getRunnableKeys = (runningMode) => {
  let runnableKeys = [];
  if (runningMode === "onLoad") {
    runnableKeys = Object.keys(generatedAiKeys).filter(
      (key) => !generatedAiKeys[key].notRunnable
    );
  } else if (runningMode === "runningAll") {
    runnableKeys = Object.keys(generatedAiKeys).filter(
      (key) => !generatedAiKeys[key].notRunnable
    );
  } else if (runningMode === "runningEach") {
    runnableKeys = Object.keys(generatedAiKeys).filter(
      (key) => !generatedAiKeys[key].notRunnable
    );
  }
  return runnableKeys;
};

const getOrderedListOfRunnableKeysList = (runningMode) => {
  const runnableKeys = getRunnableKeys(runningMode);
  const orderedListOfRunnableKeysList = runnableKeys
    .sort((a, b) => {
      return (
        generatedAiKeys[a].runningPosition - generatedAiKeys[b].runningPosition
      );
    })
    .reduce((acc, key) => {
      if (acc.length === 0) {
        acc.push([key]);
      } else {
        const lastItem = acc[acc.length - 1];
        if (
          generatedAiKeys[lastItem[0]].runningPosition ===
          generatedAiKeys[key].runningPosition
        ) {
          lastItem.push(key);
        } else {
          acc.push([key]);
        }
      }
      return acc;
    }, []);
  return orderedListOfRunnableKeysList;
};

const getProgress = (
  isRunning,
  aiRenderedData,
  resultsRunningStatus,
  runningMode
) => {
  const runnableKeys = getRunnableKeys(runningMode);
  const total = runnableKeys.length;
  const completed = runnableKeys.filter((key) => {
    const isLoading = resultsRunningStatus[key];
    const isDataEmpty = !aiRenderedData[key];
    const completed = !isLoading && !isDataEmpty;
    return completed;
  }).length;
  const progress = (completed / total) * 100;
  return progress;
};

const getKeyStatus = (key, isRunning, aiRenderedData, resultsRunningStatus) => {
  const isLoading = resultsRunningStatus[key];
  const isDataEmpty = !aiRenderedData[key];
  const completed = !isLoading && !isDataEmpty;
  const status = completed ? "completed" : isLoading ? "running" : "notStarted";
  return status;
};

// This component will be an animation
// It will show only 4 last words, with each having lower opacity than the previous one
// Also a tooltip will be shown when hovering on the text and will show the full text
// the receiving text is
const ResultStreamPrompter = ({ text = "" }) => {
  const [showFullText, setShowFullText] = useState(false);
  let textToDisplay = text;
  if (typeof text !== "string") {
    try {
      textToDisplay = JSON.stringify(text);
    } catch (error) {
      console.log("Error in ResultStreamPrompter", error);
      return null;
    }
  }

  const textToDisplayModel = (
    <Modal
      show={true}
      centered
      onHide={() => setShowFullText(false)}
      fullscreen
    >
      <Modal.Header closeButton>
        <Modal.Title>Result Stream</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Disabled textared */}
        <Form.Control as="textarea" rows={10} value={textToDisplay} disabled />
      </Modal.Body>
    </Modal>
  );

  const last_40_chars = textToDisplay.slice(-40).split("");

  return (
    <>
      <div
        className="result-stream-prompter text-center"
        role={"button"}
        onClick={() => setShowFullText(true)}
      >
        {last_40_chars.map((char, index) => {
          let opacity = 1;
          if (index === 0) {
            opacity = 0.05;
          } else if (index > 0 && index <= 3) {
            opacity = 0.1;
          } else if (index > 3 && index <= 6) {
            opacity = 0.2;
          } else if (index > 6 && index <= 9) {
            opacity = 0.25;
          } else if (index > 9 && index <= 12) {
            opacity = 0.3;
          } else if (index > 12 && index <= 15) {
            opacity = 0.35;
          } else if (index > 15 && index <= 18) {
            opacity = 0.39;
          } else if (index > 18 && index <= 21) {
            opacity = 0.6;
          } else if (index > 21 && index <= 28) {
            opacity = 0.7;
          } else if (index > 28 && index <= 35) {
            opacity = 0.8;
          } else if (index > 37 && index <= 40) {
            opacity = 1;
          }

          return (
            <span
              key={index}
              className="result-stream-prompter-char"
              style={{ opacity }}
            >
              {char}
            </span>
          );
        })}
      </div>
      {showFullText && textToDisplayModel}
    </>
  );
};

const AiRenderedDataWidget = (props) => {
  const [runAlreadyCompleted, setRunAlreadyCompleted] = useState(false);
  const {
    actions,
    aiRenderedData,
    runningMode = "runningAll",
    resultsRunningStatus,
  } = props;
  const [defaultRunningMode] = useState(runningMode);
  const [isRunning, setIsRunning] = useState(false);
  // const [mainRenderedData, setMainRenderedData] = useState({});

  useEffect(() => {
    if (defaultRunningMode === "onLoad") {
      runAll();
    }
  }, []);

  // useEffect(() => {
  // if (aiRenderedData) {
  //   setMainRenderedData(aiRenderedData);
  // }
  // }, [aiRenderedData]);

  const runAll = async () => {
    setIsRunning(true);
    const orderedListOfRunnableKeysList = getOrderedListOfRunnableKeysList(
      runningMode,
      aiRenderedData
    );

    for (let i = 0; i < orderedListOfRunnableKeysList.length; i++) {
      const keys = orderedListOfRunnableKeysList[i];
      const promises = keys.map((key) => {
        if (!runAlreadyCompleted && aiRenderedData[key]) {
          return;
        }
        return actions.runAiResult(key);
      });
      await Promise.all(promises);
    }
    setIsRunning(false);
  };

  const rerunKey = (key) => {
    actions.runAiResult(key);
  };

  const progress = getProgress(
    isRunning,
    aiRenderedData,
    resultsRunningStatus,
    runningMode
  );

  const runningKeys = Object.keys(resultsRunningStatus).filter(
    (key) => resultsRunningStatus[key]
  );

  const runnableKeys = getRunnableKeys(runningMode);

  const completedKeys = runnableKeys.filter((key) => {
    const isLoading = resultsRunningStatus[key];
    const isDataEmpty = !aiRenderedData[key];
    const completed = !isLoading && !isDataEmpty;
    return completed;
  });

  const keysToRun = runnableKeys.filter((key) => {
    const isLoading = resultsRunningStatus[key];
    const isDataEmpty = !aiRenderedData[key];
    return !isLoading && isDataEmpty;
  });

  const getKeyStatusRender = (key) => {
    let status = getKeyStatus(
      key,
      isRunning,
      aiRenderedData,
      resultsRunningStatus
    );
    if (status === "completed") {
      return <i className="bi ms-2 bi-check-circle-fill text-success"></i>;
    } else if (status === "running") {
      return (
        <Spinner
          className="ms-2"
          animation="grow"
          variant="secondary"
          size="sm"
        />
      );
    } else {
      return <i className="ms-2 bi bi-circle text-primary"></i>;
    }
  };

  return (
    <Card className="ai-rendered-data-widget text-center">
      <Card.Header onClick={props.onClose}>
        <Card.Title>AI Generated Data</Card.Title>
      </Card.Header>
      <Card.Body>
        <Container>
          <Row className="mb-3">
            <Col xs={12}>
              <div className="progress-container d-flex justify-content-center">
                <CircularProgress progress={progress} value={progress} />
              </div>
            </Col>
            <Col className="d-flex align-items-center justify-content-center mt-2">
              <div className="running-keys-container">
                {runningKeys.map((key) => {
                  return (
                    <div
                      key={key}
                      className="running-keys-container__key mb-2 text-center"
                    >
                      <h6 className="mb-0">
                        Creating {generatedAiKeys[key].title}.{" "}
                        {getKeyStatusRender(key)}
                      </h6>
                      <ResultStreamPrompter text={aiRenderedData[key] || ""} />
                    </div>
                  );
                })}
                {completedKeys.map((key) => {
                  return (
                    <div
                      key={key}
                      className="running-keys-container__key mb-2 text-center"
                    >
                      <h6 className="mb-0">
                        {generatedAiKeys[key].title} Created.
                        {getKeyStatusRender(key)}
                        {/* Button to rerun key */}
                        <i
                          onClick={() => rerunKey(key)}
                          className="bi bi-arrow-repeat ms-2"
                          role={"button"}
                        ></i>
                      </h6>
                      <ResultStreamPrompter text={aiRenderedData[key] || ""} />
                    </div>
                  );
                })}
                {keysToRun.map((key) => {
                  return (
                    <div
                      key={key}
                      className="running-keys-container__key mb-2 text-center"
                    >
                      <h6 className="mb-0">
                        {generatedAiKeys[key].title} To Be Created.
                        {getKeyStatusRender(key)}
                      </h6>
                      <ResultStreamPrompter text={aiRenderedData[key] || ""} />
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
          <Row>
            {/* Check whether it should run the already completed data or not */}
            <Col>
              <div className="run-already-completed-container">
                <FormCheck
                  type="checkbox"
                  label="Run already completed data"
                  checked={runAlreadyCompleted}
                  onChange={(e) => setRunAlreadyCompleted(e.target.checked)}
                />
              </div>
            </Col>
            <Col>
              <div className="button-container">
                {runningMode === "runningAll" && (
                  <Button variant="primary" onClick={runAll}>
                    Run All
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

const AiRenderedDataWidgetToggler = (props) => {
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <div className="ai-rendered-data-widget-toggler">
      <div className="ai-rendered-data-widget-toggler__button-container">
        {!show && (
          <Button
            variant="primary"
            onClick={toggleShow}
            className="ai-rendered-data-widget-toggler__button"
          >
            Show AI Generated Data
          </Button>
        )}
      </div>
      {show && (
        <AiRenderedDataWidget
          {...props}
          onClose={() => {
            setShow(false);
          }}
        />
      )}
    </div>
  );
};

export default AiRenderedDataWidgetToggler;
