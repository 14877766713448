/**
 * @file WebAppConfiguration.js
 * @description WebAppConfiguration Component
 *    This component accepts following parameters: webApp, actions
 *    This component is used to configure the webapp.
 *    - first part, basic information
 *     - WebApp Name
 *     - WebApp Description
 *    - second part, ai rendered data
 *     - blocks for aiRenderedData(property of webApp)
 *      - it will render a block for aiRenderedData.webAppDescription
 *      - it will render a block for aiRenderedData.dataModels
 *     - each block contains a text area to change the value of the property and a button to generate the value with AI
 */

import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Modal, Spinner } from "react-bootstrap";
import DB from "../db/DB";
import { uploadWebappPictures } from "../../../apis/storageUtils";
import { TasksVisionModal } from "./modules/TasksVision";

const WebAppIcons = ({ webApp, actions }) => {
  const [showTasksVisionModalIndex, setShowTasksVisionModalIndex] =
    useState(null);
  const [openedLogos, setOpenedLogos] = useState({});
  const [generatingContentIcons, setGeneratingContentIcons] = useState({});
  const [uploadingIcons, setUploadingIcons] = useState({});
  const [icons, setIcons] = useState([]);

  useEffect(() => {
    const unsubscribe = DB.webappProjects.sub.icons.list(webApp.id, (icons) => {
      setIcons(icons);
    });
    return unsubscribe;
  }, [webApp.id]);

  const uploadOne = async (e, index) => {
    setUploadingIcons({ ...uploadingIcons, [index]: true });
    const { name, description } = icons[index];
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("webAppId", webApp.id);
    const uploadData = await uploadWebappPictures(formData);
    const data = uploadData;
    const { url } = data;
    const newIcon = {
      ...icons[index],
      url,
    };
    await DB.webappProjects.sub.icons.update(webApp.id, newIcon.id, newIcon);
    setUploadingIcons({ ...uploadingIcons, [index]: false });
  };

  const handleOnChange = (e, index) => {
    const { name, value } = e.target;
    const newIcons = [...icons];
    newIcons[index] = {
      ...newIcons[index],
      [name]: value,
    };
    setIcons(newIcons);
  };

  const handleOnSave = async (index) => {
    const icon = icons[index];
    if (icon.id) {
      await DB.webappProjects.sub.icons.update(webApp.id, icon.id, icon);
    } else {
      await DB.webappProjects.sub.icons.create(webApp.id, icon);
    }
    // await actions.saveIcon(icon);
  };

  const handleOnDelete = async (index) => {
    const icon = icons[index];
    // await actions.deleteIcon(icon);
  };

  const handleOnAdd = async () => {
    const newIcon = {
      name: "New Icon",
      description: "New Icon",
      iconType: "website", // favicon, website
      iconTone: "light", // light, dark, color
      iconStyle: "simple and modern", // modern, classic, flat, outline, rounded, sharp, two-tone
      webAppId: webApp.id,
      designerPrompt: "",
    };

    setIcons([...icons, newIcon]);
  };

  const generateMidjourneyLogo = async (index) => {
    setGeneratingContentIcons({ ...generatingContentIcons, [index]: true });
    const icon = icons[index];
    let forcedParameters = {
      logoType: icon.iconType || "website",
      logoTone: icon.iconTone || "light",
      logoStyle: icon.iconStyle || "simple and modern",
    };
    let currentStream = "";

    const options = {
      onDataStream: (data) => {
        currentStream += data;
        setIcons((icons) => {
          const newIcons = [...icons];
          newIcons[index] = {
            ...newIcons[index],
            designerPrompt: currentStream,
          };
          return newIcons;
        });
      },
      onDataStreamEnd: (data) => {},
      forcedValues: {
        parameters: forcedParameters,
      },
    };
    const result = await actions.runAiAndGet(
      "webAppMidjournerLogo",
      null,
      options
    );
    setIcons((icons) => {
      const newIcons = [...icons];
      newIcons[index] = {
        ...newIcons[index],
        designerPrompt: result,
      };
      return newIcons;
    });
    setGeneratingContentIcons({ ...generatingContentIcons, [index]: false });
  };

  const showTasksVisionModalIcon =
    showTasksVisionModalIndex !== null
      ? icons[showTasksVisionModalIndex]
      : null;

  return (
    <div className="container p-5">
      {showTasksVisionModalIcon && (
        <TasksVisionModal
          mode="segmentation"
          show={true}
          onHide={() => setShowTasksVisionModalIndex(null)}
          imgUrl={showTasksVisionModalIcon.url}
        />
      )}
      <h5 className="mb-3 mt">Icons</h5>
      {icons.length === 0 && (
        <p className="text-center">
          No icons yet.{" "}
          <span
            className="text-primary"
            style={{ cursor: "pointer" }}
            onClick={handleOnAdd}
          >
            Add one
          </span>
        </p>
      )}
      {icons.map((icon, index) => {
        const imageClassName =
          icon.iconTone === "dark" ? "bg-light" : "bg-dark";
        return (
          <div key={index} className="mb-3">
            {!openedLogos[index] && (
              <div className="d-flex justify-content-between">
                <div>
                  <h6>{icon.name}</h6>
                </div>
                {icon.url && (
                  <div className="mb-3">
                    <img
                      src={icon.url}
                      alt={icon.name}
                      className={`${imageClassName}`}
                      style={{ maxHeight: "50px" }}
                    />
                  </div>
                )}
                <div>
                  <span
                    className="text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setOpenedLogos({ ...openedLogos, [index]: true });
                    }}
                  >
                    Edit
                  </span>
                </div>
              </div>
            )}

            {openedLogos[index] && (
              <>
                <div className="d-flex justify-content-between">
                  <div>
                    <h6>{icon.name}</h6>
                  </div>
                  <div>
                    <span
                      className="text-primary"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOpenedLogos({ ...openedLogos, [index]: false });
                      }}
                    >
                      Close
                    </span>
                  </div>
                </div>
                {icon.url && (
                  <div className="mb-3">
                    <img
                      src={icon.url}
                      alt={icon.name}
                      className={`img-fluid ${imageClassName}
              `}
                    />
                    <div className="mt-2">
                      {/* TasksVisionModal Button to show */}
                      <Button
                        variant="outline-primary"
                        onClick={() => {
                          setShowTasksVisionModalIndex(index);
                        }}
                      >
                        Custom Logo With AI TasksVision
                      </Button>
                    </div>
                  </div>
                )}

                <Form.Group className="mt-2" as={Row} controlId="formName">
                  <Form.Label column sm="2">
                    Name
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      type="text"
                      name="name"
                      value={icon.name}
                      onChange={(e) => handleOnChange(e, index)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  className="mt-2"
                  as={Row}
                  controlId="formDescription"
                >
                  <Form.Label column sm="2">
                    Description
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      type="text"
                      name="description"
                      value={icon.description}
                      onChange={(e) => handleOnChange(e, index)}
                    />
                  </Col>
                </Form.Group>

                <Form.Group className="mt-2" as={Row} controlId="formIconType">
                  <Form.Label column sm="2">
                    Icon Type
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      as="select"
                      name="iconType"
                      value={icon.iconType}
                      onChange={(e) => handleOnChange(e, index)}
                    >
                      <option value="website">Website</option>
                      <option value="favicon">Favicon</option>
                    </Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group className="mt-2" as={Row} controlId="formIconStyle">
                  <Form.Label column sm="2">
                    Icon Style
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      as="select"
                      name="iconStyle"
                      value={icon.iconStyle}
                      onChange={(e) => handleOnChange(e, index)}
                    >
                      <option value="simple and modern">
                        Simple and Modern
                      </option>
                      <option value="simple and classic">
                        Simple and Classic
                      </option>
                      <option value="complex and modern">
                        Complex and Modern
                      </option>
                      <option value="complex and classic">
                        Complex and Classic
                      </option>
                      {/* flat */}
                      {/* 3d */}
                      {/* 2d */}
                      <option value="flat">Flat</option>
                      <option value="3d">3D</option>
                      <option value="2d">2D</option>

                      {/* geometric */}
                      {/* organic */}
                      {/* abstract */}
                      <option value="geometric">Geometric</option>
                      <option value="organic">Organic</option>
                      <option value="abstract">Abstract</option>

                      {/* minimal */}
                      {/* detailed */}
                      <option value="minimal">Minimal</option>
                      <option value="detailed">Detailed</option>

                      {/* monochrome */}
                      {/* multicolor */}
                      <option value="monochrome">Monochrome</option>
                      <option value="multicolor">Multicolor</option>
                    </Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group className="mt-2" as={Row} controlId="formIconTone">
                  <Form.Label column sm="2">
                    Icon Tone
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      as="select"
                      name="iconTone"
                      value={icon.iconTone}
                      onChange={(e) => handleOnChange(e, index)}
                    >
                      <option value="light">Light</option>
                      <option value="dark">Dark</option>
                      <option value="color">Color</option>
                    </Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group className="mt-2" as={Row} controlId="formPrompt">
                  <Form.Label column sm="2">
                    Designer Prompt
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      as="textarea"
                      name="designerPrompt"
                      disabled={true}
                      value={icon.designerPrompt}
                      onChange={(e) => handleOnChange(e, index)}
                    />
                  </Col>
                  <Col sm="2"></Col>
                  <Col sm="10">
                    <Button
                      variant="primary"
                      className="mt-2"
                      onClick={() => generateMidjourneyLogo(index)}
                    >
                      Generate Prompt
                    </Button>
                  </Col>
                </Form.Group>
                <Form.Group className="mt-2" as={Row} controlId="formFile">
                  <Form.Label column sm="2">
                    File
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      type="file"
                      name="file"
                      onChange={(e) => uploadOne(e, index)}
                    />
                  </Col>
                </Form.Group>
                <div className="d-flex justify-content-end mt-2">
                  {uploadingIcons[index] && (
                    <>
                      <Spinner animation="border" /> Uploading Logo...
                    </>
                  )}
                  {generatingContentIcons[index] && (
                    <>
                      <Spinner animation="border" /> Generating Prompt for IA
                      Image generator...
                    </>
                  )}
                  <Button
                    variant="danger"
                    className="ms-2"
                    onClick={() => handleOnDelete(index)}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="primary"
                    className="ms-2"
                    onClick={() => handleOnSave(index)}
                  >
                    Save
                  </Button>
                </div>
                {index !== icons.length - 1 && <hr />}
              </>
            )}
          </div>
        );
      })}
      <div className="d-flex mt-2 justify-content-end">
        <Button variant="primary" onClick={handleOnAdd}>
          Add Icon
        </Button>
      </div>
    </div>
  );
};

const WebAppConfiguration = ({ webApp, actions, onHide }) => {
  const [webAppCopy, setWebAppCopy] = useState(webApp);
  const [loadingResults, setLoadingResults] = useState({});
  const { aiRenderedData } = webApp;
  const aiRenderedDataStringified = JSON.stringify(aiRenderedData, null, 2);

  useEffect(() => {
    const newWebAppCopy = { ...webApp };
    newWebAppCopy.aiRenderedData = { ...aiRenderedData };
    setWebAppCopy(newWebAppCopy);
  }, [aiRenderedDataStringified]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setWebAppCopy({
      ...webAppCopy,
      [name]: value,
    });
  };

  const handleAiRenderedDataOnChange = (e) => {
    const { name, value } = e.target;
    setWebAppCopy({
      ...webAppCopy,
      aiRenderedData: {
        ...webAppCopy.aiRenderedData,
        [name]: value,
      },
    });
  };

  const onRunAiResult = async (resultKey) => {
    setLoadingResults({
      ...loadingResults,
      [resultKey]: true,
    });
    await actions.runAiResult(resultKey);
    setLoadingResults({
      ...loadingResults,
      [resultKey]: false,
    });
    actions.notify({
      variant: "success",
      message: "AI Result Generated",
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await actions.saveWebApp(webAppCopy);
    actions.notify({
      variant: "success",
      message: "WebApp Saved",
    });
    onHide && onHide();
  };

  const aiResultsToRender = ["webAppDescription", "dataModels"];

  return (
    <div className="container p-5">
      <h3 className="text-center mb-3">WebApp Configuration</h3>
      <Form>
        {/* Name */}
        <Form.Group as={Row} controlId="name">
          <Form.Label column sm={2}>
            WebApp Name
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="Enter WebApp name"
              name="name"
              value={webApp.name}
              onChange={handleOnChange}
            />
          </Col>
        </Form.Group>
        {/* Description */}
        <Form.Group as={Row} controlId="description" className="mt-3">
          <Form.Label column sm={2}>
            Description
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              className="ta-m"
              as="textarea"
              placeholder="Enter WebApp description"
              name="description"
              value={webApp.description}
              onChange={handleOnChange}
            />
          </Col>
        </Form.Group>
        {/* Submit */}
        <br />
        <div className="d-flex justify-content-end">
          <Button variant="primary" onClick={handleSubmit}>
            Save WebApp
          </Button>
        </div>
      </Form>
      <hr />
      <WebAppIcons webApp={webApp} actions={actions} />
      {/* <div>
        <h3 className="text-center mb-3">AI Generated Data</h3>
        <Form>
          {aiResultsToRender.map((resultKey) => {
            return (
              <Form.Group
                key={resultKey}
                as={Row}
                controlId={resultKey}
                className="mt-3"
              >
                <Form.Label column sm={2}>
                  {resultKey}
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    className="ta-m"
                    as="textarea"
                    placeholder="Enter WebApp description"
                    name={resultKey}
                    value={webAppCopy.aiRenderedData[resultKey]}
                    onChange={handleAiRenderedDataOnChange}
                  />
                </Col>
                <Col sm={2}></Col>
                <Col sm={10} className="mt-2">
                  <Button
                    variant="primary"
                    type="button"
                    onClick={() => onRunAiResult(resultKey)}
                    disabled={loadingResults[resultKey]}
                  >
                    Generate {resultKey}
                    {loadingResults[resultKey] && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                  </Button>
                </Col>
              </Form.Group>
            );
          })}
        </Form>
      </div> */}
    </div>
  );
};

// as modal

export const WebAppConfigurationModal = ({
  show,
  webApp,
  actions,
  onChange,
  onHide,
}) => {
  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>WebApp Configuration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <WebAppConfiguration
          onHide={onHide}
          webApp={webApp}
          actions={actions}
          onChange={onChange}
        />
      </Modal.Body>
    </Modal>
  );
};
