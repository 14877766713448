import React, { useState } from "react";
import { FaTimes, FaPlus } from "react-icons/fa";
import "./RateLimiter.css";

/**
 * @typedef {Object} RateLimiterConfig
 * @property {string} id - The unique identifier of the rate limiter configuration.
 * @property {string} name - The name of the rate limiter configuration.
 * @property {string} description - The description of the rate limiter configuration.
 * @property {string} type - The type of rate limit ("request" or "token").
 * @property {number} requestRate - The rate limit for requests.
 * @property {number} tokenRate - The rate limit for tokens.
 * @property {AuthTokenGroup[]} tokenGroups - The token groups associated with this configuration.
 */

/**
 * RateLimiter component.
 * @param {Object} props - Component props.
 * @param {middleware} props.middleware - The middleware.
 * @param {Function} props.onMiddlewareChange - The callback function for middleware change.
 * @param {AuthTokenGroup[]} props.tokenGroups - The token groups available for selection.
 * @returns {JSX.Element} - Rendered component.
 */
const RateLimiter = ({ middleware, onMiddlewareChange, tokenGroups }) => {
  // Local state for new rate limiter config
  const { rateLimiterConfigs = [] } = middleware;
  const [newRateLimiter, setNewRateLimiter] = useState({
    name: "",
    type: "request",
    requestRate: 0,
    tokenRate: 0
  });

  const onRateLimiterChange = (updatedConfigs) => {
    onMiddlewareChange({
      ...middleware,
      rateLimiterConfigs: updatedConfigs
    });
  };

  const handleAddRateLimiter = () => {
    onRateLimiterChange([...rateLimiterConfigs, newRateLimiter]);
    setNewRateLimiter({
      name: "",
      type: "request",
      requestRate: 0,
      tokenRate: 0
    });
  };

  const handleUpdateRateLimiter = (index, updatedConfig) => {
    const updatedConfigs = [...rateLimiterConfigs];
    updatedConfigs[index] = updatedConfig;
    onRateLimiterChange(updatedConfigs);
  };

  const handleRemoveRateLimiter = (index) => {
    const updatedConfigs = rateLimiterConfigs.filter((_, i) => i !== index);
    onRateLimiterChange(updatedConfigs);
  };

  return (
    <div className="rate-limiter">
      {rateLimiterConfigs.map((config, index) => (
        <div key={index} className="rate-limiter-config">
          <input
            type="text"
            value={config.name}
            onChange={(e) =>
              handleUpdateRateLimiter(index, {
                ...config,
                name: e.target.value
              })
            }
            placeholder="Config Name"
          />
          <select
            value={config.type}
            onChange={(e) =>
              handleUpdateRateLimiter(index, {
                ...config,
                type: e.target.value
              })
            }
          >
            <option value="request">Request</option>
            <option value="token">Token</option>
          </select>
          <input
            type="number"
            value={config.requestRate}
            onChange={(e) =>
              handleUpdateRateLimiter(index, {
                ...config,
                requestRate: e.target.value
              })
            }
            placeholder="Request Rate"
          />
          <input
            type="number"
            value={config.tokenRate}
            onChange={(e) =>
              handleUpdateRateLimiter(index, {
                ...config,
                tokenRate: e.target.value
              })
            }
            placeholder="Token Rate"
          />
          {/* Add token group selection logic here */}
          <button onClick={() => handleRemoveRateLimiter(index)}>
            <FaTimes />
          </button>
        </div>
      ))}

      <div className="add-rate-limiter">
        {/* UI for adding new rate limiter config */}
        <button onClick={handleAddRateLimiter}>
          <FaPlus />
        </button>
      </div>
    </div>
  );
};

export default RateLimiter;
