import React, { useContext } from "react";
import "./EndpointDetails.css"; // Assurez-vous d'inclure le chemin correct vers votre fichier CSS
import { ApiBuilderContext } from "../../../../../contexts/ApiBuilderContext";

// get sample query params from dependencies
// as it's a sample we don't need a value
// we just need to show the key
const addQueryParamsToExampleUrl = (url, queryParamsKeys) => {
  if (queryParamsKeys.length === 0) return url;

  let urlWithQueryParams = url + "?";

  queryParamsKeys.forEach((key, index) => {
    // variable name is value${keyWithFirstLetterUpperCase}
    const variableName = `value${key.charAt(0).toUpperCase() + key.slice(1)}`;
    if (index !== 0) urlWithQueryParams += "&";
    urlWithQueryParams += `${key}={${variableName}}`;
  });

  return urlWithQueryParams;
};

const EndpointDetailsComponent = ({
  dependencies,
  systemOutputs,
  activatedDependenciesParams,
  component,
  lite = false,
  noMethod = false
}) => {
  const { method, url } = component;

  const renderRequestParams = () => {
    const methodLower = method?.toLowerCase();
    if (["GET", "DELETE"].includes(method)) {
      const queryParams = Object.keys(activatedDependenciesParams).filter(
        (key) => activatedDependenciesParams[key]
      );
      const urlWithQueryParams = addQueryParamsToExampleUrl(url, queryParams);

      return (
        <div className="request-details">
          <div className="request-line">
            {!noMethod && (
              <span className={`method ${methodLower}`}>{method}</span>
            )}
            <span className="url">{urlWithQueryParams}</span>
          </div>
          {queryParams.length > 0 && (
            <>
              <h6>Query Params</h6>
              <table className="params-table">
                <tbody>
                  {dependencies
                    .filter((dep) => queryParams.includes(dep.key))
                    .map((dep) => (
                      <tr key={dep.key}>
                        <td>{dep.key}</td>
                        <td>{dep.type || "unknown type"}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      );
    }

    if (["POST", "PUT"].includes(method)) {
      const requestBody = dependencies
        .filter((dep) => activatedDependenciesParams[dep.key])
        .reduce((body, dep) => {
          body[dep.key] = dep.type || "unknown type";
          return body;
        }, {});

      let JSON_text = "{\n";

      Object.keys(requestBody).forEach((key, index) => {
        const value = requestBody[key];
        JSON_text += `  ${key}: ${
          value.charAt(0).toUpperCase() + value.slice(1)
        }`;
        if (index !== Object.keys(requestBody).length - 1) {
          JSON_text += ",";
        }
        JSON_text += "\n";
      });

      JSON_text += "}";

      return (
        <div className="request-details">
          <div className="request-line">
            {!noMethod && (
              <span className={`method ${methodLower}`}>{method}</span>
            )}
            <span className="url">{url}</span>
          </div>
          <h6>Body</h6>
          <pre className="json-body">{JSON_text}</pre>
        </div>
      );
    }
  };

  const renderResponse = () => {
    const responseOutput = systemOutputs[0] || {
      type: "Unknown Type - Consider it as a text response"
    };
    return (
      <div className="response-details">
        <p>{responseOutput.type || "Unknown Type"}</p>
      </div>
    );
  };

  return (
    <div className={`endpoint-details-container ${lite ? "lite" : ""}`}>
      <div className="request-side">
        <h5>Request</h5>
        {renderRequestParams()}
      </div>
      <div className="response-side">
        <h5>Response</h5>
        {renderResponse()}
      </div>
    </div>
  );
};

export const EndpointDetailsWithData = ({
  component,
  lite = false,
  noMethod = false
}) => {
  const { systemOverview } = useContext(ApiBuilderContext);
  const selectedChain = component.selectedChain || "";
  const selectedBlock = component.selectedBlock || "";
  const activatedDependenciesParams =
    component.activatedDependenciesParams || [];

  const chainExists = !!systemOverview[selectedChain];

  const selectedOverview =
    chainExists && selectedBlock
      ? systemOverview[selectedChain][selectedBlock]
      : {};
  const userInputs = selectedOverview?.userInputs || [];
  const systemOutputs = selectedOverview?.systemOutputs || [];
  const dependencies = selectedOverview?.dependencies || [];

  return (
    <EndpointDetailsComponent
      noMethod={noMethod}
      dependencies={dependencies}
      systemOutputs={systemOutputs}
      activatedDependenciesParams={activatedDependenciesParams}
      component={component}
      lite={lite}
    />
  );
};

export const EndpoindDetails = ({
  component,
  lite = false,
  noMethod = false,
  systemOverview
}) => {
  const selectedChain = component.selectedChain || "";
  const selectedBlock = component.selectedBlock || "";
  const activatedDependenciesParams =
    component.activatedDependenciesParams || [];

  const chainExists = !!systemOverview[selectedChain];

  const selectedOverview =
    chainExists && selectedBlock
      ? systemOverview[selectedChain][selectedBlock]
      : {};
  const userInputs = selectedOverview?.userInputs || [];
  const systemOutputs = selectedOverview?.systemOutputs || [];
  const dependencies = selectedOverview?.dependencies || [];

  return (
    <EndpointDetailsComponent
      noMethod={noMethod}
      dependencies={dependencies}
      systemOutputs={systemOutputs}
      activatedDependenciesParams={activatedDependenciesParams}
      component={component}
      lite={lite}
    />
  );
};

export default EndpointDetailsComponent;
