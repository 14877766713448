import React from "react";
import { useState, useEffect } from "react";
import {
  Modal,
  Button,
  NavDropdown,
  DropdownButton,
  Dropdown,
  Placeholder,
  Form,
  Row,
  Col,
  Card,
  Accordion,
  ListGroup,
  InputGroup,
  FormControl,
  Tabs,
  Tab,
  Nav,
  Table,
  Alert,
  Container,
  OverlayTrigger,
  Tooltip,
  Popover,
  Badge,
  ProgressBar,
  Spinner,
  Toast,
  ToastContainer,
  Collapse,
} from "react-bootstrap";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { sublime } from "@uiw/codemirror-theme-sublime";
import DB from "../../../database/DB";

const SCRIPT_ROLES = {
  condition: {
    value: "condition",
    label: "Condition",
    description:
      "A condition script is used to determine if a task should be executed or not. It should return a boolean value(any value that can be converted to a boolean).",
  },
  transform: {
    value: "transform",
    label: "Transform",
    description:
      "A transform script is used to transform the input data of a task. It should return the transformed data.",
  },
};

const IMPORTED_VARIABLES_TYPES = {
  rawValue: {
    value: "rawValue",
    label: "Raw value",
    description:
      "Raw value mode allow to set a raw value to the variable, available from `DATA.IMPORTED[variableName]`.",
    valueTypes: [
      "string",
      "number",
      "boolean",
      "object",
      "array",
      "arrayString",
      "arrayNumber",
      "arrayBoolean",
      "arrayObject",
    ],
  },
  variableRef: {
    value: "variableRef",
    label: "Variable reference",
    description:
      "Variable reference mode allow to reference another variable from the system, available from `DATA.COMPUTED[variableName]`.",
  },
};

const CHANGE_KEYS = ["type", "valueType"];

const VariableLine = ({
  mode = "add",
  variable,
  onChange,
  onRemove,
  onAdd,
  key = "",
  className = "",
  showHelper,
}) => {
  const [variableForm, setVariableForm] = useState(
    variable || {
      key: "",
      type: IMPORTED_VARIABLES_TYPES.rawValue.value,
      valueType: "string",
    }
  );
  const shouldChange = React.useRef(false);

  useEffect(() => {
    if (shouldChange.current) {
      onChange(variableForm);
      shouldChange.current = false;
    }
  }, [variableForm]);

  const handleOnChange = (e) => {
    if (mode === "edit" && CHANGE_KEYS.includes(e.target.name)) {
      shouldChange.current = true;
    }

    setVariableForm({ ...variableForm, [e.target.name]: e.target.value });
  };

  const handleOnAddImportedVariable = () => {
    if (!variableForm.key) {
      return;
    }

    onAdd(variableForm);

    setVariableForm({
      key: "",
      type: IMPORTED_VARIABLES_TYPES.rawValue.value,
      valueType: "string",
    });
  };

  const onBlur = () => {
    if (mode === "edit") {
      onChange(variableForm);
    }
  };

  return (
    <React.Fragment key={key}>
      <Row className={`${className}`}>
        <Col xs={4}>
          <Form.Control
            type="text"
            name="key"
            value={variableForm.key}
            className="py-2"
            onChange={handleOnChange}
            onBlur={onBlur}
            placeholder="Variable name"
          />
        </Col>
        <Col xs={4}>
          <Form.Control
            as="select"
            name="type"
            value={variableForm.type}
            className="py-2"
            onChange={handleOnChange}
            placeholder="Variable type"
          >
            {Object.keys(IMPORTED_VARIABLES_TYPES).map((key) => {
              const importedVariableType = IMPORTED_VARIABLES_TYPES[key];

              return (
                <option key={key} value={importedVariableType.value}>
                  {importedVariableType.label}
                </option>
              );
            })}
          </Form.Control>
        </Col>
        <Col xs={3}>
          {variableForm.type === IMPORTED_VARIABLES_TYPES.rawValue.value && (
            <Form.Control
              as="select"
              name="valueType"
              value={variableForm.valueType}
              className="py-2"
              onChange={handleOnChange}
              placeholder="Variable value type"
            >
              {IMPORTED_VARIABLES_TYPES.rawValue.valueTypes.map((key) => {
                return (
                  <option key={key} value={key}>
                    {key}
                  </option>
                );
              })}
            </Form.Control>
          )}
        </Col>
        {mode === "add" && (
          <Col xs={1}>
            <Button
              variant="link"
              className="py-2 text-white-8"
              onClick={handleOnAddImportedVariable}
            >
              <i className="fas fa-plus"></i>
            </Button>
          </Col>
        )}
        {mode === "edit" && (
          <Col xs={1}>
            <Button
              variant="link text-white-8"
              className="py-2"
              onClick={() => onRemove(variableForm.key)}
            >
              <i className="fas fa-trash"></i>
            </Button>
          </Col>
        )}
      </Row>
      {showHelper && (
        <>
          <p className="text-white-6 mt-1 fs-6">
            {IMPORTED_VARIABLES_TYPES[variableForm.type].description}
          </p>
          <div className="horizontal-separator my-3"></div>
        </>
      )}
    </React.Fragment>
  );
};

const SHOULD_SAVE_KEYS = ["role", "importedVariables"];

/**
 * @typedef {Object} LogicScript
 * @property {string} id
 * @property {string} name
 * @property {string} title
 * @property {string} description
 * @property {string} role
 * @property {string} script
 * @property {Array}  importedVariables
 * @property {string} importedVariables.key
 * @property {string} importedVariables.type
 * @property {string} importedVariables.valueType
 */

const LogicScript = ({
  apiProjectId,
  currentScript,
  onCreated,
  onHide,
  forcedScriptRole,
}) => {
  const [script, setScript] = useState(currentScript || {});
  const isNew = !script.id;
  const shouldSave = React.useRef(false);

  const scriptRole = forcedScriptRole || script.role;
  const importedVariables = script.importedVariables || [];

  useEffect(() => {
    if (shouldSave.current) {
      autoSave(script);
      shouldSave.current = false;
    }
  }, [script]);

  const onCreate = async () => {
    try {
      let newId = null;
      if (apiProjectId) {
        newId = await DB.apiProjects.sub.dslScripts.create(
          apiProjectId,
          script
        );
      } else {
        newId = await DB.dslScripts.create(script);
      }
      setScript({ ...script, id: newId });
      onCreated && onCreated(script);
    } catch (error) {
      console.error(error);
    }
  };

  const onTextChange = (value) => {
    setScript({ ...script, script: value });
  };

  const handleOnChange = (e) => {
    if (SHOULD_SAVE_KEYS.includes(e.target.name)) {
      shouldSave.current = true;
    }
    setScript({ ...script, [e.target.name]: e.target.value });
  };

  const handleOnChangeImportedVariable = (updated, index) => {
    let importedVariables = [...(script.importedVariables || [])];

    const indexFound = importedVariables.findIndex(
      (_, indexItem) => indexItem === index
    );

    if (indexFound !== -1) {
      importedVariables[indexFound] = updated;

      setScript({
        ...script,
        importedVariables,
      });
    }
  };

  const handleOnAddImportedVariable = (newImportedVariable) => {
    const importedVariables = [...(script.importedVariables || [])];

    if (!newImportedVariable.key) {
      return;
    }

    const newImportedVariables = [...importedVariables, newImportedVariable];

    shouldSave.current = true;

    setScript({
      ...script,
      importedVariables: newImportedVariables,
    });
  };

  const handleOnRemoveImportedVariable = (key) => {
    const importedVariables = [...(script.importedVariables || [])];

    const newImportedVariables = importedVariables.filter(
      (importedVariable) => importedVariable.key !== key
    );

    setScript({
      ...script,
      importedVariables: newImportedVariables,
    });
  };

  const onBlur = () => {
    autoSave();
  };

  const autoSave = async () => {
    if (!isNew) {

      if (apiProjectId) {
        await DB.apiProjects.sub.dslScripts.update(
          apiProjectId,
          script.id,
          script
        );
      } else {
        await DB.dslScripts.update(script.id, script);
      }
    }
  };

  const canCreate = isNew && script.name && script.script;

  return (
    <Row className="h-100 m-0">
      <Col xs={12} className="px-4 py-3 LogicScriptHeader">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="text-white-8 mb-0">
            {isNew ? "New" : "Edit"} Logic Script
          </h2>
          <div>
            {isNew && (
              <Button
                variant="primary"
                className="py-2"
                disabled={!canCreate}
                onClick={onCreate}
              >
                Create
              </Button>
            )}
            <Button
              variant="link text-white-8"
              className="py-2 ms-3"
              onClick={onHide}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Col>
      <Col xs={5} className="p-3 px-4">
        {isNew && (
          <>
            <p className="text-white-8 fs-5">
              Create a new script for your specific use case. <br />A script can
              be used to better control the flow of your project. <br /> <br />
              <a href="https://docs.code.ai/ai-builder/ai-builder-logic-scripts">
                Learn more about logic scripts.
              </a>
            </p>
            <div className="horizontal-separator my-3"></div>
          </>
        )}
        <div className="d-flex align-items-center mb-3">
          <Form.Control
            type="text"
            name="name"
            value={script.name || ""}
            onAbort={onBlur}
            className="py-3"
            onChange={handleOnChange}
            placeholder="Script name"
          />
        </div>
        <div className="d-flex align-items-center mb-3">
          <Form.Control
            type="text"
            name="title"
            value={script.title || ""}
            className="py-3"
            onBlur={onBlur}
            onChange={handleOnChange}
            placeholder="Script title"
          />
        </div>
        <div className="d-flex align-items-center mb-3">
          <Form.Control
            as="textarea"
            rows={2}
            name="description"
            onAbort={onBlur}
            value={script.description || ""}
            className="py-3 text-area-clean"
            onChange={handleOnChange}
            placeholder="Script description"
          />
        </div>
        <div className="">
          <Form.Control
            as="select"
            name="role"
            value={scriptRole || ""}
            className="py-3"
            onChange={handleOnChange}
            disabled={forcedScriptRole}
            placeholder="Script role"
          >
            {Object.keys(SCRIPT_ROLES).map((key) => {
              const scriptRole = SCRIPT_ROLES[key];

              return (
                <option key={key} value={scriptRole.value}>
                  {scriptRole.label}
                </option>
              );
            })}
          </Form.Control>
          {scriptRole && (
            <p className="text-white-6 mt-1 fs-6">
              {SCRIPT_ROLES[scriptRole].description}
            </p>
          )}
        </div>

        <div className="horizontal-separator my-3"></div>

        <p className="text-white-8 fs-5">
          Import variables from the system to use them in your script.
        </p>

        <VariableLine
          mode="add"
          onAdd={handleOnAddImportedVariable}
          showHelper={true}
        />
        {/* <p className="text-white-6 mt-0 mb-3 fs-6">
          You can use the imported variables in your script with the following
          syntax:{" "}
          <span className="text-white-8">DATA.IMPORTED[variableName]</span>
        </p> */}

        {importedVariables.map((importedVariable, i) => {
          return (
            <VariableLine
              mode="edit"
              key={i}
              variable={importedVariable}
              onChange={(updated) => {
                handleOnChangeImportedVariable(updated, i);
              }}
              onRemove={handleOnRemoveImportedVariable}
            />
          );
        })}
      </Col>
      <Col xs={7} className="p-0 h-100">
        <CodeMirror
          value={script.script}
          height="100%"
          className="h-100"
          extensions={[javascript({ jsx: false, typescript: false })]}
          theme={sublime}
          onChange={onTextChange}
          onBlur={onBlur}
        />
      </Col>
    </Row>
  );
};

export const LogicScriptModal = ({
  currentScript,
  apiProjectId,
  onCreated,
  onHide,
  forcedScriptRole,
}) => {
  return (
    <Modal
      show={true}
      onHide={onHide}
      fullscreen={true}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="LogicScriptModal"
    >
      {/* <Modal.Header closeButton>
        <Modal.Title>Logic Script</Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <LogicScript
          apiProjectId={apiProjectId}
          currentScript={currentScript}
          onCreated={onCreated}
          forcedScriptRole={forcedScriptRole}
          onHide={onHide}
        />
      </Modal.Body>
    </Modal>
  );
};

//   <CodeMirror
//   value={value}
//   height="calc(100vh - 73px - 38px - 42px)"
//   extensions={[javascript({ jsx: false, typescript: false })]}
//   theme={sublime}
//   onChange={onTextChange}
// />
