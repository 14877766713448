/**
 * @fileoverview This file contains the CrudPage component.
 * This component is used to display a page with a list of items and a content part which display the children property.
 * The list of item allow to navigate(using navigate of react-router-dom)
 */

import React, { useState, useEffect } from "react";
import { Container, Row, Spinner, Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const CrudPage = ({
  children,
  value,
  links,
  title,
  path,
  included,
  elements,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="padding-page CrudPage">
        <Card className="bg-transparent">
          {/* Title and prompt selector */}
          <Card.Header as={"div"} className="d-flex align-items-center">
            <h5 className="me-3">{title}</h5>
            <Form.Group className="ml-3">
              <Form.Control
                as="select"
                value={value}
                onChange={(e) => {
                  navigate(`${path}/${e.target.value}`);
                }}
              >
                {links.map((link) => {
                  return (
                    <option key={link.value} value={link.value}>
                      {link.label}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
            {elements && <div className="ml-auto ms-3"> {elements}</div>}
          </Card.Header>
          {included && children && (
            <Container fluid className="p-4">
              {children}
            </Container>
          )}
          <Card.Body className="px-1">
            {!included && children && <Container fluid>{children}</Container>}
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default CrudPage;
