import React, { useState, useContext } from "react";
import { Card, Form } from "react-bootstrap";
import { ChainContext } from "../../../../contexts/ChainContext";
import BlockType from "../utils/BlockType";

const InputBlockNode = ({ data }) => {
  const { inputBlock } = data;
  const [inputBlockState, setInputBlockState] = useState(inputBlock);
  const { chainActions } = useContext(ChainContext);
  // const { inputs, outputs } = useContext(ChainContext);
  // const { id: apiProjectId, apiProject } = useContext(ApiBuilderContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputBlockState({ ...inputBlockState, [name]: value });
  };

  const handleSelect = (e) => {
    const { name, value } = e.target;
    setInputBlockState({ ...inputBlockState, [name]: value });
    chainActions.updateInputBlock({ ...inputBlockState, [name]: value });
  };

  const handleSave = () => {
    chainActions.updateInputBlock(inputBlockState);
  };

  return (
    <Card className={`p-3 shadow-sm DataInputNode fs-6`}>
      <Card.Title className="text-white">{inputBlockState.name}</Card.Title>
      <Card.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={inputBlockState.name || ""}
              onChange={handleChange}
              onBlur={handleSave}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Value Type</Form.Label>
            <Form.Control
              as="select"
              name="valueType"
              value={inputBlockState.valueType || ""}
              onChange={handleSelect}
            >
              {/* text, number, date, time, datetime, select, multi-select, checkbox, radio, file, image, video, audio, location, email, phone, url, password, hidden, html, markdown, code, json, yaml, xml, csv, excel, pdf, word, powerpoint, archive, other */}
              <option value="text">Text</option>
              <option value="number">Number</option>
              <option value="date">Date</option>
              <option value="time">Time</option>
              <option value="datetime">Datetime</option>
              <option value="select">Select</option>
              <option value="multi-select">Multi-Select</option>
              <option value="checkbox">Checkbox</option>
              <option value="radio">Radio</option>
              <option value="file">File</option>
              <option value="image">Image</option>
              <option value="video">Video</option>
              <option value="audio">Audio</option>
              <option value="location">Location</option>
              <option value="email">Email</option>
              <option value="phone">Phone</option>
              <option value="url">URL</option>
              <option value="password">Password</option>
              <option value="hidden">Hidden</option>
              <option value="html">HTML</option>
              <option value="markdown">Markdown</option>
              <option value="code">Code</option>
              <option value="json">JSON</option>
              <option value="yaml">YAML</option>
              <option value="xml">XML</option>
              <option value="csv">CSV</option>
              <option value="excel">Excel</option>
              <option value="pdf">PDF</option>
              <option value="word">Word</option>
              <option value="powerpoint">Powerpoint</option>
              <option value="archive">Archive</option>
              <option value="other">Other</option>
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Default Value</Form.Label>
            <Form.Control
              type="text"
              name="defaultValue"
              onBlur={handleSave}
              value={inputBlockState.defaultValue || ""}
              onChange={handleChange}
            />
          </Form.Group>
          <BlockType block={inputBlockState} blockType={"input"} />
        </Form>
      </Card.Body>
    </Card>
  );
};

export default InputBlockNode;
