/* This file contain a component which is a Files/Folder explorer list as in a code editor, with list of files and folders, the folders is clickable and show the files inside, the files is clickable and show the content of the file
 * in the right side of the screen in the tabs. Right click on a file or folder show a menu with options like: rename, delete, create new file, create new folder, copy, paste, cut, etc.
 * It uses react bootsrap to provide the UI components. The component receive the following props: files, onNewFile, onNewFolder and does not import "../../contexts/GlobalContext" which is deprecated.
 */

import { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Button, Dropdown } from "react-bootstrap";
import FormModals from "../modals/FormModals";
import { useLocalStorage } from "../../hooks/useLocalStorage";

const newFile = (props) => ({
  name: "",
  description: "",
  generatedCode: "",
  gptInstructions: "",
  fileExtension: null,
  path: null,
  fileType: null,
  projectId: null,
  ...props,
});

const newFolder = (props) => ({
  name: ".",
  folderName: "",
  description: "",
  isFolder: true,
  path: `${props.folderPath}/${props.folderName}`,
  projectId: null,
  ...props,
});

const newFileFields = [
  {
    name: "name",
    label: "File name",
    type: "text",
    placeholder: "Enter file name",
  },
  {
    name: "description",
    label: "Description",
    type: "text",
    placeholder: "Enter file description",
  },
];

const newFolderFields = [
  {
    name: "folderName",
    label: "Folder name",
    type: "text",
    placeholder: "Enter folder name",
  },
  {
    name: "description",
    label: "Description",
    type: "text",
    placeholder: "Enter folder description",
  },
];

const File = ({ file, onFileClick, actionsManager }) => {
  const [contextMenuOpen, setContextMenuOpen] = useState(false);

  const onFileClickHandler = () => {
    onFileClick(file);
  };

  const onContextMenuHandler = (e) => {
    e.preventDefault();
    setContextMenuOpen(!contextMenuOpen);
  };

  return (
    // On rightclick show a menu with options like: rename, copy, paste, cut, etc.
    // The context is shown using Dropdown component from react-bootstrap
    <div className="file" onContextMenu={onContextMenuHandler}>
      <div onClick={onFileClickHandler}>
        <i className="fa fa-file" aria-hidden="true"></i>
        <span className="file-name">{file.name}</span>
      </div>
      <Dropdown
        show={contextMenuOpen}
        onToggle={() => setContextMenuOpen(!contextMenuOpen)}
        className="file-context-menu"
      >
        <Dropdown.Menu>
          <Dropdown.Item as="span">Rename</Dropdown.Item>
          <Dropdown.Item as="span">Rename</Dropdown.Item>
          <Dropdown.Item as="span">Copy</Dropdown.Item>
          <Dropdown.Item as="span">Paste</Dropdown.Item>
          <Dropdown.Item as="span">Cut</Dropdown.Item>
          <Dropdown.Item
            as="span"
            onClick={() => actionsManager.handleDeleteFile(file)}
          >
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

const Folder = (props) => {
  const {
    folder,
    onFolderClick,
    onNewFile,
    onNewFolder,
    onFileClick,
    actionsManager,
    project,
  } = props;
  const [folderIsOpenStored, setFolderIsOpenStored, cleanFolderIsOpen] =
    useLocalStorage(`FolderIsOpen-${project.id}-${folder.path}`, false);
  const [modalOpen, setModalOpen] = useState(null);
  // const [isOpen, setIsOpen] = useState(false);
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const isRootPath = folder.path === "/";
  const basePath = isRootPath ? "" : folder.path;
  const sortFoldersByName = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };
  const isOpen = folderIsOpenStored;

  const onContextMenuHandler = (e) => {
    e.preventDefault();
    setContextMenuOpen(!contextMenuOpen);
  };

  const onFolderClickHandler = () => {
    if (!folderIsOpenStored) {
      setFolderIsOpenStored(true);
    } else {
      setFolderIsOpenStored(false);
      cleanFolderIsOpen();
    }
    // setIsOpen(!isOpen);
    // onFolderClick(folder);
  };

  const filterFilesNoFoFolder = (file) => {
    return file.name !== ".";
  };

  return (
    <div>
      <div
        className="folder"
        style={{
          backgrounddivor: isOpen ? "#f8f9fa" : "#fff",
        }}
      >
        <div style={{ padding: 0 }}>
          <div className="folder-line" onContextMenu={onContextMenuHandler}>
            <div onClick={onFolderClickHandler}>
              <i
                className={`fa fa-folder${isOpen ? "-open" : ""}`}
                aria-hidden="true"
              ></i>
              <span className="folder-name">{folder.name}</span>
            </div>
            <Dropdown
              show={contextMenuOpen}
              onToggle={() => setContextMenuOpen(!contextMenuOpen)}
              className="folder-context-menu"
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  as="span"
                  onClick={() => setModalOpen("newFile")}
                >
                  Add file
                </Dropdown.Item>
                <Dropdown.Item
                  as="span"
                  onClick={() => setModalOpen("newFolder")}
                >
                  Add folder
                </Dropdown.Item>
                <Dropdown.Item as="span">Rename</Dropdown.Item>
                <Dropdown.Item as="span">Copy</Dropdown.Item>
                <Dropdown.Item as="span">Paste</Dropdown.Item>
                <Dropdown.Item as="span">Cut</Dropdown.Item>
                <Dropdown.Item
                  as="span"
                  onClick={() =>
                    actionsManager.handleDeleteFile({
                      path: folder.path,
                      fileName: ".",
                      isFolder: true,
                    })
                  }
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="folder-children-list">
            {isOpen &&
              folder.childrenFolders
                .sort(sortFoldersByName)
                .map((folder, i) => (
                  // Recursive call to render children folders and props
                  <Folder
                    {...props}
                    folder={folder}
                    key={folder.path + i}
                    onFileClick={onFileClick}
                  />
                ))}
            {isOpen &&
              folder.files
                .filter(filterFilesNoFoFolder)
                .map((file, i) => (
                  <File
                    actionsManager={actionsManager}
                    file={file}
                    key={file.path + i}
                    onFileClick={onFileClick}
                  />
                ))}
          </div>
        </div>
      </div>
      {/* Actions modals */}
      {/* Add new file */}
      <FormModals
        show={modalOpen === "newFile"}
        initialValue={newFile({
          path: folder.path,
        })}
        fields={newFileFields}
        onFormSubmit={(newFile) => {
          const newFileWithExtension = {
            ...newFile,
            fileExtension:
              newFile.name.split(".").length > 1
                ? newFile.name.split(".").pop()
                : "",
          };
          actionsManager.handleNewFile(newFileWithExtension);
          setModalOpen(null);
        }}
        onClose={() => setModalOpen(null)}
      />
      {/* Add new folder */}
      <FormModals
        show={modalOpen === "newFolder"}
        initialValue={newFolder({
          path: folder.path,
          folderPath: folder.path,
        })}
        fields={newFolderFields}
        onFormSubmit={(newFolder) => {
          const newFolderWithPath = {
            ...newFolder,
            path: `${basePath}/${newFolder.folderName}`,
          };
          actionsManager.handleNewFolder(newFolderWithPath);
          setModalOpen(null);
        }}
        onClose={() => setModalOpen(null)}
      />
    </div>
  );
};

const getDefaultRootFolder = (childrenFolders) => ({
  folderLevel: 0,
  files: [],
  childrenFolders,
  name: "root",
  path: "/",
});

const getPathsMap = (files) => {
  const pathsMap = files.reduce(
    (acc, file) => {
      const path = file.path;
      const folderLevel = file.path === "/" ? 0 : path.split("/").length - 1;
      const folderName = file.path === "/" ? "root" : path.split("/").pop();
      const keyMap = `${folderLevel}-${path}`;

      if (!acc[keyMap]) {
        acc[keyMap] = {
          folderLevel,
          files: [],
          childrenFolders: [],
          name: folderName,
          path,
          key: keyMap,
        };
      }
      acc[keyMap].files.push(file);
      return acc;
    },
    {
      "0-/": getDefaultRootFolder([]),
    }
  );
  return pathsMap;
};

const createFolder = (currentFilesMap, level, path) => {
  const keyMap = `${level}-${path}`;
  const isEmptyFilesRootFolder = !!currentFilesMap["0-/"] && keyMap === "0-/";

  if (Object.keys(currentFilesMap).length === 0) {
    return getDefaultRootFolder([]);
  } else if (!currentFilesMap[keyMap]) {
    if (level !== 0) return {};
  }
  const childrenFolders = Object.keys(currentFilesMap)
    .filter(
      (key) =>
        currentFilesMap[key].folderLevel === level + 1 &&
        currentFilesMap[key].path.includes(path) &&
        currentFilesMap[key].path !== path
    )
    .map((key) => {
      return createFolder(
        currentFilesMap,
        level + 1,
        currentFilesMap[key].path
      );
    });

  const folderFiles = currentFilesMap[keyMap].files;

  return {
    folderLevel: level,
    files: folderFiles,
    childrenFolders: childrenFolders,
    name: currentFilesMap[keyMap].name,
    path: currentFilesMap[keyMap].path,
    key: currentFilesMap[keyMap].key,
  };
};

const FoldersAndFiles = ({ files, actionsManager, onOpenFile, project }) => {
  // folders is a list of folders, each folder is an object with the following properties: folderLevel, name, files, childrenFolders, path
  const [folders, setFolders] = useState([
    {
      folderLevel: 0,
      files: [],
      childrenFolders: [],
      name: "root",
      path: "/",
    },
  ]);

  // Writing above useEffect again but using createFolder function in a recursive way
  useEffect(() => {
    const newFolders = [...folders];
    if (files.length === 0) {
      return;
    }
    const newRootFolderFromFiles = createFolder(getPathsMap(files), 0, "/");
    newFolders[0] = newRootFolderFromFiles;
    setFolders(newFolders);
  }, [files]);

  const onFileClick = (file) => {
    onOpenFile(file);
  };

  return (
    <div className="FoldersAndFiles">
      <div>
        <div>
          {folders.map((folder, i) => (
            <Folder
              project={project}
              key={folder.path + i}
              onFileClick={onFileClick}
              actionsManager={actionsManager}
              folder={folder}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FoldersAndFiles;
