import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { Modal, Button, Placeholder, Dropdown } from "react-bootstrap";
import DB from "../../../database/DB";
// import { PromptBlock } from "../../pages/ApiBuilder";
import {
  PanelTopFlowSelector,
  ContextMenuPane,
  GlobalChainParameters,
  PanelLeft
} from "./SystemFlowCreator";
import ReactFlow, {
  Controls,
  Background,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  MiniMap,
  useViewport,
  Panel
} from "reactflow";
import { ApiBuilderContext } from "../../../contexts/ApiBuilderContext";
import { SystemBuilderInterfaceContext } from "../../../contexts/SystemBuilderInterfaceContext";
import { ChainContext } from "../../../contexts/ChainContext";
import flowDbUtils from "./flowDbUtils";
import { PromptBlockNode } from "./Transform/PromptBlock";
import { InputConnectionNode } from "./InputConnectorsFlow";
import { EmitterNode } from "./EmittersFlow";
import InputBlockNode from "./nodes/InputBlock";
import DataObjectNode from "./nodes/DataObject";
import { DataSetsDatabaseModal } from "./DataSetsDatabase";

const initialEdges = (nodes) => {
  return nodes.reduce((acc, node) => {
    if (node.edge) {
      acc.push(node.edge);
    }
    return acc;
  }, []);
};

const getDataFromPromptBlocks = (promptBlocks) => {
  const nodes = promptBlocks.map((pb, i) => {
    // Will render by columns of 300px with 3 columns

    const {
      chainKey,
      valuesLinkedExport,
      valuesLogicLinkedImport,
      positionFlow = {}
    } = pb;
    const { result } = valuesLinkedExport || {};
    const { source } = valuesLogicLinkedImport || {};
    let additionalNodeProps = {};

    let x = positionFlow.x;
    let y = positionFlow.y;

    if (!x || !y) {
      const col = i % 3;
      const row = Math.floor(i / 3);

      x = col * 400;
      y = row * 400;
    }

    const node = {
      id: `transform-${chainKey}`,
      // id: chainKey,
      data: {
        // label: result || chainKey,
        label: chainKey,
        promptExportVariable: result,
        logicExportVariable: source,
        key: chainKey,
        promptBlock: pb
      },
      position: { x, y },
      type: "promptBlock"
      // ...additionalNodeProps,
    };

    return node;
  });

  return nodes;
};

const getNodes = (data, dataType, datasKey) => {
  const nodes = data.map((d, i) => {
    const { name, positionFlow = {}, id } = d;

    let x = positionFlow.x;
    let y = positionFlow.y;

    if ((!x && x !== 0) || (!y && y !== 0)) {
      const col = i % 3;
      const row = Math.floor(i / 3);

      x = col * 400;
      y = row * 400;
    }

    const node = {
      id: `${dataType}-${id}`,
      data: {
        label: name,
        key: id,
        [datasKey]: d
      },
      position: { x, y },
      type: dataType
    };

    return node;
  });

  return nodes;
};

const nodesTypes = {
  emitter: EmitterNode,
  promptBlock: PromptBlockNode,
  inputConnection: InputConnectionNode,
  inputBlock: InputBlockNode,
  dataObject: DataObjectNode
};

const getDataNodes = (
  inputBlocks,
  dataObjects,
  promptBlocks,
  emitters,
  connections
) => {
  const nodes = [
    ...getNodes(inputBlocks, "inputBlock", "inputBlock"),
    ...getNodes(dataObjects, "dataObject", "dataObject"),
    ...getDataFromPromptBlocks(promptBlocks),
    ...getNodes(emitters, "emitter", "emitter"),
    ...getNodes(connections, "inputConnection", "connection")
  ];
  return nodes;
};

const groupNodes = (nodes) => {
  const group = {
    inputConnections: [],
    emitters: [],
    promptBlocks: [],
    inputBlocks: [],
    dataObjects: []
  };

  nodes.forEach((node) => {
    const { id } = node;
    const type = id.split("-")[0];
    const realId = id.split("-")[1];
    if (type === "inputConnection") {
      group.inputConnections.push({ ...node, id: realId });
    } else if (type === "emitter") {
      group.emitters.push({ ...node, id: realId });
    } else if (type === "transform") {
      group.promptBlocks.push({ ...node, id: realId });
    } else if (type === "inputBlock") {
      group.inputBlocks.push({ ...node, id: realId });
    } else if (type === "dataObject") {
      group.dataObjects.push({ ...node, id: realId });
    }
  });

  return group;
};

const FloatingMenu = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showPlayground, setShowPlayground] = useState(false);
  const { chain } = useContext(ChainContext);

  return (
    <div className="floating-menu">
      <Button variant="primary" onClick={() => setShowMenu(!showMenu)}>
        ☰
      </Button>
      {showMenu && (
        <div className="menu-dropdown">
          <a href="#/delete-chain" className="action-link">
            Duplicate Chain
          </a>
          <a href="#/delete-chain" className="action-link">
            Delete Chain
          </a>
          <a href="#/configure" className="action-link">
            Configure Chain
          </a>
          <a className="action-link" onClick={() => setShowPlayground(true)}>
            Playground
          </a>
          {/* Ajoutez ici d'autres actions si nécessaire */}
        </div>
      )}
      {showPlayground && (
        <DataSetsDatabaseModal
          onHide={() => setShowPlayground(false)}
          projectId={chain.apiProjectId}
          show={true}
          chainId={chain.id}
        />
      )}
    </div>
  );
};

const FlowBuilder = ({ reactFlowInstance }) => {
  const {
    promptBlocks = [],
    emitters = [],
    inputConnections = [],
    inputBlocks = [],
    dataObjects = [],
    chainActions
  } = useContext(ChainContext);
  const { setBuilderViewPort } = useContext(SystemBuilderInterfaceContext);
  const { setNodes: setNodesFlow } = reactFlowInstance;
  const { x: viewportX, y: viewportY, zoom } = useViewport();
  const [showGlobalParameters, setShowGlobalParameters] = useState(false);
  const [nodes, setNodes] = useState(
    getDataNodes(
      inputBlocks,
      dataObjects,
      promptBlocks,
      emitters,
      inputConnections
    )
  );
  const [contextMenu, setContextMenu] = useState({
    show: false,
    position: { x: 0, y: 0 }
  });
  const [edges, setEdges] = useState([]);
  const isMovingNode = React.useRef(false);
  const lastNodesPositions = React.useRef({});

  useEffect(() => {
    setNodesFlow(
      getDataNodes(
        inputBlocks,
        dataObjects,
        promptBlocks,
        emitters,
        inputConnections
      )
    );
    setNodes(
      getDataNodes(
        inputBlocks,
        dataObjects,
        promptBlocks,
        emitters,
        inputConnections
      )
    );
  }, [promptBlocks, emitters, inputConnections]);

  useEffect(() => {
    setBuilderViewPort({ x: viewportX, y: viewportY, zoom });
  }, [zoom, viewportX, viewportY]);

  const updateEmitters = async (emitters) => {
    try {
      for (let i = 0; i < emitters.length; i++) {
        const emitter = emitters[i];

        await chainActions.updateEmitter(emitter);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateInputConnections = async (connections) => {
    try {
      for (let i = 0; i < connections.length; i++) {
        const connection = connections[i];
        await chainActions.updateInputConnection(connection);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updatePromptBlocksPositions = (nodes) => {
    const newPromptBlocks = [...promptBlocks];

    nodes.forEach((node) => {
      const { id, positionFlow } = node;
      if (!positionFlow) {
        return;
      }
      const index = newPromptBlocks.findIndex((pb) => pb.chainKey === id);
      if (index !== -1) {
        newPromptBlocks[index] = {
          ...newPromptBlocks[index],
          positionFlow: positionFlow
        };
      }
    });

    chainActions.updatePromptBlocks(newPromptBlocks);
  };

  const updateInputBlocksPositions = (nodes) => {
    const newInputBlocks = [...inputBlocks];

    nodes.forEach((node) => {
      const { id, positionFlow } = node;
      if (!positionFlow) {
        return;
      }
      const index = newInputBlocks.findIndex((ib) => ib.id === id);
      if (index !== -1) {
        newInputBlocks[index] = {
          ...newInputBlocks[index],
          positionFlow: positionFlow
        };
      }
    });

    chainActions.updateInputBlocks(newInputBlocks);
  };

  const updateDataObjectsPositions = (nodes) => {
    const newDataObjects = [...dataObjects];

    nodes.forEach((node) => {
      const { id, positionFlow } = node;
      if (!positionFlow) {
        return;
      }
      const index = newDataObjects.findIndex((ib) => ib.id === id);
      if (index !== -1) {
        newDataObjects[index] = {
          ...newDataObjects[index],
          positionFlow: positionFlow
        };
      }
    });

    chainActions.updateDataObjects(newDataObjects);
  };

  const onNewPositionsCallback = (changePayload) => {
    flowDbUtils.updateNodesPositions(
      changePayload,
      isMovingNode,
      lastNodesPositions,
      (newNodes) => {
        const groupedNodes = groupNodes(newNodes);

        if (groupedNodes.emitters.length > 0) {
          updateEmitters(groupedNodes.emitters);
        }
        if (groupedNodes.inputConnections.length > 0) {
          updateInputConnections(groupedNodes.inputConnections);
        }
        if (groupedNodes.promptBlocks.length > 0) {
          updatePromptBlocksPositions(groupedNodes.promptBlocks);
        }
        if (groupedNodes.inputBlocks.length > 0) {
          updateInputBlocksPositions(groupedNodes.inputBlocks);
        }
        if (groupedNodes.dataObjects.length > 0) {
          updateDataObjectsPositions(groupedNodes.dataObjects);
        }
      }
    );
  };

  const onNodesChange = React.useCallback(
    (changes) =>
      setNodes((nds) => {
        // reset, position, add, select, dimensions --- remove, hover, connect, drag, selectconnection, source, target
        const positions = changes.map((change) => {
          const { type: changeType } = change;

          if (changeType === "position") {
            const { position = null, id = null } = change;
            return { position, id, changeType };
          } else if (changeType === "add") {
            const { item } = change;
            const { data, id, position, type } = item;
            return { data, id, position, type, changeType };
          }
          return { ...change, changeType };
        });

        const positionsToChange = positions.filter(
          (position) => position.changeType === "position"
        );
        if (positionsToChange.length > 0) {
          onNewPositionsCallback(positionsToChange);
        }

        const filteredChanges = changes.filter((change) => {
          const { type } = change;
          return !["remove", "select"].includes(type);
        });

        return applyNodeChanges(filteredChanges, nds);
      }),
    [onNewPositionsCallback]
  );

  const onEdgesChange = React.useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onConnect = React.useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  const onBackgroundContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const x = e.clientX;
    const y = e.clientY;
    const height = e.target.clientHeight;

    setContextMenu({ show: true, position: { x, y }, menuHeight: height });
  };

  const onCloseContextMenu = () => {
    setContextMenu({ show: false, position: { x: 0, y: 0 } });
  };

  const createNewNode = (id, type, nodeData) => {
    const { positionFlow = { x: 0, y: 0 } } = nodeData;
    const newNode = {
      id: `${type}-${id}`,
      data: {
        ...nodeData,
        key: id,
        label: id
      },
      position: positionFlow,
      type: type
    };

    return newNode;
  };

  const getPositionFlow = (clientX, clientY, zoom, viewportX, viewportY) => {
    const xFromViewport = -(viewportX / zoom) + clientX / zoom;
    const yFromViewport = -(viewportY / zoom) + clientY / zoom;

    const positionFlow = {
      x: xFromViewport,
      y: yFromViewport
    };

    return positionFlow;
  };

  const actions = [
    {
      name: "Add Data Fetcher",
      onClick: async (e) => {
        const { clientX, clientY } = e;

        const positionFlow = getPositionFlow(
          clientX,
          clientY,
          zoom,
          viewportX,
          viewportY
        );

        const newConnection = await chainActions.createInputConnection({
          positionFlow
        });

        const newNode = createNewNode(
          newConnection.id,
          "inputConnection",
          newConnection
        );
      },
      icon: "fas fa-plus-circle"
    },
    {
      name: "Add AI Processing Block",
      onClick: async (e) => {
        const { clientX, clientY } = e;

        const positionFlow = getPositionFlow(
          clientX,
          clientY,
          zoom,
          viewportX,
          viewportY
        );

        const newPromptBlock = await chainActions.createPromptBlock({
          positionFlow
        });

        const newNode = createNewNode(
          newPromptBlock.chainKey,
          "transform",
          newPromptBlock
        );
      },
      icon: "fas fa-plus-circle"
    },
    {
      name: "Add Logic Module",
      onClick: async (e) => {
        const { clientX, clientY } = e;

        const positionFlow = getPositionFlow(
          clientX,
          clientY,
          zoom,
          viewportX,
          viewportY
        );

        const newEmitter = await chainActions.createEmitter({
          positionFlow,
          action: "logic"
        });

        const newNode = createNewNode(newEmitter.id, "emitter", newEmitter);
      },
      icon: "fas fa-plus-circle"
    },
    {
      name: "Add Action Fetcher",
      onClick: async (e) => {
        const { clientX, clientY } = e;

        const positionFlow = getPositionFlow(
          clientX,
          clientY,
          zoom,
          viewportX,
          viewportY
        );

        const newEmitter = await chainActions.createEmitter({
          positionFlow,
          action: "fetch"
        });

        const newNode = createNewNode(newEmitter.id, "emitter", newEmitter);
      },
      icon: "fas fa-plus-circle"
    }
  ];

  return (
    <>
      {showGlobalParameters && <GlobalChainParameters />}
      <ReactFlow
        style={{ height: "100%" }}
        nodes={nodes}
        edges={edges}
        nodeTypes={nodesTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        panOnScroll
        snapToGrid={true}
        snapGrid={[17, 20]}
        // set origin to -100, -100
        defaultViewport={{
          x: 0,
          y: 0,
          zoom: 1
        }}
        className="flow-builder"
        onPaneContextMenu={onBackgroundContextMenu}
      >
        <Panel position="top-center" className="px-5 w-100">
          <FloatingMenu />
        </Panel>
        <ContextMenuPane
          {...contextMenu}
          onClose={onCloseContextMenu}
          actions={actions}
        />
        <Background className="nodes-background" gap={77} id="background" />
        <Controls />
        <MiniMap />
      </ReactFlow>
    </>
  );
};

export default FlowBuilder;
