/**
 * @file useAuth.js
 * @description This file contains the useAuth hook.
 * the useAuth hook is used to check if the user is logged in or not.
 * It uses the useLocalStorage hook to check if the user is logged in or not.
 * It uses firebase to provide the authentication methods.
 * It uses firebase to provide account creation methods.
 * The account creation is made using email and password methods of firebase.
 */

import { useEffect, useState, useContext } from "react";
import { firebaseAuth } from "../api-connector/firebase";
import { GlobalContext } from "../contexts/GlobalContext";
import DB from "../database/DB";

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

const createUserAccount = async (uid) => {
  try {
    console.log("create user account", uid);
    const docId = await DB.userAccounts.createWithId(uid, {
      uid: uid,
      id: uid,
      apiKeys: {
        openai: "",
      },
    });
    console.log("docId", docId);
  } catch (error) {
    console.error(error);
  }
};

const useAuth = () => {
  const { setFirebaseUser, setFirebaseUserLoading } = useContext(GlobalContext);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      setUser(user);
      setFirebaseUser(user);
      setFirebaseUserLoading(false);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const signup = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        firebaseAuth,
        email,
        password
      );
      const uid = userCredential.user.uid;
      await createUserAccount(uid);
    } catch (error) {
      setError(error.message);
    }
  };

  const login = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        firebaseAuth,
        email,
        password
      );
    } catch (error) {
      setError(error.message);
    }
  };

  const logout = async () => {
    try {
      await signOut(firebaseAuth);
    } catch (error) {
      setError(error.message);
    }
  };

  return {
    user,
    loading,
    error,
    signup,
    login,
    logout,
  };
};

export default useAuth;
