import React from "react";
import "./UserGeneratedApiDetails.css"; // Assurez-vous d'inclure le chemin correct vers votre fichier CSS

const UserGeneratedApiDetails = ({
  baseApiUrl = "{deploymentApiHost}",
  ref = null,
  lite = false
}) => {
  return (
    <div
      className={`user-generated-api-details-container ${lite ? "lite" : ""}`}
      ref={ref}
    >
      <p>
        This section covers the entire user-generated API, including how to
        develop interfaces and utilize the system with it.
      </p>
      <div className="api-endpoint">
        <span>Base Path for User API:</span>
        <code>
          {`${baseApiUrl}/`}
          <strong>pb</strong>
        </code>
      </div>
      <p>
        This API path is accessible with tokens defined in the TokenGroups by
        the user. It is the backbone for developing your interfaces and
        interacting with your system.
      </p>
      <div className="api-headers">
        <span>Required Headers for requests:</span>
        <code>{`Authorization: Bearer {tokenAuth}:{secretKey}`}</code>
      </div>
      <p>
        Use tokens generated from the TokenGroups management methods for these
        requests. The header should include the generated token in the format
        {`Authorization: Bearer {tokenAuth}:{secretKey}`}.
      </p>
    </div>
  );
};

export default UserGeneratedApiDetails;
