import RequestCreaxysSDK from "./requests";

class CreaxysSystemHelperClass {
  async loadSystemOverview(apiProjectId) {
    const result = await RequestCreaxysSDK.getOverview(apiProjectId);
    console.log("loadSystemOverview", result);
    return result;
  }
}

export const CreaxysSystemHelper = new CreaxysSystemHelperClass();
