import React, { useState, useEffect } from "react";
import { Form, Col } from "react-bootstrap";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { sublime } from "@uiw/codemirror-theme-sublime";
import "./TypingForm.css"; // Assurez-vous d'avoir ce fichier CSS

const TypingForm = ({ typing, onTypingChange }) => {
  const { globalType, result: typingResult, enums } = typing;

  const handleTypeChange = (e) => {
    const newType = e.target.value;
    onTypingChange({
      globalType: newType,
      result: "",
      enums: []
    });
  };

  const handleTypingResultChange = (value) => {
    onTypingChange({ globalType, result: value, enums });
  };

  const handleEnumChange = (e) => {
    const enums = e.target.value.split(",").map((item) => item.trim());
    onTypingChange({
      globalType,
      result: typingResult,
      enums
    });
  };

  let resultStr = "";

  try {
    if (typeof typingResult === "object") {
      resultStr = JSON.stringify(typingResult, null, 2);
    } else if (typeof typingResult === "string") {
      resultStr = typingResult;
    }
  } catch (e) {
    resultStr = typingResult;
  }

  return (
    <div className="typing-form">
      <Form.Group as={Col} controlId="globalTypeSelect">
        <Form.Label>Global Type</Form.Label>
        <Form.Control
          as="select"
          value={globalType}
          onChange={handleTypeChange}
        >
          <option value="">Select a type</option>
          <option value="JSON_OBJECT">JSON Object</option>
          <option value="JSON_ARRAY">JSON Array</option>
          <option value="STRING_ENUM">String Enum</option>
          <option value="STRING">String</option>
          <option value="NUMBER">Number</option>
          <option value="BOOLEAN">Boolean</option>
        </Form.Control>
      </Form.Group>
      {globalType === "STRING_ENUM" && (
        <Form.Group as={Col}>
          <Form.Label>Enum Values (comma separated)</Form.Label>
          <Form.Control
            type="text"
            placeholder="e.g. value1, value2"
            value={enums && enums.length > 0 ? enums.join(", ") : ""}
            onChange={handleEnumChange}
          />
        </Form.Group>
      )}
      {(globalType === "JSON_OBJECT" || globalType === "JSON_ARRAY") && (
        <Form.Group as={Col}>
          <Form.Label>{`Typing Result (${globalType})`}</Form.Label>
          <CodeMirror
            value={resultStr}
            height="100%"
            className="h-100"
            extensions={[javascript({ jsx: false, typescript: false })]}
            theme={sublime}
            onChange={handleTypingResultChange}
          />
        </Form.Group>
      )}
    </div>
  );
};

export default TypingForm;
