import React, { useState, useEffect } from "react";
import { useDeployments } from "../../../../contexts/DeploymentContext";
import { useApiBuilder } from "../../../../contexts/ApiBuilderContext";
import { FaSpinner } from "react-icons/fa";
import DB from "../../../../database/DB";
import "./CreateCreaxysDeploymentForm.css";
import DeploymentApi from "../../../../apis/deployment";

const CreateCreaxysDeploymentForm = ({ onReturnToList }) => {
  const { apiProject } = useApiBuilder();
  const { fetchDeployments } = useDeployments();
  const [creatingDeployment, setCreatingDeployment] = useState(false);
  const { deploymentModels } = useApiBuilder();
  const [tokenGroups, setTokenGroups] = useState([]);
  const [deploymentData, setDeploymentData] = useState({
    name: "",
    deploymentModelId: "",
    resourceType: "managed", // Par défault
    deployedTokenGroups: {}
  });
  const selectedDeployment = deploymentModels.find(
    (model) => model.id === deploymentData.deploymentModelId
  );
  const tokenGroupsModelSelected = selectedDeployment?.tokenGroups;
  const projectId = apiProject?.id;

  useEffect(() => {
    if (deploymentData.deploymentModelId) {
      const deploymentModel = deploymentModels.find(
        (model) => model.id === deploymentData.deploymentModelId
      );
      const tokenGroupsModel = deploymentModel.tokenGroups || [];

      const oldDeployedTokenGroups = deploymentData.deployedTokenGroups || {};
      const newDeployedTokenGroups = {};

      tokenGroupsModel.forEach((tokenGroup) => {
        const oldDeployedTokenGroup =
          oldDeployedTokenGroups[tokenGroup.id] || "new";
        newDeployedTokenGroups[tokenGroup.id] = oldDeployedTokenGroup;
      });

      setDeploymentData({
        ...deploymentData,
        deployedTokenGroups: newDeployedTokenGroups
      });
    } else {
      setDeploymentData({
        ...deploymentData,
        deployedTokenGroups: {}
      });
    }
  }, [deploymentData.deploymentModelId]);

  useEffect(() => {
    const loadTokenGroups = async () => {
      const tokenGroups = await DB.tokenGroups.getList();
      setTokenGroups(tokenGroups);
    };

    loadTokenGroups();
  }, []);

  const handleTokenGroupChange = (tokenGroupModelId, selectedTokenGroupId) => {
    const updatedDeployedTokenGroups = {
      ...deploymentData.deployedTokenGroups,
      [tokenGroupModelId]: selectedTokenGroupId
    };

    setDeploymentData({
      ...deploymentData,
      deployedTokenGroups: updatedDeployedTokenGroups
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCreatingDeployment(true);
    await DeploymentApi.deployManaged(
      projectId,
      deploymentData.deploymentModelId,
      deploymentData.name,
      deploymentData.resourceType,
      deploymentData.deployedTokenGroups
    );
    await fetchDeployments();
    setCreatingDeployment(false);
    onReturnToList();
  };

  return (
    <div className="create-deployment-form-container">
      <div className="create-deployment-form-header">
        <h2>Create Deployment</h2>
        <button onClick={onReturnToList}>Back to List</button>
      </div>
      <form className="create-deployment-form" onSubmit={handleSubmit}>
        <label>Deployment Name</label>
        <input
          type="text"
          value={deploymentData.name}
          onChange={(e) =>
            setDeploymentData({ ...deploymentData, name: e.target.value })
          }
          required
        />

        <label>Deployment Model</label>
        <select
          value={deploymentData.deploymentModelId}
          onChange={(e) =>
            setDeploymentData({
              ...deploymentData,
              deploymentModelId: e.target.value
            })
          }
          required
        >
          <option value="">Select a Deployment Model</option>
          {deploymentModels.map((model) => (
            <option key={model.id} value={model.id}>
              {model.name}
            </option>
          ))}
        </select>

        {tokenGroupsModelSelected && (
          <div className="token-group-selection">
            <h3>Token Groups</h3>
            {tokenGroupsModelSelected.map((tokenGroup) => (
              <div
                key={tokenGroup.id}
                className={`token-group-block ${
                  deploymentData.deployedTokenGroups[tokenGroup.id]
                    ? "configured"
                    : "unconfigured"
                }`}
              >
                <span className="token-group-name">{tokenGroup.name}</span>
                <select
                  className="token-group-select"
                  value={
                    deploymentData.deployedTokenGroups[tokenGroup.id] || ""
                  }
                  onChange={(e) =>
                    handleTokenGroupChange(tokenGroup.id, e.target.value)
                  }
                >
                  {/* <option value="">Select a Token Group</option> */}
                  <option value="new">Create New Token Group</option>
                  {tokenGroups.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
                </select>
              </div>
            ))}
          </div>
        )}

        <button type="submit" disabled={creatingDeployment}>
          {creatingDeployment ? (
            <>
              <FaSpinner className="spin" /> Creating Deployment...
            </>
          ) : (
            "Create Deployment"
          )}
          {/* Launch Deployment */}
        </button>
        <button type="button" onClick={onReturnToList}>
          Cancel
        </button>
      </form>
    </div>
  );
};

export default CreateCreaxysDeploymentForm;
