import React, { useEffect, useRef, useState } from "react";
import "./RestApiDocumentation.css";
import { EndpointDetailsWithData } from "../EndpointDetails";
import MiddlewareDetailsComponent from "../middleware-components/MiddlewareDetailsComponent";
import { TokenGroupsApiDetails } from "../TokenGroupManager";
import CreaxysConfigurationDetails from "./details/CreaxysConfigurationDetails";
import UserGeneratedApiDetails from "./details/UserGeneratedApiDetails";
import { ApiEndpointDetails } from "./ApiEndpointDetails";
import generatePostmanCollection from "../../../../../../utils/generatePostmanCollection";
import { languages } from "./languages";
import DeploymentApi from "../../../../../../apis/deployment";

// return array of paragraphs ranges with min=element[i].offsetTop and max=element[i+1].offsetTop
const getParagraphsRanges = (paragraphDetailsRef, top) => {
  const paragraphs = Object.values(paragraphDetailsRef);
  const paragraphsRanges = [];
  for (let i = 0; i < paragraphs.length; i++) {
    const paragraph = paragraphs[i];
    const nextParagraph = paragraphs[i + 1];
    if (nextParagraph) {
      paragraphsRanges.push({
        min: paragraph.offsetTop - top,
        max: nextParagraph.offsetTop - top
      });
    } else {
      paragraphsRanges.push({
        min: paragraph.offsetTop - top,
        max: paragraph.offsetTop + paragraph.offsetHeight - top
      });
    }
  }
  return paragraphsRanges;
};

const getParagraphIdFromScroll = (paragraphDetailsRef, scrollY, top) => {
  const paragraphsRanges = getParagraphsRanges(paragraphDetailsRef, top);
  for (let i = 0; i < paragraphsRanges.length; i++) {
    const paragraphRange = paragraphsRanges[i];
    if (scrollY >= paragraphRange.min && scrollY < paragraphRange.max) {
      return Object.keys(paragraphDetailsRef)[i];
    }
  }
  return null;
};

const getLocalStoredLanguageConfig = () => {
  const localStoredLanguageConfig = localStorage.getItem(
    "restApiDocumentationLanguageConfig"
  );
  if (localStoredLanguageConfig) {
    try {
      return JSON.parse(localStoredLanguageConfig);
    } catch (error) {}
  }
  return {
    selectedLanguage: "javascript",
    selectedLanguageLib: "vanilla"
  };
};

const setLocalStoredLanguageConfig = (languageConfig) => {
  localStorage.setItem(
    "restApiDocumentationLanguageConfig",
    JSON.stringify(languageConfig)
  );
};

const RestApiDocumentation = ({
  deploymentModel,
  baseApiUrl,
  readableApiMap,
  deployment
}) => {
  const [latestDeploymentApiKey, setLatestDeploymentApiKey] = useState(null);
  const [languageConfig, setLanguageConfig] = useState(
    getLocalStoredLanguageConfig()
  );
  const [selectedParagraphId, setSelectedParagraphId] = useState(null); // paragraph id is the id of the component
  const contentRef = useRef(null);
  const paragraphDetailsRef = useRef({});
  const { selectedLanguage, selectedLanguageLib } = languageConfig;

  let deploymentModelName = deploymentModel.name;

  if (!deploymentModelName.toLowerCase().includes("api")) {
    deploymentModelName += " API";
  }

  console.log("deploymentModel", deploymentModel);

  useEffect(() => {
    setLocalStoredLanguageConfig(languageConfig);
  }, [languageConfig]);

  useEffect(() => {
    if (deployment && deployment.id) {
      DeploymentApi.getLatestCreaxysApiKey(deployment.id).then((data) => {
        setLatestDeploymentApiKey(data);
      });
    }
  }, [deployment]);

  const handleScroll = () => {
    const scrollY = contentRef.current.scrollTop;
    const top = contentRef.current.getBoundingClientRect().top;
    const paragraphId = getParagraphIdFromScroll(
      paragraphDetailsRef.current,
      scrollY,
      top
    );
    if (paragraphId) {
      setSelectedParagraphId(paragraphId);
    }
  };

  useEffect(() => {
    contentRef.current.addEventListener("scroll", handleScroll);
    return () => {
      if (contentRef.current) {
        contentRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const scrollContentToParagraph = (paragraphId) => {
    const paragraph = paragraphDetailsRef.current[paragraphId];
    if (paragraph) {
      contentRef.current.scrollTo({
        top: paragraph.offsetTop - 100,
        behavior: "smooth"
      });
    }
  };

  const renderSystemList = () => {
    return (
      <React.Fragment>
        <div
          className={`sidebar-item ${
            selectedParagraphId === "creaxys" ? "active" : ""
          }`}
          onClick={() => {
            setSelectedParagraphId("creaxys");
            scrollContentToParagraph("creaxys");
          }}
        >
          Creaxys Configuration
        </div>

        <div
          className={`sidebar-item ${
            selectedParagraphId === "api" ? "active" : ""
          }`}
          onClick={() => {
            setSelectedParagraphId("api");
            scrollContentToParagraph("api");
          }}
        >
          Token Groups
        </div>
        <div
          className={`sidebar-item ${
            selectedParagraphId === "systemApi" ? "active" : ""
          }`}
          onClick={() => {
            setSelectedParagraphId("systemApi");
            scrollContentToParagraph("systemApi");
          }}
        >
          {deploymentModelName} Documentation
        </div>
        <div
          className={`sidebar-item ${
            selectedParagraphId === "user" ? "active" : ""
          }`}
          onClick={() => {
            setSelectedParagraphId("user");
            scrollContentToParagraph("user");
          }}
        >
          API Details
        </div>
      </React.Fragment>
    );
  };

  const renderComponentList = (components) => {
    return components.map((component, index) => (
      <React.Fragment key={index}>
        <div
          className={`sidebar-item ${
            selectedParagraphId === component.id ? "active" : ""
          }`}
          onClick={() => {
            setSelectedParagraphId(component.id);
            scrollContentToParagraph(component.id);
          }}
        >
          {component.url || component.name || component.type}
        </div>
        {(component.routes || component.endpoints || component.middlewares) && (
          <div className="child-components">
            {renderComponentList(component.middlewares || [], "Middleware")}
            {renderComponentList(component.endpoints || [], "Endpoint")}
            {renderComponentList(component.routes || [], "Route")}
          </div>
        )}
      </React.Fragment>
    ));
  };

  const renderComponentTitle = (component) => {
    let title = "";
    let subtitle = "";

    if (component.type === "route") {
      title = "Route";
      subtitle = component.url;
      return (
        <h3>
          {title} - {subtitle}
        </h3>
      );
    } else if (component.type === "endpoint") {
      title = "Endpoint";
      subtitle = component.method + " " + component.url;
      return (
        <h4>
          <span className={`method fs-6 ${component.method.toLowerCase()}`}>
            {component.method}
          </span>{" "}
          {component.url}
        </h4>
      );
    } else if (component.type === "middleware") {
      title = "Middleware";
      subtitle = component.name;
      return <h4>{subtitle}</h4>;
    }

    return (
      <div className="component-title">
        No title for component type {component.type}
      </div>
    );
  };

  const downloadJson = (data, fileName) => {
    const blob = new Blob([data], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  };

  const downloadPostman = () => {
    const host =
      process.env.NODE_ENV === "production"
        ? "https://cx0.io"
        : "http://localhost:8000";
    const baseUrlCreaxys = `${host}/${deployment.id}/dep`;
    const apiKey = latestDeploymentApiKey && latestDeploymentApiKey.keyId;
    const headers = [
      {
        key: "Authorization",
        value: "Bearer " + apiKey
      }
    ];
    const tokenGroupsModelIds = (deploymentModel.tokenGroups || []).map(
      (tokenGroup) => `${tokenGroup.id}(${tokenGroup.name})`
    );

    const creaxysEndpoints = [
      {
        name: "Generate Group's Api Key",
        url: `${baseUrlCreaxys}/generateTokenGroupApiKey`,
        headers: headers,
        method: "POST",
        body: {
          tokenGroupId: tokenGroupsModelIds.join(", "),
          custom_metadata: "string",
          unique_id_for_rollkey: "string"
        }
      },
      {
        name: "Verify Group's Api Key",
        url: `${baseUrlCreaxys}/verifyTokenGroupApiKey`,
        headers: headers,
        method: "POST",
        body: {
          keyId: "string"
        }
      },
      {
        name: "Get Group's Api Key Using A Secure Token",
        url: `${baseUrlCreaxys}/getSecureToken`,
        headers: headers,
        method: "POST",
        body: {
          secure_token: "string"
        }
      }
    ];

    const postmanCollection = generatePostmanCollection(
      deployment,
      creaxysEndpoints
    );

    downloadJson(postmanCollection, "postman_collection.json");
  };

  const renderComponentDetails = (component) => {
    if (!component) {
      return <div>Select a component to view details...</div>;
    }

    const componentTitle = renderComponentTitle(component);
    const readableApi = readableApiMap && readableApiMap[component.id];

    return (
      <div
        className="component-details"
        ref={(ref) => (paragraphDetailsRef.current[component.id] = ref)}
      >
        {/* Contenu détaillé ici */}
        {component.type === "endpoint" &&
          (!!readableApi ? (
            <ApiEndpointDetails
              languageLib={selectedLanguageLib}
              exampleLanguage={selectedLanguage}
              lite
              title={`${readableApi.path}${component.url}`}
              endpointUrl={
                baseApiUrl + "/pb" + readableApi.path + component.url
              }
              method={readableApi.method}
              headers={{
                Authorization: "Bearer {Token Group API Key}}",
                ...readableApi.headers
              }}
              body={readableApi.body}
              query={readableApi.queries}
              response={{
                type: readableApi.responses
              }}
            />
          ) : (
            <EndpointDetailsWithData component={component} lite noMethod />
          ))}
        {component.type === "middleware" && (
          <>
            {componentTitle}

            <MiddlewareDetailsComponent middleware={component} lite />
          </>
        )}
      </div>
    );
  };

  const renderParagraphDetailsList = (components) => {
    return components.map((component, index) => {
      const isRouteButNotFirst = component.type === "route" && index > 0;
      const isEndpointButNotFirst = component.type === "endpoint" && index > 0;
      const isMiddlewareButNotFirst =
        component.type === "middleware" && index > 0;

      return (
        <React.Fragment key={index}>
          {isRouteButNotFirst && (
            <div
              className="my-5"
              style={{ width: "100%", height: "3px", backgroundColor: "#ddd" }}
            ></div>
          )}
          {isEndpointButNotFirst && (
            <div
              className="my-4"
              style={{ width: "100%", height: "1px", backgroundColor: "#ddd" }}
            ></div>
          )}
          {isMiddlewareButNotFirst && (
            <div
              className="my-4"
              style={{ width: "100%", height: "1px", backgroundColor: "#ddd" }}
            ></div>
          )}

          {renderComponentDetails(component)}
          {(component.routes ||
            component.endpoints ||
            component.middlewares) && (
            <div className="">
              {component.middlewares && component.middlewares.length > 0 && (
                <h2 className="text-center mt-4 mb-5">
                  Requirements for route {component.url}
                </h2>
              )}
              {renderParagraphDetailsList(component.middlewares || [])}
              {component.endpoints && component.endpoints.length > 0 && (
                <h2 className="text-center mt-5 mb-5">
                  Endpoints for route {component.url}
                </h2>
              )}
              {renderParagraphDetailsList(component.endpoints || [])}
              {component.routes && component.routes.length > 0 && (
                <h2 className="mx-5">Routes</h2>
              )}
              {renderParagraphDetailsList(component.routes || [])}
            </div>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <div className="rest-api-doc">
      <div className="sidebar">
        {renderSystemList()}
        {renderComponentList(deploymentModel.components)}
      </div>
      <div className="doc-actions-header">
        {deployment && (
          <button className="download-postman-btn" onClick={downloadPostman}>
            Download Postman Collection
          </button>
        )}

        <div className="language-selector">
          <select
            value={selectedLanguage}
            onChange={(e) => {
              const libs = languages[e.target.value].libs;
              const firstLib = Object.keys(libs)[0];

              setLanguageConfig({
                selectedLanguage: e.target.value,
                selectedLanguageLib: firstLib
              });
            }}
          >
            {Object.keys(languages).map((language) => (
              <option key={language} value={language}>
                {languages[language].name}
              </option>
            ))}
          </select>
          {languages[selectedLanguage] && languages[selectedLanguage].libs && (
            <select
              value={selectedLanguageLib}
              onChange={(e) => {
                setLanguageConfig({
                  selectedLanguage: selectedLanguage,
                  selectedLanguageLib: e.target.value
                });
              }}
            >
              {Object.keys(languages[selectedLanguage].libs).map((libKey) => (
                <option key={libKey} value={libKey}>
                  {languages[selectedLanguage].libs[libKey].name}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>
      <div className="content" ref={contentRef}>
        <div
          className="mb-5"
          ref={(ref) => (paragraphDetailsRef.current["creaxys"] = ref)}
        >
          <h4>Creaxys Configuration</h4>

          <CreaxysConfigurationDetails lite baseApiUrl={baseApiUrl} />
        </div>

        {deploymentModel.tokenGroups &&
          deploymentModel.tokenGroups.length > 0 && (
            <div
              className="mb-5"
              ref={(ref) => (paragraphDetailsRef.current["api"] = ref)}
            >
              <h4>Token Groups Models</h4>

              <TokenGroupsApiDetails
                languageLib={selectedLanguageLib}
                exampleLanguage={selectedLanguage}
                tokenGroups={deploymentModel.tokenGroups}
                lite
                baseApiUrl={baseApiUrl}
              />
            </div>
          )}

        <div
          style={{
            height: "3px",
            width: "100%",
            backgroundColor: "#ddd",
            marginTop: "100px"
          }}
        ></div>

        <div
          style={{ marginBottom: "100px", marginTop: "100px" }}
          ref={(ref) => (paragraphDetailsRef.current["systemApi"] = ref)}
        >
          <h2 className="text-center">
            {deploymentModelName} Documentation
            <br></br>
            Routes, Endpoints, and Configuration
          </h2>
        </div>

        <div
          style={{ marginBottom: "100px" }}
          ref={(ref) => (paragraphDetailsRef.current["user"] = ref)}
        >
          <h4>API Details</h4>
          <UserGeneratedApiDetails lite baseApiUrl={baseApiUrl} />
        </div>

        {renderParagraphDetailsList(deploymentModel.components)}
      </div>
    </div>
  );
};

export default RestApiDocumentation;
