/**
 * @function getInputs
 * @description Get the inputs for the current flow render a list of objects with keys and type if exists as follow: {
 *   transform : {
 *     promptBlocks : [{key: "input1", varType: "input", type: "string"}, {key: "input2", varType: "computed", type: "string"}],
 *   },
 *   inputConnections: {
 *     fetcher : [{key: "input1", type: "string"}, {key: "input2", type: "number"}],
 *   },
 *   emitters: {
 *      fetcher : [{key: "input1", type: "string"}, {key: "input2", type: "number"}],
 *      logic : [{key: "input1", type: "string", varType : "rawValue"}, {key: "input2", type: "number", varType : "variableRef"}],
 *   }
 * }
 *   - For promptBlocks, if promptBlock.blockType === "completion",
 *     the valueKey is the the property name of each valuesLinkedImport property,
 *     if valuesLinkedImport[valueKey].value is not null, then the final valueKey is valuesLinkedImport[valueKey].value
 *     transform.promptBlocks[index].varType = valuesLinkedImport[valueKey].type
 *   - For InputConnection, if inputConnection.mode === "fetcher"
 *     the valueKey contains:
 *     - inputConnection.name
 *     - inputConnection.fetcherConfig.importedParameters[index].key
 *       if in fetcherConfig.importedParameters is empty or does not exist, then there is no valueKey
 *  - For Emitter, if emitter.mode === "fetcher"
 *    the valueKey contains:
 *   - emitter.name + "." + emitter.fetcherConfig.importedParameters[index].key
 *   if in fetcherConfig.importedParameters is empty or does not exist, then then there is no valueKey
 * - For Emitter, if emitter.mode === "logic"
 *   the valueKey contains:
 *     - emitter.name + "." + emitter.logicConfig.importedParameters[index].key
 *     if in logicConfig.importedParameters is empty or does not exist, then then there is no valueKey
 *
 *
 *
 * @param {[PromptBlock]} promptBlocks
 * @param {[PromptBlock]} promptBlock.blockType // should be "completion"
 * @param {string} promptBlock.valuesLinkedImport[valueKey].type // input/computed
 * @param {string} promptBlock.valuesLinkedImport[valueKey].value // if value is not null, then it's the valueKey of the input
 * @param {[InputConnection]} inputConnections
 * @param {string} inputConnection.name
 * @param {string} inputConnection.fetcherConfig
 * @param {string} inputConnection.fetcherConfig.importedParameters
 * @param {string} inputConnection.fetcherConfig.importedParameters[i].key
 * @param {string} inputConnection.fetcherConfig.importedParameters[i].type // string/number/boolean/etc...
 * @param {string} inputConnection.fetcherConfig.importedParameters[i].isRequired
 * @param {[Emitter]} emitters
 * @param {[Emitter]} emitter.name
 * @param {[Emitter]} emitter.mode // fetcher/logic
 * @param {[Emitter]} emitter.fetcherConfig // if mode === "fetcher"
 * @param {[Emitter]} emitter.fetcherConfig.importedParameters
 * @param {[Emitter]} emitter.fetcherConfig.importedParameters[i].key
 * @param {[Emitter]} emitter.fetcherConfig.importedParameters[i].isRequired
 * @param {[Emitter]} emitter.fetcherConfig.importedParameters[i].type // string/number/boolean/etc...
 * @param {[Emitter]} emitter.logicConfig // if mode === "logic"
 * @param {[Emitter]} emitter.logicConfig.importedParameters
 * @param {[Emitter]} emitter.logicConfig.importedParameters[i].key
 * @param {[Emitter]} emitter.logicConfig.importedParameters[i].type // rawValue/variableRef
 * @param {[Emitter]} emitter.logicConfig.importedParameters[i].valueType // string/number/boolean/etc...
 */

const getInputs = (
  promptBlocks,
  inputConnections,
  emitters,
  globalParameters = [],
  inputBlocks = []
) => {
  const inputs = {
    transform: {
      promptBlocks: []
    },
    inputConnections: {
      fetcher: []
    },
    emitters: {
      fetcher: [],
      logic: []
    },
    global: {
      parameters: []
    },
    inputBlocks: {
      userInput: []
    }
  };

  console.log("globalParameters", globalParameters);

  globalParameters.forEach((globalParameter) => {
    const { name, valueType } = globalParameter;
    inputs.global.parameters.push({
      key: name,
      valueType,
      inputType: "input:global:parameters"
    });
  });

  promptBlocks.forEach((promptBlock) => {
    const { chainKey, valuesLinkedExport } = promptBlock;
    const { result } = valuesLinkedExport;

    if (result) {
      inputs.transform.promptBlocks.push({
        key: result,
        varType: "input",
        type: "string",
        inputType: "input:transform:promptBlocks"
      });
    }
  });

  inputConnections.forEach((inputConnection) => {
    const { name, fetcherConfig = {} } = inputConnection;
    const { importedParameters = [] } = fetcherConfig;

    importedParameters.forEach((importedParameter) => {
      const { key, type } = importedParameter;
      inputs.inputConnections.fetcher.push({
        key: name + "." + key,
        type,
        inputType: "input:inputConnections:fetcher"
      });
    });
  });

  emitters.forEach((emitter) => {
    const { name, action, fetcherConfig = {}, logicConfig } = emitter;

    if (action === "fetch") {
      const { importedParameters = [] } = fetcherConfig;

      importedParameters.forEach((importedParameter) => {
        const { key, type } = importedParameter;
        inputs.emitters.fetch.push({
          key: name + "." + key,
          type,
          inputType: "input:emitters:fetcher"
        });
      });
    } else if (action === "logic") {
      const { importedParameters = [] } = logicConfig;

      importedParameters.forEach((importedParameter) => {
        const { key, type, valueType } = importedParameter;
        inputs.emitters.logic.push({
          key: name + "." + key,
          varType: type,
          type: valueType,
          inputType: "input:emitters:logic"
        });
      });
    }
  });

  inputBlocks.forEach((inputBlock) => {
    const { name, type } = inputBlock;

    inputs.inputBlocks.userInput.push({
      key: name,
      type,
      inputType: "input:inputBlocks:userInput"
    });
  });

  return inputs;
};

/**
 * @function getOutputs
 * @description Get the outputs for the current flow render a list of objects with keys and type if exists as follow: {
 *  transform : {
 *    promptBlocks : [{key: "output1", type: "string"}, {key: "output2", type: "string"}],
 *  },
 *  inputConnections: {
 *    fetcher : [{key: "output1", type: "string"}, {key: "output2", type: "number"}],
 *    logic : [{key: "output1", type: "string", varType : "rawValue"}, {key: "output2", type: "number", varType : "variableRef"}],
 *  },
 *  emitters: {
 *   fetcher : [{key: "output1", type: "string"}, {key: "output2", type: "number"}],
 *  }
 *
 *  - For promptBlocks, if promptBlock.blockType === "completion",
 *      the output key is promptBlock.valuesLinkedExport.result
 *      default type is string
 *  - For InputConnection, if inputConnection.mode === "fetcher"
 *    the output key is inputConnection.name + "." + inputConnection.fetcherConfig.exportedBody[0].key
 *    if in fetcherConfig.exportedBody is empty or does not exist, then the key is inputConnection.name and the type is requestAny
 *  - For Emitter, if emitter.mode === "fetcher"
 *    the output key is emitter.name + "." + emitter.fetcherConfig.exportedBody[0].key
 *    if in fetcherConfig.exportedBody is empty or does not exist, then the key is emitter.name and the type is requestAny
 *  - For Emitter, if emitter.mode === "logic"
 *   the output key is emitter.name + "." + emitter.logicConfig.exportedResult[0].key
 *   if in logicConfig.exportedResult is empty or does not exist, then the key is emitter.name and the type is logicAny
 *
 *
 *   @param {[PromptBlock]} promptBlocks
 *   @param {[PromptBlock]} promptBlock.blockType // should be "completion"
 *   @param {string} promptBlock.valuesLinkedExport.result // if value is not null, then it's the valueKey of the input
 *   @param {[InputConnection]} inputConnections
 *   @param {string} inputConnection.name
 *   @param {string} inputConnection.fetcherConfig
 *   @param {string} inputConnection.fetcherConfig.exportedBody
 *   @param {string} inputConnection.fetcherConfig.exportedBody[i].key
 *   @param {string} inputConnection.fetcherConfig.exportedBody[i].type // string/number/boolean/etc...
 *   @param {[Emitter]} emitters
 *   @param {[Emitter]} emitter.name
 *   @param {[Emitter]} emitter.mode // fetcher/logic
 *   @param {[Emitter]} emitter.fetcherConfig // if mode === "fetcher"
 *   @param {[Emitter]} emitter.fetcherConfig.exportedBody
 *   @param {[Emitter]} emitter.fetcherConfig.exportedBody[i].key
 *   @param {[Emitter]} emitter.fetcherConfig.exportedBody[i].type // string/number/boolean/etc...
 *   @param {[Emitter]} emitter.logicConfig // if mode === "logic"
 *   @param {[Emitter]} emitter.logicConfig.exportedResult
 *   @param {[Emitter]} emitter.logicConfig.exportedResult[i].key
 *   @param {[Emitter]} emitter.logicConfig.exportedResult[i].type // string/number/boolean/etc...
 */

const getOutputs = (promptBlocks, inputConnections, emitters) => {
  const outputs = {
    transform: {
      promptBlocks: []
    },
    inputConnections: {
      fetcher: []
    },
    emitters: {
      fetch: [],
      logic: []
    }
  };

  promptBlocks.forEach((promptBlock) => {
    const { chainKey, valuesLinkedExport } = promptBlock;
    const { result } = valuesLinkedExport;

    if (result) {
      outputs.transform.promptBlocks.push({
        key: result,
        type: "string",
        inputType: "output:transform:promptBlocks"
      });
    }
  });

  inputConnections.forEach((inputConnection) => {
    const { name, fetcherConfig = {} } = inputConnection;
    const { exportedBody = [] } = fetcherConfig;

    if (exportedBody.length > 0) {
      exportedBody.forEach((exported) => {
        const { key, type } = exported;
        outputs.inputConnections.fetcher.push({
          key: name + "." + key,
          type,
          inputType: "output:inputConnections:fetcher"
        });
      });
    } else {
      outputs.inputConnections.fetcher.push({
        key: name,
        type: "requestAny",
        inputType: "output:inputConnections:fetcher"
      });
    }
  });

  emitters.forEach((emitter) => {
    const { name, action, fetcherConfig = {}, logicConfig = {} } = emitter;

    if (action === "fetch") {
      const { exportedBody = [] } = fetcherConfig;

      if (exportedBody.length > 0) {
        exportedBody.forEach((exported) => {
          const { key, type } = exported;
          outputs.emitters.fetch.push({
            key: name + "." + key,
            type,
            inputType: "output:emitters:fetcher"
          });
        });
      } else {
        outputs.emitters.fetch.push({
          key: name,
          type: "requestAny",
          inputType: "output:emitters:fetcher"
        });
      }
    } else if (action === "logic") {
      const { exportedResult = [] } = logicConfig;

      if (exportedResult.length > 0) {
        exportedResult.forEach((exported) => {
          const { key, type } = exported;
          outputs.emitters.logic.push({
            key: name + "." + key,
            varType: "rawValue",
            type,
            inputType: "output:emitters:logic"
          });
        });
      } else {
        outputs.emitters.logic.push({
          key: name,
          type: "logicAny",
          inputType: "output:emitters:logic"
        });
      }
    }
  });

  return outputs;
};

// const inputs = {
//   transform: {
//     promptBlocks: [],
//   },
//   inputConnections: {
//     fetcher: [],
//   },
//   emitters: {
//     fetcher: [],
//     logic: [],
//   },
//   global: {
//     parameters: [],
//   },
// };
// const outputs = {
//   transform: {
//     promptBlocks: [],
//   },
//   inputConnections: {
//     fetcher: [],
//   },
//   emitters: {
//     fetch: [],
//     logic: [],
//   },
// };
// inputs/outputs items are as follow: { key, varType, type, inputType }
// key is as follow "blockName.property"
// emitter.name can be seen as blockName
// the final listeners does not inclue the emitter itself(which contains the listeners with blockName == emitter.name)

/**
 * @function getIOitems_emitter
 * @description Get the inputs/outputs items for a given emitter and based on a filter
 *   should return filtered items for listeners
 *   inputs/outputs items are as follow: { key, varType, type, inputType }
 *   key is as follow "blockName.property"
 *   emitter.name can be seen as blockName
 *   the final listeners does not inclue the emitter itself(which contains the listeners with blockName == emitter.name)
 *   filter is as follow : { inputs : [], outputs : [] } for example { inputs : ["transform.promptBlocks"], outputs : ["transform.promptBlocks", "inputConnections.fetcher"] }
 *   it's important to note that emitter.name is nothing related to ouputs/inputs keys, emiter.name is the blockName, it's for example "myNodeWhichContains"
 *   emitter, inputsConnections, transform etc.. are nodes which contains inputs/outpts which they share with other nodes
 *   inputs and outputs contains the inputs/outputs of every nodes included, and the goal here is to get the inputs/outputs of every nodes but filtering the kind of inputs/outputs we want
 */

const getIOitems_emitter = (inputs, outputs, emitter, filter) => {
  const emitterName = emitter.name;
  let filteredInputs = {};
  let filteredOutputs = {};
  let filteredInputsList = [];
  let filteredOutputsList = [];

  if (filter.inputs) {
    filteredInputs = filter.inputs.reduce((acc, input) => {
      const inputTypeKeys = input.split(".");
      const [nodeType, inputType] = inputTypeKeys;

      let inputsList = inputs[nodeType][inputType];

      inputsList = inputsList.filter(
        (inputItem) => inputItem.key.split(".")[0] !== emitterName
      );

      if (!acc[nodeType]) {
        acc[nodeType] = {};
      }

      acc[nodeType][inputType] = inputsList;

      filteredInputsList = filteredInputsList.concat(inputsList);

      return acc;
    }, {});
  }

  if (filter.outputs) {
    filteredOutputs = filter.outputs.reduce((acc, output) => {
      const outputTypeKeys = output.split(".");
      const [nodeType, outputType] = outputTypeKeys;

      let outputsList = outputs[nodeType][outputType];

      outputsList = outputsList.filter(
        (outputItem) => outputItem.key.split(".")[0] !== emitterName
      );

      if (!acc[nodeType]) {
        acc[nodeType] = {};
      }

      acc[nodeType][outputType] = outputsList;

      filteredOutputsList = filteredOutputsList.concat(outputsList);

      return acc;
    }, {});
  }

  return {
    inputs: filteredInputs,
    outputs: filteredOutputs,
    inputsList: filteredInputsList,
    outputsList: filteredOutputsList
  };
};

const getIOitems = (inputs, outputs, filter) => {
  let filteredInputs = {};
  let filteredOutputs = {};
  let filteredInputsList = [];
  let filteredOutputsList = [];

  if (filter.inputs) {
    filteredInputs = filter.inputs.reduce((acc, input) => {
      const inputTypeKeys = input.split(".");
      const [nodeType, inputType] = inputTypeKeys;

      let inputsList = inputs[nodeType][inputType];

      if (!acc[nodeType]) {
        acc[nodeType] = {};
      }

      acc[nodeType][inputType] = inputsList;

      filteredInputsList = filteredInputsList.concat(inputsList);

      return acc;
    }, {});
  }

  if (filter.outputs) {
    filteredOutputs = filter.outputs.reduce((acc, output) => {
      const outputTypeKeys = output.split(".");
      const [nodeType, outputType] = outputTypeKeys;

      let outputsList = outputs[nodeType][outputType];

      if (!acc[nodeType]) {
        acc[nodeType] = {};
      }

      acc[nodeType][outputType] = outputsList;

      filteredOutputsList = filteredOutputsList.concat(outputsList);

      return acc;
    }, {});
  }

  return {
    inputs: filteredInputs,
    outputs: filteredOutputs,
    inputsList: filteredInputsList,
    outputsList: filteredOutputsList
  };
};

const IOitemsToDropdownItems = (items) => {
  return items.map((item) => {
    return {
      key: item.key,
      value: item.key,
      label: item.key,
      group: item.inputType,
      data: item,
      name: item.key
    };
  });
};

const I18N = {
  "input:transform:promptBlocks": "Imported values for AI blocks",
  "input:inputConnections:fetcher":
    'Imported values for requests(fetcher) in "Receive"',
  "input:emitters:fetcher": 'Imported values for requests(fetcher) in "Emit"',
  "input:emitters:logic": 'Imported values for logic in "Emit"',
  "output:transform:promptBlocks": "Computed values by AI blocks",
  "output:inputConnections:fetcher":
    'Fetched values by requests(fetcher) in "Receive"',
  "output:emitters:fetcher": 'Fetched values by requests(fetcher) in "Emit"',
  "output:emitters:logic": 'Computed values by logic in "Emit"',
  "input:global:parameters": "Global parameters",
  "input:inputBlocks:userInput": "User inputs"
};

const tr = (key) => {
  return I18N[key] || key;
};

const SystemBuilderUtils = {
  getIOitems_emitter,
  getIOitems,
  getInputs,
  getOutputs,
  IOitemsToDropdownItems,
  tr
};

export default SystemBuilderUtils;
