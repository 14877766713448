import React from "react";
import CreaxysDeployments from "./ CreaxysDeployments";

const DeploymentContent = ({ resourceType }) => {
  if (!resourceType) {
    return <div className="deployment-intro">Contenu d'introduction...</div>;
  }

  return (
    <div className="deployment-details">
      {/* Afficher les détails en fonction de resourceType */}
      {resourceType === "managed" && <CreaxysDeployments />}
      {resourceType === "custom" && (
        <div>Contenu pour Ressources Personnalisées</div>
      )}
    </div>
  );
};

export default DeploymentContent;

// Mais sinon, dans le cadre d'appel API non persistant(rien d'enregistré).
// Pour le premier type de deploiement le flow c'est:
// - donc creation d'une entité Deployment de type "API Non persistent)
// - lors de lacreation une copie de deployment model est crée et associé a deploiement et est considéré comme une nouvelle version de deploiement.
// - un serveur express recoit des requete avec en parametre l'id du deploiement, avec une clé api et un token dans le header
// - le token est utilisé pour chercher dans la base de données contenant les jetons
// - si le jeton est trouvé check si le secret key du jeton correspond au secret key envoyé en header
// - si c'est trouvé alors je vais recuperer le deploiement en base de donees grace à l'id
// - l'url contient egalement le nom de l'endpoint, le deploiement recuperé contient les endpoints construit du systeme
// - si le endpoint est existant dans le deploiement
// - alors executé les operations
// - mettre a jour des metrics tel que le nombre de requette appellé, le nombre de tokens(generation IA) utilisé, temps d'usage, etc....
