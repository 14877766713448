/**
 * @file Authentification.js
 * @description This file contains the Authentification component.
 * The Authentification component is used to display the login page or the signup page.
 * It renders a react-bootstrap Tab component.
 * For each tab, it renders a react-bootstrap Form component.
 * One of the forms is used to login and the other one is used to signup.
 */

import React, { useRef, useState } from "react";
import {
  Form,
  Button,
  Card,
  Alert,
  Tab,
  Tabs,
  Image,
  Container,
} from "react-bootstrap";
import useAuth from "../../hooks/useAuth";

const Login = () => {
  const emailLoginRef = useRef();
  const passwordLoginRef = useRef();
  const { login } = useAuth();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleLogin(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(emailLoginRef.current.value, passwordLoginRef.current.value);
    } catch {
      setError("Failed to log in");
    }

    setLoading(false);
  }

  return (
    <Card style={{ minWidth: "400px" }}>
      <Card.Body>
        <h2 className="text-center mb-4">Log In</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleLogin}>
          <Form.Group id="email-login">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" ref={emailLoginRef} required />
          </Form.Group>
          <Form.Group id="password-login">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" ref={passwordLoginRef} required />
          </Form.Group>
          <Button disabled={loading} className="w-100" type="submit">
            Log In
          </Button>
        </Form>
        <div className="w-100 text-center mt-3">
          <a href="/forgot-password">Forgot Password?</a>
        </div>
      </Card.Body>
    </Card>
  );
};

const Signup = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { signup } = useAuth();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    try {
      setError("");
      setLoading(true);
      await signup(emailRef.current.value, passwordRef.current.value);
    } catch {
      setError("Failed to create an account");
    }

    setLoading(false);
  }

  return (
    <Card style={{ minWidth: "400px" }}>
      <Card.Body>
        <h2 className="text-center mb-4">Sign Up</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-4" id="email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" ref={emailRef} required />
          </Form.Group>
          <Form.Group className="mb-4" id="password">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" ref={passwordRef} required />
          </Form.Group>
          <Form.Group className="mb-4" id="password-confirm">
            <Form.Label>Password Confirmation</Form.Label>
            <Form.Control type="password" ref={passwordConfirmRef} required />
          </Form.Group>
          <Button disabled={loading} className="w-100" type="submit">
            Sign Up
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

// Page wich take all the screen with a background image(/illustration/background-login.png)
// The logo will be on the top of the page in the middle

const background_images = [
  "/illustration/background-login.jpg",
  "/illustration/background-login-1.jpg",
  "/illustration/background-login-2.jpg",
];

const random_background_image =
  background_images[Math.floor(Math.random() * background_images.length)];

export default function Authentification() {
  return (
    <div
      className="login-page"
      style={{
        minHeight: "100vh",
        width: "100vw",
        backgroundImage: `url(${random_background_image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundOrigin: "border-box",
        backgroundClip: "border-box",
      }}
    >
      <div className="background-overlay-effect pt-5">
        <Container
          fluid
          className="w-100 h-100 d-flex flex-column align-items-center py-5"
          // className="w-100 h-100 d-flex flex-column justify-content-center align-items-center py-5"
        >
          <div className="w-100 mb-5" style={{ maxWidth: "400px" }}>
            <Image src="/logos/Logo-Creaxys-1.png" fluid />
          </div>

          <Tabs
            defaultActiveKey="login"
            id="uncontrolled-tab-example"
            className="mt-5"
          >
            <Tab eventKey="login" title="Log In" className="w-100">
              <Login />
            </Tab>
            <Tab eventKey="signup" title="Sign Up">
              <Signup />
            </Tab>
          </Tabs>
        </Container>
      </div>
    </div>
  );
}
