/**
 * @fileoverview This file contains the ApiBuilderPage component.
 * This component is responsible for rendering the ApiBuilder component.
 * It also handles the navigation between different ApiProjects.
/**
 * For fast development, we are writing all the components in one file.
 */

import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Form,
  Button,
  Col,
  Row,
  Card,
  Table,
  Dropdown,
  Offcanvas,
  ListGroup,
  OverlayTrigger,
  Tooltip,
  Modal,
  Tab,
  Tabs,
  ProgressBar,
  Alert
} from "react-bootstrap";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";

// components
import SdkDocumentation from "./ApiBuilder/SdkDocumentation";
import { DataSetsDatabaseModal } from "./ApiBuilder/DataSetsDatabase";
import ConfirmPopover from "../UI/ConfirmPopover";

import { useParams, useNavigate, Link } from "react-router-dom";
import DB from "../../database/DB";
import SystemFlowCreator from "./ApiBuilder/SystemFlowCreator";
import { useNotif } from "../zones/NotifZone";
import AudioInput from "../UI/AudioInput";
import { useAiChain } from "../../libraries/ApiProjectSdk";
import { aiChainBlocksParser } from "../../utils/chainResultParsers";
import AiEngineErrors from "../modals/AiEngineErrors";
import systemCreatorApi from "../../apis/systemCreator";
import {
  ApiBuilderContext,
  ApiBuilderProvider
} from "../../contexts/ApiBuilderContext";
import { SystemBuilderInterfaceProvider } from "../../contexts/SystemBuilderInterfaceContext";
import { ChainContext, ChainProvider } from "../../contexts/ChainContext";
import LeftBarDesigner, {
  BlockLeftBarReceiver
} from "./ApiBuilder/LeftBarDesigner";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import LaunchLite from "./ApiBuilder/LaunchLite/LaunchLite";
import DeploymentsPage from "./ApiBuilder/Deployments/DeploymentsPage";
import "./ApiBuilder.css";

// generated chain

const CommonJoyride = ({ steps, callback = () => {} }) => {
  return (
    <Joyride
      callback={callback}
      steps={steps}
      continuous={true}
      showProgress={true}
      showSkipButton={false}
      disableCloseOnEsc={true}
      disableOverlayClose={true}
      hideCloseButton={true}
      spotlightClicks={true}
      styles={{
        options: {
          zIndex: 10000
        }
      }}
    />
  );
};

// PromptBlock
const getDefaultNewPromptBlock = (props = {}) => {
  return {
    chainKey: null,
    aliasName: null,
    builtPromptId: null,
    blockType: "completion", // can be "completion", "logic"
    logicType: null, // can be "loopResult", "concatResult"
    valuesLogicLinkedImport: {},
    valuesLinkedImport: {},
    valuesLinkedExport: {},
    ...props
  };
};

const getDefaultChain = (props = {}) => {
  return {
    promptBlocks: [],
    name: "one",
    apiProjectId: "",
    description: "",
    status: "readyForDeployment",
    apiUrl: "",
    testing: {
      promptsVariables: {},
      promptsTextValues: {},
      resultValues: {}
    },
    ...props
  };
};

// This component will be an animation
// It will show only 4 last words, with each having lower opacity than the previous one
// Also a tooltip will be shown when hovering on the text and will show the full text
// the receiving text is
const ResultStreamPrompter = ({ text = "" }) => {
  const [showFullText, setShowFullText] = useState(false);
  let textToDisplay = text;
  if (typeof text !== "string") {
    try {
      textToDisplay = JSON.stringify(text);
    } catch (error) {
      console.log("Error in ResultStreamPrompter", error);
      return null;
    }
  }

  const textToDisplayModel = (
    <Modal
      show={true}
      centered
      onHide={() => setShowFullText(false)}
      fullscreen
    >
      <Modal.Header closeButton>
        <Modal.Title>Result Stream</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Disabled textared */}
        <Form.Control as="textarea" rows={10} value={textToDisplay} disabled />
      </Modal.Body>
    </Modal>
  );

  const last_40_chars = textToDisplay.slice(-40).split("");

  return (
    <>
      <div
        className="result-stream-prompter text-center"
        role={"button"}
        onClick={() => setShowFullText(true)}
      >
        {last_40_chars.map((char, index) => {
          let opacity = 1;
          if (index === 0) {
            opacity = 0.05;
          } else if (index > 0 && index <= 3) {
            opacity = 0.1;
          } else if (index > 3 && index <= 6) {
            opacity = 0.2;
          } else if (index > 6 && index <= 9) {
            opacity = 0.25;
          } else if (index > 9 && index <= 12) {
            opacity = 0.3;
          } else if (index > 12 && index <= 15) {
            opacity = 0.35;
          } else if (index > 15 && index <= 18) {
            opacity = 0.39;
          } else if (index > 18 && index <= 21) {
            opacity = 0.6;
          } else if (index > 21 && index <= 28) {
            opacity = 0.7;
          } else if (index > 28 && index <= 35) {
            opacity = 0.8;
          } else if (index > 37 && index <= 40) {
            opacity = 1;
          }

          return (
            <span
              key={index}
              className="result-stream-prompter-char"
              style={{ opacity }}
            >
              {char}
            </span>
          );
        })}
      </div>
      {showFullText && textToDisplayModel}
    </>
  );
};

const TooltipEnabling = ({
  children,
  enabled,
  overlay,
  text,
  position = "bottom"
}) => {
  if (!enabled) {
    return children;
  }

  const tooltip = overlay || <Tooltip>{text}</Tooltip>;

  return (
    <OverlayTrigger placement={position} overlay={tooltip}>
      <div>{children}</div>
    </OverlayTrigger>
  );
};

/**
 * @type DropBetweenBlocksZone - The DropBetweenBlocksZone component to between blocks zone to accept dropped blocks.
 */
const DropBetweenBlocksZone = ({ children, ...props }) => {
  const { sourceChainKey, direction, setPromptBlocks } = props;
  // const { promptBlock } = props;

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const chainKeyToMove = e.dataTransfer.getData("text/plain");
  };

  return (
    <div
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      style={{
        border: "1px solid #ccc",
        height: "20px",
        width: "100%",
        margin: "10px 0"
      }}
    >
      {children}
    </div>
  );
};

/**
 * @typedef DropZone - The DropZone component to make a block accept dropped blocks.
 */
const DropZone = ({ children, ...props }) => {
  // const { chain, setChain, promptBlocks, setPromptBlocks } = props;
  const { promptBlock, onBlocksChange, promptBlocks } = props;
  if (!promptBlock) {
    return null;
  }
  const { chainKey } = promptBlock;

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const chainKeyToMove = e.dataTransfer.getData("text/plain");

    const isToMoveSame = chainKeyToMove === chainKey;

    if (isToMoveSame) {
      return;
    }

    // else just switch keys
    const promptBlockSrc = promptBlocks.find(
      (block) => block.chainKey === chainKeyToMove
    );

    const newPromptBlock = {
      ...promptBlock,
      chainKey: chainKeyToMove
    };
    const newPromptBlockSrc = {
      ...promptBlockSrc,
      chainKey: chainKey
    };

    const newPromptBlocks = promptBlocks.reduce((acc, block) => {
      if (block.chainKey === chainKeyToMove) {
        return [...acc, newPromptBlockSrc];
      } else if (block.chainKey === chainKey) {
        return [...acc, newPromptBlock];
      }
      return [...acc, block];
    }, []);

    onBlocksChange(newPromptBlocks);
  };

  return (
    <div onDragOver={handleDragOver} onDrop={handleDrop}>
      {children}
    </div>
  );
};

/**
 * @typedef DragBlock - The DragBlock component to make a block draggable.
 */
const DragBlock = ({ children, ...props }) => {
  const [dragging, setDragging] = useState(false);

  const { chain, setChain, promptBlock, setPromptBlocks } = props;
  // const { chainKey } = props.promptBlock;
  if (!promptBlock) return null;
  const { chainKey } = promptBlock;

  const handleDragStart = (e) => {
    e.dataTransfer.setData("text/plain", chainKey);
    setDragging(true);
  };

  const handleDragEnd = (e) => {
    setDragging(false);
  };

  return (
    <div
      draggable
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      style={{
        opacity: dragging ? 0.5 : 1
      }}
    >
      {children}
    </div>
  );
};

const renderTreeListElementsWithStyle = (rawElements) => {
  const elements = [];

  rawElements.forEach((element) => {
    if (element[0]) {
      elements.push(
        <div key={element[0].key} className="tree-list-element">
          <div className="parent-block">
            <div className="content">{element[0]}</div>
          </div>
          {element[1] && element[1].length > 0 && (
            <>
              <div className="link-line"></div>
              <div className="children-parent">
                {renderTreeListElementsWithStyle(element[1])}
              </div>
            </>
          )}
        </div>
      );
    }
  });

  return elements;
};

// OffCanvas which shows how to use the api with current chain
// A part with all the available paramaters using chain.promptBlocks.valuesLinkedImport[i]
// A part with all the available data properties using chain.promptBlocks.valuesLinkedExport[i].result
const OffCanvasApiUsage = ({ chain }) => {
  const [showZones, setShowZones] = useState({
    parameters: true,
    dataProperties: true,
    sdkUsage: false
  });
  const { promptBlocks } = chain;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const listOfAvailableParameters = promptBlocks.reduce((acc, promptBlock) => {
    const { valuesLinkedImport } = promptBlock;
    if (valuesLinkedImport) {
      Object.keys(valuesLinkedImport).forEach((key) => {
        const value = valuesLinkedImport[key].value;
        const type = valuesLinkedImport[key].type;
        if (type === "input" && value && !acc.includes(value)) {
          acc.push(value);
        } else if (type === "input" && !acc.includes(key)) {
          acc.push(key);
        }
      });
    }
    return acc;
  }, []);

  const listOfAvailableDataPropertiesElements = promptBlocks.reduce(
    (acc, promptBlock) => {
      const { valuesLinkedExport, chainKey } = promptBlock;
      if (
        valuesLinkedExport &&
        valuesLinkedExport.result &&
        !acc.includes(valuesLinkedExport.result)
      ) {
        acc.push(
          <ListGroup.Item key={chainKey}>
            {valuesLinkedExport.result} {chainKey}
          </ListGroup.Item>
        );
      }
      return acc;
    },
    []
  );

  const toggleShowZone = (name, value) => {
    setShowZones({ ...showZones, [name]: value });
  };

  return (
    <>
      <Button variant="link" onClick={handleShow}>
        API Usage
      </Button>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>API Usage/Configuration</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Button
            variant="link"
            role="button"
            onClick={() => toggleShowZone("parameters", !showZones.parameters)}
          >
            Available parameters{" "}
            <i
              className={`fa-solid fa-chevron-${
                showZones.parameters ? "up" : "down"
              }`}
            ></i>
          </Button>
          {showZones.parameters && (
            <ListGroup variant="flush">
              {listOfAvailableParameters.map((parameter) => (
                <ListGroup.Item key={parameter}>{parameter}</ListGroup.Item>
              ))}
            </ListGroup>
          )}
          <hr />
          <Button
            variant="link"
            role="button"
            onClick={() =>
              toggleShowZone("dataProperties", !showZones.dataProperties)
            }
          >
            Available data properties{" "}
            <i
              className={`fa-solid fa-chevron-${
                showZones.dataProperties ? "up" : "down"
              }`}
            ></i>
          </Button>
          {showZones.dataProperties && (
            <ListGroup variant="flush">
              {listOfAvailableDataPropertiesElements}
            </ListGroup>
          )}

          <hr />

          {/* integration current chain with SDK */}
          <Button
            variant="link"
            role="button"
            onClick={() =>
              toggleShowZone("integration", !showZones.integration)
            }
          >
            Integration with SDK{" "}
            <i
              className={`fa-solid fa-chevron-${
                showZones.integration ? "up" : "down"
              }`}
            ></i>
          </Button>

          {showZones.integration && (
            <Card>
              {/* projectId chainId mapping */}
              <Card.Header>
                <h5>ProjectId and ChainId mapping</h5>
                <p>
                  The SDK uses the projectId and chainId to identify the chain.
                  The projectId and chainId are available in the chain object.
                  The projectId and chainId can be used to identify the chain in
                  the SDK.
                </p>
              </Card.Header>
              <Card.Body>
                <p>
                  <b>projectId::chainId</b>
                  <br />
                  <code>
                    {chain.apiProjectId}::{chain.id}{" "}
                  </code>
                  <br />
                  <hr />
                  <b>In code</b> <br />
                  <code style={{ whiteSpace: "pre-wrap" }}>
                    {`aiChainConfig.config({
  "aliasName" : "${chain.apiProjectId}::${chain.id}"
})`}
                  </code>
                </p>
              </Card.Body>
            </Card>
          )}

          <Button
            variant="link"
            role="button"
            onClick={() => toggleShowZone("sdkUsage", !showZones.sdkUsage)}
          >
            ApiProject SDK Documentation{" "}
            <i
              className={`fa-solid fa-chevron-${
                showZones.sdkUsage ? "up" : "down"
              }`}
            ></i>
          </Button>

          {showZones.sdkUsage && (
            <div>
              <SdkDocumentation />
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

const getElementPosition = (element) => {
  let xPos = 0;
  let yPos = 0;

  while (element) {
    xPos += element.offsetLeft - element.scrollLeft + element.clientLeft;
    yPos += element.offsetTop - element.scrollTop + element.clientTop;
    element = element.offsetParent;
  }
  return {
    x: xPos,
    y: yPos
  };
};

const ToolTipHelper = ({ children, placement = "top", text }) => {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={<Tooltip id="button-tooltip-2">{text}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  );
};

const ChainConfigurationOffCanvas = ({ children, chain, onChange, onSave }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleOnChainChange = (e) => {
    onChange({ ...chain, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Chain Configuration</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* Chain Name */}
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Chain Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter chain name"
              value={chain.name || ""}
              onChange={(e) => {
                handleOnChainChange({
                  target: { name: "name", value: e.target.value }
                });
              }}
            />
          </Form.Group>

          {/* Description */}
          <Form.Group
            className="mb-3 chain-configuration-description"
            controlId="description"
          >
            <Form.Label>Description</Form.Label>
            <AudioInput
              value={chain.description || ""}
              onChange={(e) => {
                handleOnChainChange({
                  target: { name: "description", value: e.target.value }
                });
              }}
              as="textarea"
            />
          </Form.Group>

          {/* Allow to set visibility to public, default is private */}
          <Form.Group className="mb-3" controlId="visibility">
            <Form.Check
              type="checkbox"
              label="Make chain public"
              checked={chain.visibility === "public"}
              onChange={(e) => {
                handleOnChainChange({
                  target: {
                    name: "visibility",
                    value: e.target.checked ? "public" : "private"
                  }
                });
              }}
            />
          </Form.Group>

          {/* Save button */}
          <Button
            className="offcanvas-save-button"
            variant="primary"
            onClick={() => {
              onSave();
              handleClose();
            }}
          >
            Save
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
      {children(handleShow)}
    </>
  );
};

const ModalAiProgressBar = ({ progress, status, generatingText = "" }) => {
  return (
    <Modal
      show={true}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h5 className="text-center">
          The AI Engine are building your chain...
        </h5>
        <p className="text-center">
          <b>{status}</b>
        </p>
        <ResultStreamPrompter text={generatingText} />
        <ProgressBar
          animated
          now={progress}
          label={`${progress}%`}
          variant="success"
        />
      </Modal.Body>
    </Modal>
  );
};

const ChainEditor = ({
  actionsNavbarLeft = [],
  cloneCurrentChain,
  onChainDelete
}) => {
  const { apiProject } = useContext(ApiBuilderContext);
  const { chain: currentChain } = useContext(ChainContext);
  const chainBlocksContainerRef = React.useRef(null);
  const chainNavRef = React.useRef(null);
  const { notify } = useNotif();
  const [generatingBlocksStr, setGeneratingBlocksStr] = useState(false);
  const [showModalValues, setShowModalValues] = useState(false);
  const [showDataSetsDatabase, setShowDataSetsDatabase] = useState(false);
  const [showAiChainModalLoading, setShowAiChainModalLoading] = useState(false);
  const [chain, setChain] = useState(currentChain);
  const [builtPrompts, setBuiltPrompts] = useState([]);
  const [promptBlocksViewMode, setPromptBlocksViewMode] = useState("extended");

  const { description, promptBlocksAiGenerated } = chain || {};
  const dataChainParameters = {
    description
  };
  const [results = {}, runResults, runResultsAsync, resultsRunningStatus] =
    useAiChain("aiChain", dataChainParameters, {}, chain.id);

  const apiChainPromptBlockJson = results.apiChainPromptBlockJson || [];
  const stringifiedApiChainPromptBlockJson = JSON.stringify(
    apiChainPromptBlockJson
  );

  // AI Chain

  useEffect(() => {
    if (
      results.apiChainPromptBlocks &&
      results.apiChainPromptBlocks.length > 0 &&
      !generatingBlocksStr
    ) {
      const parsedText = aiChainBlocksParser(results.apiChainPromptBlocks);
      setAiGeneratedPromptBlocks(parsedText);
      setShowAiChainModalLoading(false);
    }
  }, [results.apiChainPromptBlocks, generatingBlocksStr]);

  // UI updates

  // Make chainNavRef fixed when scrolling beside it
  useEffect(() => {
    const chainNavRefElement = chainNavRef.current;
    const { y } = getElementPosition(chainNavRefElement);

    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY > y) {
        chainNavRefElement.classList.add("fixed");
      } else {
        chainNavRefElement.classList.remove("fixed");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Chain DB updates

  useEffect(() => {
    if (!currentChain) {
      setChain(null);
      return;
    }
    setChain(currentChain);
  }, [currentChain]);

  // console.log("chain", chain);

  useEffect(() => {
    const unsubscribe = DB.apiProjects.sub.builtPrompts.list(
      apiProject.id,
      (builtPrompts) => {
        setBuiltPrompts(builtPrompts);
      }
    );
    return unsubscribe;
  }, []);

  // AI Chain

  const setAiGeneratedPromptBlocks = (promptBlocks) => {
    const newPromptBlocks = promptBlocks
      .filter((it) => !!it)
      .map((pb) => {
        const promptBlock = pb;
        const { builtPrompt, ...rest } = promptBlock;
        const {
          promptName,
          model = "gpt-4",
          valueKeys,
          prompt = ""
        } = builtPrompt || {};

        const { blockType } = rest;

        if (blockType === "completion") {
          return {
            ...rest,
            aiGeneratedBuiltPrompt: {
              promptName,
              model,
              valueKeys,
              prompt
            }
          };
        } else if (blockType === "logic") {
          return { ...rest };
        }
      });

    setChain({
      ...chain,
      // promptBlocks: newPromptBlocks,
      promptBlocksAiGenerated: newPromptBlocks
    });
  };

  const createBuiltPrompt = async (builtPrompt) => {
    const { promptName, prompt, model, valueKeys } = builtPrompt;

    const idCreated = await DB.apiProjects.sub.builtPrompts.create(
      apiProject.id,
      {
        promptName,
        prompt,
        model,
        valueKeys
      }
    );
    return idCreated;
  };

  const saveCurrentGeneratedAiPromptBlocks = async () => {
    const { id, apiProjectId } = chain;
    const { promptBlocksAiGenerated } = chain;
    const newPromptBlocks = [];

    for (let i = 0; i < promptBlocksAiGenerated.length; i++) {
      const promptBlock = promptBlocksAiGenerated[i];
      const { blockType } = promptBlock;

      if (blockType === "completion") {
        const { aiGeneratedBuiltPrompt, ...restPromptBlock } = promptBlock;
        const { promptName, prompt, model, valueKeys } = aiGeneratedBuiltPrompt;

        const idCreated = await createBuiltPrompt({
          promptName,
          prompt,
          model,
          valueKeys
        });

        newPromptBlocks.push({
          ...restPromptBlock,
          builtPromptId: idCreated
        });
      } else if (blockType === "logic") {
        newPromptBlocks.push(promptBlock);
      }
    }

    await DB.apiProjects.sub.chains.update(apiProjectId, id, {
      promptBlocks: newPromptBlocks,
      promptBlocksAiGenerated: null
    });
    notify({
      variant: "success",
      message: "Generated prompt blocks saved!"
    });
  };

  const startGenerateAiPromptBlocks = async () => {
    setGeneratingBlocksStr(true);
    setShowAiChainModalLoading(true);
    // await runResults("apiChainPromptBlocks", { stream: false });
    await runResults("apiChainPromptBlocks");
    setGeneratingBlocksStr(false);
    // await runResults("apiChainPromptBlocksList", { stream: false });
  };

  const getLoadingValueInPercentage = () => {
    const { apiChainPromptBlocks } = results;

    let loadedFirstPart = 0;

    if (apiChainPromptBlocks) {
      loadedFirstPart += 1;
    } else return 0;

    let finalPercentage = loadedFirstPart / 1;

    return finalPercentage * 100;
  };

  const saveChain = async (chain) => {
    try {
      await DB.apiProjects.sub.chains.update(chain.apiProjectId, chain.id, {
        ...chain
      });
      notify({
        variant: "success",
        message: `Chain ${chain.name} saved.`
      });
    } catch (error) {
      console.error(error);
      notify({
        variant: "error",
        message: `Chain ${chain.name} failed to save.`
      });
    }
  };

  const handleSave = async () => {
    const newChain = { ...chain };
    if (
      newChain.visibility === "public" &&
      apiProject.visibility !== "public"
    ) {
      await DB.apiProjects.update(apiProject.id, {
        visibility: "public"
      });
    } else if (
      newChain.visibility === "private" &&
      apiProject.visibility !== "private"
    ) {
      await DB.apiProjects.update(apiProject.id, {
        visibility: "private"
      });
    }
    await saveChain(newChain);
  };

  const savePromptBlock = async (promptBlock) => {
    const newPromptBlocks = [...chain.promptBlocks];
    const index = newPromptBlocks.findIndex(
      (pb) => pb.chainKey === promptBlock.chainKey
    );
    newPromptBlocks[index] = promptBlock;
    const newChain = { ...chain, promptBlocks: newPromptBlocks };
    console.log("newChain in savePromptBlock", newChain);
    await saveChain(newChain);
  };

  const deleteChain = async () => {
    onChainDelete(chain.id);
  };

  const aiChainLoadingStatus = (() => {
    if (!showAiChainModalLoading) return null;
    if (!results.apiChainPromptBlocks) return "Generating the prompt blocks...";
    if (!results.apiChainPromptBlocksList)
      return "Generating the prompt blocks list...";
    if (
      !results.apiChainPromptBlockJson ||
      results.apiChainPromptBlockJson.length === 0
    )
      return `Generating the prompt blocks list 0/${results.apiChainPromptBlocksList.length} done...`;
    else {
      const loadedBlocks = apiChainPromptBlockJson.filter((block) => !!block);
      return `Generating the prompt blocks list ${loadedBlocks.length}/${results.apiChainPromptBlocksList.length} done...`;
    }
  })();

  const aiPercentageLoaded = showAiChainModalLoading
    ? getLoadingValueInPercentage()
    : 0;

  return (
    <Container fluid style={{ position: "relative" }}>
      {/* Modals */}
      {/* ModalAiProgressBar progress */}
      {showAiChainModalLoading && (
        <ModalAiProgressBar
          generatingText={results.apiChainPromptBlocks}
          progress={aiPercentageLoaded > 0 ? aiPercentageLoaded : 10}
          status={aiChainLoadingStatus}
        />
      )}
      {
        <DataSetsDatabaseModal
          onHide={() => setShowDataSetsDatabase(false)}
          projectId={chain.apiProjectId}
          show={showDataSetsDatabase}
          chainId={chain.id}
        />
      }
      {!showDataSetsDatabase && <AiEngineErrors />}

      <Row className="chain-designer-navbar mt-navbar" ref={chainNavRef}>
        <Col className="d-flex justify-content-start align-items-center">
          {actionsNavbarLeft.length > 0 && (
            <>
              <div className="d-flex align-items-center">
                {actionsNavbarLeft.map((action, i) => action)}
              </div>
              <div className="menu-horizontal-separator"></div>
            </>
          )}
          <div className="d-flex align-items-center">
            <Dropdown className="dropdown-chain-menu">
              <Dropdown.Toggle variant="link" id="dropdown-project-action">
                Chain
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleSave}>Save</Dropdown.Item>
                <Dropdown.Item onClick={() => cloneCurrentChain(chain.id)}>
                  Clone Chain
                </Dropdown.Item>
                <ConfirmPopover
                  title={"Are you sure?"}
                  message="Removing the chain will remove all the data associated with it, and it cannot be undone."
                  placement="bottom"
                  onConfirm={() => deleteChain(chain.id)}
                  stopPropagation={true}
                >
                  <Dropdown.Item disabled className="text-gray-700">
                    Delete Chain
                  </Dropdown.Item>
                </ConfirmPopover>
              </Dropdown.Menu>
            </Dropdown>

            <ChainConfigurationOffCanvas
              chain={chain}
              onChange={(newChain) => {
                setChain(newChain);
              }}
              onSave={handleSave}
            >
              {(onShow) => (
                <Button
                  className="button-chain-configuration"
                  onClick={onShow}
                  variant="link"
                  disabled={chain.id === null}
                >
                  Configure
                </Button>
              )}
            </ChainConfigurationOffCanvas>
            <div className="menu-horizontal-separator"></div>

            {/* AI actions dropdown */}
            <Dropdown className="chain-ai-actions-dropdown">
              <TooltipEnabling
                text={"Require a description"}
                enabled={chain.description?.length === 0}
              >
                <Dropdown.Toggle
                  variant="link"
                  id="dropdown-ai-action"
                  disabled={chain.description?.length === 0}
                >
                  AI
                </Dropdown.Toggle>
              </TooltipEnabling>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => startGenerateAiPromptBlocks()}
                  disabled={chain.description?.length === 0}
                >
                  Generate
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => saveCurrentGeneratedAiPromptBlocks()}
                  disabled={!chain.promptBlocksAiGenerated}
                >
                  Save current generated blocks
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Button
              className="data-sets-button"
              onClick={() => {
                setShowDataSetsDatabase(true);
              }}
              variant="link"
            >
              Playgrounds
            </Button>

            {false && <div className="menu-horizontal-separator"></div>}

            {false && chain.id && <OffCanvasApiUsage chain={chain} />}
            {/* Get chain in stringified JSON forma */}
            {false && (
              <Button variant="link" onClick={() => setShowModalValues(true)}>
                Values/Keys
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <div className="block-empty"></div>

      {/* <hr /> */}
      <Row>
        <Col className="p-0">
          <div
            className="chain-blocks-react-flow p-0"
            ref={chainBlocksContainerRef}
          >
            <SystemFlowCreator />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const ShareProjectModal = ({ apiProject, onClose }) => {
  const [userToAdd, setUserToAdd] = useState({
    email: "",
    read: false,
    write: false,
    useOnly: false
  });
  const [sharedUsers, setSharedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (apiProject) {
      (async () => {
        const {
          sharedUidsRead = [],
          sharedUidsWrite = [],
          sharedUidsUseOnly = []
        } = apiProject;
        const sharedUids = [
          ...sharedUidsRead,
          ...sharedUidsWrite,
          ...sharedUidsUseOnly
        ];
        const uniqueIds = sharedUids.filter((v, i, a) => a.indexOf(v) === i);
        const { emails = [] } = await systemCreatorApi.getEmailsFromIds(
          uniqueIds
        );
        const sharedUsers = emails.map((user) => {
          const { id, email } = user;
          const readAccess = sharedUidsRead.includes(id);
          const writeAccess = sharedUidsWrite.includes(id);
          const useOnlyAccess = sharedUidsUseOnly.includes(id);
          return { id, email, readAccess, writeAccess, useOnlyAccess };
        });
        setSharedUsers(sharedUsers);
      })();
    }
  }, [apiProject]);

  const handleShare = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await systemCreatorApi.checkIfEmailExists(
        userToAdd.email
      );

      if (response) {
        const { uid, exists } = response;
        if (exists) {
          const {
            sharedUidsRead = [],
            sharedUidsWrite = [],
            sharedUidsUseOnly = []
          } = apiProject;
          const sharedUids = [
            ...sharedUidsRead,
            ...sharedUidsWrite,
            ...sharedUidsUseOnly
          ];
          const updatedSharedUidsRead = userToAdd.read
            ? [...sharedUidsRead, uid]
            : sharedUidsRead;
          const updatedSharedUidsWrite = userToAdd.write
            ? [...sharedUidsWrite, uid]
            : sharedUidsWrite;
          const updatedSharedUidsUseOnly = userToAdd.useOnly
            ? [...sharedUidsUseOnly, uid]
            : sharedUidsUseOnly;
          const res = await DB.apiProjects.update(apiProject.id, {
            sharedUidsRead: updatedSharedUidsRead,
            sharedUidsWrite: updatedSharedUidsWrite,
            sharedUidsUseOnly: updatedSharedUidsUseOnly
          });
          setUserToAdd({ ...userToAdd, email: "" });
          setError("");
        } else {
          setError("User not found");
        }
        setLoading(false);
      }
    } catch (error) {
      setMessage("");
      setError(error.message);
    }
  };

  const handleRemove = async (uid) => {
    const {
      sharedUidsRead = [],
      sharedUidsWrite = [],
      sharedUidsUseOnly = []
    } = apiProject;
    const updatedSharedUidsRead = sharedUidsRead.filter((id) => id !== uid);
    const updatedSharedUidsWrite = sharedUidsWrite.filter((id) => id !== uid);
    const updatedSharedUidsUseOnly = sharedUidsUseOnly.filter(
      (id) => id !== uid
    );
    const res = await DB.apiProjects.update(apiProject.id, {
      sharedUidsRead: updatedSharedUidsRead,
      sharedUidsWrite: updatedSharedUidsWrite,
      sharedUidsUseOnly: updatedSharedUidsUseOnly
    });
    // setMessage("User removed");
    setError("");
  };

  const handleModifyAccess = async (uid, accessType, access) => {
    const {
      sharedUidsRead = [],
      sharedUidsWrite = [],
      sharedUidsUseOnly = []
    } = apiProject;
    const updatedSharedUidsRead =
      accessType === "read"
        ? access
          ? [...sharedUidsRead, uid]
          : sharedUidsRead.filter((id) => id !== uid)
        : sharedUidsRead;
    const updatedSharedUidsWrite =
      accessType === "write"
        ? access
          ? [...sharedUidsWrite, uid]
          : sharedUidsWrite.filter((id) => id !== uid)
        : sharedUidsWrite;
    const updatedSharedUidsUseOnly =
      accessType === "useOnly"
        ? access
          ? [...sharedUidsUseOnly, uid]
          : sharedUidsUseOnly.filter((id) => id !== uid)
        : sharedUidsUseOnly;
    const res = await DB.apiProjects.update(apiProject.id, {
      sharedUidsRead: updatedSharedUidsRead,
      sharedUidsWrite: updatedSharedUidsWrite,
      sharedUidsUseOnly: updatedSharedUidsUseOnly
    });
    // setMessage("User removed");
    setError("");
  };

  return (
    <Modal show={true} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Share project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleShare}>
          <Form.Group controlId="shareProjectForm.ControlInput1">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Add email"
              value={userToAdd.email}
              onChange={(e) =>
                setUserToAdd({ ...userToAdd, email: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group controlId="shareProjectForm.ControlCheckbox1">
            <Form.Check
              type="checkbox"
              label="Read access"
              checked={userToAdd.read}
              onChange={(e) =>
                setUserToAdd({ ...userToAdd, read: e.target.checked })
              }
            />
          </Form.Group>
          <Form.Group controlId="shareProjectForm.ControlCheckbox2">
            <Form.Check
              type="checkbox"
              label="Write access"
              checked={userToAdd.write}
              onChange={(e) =>
                setUserToAdd({ ...userToAdd, write: e.target.checked })
              }
            />
          </Form.Group>
          <Form.Group controlId="shareProjectForm.ControlCheckbox3">
            <Form.Check
              type="checkbox"
              label="Use only access"
              checked={userToAdd.useOnly}
              onChange={(e) =>
                setUserToAdd({ ...userToAdd, useOnly: e.target.checked })
              }
            />
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            disabled={loading || userToAdd.email.length === 0}
          >
            {loading ? "Loading..." : "Share"}
          </Button>
        </Form>
        <hr />
        <h5>Shared users:</h5>
        {sharedUsers.length > 0 ? (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Email</th>
                <th>Read access</th>
                <th>Write access</th>
                <th>Use only access</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {sharedUsers.map((user) => (
                <tr key={user.id}>
                  <td>{user.email}</td>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={user.readAccess}
                      onChange={(e) =>
                        handleModifyAccess(user.id, "read", e.target.checked)
                      }
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={user.writeAccess}
                      onChange={(e) =>
                        handleModifyAccess(user.id, "write", e.target.checked)
                      }
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={user.useOnlyAccess}
                      onChange={(e) =>
                        handleModifyAccess(user.id, "useOnly", e.target.checked)
                      }
                    />
                  </td>
                  <td>
                    <ConfirmPopover
                      onConfirm={() => handleRemove(user.id)}
                      title="Revoking all access"
                      placement="bottom"
                      message="Are you sure you want to revoke all access for this user?"
                    >
                      <Button
                        variant="link"
                        className="text-danger"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {/* cross icon */}
                        Revoking <i className="bi bi-x-circle"></i>
                      </Button>
                    </ConfirmPopover>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>No shared users yet.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        {error && <Alert variant="danger">{error}</Alert>}
        {message && <Alert variant="success">{message}</Alert>}
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const OnBoardingNoChains = ({ onCreateChain }) => {
  return (
    <div className="onboarding-no-chains text-center p-5">
      <h5 className="fs-2 mb-4">Let's create your first chain!</h5>
      <p className="fs-5">
        Think of a chain as a series of connected toy bricks. Each "brick" or
        "block" in Creaxys has its own unique role. When you line them up and
        connect them, they work together to perform a special task.
      </p>
      <p className="fs-5 mb-4">
        By building chains, you're piecing together these blocks to make
        something amazing. Ready to get started?
      </p>
      <Button variant="primary" className="fs-5" onClick={onCreateChain}>
        Start Building!
      </Button>
    </div>
  );
};

// Links for Designer, LaunchLite, Deployments, Visibilities
const SystemDesignerNavbar = () => {
  const { id: apiProjectId } = useContext(ApiBuilderContext);
  const { pageId } = useParams();

  const startLink = `/system-designer/${apiProjectId}`;

  const isPageChain = !!pageId && !systemDesignerPages.includes(pageId);

  return (
    <Row className="system-designer-header">
      <Col>
        <div className="d-flex justify-content-start align-items-center">
          <div className="d-flex align-items-center">
            <Link
              to={`${startLink}/designer`}
              className={isPageChain ? "active" : ""}
            >
              Designer
            </Link>
            <div className="menu-horizontal-separator"></div>
            <Link
              to={`${startLink}/launch-lite`}
              className={pageId === "launch-lite" ? "active" : ""}
            >
              Launch Lite
            </Link>
            <div className="menu-horizontal-separator"></div>
            <Link
              to={`${startLink}/deployments`}
              className={pageId === "deployments" ? "active" : ""}
            >
              Deployments
            </Link>
            {/* <div className="menu-horizontal-separator"></div> */}
            {/* <Link
              to={`${startLink}/team`}
              className={pageId === "team" ? "active" : ""}
            >
              Visibilities
            </Link> */}
          </div>
        </div>
      </Col>
    </Row>
  );
};

const systemDesignerPages = ["designer", "launch-lite", "deployments", "team"];

const BuilderDragAndDropProvider = ({ children }) => {
  return <DndProvider backend={HTML5Backend}>{children}</DndProvider>;
};

const DesignerRedirect = () => {
  const { id: apiProjectId, chains = [] } = useContext(ApiBuilderContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (chains.length > 0) {
      navigate(`/system-designer/${apiProjectId}/${chains[0].id}`);
    } else {
      navigate(`/system-designer/${apiProjectId}`);
    }
  }, [chains]);

  return <></>;
};

const ApiBuilder = () => {
  const { chains, systemActions, apiProjectId } = useContext(ApiBuilderContext);
  const { pageId } = useParams();
  const navigate = useNavigate();

  const isPageChain = !!pageId && !systemDesignerPages.includes(pageId);

  const createChain = async () => {
    // TODO: Update function to create a new chain
    const newChain = await systemActions.createChain({
      positionOrder: chains.length + 1
    });

    navigate(`/system-designer/${apiProjectId}/${newChain.id}`);
  };

  return (
    <Container fluid className="p-0 system-designer-container">
      <SystemDesignerNavbar />

      {pageId === "launch-lite" && (
        <div className="system-designer-content d-flex flex-grow-1">
          <LaunchLite />
        </div>
      )}

      {pageId === "deployments" && (
        <div className="system-designer-content flex-column d-flex flex-grow-1">
          <DeploymentsPage />
        </div>
      )}

      {pageId === "designer" && (
        <div className="system-designer-content d-flex flex-grow-1">
          <DesignerRedirect />
        </div>
      )}

      {isPageChain && (
        <SystemBuilderInterfaceProvider>
          <BuilderDragAndDropProvider>
            <div className="system-designer-content d-flex flex-grow-1">
              <>
                <ChainProvider chainId={pageId}>
                  <>
                    <LeftBarDesigner />
                    <BlockLeftBarReceiver>
                      {(refDrop) => (
                        <Container fluid className="p-0" ref={refDrop}>
                          <SystemFlowCreator />
                        </Container>
                      )}
                    </BlockLeftBarReceiver>
                  </>
                </ChainProvider>
              </>
            </div>
          </BuilderDragAndDropProvider>
        </SystemBuilderInterfaceProvider>
      )}
      {!pageId && (
        <div className="system-designer-content-onboarding">
          <OnBoardingNoChains onCreateChain={createChain} />
        </div>
      )}
    </Container>
  );
};

const NoSystemSelected = () => {
  const [apiProjects, setApiProjects] = useState([]);
  const { notify } = useNotif();
  const [creationFormApiProject, setCreationFormApiProject] = useState({
    name: "",
    description: ""
  });
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch apiProjects collection from Firestore
    const unsubscribe = DB.apiProjects.list(
      (apiProjects) => {
        setApiProjects(apiProjects);
      },
      null,
      null,
      null,
      {
        sharedUidLists: ["sharedUidsRead"]
      }
    );
    return unsubscribe;
  }, []);

  const handleCreateApiProject = async (e) => {
    e.preventDefault();
    const idCreated = await DB.apiProjects.create(creationFormApiProject);
    setCreationFormApiProject({
      name: "",
      description: ""
    });
    notify({
      title: "Api project created",
      message: "Your api project has been created with id " + idCreated,
      variant: "success"
    });
    navigate(`/system-designer/${idCreated}`);
  };

  const handleChangeCreationFormApiProject = (e) => {
    setCreationFormApiProject({
      ...creationFormApiProject,
      [e.target.name]: e.target.value
    });
  };

  const navigateToApiProject = (e) => {
    e.preventDefault();
    if (!e.target.value) return;
    navigate(`/system-designer/${e.target.value}`);
  };

  return (
    <Container fluid="md">
      <Row>
        <Col xs={12} className="py-3">
          <h1 className="text-center">Logic System Creator</h1>
        </Col>
        <Col>
          <Form.Group controlId="apiProjectId">
            {/* <Form.Label>Logic System Creator</Form.Label> */}
            <Form.Control
              as="select"
              onChange={navigateToApiProject}
              value={""}
            >
              <option value="">Select Logic System</option>
              {apiProjects.map((apiProject) => (
                <option key={apiProject.id} value={apiProject.id}>
                  {apiProject.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      <hr />

      <Card>
        <Card.Body>
          <Row>
            <Col>
              <h5>Create Logic System</h5>
            </Col>
            <Col>
              <Form.Group
                controlId="apiProjectName"
                className="form-api-project-name"
              >
                <Form.Label>Logic System Project Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={creationFormApiProject.name}
                  onChange={handleChangeCreationFormApiProject}
                />
                <Form.Text className="text-muted">
                  This is the name of the logic system project
                </Form.Text>
              </Form.Group>
              <Form.Group
                controlId="apiProjectDescription"
                className="form-api-project-description"
              >
                <Form.Label>Logic System Description</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  value={creationFormApiProject.description}
                  onChange={handleChangeCreationFormApiProject}
                />
                <Form.Text className="text-muted">
                  This is the description of the Logic System project
                </Form.Text>
              </Form.Group>
              <Button
                variant="primary"
                className="form-api-project-submit"
                onClick={handleCreateApiProject}
              >
                Create Logic System
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

const SystemDesigner = ({ apiProjectId }) => {
  return (
    <ApiBuilderProvider apiProjectId={apiProjectId}>
      <ApiBuilder />
    </ApiBuilderProvider>
  );
};

const ApiBuilderPage = () => {
  const { apiProjectId } = useParams();

  useEffect(() => {
    window.onbeforeunload = function () {
      return "Are you sure you want to leave?";
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  if (!apiProjectId) {
    return <NoSystemSelected />;
  }

  return <SystemDesigner apiProjectId={apiProjectId} />;
};

export default ApiBuilderPage;
