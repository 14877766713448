import React, { useContext } from "react";
import { useState, useEffect } from "react";
import {
  Modal,
  Button,
  NavDropdown,
  DropdownButton,
  Dropdown,
  Placeholder,
  Form,
  Row,
  Col,
  Card,
  Accordion,
  ListGroup,
  InputGroup,
  FormControl,
  Tabs,
  Tab,
  Nav,
  Table,
  Alert,
  Container,
  OverlayTrigger,
  Tooltip,
  Popover,
  Badge,
  ProgressBar,
  Spinner,
  Toast,
  ToastContainer,
  Collapse
} from "react-bootstrap";
import { LogicScriptModal } from "./LogicScript";
import DB from "../../../database/DB";
import ReactFlow, {
  Controls,
  Background,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  Handle,
  Position,
  MiniMap,
  ReactFlowProvider,
  useOnViewportChange,
  useViewport,
  useReactFlow,
  Panel
} from "reactflow";
import {
  PanelTopFlowSelector,
  ContextMenuPane,
  PanelLeft,
  GlobalChainParameters
} from "./SystemFlowCreator";
import deepEqual from "deep-equal";
// import jsonpath
import * as jsonpath from "jsonpath";
import ClickableTextInput from "../../UI/ClickableTextInput";
import { InputApiConnectorModal } from "./InputApiConnectors";
import SystemBuilderUtils from "./utils";
import DropdownGroupedSelect from "../../UI/DropdownGroupedSelect";
import SimpleSelector from "../../UI/SimpleSelector";
import InputTypeForm from "../../UI/InputForms";
import { ApiBuilderContext } from "../../../contexts/ApiBuilderContext";
import { ChainContext } from "../../../contexts/ChainContext";
import flowDbUtils from "./flowDbUtils";
import { GlobalContext } from "../../../contexts/GlobalContext";
import BlockType from "./utils/BlockType";

/**
 * @typedef {Object} LogicScript
 * @property {string} id
 * @property {string} name
 * @property {string} title
 * @property {string} description
 * @property {string} role
 * @property {string} script
 * @property {Array}  importedVariables
 * @property {string} importedVariables.key
 * @property {string} importedVariables.type
 * @property {string} importedVariables.valueType
 * @property {string} importedVariables.value
 */

const SHOULD_SAVE_KEYS_LOGIC_SCRIPT = ["importedVariables"];

// rawValue
// variableRef

const LogicConfigScriptOptions = ({
  logicConfig,
  onChange,
  logicScript,
  systemValuesItems
}) => {
  const [seeOptions, setSeeOptions] = useState(false);
  const [logicConfigState, setLogicConfigState] = useState(logicConfig);
  const shouldChange = React.useRef(false);

  useEffect(() => {
    if (shouldChange.current) {
      shouldChange.current = false;
      onChange(logicConfigState);
    }
  }, [logicConfigState]);

  useEffect(() => {
    hyrdateLogicConfigStateImportedVariables();
  }, [logicScript.id, logicScript.importedVariables]);

  const { importedVariables = [] } = logicScript;

  const hyrdateLogicConfigStateImportedVariables = () => {
    const importedVariablesSaved = logicConfigState.importedVariables || [];
    const newImportedVariables = importedVariables.map((importedVariable) => {
      const { key } = importedVariable;
      const currentVariableSaved = importedVariablesSaved.find(
        (importedVariableSaved) => importedVariableSaved.key === key
      );
      const currentVariableSavedValue = currentVariableSaved
        ? currentVariableSaved.value
        : null;
      return {
        key,
        value: currentVariableSavedValue
      };
    });

    const newLogicConfigState = {
      ...logicConfigState,
      importedVariables: newImportedVariables
    };

    setLogicConfigState(newLogicConfigState);
  };

  const handleOnChange = (value, key) => {
    const newLogicConfigState = {
      ...logicConfigState,
      [key]: value
    };

    if (SHOULD_SAVE_KEYS_LOGIC_SCRIPT.includes(key)) {
      shouldChange.current = true;
    }

    setLogicConfigState(newLogicConfigState);
  };

  const handleOnImportedVariableChange = (value, key, index) => {
    const newImportedVariables = [...logicConfigState.importedVariables];

    newImportedVariables[index] = {
      ...newImportedVariables[index],
      [key]: value
    };

    handleOnChange(newImportedVariables, "importedVariables");

    // setLogicConfigState({
    //   ...logicConfigState,
    //   scriptConfig: {
    //     ...logicConfigState.scriptConfig,
    //     importedVariables: newImportedVariables,
    //   },
    // });
  };

  return (
    <>
      <p className="mb-3">
        <Button
          variant="link"
          className="p-0 fw-bold text-white-9"
          onClick={() => setSeeOptions(true)}
        >
          Variables ({importedVariables.length})
        </Button>
      </p>
      {seeOptions && (
        <Modal
          className="flow-parameters-modal"
          show={true}
          onHide={() => setSeeOptions(false)}
          size="lg"
        >
          <Modal.Header closeButton className="p-4 mb-3">
            <Modal.Title className="text-white">Variables</Modal.Title>
          </Modal.Header>
          <div className="modal-form-container">
            <Form.Group className="mb-3">
              {importedVariables.map((importedVariable, index) => {
                const { key, type, valueType } = importedVariable;
                const savedImportedVariable =
                  logicConfigState.importedVariables.find(
                    (importedVariable) => importedVariable.key === key
                  );
                const value = savedImportedVariable
                  ? savedImportedVariable.value
                  : { key, value: "" };

                const inputMode = type === "variableRef" ? "select" : "default";

                let selectProps = {};

                if (inputMode === "select") {
                  selectProps = {
                    items: systemValuesItems,
                    isSelected: (item) => {
                      return item.value === value;
                    },
                    onSelect: (item) => {
                      handleOnImportedVariableChange(
                        item.value,
                        "value",
                        index
                      );
                    },
                    className: "nodrag  nowheel",
                    selectorPlaceholder: `Select ${key}`
                  };
                }

                return (
                  <Row key={index} className="mb-3">
                    <Col xs={12} className="mb-3">
                      <div className="d-flex align-items-center">
                        <div className="me-3">
                          <h5 className="title-label-parameter">{key}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <InputTypeForm
                        resultclassname="node-dropdown nodrag dpd-1 text-white w-80 text-center d-flex align-items-center justify-content-center"
                        editbuttonclassname="btn-side-1"
                        type={valueType}
                        inputMode={inputMode}
                        value={value}
                        notresultlabel={
                          <span className="text-white-5">
                            List of {key} is empty
                          </span>
                        }
                        editbuttonlabel={`Add ${key} item`}
                        onChange={(value) => {
                          handleOnImportedVariableChange(value, "value", index);
                        }}
                        {...selectProps}
                      />
                    </Col>
                    <div className="horizontal-separator mt-3" />
                  </Row>
                );
              })}
            </Form.Group>
          </div>
        </Modal>
      )}
    </>
  );
};

const LOGIC_CONFIG_SHOULD_SAVE_KEYS = ["logicScriptId", "importedVariables"];

const LogicConfig = ({ emitter, onChange }) => {
  const { id: apiProjectId, dslScripts } = useContext(ApiBuilderContext);
  const { inputs, outputs } = useContext(ChainContext);
  const [logicConfig, setLogicConfig] = useState(emitter.logicConfig || {});
  const [createLogicScriptModal, setCreateLogicScriptModal] = useState(false);
  const [editLogicScriptModal, setEditLogicScriptModal] = useState(false);
  const shouldSave = React.useRef(false);

  const systemValues = SystemBuilderUtils.getIOitems(inputs, outputs, {
    inputs: ["inputBlocks.userInput", "global.parameters"],
    outputs: [
      "transform.promptBlocks",
      "inputConnections.fetcher",
      "emitters.logic",
      "emitters.fetch"
    ]
  });
  const { inputsList, outputsList } = systemValues;

  const systemValuesItems = SystemBuilderUtils.IOitemsToDropdownItems([
    ...inputsList,
    ...outputsList
  ]);

  useEffect(() => {
    if (shouldSave.current) {
      shouldSave.current = false;
      autoSave();
    }
  }, [logicConfig]);

  const handleOnChange = (value, key) => {
    const newLogicConfig = {
      ...logicConfig,
      [key]: value
    };

    if (LOGIC_CONFIG_SHOULD_SAVE_KEYS.includes(key)) {
      shouldSave.current = true;
    }
    setLogicConfig(newLogicConfig);
  };

  const handleOnChangeAllSave = (newLogicConfig) => {
    shouldSave.current = true;
    setLogicConfig({
      ...logicConfig,
      ...newLogicConfig
    });
  };

  const selectedDslScript = dslScripts.find(
    (dslScript) => dslScript.id === logicConfig.logicScriptId
  );

  const autoSave = () => {
    const newEmitter = {
      ...emitter,
      logicConfig
    };
    onChange(newEmitter);
  };

  return (
    <>
      {createLogicScriptModal && (
        <LogicScriptModal
          apiProjectId={apiProjectId}
          forcedScriptRole={"condition"}
          onCreated={(logicScript) => {
            setCreateLogicScriptModal(false);
          }}
          onHide={() => setCreateLogicScriptModal(false)}
        />
      )}
      {editLogicScriptModal && selectedDslScript && (
        <LogicScriptModal
          apiProjectId={apiProjectId}
          forcedScriptRole={"condition"}
          currentScript={selectedDslScript}
          onHide={() => setEditLogicScriptModal(false)}
        />
      )}
      <Form.Group className="mb-3">
        <Form.Label>Logic Script</Form.Label>

        <SimpleSelector
          className="node-dropdown"
          selectorPlaceholder={"Select Logic Script"}
          items={dslScripts}
          onSelect={(logicScript) => {
            handleOnChange(logicScript.id, "logicScriptId");
          }}
          isSelected={(logicScript) => {
            return logicScript.id === logicConfig.logicScriptId;
          }}
          actions={[
            {
              label: "Create Logic Script",
              icon: "plus-circle",
              onClick: () => {
                setCreateLogicScriptModal(true);
              }
            }
          ]}
        />
        {logicConfig.logicScriptId && (
          <Button
            variant="link"
            className="p-0 fw-bold mt-1"
            onClick={() => {
              setEditLogicScriptModal(true);
            }}
          >
            Edit Logic Script
          </Button>
        )}
      </Form.Group>

      {logicConfig.logicScriptId && selectedDslScript && (
        <LogicConfigScriptOptions
          systemValuesItems={systemValuesItems}
          logicConfig={logicConfig}
          onChange={(newLogicConfig) => {
            handleOnChangeAllSave(newLogicConfig);
          }}
          logicScript={selectedDslScript}
        />
      )}

      <BlockType block={emitter} blockType="logicListener" />
    </>
  );
};

// If emitter.fetcherConfig is empty, it will be set to {apiConnectorId: null, options: {}, selectedMethod: null}
// If emitter.fetcherConfig.apiConnectorId exists, then it will look for the routes using DB.inputApiConnectors.sub.methods.list(
//   inputApiConnector.id,
//   (methods) => {
//     setMethods(methods);
//   }
// );
//
// if emitter.fetcherConfig.selectedMethod exists, then it render the inputs for emitter.fetcherConfig.options which is based on the selectedMethod which is as follows:
// {
// name : string
// description : string
// path : string
// httpMethod : string
// -- The form for emitter.fetcherConfig.options will be based on the inputs of the selectedMethod, when selectedMethod changes it will hydrate the emitter.fetcherConfig.options by adding an item {key: input.key, valueRef: null, value: null} for each string of the lists below
// importedQueriesVariables : [string]
// importedParametersVariables : [string]
// importedPathVariables : [string]
// importedHeadersVariables : [string]
// }

const FETCHER_CONFIG_SHOULD_SAVE_KEYS = [
  "apiConnectorId",
  "selectedMethod",
  "options"
];

const FetcherConfig = ({ emitterState, onChange }) => {
  const { inputs, outputs } = useContext(ChainContext);
  const {
    inputApiConnectors,
    id: apiProjectId,
    isCreator
  } = useContext(ApiBuilderContext);
  const [fetcherConfig, setFetcherConfig] = useState(
    emitterState.fetcherConfig || {
      apiConnectorId: null,
      selectedMethod: null,
      options: []
    }
  );
  const [apiConnector, setApiConnector] = useState(null);
  const [methods, setMethods] = useState([]);
  const [showApiConnectorModal, setShowApiConnectorModal] = useState(false);
  const [showInputApiConnectorModal, setShowInputApiConnectorModal] =
    useState(false);
  const [seeMethodOptions, setSeeMethodOptions] = useState(false);
  const shouldSave = React.useRef(false);
  const isFromShared = !isCreator;

  useEffect(() => {
    if (shouldSave.current) {
      shouldSave.current = false;
      autoSave();
    }
  }, [fetcherConfig]);

  useEffect(() => {
    if (fetcherConfig.apiConnectorId) {
      const inputApiConnector = inputApiConnectors.find(
        (inputApiConnector) =>
          inputApiConnector.id === fetcherConfig.apiConnectorId
      );
      setApiConnector(inputApiConnector);
    } else {
      setApiConnector(null);
    }
  }, [fetcherConfig.apiConnectorId, inputApiConnectors]);

  useEffect(() => {
    if (apiConnector) {
      DB.apiProjects.sub.inputApiConnectors.sub.methods.list(
        apiProjectId,
        apiConnector.id,
        (methods) => {
          setMethods(methods);
        },
        null,
        null,
        null,
        {
          isFromShared: isFromShared
        }
      );
    } else {
      setMethods([]);
    }
  }, [apiConnector]);

  const handleOnChange = (value, key) => {
    const newFetcherConfig = {
      ...fetcherConfig,
      [key]: value
    };

    if (FETCHER_CONFIG_SHOULD_SAVE_KEYS.includes(key)) {
      shouldSave.current = true;
    }

    setFetcherConfig(newFetcherConfig);
    // if (key === "apiConnectorId") {
    //   handleOnChange(null, "selectedMethod");
    // }
  };

  const handleOnChangeAllSave = (newFetcherConfig) => {
    shouldSave.current = true;
    setFetcherConfig({
      ...fetcherConfig,
      ...newFetcherConfig
    });
  };

  const autoSave = () => {
    const newEmitter = {
      ...emitterState,
      fetcherConfig
    };
    onChange(newEmitter);
  };

  const systemValues = SystemBuilderUtils.getIOitems(inputs, outputs, {
    inputs: ["inputBlocks.userInput", "global.parameters"],
    outputs: [
      "transform.promptBlocks",
      "inputConnections.fetcher",
      "emitters.logic",
      "emitters.fetch"
    ]
  });
  const { inputsList, outputsList } = systemValues;

  const systemValuesItems = SystemBuilderUtils.IOitemsToDropdownItems([
    ...inputsList,
    ...outputsList
  ]);

  const selectedMethod = methods.find(
    (method) => method.id === fetcherConfig.selectedMethod
  );

  const importedQueriesVariables = selectedMethod
    ? selectedMethod.importedQueriesVariables
    : [];
  const importedParametersVariables = selectedMethod
    ? selectedMethod.importedParametersVariables
    : [];
  const importedPathVariables = selectedMethod
    ? selectedMethod.importedPathVariables
    : [];
  const importedHeadersVariables = selectedMethod
    ? selectedMethod.importedHeadersVariables
    : [];

  const importedVariables = [
    ...importedQueriesVariables,
    ...(importedParametersVariables || []),
    ...importedPathVariables,
    ...importedHeadersVariables
  ];

  const importedVariablesSaved = fetcherConfig.options || [];
  const newImportedVariables = importedVariables.map((importedVariable) => {
    const { key } = importedVariable;
    const currentVariableSaved = importedVariablesSaved.find(
      (importedVariableSaved) => importedVariableSaved.key === key
    );
    const currentVariableSavedValue = currentVariableSaved
      ? currentVariableSaved.value
      : null;
    const variableMode = currentVariableSaved
      ? currentVariableSaved.variableMode
      : "rawValue";

    return {
      key,
      value: currentVariableSavedValue,
      type: importedVariable.type,
      variableMode
    };
  });

  const handleOnImportedVariableChange = (value, keyValue, keyVariable) => {
    const newOptions = [...(fetcherConfig.options || [])];

    console.log("newOptions", newOptions);

    const keyFoundIndex = newOptions.findIndex(
      (option) => option.key === keyVariable
    );

    if (keyFoundIndex === -1) {
      newOptions.push({
        key: keyVariable,
        [keyValue]: value
      });
    } else {
      newOptions[keyFoundIndex] = {
        ...newOptions[keyFoundIndex],
        [keyValue]: value
      };
    }

    handleOnImportedVariableChangeAllSave(newOptions);
  };

  const handleOnImportedVariableChangeAllSave = (newOptions) => {
    const newFetcherConfig = {
      ...fetcherConfig,
      options: newOptions
    };
    handleOnChangeAllSave(newFetcherConfig);
  };

  const options = fetcherConfig.options || [];

  return (
    <>
      {/* {showApiConnectorModal && (
        <ApiConnectorModal
          onHide={() => setShowApiConnectorModal(false)}
          onCreated={(apiConnector) => {
            setShowApiConnectorModal(false);
          }}
        />
      )}
      {showInputApiConnectorModal && (
        <InputApiConnectorModal
          onHide={() => setShowInputApiConnectorModal(false)}
          onCreated={(inputApiConnector) => {
            setShowInputApiConnectorModal(false);
          }}
        />
      )} */}
      <Form.Group className="mb-3">
        <Form.Label>API Connector</Form.Label>
        <SimpleSelector
          className="node-dropdown"
          selectorPlaceholder={"Select API Connector"}
          items={inputApiConnectors}
          onSelect={(apiConnector) => {
            handleOnChange(apiConnector.id, "apiConnectorId");
          }}
          isSelected={(apiConnector) => {
            return apiConnector.id === fetcherConfig.apiConnectorId;
          }}
          actions={[
            {
              label: "Create API Connector",
              icon: "plus-circle",
              onClick: () => {
                setShowApiConnectorModal(true);
              }
            }
          ]}
        />

        <div>
          {fetcherConfig.apiConnectorId && (
            <Button
              className="pb-0 ps-0 fw-bold"
              variant="link"
              onClick={() => setShowApiConnectorModal(true)}
            >
              Edit Input API Connector
            </Button>
          )}
          {showApiConnectorModal && (
            <InputApiConnectorModal
              apiProjectId={apiProjectId}
              show={true}
              onHide={() => setShowApiConnectorModal(false)}
              onSave={(newApiConnector) => {
                //
              }}
              connectorId={fetcherConfig.apiConnectorId}
            />
          )}
        </div>
      </Form.Group>
      {apiConnector && (
        <Form.Group className="mb-3">
          <Form.Label>Method</Form.Label>
          <Form.Control
            as="select"
            value={fetcherConfig.selectedMethod || ""}
            onChange={(e) => {
              const value = e.target.value;
              handleOnChange(value, "selectedMethod");
            }}
          >
            <option value="">None</option>
            {methods.map((method) => (
              <option key={method.id} value={method.id}>
                {method.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      )}

      {fetcherConfig.selectedMethod && (
        <Form.Group className="mb-3">
          <Button
            variant="link"
            className="p-0 fw-bold text-white-9"
            onClick={() => setSeeMethodOptions(true)}
          >
            Variables ({importedVariables.length})
          </Button>
          <Modal
            show={seeMethodOptions}
            onHide={() => setSeeMethodOptions(false)}
            className="flow-parameters-modal"
            size="lg"
          >
            <Modal.Header closeButton className="p-4 mb-3">
              <Modal.Title className="text-white">Variables</Modal.Title>
            </Modal.Header>
            <div className="modal-form-container">
              {newImportedVariables.map((importedVariable, index) => {
                const { key, type } = importedVariable;
                const savedImportedVariable = options.find(
                  (importedVariable) => importedVariable.key === key
                );
                const value = savedImportedVariable
                  ? savedImportedVariable.value
                  : null;
                const variableMode = savedImportedVariable
                  ? savedImportedVariable.variableMode
                  : "rawValue";

                let selectProps = {};

                const inputMode =
                  variableMode === "variableRef" ? "select" : "default";

                const placeholder = `Enter value for ${key}`;

                if (inputMode === "select") {
                  selectProps = {
                    items: systemValuesItems,
                    isSelected: (item) => item.value === value,
                    onSelect: (item) => {
                      handleOnImportedVariableChange(item.value, "value", key);
                    },
                    className: "nodrag nowheel",
                    selectorPlaceholder: `Select ${key}`
                  };
                }

                return (
                  <Row key={index} className="mb-3">
                    {/* {inputMode === "default" && (
                  <Col>
                    <Form.Control type="text" value={key} disabled={true} />
                  </Col>
                )} */}
                    <Col xs={12} className="mb-3">
                      <div className="d-flex align-items-center">
                        <div className="me-3">
                          <h5 className="title-label-parameter">{key}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <InputTypeForm
                        resultclassname="node-dropdown nodrag dpd-1 text-white w-80 text-center d-flex align-items-center justify-content-center"
                        editbuttonclassname="btn-side-1"
                        placeholder={placeholder}
                        type={type}
                        inputMode={inputMode}
                        value={value}
                        notresultlabel={
                          <span className="text-white-5">
                            List of {key} is empty
                          </span>
                        }
                        editbuttonlabel={`Add ${key} item`}
                        onChange={(value) => {
                          handleOnImportedVariableChange(value, "value", key);
                        }}
                        {...selectProps}
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        as="select"
                        value={variableMode}
                        onChange={(e) => {
                          const value = e.target.value;
                          handleOnImportedVariableChange(
                            value,
                            "variableMode",
                            key
                          );
                        }}
                      >
                        <option value="rawValue">Raw Value</option>
                        <option value="variableRef">Variable Ref</option>
                      </Form.Control>
                    </Col>
                    <div className="horizontal-separator mt-3" />
                  </Row>
                );
              })}
            </div>
          </Modal>
        </Form.Group>
      )}

      {selectedMethod && (
        <BlockType
          block={{
            ...emitterState,
            method: selectedMethod
          }}
          blockType="actionFetcher"
        />
      )}
    </>
  );
};

const getDataFromEmitters = (emitters) => {
  const nodes = emitters.map((emitter, i) => {
    // Will render by columns of 300px with 3 columns

    const { name, positionFlow = {}, id } = emitter;

    let x = positionFlow.x;
    let y = positionFlow.y;

    if ((!x && x !== 0) || (!y && y !== 0)) {
      const col = i % 3;
      const row = Math.floor(i / 3);

      x = col * 400;
      y = row * 400;
    }

    const node = {
      id: id,
      data: {
        label: name,
        key: id,
        emitter
      },
      position: { x, y },
      type: "emitter"
      // ...additionalNodeProps,
    };

    return node;
  });

  return nodes;
};

const EMITTER_NODE_SHOULD_SAVE_KEYS = [
  "logicConfig",
  "fetcherConfig",
  "action",
  "listeners"
];

export const EmitterNode = ({ data }) => {
  const { inputs, outputs, emitters, chainActions } = useContext(ChainContext);
  const { label, emitter, key } = data;
  const [emitterState, setEmitterState] = useState(emitter);
  const shouldSave = React.useRef(false);

  const { action } = emitterState;

  useEffect(() => {
    if (shouldSave.current) {
      shouldSave.current = false;
      autoSave();
    }
  }, [emitterState]);

  const nodeClassName = `node-border-${action}`;

  const handleOnChange = (value, key) => {
    const newEmitterState = {
      ...emitterState,
      [key]: value
    };

    if (EMITTER_NODE_SHOULD_SAVE_KEYS.includes(key)) {
      shouldSave.current = true;
    }

    setEmitterState(newEmitterState);
  };

  const autoSave = async () => {
    try {
      const { positionFlow, ...emitterStateClean } = emitterState;
      await chainActions.updateEmitter(emitterStateClean);
    } catch (error) {
      console.error(error);
    }
  };

  const onBlur = () => {
    autoSave();
  };

  const listenersIO = SystemBuilderUtils.getIOitems_emitter(
    inputs,
    outputs,
    emitterState,
    {
      inputs: ["global.parameters", "inputBlocks.userInput"],
      outputs: [
        "transform.promptBlocks",
        "inputConnections.fetcher",
        "emitters.logic",
        "emitters.fetch"
      ]
    }
  );
  const { inputsList, outputsList } = listenersIO;

  const IOdropdownItems = SystemBuilderUtils.IOitemsToDropdownItems([
    ...inputsList,
    ...outputsList
  ]);

  return (
    <Card
      className={`p-3 shadow-sm EmitterNode fs-6 node-dark-theme ${nodeClassName}`}
    >
      <Card.Title className="text-white">{label}</Card.Title>
      <Card.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              onBlur={onBlur}
              value={emitterState.name || ""}
              onChange={(e) => {
                const value = e.target.value;
                handleOnChange(value, "name");
              }}
            />
          </Form.Group>
          {/* listeners */}
          {/* <Form.Group className={"mb-3"}>
            <Form.Label>Triggered by</Form.Label>
            <DropdownGroupedSelect
              className="node-dropdown nodrag dpd-1 zIndex-9999"
              classNameMenu="nowheel"
              items={IOdropdownItems}
              onAdd={(item) => {
                const newListeners = [...emitterState.listeners, item.value];
                handleOnChange(newListeners, "listeners");
              }}
              onRemove={(item) => {
                const newListeners = emitterState.listeners.filter(
                  (listener) => listener !== item.value
                );
                handleOnChange(newListeners, "listeners");
              }}
              isSelected={(item) => {
                return emitterState.listeners.includes(item.value);
              }}
              tr={{
                title: (group) => SystemBuilderUtils.tr(group)
              }}
              placeholder={"Select Listener"}
            />
          </Form.Group> */}
          {/* 
          <Form.Group className="mb-3">
            <Form.Label>Action</Form.Label>
            <Form.Control
              as="select"
              value={action}
              onChange={(e) => {
                const value = e.target.value;
                handleOnChange(value, "action");
              }}
            >
              <option value="fetch">Fetch</option>
              <option value="chainAction">Chain Action</option>
              <option value="systemAction">System Action</option>
              <option value="systemsAction">Systems Action</option>
              <option value="logic">Logic</option>
            </Form.Control>
          </Form.Group> */}
          {action === "logic" && (
            <LogicConfig
              emitter={emitterState}
              onChange={(newEmitter) => {
                handleOnChange(newEmitter.logicConfig, "logicConfig");
              }}
            />
          )}

          {action === "fetch" && (
            <FetcherConfig
              emitterState={emitterState}
              onChange={(newEmitter) => {
                handleOnChange(newEmitter.fetcherConfig, "fetcherConfig");
              }}
            />
          )}
        </Form>
      </Card.Body>
    </Card>
  );
};

const nodesTypes = {
  emitter: EmitterNode
  // "global-chain-parameters": GlobalChainParameters,
};

const getDataNodes = (emitters) => {
  const nodes = [
    // getDataFromGlobalChainParameters(globalParameters, promptBlocks),
    ...getDataFromEmitters(emitters)
  ];
  return nodes;
};

const EmittersFlow = ({ reactFlowInstance, onSelectFlow }) => {
  const { id: apiProjectId } = useContext(ApiBuilderContext);
  const { id: chainId, chainActions, emitters } = useContext(ChainContext);

  const [showGlobalParameters, setShowGlobalParameters] = useState(false);
  const [lastSavedConnections, setLastSavedConnections] = useState([]);
  const { addNodes, setNodes: setNodesFlow } = reactFlowInstance;
  const { x: viewportX, y: viewportY, zoom } = useViewport();
  const [nodes, setNodes] = useState(getDataNodes([]));
  const [contextMenu, setContextMenu] = useState({
    show: false,
    position: { x: 0, y: 0 }
  });
  const [edges, setEdges] = useState([]);
  const isMovingNode = React.useRef(false);
  const lastNodesPositions = React.useRef({});

  useEffect(() => {
    if (emitters) {
      setNodesFlow(getDataNodes(emitters));
      setNodes(getDataNodes(emitters));
    } else {
      setNodesFlow(getDataNodes([]));
      setNodes(getDataNodes([]));
    }
  }, [emitters]);

  const updateEmitters = async (emitters) => {
    try {
      for (let i = 0; i < emitters.length; i++) {
        const emitter = emitters[i];
        chainActions.updateEmitter(emitter);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onNewPositionsCallback = (changePayload) => {
    flowDbUtils.updateNodesPositions(
      changePayload,
      isMovingNode,
      lastNodesPositions,
      (newNodes) => {
        updateEmitters(newNodes);
      }
    );
  };

  const onNodesChange = (changes) =>
    setNodes((nds) => {
      const changesPayload = changes.map((change) => {
        const { type: changeType } = change;

        if (changeType === "position") {
          const { position = null, id = null } = change;
          return { position, id, changeType };
        } else if (changeType === "add") {
          const { item } = change;
          const { data, id, position, type } = item;
          return { data, id, position, type, changeType };
        }
        return { ...change, changeType };
      });

      const positionsToChange = changesPayload.filter(
        (position) => position.changeType === "position"
      );
      if (positionsToChange.length > 0) {
        onNewPositionsCallback(changesPayload);
      }

      const filteredChanges = changes.filter((change) => {
        const { type, id } = change;

        const isOperationAllowed = !["remove", "select"].includes(type);
        // const isIdAllowed = !["global-chain-parameters"].includes(id);

        return isOperationAllowed;
      });

      return applyNodeChanges(filteredChanges, nds);
    });

  const onEdgesChange = React.useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onConnect = React.useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  const onBackgroundContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const x = e.clientX;
    const y = e.clientY;
    const height = e.target.clientHeight;

    setContextMenu({ show: true, position: { x, y }, menuHeight: height });
  };

  const onCloseContextMenu = () => {
    setContextMenu({ show: false, position: { x: 0, y: 0 } });
  };

  const actions = [
    {
      name: "Add Emitters",
      onClick: async (e) => {
        const { clientX, clientY } = e;

        const positionFlow = {
          x: (clientX - viewportX) * zoom,
          y: (clientY - viewportY) * zoom
        };

        const newEmitter = await chainActions.createEmitter({
          positionFlow
        });

        addNodes({
          id: newEmitter.id,
          data: {
            label: newEmitter.name,
            emitters,
            key: newEmitter.id,
            emitter: {
              ...newEmitter,
              id: newEmitter.id
            }
          },
          position: { x: positionFlow.x, y: positionFlow.y },
          type: "emitter"
        });
      },
      icon: "fas fa-plus-circle"
    }
  ];

  const actionsLeftMenu = [
    {
      icon: "bi bi-receipt",
      label: "Global Inputs/Parameters",
      description: "See and set the inputs/parameters your system will use",
      onClick: () => {
        setShowGlobalParameters(!showGlobalParameters);
      }
    }
  ];

  return (
    <>
      {showGlobalParameters && <GlobalChainParameters />}
      <ReactFlow
        style={{ height: "100%" }}
        nodes={nodes}
        edges={edges}
        nodeTypes={nodesTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        panOnScroll
        snapToGrid={true}
        defaultViewport={{
          x: 100,
          y: 100,
          zoom: 1
        }}
        snapGrid={[17, 20]}
        className="nodes-background"
        // onContextMenu={onBackgroundContextMenu}
        onPaneContextMenu={onBackgroundContextMenu}
      >
        <Panel position="top-center" className="px-5 w-100">
          <PanelTopFlowSelector
            apiProjectId={apiProjectId}
            chainId={chainId}
            onSelectFlow={onSelectFlow}
            selectedFlow={"emit"}
          />
        </Panel>
        <Panel
          position="left"
          className="px-3 h-100 d-flex flex-column align-items-center justify-content-center"
        >
          <PanelLeft actions={actionsLeftMenu} />
        </Panel>
        <ContextMenuPane
          {...contextMenu}
          onClose={onCloseContextMenu}
          actions={actions}
        />
        <Background className="nodes-background" gap={75} id="background" />
        <Controls />
        <MiniMap />
      </ReactFlow>
    </>
  );
};

export default EmittersFlow;
