import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  query,
  orderBy,
  limit,
  where,
  onSnapshot,
  setDoc,
  updateDoc,
  getDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { firebaseDB, firebaseAuth } from "../api-connector/firebase";

/**
 * @typedef {Object} BuiltPromptListItem
 * @property {string} id - The id of the builtPrompt.
 * @property {Object} valueKeysToMap - The initial keys of this are the valueKeys of the builtPrompt.
 */

/**
 * This files contains all the functions that interact with the firebase database using firebaseDB(getFirestore(firebaseApp)).
 * The functions are used to create, read, update and delete chainedBuilt collections and documents.
 */

/*
 * @typedef {Object} chainedBuilt
 * @property {string} id - The id of the chainedBuilt.
 * @property {string} name - The name of the chainedBuilt.
 * @property {[BuiltPromptListItem]} builtPromptList - The list of builtPromptListItems.
 * @property {string} createdAt - The date the chainedBuilt was created.
 * @property {string} updatedAt - The date the chainedBuilt was last updated.
 */

const chainedBuiltCollection = collection(firebaseDB, "chainedBuilts");

const version = "1.0.0";

/**
 * Creates a chainedBuilt in the database.
 * @param {chainedBuilt} chainedBuilt
 * @returns {string} The id of the chainedBuilt.
 * @throws {Error} If the chainedBuilt could not be created.
 */
export const createChainedBuilt = async (chainedBuilt) => {
  console.log("createChainedBuilt", chainedBuilt);
  try {
    const user = firebaseAuth.currentUser;
    const chainedBuiltDoc = await addDoc(chainedBuiltCollection, {
      ...chainedBuilt,
      createdAt: serverTimestamp(),
      uid: user.uid,
      v: version,
    });
    console.log("Document written with ID: ", chainedBuiltDoc.id);
    return chainedBuiltDoc.id;
  } catch (e) {
    console.log(e);
  }
};

/**
 * Gets a chainedBuilt from the database.
 * @param {string} builtPromptId - The id of the builtPrompt.
 * @param {string} chainedBuiltId - The id of the chainedBuilt.
 * @param {function} setChainedBuilt - The function to set the chainedBuilt.
 * @returns {function} The unsubscribe function.
 * @throws {Error} If the chainedBuilt could not be retrieved.
 * @throws {Error} If the chainedBuiltId is not provided.
 * @throws {Error} If the setChainedBuilt function is not provided.
 *  */
export const getChainedBuilt = (chainedBuiltId, setChainedBuilt) => {
  if (!chainedBuiltId) {
    throw new Error("chainedBuiltId is required");
  }
  if (!setChainedBuilt) {
    throw new Error("setChainedBuilt is required");
  }
  const docRef = doc(firebaseDB, "chainedBuilts", chainedBuiltId);

  const unsubscribe = onSnapshot(docRef, (doc) => {
    if (doc.exists()) {
      setChainedBuilt({ ...doc.data(), id: doc.id });
    } else {
      console.log("No such document!");
    }
  });

  return unsubscribe;
};

/**
 * getChainedBuilts
 * @param {function} setChainedBuilts
 * @returns {function} The unsubscribe function.
 */
export const getChainedBuilts = (setChainedBuilts) => {
  const user = firebaseAuth.currentUser;
  const q = query(
    chainedBuiltCollection,
    where("uid", "==", user.uid)
    // orderBy("createdAt", "desc"),
    // limit(10)
  );
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const chainedBuilts = [];
    querySnapshot.forEach((doc) => {
      chainedBuilts.push({ ...doc.data(), id: doc.id });
    });
    setChainedBuilts(chainedBuilts);
  });

  return unsubscribe;
};

/**
 * Update a chainedBuilt in the database.
 * @param {string} chainedBuiltId - The id of the chainedBuilt.
 * @param {chainedBuilt} chainedBuilt - The chainedBuilt to update.
 * @returns {string} The id of the chainedBuilt.
 * @throws {Error} If the chainedBuilt could not be updated.
 * @throws {Error} If the chainedBuilt is not provided.
 * @throws {Error} If the chainedBuilt does not have an id.
 * @throws {Error} If the chainedBuilt does not have a builtPromptId.
 **/
export const updateChainedBuilt = async (chainedBuiltId, chainedBuilt) => {
  if (!chainedBuiltId) {
    throw new Error("chainedBuiltId is required");
  }
  if (!chainedBuilt) {
    throw new Error("chainedBuilt is required");
  }
  if (!chainedBuilt.id) {
    throw new Error("chainedBuilt must have an id");
  }
  try {
    const docRef = doc(firebaseDB, "chainedBuilts", chainedBuiltId);
    await updateDoc(docRef, {
      ...chainedBuilt,
      updatedAt: serverTimestamp(),
    });
    console.log("Document successfully updated!");
    return docRef.id;
  } catch (e) {
    console.log(e);
  }
};

/**
 * Deletes a chainedBuilt from the database.
 * @param {string} chainedBuiltId - The id of the chainedBuilt.
 * @returns {string} The id of the chainedBuilt.
 * @throws {Error} If the chainedBuilt could not be deleted.
 * @throws {Error} If the chainedBuiltId is not provided.
 * @throws {Error} If the chainedBuilt does not have an id.
 * @throws {Error} If the chainedBuilt does not have a builtPromptId.
 * */
export const deleteChainedBuilt = async (chainedBuiltId) => {
  if (!chainedBuiltId) {
    throw new Error("chainedBuiltId is required");
  }
  try {
    const docRef = doc(firebaseDB, "chainedBuilts", chainedBuiltId);
    await deleteDoc(docRef);
    console.log("Document successfully deleted!");
    return docRef.id;
  } catch (e) {
    console.log(e);
  }
};

export default {
  get: getChainedBuilt,
  list: getChainedBuilts,
  create: createChainedBuilt,
  update: updateChainedBuilt,
  delete: deleteChainedBuilt,
};
