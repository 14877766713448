import React, { useState } from "react";
import DeploymentSidebar from "./DeploymentSidebar";
import DeploymentContent from "./DeploymentContent";
import "./DeploymentsPage.css";

const DeploymentsPage = () => {
  const [selectedResourceType, setSelectedResourceType] = useState("managed");

  return (
    <div className="deployments-page">
      <DeploymentSidebar onSelectResourceType={setSelectedResourceType} />
      <DeploymentContent resourceType={selectedResourceType} />
    </div>
  );
};

export default DeploymentsPage;
