import axios from "axios";
import { firebaseAuth } from "../api-connector/firebase";

const api_base = process.env.REACT_APP_API_URL + "/deployment";

const r = {
  deployManaged: `${api_base}/deploy-managed`,
  updateManaged: `${api_base}/update-managed`,
  creaxysApiKeys: `${api_base}/creaxys-api-keys`,
  tokenGroupApiKeys: `${api_base}/token-group-api-keys`
};

const getFirebaseToken = async () => {
  const token = await firebaseAuth.currentUser.getIdToken();
  return token;
};

const getHeaders = async () => {
  const token = await getFirebaseToken();
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
};

/**
 * @param {string} deploymentModelId
 * @param {string} deploymentName
 * @param {string} resourceType
 * @param {object} deployedTokenGroups
 * @returns {Promise<object>}
 */
export const deployManaged = async (
  projectId,
  deploymentModelId,
  deploymentName,
  resourceType,
  deployedTokenGroups
) => {
  try {
    const headers = await getHeaders();

    const result = await axios.post(
      r.deployManaged,
      {
        projectId,
        deploymentModelId,
        deploymentName,
        resourceType,
        deployedTokenGroups
      },
      headers
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const updateManaged = async (deploymentId) => {
  try {
    const headers = await getHeaders();

    const result = await axios.put(
      r.updateManaged + `/${deploymentId}`,
      {
        deploymentId
      },
      headers
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getCreaxysApiKeys = async (deploymentId) => {
  try {
    const headers = await getHeaders();

    const result = await axios.get(
      `${r.creaxysApiKeys}/${deploymentId}`,
      headers
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getLatestCreaxysApiKey = async (deploymentId) => {
  try {
    const headers = await getHeaders();

    const result = await axios.get(
      `${r.creaxysApiKeys}/${deploymentId}/latest`,
      headers
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const createCreaxysApiKey = async (deploymentId) => {
  try {
    const headers = await getHeaders();

    const result = await axios.post(
      `${r.creaxysApiKeys}/${deploymentId}`,
      {},
      headers
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getTokenGroupApiKeys = async (deploymentId) => {
  try {
    const headers = await getHeaders();

    const result = await axios.get(
      `${r.tokenGroupApiKeys}/${deploymentId}`,
      headers
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const ALLOWED_OPTIONAL_KEYS = ["custom_metadata", "unique_id_for_rollkey"];
const createTokenGroupApiKey = async (
  deploymentId,
  tokenGroupId,
  optional = {}
) => {
  try {
    const headers = await getHeaders();

    let body = {};

    ALLOWED_OPTIONAL_KEYS.forEach((key) => {
      if (optional[key]) {
        body[key] = optional[key];
      }
    });

    const result = await axios.post(
      `${r.tokenGroupApiKeys}/${deploymentId}/${tokenGroupId}`,
      body,
      headers
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const DeploymentApi = {
  deployManaged,
  getCreaxysApiKeys,
  updateManaged,
  createCreaxysApiKey,
  getTokenGroupApiKeys,
  createTokenGroupApiKey,
  getLatestCreaxysApiKey
};

export default DeploymentApi;
