import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  query,
  orderBy,
  limit,
  where,
  onSnapshot,
  setDoc,
  updateDoc,
  getDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { firebaseDB, firebaseAuth } from "../api-connector/firebase";

const version = "1.0.0";

/**
 * This files contains all the functions that interact with the firebase database using firebaseDB(getFirestore(firebaseApp)).
 * The functions are used to create, read, update and delete builtPrompts collections and documents.
 */

/*
 * @typedef {Object} BuiltPrompt
 * @property {string} id - The id of the builtPrompt.
 * @property {string} uid - The user id of the user who created the builtPrompt.
 * @property {string} promptName - The name of the builtPrompt.
 * @property {string} prompt - The prompt of the builtPrompt.
 * @property {string} example - The example of the builtPrompt.
 * @property [string] valueKeys - The value keys of the builtPrompt.
 * @property {string} createdAt - The date the builtPrompt was created.
 * @property {string} updatedAt - The date the builtPrompt was last updated.
 * @property {GptConfig} gptConfig - The gptConfig of the builtPrompt.
 */

const builtPromptCollection = collection(firebaseDB, "builtPrompts");

/**
 * Creates a builtPrompt in the database.
 */
export const createBuiltPrompt = async (builtPrompt) => {
  try {
    const user = firebaseAuth.currentUser;
    const builtPromptDoc = await addDoc(builtPromptCollection, {
      ...builtPrompt,
      createdAt: serverTimestamp(),
      uid: user.uid,
      v: version,
    });
    console.log("Document written with ID: ", builtPromptDoc.id);
    return builtPromptDoc.id;
  } catch (e) {
    console.log(e);
  }
};

/**
 * Gets a builtPrompt from the database.
 */
export const getBuiltPrompt = (builtPromptId, setBuiltPrompt) => {
  // const user = firebaseAuth.currentUser;
  const docRef = doc(firebaseDB, "builtPrompts", builtPromptId);

  const unsubscribe = onSnapshot(docRef, (doc) => {
    if (doc.exists()) {
      setBuiltPrompt({ ...doc.data(), id: doc.id });
    } else {
      console.log("No such document!");
    }
  });

  return unsubscribe;
};

/**
 * Gets all the builtPrompts from the database.
 */
export const getBuiltPrompts = (setBuiltPrompts) => {
  const user = firebaseAuth.currentUser;
  const q = query(
    builtPromptCollection,
    where("uid", "==", user.uid)
    // orderBy("createdAt", "desc"),
    // limit(10)
  );
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const builtPrompts = [];
    querySnapshot.forEach((doc) => {
      builtPrompts.push({ ...doc.data(), id: doc.id });
    });
    setBuiltPrompts(builtPrompts);
  });

  return unsubscribe;
};

/**
 * Update a builtPrompt in the database.
 */
export const updateBuiltPrompt = async (id, builtPrompt) => {
  console.log("update built prompt", builtPrompt);
  try {
    const docRef = doc(firebaseDB, "builtPrompts", id);
    await updateDoc(docRef, {
      ...builtPrompt,
      updatedAt: serverTimestamp(),
    });
    console.log("Document successfully updated!");
    return docRef.id;
  } catch (e) {
    console.log(e);
  }
};

/**
 * Delete a builtPrompt from the database.
 */
export const deleteBuiltPrompt = async (builtPromptId) => {
  try {
    const docRef = doc(firebaseDB, "builtPrompts", builtPromptId);
    await deleteDoc(docRef);
    console.log("Document successfully deleted!");
  } catch (e) {
    console.log(e);
  }
};

export default {
  create: createBuiltPrompt,
  get: getBuiltPrompt,
  list: getBuiltPrompts,
  update: updateBuiltPrompt,
  delete: deleteBuiltPrompt,
};
