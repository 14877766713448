import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import AppBuilderApp from "./apps/AppBuilder/App";
import routes from "./apps/AppBuilder/routes";
import Home from "./components/pages/Home";
import ProjectConfiguration from "./components/pages/ProjectConfiguration";
import SmartCodeEditor from "./components/pages/SmartCodeEditor";
import LiteTraining from "./components/pages/LiteTraining";
import ChainedBuiltPage from "./components/pages/LiteTraining/ChainedBuilt";
import FineTuning from "./components/pages/FineTuning";
import ApiBuilder from "./components/pages/ApiBuilder";
import Account from "./components/pages/Account";
import { GlobalProvider } from "./contexts/GlobalContext";
import { aiChainConfig } from "./libraries/ApiProjectSdk";
import { firebaseDB } from "./api-connector/firebase";

aiChainConfig.config({
  aliases: {
    aiChain: "p7Te27o1tJku1y8eBzTW::vZTEME3YwDBVwybZIhFk",
    webappBuilder: "HXKqDpnR1yFQKWKynA5u::NHzbwkgAesuq0A39HbCi"
  },
  db: firebaseDB
});

const smartCodeEditorLoader = ({ params }) => {
  return {
    projectId: params.projectId
  };
};

const webappRoutes = routes("/webapp-builder");

const routerDashboard = createBrowserRouter([
  ...webappRoutes,
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/account",
        element: <Account />
      },
      {
        path: "/project-configuration",
        element: <ProjectConfiguration />
      },
      {
        path: "/project-configuration/:projectId",
        element: <ProjectConfiguration />
      },
      {
        path: "/smart-code-editor",
        element: <SmartCodeEditor />
      },
      {
        path: "/smart-code-editor/:projectId",
        loader: smartCodeEditorLoader,
        element: <SmartCodeEditor />
      },
      {
        path: "/prompt-builder",
        element: <LiteTraining />
      },
      {
        path: "/prompt-builder/:builtPromptId",
        element: <LiteTraining />
      },
      {
        path: "/chained-built",
        element: <ChainedBuiltPage />
      },
      {
        path: "/chained-built/:chainedBuiltId",
        element: <ChainedBuiltPage />
      },
      {
        path: "/fine-tuning",
        element: <FineTuning />
      },
      {
        path: "/fine-tuning/:fineTuningId",
        element: <FineTuning />
      },
      {
        path: "/system-designer",
        element: <ApiBuilder />
      },
      {
        path: "/system-designer/:apiProjectId",
        element: <ApiBuilder />
      },
      {
        path: "/system-designer/:apiProjectId/:pageId",
        element: <ApiBuilder />
      },
      {
        path: "/system-designer/:apiProjectId/:pageId/:subId",
        element: <ApiBuilder />
      },
      {
        path: "/system-designer/:apiProjectId/:pageId/:subId/:subPageId",
        element: <ApiBuilder />
      }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <GlobalProvider>
    <RouterProvider router={routerDashboard}></RouterProvider>
  </GlobalProvider>
  // </React.StrictMode>
);
