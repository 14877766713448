import React from "react";
import { useState, useEffect } from "react";
import {
  Modal,
  Button,
  NavDropdown,
  DropdownButton,
  Dropdown,
  Form,
  Row,
  Col,
  Accordion,
  ListGroup,
  Tab,
  Nav,
  Badge,
  Spinner
} from "react-bootstrap";

import DB from "../../../database/DB";
import ClickableTextInput from "../../UI/ClickableTextInput";
import { ReactAutocomplete } from "../LiteTraining";
import { ModalTestApi } from "./InputApiConnector/ModalTestApi";

const getMethodFromRequestItem = (requestItem, nameGroup) => {
  const {
    name: nameRequest,
    description = "",
    request,
    response: responseList,
    item: items
  } = requestItem;
  const { url, method, header, body } = request;
  const { path: pathList, query: queryList } = url;
  const path = pathList.join("/");
  let queries = [];
  let parameters = [];
  let results = [];
  let headers = [];

  if (queryList) {
    queries = queryList.map(({ key, value }) => ({
      key: key || "",
      value: value || "",
      description: description
    }));
  }

  if (body) {
    const { mode, raw } = body;
    if (mode === "raw") {
      try {
        const bodyJson = JSON.parse(raw);
        parameters = Object.keys(bodyJson).map((key) => ({
          key,
          value: bodyJson[key] || "",
          type: bodyJson[key] ? typeof bodyJson[key] : ""
        }));
      } catch (error) {
        console.error(error);
      }
    }
  }

  if (responseList && responseList.length > 0) {
    const { code, body: bodyResponse, name: nameResponse } = responseList[0];
    let methodResults = [];

    if (bodyResponse) {
      try {
        const bodyResponseJson = JSON.parse(bodyResponse);
        methodResults = Object.keys(bodyResponseJson).map((key) => ({
          key: key,
          path: key,
          resultType: "json"
        }));
      } catch (error) {
        console.error(error);
        methodResults = [
          {
            key: "response",
            path: "response",
            resultType: "text"
          }
        ];
      }
    }
    results = methodResults;
  }

  if (header) {
    headers = header.map(({ key, value, type }) => ({
      key: key || "",
      value: value || "",
      type: type || ""
    }));
  }

  const newMethod = {
    name: nameRequest,
    description,
    group: nameGroup,
    path,
    httpMethod: method,
    queries,
    parameters,
    headers,
    results,
    importedQueriesVariables: extractParametersVariables(queries),
    importedParametersVariables: extractParametersVariables(parameters),
    importedPathVariables: extractPathVariables(path),
    importedHeadersVariables: extractHeadersVariables(headers),
    randomId: Math.random().toString(36).substring(7) + Date.now().toString()
  };

  return newMethod;
};

const extractGroupWithMethods = (item, groupAcc = "") => {
  let groups = [];
  let methods = [];

  item.forEach((itemCurrent, index, array) => {
    const { name, item: itemChild, request } = itemCurrent;

    if (request) {
      const newMethod = getMethodFromRequestItem(itemCurrent, groupAcc);
      methods.push(newMethod);
    }

    if (itemChild) {
      const newGroupAcc = groupAcc ? `${groupAcc}/${name}` : name;
      const { groups: groupsChild, methods: methodsChild } =
        extractGroupWithMethods(itemChild, newGroupAcc);
      groups = [...groups, ...groupsChild];
      methods = [...methods, ...methodsChild];
    }
  });

  return { groups, methods };
};

const fromPostmanToInputApiConnector = (postmanCollection) => {
  const { info, item } = postmanCollection;
  const { name: nameCollection, description } = info;
  let inputApiConnector = {
    name: nameCollection,
    description,
    methods: []
  };
  let methods = [];

  item.forEach((currentItem) => {
    const { groups: groupsChild, methods: currentMethods } =
      extractGroupWithMethods(currentItem.item, currentItem.name || "");
    methods = [...methods, ...currentMethods];
  });

  inputApiConnector.methods = methods;

  return inputApiConnector;
};

const ImportInputApiConnectorModal = ({ onHide, onImported }) => {
  const [postmanCollection, setPostmanCollection] = useState(null);
  const [inputApiConnector, setInputApiConnector] = useState(null);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [methods, setMethods] = useState([]);
  const [selectedMethods, setSelectedMethods] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [methodConfigTab, setMethodConfigTab] = useState("queries"); // parameters, results, headers, queries

  useEffect(() => {
    if (postmanCollection) {
      const newInputApiConnector =
        fromPostmanToInputApiConnector(postmanCollection);
      const { methods, ...newInputApiConnectorWithoutMethods } =
        newInputApiConnector;
      setInputApiConnector(newInputApiConnectorWithoutMethods);
      setMethods(methods);
    }
  }, [postmanCollection]);

  useEffect(() => {
    if (selectedGroups) {
      const methods = selectedGroups.reduce((acc, group) => {
        acc.push(...group.methods);
        return acc;
      }, []);

      setSelectedMethods(methods);
    }
  }, [selectedGroups]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      const postmanCollection = JSON.parse(e.target.result);
      setPostmanCollection(postmanCollection);
    };
    reader.readAsText(file);
  };

  useEffect(() => {
    setSelectedMethod(null);
  }, [selectedGroup]);

  const onAddGroup = (group) => {
    const updatedSelectedGroups = [...selectedGroups, group];
    setSelectedGroups(updatedSelectedGroups);
  };

  const onRemoveGroup = (group) => {
    const updatedSelectedGroups = selectedGroups.filter(
      (selectedGroup) => selectedGroup.name !== group.name
    );
    setSelectedGroups(updatedSelectedGroups);
  };

  const onAddMethod = (method) => {
    const updatedSelectedMethods = [...selectedMethods, method];
    setSelectedMethods(updatedSelectedMethods);
  };

  const onRemoveMethod = (method) => {
    const updatedSelectedMethods = selectedMethods.filter(
      (selectedMethod) => selectedMethod.randomId !== method.randomId
    );
    setSelectedMethods(updatedSelectedMethods);
  };

  const onMethodChange = (method, key, value) => {
    const updatedSelectedMethods = selectedMethods.map((selectedMethod) => {
      if (selectedMethod.randomId === method.randomId) {
        let updatedMethod = { ...selectedMethod };
        updatedMethod[key] = value;
        return updatedMethod;
      }
      return selectedMethod;
    });

    setSelectedMethods(updatedSelectedMethods);
  };

  const onImport = () => {
    const newInputApiConnector = {
      name: postmanCollection.info.name,
      description: postmanCollection.info.description
    };
    onImported(newInputApiConnector, selectedMethods);
    onHide();
  };

  const groups = methods.reduce((acc, method) => {
    if (!acc[method.group]) {
      acc[method.group] = {
        name: method.group,
        methods: []
      };
    }
    acc[method.group].methods.push(method);
    return acc;
  }, {});

  const methodConfiTabTitle = (tab) => {
    switch (tab) {
      case "parameters":
        return "Parameters";
      case "results":
        return "Results";
      case "headers":
        return "Headers";
      case "queries":
        return "Queries";
      default:
        return "";
    }
  };

  const getTabLinkClassName = (tab) => {
    if (tab === methodConfigTab) {
      return "text-white";
    }
    return "text-white-6";
  };

  const savedSelectedMethod = selectedMethods.find(
    (method) => method.randomId === selectedMethod?.randomId
  );

  const onSelectItemForMethod = (item, configKey, method) => {
    const savedSelectedMethod = selectedMethods.find(
      (method) => method.randomId === selectedMethod?.randomId
    );

    if (!savedSelectedMethod) {
      return;
    }

    const updatedMethod = {
      ...savedSelectedMethod
    };

    if (!updatedMethod[configKey]) {
      updatedMethod[configKey] = [];
    }

    updatedMethod[configKey].push(item);

    const updatedSelectedMethods = selectedMethods.map((selectedMethod) => {
      if (selectedMethod.randomId === method.randomId) {
        return updatedMethod;
      }
      return selectedMethod;
    });

    setSelectedMethods(updatedSelectedMethods);
  };

  const onUnselectItemForMethod = (item, configKey, method) => {
    const savedSelectedMethod = selectedMethods.find(
      (method) => method.randomId === selectedMethod?.randomId
    );

    if (!savedSelectedMethod) {
      return;
    }

    const updatedMethod = {
      ...savedSelectedMethod
    };

    if (!updatedMethod[configKey]) {
      updatedMethod[configKey] = [];
    }

    updatedMethod[configKey] = updatedMethod[configKey].filter(
      (selectedItem) => selectedItem.key !== item.key
    );

    const updatedSelectedMethods = selectedMethods.map((selectedMethod) => {
      if (selectedMethod.randomId === method.randomId) {
        return updatedMethod;
      }
      return selectedMethod;
    });

    setSelectedMethods(updatedSelectedMethods);
  };

  const canImport = () => {
    return selectedMethods.length > 0;
  };

  return (
    <Modal
      show={true}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      className="ModalImportInputApiConnector"
      fullscreen
    >
      <Modal.Header className="d-flex justify-content-end">
        {/* <Modal.Title>Import Input API Connector from Postman</Modal.Title> */}
        <div>
          <Button
            variant=""
            disabled={!canImport()}
            className="fw-bold fs-5 bg-cyan-1 text-white me-3"
            onClick={onImport}
          >
            Import
          </Button>
          <Button
            variant="link"
            className="fw-bold fs-5 text-white-9"
            onClick={onHide}
          >
            Cancel
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row className="h-100">
          <Col xs={4} className="d-flex flex-column h-100">
            <ImportFromPostmanBlock
              postmanCollection={postmanCollection}
              handleFileChange={handleFileChange}
            />
            <SelectPostManGroup
              groups={groups}
              selectedGroups={selectedGroups}
              onAddGroup={onAddGroup}
              onRemoveGroup={onRemoveGroup}
              selectedGroup={selectedGroup}
              setSelectedGroup={setSelectedGroup}
            />
          </Col>

          <Col xs={4} className="d-flex flex-column h-100">
            <SelectPostManMethods
              selectedGroup={selectedGroup}
              selectedMethods={selectedMethods}
              selectedMethod={selectedMethod}
              setSelectedMethod={setSelectedMethod}
              onAddMethod={onAddMethod}
              onRemoveMethod={onRemoveMethod}
            />
          </Col>

          <Col xs={4} className="d-flex flex-column h-100">
            <div>
              <h3 className="mb-3 fs-4 text-white-9 fw-bold">
                Select {methodConfiTabTitle(methodConfigTab)}
              </h3>
            </div>

            <div className="d-flex justify-content-end">
              <Button
                variant="link"
                className={`fw-bold text-white fs-6 p-0 ms-3 ${getTabLinkClassName(
                  "queries"
                )}`}
                onClick={() => setMethodConfigTab("queries")}
              >
                Queries
              </Button>
              <Button
                variant="link"
                className={`fw-bold text-white fs-6 p-0 ms-3 ${getTabLinkClassName(
                  "parameters"
                )}`}
                onClick={() => setMethodConfigTab("parameters")}
              >
                Parameters
              </Button>
              <Button
                variant="link"
                className={`fw-bold text-white fs-6 p-0 ms-3 ${getTabLinkClassName(
                  "results"
                )}`}
                onClick={() => setMethodConfigTab("results")}
              >
                Results
              </Button>
              <Button
                variant="link"
                className={`fw-bold text-white fs-6 p-0 ms-3 ${getTabLinkClassName(
                  "headers"
                )}`}
                onClick={() => setMethodConfigTab("headers")}
              >
                Headers
              </Button>
            </div>

            <div className="flex-grow-1">
              {methodConfigTab === "queries" && (
                <MethodConfigSelector
                  selectedMethod={selectedMethod}
                  savedSelectedMethod={savedSelectedMethod}
                  addMethod={onAddMethod}
                  configKey={"queries"}
                  selectItem={(item) =>
                    onSelectItemForMethod(item, "queries", selectedMethod)
                  }
                  unselectItem={(item) =>
                    onUnselectItemForMethod(item, "queries", selectedMethod)
                  }
                />
              )}
              {methodConfigTab === "parameters" && (
                <MethodConfigSelector
                  selectedMethod={selectedMethod}
                  savedSelectedMethod={savedSelectedMethod}
                  configKey={"parameters"}
                  addMethod={onAddMethod}
                  selectItem={(item) =>
                    onSelectItemForMethod(item, "parameters", selectedMethod)
                  }
                  unselectItem={(item) =>
                    onUnselectItemForMethod(item, "parameters", selectedMethod)
                  }
                />
              )}
              {methodConfigTab === "results" && (
                <MethodConfigSelector
                  selectedMethod={selectedMethod}
                  savedSelectedMethod={savedSelectedMethod}
                  configKey={"results"}
                  addMethod={onAddMethod}
                  selectItem={(item) =>
                    onSelectItemForMethod(item, "results", selectedMethod)
                  }
                  unselectItem={(item) =>
                    onUnselectItemForMethod(item, "results", selectedMethod)
                  }
                />
              )}
              {methodConfigTab === "headers" && (
                <MethodConfigSelector
                  selectedMethod={selectedMethod}
                  savedSelectedMethod={savedSelectedMethod}
                  configKey={"headers"}
                  addMethod={onAddMethod}
                  selectItem={(item) =>
                    onSelectItemForMethod(item, "headers", selectedMethod)
                  }
                  unselectItem={(item) =>
                    onUnselectItemForMethod(item, "headers", selectedMethod)
                  }
                />
              )}
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

const MethodConfigSelector = ({
  selectedMethod,
  configKey,
  savedSelectedMethod,
  onChange,
  selectItem,
  unselectItem,
  addMethod
}) => {
  if (!selectedMethod) {
    return (
      <div>
        <p className="text-white-6 mt-3">No method selected</p>
      </div>
    );
  }
  if (!savedSelectedMethod) {
    return (
      <div>
        <p className="text-white-6 mt-3">
          <span
            className="orange-1-selected fw-bold btn btn-link p-0"
            onClick={() => addMethod(selectedMethod)}
          >
            Add
          </span>{" "}
          this method to see the configuration options
        </p>
      </div>
    );
  }
  const { [configKey]: items } = selectedMethod;

  const isItemSelected = (item) => {
    return savedSelectedMethod[configKey].some((i) => i.key === item.key);
  };

  return (
    <div className="h-100">
      {items.length === 0 && (
        <div className="text-white-6 mt-3">
          No {configKey} found for this method
        </div>
      )}

      <ListGroup className="flex-column h-100" style={{ overflowY: "scroll" }}>
        {items.map((item) => (
          <ListGroup.Item
            key={item.key}
            className="d-flex align-items-center bg-dark-item-2 justify-content-between px-3 py-4 border-bottom-1-lc"
            style={{ borderRadius: 0 }}
            onClick={() => {}}
          >
            <div>
              <span className="fw-bold">{item.key}</span>
              {item.description && (
                <p className="mb-0 text-gray-5">{item.description}</p>
              )}
            </div>
            <div>
              <i
                onClick={(e) => {
                  e.stopPropagation();
                  if (isItemSelected(item)) {
                    unselectItem(item, configKey);
                  } else {
                    selectItem(item, configKey);
                  }
                }}
                className={`fas fs-4 fa-${
                  isItemSelected(item)
                    ? "check-square orange-3-selected"
                    : "square"
                }`}
              ></i>
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

const ImportFromPostmanBlock = ({ postmanCollection, handleFileChange }) => {
  const input = React.useRef(null);

  return (
    <div>
      <h3 className="fs-4 fw-bold text-white-9">Import from Postman</h3>
      <div className="mb">
        <Button
          variant="link"
          className="ps-0 text-cyan-1 fw-bold"
          onClick={() => input.current.click()}
        >
          <i className="fas fa-file-import"></i> Import a Postman collection
          file (.json)
        </Button>
        <input
          ref={input}
          type="file"
          style={{ display: "none" }}
          className="form-control"
          id="postmanCollectionFile"
          onChange={handleFileChange}
        />
      </div>
      <div className="" style={{ height: "100px", overflowY: "scroll" }}>
        {postmanCollection && (
          <>
            <p className="text-white-5 m-0">Selected file</p>
            <div>
              <span className="fs-16 fw-bold text-white-7">
                {postmanCollection.info.name}
              </span>
            </div>
            <div>
              <span className="ms text-white-5">
                {postmanCollection.info.description}
              </span>
            </div>
          </>
        )}
        {!postmanCollection && (
          <div className="text-white-5" style={{ width: "300px" }}>
            <i className="fas fa-info-circle"></i> No Postman collection file
            selected, click on "Import a Postman collection file" to select a
            file to import from. The file must be a .json file.
          </div>
        )}
      </div>
    </div>
  );
};

const SelectPostManGroup = ({
  groups,
  selectedGroups,
  onAddGroup,
  onRemoveGroup,
  selectedGroup,
  setSelectedGroup
}) => {
  const isGroupSelected = (group) => {
    return selectedGroups.find(
      (selectedGroup) => selectedGroup.name === group.name
    );
  };

  return (
    <div className="mt-4 mb-4 flex-grow-1 d-flex flex-column">
      <h3 className="fs-4 fw-bold text-white-9">Select Groups</h3>
      {(!groups || Object.keys(groups).length === 0) && (
        <div className="text-white-5" style={{ width: "300px" }}>
          <i className="fas fa-info-circle"></i> No groups found, import a file
          from Postman to get started.
        </div>
      )}
      <ListGroup
        className="mt-3 flex-grow-1 mb-0"
        style={{ width: "100%", height: "200px", overflowY: "scroll" }}
      >
        {Object.keys(groups).map((groupKey, i) => {
          const group = groups[groupKey];
          const isSelected = isGroupSelected(group);
          return (
            <ListGroup.Item
              active={selectedGroup && selectedGroup.name === group.name}
              key={i}
              className="d-flex align-items-center justify-content-between px-3 py-4"
              onClick={() => setSelectedGroup(group)}
            >
              <div>
                <span className="fw-bold">{group.name}</span>
              </div>
              <div className="">
                {isSelected ? (
                  <Button
                    variant="link"
                    className="fw-bold fs-5 p-0 text-white-5"
                    onClick={() => onRemoveGroup(group)}
                  >
                    <i className="bi bi-dash-circle-fill"></i>
                  </Button>
                ) : (
                  <Button
                    variant="link"
                    className="fw-bold fs-5 p-0 text-white-5"
                    onClick={() => onAddGroup(group)}
                  >
                    <i className="bi bi-plus-circle"></i>
                  </Button>
                )}
              </div>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </div>
  );
};

const SelectPostManMethods = ({
  selectedMethods,
  selectedMethod,
  selectedGroup,
  setSelectedMethod,
  onAddMethod,
  onRemoveMethod
}) => {
  if (!selectedGroup) {
    return (
      <div className="h-100 d-flex flex-column">
        <h3 className="fs-4 fw-bold text-white-9">Select Methods From Group</h3>
        <div>
          <p className="text-white-6 mt-3">No group selected</p>
        </div>
      </div>
    );
  }

  const isMethodSelected = (method) => {
    return selectedMethods.find(
      (selectedMethod) => selectedMethod.name === method.name
    );
  };

  return (
    <div className="h-100 d-flex flex-column">
      <h3 className="fs-4 fw-bold text-white-9">
        Select Methods From {selectedGroup.name}
      </h3>
      <ListGroup
        className="mt-3 flex-grow-1"
        style={{ width: "100%", overflowY: "scroll" }}
      >
        {selectedGroup.methods.map((method) => (
          <ListGroup.Item
            active={selectedMethod && selectedMethod.name === method.name}
            key={method.name}
            className="d-flex align-items-center justify-content-between px-3 py-4"
            onClick={() => setSelectedMethod(method)}
          >
            <div style={{ width: "87%" }}>
              <p className="fw-bold m-0 text-truncate">{method.name}</p>
              <p className="m-0 text-gray-5">{method.description}</p>
            </div>
            <div className="">
              {isMethodSelected(method) ? (
                <Button
                  variant="link"
                  className="fw-bold p-0 text-white-5 fs-5"
                  onClick={() => onRemoveMethod(method)}
                >
                  <i className="bi bi-dash-circle-fill"></i>
                </Button>
              ) : (
                <Button
                  variant="link"
                  className="fw-bold p-0 text-white-5 fs-5"
                  onClick={() => onAddMethod(method)}
                >
                  <i className="bi bi-plus-circle"></i>
                </Button>
              )}
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

const GLOBAL_PARAMETERS_TYPES = [
  {
    name: "String",
    label: "Text",
    value: "string"
  },
  {
    name: "String(Select)",
    label: "Select",
    value: "string-select"
  },
  {
    name: "Number",
    label: "Number",
    value: "number"
  },
  {
    name: "Integer",
    label: "Integer",
    value: "int"
  },
  {
    name: "Long",
    label: "Long",
    value: "long"
  },
  {
    name: "Boolean",
    label: "True/False",
    value: "boolean"
  },
  {
    name: "Array",
    label: "List",
    value: "array"
  },
  {
    name: "Object",
    label: "Object",
    value: "object"
  },
  {
    name: "Enum",
    label: "Enum",
    value: "enum"
  }
];

const extractVariables = (text) => {
  const variables = [];

  const regex = /{{(.*?)}}/g;
  let match;
  while ((match = regex.exec(text))) {
    variables.push(match[1]);
  }

  return variables;
};

const extractHeadersVariables = (headers) => {
  const variables = [];

  headers.forEach((header) => {
    const { value } = header;
    const valueVariables = extractVariables(value);
    if (variables.includes(value)) {
      return;
    }
    variables.push(...valueVariables);
  });

  return variables;
};

const InputApiConnectorForm = ({ inputApiConnector = {}, apiProjectId }) => {
  const [inputApiConnectorState, setInputApiConnectorState] =
    useState(inputApiConnector);
  const [importedUrlVariables, setImportedUrlVariables] = useState(
    inputApiConnectorState.importedUrlVariables || []
  );
  const [importedHeadersVariables, setImportedHeadersVariables] = useState(
    inputApiConnectorState.importedHeadersVariables || []
  );
  const [headers, setHeaders] = useState(inputApiConnectorState.headers || []);
  const shouldSave = React.useRef(false);

  useEffect(() => {
    if (inputApiConnector && inputApiConnector.id) {
      setInputApiConnectorState(inputApiConnector);
    } else {
      setInputApiConnectorState(inputApiConnector || {});
    }
  }, [inputApiConnector]);

  useEffect(() => {
    if (shouldSave.current) {
      autoSave();
      shouldSave.current = false;
    }
  }, [inputApiConnectorState]);

  useEffect(() => {
    if (inputApiConnectorState.baseUrl) {
      const variables = extractVariables(inputApiConnectorState.baseUrl);
      setImportedUrlVariables(variables);
    } else {
      setImportedUrlVariables([]);
    }
  }, [inputApiConnectorState.baseUrl]);

  useEffect(() => {
    if (headers) {
      const variables = extractHeadersVariables(headers);
      setImportedHeadersVariables(variables);
    } else {
      setImportedHeadersVariables([]);
    }
  }, [headers]);

  const onCreateInputApiConnector = async () => {
    try {
      const dbInstance = apiProjectId
        ? DB.apiProjects.sub.inputApiConnectors
        : DB.inputApiConnectors;
      const dbArgs = apiProjectId ? [apiProjectId] : [];

      const newId = await dbInstance.create(...dbArgs, inputApiConnectorState);
      const newInputApiConnector = {
        ...inputApiConnectorState,
        id: newId
      };
      setInputApiConnectorState(newInputApiConnector);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickableTextChange = (name, value) => {
    const updatedInputApiConnector = {
      ...inputApiConnectorState,
      [name]: value
    };
    setInputApiConnectorState(updatedInputApiConnector);
    shouldSave.current = true;
  };

  const autoSave = () => {
    if (inputApiConnectorState.id) {
      const dbInstance = apiProjectId
        ? DB.apiProjects.sub.inputApiConnectors
        : DB.inputApiConnectors;
      const dbArgs = apiProjectId ? [apiProjectId] : [];

      dbInstance.update(...dbArgs, inputApiConnectorState.id, {
        ...inputApiConnectorState,
        importedUrlVariables,
        importedHeadersVariables,
        headers
      });
    }
  };

  const modifyHeader = (i, key, value) => {
    const updatedHeaders = [...headers];
    updatedHeaders[i][key] = value;
    setHeaders(updatedHeaders);
  };

  const addHeader = () => {
    const updatedHeaders = [
      ...headers,
      {
        key: `header${headers.length + 1}`,
        value: ""
      }
    ];
    setHeaders(updatedHeaders);
  };

  const removeHeader = (key) => {
    const updatedHeaders = headers.filter((header) => header.key !== key);
    setHeaders(updatedHeaders);
  };

  const onBlur = () => {
    autoSave();
  };

  const onEnter = (e) => {
    if (e.key === "Enter") {
      autoSave();
    }
  };

  const importedVariables = [
    ...importedUrlVariables,
    ...importedHeadersVariables
  ];

  return (
    <div style={{ position: "relative" }} className="h-100 w-100">
      <Form className="InputApiConnectorForm form-header-block p-3 pb-1 ps-4">
        {/* form fields for inputApiConnector */}
        <Form.Group
          controlId="name"
          style={{ position: "relative" }}
          className="mb-4"
        >
          <ClickableTextInput
            placeholder="Name your API Connector"
            textclassname="fs-3 fw-bold"
            type="text"
            name="name"
            value={inputApiConnectorState.name || ""}
            onChange={(value) => handleClickableTextChange("name", value)}
          />
        </Form.Group>
        <Form.Group controlId="description" className="mb-4">
          <ClickableTextInput
            placeholder="Enter a description for the Input API Connector"
            type="text"
            name="description"
            value={inputApiConnectorState.description || ""}
            onChange={(value) =>
              handleClickableTextChange("description", value)
            }
          />
        </Form.Group>
        <Form.Group controlId="baseUrl" className="mb-3 d-flex flex-column">
          <Form.Label className="fs-5">Base URL</Form.Label>
          <ClickableTextInput
            placeholder="Enter the base URL of the API to connect to, using {{}} to add custom variables in the URL"
            textclassname="fs-5"
            type="text"
            name="baseUrl"
            value={inputApiConnectorState.baseUrl || ""}
            onChange={(value) => handleClickableTextChange("baseUrl", value)}
          />
          {importedVariables.length > 0 && (
            <div className="mt-4 fs-5 base-url-variables">
              <span className="fw-bold ">Global Imported Variables: </span>
              <span className="">{importedVariables.join(", ")}</span>
            </div>
          )}
        </Form.Group>
        <div className="horizontal-separator mb-2"></div>
      </Form>
      <div className="horizontal-separator mt-5"></div>
      <Row className="mt-3">
        <div className="mb-3">
          <h2 className="fs-4 fw-bold text-white-8">Global Header</h2>
          <p className="fs-5 text-white-5">
            Add headers to the API request,
            <br /> using {"{{}}"} to add custom variables in the header value
          </p>
        </div>
        {headers.length > 0 &&
          headers.map(({ key, value }, i) => (
            <Col xs={12} md={4} className="mb-3" key={key}>
              <div className="headers-api-connector p-2">
                <div className="header-key ps-3 d-flex align-items-center">
                  <ClickableTextInput
                    placeholder="Header Key"
                    textclassname="fs-5"
                    type="text"
                    name="headerKey"
                    value={key}
                    onChange={(textValue) => modifyHeader(i, "key", textValue)}
                  />
                </div>
                <div className="px-3">
                  <div className="horizontal-separator-3"></div>
                </div>
                <div className="header-value d-flex align-items-center ps-3">
                  <ClickableTextInput
                    placeholder="Header Value"
                    textclassname="fs-5"
                    type="text"
                    name="headerValue"
                    value={value}
                    onChange={(textValue) =>
                      modifyHeader(i, "value", textValue)
                    }
                  />
                </div>
                <div className="position-absolute top-0 end-0 p-2">
                  <DropdownButton
                    id="dropdown-basic-button"
                    title={
                      <i
                        className="fas fa-ellipsis-v
                    fs-5 text-gray-500"
                      ></i>
                    }
                    variant="link"
                    className="p-0"
                  >
                    <Dropdown.Item
                      onClick={() => removeHeader(key)}
                      className="text-danger"
                    >
                      Remove Header
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
            </Col>
          ))}
        <Col xs={12} md={4} className="mb-3">
          <div
            className="headers-api-connector d-flex flex-column align-items-center justify-content-center"
            onClick={addHeader}
            role="button"
          >
            <div>
              <i
                className="fas fa-plus-square
              fs-4 text-primary"
              ></i>
            </div>
            <p className="fs-5 m-0 text-primary">Add Header</p>
          </div>
        </Col>
      </Row>
      {!inputApiConnectorState.id && inputApiConnectorState.name && (
        <div className="d-flex justify-content-end mt-5 py-4 InputApiConnectorFormFooter">
          <Button
            variant="primary"
            onClick={onCreateInputApiConnector}
            size="lg"
          >
            Create
          </Button>
        </div>
      )}
    </div>
  );
};

const TablesTabContent = ({ items, fields, onChange, texts }) => {
  const [itemsState, setItemsState] = useState(items || []);
  const shouldSave = React.useRef(false);
  const fieldsObj = fields.reduce((acc, field) => {
    acc[field.itemKey] = field;
    return acc;
  }, {});

  useEffect(() => {
    if (items) {
      setItemsState(items);
    } else {
      setItemsState([]);
    }
  }, [items]);

  useEffect(() => {
    if (shouldSave.current) {
      onChange(itemsState);
      shouldSave.current = false;
    }
  }, [itemsState]);

  const modifyItemTextType = (i, key, value) => {
    const updatedItems = [...itemsState];
    updatedItems[i][key] = value;

    if (fieldsObj[key].saveOnChange) {
      shouldSave.current = true;
    }

    setItemsState(updatedItems);
  };

  const modityItemSelectType = (i, key, value) => {
    const updatedItems = [...itemsState];
    updatedItems[i][key] = value;
    shouldSave.current = true;
    setItemsState(updatedItems);
  };

  const addNewItem = () => {
    let updatedItems = [...itemsState];

    const addedItem = fields.reduce((acc, field) => {
      if (field.defaultValue) {
        if (typeof field.defaultValue === "function") {
          acc[field.itemKey] = field.defaultValue({ i: updatedItems.length });
          return acc;
        }
        acc[field.itemKey] = field.defaultValue;
        return acc;
      }
      acc[field.itemKey] = "";
      return acc;
    }, {});

    updatedItems.push(addedItem);

    setItemsState(updatedItems);
    onChange(updatedItems);
  };

  const removeItem = (i) => {
    const updatedItems = itemsState.filter((item, index) => index !== i);
    setItemsState(updatedItems);
    onChange(updatedItems);
  };

  const onBlur = () => {
    onChange(itemsState);
  };

  const onEnter = (e) => {
    if (e.key === "Enter") {
      onChange(itemsState);
    }
  };

  const totalCols = fields.reduce((acc, field) => {
    acc += field.colOptions.xs;
    return acc;
  }, 0);

  const lastCol = 12 - totalCols;

  return (
    <div className="parameters-tab-content h-100 d-flex flex-column">
      <div className="px-2" style={{ height: "calc(100% - 50px)" }}>
        <Row className="mb-0 bg-dark-item-1 p-3 border-radius-6">
          {fields.map(({ label, itemKey, colOptions }, i) => (
            <Col key={itemKey} {...colOptions} className="">
              <div className="d-flex align-items-center">
                <div className="text-gray-700 fw-bold fs-5">{label}</div>
              </div>
            </Col>
          ))}
        </Row>

        {itemsState.length === 0 && texts.emptyList && (
          <div className="d-flex text-center justify-content-center align-items-center h-100">
            <p className="text-white-6">{texts.emptyList}</p>
          </div>
        )}

        {itemsState.length > 0 &&
          itemsState.map((item, i) => (
            <Row
              className={`${
                i % 2 !== 0 && "bg-dark-item-1"
              } p-2 border-radius-6`}
              key={`item-${i}`}
            >
              {fields.map(
                (
                  {
                    label,
                    itemKey,
                    colOptions,
                    type: fieldType,
                    iconClassName,
                    selectList,
                    formProps
                  },
                  iFields
                ) => (
                  <Col key={itemKey} {...colOptions} className="">
                    {fieldType === "text" && (
                      <Form.Control
                        className="fs-5 fw-bold bg-transparent text-white-9 ph-text-white-9 border-0 ps-0 text-area-clean"
                        name={itemKey}
                        placeholder={label}
                        value={item[itemKey] || ""}
                        onChange={(e) =>
                          modifyItemTextType(i, itemKey, e.target.value)
                        }
                        onBlur={onBlur}
                        {...formProps}
                      />
                    )}
                    {fieldType === "select" && (
                      <Form.Control
                        className="input-transparent fs-5 fw-bold bg-transparent border-0 ps-0"
                        as="select"
                        name={itemKey}
                        value={item[itemKey] || ""}
                        onChange={(e) =>
                          modityItemSelectType(i, itemKey, e.target.value)
                        }
                        {...formProps}
                      >
                        {selectList.map((select) => (
                          <option key={select.value} value={select.value}>
                            {select.label}
                          </option>
                        ))}
                      </Form.Control>
                    )}
                    {fieldType === "check" && (
                      <div
                        className="d-flex align-items-center justify-content-center
                  h-100"
                      >
                        <i
                          role="button"
                          onClick={() =>
                            modifyItemTextType(i, itemKey, !item[itemKey])
                          }
                          className={`${iconClassName} fs-5 ${
                            item[itemKey] ? "orange-3-selected" : "text-white-4"
                          }`}
                        ></i>
                      </div>
                    )}
                  </Col>
                )
              )}
              <Col xs={lastCol} className="">
                <div className="d-flex justify-content-end align-items-center">
                  {/* Three dots drowpdown actions */}
                  <DropdownButton
                    id="dropdown-basic-button"
                    title={
                      <i
                        className="fas fa-ellipsis-v
                    fs-5 text-gray-500"
                        style={{ position: "relative", top: "4px" }}
                      ></i>
                    }
                    variant="link"
                    className="p-0"
                  >
                    <Dropdown.Item
                      onClick={() => removeItem(i)}
                      className="text-danger"
                    >
                      {texts.removeItem || "Remove Item"}
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </Col>
            </Row>
          ))}
      </div>
      <div className="horizontal-separator flex-grow-1"></div>
      <Row className="flex-grow-1 mt">
        <Col xs={12} className="justify-content-end d-flex py-2">
          <Button
            variant="link-secondary"
            className="pe-0"
            onClick={addNewItem}
            size="lg"
          >
            <span className="fs-5 text-white-8 fw-bold">
              {texts.addNewItem || "Add Item"}
            </span>
          </Button>
        </Col>
      </Row>
    </div>
  );
};

const parametersFields = [
  {
    label: "Key",
    itemKey: "key",
    colOptions: { xs: 3 },
    type: "text",
    defaultValue: ({ i }) => `parameter${i + 1}`
  },
  {
    label: "Value",
    itemKey: "value",
    colOptions: { xs: 4 },
    type: "text",
    formProps: {
      as: "textarea",
      rows: 1
    }
  },
  {
    label: "Type",
    itemKey: "type",
    colOptions: { xs: 2 },
    type: "select",
    selectList: GLOBAL_PARAMETERS_TYPES,
    saveOnChange: true
  },
  {
    label: "Mandatory",
    itemKey: "isRequired",
    colOptions: { xs: 2 },
    type: "check",
    iconClassName: "fas fa-check-circle",
    saveOnChange: true
  }
];

const resultsFields = [
  {
    label: "Key",
    itemKey: "key",
    colOptions: { xs: 3 },
    type: "text"
  },
  {
    label: "Path",
    itemKey: "path",
    colOptions: { xs: 4 },
    type: "text"
  },
  {
    label: "Type",
    itemKey: "resultType",
    colOptions: { xs: 2 },
    type: "select",
    saveOnChange: true,
    selectList: [
      {
        label: "Text",
        value: "string"
      },
      {
        label: "Number",
        value: "number"
      },
      {
        label: "Boolean",
        value: "boolean"
      },
      {
        label: "JSON",
        value: "json"
      },
      {
        label: "JSON Array",
        value: "json-array"
      }
    ]
  }
];

const queriesFields = [
  {
    label: "Key",
    itemKey: "key",
    colOptions: { xs: 3 },
    type: "text"
  },
  {
    label: "Value",
    itemKey: "value",
    colOptions: { xs: 5 },
    type: "text",
    formProps: {
      as: "textarea",
      rows: 1
    }
  },
  {
    label: "Mandatory",
    itemKey: "isRequired",
    colOptions: { xs: 3 },
    type: "check",
    iconClassName: "fas fa-check-circle",
    saveOnChange: true
  }
];

const headersFields = [
  {
    label: "Key",
    itemKey: "key",
    colOptions: { xs: 3 },
    type: "text"
  },
  {
    label: "Value",
    itemKey: "value",
    colOptions: { xs: 8 },
    type: "text",
    formProps: {
      as: "textarea",
      rows: 1
    }
  }
];

const extractPathVariables = (path) => {
  return extractVariables(path);
};

const extractParametersVariables = (parameters) => {
  const variables = [];

  parameters.forEach((parameter) => {
    const { value } = parameter;
    const valueVariables = extractVariables(value);
    if (variables.includes(value)) {
      return;
    }
    variables.push(...valueVariables);
  });

  return variables;
};

const extractParametersVariablesTypes = (parameters) => {
  const variables = [];

  parameters.forEach((parameter) => {
    const { value, type, isRequired } = parameter;
    const valueVariables = extractVariables(value);
    if (variables.includes(value)) {
      return;
    }
    valueVariables.forEach((valueVariable) => {
      variables.push({
        key: valueVariable,
        type,
        isRequired
      });
    });
  });

  return variables;
};

const MethodForm = ({
  apiProjectId,
  method,
  inputApiConnector,
  onCreated,
  groups
}) => {
  const methodFormRef = React.useRef();
  const [methodState, setMethodState] = useState(method);
  const [showTestModal, setShowTestModal] = useState(false);
  const { baseUrl } = inputApiConnector;
  const isNew = !methodState.id;
  const shouldSave = React.useRef(false);
  // result

  useEffect(() => {
    if (method && method.id) {
      setMethodState(method);
    } else if (method) {
      setMethodState(method);
    } else {
      setMethodState({});
    }
  }, [method]);

  useEffect(() => {
    if (shouldSave.current) {
      autoSave();
      shouldSave.current = false;
    }
  }, [methodState]);

  const onCreateMethod = async () => {
    try {
      const dbInstance = apiProjectId
        ? DB.apiProjects.sub.inputApiConnectors
        : DB.inputApiConnectors;
      const dbArgs = apiProjectId
        ? [apiProjectId, inputApiConnector.id]
        : [inputApiConnector.id];

      const newId = await dbInstance.sub.methods.create(...dbArgs, {
        ...methodState
      });
      const newMethod = {
        ...methodState,
        id: newId
      };
      setMethodState(newMethod);
      onCreated && onCreated(newMethod);
    } catch (error) {
      console.error(error);
    }
  };

  const handleManyChange = (...events) => {
    const updatedMethod = events.reduce(
      (acc, e) => {
        acc = updateMethod(e, acc);
        return acc;
      },
      { ...methodState }
    );

    setMethodState(updatedMethod);
  };

  const handleChange = (e) => {
    const updated = updateMethod(e, methodState);
    setMethodState(updated);
  };

  const updateMethod = (e, methodState) => {
    let updatedMethod = {
      ...methodState,
      [e.target.name]: e.target.value
    };

    if (
      [
        "httpMethod",
        "results",
        "runTransformScript",
        "transformScript",
        "transformScriptLanguage",
        "runTransformScript_params",
        "transformScript_params",
        "transformScriptLanguage_params"
      ].includes(e.target.name)
    ) {
      shouldSave.current = true;
    }

    if (["path"].includes(e.target.name)) {
      const variables = extractPathVariables(e.target.value);
      updatedMethod = {
        ...updatedMethod,
        importedPathVariables: variables.map((variable) => ({
          key: variable,
          type: "string"
        }))
      };
    }

    if (["parameters"].includes(e.target.name)) {
      const variables = extractParametersVariablesTypes(e.target.value);
      updatedMethod = {
        ...updatedMethod,
        importedParametersVariables: variables
      };
      shouldSave.current = true;
    }

    if (["queries"].includes(e.target.name)) {
      const variables = extractParametersVariables(e.target.value);
      updatedMethod = {
        ...updatedMethod,
        importedQueriesVariables: variables.map((variable) => ({
          key: variable,
          type: "string"
        }))
      };
      shouldSave.current = true;
    }

    if (["headers"].includes(e.target.name)) {
      const variables = extractHeadersVariables(e.target.value);
      updatedMethod = {
        ...updatedMethod,
        importedHeadersVariables: variables.map((variable) => ({
          key: variable,
          type: "string"
        }))
      };
      shouldSave.current = true;
    }

    // setMethodState(updatedMethod);
    return updatedMethod;
  };

  const canSave = () => {
    return !!methodState.name && !!methodState.path && !!methodState.httpMethod;
  };

  const autoSave = () => {
    if (methodState.id) {
      const dbInstance = apiProjectId
        ? DB.apiProjects.sub.inputApiConnectors
        : DB.inputApiConnectors;
      const dbArgs = apiProjectId
        ? [apiProjectId, inputApiConnector.id]
        : [inputApiConnector.id];

      const updates = {
        importedPathVariables: methodState.importedPathVariables || [],
        importedParametersVariables:
          methodState.importedParametersVariables || [],
        importedQueriesVariables: methodState.importedQueriesVariables || [],
        importedHeadersVariables: methodState.importedHeadersVariables || []
      };

      dbInstance.sub.methods.update(...dbArgs, methodState.id, {
        ...methodState,
        ...updates
      });
    }
  };

  const onBlur = () => {
    autoSave();
  };

  const onEnter = (e) => {
    if (e.key === "Enter") {
      autoSave();
      // blur the input
      e.target.blur();
    }
  };

  const showParametersTab =
    methodState.httpMethod === "POST" || methodState.httpMethod === "PUT";

  return (
    <div
      ref={methodFormRef}
      style={{ position: "relative" }}
      className="h-100 w-100 d-flex flex-column"
    >
      {showTestModal && (
        <ModalTestApi
          inputApiConnector={inputApiConnector}
          method={methodState}
          onClose={() => setShowTestModal(false)}
          onMethodChange={handleManyChange}
        />
      )}

      <Form className="MethodForm form-header-block p-3 pb-1 ps-4">
        <Form.Group controlId="name" className="mb-4">
          <Form.Control
            className="input-transparent fs-3 fw-bold"
            type="text"
            name="name"
            placeholder="Name your method"
            value={methodState.name}
            onChange={handleChange}
            onBlur={onBlur}
            onKeyPress={onEnter}
          />
        </Form.Group>
        <Form.Group controlId="path" className="mb-4">
          <Form.Label className="fs-5 fw-bold">Path</Form.Label>
          <div className="d-flex align-items-center w-100">
            <div className="text-gray-700 fw-bold fs-5">{baseUrl}</div>
            <Form.Control
              className="input-transparent fs-5 fw-bold"
              type="text"
              name="path"
              placeholder="Enter the path of the API endpoint, relative to the Base URL"
              value={methodState.path}
              onChange={handleChange}
              onBlur={onBlur}
              onKeyPress={onEnter}
            />
          </div>
        </Form.Group>

        <Form.Group controlId="httpMethod">
          <Form.Label className="fw-bold fs-5">HTTP Method</Form.Label>
          <Form.Control
            as="select"
            name="httpMethod"
            className="input-transparent fs-5 text-white orange-2 fw-bold"
            value={methodState.httpMethod}
            onChange={handleChange}
          >
            <option>GET</option>
            <option>POST</option>
            <option>PUT</option>
            <option>DELETE</option>
          </Form.Control>

          {/* Group */}
          <ReactAutocomplete
            placeholder="Group"
            className="group-autocomplete"
            classNameInput="fs-5 fw-bold mt-4 p-2"
            items={groups || []}
            onBlur={onBlur}
            shouldItemRender={(group, value) => {
              return group.toLowerCase().indexOf(value.toLowerCase()) > -1;
            }}
            getItemValue={(group) => group}
            renderItem={(group, highlighted) => (
              <div
                key={group.id}
                style={{
                  backgroundColor: highlighted ? "#eee" : "transparent"
                }}
              >
                {group}
              </div>
            )}
            value={methodState.group || ""}
            onChange={(event) => {
              handleChange({
                target: {
                  name: "group",
                  value: event.target.value
                }
              });
            }}
            onSelect={(group) => {
              handleChange({
                target: {
                  name: "group",
                  value: group
                }
              });
            }}
          />

          <hr />
        </Form.Group>

        <div className="d-flex justify-content-end mb-3">
          <Button
            variant="outline-primary"
            className="fs-5 fw-bold"
            onClick={() => {
              setShowTestModal(true);
            }}
          >
            Test API Method
          </Button>
        </div>
      </Form>

      <div className="input-api-connector-body-background flex-grow-1 p-4 pb-0">
        <Tab.Container
          id="left-tabs-methods"
          defaultActiveKey="queries"
          className="h-100"
        >
          <Row className="h-100">
            <Col xs={12} md={3}>
              {/* Left Nav */}
              <Nav className="flex-column body-tab h-100">
                <Nav.Item className="mb-3">
                  <Nav.Link className="fs-5 text-gray-500" eventKey="queries">
                    Queries
                  </Nav.Link>
                  <p className="fs-14 mt-2 text-gray-700">
                    Add queries to the API request
                  </p>
                </Nav.Item>

                {showParametersTab && (
                  <Nav.Item className="mb-3">
                    <Nav.Link
                      className="fs-5 text-gray-500"
                      eventKey="parameters"
                    >
                      Parameters
                    </Nav.Link>
                    <p className="fs-14 mt-2 text-gray-700">
                      Add parameters to the API request
                    </p>
                  </Nav.Item>
                )}
                <Nav.Item className="mb-3">
                  <Nav.Link className="fs-5 text-gray-500" eventKey="results">
                    Results
                  </Nav.Link>
                  <p className="fs-14 mt-2 text-gray-700">
                    Export from the API response
                  </p>
                </Nav.Item>
                <Nav.Item className="mb-3">
                  <Nav.Link className="fs-5 text-gray-500" eventKey="headers">
                    Headers
                  </Nav.Link>
                  <p className="fs-14 mt-2 text-gray-700">
                    Add headers to the API request
                  </p>
                </Nav.Item>
              </Nav>
            </Col>
            <Col xs={12} md={9}>
              <Tab.Content className="h-100">
                <Tab.Pane eventKey="parameters" className="h-100">
                  <TablesTabContent
                    items={methodState.parameters || []}
                    fields={parametersFields}
                    onChange={(parameters) => {
                      handleChange({
                        target: {
                          name: "parameters",
                          value: parameters
                        }
                      });
                    }}
                    texts={{
                      addNewItem: "Add Parameter",
                      removeItem: "Remove Parameter"
                    }}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="results" className="h-100">
                  <TablesTabContent
                    items={methodState.results || []}
                    fields={resultsFields}
                    onChange={(results) => {
                      handleChange({
                        target: {
                          name: "results",
                          value: results
                        }
                      });
                    }}
                    texts={{
                      addNewItem: "Add Result",
                      removeItem: "Remove Result",
                      emptyList: !!methodState.runTransformScript ? (
                        <span>
                          {/* link button with primary color */}
                          <Button
                            variant="link"
                            className="p-0 m-0"
                            onClick={() => {
                              setShowTestModal(true);
                            }}
                          >
                            A transform script
                          </Button>{" "}
                          is set.
                        </span>
                      ) : (
                        'You can configure how the response will be handled by clicking on "Test Api Method", then you can transform the response in a more convenient way(deep object, lists, lists of lists). Or you can add a result manually by selecting response\'s keys(convenient for simple JSON objects).'
                      )
                    }}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="queries" className="h-100">
                  <TablesTabContent
                    items={methodState.queries || []}
                    fields={queriesFields}
                    onChange={(queries) => {
                      handleChange({
                        target: {
                          name: "queries",
                          value: queries
                        }
                      });
                    }}
                    texts={{
                      addNewItem: "Add Query",
                      removeItem: "Remove Query"
                    }}
                  />
                </Tab.Pane>

                <Tab.Pane eventKey="headers" className="h-100">
                  <TablesTabContent
                    items={methodState.headers || []}
                    fields={headersFields}
                    onChange={(headers) => {
                      handleChange({
                        target: {
                          name: "headers",
                          value: headers
                        }
                      });
                    }}
                    texts={{
                      addNewItem: "Add Header",
                      removeItem: "Remove Header"
                    }}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
      {isNew && (
        <div className="d-flex justify-content-end mt-5 py-4 InputApiConnectorFormFooter">
          <Button
            variant="primary"
            onClick={onCreateMethod}
            size="lg"
            disabled={!canSave()}
          >
            Create
          </Button>
        </div>
      )}
    </div>
  );
};

const getMethodsByGroup = (methods) => {
  const methodsByGroup = {};

  methods.forEach((method) => {
    const group = method.group || "ungrouped";
    if (!methodsByGroup[group]) {
      methodsByGroup[group] = [];
    }
    methodsByGroup[group].push(method);
  });

  return methodsByGroup;
};

const LeftMenu = ({
  inputApiConnector,
  methods,
  onSelectMethod,
  selectedMethod,
  onImported
}) => {
  const [importPostmanModal, setImportPostmanModal] = useState(false);
  const isSaved = !!inputApiConnector.id;

  const methodsByGroup = getMethodsByGroup(methods);
  const groups = Object.keys(methodsByGroup);

  return (
    <>
      {importPostmanModal && (
        <ImportInputApiConnectorModal
          onImported={onImported}
          inputApiConnector={inputApiConnector}
          onHide={() => {
            setImportPostmanModal(false);
          }}
          onCreateInputApiConnector={(inputApiConnector) => {
            setImportPostmanModal(false);
            onSelectMethod(null);
          }}
        />
      )}

      <Nav
        className="flex-column InputApiConnectorNav pt-4 px-3 d-flex"
        style={{ overflowY: "auto" }}
      >
        <Nav.Item>
          <Nav.Link
            className={`fs-4 text-gray-300 mb-0 fw-bold`}
            disabled
            onClick={() => {
              onSelectMethod(null);
            }}
          >
            {inputApiConnector.name || "Input API Connector"}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            active={!selectedMethod}
            className="fs-5"
            onClick={() => {
              onSelectMethod(null);
            }}
          >
            {isSaved ? (
              <span>
                <i className="fas fa-edit me-2"></i>
                Edit
              </span>
            ) : (
              <span>
                <i className="fas fa-plus me-2"></i>
                Create
              </span>
            )}
          </Nav.Link>
        </Nav.Item>
        <NavDropdown
          title={
            <span className="fs-5 text-gray-500">
              <i className="fas fa-file-import me-2"></i> Import
            </span>
          }
          id="nav-dropdown"
        >
          <NavDropdown.Item
            onClick={() => {
              setImportPostmanModal(true);
            }}
          >
            Postman
          </NavDropdown.Item>
        </NavDropdown>

        <hr className="mt-1 mb-4" />
        <Nav.Item>
          <Nav.Link className={`fs-4 text-gray-300 mb-0 fw-bold`} disabled>
            Methods
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            className="fs-5 text-gray-500"
            active={
              selectedMethod && !selectedMethod.id && !selectedMethod.group
            }
            disabled={!isSaved}
            onClick={() => {
              onSelectMethod({ id: "new" });
            }}
          >
            <i className="fas fa-plus me-2"></i>
            New
          </Nav.Link>
        </Nav.Item>

        <hr className="mt-1 mb-1" />

        <Nav.Item className="flex-grow-1 overflow-auto" position="relative">
          <Accordion defaultActiveKey="0" className="nav-accordion">
            {groups.map((group, i) => [
              <Accordion.Item eventKey="0" key={`group-${i}`}>
                <Accordion.Header className="">
                  {" "}
                  <i
                    className="fas fa-layer-group
              fs-5 me-2"
                  ></i>
                  <span className="fs-5 text-white-8 mb-0 fw-bold">
                    {group}
                  </span>
                </Accordion.Header>
                <Accordion.Body className="pt-0">
                  {group !== "ungrouped" && (
                    <Nav.Item>
                      <Nav.Link
                        className="fs-5 text-gray-500 pt-0"
                        active={
                          selectedMethod &&
                          !selectedMethod.id &&
                          selectedMethod.group === group
                        }
                        disabled={!isSaved}
                        onClick={() => {
                          onSelectMethod({ id: "new" }, group);
                        }}
                      >
                        <i className="fas fa-plus me-2"></i>
                        New
                      </Nav.Link>
                    </Nav.Item>
                  )}

                  {methodsByGroup[group].map((method) => (
                    <Nav.Item key={method.id || method.randomId}>
                      <Nav.Link
                        className="mb-2 text-gray-500 pt-0 d-flex align-items-start"
                        active={
                          selectedMethod && selectedMethod.id === method.id
                        }
                        onClick={() => {
                          onSelectMethod(method, group);
                        }}
                      >
                        <Badge
                          className={`me-2 fs-6 fw-bold request-${method.httpMethod}`}
                          style={{ position: "relative", top: "-2px" }}
                        >
                          {method.httpMethod}
                        </Badge>{" "}
                        <span className="fs-6">{method.name}</span>
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            ])}
          </Accordion>
        </Nav.Item>
      </Nav>
    </>
  );
};

const InputApiConnector = ({
  apiProjectId,
  existingInputApiConnector,
  connectorId,
  onHide,
  isFromShared
}) => {
  const [savingImport, setSavingImport] = useState(false);
  const [inputApiConnector, setInputApiConnector] = useState(
    existingInputApiConnector || {}
  );
  const [methods, setMethods] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const isNew = !inputApiConnector.id;

  useEffect(() => {
    if (inputApiConnector.id) {
      const dbInstance = apiProjectId
        ? DB.apiProjects.sub.inputApiConnectors
        : DB.inputApiConnectors;
      const dbArgs = apiProjectId ? [apiProjectId] : [];
      const lastArgs = isFromShared
        ? [
            null,
            null,
            null,
            {
              isFromShared: true
            }
          ]
        : [];

      const unsubscribe = dbInstance.sub.methods.list(
        ...dbArgs,
        inputApiConnector.id,
        (methods) => {
          setMethods(methods);
        },
        ...lastArgs
      );
      return () => unsubscribe();
    }
  }, [inputApiConnector.id]);

  useEffect(() => {
    if (connectorId) {
      const dbInstance = apiProjectId
        ? DB.apiProjects.sub.inputApiConnectors
        : DB.inputApiConnectors;
      const dbArgs = apiProjectId ? [apiProjectId] : [];

      dbInstance.get(...dbArgs, connectorId, (inputApiConnector) => {
        setInputApiConnector(inputApiConnector);
      });
    }
  }, [connectorId]);

  const onSelectedMethodChange = (method, group) => {
    if (!method) {
      setSelectedMethod(null);
      return;
    }

    if (method.id === "new") {
      const newMethod = {
        name: "",
        path: "",
        httpMethod: "GET",
        parameters: [],
        importedParameters: [],
        exportedResults: [],
        group: group || null
      };
      setSelectedMethod(newMethod);
    } else {
      setSelectedMethod(method);
    }
  };

  const onImported = async (newInputApiConnector, newMethods) => {
    setSavingImport(true);

    try {
      let inputApiConnectorId = newInputApiConnector.id;

      const dbInstance = apiProjectId
        ? DB.apiProjects.sub.inputApiConnectors
        : DB.inputApiConnectors;
      const dbArgs = apiProjectId ? [apiProjectId] : [];

      if (isNew) {
        inputApiConnectorId = await dbInstance.create(
          ...dbArgs,
          newInputApiConnector
        );
      }

      let i = 0;

      for (const method of newMethods) {
        const methodId = await dbInstance.sub.methods.create(
          ...dbArgs,
          inputApiConnectorId,
          method
        );
        i++;
      }

      if (isNew) {
        setInputApiConnector({
          ...newInputApiConnector,
          id: inputApiConnectorId
        });
      }
      setSavingImport(false);
    } catch (e) {
      console.log(e);
      setSavingImport(false);
    }
  };

  const handleOnCreatedMethod = (method) => {
    setSelectedMethod(method);
  };

  const handleChange = (e) => {
    setInputApiConnector({
      ...inputApiConnector,
      [e.target.name]: e.target.value
    });
  };

  const groups = methods.reduce((groups, method) => {
    const group = method.group || "ungrouped";
    if (!groups.includes(group)) {
      groups.push(group);
    }
    return groups;
  }, []);

  return (
    <>
      {savingImport && (
        <div
          className="d-flex justify-content-center align-items-center position-fixed top-0 start-0 w-100 h-100"
          style={{ background: "rgba(0,0,0,0.5)", zIndex: 9999 }}
        >
          <Spinner animation="border" />
          <span className="ms-2">Importing...</span>
        </div>
      )}
      <div className="InputApiConnectorHeaderModal mb-0 p-30 d-flex justify-content-between align-items-center">
        <h1 className="fs-1 fw-bold m-0">
          {inputApiConnector.id ? "Edit" : "Create"} Input API Connector
        </h1>
        <Nav className="justify-content-end">
          <Nav.Item>
            <Nav.Link
              className="fs-5 text-gray-500"
              onClick={() => {
                onHide();
              }}
            >
              Cancel
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      <div className="d-flex InputApiConnectorsBody pe-30 ps-30">
        <LeftMenu
          onImported={onImported}
          inputApiConnector={inputApiConnector}
          methods={methods}
          selectedMethod={selectedMethod}
          onSelectMethod={onSelectedMethodChange}
        />
        <div
          className="flex-grow-1 middle-content"
          style={{ position: "relative" }}
        >
          {selectedMethod ? (
            <MethodForm
              apiProjectId={apiProjectId}
              groups={groups}
              onCreated={handleOnCreatedMethod}
              inputApiConnector={inputApiConnector}
              method={selectedMethod}
              onChange={(method) => {
                const updatedMethods = methods.map((m) =>
                  m.id === method.id ? method : m
                );
                setMethods(updatedMethods);
              }}
            />
          ) : (
            <InputApiConnectorForm
              apiProjectId={apiProjectId}
              inputApiConnector={inputApiConnector}
              onChange={setInputApiConnector}
            />
          )}
        </div>
      </div>
    </>
  );
};

export const InputApiConnectorModal = ({
  show,
  onHide,
  onSave,
  inputApiConnector,
  connectorId,
  apiProjectId,
  isFromShared
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      fullscreen
      className="InputApiConnectorModal"
    >
      <Modal.Body>
        <InputApiConnector
          apiProjectId={apiProjectId}
          onSave={onSave}
          onHide={onHide}
          existingInputApiConnector={inputApiConnector}
          connectorId={connectorId}
          isFromShared={isFromShared}
        />
      </Modal.Body>
    </Modal>
  );
};

export default InputApiConnector;
