import React, { createContext, useState } from "react";

/**
 * SystemBuilderInterfaceContext
 * @description
 * This context is used to provide the systemBuilder data to the SystemBuilder component.
 */

export const SystemBuilderInterfaceContext = createContext();

export const SystemBuilderInterfaceProvider = ({ children }) => {
  const [builderViewPort, setBuilderViewPort] = useState({
    x: 0,
    y: 0,
    zoom: 1
  });

  const value = {
    builderViewPort,
    setBuilderViewPort
  };

  return (
    <SystemBuilderInterfaceContext.Provider value={value}>
      {children}
    </SystemBuilderInterfaceContext.Provider>
  );
};
