import React, { useContext, useEffect, useRef, useState } from "react";
import { ApiBuilderContext } from "../../../../../contexts/ApiBuilderContext";
import { FaCircle, FaAngleUp, FaAngleDown } from "react-icons/fa";
import "./EndpointItem.css";
import EndpointDetails from "./EndpointDetails";
import MiddlewareItem from "./MiddlewareItem";
import { DraggableAPIComponent } from "./ApiModelConstructionZone";

const computeBlocks = [
  "promptBlock",
  "inputConnection",
  "emitterFetch",
  "emitterLogic",
  "emitter"
];

const selectableBlockTypes = [...computeBlocks];

const alwaysActivatedDependenciesParams = ["inputBlock", "globalParameter"];

const EndpointItem = ({
  component: componentProp,
  onComponentChange,
  isDragging,
  draggbaleActions,
  onRemoveComponent
}) => {
  const [seeDetails, setSeeDetails] = useState(false);
  const [component, setComponent] = useState(componentProp);
  const { systemOverview, chains } = useContext(ApiBuilderContext);
  const [selectedChain, setSelectedChain] = useState(
    component.selectedChain || ""
  );
  const [activatedDependenciesParams, setActivatedDependenciesParams] =
    useState(component.activatedDependenciesParams || {});
  const [selectedBlock, setSelectedBlock] = useState(
    component.selectedBlock || ""
  );
  const shouldUpdate = useRef(false);

  const chainExists = !!systemOverview[selectedChain];

  const selectedOverview =
    chainExists && selectedBlock
      ? systemOverview[selectedChain][selectedBlock]
      : {};
  const userInputs = selectedOverview?.userInputs || [];
  const systemOutputs = selectedOverview?.systemOutputs || [];
  const dependencies = selectedOverview?.dependencies || [];
  const allIO = [...userInputs, ...systemOutputs, ...dependencies];

  useEffect(() => {
    const newComponent = { ...component };
    if (selectedChain) {
      newComponent.selectedChain = selectedChain;
    }
    if (selectedBlock) {
      newComponent.selectedBlock = selectedBlock;
    }
    if (activatedDependenciesParams) {
      newComponent.activatedDependenciesParams = activatedDependenciesParams;
    }
    shouldUpdate.current = true;
    setComponent(newComponent);
    // onComponentChange(newComponent);
  }, [selectedChain, selectedBlock, activatedDependenciesParams]);

  useEffect(() => {
    if (shouldUpdate.current) {
      shouldUpdate.current = false;
      onComponentChange(component);
      return;
    }
  }, [component]);

  useEffect(() => {
    if (componentProp) {
      setComponent(componentProp);
    }
  }, [componentProp]);

  useEffect(() => {
    if (selectedBlock) {
      const newActivatedDependenciesParams = {};
      allIO.forEach((item) => {
        const currentActivatedDependenciesParams =
          activatedDependenciesParams[item.key];

        if (typeof currentActivatedDependenciesParams === "boolean") {
          newActivatedDependenciesParams[item.key] =
            currentActivatedDependenciesParams;
          return;
        }

        if (alwaysActivatedDependenciesParams.includes(item.blockType)) {
          activatedDependenciesParams[item.key] = true;
        } else {
          activatedDependenciesParams[item.key] = false;
        }
      });
      setActivatedDependenciesParams(activatedDependenciesParams);
    }
  }, [selectedBlock]);

  const handleChange = (field, value) => {
    setComponent({ ...component, [field]: value });
    // onComponentChange({ ...component, [field]: value });
  };

  const handleSelectChange = (field, value) => {
    shouldUpdate.current = true;
    setComponent({ ...component, [field]: value });
  };

  const onBlur = () => {
    shouldUpdate.current = true;
    onComponentChange(component);
  };

  const renderTag = (item, index) => (
    <span
      key={index}
      className={`tag ${item.blockType} ${item.isInput ? "input" : "output"}`}
    >
      {item.key}
    </span>
  );

  const renderDependencyTag = (item, index) => {
    const isActivated = activatedDependenciesParams[item.key];
    const isComputeBlock = computeBlocks.includes(item.blockType);

    return (
      <span
        key={index}
        className={`tag ${item.blockType} ${
          item.isInput ? "input" : "output"
        } ${isActivated ? "activated" : "deactivated"}`}
        onClick={() => handleToggleActivatedDependenciesParams(item)}
      >
        {item.key}{" "}
        {isComputeBlock && <FaCircle className="compute-block-icon" />}
      </span>
    );
  };

  const renderMiddlewares = () => {
    return component.middlewares?.map((middleware, index) => {
      // Trouver le middleware dans la liste des components
      return (
        <DraggableAPIComponent
          type={middleware.type}
          key={index}
          id={middleware.id}
          index={index}
          className="middleware-item-parent-draggable"
          {...draggbaleActions}
        >
          {(isDragging) => {
            return (
              <MiddlewareItem
                onRemoveComponent={onRemoveComponent}
                component={middleware}
                onComponentChange={onComponentChange}
              />
            );
          }}
        </DraggableAPIComponent>
      );
    });
  };

  const renderNoTag = (text) => <span className="tag no-tag">{text}</span>;

  const handleToggleActivatedDependenciesParams = (item) => {
    if (alwaysActivatedDependenciesParams.includes(item.blockType)) {
      return;
    }

    setActivatedDependenciesParams({
      ...activatedDependenciesParams,
      [item.key]: !activatedDependenciesParams[item.key]
    });
  };

  console.log("component", component);

  // sort by blockType, and put inputBlock type last
  const renderSortedDependencies = (dependencies) => {
    return dependencies
      .sort((a, b) => {
        if (a.blockType === "inputBlock") return 1;
        if (b.blockType === "inputBlock") return -1;
        return 0;
      })
      .map(renderDependencyTag);
  };

  return (
    <div className={`endpoint-item ${isDragging ? "is-dragging" : ""}`}>
      <div className="endpoint-header">
        <div className="endpoint-base-info">
          <input
            type="text"
            value={component.url || ""}
            onChange={(e) => handleChange("url", e.target.value)}
            onBlur={onBlur}
            placeholder="URL de l'Endpoint"
            className="endpoint-input"
          />
          <select
            value={component.method || "GET"}
            onChange={(e) => handleSelectChange("method", e.target.value)}
            className="method-selector"
          >
            <option value="GET">GET</option>
            <option value="POST">POST</option>
            <option value="PUT">PUT</option>
            <option value="DELETE">DELETE</option>
          </select>
        </div>
        <div className="selectors">
          <select
            value={selectedChain}
            onChange={(e) => setSelectedChain(e.target.value)}
            className="chain-selector"
          >
            <option value="">Sélectionner une chaîne</option>
            {chains.map((chain) => (
              <option key={chain.id} value={chain.id}>
                {chain.name}
              </option>
            ))}
          </select>
          {selectedChain && (
            <select
              value={selectedBlock}
              onChange={(e) => setSelectedBlock(e.target.value)}
              className="block-selector"
            >
              <option value="">Sélectionner un bloc</option>
              {Object.keys(systemOverview[selectedChain] || {})
                .filter((blockKey) =>
                  selectableBlockTypes.includes(
                    systemOverview[selectedChain][blockKey].blockCategory
                  )
                )
                .map((blockKey) => (
                  <option key={blockKey} value={blockKey}>
                    {blockKey}
                  </option>
                ))}
            </select>
          )}
        </div>
      </div>
      <div className="endpoint-details">
        {chainExists &&
          selectedBlock &&
          systemOverview[selectedChain] &&
          systemOverview[selectedChain][selectedBlock] && (
            <>
              {/* <div className="tag-container">
                {systemOverview[selectedChain][selectedBlock].userInputs?.map(
                  renderTag
                )}
              </div> */}

              <div className="tag-container">
                {systemOverview[selectedChain][
                  selectedBlock
                ].systemOutputs?.map(renderTag)}
              </div>

              <div className="tag-container">
                {renderSortedDependencies(
                  systemOverview[selectedChain][selectedBlock].dependencies
                )}
              </div>

              {/* <div className="tag-container">
              {systemOverview[selectedChain][selectedBlock].listeners?.map(
                (listener) => (
                  <span key={listener} className="tag listener">
                    {listener}
                  </span>
                )
              )}
            </div> */}

              <div className="middlewares-list">{renderMiddlewares()}</div>
            </>
          )}
      </div>

      {selectedBlock && (
        <span className="endpoint-arrow">
          {/* <hr /> */}
          {seeDetails ? (
            <span onClick={() => setSeeDetails(false)}>
              {" "}
              Hide Details
              <FaAngleUp />
            </span>
          ) : (
            <span onClick={() => setSeeDetails(true)}>
              {" "}
              Show Details
              <FaAngleDown />
            </span>
          )}
          {/* FaAngleUp and FaAngleDown is not good for this purpose use FaAngleDown and FaAngleUp instead */}
        </span>
      )}

      {selectedBlock && seeDetails && (
        <EndpointDetails
          component={component}
          dependencies={dependencies}
          systemOutputs={systemOutputs}
          activatedDependenciesParams={activatedDependenciesParams}
        />
      )}
    </div>
  );
};

export default EndpointItem;
