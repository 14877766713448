const getSystemPromptWithType = (typing) => {
  const { globalType, result: typingResult, enums } = typing;

  let resultStr = "";

  if (globalType === "STRING_ENUM") {
    if (!enums || !enums.length) {
      throw new Error("String Enum must have at least one value");
    } else {
      resultStr = `Format Reponse: STRING ENUM: [${enums
        .map((item) => `"${item}"`)
        .join(", ")}]`;
    }
  } else if (globalType === "JSON_ARRAY") {
    if (!typingResult) {
      throw new Error("Array JSON must have a result");
    } else {
      resultStr = `Format Reponse: ARRAY JSON.\nType Reponse: [${typingResult}]`;
    }
  } else if (globalType === "JSON_OBJECT") {
    if (!typingResult) {
      throw new Error("JSON Object must have a result");
    }
    resultStr = `Format Reponse: JSON OBJECT.\nType Reponse: ${typingResult}`;
  } else {
    resultStr = `Format Reponse: ${globalType}`;
  }

  resultStr +=
    "\n\nToutes réponse de l'assistant ne se fait uniquement qu'en ce Type Reponse. Rien d'autres. Les reponses sont directement utilisé dans un system d'api en production et sensible au bug lié aux types de reponses, qu'il soit JSON, STRING, STRING ENUM, ARRAY JSON, etc....\n\n";
  return resultStr;
};

export default getSystemPromptWithType;
