import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  query,
  orderBy,
  limit,
  where,
  onSnapshot,
  setDoc,
  updateDoc,
  getDoc,
  doc,
  serverTimestamp,
  or,
  documentId,
  collectionGroup
} from "firebase/firestore";
import { firebaseDB, firebaseAuth } from "../api-connector/firebase";

const version = "1.0.0";

/**
 * FirestoreCrud
 * @class FirestoreCrud
 * @param {string} collectionName - The parent collection.
 * @param {...args} subCollections - The sub collections.
 * @param {object} sub - Access the sub collections FirestoreCrud.
 * @property {string} collectionName - The parent collection.
 * @property {[...string]} subCollections - The sub collections.
 * @method get - Gets a doc from the database.
 * @method list - Gets a list of docs from the database.
 * @method create - Creates a doc in the database.
 * @method update - Updates a doc in the database.
 * @method delete - Deletes a doc from the database.
 */
export class FirestoreCrud {
  constructor(collectionName, ...subCollections) {
    this.type = "FirestoreCrud";
    this.collectionName = collectionName;
    this.subCollections = subCollections;
    this.sub = {};
    this.parentCollection = null;
    return this;
  }

  /**
   * setSubCollection
   * @param {string} subCollectionName - The name of the sub collection.
   * @param {FirestoreCrud} subCollectionCrud - The FirestoreCrud instance for the sub collection.
   * @returns {FirestoreCrud} this - The FirestoreCrud instance for the sub collection.
   */
  setSubCollection(subCollection) {
    if (typeof subCollection === "string") {
      this.sub[subCollection] = new FirestoreCrud(
        subCollection
      ).setParentCollection(this);
    } else if (subCollection instanceof FirestoreCrud) {
      this.sub[subCollection.collectionName] =
        subCollection.setParentCollection(this);
    }
    return this;
  }

  /**
   * setParentCollection
   * @param {string} parentCollectionName - The name of the parent collection.
   * @returns {FirestoreCrud} this - The FirestoreCrud instance for the sub collection.
   */
  setParentCollection(parentCollection) {
    this.parentCollection = parentCollection;
    return this;
  }

  /**
   * getFullCollectionPath
   * based on parentCollection, collectionName, and subCollections
   * @returns {string} The full collection path.
   */
  getFullCollectionPath() {
    let path = this.collectionName;
    let currentParentCollection = this.parentCollection;
    while (currentParentCollection) {
      path = `${currentParentCollection.collectionName}/${path}`;
      currentParentCollection = currentParentCollection.parentCollection;
    }
    return path;
  }

  /**
   * getCollectionPathPosition - Gets the position of the collection in the path.
   */
  getCollectionPathPosition() {
    const collectionPath = this.getFullCollectionPath();
    const collectionPathArray = collectionPath.split("/");
    return collectionPathArray.length - 1;
  }

  /**
   * getParentsCollectionsName
   */
  getParentsCollectionsList() {
    let collectionPath = this.getFullCollectionPath();
    const parentsArray = collectionPath.split("/").slice(0, -1);
    return parentsArray;
  }

  /**
   * getCollectionsNameWithIds
   */
  getCollectionsPathsWithIds_list(...ids) {
    const collectionPath = this.getFullCollectionPath();
    const collectionPathArray = collectionPath.split("/");
    const collectionPathArrayWithIds = collectionPathArray.reduce(
      (acc, collectionName) => {
        const id = ids.shift();
        if (id) {
          acc.push(collectionName, id);
        } else {
          acc.push(collectionName);
        }
        return acc;
      },
      []
    );
    return collectionPathArrayWithIds;
  }

  /**
   * getCollectionRef
   * @param {string} docId - The id of the doc.
   * @returns {object} The collection reference.
   */
  getCollectionRef(...docId) {
    const collectionPathArrayWithIds = this.getCollectionsPathsWithIds_list(
      ...docId
    );

    const path = this.getFullCollectionPath();

    return collection(firebaseDB, ...collectionPathArrayWithIds);
  }

  /**
   * getDocRefWithCustomId
   * @param {string} docId - The id of the doc.
   * @param {string} childId - The id of the doc.
   * @returns {object} The doc reference.
   * @throws {Error} If docId is not provided.
   * @throws {Error} If childId is not provided.
   * @throws {Error} If subCollections is not provided.
   * @throws {Error} If parentCollection is not provided.
   */
  getDocRefWithCustomId(parentDocId, customId) {
    let customIdValue = customId || parentDocId;
    if (this.subCollections.length > 0) {
      if (!parentDocId) {
        throw new Error("parentDocId is required");
      }
      if (!customIdValue) {
        throw new Error("customId is required");
      }
      return doc(
        firebaseDB,
        this.collectionName,
        parentDocId,
        ...this.subCollections,
        customIdValue
      );
    }
    return doc(firebaseDB, this.collectionName, customIdValue);
  }

  /**
   * getDocRef
   * @param {string} parentId - The id of the doc.
   * @param {string} childId - The id of the doc.
   */
  getDocRef(...ids) {
    const collectionPathArrayWithIds = this.getCollectionsPathsWithIds_list(
      ...ids
    );

    const path = this.getFullCollectionPath();

    return doc(firebaseDB, ...collectionPathArrayWithIds);
  }

  /**
   * get
   * @param {string} parentDocId - The id of the doc.
   * @param {string} childDocId - The id of the doc.
   * @param {function} setDoc - The function to set the doc.
   * @returns {function} The unsubscribe function.
   * @throws {Error} If the doc could not be retrieved.
   * @throws {Error} If the docId is not provided.
   * @throws {Error} If the setDoc function is not provided.
   * @throws {Error} If the setDoc function is not a function.
   * */
  get(...args) {
    const setDoc = args.pop();
    if (!args.length) {
      throw new Error("documents ids are required");
    }
    if (!setDoc) {
      throw new Error("setDoc is required");
    }
    if (typeof setDoc !== "function") {
      throw new Error("setDoc must be a function");
    }
    const docRef = this.getDocRef(...args);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        setDoc({ id: doc.id, ...doc.data() });
      } else {
        setDoc(null);
      }
    });
    return unsubscribe;
  }

  /**
   * get
   * @param {string} parentDocId - The id of the doc.
   * @param {string} childDocId - The id of the doc.
   * @param {function} setDoc - The function to set the doc.
   * @returns {function} The unsubscribe function.
   * @throws {Error} If the doc could not be retrieved.
   * @throws {Error} If the docId is not provided.
   * @throws {Error} If the setDoc function is not provided.
   * @throws {Error} If the setDoc function is not a function.
   * */
  getByUid(...args) {
    const setDoc = args.pop();
    const [childDocId] = args;

    if (!setDoc) {
      throw new Error("setDoc is required");
    }
    if (typeof setDoc !== "function") {
      throw new Error("setDoc must be a function");
    }
    const user = firebaseAuth.currentUser;
    const uid = user ? user.uid : null;

    const docRef = this.getDocRef(uid, childDocId);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        setDoc({ id: doc.id, ...doc.data() });
      } else {
        setDoc(null);
      }
    });
    return unsubscribe;
  }

  /**
   * list
   * @param {string} parentDocId - The id of the doc.
   * @param {function} setDocs - The function to set the docs.
   * @returns {function} The unsubscribe function.
   * @throws {Error} If the docs could not be retrieved.
   * @throws {Error} If the docId is not provided.
   * @throws {Error} If the setDocs function is not provided.
   * @throws {Error} If the setDocs function is not a function.
   * */
  list(...args) {
    const lastArg = args[args.length - 1];
    let setDocs = null; // mandatory
    let filterVal = null; // optional
    let orderByVal = null; // optional
    let limitVal = null; // optional
    let ids = null; // optional
    let options = null;

    if (typeof lastArg === "function") {
      setDocs = args.pop();
      ids = args;
    } else if (typeof lastArg === "object") {
      const argsWithoutStrings = args.filter((arg) => typeof arg !== "string");
      const [setDocsArg, filterArg, orderByArg, limitArg, optionsArg] =
        argsWithoutStrings;
      setDocs = setDocsArg;
      filterVal = filterArg;
      orderByVal = orderByArg;
      limitVal = limitArg;
      options = optionsArg;
      ids = args.filter((arg) => typeof arg === "string");
    }

    if (!setDocs) {
      throw new Error("setDocs is required");
    }
    if (typeof setDocs !== "function") {
      throw new Error("setDocs must be a function");
    }

    let collectionRef = this.getCollectionRef(...ids);
    const user = firebaseAuth.currentUser;
    const uid = user ? user.uid : null;

    let queryList = [];
    let parentCondition = null;

    if (options && options.sharedUidLists) {
      const orQueries = options.sharedUidLists.map(
        (sharedUidListPropertyName) =>
          where(sharedUidListPropertyName, "array-contains", user.uid)
      );

      queryList.push(or(where("uid", "==", user.uid), ...orQueries));
    } else if (options && options.isFromShared) {
    } else if (options && options.parentQuery) {
      const { field, operator, value } = options.parentQuery;
      const parentCollection = this.parentCollection;
      const parentCollectionName = parentCollection.collectionName;

      let valueToUse = value;

      if (value === "$$uid") {
        valueToUse = uid;
      }

      const uidCondition = where("uid", "==", uid);

      console.log("parentCollectionName", parentCollectionName);

      parentCondition = collectionGroup(collectionRef).where(
        field,
        operator,
        valueToUse
      );

      queryList.push(or(uidCondition, parentCondition));
    } else {
      queryList.push(where("uid", "==", user.uid));
    }

    if (filterVal) {
      const { field, operator, value } = filterVal;
      queryList.push(where(field, operator, value));
    }

    if (orderByVal) {
      const { field, direction } = orderByVal;
      queryList.push(orderBy(field, direction));
    }

    if (limitVal) {
      queryList.push(limit(limitVal));
    }

    let q = null;

    if (options && options.parentQuey && parentCondition) {
      q = query(parentCondition, collectionRef, ...queryList);
    } else {
      q = query(collectionRef, ...queryList);
    }

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ id: doc.id, ...doc.data() });
      });
      setDocs(docs);
    });
    return unsubscribe;
  }

  /**
   * getList - return the list of docs without subscribing to changes
   */
  async getList(...args) {
    const lastArg = args[args.length - 1];
    let filterVal = null; // optional
    let orderByVal = null; // optional
    let limitVal = null; // optional

    if (typeof lastArg === "object") {
      const argsWithoutStrings = args.filter((arg) => typeof arg !== "string");
      const [filterArg, orderByArg, limitArg] = argsWithoutStrings;
      filterVal = filterArg;
      orderByVal = orderByArg;
      limitVal = limitArg;
      args = args.filter((arg) => typeof arg === "string");
    }

    const user = firebaseAuth.currentUser;
    const uid = user ? user.uid : null;
    const collectionRef = this.getCollectionRef(...args);

    const queryList = [];

    if (uid) {
      queryList.push(where("uid", "==", uid));
    }

    if (filterVal) {
      // const { field, operator, value } = filterVal;
      // queryList.push(where(field, operator, value));
      if (typeof filterVal === "object" && filterVal.length) {
        filterVal.forEach((filter) => {
          const { field, operator, value } = filter;
          queryList.push(where(field, operator, value));
        });
      } else if (typeof filterVal === "object") {
        const { field, operator, value } = filterVal;
        queryList.push(where(field, operator, value));
      }
    }

    if (orderByVal) {
      const { field, direction } = orderByVal;
      queryList.push(orderBy(field, direction));
    }

    if (limitVal) {
      queryList.push(limit(limitVal));
    }

    const q = query(collectionRef, ...queryList);
    const querySnapshot = await getDocs(q);
    const docs = [];
    querySnapshot.forEach((doc) => {
      docs.push({ id: doc.id, ...doc.data() });
    });
    return docs;
  }

  /**
   * create
   *  @param {string} parentDocId - The id of the doc.
   * @param {object} doc - The doc to create.
   * @returns {string} The id of the created doc.
   * @throws {Error} If the doc could not be created.
   * @throws {Error} If the docId is not provided.
   * @throws {Error} If the doc is not provided.
   * @throws {Error} If the doc is not an object.
   * */
  async create(...args) {
    const doc = args.pop();
    if (!doc) {
      throw new Error("doc is required");
    }
    if (typeof doc !== "object") {
      throw new Error("doc must be an object");
    }
    const collectionRef = this.getCollectionRef(...args);
    const user = firebaseAuth.currentUser;
    const docRef = await addDoc(collectionRef, {
      uid: user.uid,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      v: version,
      ...doc
    });
    return docRef.id;
  }

  /**
   * create with id
   * @param {string} parentDocId - The id of the doc.
   * @param {string} childDocId - The id of the doc.
   * @param {object} doc - The doc to create.
   * @returns {string} The id of the created doc.
   * @throws {Error} If the doc could not be created.
   * @throws {Error} If the docId is not provided.
   * @throws {Error} If the doc is not provided.
   * @throws {Error} If the doc is not an object.
   */
  async createWithId(...args) {
    console.log("createWithId", args);
    const doc = args.pop();
    console.log("createWithId", args, doc);
    let [parentDocId, customId] = args;
    let collectionRefArgs = [];
    if (!doc) {
      throw new Error("doc is required");
    }
    if (typeof doc !== "object") {
      throw new Error("doc must be an object");
    }
    const docRef = this.getDocRef(...args);
    const user = firebaseAuth.currentUser;
    await setDoc(docRef, {
      ...doc,
      uid: user.uid,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      v: version
    });
    return customId;
  }

  /**
   * update
   * @param {string} parentDocId - The id of the doc.
   * @param {string} childDocId - The id of the doc.
   * @param {object} doc - The doc to update.
   * @throws {Error} If the doc could not be updated.
   * @throws {Error} If the docId is not provided.
   * @throws {Error} If the doc is not provided.
   * @throws {Error} If the doc is not an object.
   * */
  async update(...args) {
    const doc = args.pop();
    // if (!parentDocId) {
    //   throw new Error("docId is required");
    // }
    if (!doc) {
      throw new Error("doc is required");
    }
    if (typeof doc !== "object") {
      throw new Error("doc must be an object");
    }
    const user = firebaseAuth.currentUser;
    const docRef = this.getDocRef(...args);
    await updateDoc(docRef, {
      ...doc,
      // uid: user.uid,
      updatedAt: serverTimestamp()
    });
    console.log("updated");
  }

  /**
   * delete
   * @param {string} parentDocId - The id of the doc.
   * @param {string} childDocId - The id of the doc.
   * @throws {Error} If the doc could not be deleted.
   * @throws {Error} If the docId is not provided.
   * */
  async delete(...args) {
    const [parentDocId, childDocId] = args;
    if (!parentDocId) {
      throw new Error("docId is required");
    }
    const docRef = this.getDocRef(parentDocId, childDocId);
    await deleteDoc(docRef);
  }

  /**
   * clone
   * @param {string} parentDocId - The id of the doc.
   * @param {string} childDocId - The id of the doc.
   * @param {object} docAdditional - The additional data to add to the cloned doc.
   * @throws {Error} If the doc could not be cloned.
   * @returns {string} The id of the cloned doc.
   */
  async clone(...args) {
    const [parentDocId, arg1, arg2] = args;
    if (!parentDocId) {
      throw new Error("docId is required");
    }
    let childDocId = null;
    let docAdditional = {};
    if (arg1) {
      if (typeof arg1 === "string") {
        childDocId = arg1;
      } else if (typeof arg1 === "object") {
        docAdditional = arg1;
      }
    }
    if (arg2) {
      if (typeof arg2 === "object") {
        docAdditional = arg2;
      }
    }

    const docRef = this.getDocRef(parentDocId, childDocId);
    const doc = await getDoc(docRef);
    const data = doc.data();

    if (!data) {
      throw new Error("doc not found");
    }

    let dataWithoutId = { ...data };
    delete dataWithoutId.id;

    const newDocRef = await addDoc(this.getCollectionRef(parentDocId), {
      ...dataWithoutId,
      ...docAdditional,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      v: version
    });
    return newDocRef.id;
  }
}

// quick doc
/**
 * db.collection.get(parentDocId, childDocId, setDoc);
 * db.collection.list(parentDocId, setDocs);
 * db.collection.create(parentDocId, doc);
 * db.collection.update(parentDocId, childDocId, doc);
 * db.collection.delete(parentDocId, childDocId);
 */

/**
 * Sub collection example
 * db.collection.sub.subCollection.get(parentDocId, childDocId, setDoc);
 * ....
 **/
