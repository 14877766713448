// imported jsdon
/**
 * @typedef {Object} ApiProject - The apiProject object.
 * @property {string} id - The id of the apiProject.
 * @property {string} name - The name of the apiProject.
 * @property {string} description - The description of the apiProject.
 * @property {string} status - The status of the apiProject.
 * @property {string} uid - The uid of the apiProject.
 * @property {string} createdAt - The date the apiProject was created.
 */

/**
 * @fileoverview ApiProjectSdk is a library to help with the BeegBang Api Project Creator feature.
 *
 *
 *
 * SDK js usage.
 * - SDK is named ApiProjectSdk
 * - SDK is available in the browser
 * - SDK is available in the nodejs server
 * - SDK is initialized using ApiProjectSdk.constructor({ projectId, chainId })
 * - SDK has following methods:
 *   - getChainParametersKeys() return array of keys
 *   - getChainResultKeys() return array of keys
 *   - getRequiredChainKeysForResultValue(valueName) return array of keys
 *   - getRequiredChainKeysForChainKey(valueName) return array of keys
 *   - getRequiredChainKeysForAliasName(valueName) return array of keys
 *   - createDataSet() return the new set as an object ApiProjectSdk.DataSet and hydrate it with the data (paramaters and result)
 *   - getDataSet(dataSetId, hydrateSelect) return the set as an object ApiProjectSdk.DataSet and hydrate it with the data (paramaters and result)
 * - ApiProjectSdk.DataSet following methods:
 *   - local writing methods:
 *   - setParameters(parameters) update the parameters locally without saving
 *   - setResults(results) update the results locally without saving
 *   - runBlocks(...chainKeys/aliases) run the blocks for the chainKeys/aliasNames, and update result locally without saving
 *     - can also return error if:
 *       - parameters are not valid
 *       - parameters for the block has not been initialized yet
 *   - runResultsBlocks(...valuesNames) run the blocks for the valuesNames, and update result locally without saving
 *     - can also return error if:
 *       - value names are not valid
 *       - parameters for the key has not been initialized yet
 *   - remote writing methods:
 *   - saveParameters() save the parameters locally and remotely
 *   - saveResults() save the results locally and remotely
 *   - Checking methods:
 *   - isChainKeyInitialized(chainKey) return boolean
 *   - isAllRequiredChainKeysInitialized(chainKey) return boolean
 */

// import usefull npm libraries
import axios from "axios";
import { last, Observable, Subject } from "rxjs";
import _ from "lodash";
import "firebase/firestore";
import { firebaseAuth } from "../api-connector/firebase";
import PubSub from "pubsub-js";

import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  query,
  orderBy,
  limit,
  where,
  onSnapshot,
  setDoc,
  updateDoc,
  getDoc,
  doc,
  serverTimestamp
} from "firebase/firestore";

// import for hooks
import { useState, useEffect, useRef, useCallback } from "react";

// import sdk classes
// import ApiProject from './ApiProject';
// import Chain from './Chain';
// import DataSet from './DataSet.js';

/**
 * @info FireStoreDB
 * - apiProject documents are stored in the collection apiProjects
 * - chain documents are stored in the collection apiProjects/{apiProjectId}/chains
 * - dataSet documents are stored in the collection apiProjects/{apiProjectId}/chains/{chainId}/dataSets
 * - builtPrompt documents are stored in the collection builtPrompts
 */

class ApiProjectFirestoreDb {
  /**
   * @constructor ApiProjectFirestoreDb
   * @param {FireStoreDb} db - The db of the apiProject.
   * @param {string} projectId - The projectId of the apiProject.
   */
  constructor(db, projectId) {
    this.db = db;
    this.projectId = projectId;
  }

  /**
   * @method get() return the apiProject document
   * @returns {Promise} - The promise of the apiProject document.
   * @example
   * const apiProject = await apiProjectSdk.apiProject.get();
   */
  async getProject(options = {}) {
    // firestore v9
    const apiProjectRef = doc(this.db, "apiProjects", this.projectId);

    const apiProject = await getDoc(apiProjectRef);
    return { id: apiProject.id, ...apiProject.data() };
  }

  /**
   * @method getChains(options) return the chains of the apiProject
   * @param {object} options - The options of the apiProject.
   * @param {boolean} options.returnOnSnapshot - Set to true to not return a promise but a snapshot and unsubscribe function instead.
   * @param {function} options.onSnapshot - The onSnapshot function of the apiProject. Required if returnOnSnapshot is true.
   * @returns {Promise} - The promise of the chains of the apiProject.
   * @returns {function} - The unsubscribe function of the onSnapshot listener.
   * @example
   * const chains = await apiProjectSdk.apiProject.getChains();
   * @example
   * const unsubscribe = await apiProjectSdk.apiProject.getChains({
   *  returnOnSnapshot: true,
   *  onSnapshot: (chains) => {
   *   console.log(chains);
   * }
   */
  async getChains(options = {}) {
    // firestore v9
    if (options.returnOnSnapshot) {
      if (!options.onSnapshot) {
        throw new Error(
          "options.onSnapshot is required if options.returnOnSnapshot is true"
        );
      }
      const chainsRef = collection(
        this.db,
        "apiProjects",
        this.projectId,
        "chains"
      );
      const unsubscribe = onSnapshot(chainsRef, (snapshot) => {
        const chains = snapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        });
        options.onSnapshot(chains);
      });
      return unsubscribe;
    } else {
      const chainsRef = collection(
        this.db,
        "apiProjects",
        this.projectId,
        "chains"
      );
      const chains = await getDocs(chainsRef);
      return chains.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
    }
  }

  /**
   * @method getChain(chainId,options) return the chain of the apiProject
   * @param {string} chainId - The chainId of the chain.
   * @param {object} options - The options of the apiProject.
   * @param {boolean} options.returnOnSnapshot - Set to true to not return a promise but a snapshot and unsubscribe function instead.
   * @param {function} options.onSnapshot - The onSnapshot function of the apiProject. Required if returnOnSnapshot is true.
   * @returns {Promise} - The promise of the chain of the apiProject.
   * @returns {function} - The unsubscribe function of the onSnapshot listener.
   * @example
   * const chain = await apiProjectSdk.apiProject.getChain(chainId);
   * @example
   * const unsubscribe = await apiProjectSdk.apiProject.getChain(chainId, {
   *  returnOnSnapshot: true,
   *  onSnapshot: (chain) => {},
   * });
   */
  async getChain(chainId, options = {}) {
    // firestore v9
    if (options.returnOnSnapshot) {
      if (!options.onSnapshot) {
        throw new Error(
          "options.onSnapshot is required if options.returnOnSnapshot is true"
        );
      }
      const chainRef = doc(
        this.db,
        "apiProjects",
        this.projectId,
        "chains",
        chainId
      );
      const unsubscribe = onSnapshot(chainRef, (snapshot) => {
        const chain = { id: snapshot.id, ...snapshot.data() };
        options.onSnapshot(chain);
      });
      return unsubscribe;
    } else {
      const chainRef = doc(
        this.db,
        "apiProjects",
        this.projectId,
        "chains",
        chainId
      );

      const chain = await getDoc(chainRef);
      return { id: chain.id, ...chain.data() };

      // if (options.isPublic) {
      //   // query with visibility == public
      //   const queryDoc = query(chainRef, where("visibility", "==", "public"));
      //   const chain = await getDocs(queryDoc);
      //   return { id: chain.id, ...chain.data() };
      // } else {
      // }
    }
  }

  /**
   * @method createDataSet(chainId,dataSet) create a dataSet in the apiProject
   * @param {string} chainId - The chainId of the chain.
   * @param {object} dataSet - The dataSet of the apiProject.
   * @returns {Promise<string>} - The promise return the id of the dataSet or the new id of the dataSet
   * @example
   * const dataSet = await apiProjectSdk.apiProject.createDataSet(chainId, dataSet);
   */
  async createDataSet(chainId, dataSet) {
    // firestore v9
    const collectionRef = collection(
      this.db,
      "apiProjects",
      this.projectId,
      "chains",
      chainId,
      "dataSets"
    );
    const docRef = await addDoc(collectionRef, dataSet);
    return docRef.id;
  }

  /**
   * @method updateDataSet(chainId,dataSetId,dataSet) update a dataSet in the apiProject
   * @param {string} chainId - The chainId of the chain.
   * @param {string} dataSetId - The dataSetId of the dataSet.
   * @param {object} dataSet - The dataSet of the apiProject.
   * @returns {Promise<string>} - The promise of the dataSet of the apiProject.
   * @example
   * const idUpdated = await apiProjectSdk.apiProject.updateDataSet(chainId, dataSetId, dataSet);
   */
  async updateDataSet(chainId, dataSetId, dataSet) {
    // firestore v9
    const docRef = doc(
      this.db,
      "apiProjects",
      this.projectId,
      "chains",
      chainId,
      "dataSets",
      dataSetId
    );

    await updateDoc(docRef, dataSet);
    return dataSetId;
  }

  /**
   * @method getDataSet(chainId,dataSetId,options) return the dataSet of the apiProject
   * @param {string} chainId - The chainId of the chain.
   * @param {string} dataSetId - The dataSetId of the dataSet.
   * @param {object} options - The options of the apiProject.
   * @param {boolean} options.returnOnSnapshot - Set to true to not return a promise but a snapshot and unsubscribe function instead.
   * @param {function} options.onSnapshot - The onSnapshot function of the apiProject. Required if returnOnSnapshot is true.
   * @returns {Promise} - The promise of the dataSet of the apiProject.
   * @returns {function} - The unsubscribe function of the onSnapshot listener.
   * @example
   * const dataSet = await apiProjectSdk.apiProject.getDataSet(chainId, dataSetId);
   * @example
   * const unsubscribe = await apiProjectSdk.apiProject.getDataSet(chainId, dataSetId, {
   * returnOnSnapshot: true,
   * onSnapshot: (dataSet) => {},
   * });
   */
  async getDataSet(chainId, dataSetId, options = {}) {
    // firestore v9
    if (options.returnOnSnapshot) {
      if (!options.onSnapshot) {
        throw new Error(
          "options.onSnapshot is required if options.returnOnSnapshot is true"
        );
      }
      const dataSetRef = doc(
        this.db,
        "apiProjects",
        this.projectId,
        "chains",
        chainId,
        "dataSets",
        dataSetId
      );
      const unsubscribe = onSnapshot(dataSetRef, (snapshot) => {
        const dataSet = { id: snapshot.id, ...snapshot.data() };
        options.onSnapshot(dataSet);
      });
      return unsubscribe;
    } else {
      const dataSetRef = doc(
        this.db,
        "apiProjects",
        this.projectId,
        "chains",
        chainId,
        "dataSets",
        dataSetId
      );
      const dataSet = await getDoc(dataSetRef);
      return { id: dataSet.id, ...dataSet.data() };
    }
  }

  /**
   * @method getDataSets(chainId,options) return the dataSets of the apiProject
   * @param {string} chainId - The chainId of the chain.
   * @param {object} options - The options of the apiProject.
   * @param {boolean} options.returnOnSnapshot - Set to true to not return a promise but a snapshot and unsubscribe function instead.
   * @param {function} options.onSnapshot - The onSnapshot function of the apiProject. Required if returnOnSnapshot is true.
   * @param {object} options - The options of the apiProject.
   * @param {boolean} options.returnOnSnapshot - Set to true to not return a promise but a snapshot and unsubscribe function instead.
   * @param {function} options.onSnapshot - The onSnapshot function of the apiProject. Required if returnOnSnapshot is true.
   * @returns {Promise} - The promise of the dataSets of the apiProject.
   * @returns {function} - The unsubscribe function of the onSnapshot listener.
   *
   * @example
   * const dataSets = await apiProjectSdk.apiProject.getDataSets(chainId);
   * dataSets.forEach(dataSet => {
   *  console.log(dataSet.data);
   * });
   * @example
   * const unsubscribe = await apiProjectSdk.apiProject.getDataSets(chainId, {
   * returnOnSnapshot: true,
   * onSnapshot: (dataSets) => {
   * dataSets.forEach(dataSet => {
   * console.log(dataSet.data);
   * });
   */
  getDataSets(chainId, options = {}) {
    // firestore v9
    if (options.returnOnSnapshot) {
      if (!options.onSnapshot) {
        throw new Error(
          "options.onSnapshot is required if options.returnOnSnapshot is true"
        );
      }
      const dataSetsRef = collection(
        this.db,
        "apiProjects",
        this.projectId,
        "chains",
        chainId,
        "dataSets"
      );
      const unsubscribe = onSnapshot(dataSetsRef, (snapshot) => {
        const dataSets = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id
        }));
        options.onSnapshot(dataSets);
      });
      return unsubscribe;
    } else {
      const dataSetsRef = collection(
        this.db,
        "apiProjects",
        this.projectId,
        "chains",
        chainId,
        "dataSets"
      );
      const dataSets = getDocs(dataSetsRef);
      // return promise so the method can called with await
      const dataSetsPromise = dataSets.then((dataSets) => {
        return dataSets.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      });
    }
  }

  /**
   * @method getBuiltPrompt(builtPromptId) return the builtPrompt of the apiProject
   * @param {string} builtPromptId - The builtPromptId of the builtPrompt.
   * @returns {Promise} - The promise of the builtPrompt of the apiProject.
   * @example
   * const builtPrompt = await apiProjectSdk.apiProject.getBuiltPrompt(builtPromptId);
   */
  async getBuiltPrompt(builtPromptId) {
    // firestore v9
    const builtPromptRef = doc(this.db, "builtPrompts", builtPromptId);
    const builtPrompt = await getDoc(builtPromptRef);
    return builtPrompt.data();
  }

  /**
   * @method updateBuiltPrompt(builtPromptId, builtPrompt) update the builtPrompt of the apiProject
   * @param {string} builtPromptId - The builtPromptId of the builtPrompt.
   * @param {object} builtPrompt - The builtPrompt of the builtPrompt.
   * @returns {Promise} - The promise of the builtPrompt of the apiProject.
   * @example
   * const builtPrompt = await apiProjectSdk.apiProject.updateBuiltPrompt(builtPromptId, builtPrompt);
   */
  async updateBuiltPrompt(builtPromptId, builtPrompt) {
    // firestore v9
    const builtPromptRef = doc(this.db, "builtPrompts", builtPromptId);
    await updateDoc(builtPromptRef, {
      ...builtPrompt,
      updatedAt: serverTimestamp()
    });
    return builtPrompt;
  }
}

/**
 ****************************************************************************************************************************************************************
 ****************************************************************************************************************************************************************
 ****************************************************************************************************************************************************************
 */

/**
 * @typedef {Object} ApiProjectOption
 * @property {FireStoreDb} db - The db of the apiProject.
 * @property {{string : string}} AIengineApiUrls - The AIengineApiUrls of the apiProject.
 */

/**
 * @typedef {Object} InitSdkParams
 * @property {string} projectId - The projectId of the apiProject.
 * @property {string} chainId - The chainId of the chain.
 * @property {ApiProjectOption} options - The options of the apiProject.
 */

/**
 * @typedef {Object} ApiProjectSdk
 * @constructor ApiProjectSdk
 * @param {ApiProject} project - The projectId of the apiProject.
 * @param {Chain} chain - The chainId of the chain.
 * @param {ApiProjectOption} options - The options of the apiProject.
 * @param {FireStoreDb} options.db - The firestore db of the apiProject.
 * @param {string} options.AIengineApiUrls - The AIengineApiUrls of the apiProject.
 * @property {Chain} chain - The chain object.
 * @property {ApiProject} apiProject - The apiProject object.
 * @property {FireStoreDb} db - The firestore db of the apiProject.
 * @property {{string : string}} AIengineApiUrls - The AIengineApiUrls of the apiProject.
 * @method init(InitSdkParams) - static function, The init function of the ApiProjectSdk.
 * @method getChainParametersKeys() return array of keys
 * @method getChainResultKeys() return array of keys
 * @method getRequiredChainKeysForResultValue(valueName) return array of keys
 * - @param {string} valueName - The valueName of the result.
 * - @param {string} valueNames - The valueNames of the parameters.
 * @method getRequiredChainKeysForChainKey(chainKey) return array of keys
 * - @param {string} chainKey - The chainKey of the block.
 * @method getRequiredChainKeysForAliasName(aliasName) return array of keys
 * - @param {string} aliasName - The aliasName of the block.
 * @method createDataSet() return the new set as an object ApiProjectSdk.DataSet and hydrate it with the data (paramaters and result)
 * @method getDataSet(dataSetId) return the set as an object ApiProjectSdk.DataSet and hydrate it with the data (paramaters and result)
 *  - @param {string} dataSetId - The id of the dataSet.
 */

export default class ApiProjectSdk {
  /**
   * @constructor ApiProjectSdk
   * @param {string} project - The projectId of the apiProject.
   * @param {string} chain - The chainId of the chain.
   * @param {ApiProjectOption} options - The options of the apiProject.
   */
  constructor(project, chain, options = {}) {
    this.project = project;
    this.chain = chain;
    this.db = options.db;
    this.isDataSetsExternal = options.isDataSetsExternal;
    this.AIengineApi = new AIEngineApi({ urls: options.AIengineApiUrls });
    this.apiProjectFirestoreDb = new ApiProjectFirestoreDb(
      this.db,
      this.project.id
    );
    this.initializeHelperMaps();
  }

  /** get apiProject and chain documents and return new instance of ApiProjectSdk */
  /**
   * @method init(InitSdkParams) - static function, The init function of the ApiProjectSdk.
   * @param {InitSdkParams} params - The params of the init function.
   * @param {string} params.projectId - The projectId of the apiProject.
   * @param {string} params.chainId - The chainId of the chain.
   * @param {ApiProjectOption} params.options - The options of the apiProject.
   * @param {FireStoreDb} params.options.db - The firestore db of the apiProject.
   * @param {string} params.options.AIengineApiUrls - The AIengineApiUrls of the apiProject.
   * @returns {ApiProjectSdk} - The new instance of ApiProjectSdk.
   * @example
   * const apiProjectSdk = await ApiProjectSdk.init({
   *    projectId: 'myProjectId',
   *    chainId: 'myChainId',
   *    options: {
   *      db: myFirestoreDb,
   *      AIengineApiUrls: {
   *        completion: 'https://api.openai.com/v1/engines/davinci/completions',
   *      },
   *   },
   * });
   */
  static async init({ projectId, chainId, options, isPublic = false }) {
    const apiProjectFirestoreDb = new ApiProjectFirestoreDb(
      options.db,
      projectId
    );
    const apiProject = await apiProjectFirestoreDb.getProject({ isPublic });
    const chain = await apiProjectFirestoreDb.getChain(chainId, { isPublic });
    return new ApiProjectSdk(apiProject, chain, options);
  }

  /**
   * @private
   * @method initializeHelperMaps() initialize the helper maps
   */
  initializeHelperMaps() {
    this.chainKeyBlocksMap = this.chain.promptBlocks.reduce((acc, block) => {
      acc[block.chainKey] = block;
      return acc;
    }, {});
    this.exportedValueKeyBlocksMap = this.chain.promptBlocks.reduce(
      (acc, block) => {
        acc[block.valuesLinkedExport.result] = block;
        return acc;
      },
      {}
    );
  }

  /**
   * @method getChainParametersKeys() return array of keys
   * @returns {string[]} - The array of keys.
   * @example
   * const parametersKeys = apiProjectSdk.getChainParametersKeys();
   * // ['key1', 'key2', 'key3']
   */
  getChainParametersKeys() {
    return this.chain.promptBlocks.reduce((acc, block) => {
      const keysOfInput = Object.keys(block.valuesLinkedImport || {}).reduce(
        (acc, valueKeyImport) => {
          const { value, type } = block.valuesLinkedImport[valueKeyImport];
          if (type === "input") {
            let key = value || valueKeyImport;
            return [...acc, key];
          }
          return acc;
        },
        []
      );
      // const keys = Object.keys(block.valuesLinkedImport);
      return [...acc, ...keysOfInput];
    }, []);
  }

  /**
   * @method getChainResultKeys() return array of keys
   * @returns {string[]} - The array of keys.
   * @example
   * const resultKeys = apiProjectSdk.getChainResultKeys();
   * // ['key1', 'key2', 'key3']
   */
  getChainResultKeys() {
    return this.chain.promptBlocks.reduce((acc, block) => {
      const key = block.valuesLinkedExport.result;
      return [...acc, key];
    }, []);
  }

  /**
   * @method getParentsBlocksFromResultKey(resultKey) get the children blocks of the resultKey
   * @param {string} resultKey - The resultKey of the dataSet.
   * @returns {Object[]} - The children blocks of the resultKey.
   * @example
   * const dataSet = new DataSet(...);
   * await dataSet.initialize();
   * console.log(dataSet.chain.promptBlocks.map((block) => block.chainKey)); // ["0", "0-0", "1", "1-0", "0-1", "0-0-0", "1-1", "1-1-0", "2", "2-0", "0-0-1", "0-0-1-0", "0-0-0-0"]
   * const childrenBlocks1 = dataSet.getParentsBlocksFromResultKey("0-0-0");
   * console.log(childrenBlocks.map((block) => block.chainKey)); // ["0", "0-0"]
   * const childrenBlocks2 = dataSet.getParentsBlocksFromResultKey("1-1-0");
   * console.log(childrenBlocks.map((block) => block.chainKey)); // ["1", "1-1"]
   */
  getParentsBlocksFromResultKey(resultKey) {
    const block = this.exportedValueKeyBlocksMap[resultKey];
    const { chainKey } = block;
    const numbers = chainKey.split("-");
    const parents = this.chain.promptBlocks.filter((block) => {
      const blockNumbers = block.chainKey.split("-");
      if (
        blockNumbers.length < numbers.length &&
        chainKey.startsWith(block.chainKey)
      ) {
        return true;
      }
      return false;
    });
    return parents;
  }

  /**
   * @method getResultType(resultKey) get the result type of the resultKey
   * @param {string} resultKey - The resultKey of the dataSet.
   * @returns {string} - The result type of the resultKey.
   */
  getResultType(resultKey) {
    const block = this.exportedValueKeyBlocksMap[resultKey];
    const { blockType, logicType } = block;
    if (blockType === "completion") {
      return "string";
    } else if (blockType === "logic" && logicType === "loopResult") {
      return "array";
    }
  }

  /**
   * @method isParentsArray(resultKey) check if the resultKey is an array
   * @param {string} resultKey - The resultKey of the dataSet.
   * @returns {boolean} - True if the resultKey is an array.
   */
  isParentsArray(resultKey) {
    let childrenBlocks = this.getParentsBlocksFromResultKey(resultKey);
    childrenBlocks.reverse();
    const lastParentBlockArray = childrenBlocks.find((block) => {
      const { blockType, logicType } = block;

      if (blockType === "logic" && logicType === "loopResult") {
        return true;
      }
      return false;
    });
    if (lastParentBlockArray) {
      return true;
    }
    return false;
  }

  /**
   * @method getParentsArray(resultKey) get the parents array of the resultKey
   * @param {string} resultKey - The resultKey of the dataSet.
   * @returns {string} - The parents array of the resultKey.
   */
  getParentArrayResultKey(resultKey) {
    let parentsBlock = this.getParentsBlocksFromResultKey(resultKey);
    parentsBlock.reverse();
    const lastParentBlockArray = parentsBlock.find((block) => {
      const { blockType, logicType } = block;

      if (blockType === "logic" && logicType === "loopResult") {
        return true;
      }
      return false;
    });
    if (lastParentBlockArray) {
      return lastParentBlockArray.valuesLinkedExport.result;
    }
    return null;
  }

  /**
   * @method getImportedArrayValueKey(resultKey) check if the resultKey has an imported array valueKey
   * @param {string} resultKey - The resultKey of the dataSet.
   * @returns {string} - The imported array valueKey.
   */
  getImportedArrayValueKey(resultKey) {
    const block = this.exportedValueKeyBlocksMap[resultKey];
    const { valuesLinkedImport } = block;
    const valueKeyImport = Object.keys(valuesLinkedImport || {}).find((key) => {
      const { type, value } = valuesLinkedImport[key];
      if (type === "computed") {
        const selectedBlock = this.exportedValueKeyBlocksMap[value];
        const { blockType, logicType } = selectedBlock;
        if (blockType === "logic" && logicType === "loopResult") {
          return true;
        }
      }
      return false;
    });
    if (valueKeyImport) {
      return valuesLinkedImport[valueKeyImport].value;
    }
    return null;
  }

  /**
   * @method getRequiredChainKeysForChainKey(chainKey) return array of keys
   * @param {string} chainKey - The chainKey of the block.
   * @returns {string[]} - The array of keys.
   * @example
   * // apiProjectSdk.chain.promptBlocks.map((block) => block.chainKey) = ['0', '1', '1-0', '0-0', '0-1', '0-0-1', '0-0-2', ' 0-0-1-0']
   * const requiredKeys = apiProjectSdk.getRequiredChainKeysForChainKey('0-0-1-0');
   * // ['0', '1', '1-0', '0-0', '0-1']
   */
  getRequiredChainKeysForChainKey(chainKey) {
    const block = this.chain.promptBlocks.find(
      (block) => block.chainKey === chainKey
    );
    if (!block) {
      throw new Error(`block with chainKey ${chainKey} not found`);
    }
    const { valuesLinkedImport } = block;

    if (!valuesLinkedImport) {
      throw new Error(
        `block with chainKey ${chainKey} has no valuesLinkedImport`
      );
    }

    const keys = Object.keys(valuesLinkedImport);
    const requiredKeys = keys.reduce((acc, key) => {
      const { type, value } = valuesLinkedImport[key];
      if (type === "computed") {
        return [...acc, value];
      }
      return acc;
    }, []);

    let finalRequiredKeys = [...requiredKeys];
    requiredKeys.forEach((key) => {
      const requiredKeysForKey = this.getRequiredChainKeysForChainKey(key);
      finalRequiredKeys = [...finalRequiredKeys, ...requiredKeysForKey];
    });

    // remove duplicates from end of array
    finalRequiredKeys = finalRequiredKeys.reverse();
    finalRequiredKeys = finalRequiredKeys.reduce((acc, key) => {
      if (!acc.includes(key)) {
        return [...acc, key];
      }
      return acc;
    }, []);
    finalRequiredKeys = finalRequiredKeys.reverse();

    return finalRequiredKeys;
  }

  /**
   * @method getRequiredChainKeysForResultValue(valueName) return array of keys
   * @param {string} valueName - The valueName of the result. Which is promptBlocks[i].valuesLinkedExport.result
   * @returns {string[]} - The array of keys. Which are promptBlocks[i].chainKey
   * @example
   * // apiProjectSdk.chain.promptBlocks.map((block) => block.valuesLinkedExport.result) = ['key1', 'key2', 'key3', 'key4', 'key5', 'key6', 'key7', 'key8']
   * // apiProjectSdk.chain.promptBlocks.map((block) => block.chainKey) = ['0', '1', '1-0', '0-0', '0-1', '0-0-1', '0-0-2', ' 0-0-1-0']
   * const requiredKeys = apiProjectSdk.getRequiredChainKeysForResultValue('key8');
   * // ['0', '0-0', '0-0-1', ' 0-0-1-0']
   * // 0 is the first block of the chain
   */
  getRequiredChainKeysForResultValue(valueName) {
    const block = this.chain.promptBlocks.find(
      (block) => block.valuesLinkedExport.result === valueName
    );
    if (!block) {
      throw new Error(`No block found with valueName: ${valueName}`);
    }
    const requiredKeys = this.getRequiredChainKeysForChainKey(block.chainKey);
    return requiredKeys;
  }

  /**
   * @method getRequiredChainKeysForAliasName(aliasName) return array of keys
   * @param {string} aliasName - The aliasName of the result. Which is promptBlocks[i].valuesLinkedExport.alias
   * @returns {string[]} - The array of keys. Which are promptBlocks[i].chainKey
   * @example
   * const requiredKeys = apiProjectSdk.getRequiredChainKeysForAliasName('alias1');
   * // ['0', '0-0', '0-0-1', ' 0-0-1-0']
   */
  getRequiredChainKeysForAliasName(aliasName) {
    const block = this.chain.promptBlocks.find(
      (block) => block.aliasName === aliasName
    );
    if (!block) {
      throw new Error(`No block found with aliasName: ${aliasName}`);
    }
    const requiredKeys = this.getRequiredChainKeysForChainKey(block.chainKey);
    return requiredKeys;
  }

  /**
   * @method createDataSet(initialValues) return new dataSet
   * @param {object} initialValues - The initialValues of the dataSet which is set to results property.
   * @returns {ApiProjectSdk.DataSet} - The dataSet.
   * @example
   * const dataSet = apiProjectSdk.createDataSet({key1: 'value1', key2: 'value2'});
   * // {id: 'id', name: 'name', uid: 'uid', apiProjectId: 'apiProjectId', chainId: 'chainId', results: {key1: 'value1', key2: 'value2'}, parameters: {}, status: 'created'}
   */
  async createDataSet(
    initialParameters = {},
    initialValues = {},
    uid = null,
    data = {}
  ) {
    const allowedDataKeys = ["name", "description", "status"];
    const dataCleaned = Object.keys(data).reduce((acc, key) => {
      if (allowedDataKeys.includes(key)) {
        acc[key] = data[key];
      }
      return acc;
    }, {});

    const dataSet = new DataSet({
      data: {
        results: initialValues,
        parameters: initialParameters,
        apiProjectId: this.project.id,
        chainId: this.chain.id,
        uid,
        ...dataCleaned
      },
      apiProject: this.project,
      chain: this.chain,
      db: this.db,
      AIengineApiUrls: this.AIengineApiUrls,
      ApiProjectSdkParent: this
    });
    const newId = await dataSet.initializeCreation(uid);
    return newId;
  }

  /**
   * @method getDataSet(dataSetId) return dataSet
   * @param {string} dataSetId - The id of the dataSet.
   * @returns {ApiProjectSdk.DataSet} - The dataSet.
   * @example
   * const dataSet = apiProjectSdk.getDataSet('dataSetId');
   */
  async getDataSet(dataSetId) {
    const dataSet = new DataSet({
      data: {
        id: dataSetId
      },
      apiProject: this.project,
      chain: this.chain,
      db: this.db,
      AIengineApiUrls: this.AIengineApiUrls,
      ApiProjectSdkParent: this
    });
    await dataSet.initialize();
    return dataSet;
  }

  /**
   * @method initDataSet(defaultParameters,defaultResults) return dataSet
   * @param {object} defaultParameters - The defaultParameters of the dataSet which is set to parameters property.
   * @param {object} defaultResults - The defaultResults of the dataSet which is set to results property.
   * @returns {ApiProjectSdk.DataSet} - The dataSet.
   * @example
   * const dataSet = apiProjectSdk.initDataSet({key1: 'value1', key2: 'value2'}, {key1: 'value1', key2: 'value2'});
   */
  initDataSet(defaultParameters = {}, defaultResults = {}) {
    const dataSet = new DataSet({
      data: {},
      apiProject: this.project,
      chain: this.chain,
      db: this.db,
      AIengineApiUrls: null,
      ApiProjectSdkParent: this,
      isDataExternal: this.isDataSetsExternal
    });
    dataSet.initializeWithNoDb(defaultParameters, defaultResults);
    return dataSet;
  }

  // Methods for listening to datasets using onSnapshot
  /**
   * @method onSnapshotDataSets(dataSetId, callback) return dataSet
   * @param {string} dataSetId - The id of the dataSet.
   * @param {function} callback - The callback function to be called when the dataSet changes.
   * @returns {function} - The unsubscribe function.
   * @example
   * const unsubscribe = apiProjectSdk.onSnapshotDataSet('dataSetId', (dataSet) => {
   *  console.log(dataSet);
   * });
   * // unsubscribe();
   * // unsubscribe the listener
   */
  onSnapshotDataSets(callback) {
    const unsubscribe = this.apiProjectFirestoreDb.getDataSets(this.chain.id, {
      onSnapshot: (dataSets) => {
        callback(dataSets);
      },
      returnOnSnapshot: true
    });
    return unsubscribe;
  }
}

/**
 ****************************************************************************************************************************************************************
 ****************************************************************************************************************************************************************
 ****************************************************************************************************************************************************************
 */

/**
 * @info AIengineApi
 * - AIengineApi is a serverless function that is used to run the chain
 * - One specific route will be called for exexuting completion type blocks
 */

const runsUrls = {
  completion: `${process.env.REACT_APP_API_URL}/openai/m/complete`
  // completionCode : `${process.env.REACT_APP_API_URL}/openai/m/complete-code`,
};

/**
 * @api /openai/m/complete
 * @method POST
 * @param {string} prompt - The prompt of the completion.
 * @returns {AxiosObject} - The axios response object.
 * @return {object} - The response.data of the axios response object.
 * @return {string} - response.data.choices[0].text which is the completion.
 * @example
 * const response = await axios.post('/openai/m/complete', {
 * prompt: 'This is a test',
 * });
 * const completion = response.data.choices[0].text;

/**
 * @typedef {Object} AIengineApiOption
 * @property {{string : string}} urls - The urls of the AIengineApi.
 * @property {string} urls.completion - The completion url of the AIengineApi.
 * @function completion(prompt) - The completion function of the AIengineApi.
 * - @param {string} prompt - The prompt of the completion.
 * - @returns {Promise} - The promise of the completion.
 * - @returns {string} - The completion.
 * @example
 * const completion = await AIengineApi.getCompletion('This is a test');
 * console.log(completion);
 */
const CHAT_MODELS = [
  "gpt-4-turbo-preview",
  "gpt-4",
  "gpt-3.5-turbo",
  "gpt-3.5-turbo-16k",
  "gpt-4-32k",
  "gpt-4-1106-preview",
  "gpt-4-0125-preview"
];
const ONLY_COMPLETION_MODELS = ["text-davinci-003", "code-davinci-002"];

const getFirebaseToken = async () => {
  const token = await firebaseAuth.currentUser.getIdToken();
  return token;
};

const getHeaders = async () => {
  const token = await getFirebaseToken();
  return {
    Authorization: `Bearer ${token}`
  };
};

async function retry(fn, errorHandling = () => {}, maxRetries = 3) {
  let attempts = 0;

  while (attempts <= maxRetries) {
    try {
      return await fn();
    } catch (errorResponse) {
      let errorHandlingObject = {
        errorResponse,
        type: "retry",
        attempts: attempts + 1
      };

      errorHandlingObject.errorBody = await errorResponse.json();

      console.log("errorResponse in retry", errorResponse);

      if (errorResponse.status === 429) {
        errorHandlingObject.type = "tooManyRequests";
        const waitTime = Math.pow(4, attempts + 1) * 1000; // Exponential backoff
        attempts++;
        if (attempts <= maxRetries) {
          errorHandlingObject.isRetrying = true;
          errorHandling(errorHandlingObject);
          await new Promise((resolve) => setTimeout(resolve, waitTime));
        }
      } else if (errorResponse.status >= 400) {
        errorHandlingObject.type = "internalServerError";
        errorHandling(errorHandlingObject);
        attempts = maxRetries + 1;
      } else {
        throw errorResponse;
      }
    }
  }

  errorHandling({
    type: "maxRetriesExceeded",
    attempts
  });
  throw new Error("Max retries exceeded");
}
class AIEngineApi {
  constructor(options = {}) {
    this.urls = options.urls || runsUrls;
    this.publisher = options.publisher || PubSub;
  }

  errorHandling(error) {
    const { type, attempts, isRetrying, errorResponse, errorBody } = error;

    if (type === "tooManyRequests") {
      this.publisher.publish("ai-engine-api/too-many-requests", {
        attempts,
        isRetrying,
        type: "tooManyRequests"
      });
    } else if (type === "internalServerError") {
      this.publisher.publish("ai-engine-api/internal-server-error", {
        attempts,
        isRetrying,
        type: "internalServerError",
        errorResponse,
        errorBody
      });
    } else if (type === "maxRetriesExceeded") {
      this.publisher.publish("ai-engine-api/max-retries-exceeded", {
        attempts,
        isRetrying,
        type: "maxRetriesExceeded"
      });
    }
  }

  /**
   * @method completion(prompt,model) return the completion of the prompt
   * @param {string} prompt - The prompt of the completion.
   * @returns {Promise} - The promise of the completion.
   * @returns {string} - The completion.
   * @example
   * const completion = await AIengineApi.getCompletion('This is a test');
   * console.log(completion);
   */
  async completion(
    prompt,
    model = "text-davinci-003",
    options = { stream: false }
  ) {
    const headers = await getHeaders();
    const response = await axios.post(
      this.urls.completion,
      {
        prompt,
        model,
        stream: options.stream
      },
      headers
    );
    return response.data.choices[0].text.trim();
  }

  async getCompletion(messages, model = "gpt-4", options = { stream: false }) {
    const fn = async () => {
      const headers = await getHeaders();
      // with no credentials

      let bodyExtention = {};

      if (CHAT_MODELS.includes(model)) {
        bodyExtention = {
          messages
        };
      } else if (ONLY_COMPLETION_MODELS.includes(model)) {
        bodyExtention = {
          prompt: messages
        };
      }

      const response = await fetch(this.urls.completion, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...headers
        },
        body: JSON.stringify({
          model,
          stream: options.stream,
          ...bodyExtention
        }),
        credentials: "omit"
      });

      if (response.status >= 400) {
        throw response;
      }

      if (!options.stream) {
        const data = await response.json();
        const { text } = data.choices[0];
        // const { text } = response.data.choices[0];
        return text ? text.trim() : "";
      } else {
        const reader = response.body.getReader();
        // const stream = response.data;
        const { onDataStream, onErrorStream, onDataStreamEnd } = options;

        const finalData = await (async () => {
          const dataChunksStr = [];

          while (true) {
            const { done, value } = await reader.read();
            if (done) {
              if (onDataStreamEnd)
                onDataStreamEnd(dataChunksStr.join("").trim());
              return dataChunksStr;
            }
            const text = new TextDecoder().decode(value);
            dataChunksStr.push(text);
            if (onDataStream) onDataStream(text, dataChunksStr.join(""));
          }
        })();
        return finalData.join("").trim();
      }
    };

    return await retry(fn, this.errorHandling.bind(this));
  }
}

// imported PubSub from "pubsub-js";

/**
 * React hook to send fetch's requests error and accept a callback to handle the error, can set a sepecific token to listen to the error
 * for preventing the error to be handled by other components
 *
 * it will create two internal subsctiptions to the global PubSub
 *
 * then it will return two functions to unsubscribe from the global PubSub
 *
 * subscribe to :
 *  - "ai-engine-api/too-many-requests"
 * - "ai-engine-api/internal-server-error"
 * - "ai-engine-api/max-retries-exceeded"
 *
 * return [lastError, unsubscribe]
 */
export const useEngineFetchError = () => {
  const [lastError, setLastError] = useState(null);

  useEffect(() => {
    const limitReachedToken = PubSub.subscribe(
      "ai-engine-api/too-many-requests",
      (msg, data) => {
        setLastError(data);
      }
    );
    const internalServerToken = PubSub.subscribe(
      "ai-engine-api/internal-server-error",
      (msg, data) => {
        setLastError(data);
      }
    );
    const maxRetriesExceededToken = PubSub.subscribe(
      "ai-engine-api/max-retries-exceeded",
      (msg, data) => {
        setLastError(data);
      }
    );

    return () => {
      PubSub.unsubscribe(limitReachedToken);
      PubSub.unsubscribe(internalServerToken);
      PubSub.unsubscribe(maxRetriesExceededToken);
    };
  }, []);

  const cleanError = () => {
    setLastError(null);
  };

  return [lastError, cleanError];
};

// FullChunk checks and parsing

// return whether the start of the current entire data seems to be a stringified JSON object, array, or just a string
const getTypeOfCurrentChunksText = (chunksText) => {
  const firstChar = chunksText[0];
  if (firstChar === "{") return "object";
  if (firstChar === "[") return "array";
  return "string";
};

// Will return the full stringified JSON object, array, or string by completing the current chunk with the missing closing brackets
// If it is a string, it will return the string
// If it is an array it will render the text but until the last full string array item and complete it with the missing closing brackets
// (TODO) If it is an object it will look for the last full object item and complete it with the missing closing brackets
const completeEndCurrentChunksText = (chunksText) => {
  const type = getTypeOfCurrentChunksText(chunksText);
  if (type === "string") return chunksText;
  if (type === "array") {
    // regexStringItems matches'"abc"'
    // regexStringItems matches '"abc\"def"'
    // /"(.*?)\"/g
    const regexStringItems = /"(?:\\"|[^"])*"/g;
    let regexStringItemsMatch = chunksText.match(regexStringItems);
    regexStringItemsMatch = regexStringItemsMatch.map((item) =>
      JSON.parse(item)
    );
    return JSON.stringify(regexStringItemsMatch);
  }
};

/**
 ****************************************************************************************************************************************************************
 ****************************************************************************************************************************************************************
 ****************************************************************************************************************************************************************
 */

/**
 * @typedef {Object} Chain - The chain object.
 * @property {string} id - The id of the chain.
 * @property {string} uid - The user id of the user who created the chain.
 * @property {string} apiProjectId - The apiProjectId of the chain.
 * @property {string} name - The name of the chain.
 * @property {string} status - The status of the chain.
 * @property {[PromptBlock]} promptBlocks - The promptBlocks of the chain.
 * @property {string} createdAt - The date the chain was created.
 * @property {string} updatedAt - The date the chain was last updated.
 */

/**
 * @typedef {Object} BuiltPrompt
 * @property {string} id - The id of the builtPrompt.
 * @property {string} uid - The user id of the user who created the builtPrompt.
 * @property {string} model - The model of the builtPrompt.
 * @property {Object} modeConfig - The modeConfig of the builtPrompt.
 * @property {string} promptName - The name of the builtPrompt.
 * @property {string} prompt - The prompt of the builtPrompt.
 * @property {string} example - The example of the builtPrompt.
 * @property {[string]} valueKeys - The value keys of the builtPrompt.
 * @property {string} createdAt - The date the builtPrompt was created.
 * @property {string} updatedAt - The date the builtPrompt was last updated.
 * @property {GptConfig} gptConfig - The gptConfig of the builtPrompt.
 */

/**
 * @typedef {Object} PromptBlock - The promptBlock object.
 * @property {string} chainKey - The chainKey of the promptBlock.
 * @property {string} aliasName - The aliasName of the promptBlock.
 * @property {string} builtPromptId - The builtPromptId of the promptBlock.
 * @property {string} blockType - The blockType of the promptBlock.
 * @property {string} logicType - The logicType of the promptBlock.
 * @property {Object} valuesLogicLinkedImport - The valuesLogicLinkedImport of the promptBlock.
 * @property {Object} valuesLinkedImport - The valuesLinkedImport of the promptBlock.
 * @property {Object} valuesLinkedExport - The valuesLinkedExport of the promptBlock.
 * @property {string} createdAt - The date the promptBlock was created.
 */

/**
 * @typedef {Object} DataSetData
 * @property {string} id - The id of the dataSet.
 * @property {string} uid - The uid of the dataSet.
 * @property {string} apiProjectId - The apiProjectId of the dataSet.
 * @property {string} chainId - The chainId of the dataSet.
 * @property {object} results - The results of the dataSet.
 * @property {object} parameters - The parameters of the dataSet.
 * @property {string} createdAt - The createdAt of the dataSet.
 * @property {string} updatedAt - The updatedAt of the dataSet.
 */

/**
 * @typedef {Object} ApiProjectSdk.DataSet
 * @constructor ApiProjectSdk.DataSet
 * @param {object} config - The initial config
 * @param {object} config.data.results - The initial results
 * @param {object} config.data.parameters - The initial parameters
 * @param {ApiProject} config.apiProject - The apiProject
 * @param {Chain} config.chain - The chain
 * @param {FireStoreDb} config.db - The firestore db connection
 * @param {{string : string}} config.AIengineApiUrls - The AIengineApiUrls
 *
 * @info DataSet properties
 * @property {DataSetData} data - The data object of the dataSet.
 * @property {FireStoreDb} db - The firestore db connection.
 * @property {AIEngineApi} AIengineApi - The AIengineApi.
 * @property {ApiProjectFirestoreDb} apiProjectFirestoreDb - The apiProjectFirestoreDb.
 * @property {ApiProject} apiProject - The apiProject.
 * @property {Chain} chain - The chain.
 * @property {Object} blocksMap - The blocksMap of the chain with chainKeys as keys.
 * @property {Subject} dataObservable - The dataObservable of the dataSet.
 *
 * @info data getting methods
 * @method onSnapshot(callback) return the dataSet data on snapshot and onSnapshot return a function to unsubscribe
 * - @param {function} callback - The callback function to be called on snapshot.
 * - @returns {function} - The unsubscribe function.
 * @method getFromDB() return the dataSet data
 * - @returns {Promise} - The promise of the dataSet data.
 *
 * @info block methods
 * @method getBlock(chainKey) return the block of the blockKey
 * @method getBlockByAliasName(aliasName) return the block of the aliasName
 * @method getBlockByExportedValueKey(valueKey) return the block of the valueKey
 * @method checkBlockMissingValueKeys(chainKey,variableType) check if the block has missing imported valueKeys/parameters, by type
 * @method getVariableNameByChainKey(chainKey) return the result variableName of the block
 *
 * @info instance methods
 * @method initializeCreation() initialize the dataSet by saving data to the db if id is not defined
 * @method initialize() initialize the dataSet creation by fetching data from the db if id is defined and set it to the data object
 * @info data manipulation methods
 * @method setParameters(parameters) update the parameters locally without saving
 * - @param {Object} parameters - The parameters of the dataSet.
 * @method setResults(results) update the results locally without saving
 * - @param {Object} results - The results of the dataSet.
 * @method runByExportedVariables(...valueLinkedExportResults) run the blocks for the valueLinkedExportResults, and update result locally without saving
 * @method runBlocks(...chainKeys/aliases) run the blocks for the chainKeys/aliasNames, and update result locally without saving
 * - @param {string} chainKeys/aliases - The chainKeys/aliases of the dataSet.
 * @method runResultsBlocks(...valuesNames) run the blocks for the valuesNames, and update result locally without saving
 * - @param {string} valuesNames - The valuesNames of the dataSet.
 * @method saveParameters() save the parameters locally and remotely
 * @method saveResults() save the results locally and remotely
 */

const defaultConfig = {
  data: {},
  db: null,
  AIengineApiUrls: {},
  ApiProjectSdkParent: null
};

const defaultDataSetData = {
  results: {},
  parameters: {}
};

class DataSet {
  constructor(config = defaultConfig) {
    this.dataObservable = new Subject();

    this.db = config.db;
    this.ApiProjectSdkParent = config.ApiProjectSdkParent;
    this.isDataExternal = config.isDataExternal;

    this.chain = config.chain;
    this.apiProject = config.apiProject;
    this.initializeHelperMaps();

    this.AIengineApi = new AIEngineApi({
      urls: config.AIengineApiUrls
    });
    this.apiProjectFirestoreDb = new ApiProjectFirestoreDb(
      this.db,
      this.apiProject.id
    );

    // this.getDefaultResults
    this.data = !!config.data
      ? { ...defaultDataSetData, ...config.data }
      : defaultDataSetData;

    this.data.results = {
      ...this.getDefaultResults(),
      ...this.data.results
    };
  }

  /**
   * @private
   * @method initializeHelperMaps() initialize the helper maps
   */
  initializeHelperMaps() {
    this.chainKeyBlocksMap = this.chain.promptBlocks.reduce((acc, block) => {
      acc[block.chainKey] = block;
      return acc;
    }, {});
    this.exportedValueKeyBlocksMap = this.chain.promptBlocks.reduce(
      (acc, block) => {
        acc[block.valuesLinkedExport.result] = block;
        return acc;
      },
      {}
    );
  }

  async initializeWithNoDb(defaultParameters, defaultResults) {
    const newData = {
      ...this.data,
      parameters: {
        ...this.data.parameters,
        ...defaultParameters
      },
      results: {
        ...this.getDefaultResults(),
        ...defaultResults
      }
    };
    this.setData(newData);
  }

  /**
   * @method initializeCreation() initialize the dataSet by saving data to the db if id is not defined
   * @returns {Promise<DataSetData>} - The promise of the dataSet data.
   * @example
   * const dataSet = new DataSet();
   * await dataSet.initializeCreation();
   */
  async initializeCreation(uid = null) {
    if (!this.data.id) {
      const newId = await this.apiProjectFirestoreDb.createDataSet(
        this.chain.id,
        { ...this.data, uid }
      );
      this.setData({ id: newId });
      return newId;
    } else {
      throw new Error("DataSet already initialized");
    }
  }

  /**
   * @method initialize() initialize the dataSet creation by fetching data from the db if id is defined and set it to the data object
   * @returns {Promise<DataSetData>} - The promise of the dataSet data.
   * @example
   * const dataSet = new DataSet();
   * await dataSet.initialize();
   * console.log(dataSet.data);
   */
  async initialize() {
    if (this.data.id) {
      const fetchedData = await this.apiProjectFirestoreDb.getDataSet(
        this.chain.id,
        this.data.id
      );
      const newData = {
        ...fetchedData,
        results: {
          ...this.data.results,
          ...fetchedData.results
        }
      };

      this.setData(newData);
      return this;
    } else {
      throw new Error("DataSet not initialized because id is not defined");
    }
  }

  /**
   * @method onSnapshot(callback) return the dataSet data on snapshot and onSnapshot return a function to unsubscribe
   * @param {function} callback - The callback function to be called on snapshot.
   * @returns {function} - The unsubscribe function.
   * @example
   * const dataSet = new DataSet();
   * await dataSet.initialize();
   * const unsubscribe = dataSet.onSnapshot((dataSetData) => {});
   */
  onSnapshot(callback) {
    return this.apiProjectFirestoreDb.getDataSet(this.chain.id, this.data.id, {
      returnOnSnapshot: true,
      onSnapshot: callback
    });
  }

  /**
   * @method getSubscriber() return the dataSet data subscriber
   * @returns {Observable<DataSetData>} - The dataSet data subscriber.
   * @example
   * const dataSet = new DataSet();
   * await dataSet.initialize();
   * const dataSetDataSubscriber = dataSet.getSubscriber();
   * dataSetDataSubscriber.subscribe((dataSetData) => {});
   */
  getSubscriber(clbk) {
    return this.dataObservable.subscribe(clbk);
  }

  /**
   * @method callNext() call the next on the dataSet data subscriber
   */
  callNext() {
    this.dataObservable.next(this.data);
  }

  /**
   * @method getFromDB() return the dataSet data
   * @returns {Promise<DataSetData>} - The promise of the dataSet data.
   * @example
   * const dataSet = new DataSet();
   * await dataSet.initialize();
   * const dataSetData = await dataSet.get();
   */
  async getFromDB() {
    return await this.apiProjectFirestoreDb.getDataSet(
      this.chain.id,
      this.data.id
    );
  }

  /**
   * @method setData(data) set the dataSet data
   * @param {DataSetData} data - The dataSet data.
   * @example
   * const dataSet = new DataSet();
   * await dataSet.initialize();
   * dataSet.setData({results: {valueKey: 1}});
   */
  setData(data, emit = true) {
    this.data = { ...this.data, ...data };
    if (emit) {
      this.dataObservable.next({ ...this.data });
    }
  }

  /**
   * @method setParameters(parameters,emit) update the parameters locally without saving
   * @param {Object} parameters - The parameters of the dataSet.
   */
  setParameters(parameters, emit = true) {
    this.data.parameters = { ...this.data.parameters, ...parameters };
    if (emit) {
      this.dataObservable.next({ ...this.data });
    }
  }

  /**
   * @method setResults(results) update the results locally without saving
   * @param {Object} results - The results of the dataSet.
   */
  setResults(results, emit = true) {
    this.data.results = { ...this.data.results, ...results };
    if (emit) {
      this.dataObservable.next({ ...this.data });
    }
  }

  async updateData(data, emit = true) {
    const allowedFields = ["name", "description"];
    const newData = Object.keys(data).reduce((acc, key) => {
      if (allowedFields.includes(key)) {
        acc[key] = data[key];
      }
      return acc;
    }, {});
    this.data = { ...this.data, ...newData };
    if (emit) {
      this.dataObservable.next({ ...this.data });
    }

    await this.saveData();
  }

  // database methods

  /**
   * @method saveData() save the data locally and remotely
   */
  async saveData() {
    await this.apiProjectFirestoreDb.updateDataSet(
      this.chain.id,
      this.data.id,
      this.data
    );
  }

  // dataset data methods

  /**
   * @method getDefaultResults() get the default results of the dataSet
   * @returns {Object} - The default results of the dataSet.
   * @description
   *   if block are completion mode the value of the results[key] is empty string
   *   if block are logic and logicType is "loopingResult" the value of the results[key] is empty array
   * use this.chain.promptBlocks to get the block list and set the results
   */
  getDefaultResults() {
    const results = {};
    this.chain.promptBlocks.forEach((block) => {
      const { blockType, logicType, valuesLinkedExport } = block;
      const { result: resultKey } = valuesLinkedExport;

      if (blockType === "completion") {
        const importedArrayValueKey =
          this.ApiProjectSdkParent.getImportedArrayValueKey(resultKey);
        if (importedArrayValueKey) {
          results[valuesLinkedExport.result] = [];
        } else {
          results[valuesLinkedExport.result] = "";
        }
      } else if (blockType === "logic" && logicType === "loopResult") {
        results[valuesLinkedExport.result] = [];
      } else if (blockType === "logic" && logicType === "concatResult") {
        results[valuesLinkedExport.result] = "";
      }
    });
    return results;
  }

  // block methods

  /**
   * @method loopParseValueToArray(string)
   * @param {string} string - The string to parse.
   * @returns [string] - The array of string.
   * looping parse techniques
   * // only 2 techniques for now
   * // 1. ["1","2"]
   * // 2. "1\\n---\\n2"
   * // 3. "\n\n" // more than 2 "\n" in a row
   * // 5. "\n"
   * // 4. "."
   * // 6. " "
   * @example
   * loopParseValueToArray('["1","2"]') // return ['1','2']
   * loopParseValueToArray('"1\\n---\\n2"') // return ['1','2']
   */
  loopParseValueToArray(string) {
    let array = [];
    if (string.startsWith("[") && string.endsWith("]")) {
      array = JSON.parse(string);
    } else if (string.includes("\n---\n")) {
      array = string.split("\n---\n");
    }
    // check if includes more than 2 "\n" in a row
    else if (/(\n{2,})/.test(string)) {
      array = string.split(/(\n{2,})/gi);
    } else if (string.includes("\n")) {
      array = string.split("\n");
    } else if (string.includes(".")) {
      array = string.split(".");
    } else if (string.includes(" ")) {
      array = string.split(" ");
    } else {
      throw new Error(
        `loopParseValueToArray: string not valid and is not parsable: ${string}`
      );
    }
    // trim the array
    array = array.map((item) => {
      let newItem = item;
      if (typeof item === "object") {
        newItem = JSON.stringify(item);
      }
      return newItem.trim();
    });
    return array;
  }

  /**
   * @method checkBlockMissingResultsAndParameters_byVariableNames(...exportedVariables) check if the block has missing imported valueKeys/parameters, by type
   * @param {string[]} exportedVariables - The chainKey of the dataSet.
   * @returns {[string][]} missingValueKeys - The missingValueKeys of the dataSet.
   * @throws {Error} - If the chainKey is not found.
   */
  checkBlockMissingValueKeysImportResult(...exportedVariablesParams) {
    const missingParameters = [];
    const missingResults = [];
    let exportedVariables = exportedVariablesParams.filter(
      (item) => item !== undefined
    );
    let lastParam = exportedVariables[exportedVariables.length - 1];
    let resultIndex = typeof lastParam === "number" ? lastParam : null;

    if (resultIndex !== null && resultIndex >= 0) {
      exportedVariables = exportedVariables.slice(0, -1);
    } else if (resultIndex === null) {
      exportedVariables = exportedVariables.slice(0, -1);
    }

    exportedVariables.forEach((exportedVariable) => {
      const block = this.exportedValueKeyBlocksMap[exportedVariable];
      if (!block) {
        throw new Error(
          `Block no found for exportedVariable: ${exportedVariable}`
        );
      } else {
        const { blockType } = block;
        if (blockType === "completion") {
          const { valuesLinkedImport } = block;
          Object.keys(valuesLinkedImport).forEach((key) => {
            const { value, type, defaultResult } = valuesLinkedImport[key];

            if (!!defaultResult) {
              return;
            }

            if (type === "input") {
              const parameterKey = value || key;
              if (!this.data.parameters[parameterKey]) {
                missingParameters.push(parameterKey);
              }
            } else if (type === "computed") {
              let isParameterValueLinkedToALoopResult =
                this.ApiProjectSdkParent.getImportedArrayValueKey(value);

              if (
                !isParameterValueLinkedToALoopResult &&
                !this.data.results[value]
              ) {
                missingResults.push(value);
              } else if (isParameterValueLinkedToALoopResult) {
                if (resultIndex !== null && resultIndex >= 0) {
                  if (!Array.isArray(this.data.results[value])) {
                    throw new Error(
                      `Using resultIndex but the result is not an array, resultKey: ${value} resultIndex: ${resultIndex} result: ${this.data.results[value]}`
                    );
                  }
                  if (!this.data.results[value][resultIndex]) {
                    missingResults.push(`${value}[${resultIndex}]`);
                  }
                } else {
                  throw new Error(
                    `Using a computed value that is linked to an array but no resultIndex was provided, resultKey: ${value} resultIndex: ${resultIndex} result: ${this.data.results[value]}`
                  );
                }
              }
            }
          });
        } else if (blockType === "logic") {
          const { valuesLogicLinkedImport } = block;
          const { source } = valuesLogicLinkedImport;
          if (!this.data.results[source]) {
            missingResults.push(source);
          }
        }
      }
    });

    return [missingParameters, missingResults];
  }

  /**
   * @method getPromptOfBlock(block,builtPrompt) get the prompt text of the block
   * @param {Block} block - The block of the dataSet.
   * @param {Object} builtPrompt - The builtPrompt of the dataSet.
   * @returns {string} - The prompt text of the block.
   */
  getPromptOfBlock(block, builtPrompt, resultIndex = null, forcedValues = {}) {
    const { prompt, valueKeys } = builtPrompt;
    const { valuesLinkedImport } = block;
    const { forcedParameters = {}, forcedResults = {} } = forcedValues;
    const parameters = {};
    let promptWithParameters = prompt;

    valueKeys.forEach((valueKey) => {
      const { value, type, defaultResult } = valuesLinkedImport[valueKey];
      if (type === "input") {
        const parameterKeyValue = value || valueKey;
        parameters[parameterKeyValue] =
          forcedParameters[parameterKeyValue] ||
          this.data.parameters[parameterKeyValue] ||
          defaultResult;

        const greedyRegex = new RegExp(`{{${valueKey}}}`, "g");
        promptWithParameters = promptWithParameters.replace(
          greedyRegex,
          parameters[parameterKeyValue]
        );
      } else if (type === "computed") {
        const blockOfComputedValue = this.exportedValueKeyBlocksMap[value];
        let parametersValue = null;

        let isParameterValueLinkedToALoopResult =
          this.ApiProjectSdkParent.getImportedArrayValueKey(value);
        let isParameterLoopResult =
          blockOfComputedValue &&
          blockOfComputedValue.blockType === "logic" &&
          blockOfComputedValue.logicType === "loopResult";

        if (isParameterLoopResult || isParameterValueLinkedToALoopResult) {
          if (resultIndex === undefined || resultIndex === null) {
            throw new Error(`resultIndex is required for loopResult`);
          }
          parametersValue =
            forcedResults[value] ||
            this.data.results[value][resultIndex] ||
            defaultResult ||
            "";
        } else {
          parametersValue =
            forcedResults[value] || this.data.results[value] || defaultResult;
        }

        parameters[value] = parametersValue;

        const greedyRegex = new RegExp(`{{${valueKey}}}`, "g");
        promptWithParameters = promptWithParameters.replace(
          greedyRegex,
          parameters[value]
        );
      }
    });

    return promptWithParameters;
  }

  /**
   * @method runByExportedVariables(...exportedVariables) run the blocks for the exportedVariables, and update result locally without saving
   * @param {[string]} exportedVariables - The exportedVariables of the dataSet.
   * @returns {Promise<[Block]>} - The promise of the blocks successfully run.
   * @throws {Error} - If the exportedVariable is not found.
   * @throws {Error} - If the required parameters(input or computed) of the block are not initialized.
   * @example
   * const dataSet = new DataSet();
   * await dataSet.initialize();
   * await dataSet.runByExportedVariables('myVariable', 'anotherVariableFromAnotherBlock');
   * console.log(dataSet.data.results);
   * // resuls has been updated with the exportedVariables
   */
  async runByExportedVariables(...params) {
    let lastParam = params[params.length - 1];
    const blocksRun = [];
    let exportedVariables = [...params];
    let resultIndex;
    let shouldStream = true;

    if (
      lastParam &&
      typeof lastParam === "object" &&
      typeof lastParam.stream === "boolean"
    ) {
      shouldStream = lastParam.stream;
      exportedVariables = exportedVariables.slice(
        0,
        exportedVariables.length - 1
      );
      lastParam = exportedVariables[exportedVariables.length - 1];
    }

    if (typeof lastParam === "number") {
      exportedVariables = exportedVariables.slice(
        0,
        exportedVariables.length - 1
      );
      resultIndex = lastParam;
    } else if (lastParam === undefined) {
      exportedVariables = exportedVariables.slice(
        0,
        exportedVariables.length - 1
      );
    }

    const [missingParameters, missingResults] =
      this.checkBlockMissingValueKeysImportResult(
        ...exportedVariables,
        resultIndex
      );

    if (missingParameters.length > 0 || missingResults.length > 0) {
      throw new Error(
        `Missing parameters: ${missingParameters.join(
          ", "
        )} and/or missing results: ${missingResults.join(", ")}`
      );
    }

    for (let i = 0; i < exportedVariables.length; i++) {
      const exportedVariable = exportedVariables[i];
      const block = this.exportedValueKeyBlocksMap[exportedVariable];
      const { blockType } = block;

      if (blockType === "completion") {
        const { builtPromptId, writingMode = "overwrite" } = block;
        const builtPrompt = await this.apiProjectFirestoreDb.getBuiltPrompt(
          builtPromptId
        );
        const { prompt, example, valueKeys, model } = builtPrompt;
        const { valuesLinkedImport } = block;
        const parameters = {};
        let promptWithParameters = prompt;
        let hasImportedArrayValueKey = false;

        valueKeys.forEach((valueKey) => {
          const { value, type, defaultResult } = valuesLinkedImport[valueKey];
          if (type === "input") {
            const parameterKeyValue = value || valueKey;
            parameters[parameterKeyValue] =
              this.data.parameters[parameterKeyValue] || defaultResult;

            const greedyRegex = new RegExp(`{{${valueKey}}}`, "g");
            promptWithParameters = promptWithParameters.replace(
              greedyRegex,
              parameters[parameterKeyValue]
            );
          } else if (type === "computed") {
            const blockOfComputedValue = this.exportedValueKeyBlocksMap[value];
            let parametersValue = null;

            let isParameterValueLinkedToALoopResult =
              this.ApiProjectSdkParent.getImportedArrayValueKey(value);
            let isParameterLoopResult =
              blockOfComputedValue &&
              blockOfComputedValue.blockType === "logic" &&
              blockOfComputedValue.logicType === "loopResult";

            if (isParameterLoopResult || isParameterValueLinkedToALoopResult) {
              if (resultIndex === undefined || resultIndex === null) {
                throw new Error(`resultIndex is required for loopResult`);
              }
              hasImportedArrayValueKey = true;
              parametersValue =
                this.data.results[value][resultIndex] || defaultResult || "";
            } else {
              parametersValue = this.data.results[value] || defaultResult;
            }

            parameters[value] = parametersValue;

            const greedyRegex = new RegExp(`{{${valueKey}}}`, "g");
            promptWithParameters = promptWithParameters.replace(
              greedyRegex,
              parameters[value]
            );
          }
        });

        let appendText = "";

        if (writingMode === "append") {
          if (hasImportedArrayValueKey) {
            appendText = this.data.results[exportedVariable][resultIndex] || "";
          } else {
            appendText = this.data.results[exportedVariable] || "";
          }
        }

        const updateResult = (result) => {
          let newResult = null;
          if (hasImportedArrayValueKey) {
            newResult = this.data.results[exportedVariable];
            newResult = newResult || [];
            for (let i = 0; i < resultIndex; i++) {
              if (!newResult[i]) {
                newResult[i] = "";
              }
            }
            newResult[resultIndex] = appendText + result;
          } else {
            newResult = appendText + result;
          }
          this.setResults({ [exportedVariable]: newResult });
        };

        let result = null;

        if (CHAT_MODELS.includes(model)) {
          const { examples = [] } = builtPrompt;
          const finalMessages = [
            ...examples,
            {
              role: "user",
              content: promptWithParameters + appendText
            }
          ];
          result = await this.AIengineApi.getCompletion(finalMessages, model, {
            stream: shouldStream,
            onDataStream: (chunk, result) => {
              updateResult(result);
            },
            onDataStreamEnd: (result) => {
              updateResult(result);
            }
          });
        } else {
          const finalPrompt = example
            ? `${example}\n${promptWithParameters}`
            : promptWithParameters + appendText;
          result = await this.AIengineApi.getCompletion(finalPrompt, model, {
            stream: shouldStream,
            onDataStream: (chunk, result) => {
              updateResult(result);
            },
            onDataStreamEnd: (result) => {
              updateResult(result);
            }
          });
        }
        if (!shouldStream) {
          updateResult(result);
        }
      } else if (blockType === "logic") {
        const { logicType } = block;
        if (logicType === "loopResult") {
          const { valuesLogicLinkedImport } = block;
          const { source } = valuesLogicLinkedImport;
          const resultStringified = this.data.results[source];
          // const result = JSON.parse(resultStringified);
          const result = this.loopParseValueToArray(resultStringified);
          this.data.results[exportedVariable] = result;
          this.setResults({ [exportedVariable]: result });
        } else if (logicType === "concatResult") {
          const { valuesLogicLinkedImport } = block;
          const { source, concatSeparator = "\n" } = valuesLogicLinkedImport;
          const resultSourceArray = this.data.results[source];

          const result = resultSourceArray.join(concatSeparator);

          this.setResults({ [exportedVariable]: result });
        }
      }

      blocksRun.push(block);
    }

    return blocksRun;
  }

  /**
   * @method runAndGetByExportedVariable_noUpdate
   * @description Run the block and return the results by exportedVariables.
   *    - The results are not updated in the data.results.
   * @param {array} exportedVariable - The exportedVariable to run.
   * @param {number?} resultIndex - The resultIndex to run.
   * @return the result by exportedVariable
   */
  async runAndGetByExportedVariable_noUpdate(
    exportedVariable,
    resultIndex = null,
    options = { onDataStream: null, onDataStreamEnd: null, forcedValues: {} }
  ) {
    const [missingParameters, missingResults] =
      this.checkBlockMissingValueKeysImportResult(
        exportedVariable,
        resultIndex
      );

    if (missingParameters.length > 0 || missingResults.length > 0) {
      const { parameters = {}, results = {} } = options.forcedValues;
      if (missingParameters.length > 0) {
        missingParameters.forEach((missingParameter) => {
          if (!parameters[missingParameter]) {
            throw new Error(
              `Missing parameter: ${missingParameter} and no forced value provided.`
            );
          }
        });
      }
      if (missingResults.length > 0) {
        missingResults.forEach((missingResult) => {
          if (!results[missingResult]) {
            throw new Error(
              `Missing result: ${missingResult} and no forced value provided.`
            );
          }
        });
      }
    }

    const block = this.exportedValueKeyBlocksMap[exportedVariable];
    const { builtPromptId, blockType } = block;
    if (blockType === "completion") {
      const builtPrompt = await this.apiProjectFirestoreDb.getBuiltPrompt(
        builtPromptId
      );
      const { model } = builtPrompt;
      const promptText = this.getPromptOfBlock(
        block,
        builtPrompt,
        resultIndex,
        {
          forcedParameters: options.forcedValues.parameters || {},
          forcedResults: options.forcedValues.results || {}
        }
      );

      if (CHAT_MODELS.includes(model)) {
        const { examples = [] } = builtPrompt;
        const finalMessages = [
          ...examples,
          {
            role: "user",
            content: promptText
          }
        ];
        const result = await this.AIengineApi.getCompletion(
          finalMessages,
          model,
          {
            stream: true,
            onDataStream: options.onDataStream,
            onDataStreamEnd: options.onDataStreamEnd
          }
        );
        return result;
      } else {
        const result = await this.AIengineApi.getCompletion(promptText, model, {
          stream: true,
          onDataStream: options.onDataStream,
          onDataStreamEnd: options.onDataStreamEnd
        });
        return result;
      }
    } else if (blockType === "logic") {
      const { logicType } = block;
      if (logicType === "loopResult") {
        if (logicType === "loopResult") {
          const { valuesLogicLinkedImport } = block;
          const { source } = valuesLogicLinkedImport;
          const resultStringified = this.data.results[source];
          // const result = JSON.parse(resultStringified);
          const result = this.loopParseValueToArray(resultStringified);
          return result;
        } else if (logicType === "concatResult") {
          const { valuesLogicLinkedImport } = block;
          const { source, concatSeparator = "\n" } = valuesLogicLinkedImport;
          const resultSourceArray = this.data.results[source];

          const result = resultSourceArray.join(concatSeparator);
          return result;
        }
      }
    }
  }

  /**
   * @method addExamplesToBuiltPrompt
   * @description Add examples to a builtPrompt.
   *  - If built prompt is a chat model,
   *    the exampleCompletion the added examples will be:
   *      [{role: "user", content: prompt}, {role: "assistant", content: exampleCompletion}],
   *      and it will be added to builtPrompt.examples.
   *  - If built prompt is a completion model,
   *    the exampleCompletion the added examples will be:
   *      example = builtPrompt.example + "\n" + prompt + "\n" + exampleCompletion,
   *      and it will be added to builtPrompt.example.
   * @param {string} exportedVariable - The id of the builtPrompt.
   * @param {string} exampleCompletion - The examples completion to add.
   */
  async addExamplesToBuiltPrompt(
    exportedVariable,
    exampleCompletion,
    resultIndex
  ) {
    try {
      const block = this.exportedValueKeyBlocksMap[exportedVariable];
      const { builtPromptId } = block;
      const builtPrompt = await this.apiProjectFirestoreDb.getBuiltPrompt(
        builtPromptId
      );
      const promptText = this.getPromptOfBlock(block, builtPrompt, resultIndex);

      const { model } = builtPrompt;

      let updates = {};

      if (CHAT_MODELS.includes(model)) {
        const { examples = [] } = builtPrompt;
        const finalMessages = [
          ...examples,
          {
            role: "user",
            content: promptText
          },
          {
            role: "assistant",
            content: exampleCompletion
          }
        ];
        updates = {
          examples: finalMessages
        };
      } else {
        const { example = "" } = builtPrompt;
        updates = {
          example: example + "\n" + promptText + "\n" + exampleCompletion
        };
      }

      await this.apiProjectFirestoreDb.updateBuiltPrompt(
        builtPromptId,
        updates
      );

      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}

/**
 * @typedef {Object} Chain - The chain object.
 * @property {string} id - The id of the chain.
 * @property {string} uid - The user id of the user who created the chain.
 * @property {string} apiProjectId - The apiProjectId of the chain.
 * @property {string} name - The name of the chain.
 * @property {string} status - The status of the chain.
 * @property {[PromptBlock]} promptBlocks - The promptBlocks of the chain.
 * @property {string} createdAt - The date the chain was created.
 * @property {string} updatedAt - The date the chain was last updated.
 */

/**
 * @typedef {Object} BuiltPrompt
 * @property {string} id - The id of the builtPrompt.
 * @property {string} uid - The user id of the user who created the builtPrompt.
 * @property {string} model - The model of the builtPrompt.
 * @property {Object} modeConfig - The modeConfig of the builtPrompt.
 * @property {string} promptName - The name of the builtPrompt.
 * @property {string} prompt - The prompt of the builtPrompt.
 * @property {string} example - The example of the builtPrompt.
 * @property {[string]} valueKeys - The value keys of the builtPrompt.
 * @property {string} createdAt - The date the builtPrompt was created.
 * @property {string} updatedAt - The date the builtPrompt was last updated.
 * @property {GptConfig} gptConfig - The gptConfig of the builtPrompt.
 */

/**
 * @typedef {Object} PromptBlock - The promptBlock object.
 * @property {string} chainKey - The chainKey of the promptBlock.
 * @property {string} aliasName - The aliasName of the promptBlock.
 * @property {string} builtPromptId - The builtPromptId of the promptBlock.
 * @property {string} blockType - The blockType of the promptBlock.
 * @property {string} logicType - The logicType of the promptBlock.
 * @property {Object} valuesLogicLinkedImport - The valuesLogicLinkedImport of the promptBlock.
 * @property {Object} valuesLinkedImport - The valuesLinkedImport of the promptBlock.
 * @property {Object} valuesLinkedExport - The valuesLinkedExport of the promptBlock.
 * @property {string} createdAt - The date the promptBlock was created.
 */

/**
 ****************************************************************************************************************************************************************
 ****************************************************************************************************************************************************************
 ****************************************************************************************************************************************************************
 */

/**
 * @typedef {function} useApiProject - The useApiProject hook for react.
 * @param {string} apiProjectId - The apiProjectId of the apiProject.
 * @returns {[ApiProject, boolean, Error]} - The apiProject, loading and error.
 * @example
 * const [apiProject, loading, error] = useApiProject('apiProjectId');
 * if (loading) {
 *  return <div>Loading...</div>;
 * }
 * if (error) {
 *   return <div>Error: {error.message}</div>;
 * }
 * return <div>{apiProject.name}</div>;
 */

const db = {};

export const useApiProject = (projectId, chainId, options) => {
  const [sdkInstance, setSdkInstance] = useState({ instance: null });
  // const [apiProject, setApiProject] = useState(null);
  const [dataSets, setDataSets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { project } = sdkInstance.instance || {};

  useEffect(() => {
    if (!projectId || !chainId) {
      setLoading(false);
      setSdkInstance({ instance: null });
      return;
    }
    const getApiProject = async () => {
      try {
        const apiProjectSdkInstance = await ApiProjectSdk.init({
          projectId,
          chainId,
          options: {
            db,
            AIengineApiUrls: runsUrls,
            ...options
          }
        });
        setSdkInstance({ instance: apiProjectSdkInstance });
        setLoading(false);
      } catch (error) {
        console.error(error);
        setError(error);
        setLoading(false);
      }
    };

    getApiProject();
  }, [projectId]);

  useEffect(() => {
    if (!sdkInstance.instance) {
      setDataSets([]);
      return;
    }

    const unsubscribe = sdkInstance.instance.onSnapshotDataSets((dataSets) => {
      setDataSets(dataSets);
    });

    return unsubscribe;
  }, [sdkInstance]);

  const createDataSet = async (
    initialParameters = {},
    initialValues = {},
    uid = null,
    data = {}
  ) => {
    const dataSet = await sdkInstance.instance.createDataSet(
      initialParameters,
      initialValues,
      uid,
      data
    );
    return dataSet;
  };

  return [sdkInstance.instance, loading, error, dataSets, createDataSet];
};

export const useDataSet = (sdkInstance, dataSetId) => {
  const [dataSetInstance, setDataSetInstance] = useState(null);
  const [dataSet, setDataSet] = useState(dataSetInstance);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!sdkInstance || !dataSetId) {
      setLoading(false);
      setDataSet(null);
      return;
    } else if (sdkInstance && dataSetId) {
      const init = async () => {
        const dataSetInstance = await sdkInstance.getDataSet(dataSetId);
        setDataSetInstance(dataSetInstance);
        setLoading(false);
        try {
        } catch (error) {
          console.error(error);
          setError(error);
          setLoading(false);
        }
      };
      init();
    }
  }, [sdkInstance, dataSetId]);

  useEffect(() => {
    if (!dataSetInstance) {
      setDataSet(null);
      return;
    }
    const subscriber = dataSetInstance.dataObservable.subscribe((data) => {
      setDataSet(data);
    });
    dataSetInstance.callNext();
    return () => {
      return subscriber.unsubscribe();
    };
  }, [dataSetInstance]);

  const dataSetMethods = {
    setParameters: (parameters) => {
      dataSetInstance.setParameters(parameters);
    },
    setResults: (results) => {
      dataSetInstance.setResults(results);
    },
    updateData: async (data) => {
      return await dataSetInstance.updateData(data);
    },
    saveData: async () => {
      await dataSetInstance.saveData();
    },
    runByExportedVariables: async (...exportedVariables) => {
      // check if last param is option {stream : true}
      const runnedBlocks = await dataSetInstance.runByExportedVariables(
        ...exportedVariables
      );
      return runnedBlocks;
    },
    addExamplesToBuiltPrompt: async (
      exportedVariableBlock,
      example,
      resultIndex
    ) => {
      await dataSetInstance.addExamplesToBuiltPrompt(
        exportedVariableBlock,
        example,
        resultIndex
      );
    }
  };

  return [dataSet, dataSetMethods, loading, error];
};

class AiChainsConfig {
  constructor(config) {
    this.projectsChainsAliases = {};
    if (config) {
      if (config) this.config(config);
    }
  }

  setProjectsChainsAliases(aliases) {
    Object.keys(aliases).forEach((aliasName) => {
      const value = aliases[aliasName].split("::");
      const [apiProjectId, chainId] = value;
      this.projectsChainsAliases[aliasName] = { apiProjectId, chainId };
    });
  }

  getProjectChainByAlias(aliasName) {
    return this.projectsChainsAliases[aliasName].chainId;
  }

  config(config) {
    this.temporaryDb = config.db;
    this.setProjectsChainsAliases(config.aliases);
  }
}

export const aiChainConfig = new AiChainsConfig();

class PublicSdkWrapper {
  constructor(aliasName, config = {}) {
    this.apiProjectId =
      aiChainConfig.projectsChainsAliases[aliasName].apiProjectId;
    this.chainId = aiChainConfig.projectsChainsAliases[aliasName].chainId;
    this.db = aiChainConfig.temporaryDb;
    this.apiProjectSdk = null;
    this.isDataSetsExternal = config.isDataSetsExternal;
  }

  async init() {
    const apiProjectSdk = await ApiProjectSdk.init({
      projectId: this.apiProjectId,
      chainId: this.chainId,
      options: {
        db: this.db,
        isDataSetsExternal: this.isDataSetsExternal
      },
      isPublic: true
    });
    this.apiProjectSdk = apiProjectSdk;
    return this.apiProjectSdk;
  }

  initDataSet(defaultParameters, defaultResults) {
    const newDataSet = this.apiProjectSdk.initDataSet(
      defaultParameters,
      defaultResults
    );
    return newDataSet;
  }

  async runDataSet(dataSet, ...exportedVariables) {
    return await dataSet.runByExportedVariables(...exportedVariables);
  }

  async runByExportedVariables_noUpdate(
    dataSet,
    exportedVariable,
    resultIndex,
    options
  ) {
    const results = await dataSet.runAndGetByExportedVariable_noUpdate(
      exportedVariable,
      resultIndex,
      options
    );
    return results;
  }

  setDataSetParameters(dataSet, parameters) {
    dataSet.setParameters(parameters, false);
  }

  setDataSetResults(dataSet, results) {
    dataSet.setResults(results, false);
  }

  subscribeToDataSetResult(dataSet, callback) {
    const subscriber = dataSet.dataObservable.subscribe((data) => {
      callback(data.results);
    });
    dataSet.callNext();
    return subscriber;
  }
}

const isParametersStringNumberPairs = (...exportedVariables) => {
  const isPairsStringNumber = exportedVariables.reduce((prev, curr, index) => {
    const isEven = index % 2 === 0;

    if (typeof curr !== "string" && typeof curr !== "number") {
      throw new Error(
        "Only string and number are allowed in exportedVariables"
      );
    }

    if (isEven) {
      if (typeof curr === "number") {
        throw new Error(
          "Index number parameter is only allowed in pair and should be the second parameter in the pair"
        );
      }
      if (index + 1 === exportedVariables.length) {
        return false;
      }
      return prev;
    } else {
      if (typeof curr === "string") {
        return false;
      }
      return prev;
    }
  }, true);
  return isPairsStringNumber;
};

const getPairsReduce = (prev, curr, index, exportedVariables) => {
  const isEven = index % 2 === 0;
  if (isEven) {
    prev.push([curr, exportedVariables[index + 1]]);
  }
  return prev;
};

export const useAiChain = (
  aliasName,
  parameters,
  defaultResults,
  key = "default"
) => {
  const [results, setResults] = useState({});
  // TODO: modify to object by key
  const [resultsRunningStatus, setResultsRunningStatus] = useState({});
  const [publicSdkWrapper, setPublicSdkWrapper] = useState(null);
  const currentDataSet = useRef(null);

  useEffect(() => {
    const init = async () => {
      const publicSdkWrapper = new PublicSdkWrapper(aliasName, {
        isDataSetsExternal: true
      });
      await publicSdkWrapper.init();
      setPublicSdkWrapper(publicSdkWrapper);
    };
    init();
  }, [aliasName]);

  useEffect(() => {
    if (!publicSdkWrapper || key === null) {
      return;
    }
    currentDataSet.current = publicSdkWrapper.initDataSet(
      parameters,
      defaultResults
    );
    const subscriber = publicSdkWrapper.subscribeToDataSetResult(
      currentDataSet.current,
      (results) => {
        setResults(results);
      }
    );
    return () => {
      subscriber.unsubscribe();
    };
  }, [publicSdkWrapper, key]);

  useEffect(() => {
    if (!currentDataSet.current || !parameters) {
      return;
    }
    publicSdkWrapper.setDataSetParameters(currentDataSet.current, parameters);
  }, [parameters]);

  // useEffect(() => {
  //   if (!currentDataSet.current || !defaultResults) {
  //     return;
  //   }
  //   publicSdkWrapper.setDataSetResults(currentDataSet.current, defaultResults);
  // }, [defaultResults]);

  const handleResultsRunningsStatus = (exportedVariable, running) => {
    setResultsRunningStatus((prev) => {
      return { ...prev, [exportedVariable]: running };
    });
  };

  const runAndGetResults = async (
    exportedVariable,
    resultIndex = null,
    options = {}
  ) => {
    if (!currentDataSet.current) {
      return;
    }

    const results = await publicSdkWrapper.runByExportedVariables_noUpdate(
      currentDataSet.current,
      exportedVariable,
      resultIndex,
      options
    );
    return results;
  };

  const runResults = async (...params) => {
    let exportedVariables = [...params];
    let lastParam = params[params.length - 1];
    let options = null;

    if (typeof lastParam === "object" && !Array.isArray(lastParam)) {
      options = lastParam;
      exportedVariables = params.slice(0, params.length - 1);
    }

    if (!currentDataSet.current) {
      return;
    }

    const isExportedVariablesPairs = isParametersStringNumberPairs(
      ...exportedVariables
    );

    if (isExportedVariablesPairs) {
      const pairs = exportedVariables.reduce(getPairsReduce, []);

      for (let i = 0; i < pairs.length; i++) {
        const [exportedVariable, index] = pairs[i];
        handleResultsRunningsStatus(`${exportedVariable}-${index}`, true);
        let paramsToPass = [currentDataSet.current, exportedVariable, index];
        if (options) {
          paramsToPass.push(options);
        }
        await publicSdkWrapper.runDataSet(...paramsToPass);
        handleResultsRunningsStatus(`${exportedVariable}-${index}`, false);
      }
      return true;
    } else {
      for (let i = 0; i < exportedVariables.length; i++) {
        const exportedVariable = exportedVariables[i];
        handleResultsRunningsStatus(exportedVariable, true);
        let paramsToPass = [currentDataSet.current, exportedVariable];
        if (options) {
          paramsToPass.push(options);
        }

        await publicSdkWrapper.runDataSet(...paramsToPass);
        handleResultsRunningsStatus(exportedVariable, false);
      }
      return true;
    }
  };

  const runResultsAsync = async (...exportedVariables) => {
    if (!currentDataSet.current) {
      return;
    }

    const p = [];

    const isExportedVariablesPairs = isParametersStringNumberPairs(
      ...exportedVariables
    );

    if (isExportedVariablesPairs) {
      const pairs = exportedVariables.reduce(getPairsReduce, []);

      for (let i = 0; i < pairs.length; i++) {
        p.push(runResults(pairs[i][0], pairs[i][1]));
      }
    } else {
      for (let i = 0; i < exportedVariables.length; i++) {
        p.push(runResults(exportedVariables[i]));
      }
    }

    return Promise.all(p);
  };

  return [results, runResults, runAndGetResults, resultsRunningStatus];
};
