import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { ApiBuilderContext } from "../../../../contexts/ApiBuilderContext";
import { DeploymentModelContext } from "../../../../contexts/DeploymentModelContext";
import { ToolElement } from "./LaunchLite";
import { ConfigurationModal } from "./ConfigurationModal";

export const DeploymentModelBuilderLeftBar = () => {
  const { deploymentModel } = useContext(DeploymentModelContext);
  const { id: apiProjectId } = useContext(ApiBuilderContext);
  const [showModal, setShowModal] = useState(false);
  const [selectedTool, setSelectedTool] = useState(null);

  const handleShow = (tool) => {
    setSelectedTool(tool);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  const { name } = deploymentModel;

  return (
    <div className="left-bar p-3">
      <Link
        to={`/system-designer/${apiProjectId}/launch-lite`}
        className="back-to-models mb-3 d-block text-decoration-none text-reset"
      >
        <i className="bi bi-arrow-left me-2"></i> Back to Models List
      </Link>

      <hr />

      <h3 className="mb-4">Design Tools</h3>

      <ConfigurationModal buttonClassName={"mb-4"} />

      <div className="tool-category mb-4">
        <h4 className="mb-3">UI Components</h4>
        <ToolElement
          type="button"
          label="Button"
          config={{ type: "button" }}
          className="mb-2"
        />
        {/* Add more UI components as needed */}
      </div>

      <div className="tool-category mb-4">
        <h4 className="mb-3">Logic Elements</h4>
        <ToolElement
          type="condition"
          label="Condition"
          config={{ type: "condition" }}
          className="mb-2"
        />
        {/* Add more logic elements as needed */}
      </div>
      {/* ... (more categories and tools) */}
    </div>
  );
};
