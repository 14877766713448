import React, { useState, useContext } from "react";
import { Card, Form, ListGroup, Button } from "react-bootstrap";
import { ChainContext } from "../../../../contexts/ChainContext";
import SystemBuilderUtils from "../utils";
import DropdownGroupedSelect from "../../../UI/DropdownGroupedSelect";

const getSources = (inputs, outputs) => {
  const systemValuesForTriggering = SystemBuilderUtils.getIOitems(
    inputs,
    outputs,
    {
      inputs: [
        "transform.promptBlocks",
        "global.parameters",
        "inputBlocks.userInput"
      ],
      outputs: [
        "transform.promptBlocks",
        "inputConnections.fetcher",
        "emitters.logic",
        "emitters.fetch"
      ]
    }
  );

  const systemValuesItemsForTriggering =
    SystemBuilderUtils.IOitemsToDropdownItems([
      ...systemValuesForTriggering.inputsList,
      ...systemValuesForTriggering.outputsList
    ]);

  return systemValuesItemsForTriggering;
};

const DataObjectNode = ({ data }) => {
  const { dataObject } = data;
  const { inputs, outputs } = useContext(ChainContext);
  const [toggleProperties, setToggleProperties] = useState(false);
  const [dataObjectState, setDataObjectState] = useState(dataObject);
  // const { inputs, outputs } = useContext(ChainContext);
  // const { id: apiProjectId, apiProject } = useContext(ApiBuilderContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataObjectState({ ...dataObjectState, [name]: value });
  };

  const properties = dataObjectState.properties || [];

  const addProperties = () => {
    properties.push({ name: "", valueSource: "" });
    setDataObjectState({ ...dataObjectState, properties });
  };

  const removeProperty = (index) => {
    properties.splice(index, 1);
    setDataObjectState({ ...dataObjectState, properties });
  };

  const onPropertyChange = (e, index) => {
    const { name, value } = e.target;
    properties[index][name] = value;
    setDataObjectState({ ...dataObjectState, properties });
  };

  const listenerSources = getSources(inputs, outputs);

  return (
    <Card className={`p-3 shadow-sm DataObjectNode fs-6`}>
      <Card.Title className="text-white">{dataObjectState.name}</Card.Title>
      <Card.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={dataObjectState.name || ""}
              onChange={handleChange}
            />
          </Form.Group>

          <h5
            className="mb-3"
            onClick={() => setToggleProperties(!toggleProperties)}
          >
            Properties{" "}
            <i
              className={`fas fa-chevron-${
                toggleProperties ? "down" : "right"
              }`}
            ></i>
          </h5>

          {toggleProperties && (
            <>
              <ListGroup>
                {properties.map((property, index) => (
                  <ListGroup.Item key={index}>
                    <Form.Group className="mb-3">
                      {/* <Form.Label className="text-white">Input Connection Name</Form.Label> */}
                      <Form.Control
                        type="text"
                        placeholder="Property Name"
                        value={property.name}
                        onChange={(e) => {
                          onPropertyChange(e, index);
                        }}
                      />
                    </Form.Group>
                    {/* dropdown selector for systemValues.trigger items list */}
                    <Form.Group className={"mb-3"}>
                      <Form.Label>Value Source</Form.Label>
                      <DropdownGroupedSelect
                        className="node-dropdown nodrag dpd-1 zIndex-9999"
                        classNameMenu="nowheel"
                        items={listenerSources}
                        onAdd={(item) => {
                          onPropertyChange(
                            { target: { name: "listener", value: item.value } },
                            index
                          );
                        }}
                        onRemove={(item) => {
                          onPropertyChange(
                            { target: { name: "listener", value: "" } },
                            index
                          );
                        }}
                        isSelected={(item) => {
                          return property.listener === item.value;
                        }}
                        tr={{
                          title: (group) => SystemBuilderUtils.tr(group)
                        }}
                        placeholder={
                          "Select a value source to listen to for this property"
                        }
                      />
                    </Form.Group>
                    <Button
                      variant="danger"
                      onClick={() => removeProperty(index)}
                    >
                      Remove Property
                    </Button>
                  </ListGroup.Item>
                ))}
              </ListGroup>
              <div>
                <Button variant="primary" onClick={addProperties}>
                  Add Property
                </Button>
              </div>
            </>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default DataObjectNode;
