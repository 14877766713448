import React, { useState, useEffect } from "react";
import CodeEditor from "@uiw/react-textarea-code-editor";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { sublime } from "@uiw/codemirror-theme-sublime";

const CodeEditorInput = ({ value, onTextChange, language }) => {
  return (
    <>
      <CodeMirror
        value={value}
        height="calc(100vh - 73px - 38px - 42px)"
        extensions={[javascript({ jsx: true })]}
        theme={sublime}
        onChange={onTextChange}
      />
      {/* <CodeEditor
        value={value}
        data-color-mode={"light"}
        language={language}
        minHeight={"calc(100vh - 73px - 38px - 42px)"}
        style={{
          fontFamily: "monospace",
          fontSize: 14,
          lineHeight: 1.5,
          borderRadius: 5,
          border: "1px solid #ccc",
        }}
        onChange={onTextChange}
      /> */}
    </>
  );
};

export default CodeEditorInput;
