import { useContext, createContext, useState, useEffect } from "react";
import DB from "../database/DB";

export const DeploymentContext = createContext();

export const DeploymentProvider = ({ children, queryObject }) => {
  const [deployments, setDeployments] = useState([]);

  // Charger les déploiements
  useEffect(() => {
    fetchDeployments();
  }, [queryObject]);

  const fetchDeployments = async () => {
    try {
      let deploymentsData;
      deploymentsData = await DB.deployments.getList();

      if (queryObject) {
        // const { field, operator, value } = filterVal;
        const queries = [];
        Object.keys(queryObject).forEach((key) => {
          queries.push({
            field: key,
            operator: "==",
            value: queryObject[key]
          });
        });

        deploymentsData = await DB.deployments.getList(queries);
      } else {
        deploymentsData = await DB.deployments.getList();
      }
      setDeployments(deploymentsData);
    } catch (error) {
      console.error("Error fetching deployments:", error);
    }
  };

  // Ajouter un déploiement
  const createDeployment = async (deploymentData) => {
    try {
      const docId = await DB.deployments.create(deploymentData);
      setDeployments([...deployments, { id: docId, ...deploymentData }]);
    } catch (error) {
      console.error("Error creating deployment:", error);
    }
  };

  // Mettre à jour un déploiement
  const updateDeployment = async (id, updatedData) => {
    try {
      await DB.deployments.update(id, updatedData);
      setDeployments(
        deployments.map((deployment) =>
          deployment.id === id ? { ...deployment, ...updatedData } : deployment
        )
      );
    } catch (error) {
      console.error("Error updating deployment:", error);
    }
  };

  // Supprimer un déploiement
  const deleteDeployment = async (id) => {
    try {
      await DB.deployments.delete(id);
      setDeployments(deployments.filter((deployment) => deployment.id !== id));
    } catch (error) {
      console.error("Error deleting deployment:", error);
    }
  };

  return (
    <DeploymentContext.Provider
      value={{
        deployments,
        createDeployment,
        updateDeployment,
        deleteDeployment,
        fetchDeployments
      }}
    >
      {children}
    </DeploymentContext.Provider>
  );
};

export const useDeployments = () => useContext(DeploymentContext);
