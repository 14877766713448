/**
 * @fileoverview This file contains the inputs components
 *
 *   We will need inputs components for the following:
 *    this is type:
 *    string
 *    number
 *    boolean
 *    object
 *    array
 *    arrayString
 *    arrayNumber
 *    arrayObject
 *
 *    this is inputMode:
 *    default available for all types
 *    select available for all types
 *    multiSelect available for all array, arrayString, arrayNumber, arrayObject
 *    selectGrouped available for all types
 *    multiSelectGrouped available for all array, arrayString, arrayNumber, arrayObject
 *
 *    for each type default inputMode will be rendered.
 *
 *    For string component: we will need simple text input
 *
 *    For number component: we will need simple number input
 *
 *    For boolean component: we will need simple checkbox input
 *      - checkIcon property which allow us to change the icon
 *      - uncheckIcon property which allow us to change the icon
 *
 *    For object component: we will need a button that will open a modal
 *      - the modal will contain a form with the following inputs:
 *      - a toggle form to add a json object in a textarea
 *      - key: string
 *      - value: string
 *      - add button
 *      - remove button
 *    - save button
 *    - cancel button
 *
 *    For array/arrayString component: we will need a button that will open a modal
 *      - the modal will contain a form with the following inputs:
 *      - a toggle form to add a json object in a textarea
 *      - value: string
 *      - add button
 *      - remove button
 *      - save button
 *     - cancel button
 *      - when the modal is not open, we will display the array as a list of items
 *
 *    For arrayNumber component: we will need a button that will open a modal
 *      - the modal will contain a form with the following inputs:
 *      - a toggle form to add a json object in a textarea
 *      - value: number
 *      - add button
 *      - remove button
 *      - save button
 *     - cancel button
 *      - when the modal is not open, we will display the array as a list of items
 *
 *    For arrayObject component: we will need a button that will open a modal
 *      - the modal will contain a form with the following inputs:
 *      - a toggle form to add a json object in a textarea
 *      - items: array of objects
 *      - add item button
 *      - key: string
 *      - value: string
 *      - add button
 *      - remove button
 *     - save button
 *      - cancel button
 *      - when the modal is not open, we will display the array as a list of items
 *
 *    For select component: we will need a button that will open a modal
 *
 *
 *   For icon we will fontawesome classes
 */

import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  Form,
  Row,
  Col,
  Modal,
  ListGroup,
} from "react-bootstrap";
import DropdownGroupedByAndAutoComplete from "./DropdownGroupedByAndAutoComplete"; // selectGrouped
import DropdownGroupedSelect from "./DropdownGroupedSelect"; // multiSelectGrouped, multiSelect
import SimpleSelector from "./SimpleSelector"; // select

const ArrayObjectInput = ({ value = [], onChange, inputMode, ...props }) => {
  const handleObjectChange = (index, object) => {
    const newValue = [...value];
    newValue[index] = object;
    onChange(newValue);
  };

  const handleAddObject = () => {
    const newValue = [...value, {}];
    onChange(newValue);
  };

  const handleRemoveObject = (index) => {
    const newValue = [...value];
    newValue.splice(index, 1);
    onChange(newValue);
  };

  if (inputMode && inputMode !== "default") {
    return (
      <InputModeForm
        inputMode={inputMode}
        value={value}
        {...props}
        onChange={onChange}
      />
    );
  }

  return (
    <div>
      {value.map((object, index) => (
        <div key={index}>
          <ObjectInput
            value={object}
            onChange={(newObject) => handleObjectChange(index, newObject)}
            {...props}
          />
          <Button variant="danger" onClick={() => handleRemoveObject(index)}>
            Remove
          </Button>
        </div>
      ))}
      <Button variant="primary" onClick={handleAddObject}>
        Add Object
      </Button>
    </div>
  );
};

const ArrayNumberInput = ({ value, onChange, inputMode, ...props }) => {
  // Modal
  const [seeJSON, setSeeJSON] = useState(false);
  // -
  const [showModal, setShowModal] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const {
    maxResultInput = 5,
    addLabel = "Add",
    removeLabel = "Remove",
    editLabel = "Edit",
    noresultlabel = "No number",
    editTitle = "Edit Array",
    saveLabel = "Save",
    cancelLabel = "Cancel",
    editbuttonlabel = "Edit",
    editbuttonclassname = "",
    modalClassName = "",
    fieldClassName = "",
    resultclassname = "",
  } = props;

  const handleAdd = () => {
    onChange([...value, Number(inputValue)]);
    setInputValue("");
  };

  const handleRemove = (index) => {
    onChange(value.filter((item, i) => index !== i));
  };

  const handleSave = (newValue) => {
    setShowModal(false);
    onChange(newValue);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  if (inputMode && inputMode !== "default") {
    return (
      <InputModeForm
        inputMode={inputMode}
        value={value}
        {...props}
        onChange={onChange}
      />
    );
  }

  const list = value || [];

  return (
    <>
      <div className={"d-flex w-100 " + fieldClassName}>
        <div className={resultclassname}>
          {(!value || value.length === 0) && noresultlabel}

          {value && value.length > 0 && (
            <div>
              <Button
                variant="link"
                onClick={() => setSeeJSON(!seeJSON)}
              >
                {seeJSON ? "Hide" : "See"} JSON
              </Button>
              {!seeJSON && (
                <div>
                  {value.slice(0, maxResultInput).map((item, index) => (
                    <span key={index}>
                      {item}
                      {index < value.length - 1 ? ", " : ""}
                    </span>
                  ))}
                  {value.length > maxResultInput && (
                    <span>... and {value.length - maxResultInput} more</span>
                  )}
                </div>
              )}
              {seeJSON && (
                <pre>{JSON.stringify(value, null, 2)}</pre>
              )}
            </div>
          )}
        </div>
        <Button onClick={handleOpenModal} className={editbuttonclassname}>
          {editbuttonlabel}
        </Button>
      </div>
      <Modal show={showModal} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{editTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Input to add new number */}
            <Form.Group controlId="arrayInput" as={Row}>
              <Col>
                <Form.Control
                  type="number"
                  value={inputValue}
                  onChange={(event) => setInputValue(event.target.value)}
                />
              </Col>
              <Col>
                <Button variant="primary" onClick={handleAdd} className="ml-2">
                  {addLabel}
                </Button>
              </Col>
            </Form.Group>
            {list.map((item, index) => (
              <Form.Group controlId="arrayInput" key={index} as={Row}>
                <Col>
                  <Form.Control
                    type="number"
                    value={item}
                    onChange={(event) =>
                      onChange(
                        value.map((item, i) =>
                          index === i ? Number(event.target.value) : item
                        )
                      )
                    }
                  />
                </Col>
                <Col>
                  <Button
                    variant="danger"
                    onClick={() => handleRemove(index)}
                    className="ml-2"
                  >
                    {removeLabel}
                  </Button>
                </Col>
              </Form.Group>
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            {cancelLabel}
          </Button>
          {/* <Button variant="primary" onClick={() => handleSave(value)}>
            {saveLabel}
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

const StringInput = ({ value, onChange, inputMode, ...props }) => {
  if (inputMode && inputMode !== "default") {
    return (
      <InputModeForm
        inputMode={inputMode}
        value={value}
        {...props}
        onChange={onChange}
      />
    );
  }

  return (
    <Form.Control
      value={value}
      onChange={(event) => onChange(event.target.value)}
      {...props}
    />
  );
};

const NumberInput = ({ value, onChange, inputMode, ...props }) => {
  if (inputMode && inputMode !== "default") {
    return (
      <InputModeForm
        inputMode={inputMode}
        value={value}
        {...props}
        onChange={onChange}
      />
    );
  }

  return (
    <Form.Control
      type="number"
      value={value}
      onChange={(event) => onChange(event.target.value)}
      {...props}
    />
  );
};

const BooleanInput = ({ value, onChange, inputMode, ...props }) => {
  const { booleanlabel = "" } = props;

  if (inputMode && inputMode !== "default") {
    return (
      <InputModeForm
        inputMode={inputMode}
        value={value}
        {...props}
        onChange={onChange}
      />
    );
  }

  const onSelectBoolean = (event) => {
    onChange(event.target.value === "true");
  };

  return (
    <Form.Group>
      <Form.Label>{booleanlabel}</Form.Label>
      <Form.Select value={value} onChange={onSelectBoolean}>
        <option value={"true"}>True</option>
        <option value={"false"}>False</option>
      </Form.Select>
    </Form.Group>
  );
};

const ArrayBooleanInput = ({ value, onChange, inputMode, ...props }) => {
  const handleValueChange = (index, newValue) => {
    const newValueArray = [...value];
    newValueArray[index] = newValue;
    onChange(newValueArray);
  };

  const handleAddValue = () => {
    const newValueArray = [...value, false];
    onChange(newValueArray);
  };

  const handleRemoveValue = (index) => {
    const newValueArray = [...value];
    newValueArray.splice(index, 1);
    onChange(newValueArray);
  };

  if (inputMode && inputMode !== "default") {
    return (
      <InputModeForm
        inputMode={inputMode}
        value={value}
        {...props}
        onChange={onChange}
      />
    );
  }

  return (
    <div>
      {value.map((item, index) => (
        <div key={index}>
          <BooleanInput
            value={item}
            onChange={(newValue) => handleValueChange(index, newValue)}
            {...props}
          />
          <Button variant="danger" onClick={() => handleRemoveValue(index)}>
            Remove
          </Button>
        </div>
      ))}
      <Button variant="primary" onClick={handleAddValue}>
        Add Value
      </Button>
    </div>
  );
};

const getObjectText = (value) => {
  if (!value) {
    return "{}";
  }
  if (Object.keys(value).length === 0) {
    return "{}";
  }
  return JSON.stringify(value, null, 2);
};

const testObjectText = (text) => {
  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
};

const ObjectInput = ({ value = {}, onChange, inputMode, ...props }) => {
  const [showModal, setShowModal] = useState(false);
  const [objectText, setObjectText] = useState(getObjectText(value));
  const [isObjectTextValid, setIsObjectTextValid] = useState(true);
  const [seeObject, setSeeObject] = useState(false);

  const {
    placeholder = "No object",
    editbuttonlabel = "Edit",
    editbuttonclassname = "",
    modalclassname = "",
    fieldclassname = "",
  } = props;

  useEffect(() => {
    if (testObjectText(objectText)) {
      setIsObjectTextValid(true);
      // onChange(JSON.parse(objectText));
    } else {
      setIsObjectTextValid(false);
    }
  }, [objectText]);

  const handleSave = () => {
    if (!isObjectTextValid) {
      return;
    }
    setShowModal(false);
    onChange(JSON.parse(objectText));
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  if (inputMode && inputMode !== "default") {
    return (
      <InputModeForm
        inputMode={inputMode}
        value={value}
        {...props}
        onChange={onChange}
      />
    );
  }

  return (
    <div className="d-flex w-100">
      <div>
        {(!value || Object.keys(value).length === 0) && [placeholder]}
        {value && Object.keys(value).length > 0 && (
          <>
            <Button variant="link" onClick={() => setSeeObject(!seeObject)}>
              {seeObject ? "Hide" : "See"} Object
            </Button>
            {seeObject && (
              <ListGroup>
                {Object.keys(value).map((key) => (
                  <ListGroup.Item key={key}>
                    <Row>
                      <Col>{key}</Col>
                      <Col>{JSON.stringify(value[key])}</Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
          </>
        )}
      </div>
      <Button onClick={handleOpenModal} className={editbuttonclassname}>
        {editbuttonlabel}
      </Button>
      <Modal show={showModal} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Object</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="objectInput">
              <Form.Label>Object</Form.Label>
              <Form.Control
                as="textarea"
                className={`${!isObjectTextValid ? "border-error" : ""}`}
                rows="3"
                value={objectText}
                onChange={(event) => setObjectText(event.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleSave()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const ArrayStringInput = ({ value, onChange, inputMode, ...props }) => {
  // Modal
  const [seeJSON, setSeeJSON] = useState(false);
  // -
  const [showModal, setShowModal] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [seeListOfString, setSeeListOfString] = useState(false);

  const {
    maxResultInput = 5,
    addLabel = "Add",
    removeLabel = "Remove",
    editLabel = "Edit",
    noresultlabel = "No string",
    editTitle = "Edit Array",
    saveLabel = "Save",
    cancelLabel = "Cancel",
    editbuttonlabel = "Edit",
    editbuttonclassname = "",
    modalClassName = "",
    fieldClassName = "",
    resultclassname = "",
  } = props;

  const handleAdd = () => {
    onChange([...value, inputValue]);
    setInputValue("");
  };

  const handleRemove = (index) => {
    onChange(value.filter((item, i) => index !== i));
  };

  const handleSave = (newValue) => {
    setShowModal(false);
    onChange(newValue);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  if (inputMode && inputMode !== "default") {
    return (
      <InputModeForm
        inputMode={inputMode}
        value={value}
        {...props}
        onChange={onChange}
      />
    );
  }

  const list = value || [];

  return (
    <>
      <div className={"d-flex w-100 " + fieldClassName}>
        <div className={resultclassname}>
          {(!value || value.length === 0) && noresultlabel}

          {value && value.length > 0 && (
            <div>
              <Button
                variant="link"
                onClick={() => setSeeListOfString(!seeListOfString)}
              >
                {seeListOfString ? "Hide" : "See"} List Of String
              </Button>
              {!seeListOfString && (
                <div>
                  {value.slice(0, maxResultInput).map((item, index) => (
                    <span key={index}>
                      {item}
                      {index < value.length - 1 ? ", " : ""}
                    </span>
                  ))}
                  {value.length > maxResultInput && (
                    <span>... and {value.length - maxResultInput} more</span>
                  )}
                </div>
              )}
              {seeListOfString && (
                <ListGroup>
                  {value.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col>{item}</Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </div>
          )}
        </div>
        <Button onClick={handleOpenModal} className={editbuttonclassname}>
          {editbuttonlabel}
        </Button>
      </div>
      <Modal show={showModal} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Array</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* <Form.Group controlId="arrayInput">
              <Form.Label>Array</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                value={JSON.stringify(value, null, 2)}
                onChange={(event) => onChange(JSON.parse(event.target.value))}
              />
            </Form.Group> */}
            {/* Input to add new string */}
            <Form.Group controlId="arrayInput" as={Row}>
              <Col>
                <Form.Control
                  value={inputValue}
                  onChange={(event) => setInputValue(event.target.value)}
                />
              </Col>
              <Col>
                <Button variant="primary" onClick={handleAdd} className="ml-2">
                  {addLabel}
                </Button>
              </Col>
            </Form.Group>
            {list.map((item, index) => (
              <Form.Group controlId="arrayInput" key={index} as={Row}>
                <Col>
                  <Form.Control
                    value={item}
                    onChange={(event) =>
                      onChange(
                        value.map((item, i) =>
                          index === i ? event.target.value : item
                        )
                      )
                    }
                  />
                </Col>
                <Col>
                  <Button
                    variant="danger"
                    onClick={() => handleRemove(index)}
                    className="ml-2"
                  >
                    {removeLabel}
                  </Button>
                </Col>
              </Form.Group>
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={() => handleSave(value)}>
            Save
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

// DropdownGroupedByAndAutoComplete - selectGrouped
// DropdownGroupedSelect - multiSelectGrouped, multiSelect
// SimpleSelector - select

const InputModeForm = ({ inputMode, ...props }) => {
  switch (inputMode) {
    case "selectGrouped":
      return <DropdownGroupedByAndAutoComplete {...props} />;
    case "multiSelectGrouped":
      return <DropdownGroupedSelect {...props} />;
    case "multiSelect":
      return <DropdownGroupedSelect {...props} />;
    case "select":
      return <SimpleSelector {...props} />;
    default:
      return null;
  }
};

const stringTypes = ["string", "text"];

const numberTypes = ["number", "int", "integer", "float", "double"];

const booleanTypes = ["boolean", "bool"];

const objectTypes = ["object", "json"];

const arrayStringTypes = ["arrayString", "array", "list"];

const arrayNumberTypes = ["arrayNumber"];

const arrayBooleanTypes = ["arrayBoolean"];

const arrayObjectTypes = ["arrayObject"];

const checkType = (value) => {
  if (stringTypes.includes(value)) {
    return "string";
  }
  if (numberTypes.includes(value)) {
    return "number";
  }
  if (booleanTypes.includes(value)) {
    return "boolean";
  }
  if (objectTypes.includes(value)) {
    return "object";
  }
  if (arrayStringTypes.includes(value)) {
    return "arrayString";
  }
  if (arrayNumberTypes.includes(value)) {
    return "arrayNumber";
  }
  if (arrayBooleanTypes.includes(value)) {
    return "arrayBoolean";
  }
  if (arrayObjectTypes.includes(value)) {
    return "arrayObject";
  }
  return null;
};

// if inputMode is select check SimpleSelector Documentation for props
// if inputMode is multiSelect check DropdownGroupedSelect Documentation for props
// if inputMode is selectGrouped check DropdownGroupedByAndAutoComplete Documentation for props
// if inputMode is multiSelectGrouped check DropdownGroupedSelect Documentation for props

/**
 * StringInputProps
 * @typedef {Object} StringInputProps
 * @property {string} value
 * @property {(value: string) => void} onChange
 * @property {string} inputMode
 * @property {string} placeholder
 * @property {boolean} disabled
 * @property {inputProps} inputProps
 * @property {string} className
 */

/**
 * NumberInputProps
 * @typedef {Object} NumberInputProps
 * @property {number} value
 * @property {(value: number) => void} onChange
 * @property {string} inputMode
 * @property {string} placeholder
 * @property {boolean} disabled
 * @property {inputProps} inputProps
 * @property {string} className
 * @property {number} min
 * @property {number} max
 * @property {number} step
 */

/**
 * BooleanInputProps
 * @typedef {Object} BooleanInputProps
 * @property {boolean} value
 * @property {(value: boolean) => void} onChange
 * @property {string} inputMode
 * @property {string} placeholder
 * @property {boolean} disabled
 * @property {inputProps} inputProps
 * @property {string} className
 * @property {string} checkIcon
 * @property {string} uncheckIcon
 * @property {string} checkLabel
 * @property {string} uncheckLabel
 */

/**
 * ObjectInputProps
 * @typedef {Object} ObjectInputProps
 * @property {Object} value
 * @property {(value: Object) => void} onChange
 * @property {string} inputMode
 * @property {string} placeholder
 * @property {boolean} disabled
 * @property {inputProps} inputProps
 * @property {string} className
 */

/**
 * ArrayStringInputProps
 * @typedef {Object} ArrayStringInputProps
 * @property {string[]} value
 * @property {(value: string[]) => void} onChange
 * @property {string} inputMode
 * @property {string} placeholder
 * @property {boolean} disabled
 * @property {inputProps} inputProps
 * @property {string} className
 * @property {number} maxResultInput
 * @property {string} addLabel
 * @property {string} removeLabel
 * @property {string} editLabel
 * @property {string} saveLabel
 * @property {string} cancelLabel
 * @property {string} editTitle
 */

/**
 * ArrayNumberInputProps
 * @typedef {Object} ArrayNumberInputProps
 * @property {number[]} value
 * @property {(value: number[]) => void} onChange
 * @property {string} inputMode
 * @property {string} placeholder
 * @property {boolean} disabled
 * @property {inputProps} inputProps
 * @property {string} className
 * @property {number} maxResultInput
 * @property {string} addLabel
 * @property {string} removeLabel
 * @property {string} editLabel
 * @property {string} saveLabel
 * @property {string} cancelLabel
 * @property {string} editTitle
 * @property {number} min
 * @property {number} max
 * @property {number} step
 */

/**
 * ArrayObjectInputProps
 * @typedef {Object} ArrayObjectInputProps
 * @property {Object[]} value
 * @property {(value: Object[]) => void} onChange
 * @property {string} inputMode
 * @property {string} placeholder
 * @property {boolean} disabled
 * @property {inputProps} inputProps
 * @property {string} className
 * @property {number} maxResultInput
 * @property {string} addLabel
 * @property {string} removeLabel
 * @property {string} editLabel
 * @property {string} saveLabel
 * @property {string} cancelLabel
 * @property {string} editTitle
 */

/**
 * InputTypeFormProps
 * @typedef {Object} InputTypeFormProps
 * @property {string} type
 * @property {string} inputMode
 * @property {string} value
 * @property {(value: any) => void} onChange
 * @property {any} restProps - rest of the props which can be any of the above
 */

const InputTypeForm = ({
  type,
  inputMode = "default",
  value,
  onChange,
  ...props
}) => {
  const inputType = checkType(type);

  switch (inputType) {
    case "string":
      return (
        <StringInput
          inputMode={inputMode}
          value={value || ""}
          onChange={onChange}
          {...props}
        />
      );
    case "number":
      return (
        <NumberInput
          inputMode={inputMode}
          value={value || 0}
          onChange={onChange}
          {...props}
        />
      );
    case "boolean":
      return (
        <BooleanInput
          inputMode={inputMode}
          value={value || false}
          onChange={onChange}
          {...props}
        />
      );
    case "object":
      return (
        <ObjectInput
          inputMode={inputMode}
          value={value || {}}
          onChange={onChange}
          {...props}
        />
      );
    case "array":
      return (
        <ArrayObjectInput
          inputMode={inputMode}
          value={value || []}
          onChange={onChange}
          {...props}
        />
      );
    case "arrayString":
      return (
        <ArrayStringInput
          inputMode={inputMode}
          value={value || []}
          onChange={onChange}
          {...props}
        />
      );
    case "arrayNumber":
      return (
        <ArrayNumberInput
          inputMode={inputMode}
          value={value || []}
          onChange={onChange}
          {...props}
        />
      );
    case "arrayBoolean":
      return (
        <ArrayBooleanInput
          inputMode={inputMode}
          value={value || []}
          onChange={onChange}
          {...props}
        />
      );
    case "arrayObject":
      return (
        <ArrayObjectInput
          inputMode={inputMode}
          value={value || []}
          onChange={onChange}
          {...props}
        />
      );
    default:
      return null;
  }
};

export default InputTypeForm;
