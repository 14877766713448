import React, { useContext, useState } from "react";
import "./ConstructionZoneNavbar.css";
import { ApiModelDocumentationModal } from "./ApiModelDocumentation";
import { DeploymentModelContext } from "../../../../../contexts/DeploymentModelContext";

const ConstructionZoneNavbar = () => {
  const [showApiDocumentation, setShowApiDocumentation] = useState(false);
  const { deploymentModel } = useContext(DeploymentModelContext);

  return (
    <>
      <div className="construction-zone-navbar">
        <ul className="navbar-links">
          <li onClick={() => setShowApiDocumentation(true)}>
            API Documentation
          </li>
          <li>Settings</li>
          <li>Export</li>
          <li>Preview</li>
          {/* Ajoutez d'autres liens selon vos besoins */}
        </ul>
      </div>
      {showApiDocumentation && (
        <ApiModelDocumentationModal
          deploymentModel={deploymentModel}
          show={true}
          handleClose={() => setShowApiDocumentation(false)}
        />
      )}
    </>
  );
};

export default ConstructionZoneNavbar;
