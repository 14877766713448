const firstLanguages = {
  javascript: {
    name: "JavaScript",
    libs: {
      vanilla: { name: "Vanilla (Browser)" },
      axios: { name: "Axios (Node.js)" }
      // fetch: { name: "Fetch (Browser)" },
      // jquery: { name: "jQuery (Browser)" }
    }
  },
  python: {
    name: "Python",
    libs: {
      requests: { name: "Requests (Standard)" },
      http: { name: "HTTPX (Async)" }
    }
  },
  csharp: {
    name: "C#",
    libs: {
      httpclient: { name: "HttpClient" },
      restsharp: { name: "RestSharp" }
    }
  },
  arduino: {
    name: "Arduino",
    libs: {
      wifi: { name: "WiFi (ESP8266/ESP32)" },
      ethernet: { name: "Ethernet (Wired)" }
    }
  },
  ruby: {
    name: "Ruby",
    libs: {
      nethttp: { name: "Net::HTTP" },
      faraday: { name: "Faraday" }
    }
  },
  php: {
    name: "PHP",
    libs: {
      curl: { name: "cURL" },
      guzzle: { name: "Guzzle" }
    }
  },
  go: {
    name: "Go",
    libs: {
      nethttp: { name: "net/http" }
    }
  },
  swift: {
    name: "Swift",
    libs: {
      urlsession: { name: "URLSession" },
      alamofire: { name: "Alamofire" }
    }
  },
  kotlin: {
    name: "Kotlin",
    libs: {
      okhttp: { name: "OkHttp" },
      ktor: { name: "Ktor" }
    }
  },
  rust: {
    name: "Rust",
    libs: {
      reqwest: { name: "Reqwest" }
    }
  }
  // Ajoutez d'autres langages et bibliothèques selon les besoins
};
const extendedLanguages = {
  typescript: {
    name: "TypeScript",
    libs: {
      axios: { name: "Axios (Node.js)" },
      angularhttp: { name: "Angular HttpClient" }
    }
  },
  dart: {
    name: "Dart",
    libs: {
      http: { name: "HTTP Package" },
      dio: { name: "Dio Package" }
    }
  },
  lua: {
    name: "Lua",
    libs: {
      luasocket: { name: "LuaSocket" }
    }
  },
  elixir: {
    name: "Elixir",
    libs: {
      httpoison: { name: "HTTPoison" }
    }
  },
  scala: {
    name: "Scala",
    libs: {
      akkahttp: { name: "Akka HTTP" },
      playws: { name: "Play WS" }
    }
  },
  clojure: {
    name: "Clojure",
    libs: {
      cljhttp: { name: "clj-http" }
    }
  },
  fsharp: {
    name: "F#",
    libs: {
      fsharpdata: { name: "FSharp.Data" }
    }
  },
  r: {
    name: "R",
    libs: {
      httr: { name: "httr" }
    }
  },
  perl: {
    name: "Perl",
    libs: {
      lwp: { name: "LWP (Libwww-perl)" }
    }
  },
  objectivec: {
    name: "Objective-C",
    libs: {
      nsurlsession: { name: "NSURLSession" }
    }
  },
  julia: {
    name: "Julia",
    libs: {
      httpjulia: { name: "HTTP.jl" }
    }
  }
};
const extendedLanguages2 = {
  haskell: {
    name: "Haskell",
    libs: {
      wreq: { name: "Wreq" }, // Wreq est une bibliothèque Haskell pour les requêtes HTTP faciles
      servant: { name: "Servant" } // Servant est utilisé pour les API REST
    }
  },
  // ... autres langages du premier objet (firstLanguages & extendedLanguages)
  // Ajout de nouveaux langages spécifiques pour les requêtes HTTP
  // elm: {
  //   name: "Elm",
  //   libs: {
  //     pure: { name: "Native Elm HTTP" }
  //   }
  // },
  erlang: {
    name: "Erlang",
    libs: {
      inets: { name: "Inets" } // Inets est un ensemble de services Internet, y compris un client HTTP
      // hackney: { name: "Hackney" } // Hackney est un client HTTP simple en Erlang
    }
  },
  // ocaml: {
  //   name: "OCaml",
  //   libs: {
  //     cohttp: { name: "Cohttp" } // Cohttp est un client HTTP léger pour OCaml
  //   }
  // },
  smalltalk: {
    name: "Smalltalk",
    libs: {
      zinc: { name: "Zinc HTTP Components" } // Zinc est un ensemble complet de composants HTTP pour Smalltalk
    }
  },
  groovy: {
    name: "Groovy",
    libs: {
      httpbuilder: { name: "HTTPBuilder" } // HTTPBuilder est un client HTTP pour Groovy
    }
  }
  // Autres langages...
};
export const languages = {
  ...firstLanguages,
  ...extendedLanguages,
  ...extendedLanguages2
};
